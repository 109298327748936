import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { t } from 'i18next';
import { useRef, useState, useEffect } from 'react';
import fingerIcon from '../../../assets/icon/ic-fingerprint.svg';
import tagIcon from '../../../assets/icon/tag-solid.svg';

import { permissionObj } from '../../../Helpers/permission';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import SearchFilterBtn from '../../../components/buttons/SearchFilterBtn';
import { SearchByFilters } from '../../../reduxToolkit/Search/SearchApi';
import SearchFor from '../../Modals/SearchFor';
import { TABLES } from '../../../Apis/Tables';
import { MODELS } from '../../../Apis/Models';
import { clearSearchFilters } from '../../../reduxToolkit/Search/SearchSlice';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import moment from 'moment';
import { accessMethod } from '../../../enums/accessMethodEnum';
import { Box, Tooltip } from '@mui/material';
import faceIcon from '../../../assets/icon/ic-face.svg';
import cardIcon from '../../../assets/icon/ic-card.svg';
import qrIcon from '../../../assets/icon/ic-qr-code.svg';
import pinIcon from '../../../assets/icon/pin-icon.png';
import bluetoothIcon from '../../../assets/icon/bluetooth-icon.svg';
import platesIcon from '../../../assets/icon/license-plate.png';
import checkIcon from '../../../assets/icon/checkIcon.svg';
import cancelIcon from '../../../assets/icon/cancelIcon.svg';
import ExportDataBtn from '../../../components/buttons/ExportDataBtn';
import NotInformation from '../../../components/NotInformation';
import laptopIcon from '../../../assets/laptop-solid.svg';
import mobileIcon from '../../../assets/mobile-screen-solid.svg';
import CloseIcon from '@mui/icons-material/Close';
const Access = () => {
    const [toggleState, setToggleState] = useState(1);
    // const elementRef = useRef(null);
    const { user, permission } = useSelector((state) => state.authenticatioauthennSlice);
    const dispatch = useDispatch();
    const { searchByFilters } = useSelector((state) => state.SearchSlice);
    const lCode = Cookies.get('i18next') || 'en';
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Props to the filter window
    const [moduleId, setModuleId] = useState(`${MODELS.UserAccessLog}`);
    const [option, setOption] = useState(`${TABLES.USER_ACCESS_LOG}`);
    const [criteria, setCriteria] = useState();

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage, finalArray, deletedFlag]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, [toggleState]);

    // This components are used to calculate the distance between the top of the window and the top of the table panel
    const elementRef = useRef(null);
    const elementRefer = useRef(null);
    useEffect(() => {
        if (elementRefer.current && toggleState === 1) {
            const rect = elementRefer.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRefer.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        if (elementRef.current && toggleState === 2) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
    }, [toggleState, rowsPerPage, page, sortBy, searchByFilters]);

    //Create body for the respectively search
    useEffect(() => {
        if (loaded) {
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                    from: item.from,
                    to: item.to,
                };
            });

            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: criteriaList,
            };
            dispatch(SearchByFilters({ option, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    {
                        setLoading(false);
                    }
                },
            );
            setCriteria({ searchCriteriaList: criteriaList });
            setLoaded(false);
            setDeletedFlag(false);
        }
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray, deletedFlag, moduleId]);

    useEffect(() => {
        if (toggleState === 1) {
            setModuleId(`${MODELS.UserAccessLog}`);
            setOption(`${TABLES.USER_ACCESS_LOG}`);
        } else {
            setModuleId(`${MODELS.VehicleAccessLog}`);
            setOption(`${TABLES.VEHICLE_ACCESS_LOG}`);
        }
    }, [toggleState]);

    // check permission check
    useEffect(() => {
        if (!permission?.includes(permissionObj?.WEB_ACCESS_VEHICLE_READ)) {
            setToggleState(1);
        } else if (!permission?.includes(permissionObj?.WEB_ACCESS_USER_READ)) {
            setToggleState(2);
        }
    }, []);

    return (
        <div>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('access')}</h2>
                </div>
            </div>
            <div className='row steps-row mb-3 mx-0' id='pills-tab' role='tablist'>
                {permission?.includes(permissionObj?.WEB_ACCESS_USER_READ) && (
                    <div
                        className={`col-6 text-center p-0 tap_hover ${
                            toggleState === 1 ? 'active_tap' : 'deactive_tap'
                        }`}
                        role='presentation'
                    >
                        <a
                            className={`steps-global btn ${toggleState === 1 ? 'btn-bordered-global' : ''}`}
                            onClick={() => {
                                if (toggleState !== 1) {
                                    setPage(0);
                                    setToggleState(1);
                                    setRowsPerPage(20);

                                    setOption(`${TABLES.USER_ACCESS_LOG}`);
                                    setModuleId(`${MODELS.UserAccessLog}`);
                                    setFinalArray([]);
                                }
                            }}
                            id='pills-home-tab'
                            data-bs-toggle='pill'
                            data-bs-target='#pills-home'
                            type='button'
                            role='tab'
                            aria-controls='pills-home'
                            aria-selected='true'
                        >
                            <span>{t('users')}</span>
                        </a>
                    </div>
                )}
                {permission?.includes(permissionObj?.WEB_ACCESS_VEHICLE_READ) && (
                    <div
                        className={`col-6 text-center p-0 tap_hover ${
                            toggleState === 2 ? 'active_tap' : 'deactive_tap'
                        }`}
                        role='presentation'
                    >
                        <a
                            className={`steps-global btn ${toggleState === 2 ? 'btn-bordered-global' : ''}`}
                            onClick={() => {
                                if (toggleState !== 2) {
                                    setPage(0);
                                    setToggleState(2);
                                    setRowsPerPage(20);
                                    setOption(`${TABLES.VEHICLE_ACCESS_LOG}`);
                                    setModuleId(`${MODELS.VehicleAccessLog}`);
                                    setFinalArray([]);
                                }
                            }}
                            id='pills-home-tab'
                            data-bs-toggle='pill'
                            data-bs-target='#pills-home'
                            type='button'
                            role='tab'
                            aria-controls='pills-home'
                            aria-selected='true'
                        >
                            <span>{t('vehicles')}</span>
                        </a>
                    </div>
                )}
            </div>
            <div className='tab-content' id='pills-tabContent'>
                <div
                    className={`${toggleState === 1 ? 'tab-pane fade show active ' : 'tab-pane fade'}`}
                    id='pills-home'
                    role='tabpanel'
                    aria-labelledby='pills-home-tab'
                >
                    <div className='container-top-right-btns'>
                        {permission?.includes(permissionObj?.WEB_ACCESS_USER_EXPORT) && (
                            <ExportDataBtn body={criteria} option={option} />
                        )}
                        <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                    </div>{' '}
                    <div className='panelTables  px-1' ref={elementRefer}>
                        {searchByFilters?.content?.length > 0 ? (
                            <table className='w-100'>
                                <thead className='no-border-thead'>
                                    <th className='first_head'>{t('user')}</th>
                                    {/* <th>{t("last_name")}</th> */}
                                    {/* <th>{t("second_last_name")}</th> */}
                                    <th>{t('user_type')}</th>
                                    <th>{t('date')}</th>
                                    <th>{t('device')}</th>
                                    <th>{t('zone')}</th>
                                    <th>{t('successful_attempt')}</th>
                                    <th className='last'>{t('access_method')}</th>
                                </thead>
                                <tbody>
                                    {searchByFilters?.content?.length > 0 &&
                                        searchByFilters?.content?.map((item) => {
                                            return (
                                                <tr>
                                                    <td className='first align-middle'>
                                                        {`${item?.user?.name || ''} ${item?.user?.lastName || ''} ${
                                                            item?.user?.secondLastName || ''
                                                        }`.trim()}
                                                    </td>
                                                    {/* <td style={{ maxWidth: 250 }}>{item?.user?.lastName ? item?.user?.lastName : "-"}</td> */}
                                                    {/* <td >{item?.user?.secondLastName ? item?.user?.secondLastName : "-"}</td> */}
                                                    <td>{item?.user?.userType?.name}</td>
                                                    <td style={{ maxWidth: 250 }}>
                                                        {lCode === 'en'
                                                            ? moment(item?.createdAt).format('MM/DD/YYYY')
                                                            : moment(item?.createdAt).format('DD/MM/YYYY')}
                                                        <br />
                                                        {moment(item?.createdAt).format('HH:mm:ss')}
                                                    </td>
                                                    <td>{item?.device?.name}</td>
                                                    <td>{item?.device?.zone?.name}</td>
                                                    <td>
                                                        {/* {item?.success ? t("yes") : t("no")} */}
                                                        <img
                                                            src={item?.success ? checkIcon : cancelIcon}
                                                            alt='AccessMethod'
                                                            // style={{
                                                            //   width: "24px",
                                                            //   height:  "24px",
                                                            // }}
                                                        />
                                                    </td>
                                                    <td className='last_tr'>
                                                        <Tooltip
                                                            title={t(
                                                                accessMethod[item?.accessMethod?.id],
                                                            ).toUpperCase()}
                                                            placement='left'
                                                            disableInteractive
                                                        >
                                                            <img
                                                                src={
                                                                    item?.accessMethod?.id === 1
                                                                        ? tagIcon
                                                                        : item?.accessMethod?.id === 2
                                                                          ? fingerIcon
                                                                          : item?.accessMethod?.id === 3
                                                                            ? cardIcon
                                                                            : item?.accessMethod?.id === 4
                                                                              ? qrIcon
                                                                              : item?.accessMethod?.id === 5
                                                                                ? faceIcon
                                                                                : item?.accessMethod?.id === 6
                                                                                  ? pinIcon
                                                                                  : item?.accessMethod?.id === 7
                                                                                    ? fingerIcon
                                                                                    : item?.accessMethod?.id === 8
                                                                                      ? bluetoothIcon
                                                                                      : item?.accessMethod?.id === 9
                                                                                        ? laptopIcon
                                                                                        : item?.accessMethod?.id === 10
                                                                                          ? mobileIcon
                                                                                          : item?.accessMethod?.id ===
                                                                                              11
                                                                                            ? CloseIcon
                                                                                            : item?.accessMethod?.id ===
                                                                                                12
                                                                                              ? CloseIcon
                                                                                              : item?.accessMethod
                                                                                                      ?.id === 13
                                                                                                ? platesIcon
                                                                                                : item?.accessMethod
                                                                                                        ?.id === 14
                                                                                                  ? fingerIcon
                                                                                                  : item?.accessMethod
                                                                                                          ?.id === 15
                                                                                                    ? fingerIcon
                                                                                                    : item?.accessMethod
                                                                                                            ?.id === 16
                                                                                                      ? CloseIcon
                                                                                                      : undefined
                                                                }
                                                                alt='AccessMethod'
                                                                style={{
                                                                    width: [6, 8, 13].includes(item?.accessMethod?.id)
                                                                        ? '24px'
                                                                        : 'auto',
                                                                    height: [6, 8, 13].includes(item?.accessMethod?.id)
                                                                        ? '24px'
                                                                        : 'auto',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        ) : (
                            <NotInformation text={t('no_information')} card={true} />
                        )}
                    </div>
                    <Box className='pagination-table'>
                        {searchByFilters?.content?.length > 0 && (
                            <div className='d-flex justify-content-center'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[20, 40, 60]}
                                    count={searchByFilters?.totalElements}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    labelRowsPerPage={t('user_access_per_page')}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        )}
                    </Box>
                </div>
                <div
                    className={`${toggleState === 2 ? 'tab-pane fade show active ' : 'tab-pane fade'}`}
                    id='pills-profile'
                    role='tabpanel'
                    aria-labelledby='pills-profile-tab'
                >
                    <div className='container-top-right-btns'>
                        {permission?.includes(permissionObj?.WEB_ACCESS_VEHICLE_EXPORT) && (
                            <ExportDataBtn body={criteria} option={option} />
                        )}
                        <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                    </div>{' '}
                    <div className='panelTables animated-div-left px-1 mt-1' ref={elementRef}>
                        {searchByFilters?.content?.length > 0 ? (
                            <table className='w-100'>
                                <thead className='no-border-thead'>
                                    <th className='first_head'>{t('brand')}</th>
                                    <th>{t('sub_brand')}</th>
                                    <th>{t('color')}</th>
                                    <th>{t('plates')}</th>
                                    <th>{t('vin')}</th>
                                    {/* <th>{t("tag")}</th> */}
                                    <th>{t('date')}</th>
                                    <th>{t('device')}</th>
                                    <th>{t('zone')}</th>
                                    <th>{t('successful_attempt')}</th>
                                    <th className='last'>{t('access_method')}</th>
                                </thead>
                                <tbody>
                                    {searchByFilters?.content?.length > 0 &&
                                        searchByFilters?.content?.map((item) => {
                                            return (
                                                <tr>
                                                    <td className='first align-middle'>{item?.brand}</td>
                                                    <td style={{ maxWidth: 250 }}>{item?.subBrand}</td>
                                                    <td>-</td>
                                                    <td>{item?.plate}</td>
                                                    <td style={{ maxWidth: 250 }}>{item?.vin}</td>
                                                    {/* <td>-</td> */}
                                                    <td style={{ maxWidth: 250 }}>
                                                        {lCode === 'en'
                                                            ? moment(item?.createdAt).format('MM/DD/YYYY')
                                                            : moment(item?.createdAt).format('DD/MM/YYYY')}
                                                        <br />
                                                        {moment(item?.createdAt).format('HH:mm:ss')}
                                                    </td>
                                                    <td>{item?.deviceName}</td>
                                                    <td>{item?.zoneName}</td>
                                                    <td>
                                                        {/* {item?.success ? t("yes") : t("no")} */}
                                                        <img
                                                            src={item?.success ? checkIcon : cancelIcon}
                                                            alt='AccessMethod'
                                                            // style={{
                                                            //   width: "24px",
                                                            //   height:  "24px",
                                                            // }}
                                                        />
                                                    </td>
                                                    <td className='last_tr'>
                                                        <Tooltip
                                                            title={t(accessMethod[item?.accessMethodId]).toUpperCase()}
                                                            placement='left'
                                                            disableInteractive
                                                        >
                                                            <img
                                                                src={
                                                                    item?.accessMethodId === 1
                                                                        ? tagIcon
                                                                        : item?.accessMethodId === 2
                                                                          ? fingerIcon
                                                                          : item?.accessMethodId === 3
                                                                            ? cardIcon
                                                                            : item?.accessMethodId === 4
                                                                              ? qrIcon
                                                                              : item?.accessMethodId === 5
                                                                                ? faceIcon
                                                                                : item?.accessMethodId === 6
                                                                                  ? pinIcon
                                                                                  : item?.accessMethodId === 7
                                                                                    ? fingerIcon
                                                                                    : item?.accessMethodId === 8
                                                                                      ? bluetoothIcon
                                                                                      : item?.accessMethod?.id === 9
                                                                                        ? laptopIcon
                                                                                        : item?.accessMethod?.id === 10
                                                                                          ? mobileIcon
                                                                                          : item?.accessMethod?.id ===
                                                                                              11
                                                                                            ? CloseIcon
                                                                                            : item?.accessMethod?.id ===
                                                                                                12
                                                                                              ? CloseIcon
                                                                                              : item?.accessMethodId ===
                                                                                                  13
                                                                                                ? platesIcon
                                                                                                : item?.accessMethod
                                                                                                        ?.id === 14
                                                                                                  ? fingerIcon
                                                                                                  : item?.accessMethod
                                                                                                          ?.id === 15
                                                                                                    ? fingerIcon
                                                                                                    : item?.accessMethod
                                                                                                            ?.id === 16
                                                                                                      ? CloseIcon
                                                                                                      : undefined
                                                                }
                                                                alt='AccessMethod'
                                                                style={{
                                                                    width: [6, 8, 13].includes(item?.accessMethodId)
                                                                        ? '24px'
                                                                        : 'auto',
                                                                    height: [6, 8, 13].includes(item?.accessMethodId)
                                                                        ? '24px'
                                                                        : 'auto',
                                                                    maxWidth: '100%',
                                                                    maxHeight: '100%',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        ) : (
                            <NotInformation text={t('no_information')} card={true} />
                        )}
                    </div>
                    {searchByFilters?.content?.length > 0 && (
                        <div className='d-flex justify-content-center'>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={[20, 40, 60]}
                                count={searchByFilters?.totalElements}
                                page={page}
                                onPageChange={handleChangePage}
                                labelRowsPerPage={t('vehicle_access_per_page')}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    )}
                </div>
            </div>
            <SearchFor
                open={filterDialogShow}
                onClose={() => {
                    setFilterDialogShow(false);
                }}
                onFiltered={(originalArray) => {
                    setFilterDialogShow(false);
                    setFinalArray(originalArray);
                }}
                moduleId={moduleId}
                option={option}
                finalArray={finalArray}
            />
        </div>
    );
};

export default Access;
