import french from 'date-fns/locale/fr';
import spanish from 'date-fns/locale/es';
import arab from 'date-fns/locale/ar';

const dateInputLanguage = (lCode) => {
    switch (lCode) {
        case 'sp':
            return spanish;
        case 'fr':
            return french;
        case 'ar':
            return arab;
        default:
            return '';
    }
};

export default dateInputLanguage;
