import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import house_plane from '../../../assets/images/house_plane@2x.png';
import map_solid from '../../../assets/images/map-solid.svg';
import ic_info from '../../../assets/images/ic-info.svg';
import ShowDeviceModal from './Modal/ShowDeviceModal';
import ShowDeviceMapModal from './Modal/showDeviceMapModal';
import ShowDeviceListModal from './Modal/ShowDeviceListModal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    GetListZoneMap,
    GetZoneDevicesLists,
    SetZoneImageCoordinate,
    ZoneDetailFatherAndChild,
} from '../../../reduxToolkit/EmployeeZones/EmployeeZonesApi';
import { useDispatch, useSelector } from 'react-redux';
import ImageMarker, { MarkerComponentProps } from 'react-image-marker';
import RemovePlanModal from './Modal/RemovePlanModal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ic_left_arrow from '../../../assets/images/ic_left_arrow.svg';

import { useDrag, useDrop } from 'react-dnd';
import DeviceListZonePlane from './DeviceListZonePlane';
import { set } from 'lodash';
import { permissionObj } from '../../../Helpers/permission';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Box } from '@mui/material';
import noData from '../../../assets/images/warning.svg';
import LefletMap from '../../../components/LefletMap';
import ZoneMapSelect from '../../../components/ZoneMapSelect';
import { ClearGetListZoneMap } from '../../../reduxToolkit/EmployeeZones/EmployeeZoneSlice';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import { display } from '@mui/system';

/*
Author : Eduardo Noguez
Module: Zone
github: https://github.com/Arman-Arzoo
*/
// show devices main function
const ShowDevices = ({ xCord, yCord }) => {
    // translation
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    // use hook importer
    const dispatch = useDispatch();

    // use State hook for local state management
    const [ProfileImage, setProfileImage] = useState('');
    const [viewPlane, setViewPlane] = React.useState('');
    const [openNavigation, setOpenNavigation] = React.useState(true);
    const [selectedZone, setSelectedZone] = React.useState('');
    const [isDeviceList, setIsDeviceList] = React.useState(false);
    const [imageMarkers, setImageMarkers] = useState([]);
    const [defaultZone, setDefaultZone] = useState([]);
    const [x, setX] = useState(xCord);
    const [y, setY] = useState(yCord);
    const [showPlaneModal, setShowPlaneModal] = useState(false);
    const [deletePlaneModal, setDeletePlaneModal] = useState(false);

    //use Selector hook to get state for redux store
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const {
        zoneDetailFatherAndChild,
        getListZoneMap,
        createZonePlane,
        deleteImgZonePlane,

        setZoneImageCoordinate,
        getZoneDevicesLists,
        uploadImgZonePlane,
    } = useSelector((state) => state.EmployeeZonesSlice);

    const handleChange = (event) => {
        setViewPlane(event.target.value);
    };

    // get the position on x and y on drop
    const printCoordinate = (e) => {
        const { width, height } = e.target.getBoundingClientRect();
        const { offsetX, offsetY } = e.nativeEvent;

        setX(Math.round(((offsetX - 15) / width) * 100));
        setY(Math.round(((offsetY - 25) / height) * 100));
    };

    //drop the device to the zone plane
    const [{ isOver }, drop] = useDrop({
        accept: 'image',
        drop: (item, monitor) => {
            addDeviceToImage(item?.id);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
    // put device on image
    const addDeviceToImage = (id) => {
        const data = {
            deviceId: id,
            axisPositionX: y,
            axisPositionY: x,
            zonePlaneId: selectedZone?.id,
        };
        dispatch(SetZoneImageCoordinate({ data }));
    };
    // get list of zone map
    useEffect(() => {
        const data = {
            // zoneId: sessionStorage?.getItem('singlezoneId')
            zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
            ].id,
        };
        dispatch(GetListZoneMap(data));
    }, [createZonePlane, deleteImgZonePlane, uploadImgZonePlane, viewPlane]);

    // get the selected zone map
    useEffect(() => {
        const checked = getListZoneMap?.filter((item) => item.id === viewPlane);
        setSelectedZone(checked[0]);
    }, [selectedZone, viewPlane, getListZoneMap, deleteImgZonePlane]);

    useEffect(() => {
        const filtered = getZoneDevicesLists?.filter((item) => item.axisPositionX || item?.axisPositionY > 0);
        const checkDeviceImg = filtered?.map((item) => ({
            top: item.axisPositionX,
            left: item.axisPositionY,
            ...item,
        }));
        setImageMarkers(checkDeviceImg);
    }, [selectedZone, getZoneDevicesLists, viewPlane, deleteImgZonePlane]);

    //get all device zone
    useEffect(() => {
        const data = {
            zonePlaneId: selectedZone?.id,
        };
        dispatch(GetZoneDevicesLists(data));
    }, [selectedZone?.id, setZoneImageCoordinate]);
    // get detail zone father and child
    useEffect(() => {
        dispatch(
            ZoneDetailFatherAndChild({
                zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                    JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
                ].id,
            }),
        );
    }, [setZoneImageCoordinate, viewPlane, selectedZone]);

    // set map by default plane or map if no zone plane
    useEffect(() => {
        if (getListZoneMap?.length > 0) {
            setViewPlane(getListZoneMap[0].id);
        }
        if (getListZoneMap?.length === 0) {
            setViewPlane('map');
        }
    }, [deleteImgZonePlane]);

    return (
        <>
            <div className='consImgDevice' style={{ display: 'inline-block', position: 'absolute', maxWidth: '500px' }}>
                {/* image show  */}

                <img
                    ref={drop}
                    src={
                        selectedZone?.path === '' || selectedZone?.path === null || selectedZone?.path === undefined
                            ? house_plane
                            : `data:${selectedZone?.path};base64,${selectedZone?.image}`
                    }
                    onDrop={(e) => {
                        printCoordinate(e);
                    }}
                    alt='ddsf'
                />
                {viewPlane === selectedZone?.id && (
                    <>
                        {imageMarkers?.map((item, index) => {
                            return (
                                <Popup
                                    trigger={
                                        <div class='marker' style={{ top: `${item?.top}%`, left: `${item?.left}%` }}>
                                            <svg
                                                width='25px'
                                                height='36px'
                                                viewBox='0 0 48 72'
                                                fill={
                                                    (item?.deviceType?.name === 'MANTRA_MFSTAB_II' && 'green') ||
                                                    (item?.deviceType?.name === 'PDA_CARIBE_PL50L' && 'blue') ||
                                                    (item?.deviceType?.name === 'TELPO_F6' && 'yellow') ||
                                                    (item?.deviceType?.name === 'TELPO_TPS_980' && 'red') ||
                                                    (item?.deviceType?.name === 'TELPO_TPS_450' && 'orange') ||
                                                    (item?.deviceType?.name === 'TELPO_K5' && 'lightblue')
                                                }
                                            >
                                                <path
                                                    id='MyPath'
                                                    d='M24,0 C11.406,0 0,10.209 0,22.806 C0,35.4 10.407,50.436 24,72 C37.593,50.436 48,35.4 48,22.806 C48,10.209 36.597,0 24,0 L24,0 Z M24,33 C19.029,33 15,28.971 15,24 C15,19.029 19.029,15 24,15 C28.971,15 33,19.029 33,24 C33,28.971 28.971,33 24,33 L24,33 Z'
                                                ></path>
                                                <text
                                                    x='50%'
                                                    y='65%'
                                                    text-anchor='middle'
                                                    fill='white'
                                                    font-size='22px'
                                                    font-family='Arial'
                                                    fontWeight='bold'
                                                    dy='.3em'
                                                >
                                                    {item?.pin}
                                                </text>
                                            </svg>
                                        </div>
                                    }
                                    position='top'
                                >
                                    <div className='pop_up_zone_img'>
                                        <h1>{item?.deviceName}</h1>
                                        <p>{item?.description}</p>
                                    </div>
                                </Popup>
                            );
                        })}
                    </>
                )}

                <ShowDeviceModal
                    show={showPlaneModal}
                    onHide={() => setShowPlaneModal(false)}
                    setProfileImage={setProfileImage}
                />
                <ShowDeviceMapModal />
                <RemovePlanModal
                    id={selectedZone?.id}
                    show={deletePlaneModal}
                    onHide={() => setDeletePlaneModal(false)}
                />
            </div>
        </>
    );
};

export default ShowDevices;
