import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const UpdateCompanySlice = createSlice({
    name: 'updateCompany',
    initialState: {
        companyDataToUpdateObj: [],
        SelectRestrictionData: [],
        CompanyRestrictionData: [],
        latLngObj: {},
    },
    reducers: {
        mapCoordinates: (state, action) => {
            state.latLngObj = action.payload;
        },
    },
    extraReducers: {
        'updateCompany/companyDataToUpdate/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.companyDataToUpdateObj = data;
            } else if (status >= 400 && status < 600) {
            }
        },
        'updateCompany/getSelectRestrictionObj/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.SelectRestrictionData = data;
            } else if (status >= 400 && status < 500) {
                toast('Failed to fetch data');
            }
        },
        'updateCompany/getCompanyRestrictionObj/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.CompanyRestrictionData = data;
            } else if (status >= 400 && status < 500) {
                toast('Failed to fetch data');
            }
        },
        'updateCompany/updateCompany/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success(t('has_been_updated_successfully'));
            } else if (status >= 400 && status < 500) {
                toast.error(t('error_updating'));
            }
        },
        'updateCompany/updateCompanyRestrictions/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast('Company restrictions updated successfully..!');
            } else if (status >= 400 && status < 500) {
                toast('Failed to update restrictions data');
            }
        },
    },
});

export const { mapCoordinates } = UpdateCompanySlice.actions;

export default UpdateCompanySlice.reducer;
