import React, { useState } from 'react';
import { Box, FormHelperText, Stack, TextField, Tooltip } from '@mui/material';
import { Modal } from 'react-bootstrap';
import cancel from '../../../../assets/images/ic-cancel.svg';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import {
    CreateChildZone,
    CreateFatherZone,
    GetListStatusZone,
} from '../../../../reduxToolkit/EmployeeZones/EmployeeZonesApi';
import ZoneLeaflet from '../../../../components/ZoneLeaflet';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import BootstrapTooltip from '../../../../utils/BootstrapTooltip';
import broomIcon from '../../../../assets/icon/broom-solid.svg';
import useStyle from '../../../../hooks/useStyle';
import { status } from '../../../../enums/statusEnum';

/*
Author : Arman Ali
Module: Zone
github: https://github.com/Arman-Arzoo
*/

const AddZoneModal = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch();
    const { smallBoxStyle, textField } = useStyle();

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showHelperText, setShowHelperText] = useState(false);

    // destructure from prop
    const { title, check, isFatherZone, sub_title, show } = props;

    //use Selector hook to get state for redux store
    const { getListStatusZone } = useSelector((state) => state.EmployeeZonesSlice);
    // const { latLngObj } = useSelector((state) => state.UpdateCompanySlice);

    //use State hook for local state management
    const [name, setName] = useState('');
    const [isStatus, setIsStatus] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [searchLocation, setSearchLocation] = useState('');

    useEffect(() => {
        setName('');
        setIsStatus('');
        setLatitude('');
        setLongitude('');
        setSearchLocation('');
    }, [show]);

    useEffect(() => {
        {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    sessionStorage.setItem('latitude', position.coords.latitude);
                    sessionStorage.setItem('longitude', position.coords.longitude);
                });
            } else {
                console.log('location not available');
            }
        }
    }, []);

    const reset = () => {
        setIsStatus('');
        setLatitude('');
        setName('');
        setLongitude('');
        setFormSubmitted(false);
        setShowHelperText(false);
    };
    const data = {
        name,
        isStatus,
        latitude,
        longitude,
    };

    // a function to handel payload and dispatch api end function
    const handelCreateZone = () => {
        // payload for father zone data
        const createFatherZoneFormData = {
            name,
            status: {
                id: isStatus,
            },
            latitud: Number(latitude),
            longitud: Number(longitude),
        };
        // payload for chilid zone data
        const createChildZoneFormData = {
            father: {
                id: isFatherZone?.id,
            },
            name,
            status: {
                id: isStatus,
            },
            latitud: Number(latitude),
            longitud: Number(longitude),
        };

        // condition to check  father zone or child zone
        if (check === 'true') {
            if (name === '' || isStatus === '' || latitude === '' || longitude === '') {
                setFormSubmitted(true);
                setShowHelperText(true);
            } else {
                dispatch(CreateChildZone(createChildZoneFormData));
                reset();
                props.onHide();
            }
        } else {
            if (name === '' || isStatus === '' || latitude === '' || longitude === '') {
                setFormSubmitted(true);
                setShowHelperText(true);
            } else {
                dispatch(CreateFatherZone(createFatherZoneFormData));
                reset();

                props.onHide();
            }
        }
    };
    const handleCleanClick = () => {
        setSearchLocation('');
    };
    // useEffect lifeCyle for dispatch list of status zone
    useEffect(() => {
        dispatch(GetListStatusZone());
    }, []);

    // return main page modal
    return (
        <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered className='add_zone_modal'>
            <Modal.Header className='header_zone'>
                <Modal.Title id='contained-modal-title-vcenter' className='header_zone'>
                    <h4>{title}</h4>
                    {/* <span className="sub_title">{`(${sub_title})`}</span> */}
                    <span>
                        <Tooltip title={t('clean_all_inputs').toUpperCase()} placement='right' disableInteractive>
                            <button
                                className='clear_all_input'
                                onClick={() => {
                                    reset();
                                }}
                            >
                                <img src={broomIcon} alt='' />
                            </button>
                        </Tooltip>
                    </span>
                </Modal.Title>
                <Stack direction='row' justifyContent='space-between' width='48%'>
                    <Box sx={{ ...smallBoxStyle, marginTop: '1.9rem' }}>
                        <TextField
                            size='small'
                            fullWidth
                            variant='outlined'
                            label={t('search_a_place')}
                            id='Serach'
                            value={searchLocation}
                            onChange={(e) => setSearchLocation(e.target.value)}
                            sx={textField}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={handleCleanClick}>
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>

                    <img
                        onClick={() => {
                            props.onHide();
                            reset();
                        }}
                        src={cancel}
                        alt='cancel'
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '15px',
                            cursor: 'pointer',
                        }}
                    />
                </Stack>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-6 '>
                        {check === 'true' ? (
                            <Box sx={{ ...smallBoxStyle, marginBottom: '1rem' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={t('father_zone')}
                                    id='FATHER ZONE*'
                                    value={isFatherZone?.name}
                                    // onChange={(e) => setFatherZone(e.target.value)}
                                    disabled
                                    sx={textField}
                                />
                            </Box>
                        ) : (
                            ''
                        )}
                        <Box sx={{ ...smallBoxStyle, marginBottom: '1.5rem' }}>
                            <TextField
                                size='small'
                                fullWidth
                                error={formSubmitted && name === ''}
                                label={t('name')}
                                id='NAME'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                helperText={formSubmitted && name === '' ? t('requiredField') : ''}
                                sx={textField}
                            />
                        </Box>

                        <Box sx={{ ...smallBoxStyle, marginBottom: '1.5rem' }}>
                            <FormControl fullWidth error={formSubmitted && isStatus === ''} sx={textField}>
                                <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                    {t('status')}
                                </InputLabel>
                                <Select
                                    size='small'
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    label={t('status')}
                                    value={isStatus}
                                    onChange={(e) => setIsStatus(e.target.value)}
                                >
                                    {getListStatusZone?.map((item, index) => {
                                        return <MenuItem value={item.id}>{t(status[item?.id]).toUpperCase()}</MenuItem>;
                                    })}
                                </Select>
                                {showHelperText && isStatus === '' && (
                                    <FormHelperText className='select_helper_text'>
                                        {t('selectOptionText')}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <div className='d-flex gap-2 align-items-center'>
                            <Box sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    error={formSubmitted && latitude === ''}
                                    label={t('latitude')}
                                    id='LATITUDE'
                                    disabled
                                    value={latitude}
                                    helperText={formSubmitted && latitude === '' ? t('click_location_in_the_map') : ''}
                                    onChange={(e) => setLatitude(e.target.value)}
                                    sx={textField}
                                />
                            </Box>

                            <Box sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    error={formSubmitted && longitude === ''}
                                    label={t('longitude')}
                                    id='LONGTITID'
                                    disabled
                                    value={longitude}
                                    helperText={formSubmitted && longitude === '' ? t('click_location_in_the_map') : ''}
                                    onChange={(e) => setLongitude(e.target.value)}
                                    sx={textField}
                                />
                            </Box>
                        </div>
                        <Box sx={{ display: 'flex', gap: '0.4rem', paddingTop: '2rem' }}>
                            <button
                                style={{ width: '100%' }}
                                className='btn_cancel_background_gray_hover'
                                onClick={() => {
                                    props.onHide();
                                    reset();
                                }}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                style={{ width: '100%' }}
                                className='custom_primary_btn_dark'
                                onClick={() => {
                                    handelCreateZone();
                                }}
                            >
                                {t('create').toUpperCase()}
                            </button>
                        </Box>
                    </div>
                    <div className='col-md-6 text-center'>
                        <ZoneLeaflet
                            // latlng={[latLngObj.lat, latLngObj.lng]}
                            // latlng={latlng || [19.4285465, -99.1718796]}
                            setLatitude={setLatitude}
                            setLongitude={setLongitude}
                            searchLocation={searchLocation}
                            latlng={[
                                sessionStorage.getItem('latitude') === null
                                    ? 19.4285465
                                    : sessionStorage.getItem('latitude'),
                                sessionStorage.getItem('longitude') === null
                                    ? -99.1718796
                                    : sessionStorage.getItem('longitude'),
                            ]}
                            // latlng={[latitude, longitude]}
                        />
                        <p className='seeLocation'>{t('click_location_in_the_map')}</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddZoneModal;
