import React, { useState } from 'react';
import androidIcon from '../../assets/apk/android.svg';
// import yourAppAPK from '../../public/ientry.zip';
// const ientryApk ='https://docs.google.com/uc?export=download&id=1UI2-ftaLGFF7h9ItvvoGQhISAnFk_2ww';
// const ientryApk = "https://iblstmx-my.sharepoint.com/personal/lcornejo_ibl_mx/_layouts/15/download.aspx?SourceUrl=%2Fpersonal%2Flcornejo%5Fibl%5Fmx%2FDocuments%2FMicrosoft%20Teams%20Chat%20Files%2Fapp%2Dtelpof6%2Ddebug%2Eapk"
// const ientryApk = "https://drive.google.com/uc?export=download&id=1_5SMIJKIPvWlSiIw2NPG6q3t8O8M8xei"
const ientryApk = 'https://drive.google.com/uc?export=download&id=1aDIsKfTfL2hb1_hqkMAzF7fsl8vaqyIw';
const DownloadApk = () => {
    const handleDownloadClick = () => {
        // Create a link and click it programmatically to start the download
        const link = document.createElement('a');
        link.href = ientryApk;
        link.download = 'ientry.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='apk_page'>
            <div className='dark-mask'>
                <div className='download_apk_container' onClick={handleDownloadClick}>
                    <img src={androidIcon} width='200px' height='100px' alt='' />
                    <p>Click here to download the Apk</p>
                </div>
            </div>
        </div>
    );
};

export default DownloadApk;
