import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteUserInvitation,
    deleteVehicleInvitation,
    getEventDetail,
    getUserInvitationsV1,
    getVehiclesInvitations,
    deleteUserInvitationByIds,
    cancelEvent,
} from '../../../reduxToolkit/EmployeeEvents/EmployeeEventsApi';
import { Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import search from '../../../assets/images/search.svg';
import cross from '../../../assets/images/ic-cancel.svg';
import checkIcon from '../../../assets/icon/checkIcon.svg';
import cancelIcon from '../../../assets/icon/cancelIcon.svg';
import lineIcon from '../../../assets/icon/LineIcon.svg';
import redTrashIcon from '../../../assets/images/redTrash.svg';
import defaultUser from '../../../assets/defaultImages/defaultUser.png';
import CardDetail from '../../../components/CardDetail';
import DeleteModalInvitation from '../../../pages/Modals/DeleteModal';

import GenericModal from '../../Modals/GenericModal';
import CancelEventModal from './CancelEventModal';
import { status } from '../../../enums/statusEnum';
import NotInformation from '../../../components/NotInformation';

export const DeleteModal = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const { id } = useParams();

    const handleInvitation = () => {
        if (props.check === 'invite') {
            dispatch(deleteUserInvitation(props?.inviteid)).then(() => {
                dispatch(getUserInvitationsV1(id));
                props.onHide();
            });
        }
        if (props.check === 'vehicle') {
            dispatch(deleteVehicleInvitation(props?.inviteid)).then(() => {
                dispatch(getVehiclesInvitations(id));
                props.onHide();
            });
        }
    };
    return (
        <Modal
            className='filter_Event_model'
            {...props}
            size='sm'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='fiter_event_model_head'>
                <Modal.Title
                    style={{
                        width: '100%',
                        color: '#146F62',
                        fontSize: '16px',
                        fontWeight: '600',
                        textAlign: 'center',
                        marginTop: '10px',
                    }}
                >
                    {t('remove_device')}
                </Modal.Title>
                <img
                    src={cross}
                    style={{
                        position: 'absolute',
                        padding: '1px',
                        right: '3px',
                        width: '15px',
                        height: '15px',
                        top: '3px',
                        cursor: 'pointer',
                    }}
                    onClick={() => props.onHide()}
                />
            </Modal.Header>
            <Modal.Body>
                <p style={{ fontSize: '14px', textAlign: 'center', paddingBottom: '1.5rem' }}>
                    If you want to remove the device, please confirm the action.
                </p>
                <div className='changeImgBottomDiv mt-3'>
                    <button className='changeImgCancelBtn' style={{ fontSize: '12px' }} onClick={() => props.onHide()}>
                        {t('cancel')}
                    </button>
                    <button className='changeImgChangeBtn' style={{ fontSize: '12px' }} onClick={handleInvitation}>
                        {t('confirm')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const EventDetailValidation = () => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const { id } = useParams();
    const eventDetailData = useSelector((state) => state?.EmployeeEventsSlice?.eventDetail);
    const userInvitationsData = useSelector((state) => state?.EmployeeEventsSlice?.userInvitationsDataV1);
    const [showEvent, setShowEvent] = useState(false);
    const [addUserquery, setAddUserquery] = useState('');
    const [isAllCheckedInvitation, setIsAllCheckedInvitation] = useState(false);
    const [showDeleteSelected, setShowDeleteSelected] = useState(false);
    const [selectEventForDelete, setSelectEventForDelete] = useState([]);
    const [showDeleteUserSelected, setShowDeleteUserSelected] = useState(false);
    const [userInvitationSelected, setUserInvitationSelected] = useState(null);
    const [showCancelModal, setShowCancelModal] = useState(false);

    useEffect(() => {
        dispatch(getEventDetail(id));
        dispatch(getUserInvitationsV1(id));
    }, []);

    const checkStatus = (name) => {
        if (name === 'EVENT_IN_VALIDATION') {
            return '#0C4523';
        } else if (name === 'EVENT_CANCEL') {
            return 'red';
        }
    };

    const hostFullName = (event) => {
        const fullNameParts = [event.hostName, event.hostLastName, event.hostSecondLastName];
        const fullName = fullNameParts.filter(Boolean).join(' ');

        return fullName || '-';
    };

    const validatedFullName = (event) => {
        const fullNameParts = [event.validatedByName, event.validatedByLastName, event.validatedBySecondLastName];
        const fullName = fullNameParts.filter(Boolean).join(' ');

        return fullName || '-';
    };

    const guestFullName = (user) => {
        const fullNameParts = [user.guestName, user.guestLastName, user.guestSecondLastName];
        const fullName = fullNameParts.filter(Boolean).join(' ');

        return fullName || '-';
    };

    const handleDeleteAll = (e) => {
        setIsAllCheckedInvitation(e.target.checked);

        if (e.target.checked) {
            const selectAllIds = userInvitationsData?.map((item) => {
                return item?.id;
            });
            setSelectEventForDelete(selectAllIds);
        } else {
            setSelectEventForDelete([]);
        }
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectEventForDelete([...selectEventForDelete, e.target.id]);
        } else {
            setSelectEventForDelete(selectEventForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    const handleDeleteSelectedInvitations = () => {
        dispatch(
            deleteUserInvitationByIds({
                id: eventDetailData.id,
                body: selectEventForDelete,
            }),
        ).then(() => {
            dispatch(getUserInvitationsV1(id));
            setShowDeleteSelected(false);
        });
    };

    const handleDeleteUserSelected = () => {
        dispatch(deleteUserInvitation(userInvitationSelected?.id)).then(() => {
            dispatch(getUserInvitationsV1(id));
            setShowDeleteUserSelected(false);
        });
    };

    const handleShowModalUserSelected = (user) => {
        setShowDeleteUserSelected(true);
        setUserInvitationSelected(user);
    };

    const showDeleteAllInvitations = () => {
        setShowDeleteSelected(true);
    };

    const urlIcon = (status) => {
        let icon = lineIcon;

        if (status === 38) {
            icon = checkIcon;
        } else if (status === 39) {
            icon = cancelIcon;
        }

        return icon;
    };

    const handleShowCancelModal = () => {
        setShowCancelModal(true);
    };

    const handleCancelEvent = () => {
        dispatch(cancelEvent(id)).then(() => {
            handleCloseCancelModal();
        });
    };

    const handleCloseCancelModal = () => {
        setShowCancelModal(false);
    };

    return (
        <div className='EventValidationDeatil'>
            <div className='head'>
                <div className='headLeft'>
                    <Link to='/dashboard/employee/events'>
                        <i className='fa fa-arrow-left' aria-hidden='true'></i>
                    </Link>
                    <h2>{t('event_detail')}</h2>
                </div>
                {eventDetailData.statusId === 32 || eventDetailData.statusId === 33 ? (
                    <div className='d-flex'>
                        <button className='delete-btn-1' onClick={handleShowCancelModal}>
                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                            {t('cancel')}
                        </button>
                    </div>
                ) : null}
                <CancelEventModal eventid={id} show={showEvent} onHide={() => setShowEvent(false)} />
            </div>

            <div className='row animated-div ' style={{ marginTop: '1.4rem' }}>
                <div className='col-md-8 flex-column justify-content-center'>
                    <CardDetail title={t('details')}>
                        <div className='row m-0 w-100'>
                            <div className='col-12 mb-2 d-flex justify-content-between'>
                                <div>
                                    <h1 className='title-detail'>{t('event_name')}</h1>
                                    <p className='description-detail'>{eventDetailData?.name}</p>
                                </div>
                                <p
                                    className={`event-status-detail ${
                                        eventDetailData?.statusId === 31
                                            ? 'event-canceled'
                                            : eventDetailData?.statusId === 32
                                              ? 'event-validation'
                                              : eventDetailData?.statusId === 33
                                                ? 'event-approved'
                                                : eventDetailData?.statusId === 34
                                                  ? 'event-declined'
                                                  : ''
                                    }`}
                                >
                                    {t(status[eventDetailData?.statusId])}
                                </p>
                            </div>
                            <div className='col-6'>
                                <div className='mb-2'>
                                    <h1 className='title-detail'>{t('date')}</h1>
                                    <div className='d-flex'>
                                        <div className='mr-1'>
                                            <p className='description-detail fw-bolder'>{t('from')}</p>
                                            <p className='description-detail fw-bolder'>{t('to')}</p>
                                        </div>
                                        <div className='mr-1'>
                                            <p className='description-detail'>{eventDetailData.reservationDate}</p>
                                            <p className='description-detail'>{eventDetailData.reservationDate}</p>
                                        </div>
                                        <div className='mr-1'>
                                            <p className='description-detail fw-bolder'>{t('at')}</p>
                                            <p className='description-detail fw-bolder'>{t('at')}</p>
                                        </div>
                                        <div>
                                            <p className='description-detail'>{eventDetailData.reservationFromTime}</p>
                                            <p className='description-detail'>{eventDetailData.reservationToTime}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='mb-2'>
                                    <h1 className='title-detail'>{t('host')}</h1>
                                    <p className='description-detail'>{hostFullName(eventDetailData)}</p>
                                </div>
                                <div className='mb-2'>
                                    <h1 className='title-detail'>{t('validated_by')}</h1>
                                    <p className='description-detail'>{validatedFullName(eventDetailData)}</p>
                                </div>
                            </div>
                        </div>
                    </CardDetail>
                </div>
                <div className='col-md-4'>
                    <CardDetail title={t('reservation')}>
                        <div className='row m-0 w-100 ' style={{ height: '134px' }}>
                            <div className='col-12 d-flex flex-column justify-content-center'>
                                <div className='mb-2'>
                                    <h1 className='title-detail'>{t('common_area')}</h1>
                                    <p className='description-detail'>{eventDetailData.reservationZoneName}</p>
                                </div>
                                <div className='mb-2'>
                                    <h1 className='title-detail'>{t('date')}</h1>
                                    <p className='description-detail'>{validatedFullName(eventDetailData)}</p>
                                </div>
                            </div>
                        </div>
                    </CardDetail>
                </div>
            </div>
            {/* invitaion */}
            <div style={{ marginTop: '1rem' }}>
                <div className='buildingdetail_access_d d-flex align-items-center'>
                    <p style={{ color: '#146F62', fontSize: '20px', letterSpacing: '7px', fontWeight: 'bold' }}>
                        {t('invitations')}
                    </p>
                    <span
                        className={`device_button ${selectEventForDelete.length > 0 ? '' : 'd-none'}  `}
                        onClick={() => {
                            showDeleteAllInvitations();
                        }}
                        disabled={true}
                    >
                        {t('delete_selected')}
                        <i className='fa fa-trash-o' aria-hidden='true'></i>
                    </span>
                </div>
            </div>
            <div>
                <p>
                    Total <span style={{ color: '#263238', fontWeight: 'bold' }}>{userInvitationsData.length}</span>
                </p>
            </div>
            {/* listing */}
            <div style={{ marginBottom: '2rem' }}>
                {userInvitationsData?.length !== 0 ? (
                    <>
                        <div className='d-flex align-items-center my-2 buildingdetail_access_d'>
                            <input
                                type='checkbox'
                                className='checkbox mr-1'
                                checked={isAllCheckedInvitation}
                                onChange={handleDeleteAll}
                            />
                            <span className='device_button'>{t('de_/_select_all')}</span>
                        </div>
                        <div className='row employe-log-module' style={{ marginTop: '0.5rem' }}>
                            <div className='col-12'>
                                <input
                                    type='text'
                                    class='form-control'
                                    name='x'
                                    onChange={(e) => setAddUserquery(e.target.value)}
                                />
                                <span class='input-group-btn'>
                                    <button class='btn btn-default' type='button'>
                                        <img src={search} alt='' />
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div className='panelTables px-1 animated-div mt-3'>
                            <div style={{ width: '100%' }}>
                                {userInvitationsData
                                    ?.filter((user) => {
                                        if (addUserquery === '') {
                                            return user;
                                        } else if (user?.guestName.toLowerCase().includes(addUserquery.toLowerCase())) {
                                            return user;
                                        }
                                    })
                                    ?.map((user) => (
                                        <div className='d-flex align-items-center mb-3'>
                                            <input
                                                type='checkbox'
                                                className='checkbox'
                                                checked={selectEventForDelete?.includes(user?.id)}
                                                id={user?.id}
                                                onChange={handleCheckboxChange}
                                            />
                                            <img
                                                src={defaultUser}
                                                alt=''
                                                width='24px'
                                                height='24px'
                                                className='ml-3 mr-2'
                                                style={{ borderRadius: '50%' }}
                                            />
                                            <div className='mr-3' style={{ width: '160px' }}>
                                                <p className='text-description-invitation text-truncate'>
                                                    {t('name')}: <span>{guestFullName(user)}</span>
                                                </p>
                                                <p className='text-description-invitation'>
                                                    {t('celular')}: <span>{user?.guestPhoneNumber}</span>
                                                </p>
                                            </div>

                                            <img src={urlIcon(user.statusId)} alt='check' width='20px' height='18px' />

                                            <button className='btn' onClick={() => handleShowModalUserSelected(user)}>
                                                <img src={redTrashIcon} alt='check' width='20px' height='18px' />
                                            </button>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </>
                ) : (
                    <NotInformation text={t('no_information')} card={true} />
                )}
            </div>
            <DeleteModalInvitation
                show={showDeleteSelected}
                onHide={() => setShowDeleteSelected(false)}
                data={selectEventForDelete}
                title_modal={t('invitations')}
                element_modal={t('user_invitation')}
                onClickFn={() => handleDeleteSelectedInvitations()}
            />
            <GenericModal
                title={t('remove_user')}
                description={t('invite_confirmation_message')}
                show={showDeleteUserSelected}
                onClose={() => setShowDeleteUserSelected(false)}
                onSave={handleDeleteUserSelected}
                confirmButtonText={t('confirm').toUpperCase()}
                cancelButtonText={t('cancel').toUpperCase()}
            />
            <GenericModal
                title={t('cancel_event')}
                description={t('cancel_event_description')}
                show={showCancelModal}
                onClose={handleCloseCancelModal}
                onSave={handleCancelEvent}
                confirmButtonText={t('apply')}
                cancelButtonText={t('cancel')}
            />
        </div>
    );
};

export default EventDetailValidation;
