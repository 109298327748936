/*
Author: Mazhar Iqbal
Module: Work Shift Panel      
*/
//Work Shift Panel
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import { Accordion } from 'react-bootstrap';
import WorkShiftCardDetails from './WorkShiftCardDetails';
import { GetAllWorkShifts } from '../../../../reduxToolkit/CompanyWorkShift/CompanyWorkShiftApi';
import { AllWorkShiftTime } from '../../../../reduxToolkit/CompanyWorkShift/CompanyWorkShiftSlice';
import { GetZoneTree } from '../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox } from '@mui/material';
import { permissionObj } from '../../../../Helpers/permission';

const WorkShiftPanelCard = ({
    setRemoveUserModal,
    selectForDelete,
    handleCheckboxChange,
    setUpdateModal,
    setIsUpdate,
    setUpdateData,
    searchResults,
}) => {
    const fetchAllWorkTime = useSelector(AllWorkShiftTime);
    const { createContract, updateWorkShiftName } = useSelector((state) => state.CompanyWorkShiftSlice);
    const { deleteItemsApi } = useSelector((state) => state.CommonsSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    // use hook importer
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    //pagination
    const [pagePagination, setPagePagination] = useState(0);
    const [rowsPerPageProvider, setRowsPerProvider] = useState(8);

    const handleChangePageProvider = (event, newPage) => {
        setPagePagination(newPage);
    };

    const handleChangeRowsPerPageProvider = (event) => {
        setRowsPerProvider(parseInt(event.target.value));
        setPagePagination(0);
    };
    useEffect(() => {
        const pagination = {
            order: true,
            page: pagePagination,
            size: rowsPerPageProvider,
            sortBy: 'id',
        };
        //get all work shifts
        dispatch(GetAllWorkShifts(pagination));
    }, [pagePagination, rowsPerPageProvider, createContract, updateWorkShiftName, deleteItemsApi]);

    useEffect(() => {
        //get zone tree
        dispatch(GetZoneTree());
    }, []);

    const [eleId, setEleId] = useState('');
    const callFun = (id) => {
        setEleId(id);
    };

    let resultsToShow;
    if (
        !searchResults ||
        searchResults?.content?.length === 0 ||
        searchResults === null ||
        searchResults === undefined
    ) {
        resultsToShow = fetchAllWorkTime;
    } else if (searchResults?.content?.length > 0) {
        resultsToShow = searchResults;
    }

    return (
        <Accordion className='centerElements customAccordion'>
            {resultsToShow &&
                resultsToShow?.content?.map((item, index) => {
                    return (
                        <Accordion.Item
                            className='custom-accordion-item'
                            eventKey={item?.id}
                            key={item?.id}
                            onClick={() => {
                                callFun(item?.id);
                            }}
                        >
                            <Accordion.Header className='workshift_header'>
                                <div className='main'>
                                    <div className='d-flex gap-2 align-items-center'>
                                        <Checkbox
                                            onClick={(event) => event.stopPropagation()}
                                            className='grid-checkall checkbox'
                                            checked={selectForDelete?.includes(item?.id)}
                                            id={item?.id}
                                            onChange={handleCheckboxChange}
                                            size='small'
                                        />
                                        <div className='title_name'>
                                            {item?.name ? item?.name?.toUpperCase() : t('no_shift_name')}
                                        </div>
                                    </div>
                                    {permission?.includes(permissionObj?.WEB_WORK_SHIFT_UPDATE) && (
                                        <div className='edit'>
                                            <EditOutlinedIcon
                                                onClick={() => {
                                                    setUpdateModal(true);
                                                    setUpdateData(item);
                                                    setIsUpdate(true);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {eleId == item?.id ? (
                                    <WorkShiftCardDetails
                                        setRemoveUserModal={setRemoveUserModal}
                                        id={item?.id}
                                        shiftName={item}
                                    />
                                ) : (
                                    'null'
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    marginBottom: '0',
                    bottom: '0',
                }}
            >
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[8, 16, 24]}
                    count={resultsToShow?.totalElements}
                    page={pagePagination}
                    onPageChange={handleChangePageProvider}
                    labelRowsPerPage={t('workshif_per_page')}
                    rowsPerPage={rowsPerPageProvider}
                    onRowsPerPageChange={handleChangeRowsPerPageProvider}
                />
            </Box>
        </Accordion>
    );
};

export default WorkShiftPanelCard;
