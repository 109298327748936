export const timeZone = {
    1: 'UTC_N_12',
    2: 'UTC_N_11',
    3: 'UTC_N_10',
    4: 'UTC_N_9',
    5: 'UTC_N_8',
    6: 'UTC_N_7',
    7: 'UTC_N_6',
    8: 'UTC_N_5',
    9: 'UTC_N_4',
    10: 'UTC_N_3',
    11: 'UTC_N_2',
    12: 'UTC_N_1',
    13: 'UTC',
    14: 'UTC_1',
    15: 'UTC_2',
    16: 'UTC_3',
    17: 'UTC_4',
    18: 'UTC_5',
    19: 'UTC_6',
    20: 'UTC_7',
    21: 'UTC_8',
    22: 'UTC_9',
    23: 'UTC_10',
    24: 'UTC_11',
    25: 'UTC_12',
};
