import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';

// Create Work Shift Api's
export const CreateCompanyWorkShift = createAsyncThunk('companyWorkShift/CreateCompanyWorkShift', async (getVal) => {
    const result = await apiInstance
        .post('work-shift-service/create', { name: getVal })
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                toast.success('Work Shift Added SuccessFully');
            }
            return response;
        })
        .catch(function (error) {
            toast.error('Work Shift Added Fail');
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

export const CreateWorkShiftAccess = createAsyncThunk('companyWorkShift/CreateWorkShiftAccess', async (shiftList) => {
    const result = await apiInstance
        .post('work-shift-service/schedule/create-list', shiftList)
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                toast.success('Access added to work shift successFully');
            }
            return response;
        })
        .catch(function (error) {
            toast.error('Work Shift Added Fail');
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

export const GetAllWorkShifts = createAsyncThunk('companyWorkShift/GetAllWorkShifts', async (getVal) => {
    const result = await apiInstance
        .post('work-shift-service/get-all-pageable', getVal)
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                // toast.success("Work Shift Added SuccessFully");
            }
            return response;
        })
        .catch(function (error) {
            // toast.error("Work Shift Added Fail");
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

export const UserWorkSchedule = createAsyncThunk('companyWorkShift/UserWorkSchedule', async (params) => {
    const { id, UsercontractPagination } = params;
    const result = await apiInstance
        .post(`work-shift-service/work/get-all-pageable/by-work-shift-id/${id}`, UsercontractPagination)
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                // toast("User Work Schedule SuccessFully");
            }
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

export const GetAllUsers = createAsyncThunk('companyWorkShift/GetAllUsers', async () => {
    const result = await apiInstance
        .get('employee-service/get-all/only-user-data')
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                // toast.success("All User Fetch SuccessFully");
            }
            return response;
        })
        .catch(function (error) {
            toast.error('Work Shift Added Fail');
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

export const GetAllByWorkShiftId = createAsyncThunk('companyWorkShift/GetAllByWorkShiftId', async (workShiftId) => {
    const result = await apiInstance
        .get(`work-shift-service/work/get-all/by-work-shift-id/${workShiftId}`)
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                // toast.success("All User Fetch SuccessFully");
            }
            return response;
        })
        .catch(function (error) {
            toast.error('Get all work shifts fail');
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

export const AddUserWithWorkShiftId = createAsyncThunk('companyWorkShift/AddUserWithWorkShiftId', async (params) => {
    const { id, all_user } = params;
    const result = await apiInstance
        .post(`work-shift-service/work/create-all/by-work-shift-id/${id}`, all_user)
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                toast.success('User Added In the Work Shift Successfully');
            }
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

export const DeleteUserFromWorkShift = createAsyncThunk('companyWorkShift/DeleteUserFromWorkShift', async (params) => {
    // const { id, delId } = params;

    const result = await apiInstance
        .post(
            // `work-shift-service/work/delete-by-user-id/${delId}/by-work-shift-id/${id}`
            'work-shift-service/work/remove/work-shift-list/to-user-ids',
            params,
        )
        .then(function (response) {
            if (response?.status == 201 || response?.status == 200) {
                toast.success(t('has_been_deleted_successfully').toUpperCase());
            }
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

export const DeleteContractFromWorkShift = createAsyncThunk(
    'companyWorkShift/DeleteContractFromWorkShift',
    async (params) => {
        const result = await apiInstance
            .post('/work-shift-service/contractor-work/remove/work-shift-list/to-contractor-ids', params)
            .then(function (response) {
                if (response?.status == 201 || response?.status == 200) {
                    toast.success(t('has_been_deleted_successfully').toUpperCase());
                }
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

/* export const DeleteWorkSHiftTime = createAsyncThunk('companyWorkShift/DeleteWorkSHiftTime', async (id) => {
    const result = await apiInstance
        .delete(`work-shift-service/schedule/delete-by-id/${id}`)
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                toast.success('User Work Schedule Delete SuccessFully');
            }
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
}); */

export const DeleteWorkSHiftTime = createAsyncThunk('companyWorkShift/DeleteWorkSHiftTime', async (id) => {
    const result = await apiInstance
        .delete(`work-shift-service/contractor-work/delete-by-id/${id}`)
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                toast.success('User Work Schedule Delete SuccessFully');
            }
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

// details of provider employee
export const UpdateWorkShiftName = createAsyncThunk(
    'companyWorkShift/updateWorkShiftName',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .put('work-shift-service/update-name', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
