import React, { useEffect, useState, Fragment } from 'react';
import {
    Dialog,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
} from '@mui/material';
import { DatePicker, DateRangePicker, LocalizationProvider, DesktopDatePicker, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import {
    GetWorkTimeAccess,
    GetAllWorkSchdule,
} from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import {
    getWorkTimeAccess,
    getAllWorkSchdule,
} from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsSlice';
import TablePagination from '@mui/material/TablePagination';

import dayId from '../../../../../hooks/dayId';

import { GetZoneTree } from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import { getZonetree } from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsSlice';
import WorkShiftTreeView from '../../WorkShift/WorkShiftTreeView';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { CreateWorkShiftAccess } from '../../../../../reduxToolkit/CompanyWorkShift/CompanyWorkShiftApi';
import { toast } from 'react-toastify';
import DeleteIcon from '../../../../../assets/images/redTrash.svg';
import useStyle from '../../../../../hooks/useStyle';
import { blockInvalidNumericChar } from '../../../../../utils/blockInvalidNumericChar';
import { GetAllCardFormats } from '../../../../../reduxToolkit/Cards/CardsApi';
import { CreateSingleCard } from '../../../../../reduxToolkit/Cards/CardsApi';
import { CreateMultipleCards } from '../../../../../reduxToolkit/Cards/CardsApi';
import { AssignCardToUser } from '../../../../../reduxToolkit/Cards/CardsApi';
import { RemoveCardToUser } from '../../../../../reduxToolkit/Cards/CardsApi';
import { AnalizeCards } from '../../../../../reduxToolkit/Cards/CardsApi';

const AddCardModal = ({
    open,
    onClose,
    customizedList,
    setCustomizedList,
    loaded,
    setLoaded,
    creationErrorsShow,
    setCreationErrorsShow,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch();
    const { smallBoxStyle, textFieldCommon } = useStyle();

    const { getAllCardFormats } = useSelector((state) => state.CardsSlice);

    // use state hook  for local state managment

    const [checked, setChecked] = useState([]);
    const [facilityCode, setFacilityCode] = useState('');
    const [number, setNumber] = useState('');
    const [format, setFormat] = useState('');
    const [addRange, setAddRange] = useState(false);
    const [emptyFields, setEmptyFields] = useState(false);
    const [startingIn, setStartingIn] = useState('');
    const [endingIn, setEndingIn] = useState('');
    const [validStarting, setValidStarting] = useState(true);
    const [validEnding, setValidEnding] = useState(true);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [dayArray, setDayArray] = useState([]);
    // const [accessArray, setAccessArray] = useState([]);
    // const [accessArray, setAccessArray] = useState(customizedList);
    const [workshift, setWorkshift] = useState('');
    const [accessCustomized, setAccessCustomized] = useState([]);

    // Pagination
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderby, setOrderby] = useState('id');
    let contractPagination = {
        order: true,
        page: page,
        size: rowsPerPage,
        sortBy: orderby,
    };
    // End Pagination

    useEffect(() => {
        if (workshift) {
            dispatch(GetWorkTimeAccess({ id: workshift, contractPagination }));
        } else {
            return null;
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        dispatch(GetZoneTree());
    }, []);

    useEffect(() => {
        resetForm();
        dispatch(GetAllCardFormats());
        setEmptyFields(false);
        setValidEnding(true);
        setValidStarting(true);
    }, [open]);

    const resetForm = () => {
        setFacilityCode('');
        setNumber('');
        setStartingIn('');
        setEndingIn('');
        setFormat('');
        setAddRange(false);
    };

    //function to handle confirm
    const handleConfirm = () => {
        setEmptyFields(true);
        if (
            facilityCode !== '' &&
            ((!addRange && number !== '') ||
                (addRange && startingIn !== '' && endingIn !== '' && validEnding && validStarting)) &&
            format !== ''
        ) {
            if (addRange) {
                const body = {
                    facilityCode: facilityCode,
                    cardFormatId: format,
                    expiretAt: 0,
                    numbers: arrayNumbers(startingIn, endingIn),
                };
                dispatch(AnalizeCards(body)).then(
                    ({
                        payload: {
                            data: { data },
                        },
                    }) => {
                        if (data.length === 0) {
                            dispatch(CreateMultipleCards(body)).then(
                                ({
                                    payload: {
                                        data: { data, success },
                                    },
                                }) => {
                                    if (success === true) {
                                        onClose();
                                        setLoaded(true);
                                    }
                                },
                            );
                        } else {
                            setCreationErrorsShow(true);
                        }
                    },
                );
            } else {
                const body = {
                    facilityCode: facilityCode,
                    number: number,
                    cardFormatId: format,
                };
                dispatch(CreateSingleCard(body)).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            onClose();
                            setLoaded(true);
                        }
                    },
                );
            }
        }
    };

    const arrayNumbers = (starting, ending) => {
        const result = [];

        if (starting > ending) {
            return result;
        }

        for (let i = starting; i <= ending; i++) {
            result.push(i);
        }

        return result;
    };

    const validateStarting = (newValue) => {
        if (endingIn === '') {
            setValidStarting(true);
        } else {
            if (newValue === '') {
                setEndingIn('');
            } else if (newValue < parseInt(endingIn)) {
                setValidStarting(true);
            } else {
                setValidStarting(false);
            }
        }
    };

    const validateEnding = (newValue) => {
        if (parseInt(startingIn) < newValue) {
            setValidEnding(true);
        } else {
            setValidEnding(false);
        }
    };

    return (
        <Dialog className='add-workshift-enrollment-dialog' open={open} onClose={onClose} maxWidth='xs'>
            <DialogTitle className='dialog-title'>
                <p className='search-title'>{t('add_card')}</p>
                <img onClick={() => onClose()} className='dialog-close-custom' src={cancel} alt='' />
            </DialogTitle>

            <DialogContent className='dialog-content mt-0 pt-0'>
                <div className='pt-3 row pr-0'>
                    <p className='add-card-modal-text'>{t('fill_out_all_the_card_data')}</p>
                    <Box className='box-text-field required-data-text-form mt-3' sx={smallBoxStyle}>
                        <TextField
                            size='small'
                            error={emptyFields && (facilityCode === '' || Number(facilityCode) > 255)}
                            type='number'
                            fullWidth
                            required
                            onKeyDown={blockInvalidNumericChar}
                            label={t('facility_code')}
                            name='facility_code'
                            value={facilityCode || ''}
                            onFocus={(e) =>
                                e.target.addEventListener(
                                    'wheel',
                                    function (e) {
                                        e.preventDefault();
                                    },
                                    { passive: false },
                                )
                            }
                            onChange={(e) => {
                                const newValue = e.target.value;

                                if (Number(newValue) <= 255 || newValue === '') {
                                    setFacilityCode(newValue);
                                }
                            }}
                            id='model'
                            sx={textFieldCommon}
                            helperText={
                                emptyFields && (facilityCode === '' || Number(facilityCode) > 255)
                                    ? facilityCode === ''
                                        ? t('requiredField')
                                        : t('facilityCodeMax255')
                                    : ''
                            }
                        />
                    </Box>
                    <div className='d-flex gap-1 mt-1'>
                        <FormControlLabel
                            className='grid-checkall'
                            control={
                                <Checkbox
                                    label='Label'
                                    checked={addRange}
                                    onChange={() => {
                                        setAddRange(!addRange);
                                    }}
                                    size='small'
                                    id='cb_acm_checkbox'
                                />
                            }
                            label={t('add_range')}
                        />
                    </div>
                    {!addRange ? (
                        <Box className='box-text-field required-data-text-form mt-0' sx={smallBoxStyle}>
                            <TextField
                                size='small'
                                error={emptyFields && !addRange && number === ''}
                                type='number'
                                fullWidth
                                required
                                onKeyDown={blockInvalidNumericChar}
                                label={t('number')}
                                name='number'
                                value={number || ''}
                                onFocus={(e) =>
                                    e.target.addEventListener(
                                        'wheel',
                                        function (e) {
                                            e.preventDefault();
                                        },
                                        { passive: false },
                                    )
                                }
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    // validateModel(newValue);
                                    setNumber(e.target.value);
                                }}
                                id='txt_acm_number'
                                sx={textFieldCommon}
                                helperText={emptyFields && !addRange && number === '' ? t('requiredField') : ''}
                            />
                        </Box>
                    ) : (
                        <div className='row mx-0 mt-0'>
                            <Box className='col-6 box-text-field required-data-text-form mt-0 pl-0' sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    error={(emptyFields && startingIn === '' && addRange) || !validStarting}
                                    type='number'
                                    fullWidth
                                    onKeyDown={blockInvalidNumericChar}
                                    label={t('starting_in')}
                                    name='number'
                                    value={startingIn || ''}
                                    onFocus={(e) =>
                                        e.target.addEventListener(
                                            'wheel',
                                            function (e) {
                                                e.preventDefault();
                                            },
                                            { passive: false },
                                        )
                                    }
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        validateStarting(newValue);
                                        setStartingIn(e.target.value);
                                    }}
                                    id='txt_acm_format'
                                    sx={textFieldCommon}
                                    helperText={
                                        emptyFields && addRange && startingIn === ''
                                            ? t('requiredField')
                                            : validStarting
                                              ? ''
                                              : t('invalid_number')
                                    }
                                />
                            </Box>
                            <Box className='col-6 box-text-field required-data-text-form mt-0 pr-0' sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    error={(emptyFields && endingIn === '' && addRange) || !validEnding}
                                    type='number'
                                    fullWidth
                                    disabled={startingIn === ''}
                                    onKeyDown={blockInvalidNumericChar}
                                    label={t('ending_in')}
                                    name='number'
                                    value={endingIn || ''}
                                    // onWheelCapture={(e) => e.preventDefault()}
                                    onFocus={(e) =>
                                        e.target.addEventListener(
                                            'wheel',
                                            function (e) {
                                                e.preventDefault();
                                            },
                                            { passive: false },
                                        )
                                    }
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        validateEnding(newValue);
                                        setEndingIn(e.target.value);
                                    }}
                                    id='txt_acm_model'
                                    sx={textFieldCommon}
                                    helperText={
                                        emptyFields && endingIn === '' && addRange
                                            ? t('requiredField')
                                            : validEnding
                                              ? ''
                                              : t('invalid_number')
                                    }
                                />
                            </Box>
                        </div>
                    )}
                    <Box className='add_provider_text_field required-data-text-form mt-4'>
                        <FormControl fullWidth required sx={textFieldCommon} error={emptyFields && format === ''}>
                            <InputLabel id='format'>{t('format')}</InputLabel>
                            <Select
                                size='small'
                                labelId='genderId'
                                id='slt_acm_format'
                                label={t('format')}
                                value={format}
                                onChange={(e) => setFormat(e.target.value)}
                            >
                                {getAllCardFormats?.map((item, index) => {
                                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                })}
                            </Select>
                            {emptyFields && format === '' && (
                                <FormHelperText className='required-data-text-form'>
                                    {t('selectOptionText')}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                </div>

                <div>
                    <>
                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => {
                                    onClose();
                                }}
                                style={{ color: '#BC0000' }}
                                id='btn_acm_cancel'
                            >
                                {t('cancel')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                onClick={() => {
                                    handleConfirm();
                                }}
                                id='btn_acm_create'
                            >
                                {t('create').toUpperCase()}
                            </button>
                        </div>
                    </>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AddCardModal;

const inputBox = {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    height: '50px',
};
