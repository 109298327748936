import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { imageSize } from '../constant/variable';
import { useTranslation } from 'react-i18next';
import cloud from '../assets/images/cloud.svg';
import cancel from '../assets/images/ic-cancel.svg';

const UploadImageModal = (props) => {
    // destructure from prop
    const { titlen, setImage, preview, setPreview } = props;
    // title: title of your modal
    // setImage: image file for set image
    // preview: to see live preview of image
    // setPreview: to set preview image
    // use hook
    const { t } = useTranslation();

    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    // funtion to handle image upload
    const handleImageUpload = (event) => {
        event.map((file) => {
            const fileImage = file['type'].split('/')[0] === 'image';
            if (fileImage) {
                if (file?.size <= imageSize) {
                    setImage(file);
                    setPreview(URL.createObjectURL(file));
                } else {
                    toast.error(`${t('the_file_size_should_not_exceed').toUpperCase()} ${imageSize / 1024}KB.`);
                }
            } else {
                toast?.warn(t('please_select_a_file'));
            }
        });
    };
    // funtion to call api or handle image
    const handelUploadImageAction = () => {
        // right logic here
        if (selectedImage) {
            setPreview(URL.createObjectURL(selectedImage));
            props.onHide();
        } else {
            toast?.warn(t('please_select_a_file').toUpperCase());
        }
    };

    const handleClearPhoto = () => {
        setSelectedImage(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            fileInputRef.current.files = files;
            onFileChange({ target: fileInputRef.current });
        }
    };

    const onFileChange = (e) => {
        const selectedFiles = e.target.files;
        const imageRegex = /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/i; // Expresión regular para imágenes (jpg, jpeg, png, gif)

        if (selectedFiles && selectedFiles.length > 0) {
            if (!imageRegex.test(selectedFiles[0].name)) {
                toast.error(t('error_file_format').toUpperCase());
            } else if (selectedFiles[0].size >= imageSize) {
                toast.error(`${t('the_file_size_should_not_exceed').toUpperCase()} ${imageSize / 1024}KB.`);
            } else {
                setSelectedImage(selectedFiles[0]);
                setImage(selectedFiles[0]);
            }
        }
    };

    useEffect(() => {
        handleClearPhoto();
    }, [props.show]);

    return (
        <Modal
            {...props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className='files_upload_conatiner'
        >
            <Modal.Header className='header_top'>
                <Modal.Title id='contained-modal-title-vcenter '>{titlen}</Modal.Title>
                <div className='modal-close-custom'>
                    <img
                        onClick={() => {
                            props.onHide();
                        }}
                        src={cancel}
                        alt=''
                    />
                </div>
            </Modal.Header>

            <Modal.Body>
                <label
                    htmlFor='file-input'
                    className='dotted-border-box'
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <img src={cloud} alt='submitupload' />
                    <input type='file' ref={fileInputRef} id='file-input' accept='image/*' onChange={onFileChange} />
                    <div
                        className='drag-and-drop'
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                        <p>
                            {t('drag_your_file_or')} {/* <br /> */}
                            {t('click_to_upload')}
                            <br /> <span>{`${t('max_size')} ${imageSize / 1024}KB`}</span>
                        </p>
                    </div>
                </label>

                {selectedImage && (
                    <div className='d-flex justify-content-center'>
                        <img
                            src={URL.createObjectURL(selectedImage)}
                            style={{ maxHeight: '210px', maxWidth: '360px', objectFit: 'cover' }}
                            alt='Thumb'
                        />
                    </div>
                )}

                <div className='footer'>
                    <button
                        onClick={() => {
                            props.onHide();
                        }}
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '180px' }}
                    >
                        {t('cancel')}
                    </button>
                    <button
                        onClick={() => {
                            handelUploadImageAction();
                        }}
                        className='custom_primary_btn_dark'
                        style={{ width: '178px' }}
                    >
                        {t('add')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default UploadImageModal;
