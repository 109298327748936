import { Box, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DropDownMenuProfile from '../../components/DropDownMenuProfile';
import eyeIcon from '../../assets/eye-solid.svg';
import DeleteModal from '../Modals/DeleteModal';
import HolidayDetailModal from './component/HolidayDetailModal';
import TablePagination from '@mui/material/TablePagination';
import { useRef } from 'react';
import { useEffect } from 'react';
import cancelIcon from '../../assets/icon/cancelIcon.svg';
import checkIcon from '../../assets/icon/checkIcon.svg';
import { permissionObj } from '../../Helpers/permission';
import { useSelector } from 'react-redux';
import NotInformation from '../../components/NotInformation';

const Holiday = () => {
    const { t } = useTranslation();
    const [approveHoliday, setApproveHoliday] = useState(false);
    const [deniedHoliday, setDeniedHoliday] = useState(false);
    const [holidayDetail, setHolidayDetail] = useState(false);
    const [holidayDetailData, setHolidayDetailData] = useState('');
    const [topHeaderHeight, setTopHeaderHeight] = useState('');
    const headerSectionHeight = useRef(null);

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    useEffect(() => {
        if (headerSectionHeight.current) {
            const height = headerSectionHeight.current.clientHeight;
            setTopHeaderHeight(height);
        }
    }, []);
    return (
        <>
            <div className='top_section_wrapper' ref={headerSectionHeight}>
                <div className='head'>
                    <div className='headLeft'>
                        <h2>{t('holidays')?.toUpperCase()}</h2>
                    </div>

                    <div className='d-flex align-items-center gap-2'>
                        {permission?.includes(permissionObj?.WEB_HOLIDAY_CREATE) && (
                            <button
                                className='add-btn-1'
                                // disabled={selectDepartmentForDelete?.length > 0}
                                //     onClick={() => {
                                //         setAddShow(true)
                                //         setIsUpdate(false)
                                //     }}
                            >
                                <i className='fa fa-plus' aria-hidden='true'></i>
                                {t('add')}
                            </button>
                        )}

                        {permission?.includes(permissionObj?.WEB_HOLIDAY_DELETE) && (
                            <button
                                className='delete-btn-1'
                                // disabled={selectDepartmentForDelete?.length === 0}
                                // onClick={() => {
                                //     setDeletedepartments(true)
                                // }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <Box className='scroll_bar_fit_hight_wrapper' sx={{ height: `calc(100vh - ${topHeaderHeight + 70}px )` }}>
                <div className='holiday_table animated-div px-1 mt-1' style={{ width: '100%', paddingTop: '0rem' }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].length > 0 ? (
                        <table className='w-100'>
                            <thead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                <th className='first_head'>
                                    <Tooltip title={t('de_/_select_all').toUpperCase()} placement='top'>
                                        <Checkbox
                                            className='grid-checkall checkbox'
                                            // checked={isAllChecked}
                                            // onChange={handelDeleteAll}
                                            size='small'
                                        />
                                    </Tooltip>

                                    {t('employee')}
                                </th>
                                <th>{t('from')}</th>
                                <th>{t('to')}</th>
                                <th>{t('requested_at')}</th>
                                <th>{t('total_days')}</th>
                                <th>{t('status')}</th>
                                <th>{t('details')}</th>
                                <th className='last'>{t('option')}</th>
                            </thead>
                            <tbody>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='first align-middle'>
                                                <Checkbox
                                                    // onClick={(event) => event.stopPropagation()}
                                                    className='grid-checkall checkbox'
                                                    // checked={selectRoleForDelete?.includes(item?.id)}
                                                    // id={item?.id}
                                                    // onChange={handleCheckboxChange}
                                                    size='small'
                                                />
                                                Luis Enrique Cornejo Arreola
                                            </td>
                                            <td style={{ maxWidth: 250 }}>10/01/2023</td>
                                            <td>20/01/2023</td>
                                            <td>01/01/2023 00:00:00</td>
                                            <td style={{ maxWidth: 250 }}> 10 DÍAS </td>
                                            <td style={{ fontWeight: 'bold', color: '#707070' }}>
                                                PENDINGT TO VALIDATE
                                            </td>
                                            <td>
                                                <IconButton
                                                    onClick={() => {
                                                        setHolidayDetailData('data here');
                                                        setHolidayDetail(true);
                                                    }}
                                                >
                                                    <img src={eyeIcon} alt='eye' />
                                                </IconButton>
                                            </td>
                                            <td className='last_tr'>
                                                <DropDownMenuProfile
                                                    menuList={[
                                                        {
                                                            name: t('approve_holiday'),
                                                            icon: (
                                                                <img
                                                                    src={checkIcon}
                                                                    alt='check'
                                                                    width='20px'
                                                                    height='18px'
                                                                />
                                                            ),
                                                            onClick: () => {
                                                                setApproveHoliday(true);
                                                            },
                                                        },
                                                        {
                                                            name: t('denied_holiday'),
                                                            icon: (
                                                                <img
                                                                    src={cancelIcon}
                                                                    alt='cancle'
                                                                    width='18'
                                                                    height='18px'
                                                                />
                                                            ),
                                                            onClick: () => {
                                                                setDeniedHoliday(true);
                                                            },
                                                        },
                                                    ].filter(Boolean)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <NotInformation text={t('no_information')} card={true} />
                    )}
                </div>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    left: '45%',
                    bottom: '0',
                }}
            >
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[10, 15, 20, 30]}
                    // count={roleList?.totalElements}
                    // page={page}
                    // onPageChange={(_, newPage) => {
                    //     setPage(newPage);
                    // }}
                    labelRowsPerPage={t('holiday_per_page')}
                    // rowsPerPage={rowsPerPage}
                    // onRowsPerPageChange={event => {
                    //     setRowsPerPage(parseInt(event.target.value));
                    //     setPage(0);
                    // }}
                />
            </Box>
            <DeleteModal
                show={approveHoliday}
                onHide={() => setApproveHoliday(false)}
                // data={selectedDeviceForDelete}
                // onClickFn={() => deleteDevice(selectedDeviceForDelete)}
                title_modal={t('approve')}
                // element_modal={t("device")}
                description='Do you want to approve the holidays? To apply the action, please confirm it'
                // isReset={setSelectDeviceForDelete}
                // isAllReset={setIsAllCheckedDevice}
            />
            <DeleteModal
                show={deniedHoliday}
                onHide={() => setDeniedHoliday(false)}
                // data={selectedDeviceForDelete}
                // onClickFn={() => deleteDevice(selectedDeviceForDelete)}
                title_modal={t('denied')}
                // element_modal={t("device")}
                description='Do you want to denied the holiday? To apply the action, please confirm it.'
                // isReset={setSelectDeviceForDelete}
                // isAllReset={setIsAllCheckedDevice}
            />
            <HolidayDetailModal
                show={holidayDetail}
                onHide={() => setHolidayDetail(false)}
                titleModal={'holiday_details'}
                data={holidayDetailData}
            />
        </>
    );
};

export default Holiday;
