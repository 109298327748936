import { FormControl, InputLabel, MenuItem, Select, TextField, Grid } from '@mui/material';
import SelectInput from '@mui/material/Select/SelectInput';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import { userTypeDrop } from '../../../../../enums/userTypeEnumDrop';
import { Col, Container, Dropdown, DropdownButton, Form, Modal, Row, Tab, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ApproveExternalDocument } from '../../../../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { ApproveCompanyEmployeeDocument } from '../../../../../reduxToolkit/Employee/EmployeeApi';
import { ApproveContractorDocument } from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import {
    ApproveOrDeniedVehicleContratorDocs,
    ApproveOrDeniedVehicleSupplierDocs,
} from '../../../../../reduxToolkit/ApproveAndDeniedVehiclesDocs/ApproveAndDeniedVehicleDocsApi';

const DenyDocumentModal = (props) => {
    const [commentText, setCommentText] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.show) {
            setCommentText('');
        }
    }, [props.show]);

    return (
        <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered className='approveModal'>
            <img onClick={props.onHide} className='dialog-close-custom' src={cancel} alt='' />
            <span className='main-modal-heading'>{t('deny_document')}</span>
            <div className='unlink-modal-body'>
                <span className='modal-desc-text'>
                    {t('are_you_sure_you_want_to_deny_the_document')}
                    <strong>{' ' + props.documentName}</strong>
                    {' ' + t('for_the')}
                    <strong>{' ' + t(userTypeDrop[props.userType])}</strong>
                    <strong>{' ' + props.employeeName}</strong>
                    {'? ' + t('enter_a_comment_to_guide_the_user')}
                </span>

                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ marginTop: '20px' }}>
                        <TextField
                            sx={{ width: '185%' }}
                            size='small'
                            // fullWidth
                            label={t('comments')}
                            id='description'
                            multiline
                            rows={2}
                            maxRows={4}
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            InputLabelProps={{
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    background: '#ffffff',
                                    padding: '0px 8px 0px 8px',
                                },
                            }} // font size of input label
                            inputProps={{
                                sx: {
                                    border: 'none',
                                    outline: 'none',
                                    fontSize: '14px',
                                    letterSpacing: '0px',
                                    color: '#707070',
                                    '&::placeholder': {
                                        color: '#707070',
                                        fontSize: '8px',
                                    },
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <div className='btn-div'>
                    <button className='button-sec btn-cancel' style={{ color: 'red' }} onClick={props.onHide}>
                        {t('cancel').toUpperCase()}
                    </button>
                    <button
                        className='button-sec btn-confirm'
                        onClick={() => {
                            const data = {
                                comments: commentText,
                                id: props?.documentId,
                                validated: false,
                            };

                            if (props.isVehicleDocuments === true) {
                                // ¿Es un documento de vehiculo?
                                try {
                                    // Función si es un documento de vehículo de contrator
                                    dispatch(ApproveOrDeniedVehicleContratorDocs(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('approved_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                                toast.error(t('error_supplier_approved').toUpperCase());
                                            }
                                        },
                                    );
                                } catch (error) {
                                    // Función por si el try principal no funciona, que se ejecute
                                    // el de provider
                                    dispatch(ApproveOrDeniedVehicleSupplierDocs(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('approved_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                                toast.error(t('error_supplier_approved').toUpperCase());
                                            }
                                        },
                                    );
                                }
                            } else {
                                if (props.userType === 1) {
                                    dispatch(ApproveCompanyEmployeeDocument(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('denied_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                            }
                                        },
                                    );
                                } else if (props.userType === 3 || props.userType === 4) {
                                    dispatch(ApproveContractorDocument(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('denied_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                            }
                                        },
                                    );
                                } else if (props.userType === 5 || props.userType === 6) {
                                    dispatch(ApproveExternalDocument(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('denied_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                            }
                                        },
                                    );
                                }
                            }
                            props.onHide();
                        }}
                    >
                        {t('confirm')?.toUpperCase()}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default DenyDocumentModal;
