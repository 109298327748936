import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstanceID } from '../../Apis/Axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';

// /get-by-user-id/{userId}provider-module in provider-controller
export const GetHidRecordDetails = createAsyncThunk(
    'hid/getHidRecordDetails',
    async (params, { dispatch, getState }) => {
        const { id } = params;
        const result = await apiInstanceID
            .get(`/hid-service/v1/get-by-id/${id}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
export const GetAllStatusForDevice = createAsyncThunk(
    'hid/getAllStatusForDevice',
    async (params, { dispatch, getState }) => {
        const result = await apiInstanceID
            .get('status-service/get-all-to-company')
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
export const CreateHidRecord = createAsyncThunk('hid/createHidRecord', async (body) => {
    const response = await apiInstanceID
        .post('hid-service/v1/create', body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});

export const GetSio = createAsyncThunk('hid/getSio', async (params, { dispatch, getState }) => {
    const result = await apiInstanceID
        .get('/hid-service/v1/sio/get-free')
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});
// /get-by-user-id/{userId}provider-module in provider-controller
export const GetAllSiosByHidId = createAsyncThunk('hid/getAllSiosByHidId', async (params, { dispatch, getState }) => {
    const { hidId } = params;
    const result = await apiInstanceID
        .get(`hid-service/v1/sio/get/by-hid-id/${hidId}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});
export const CreateHidSioRecord = createAsyncThunk('hid/createHidSioRecord', async (body) => {
    const response = await apiInstanceID
        .post('hid-service/v1/sio/create', body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
export const CreateHidInput = createAsyncThunk('hid/createHidInput', async (body) => {
    const response = await apiInstanceID
        .post('hid-service/v1/input/create', body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
export const CreateFormat = createAsyncThunk('hid/createFormat', async (body) => {
    const response = await apiInstanceID
        .post('hid-service/v1/format/create', body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
export const CreateHidReader = createAsyncThunk('hid/createHidReader', async (body) => {
    const response = await apiInstanceID
        .post('hid-service/v1/reader/create', body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});

export const GetAllZones = createAsyncThunk('hid/getAllZones', async () => {
    const result = await apiInstanceID
        .get('zone-service/get-all')
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

export const UpdateHidRecord = createAsyncThunk('hid/updateHidRecord', async (body, { dispatch, getState }) => {
    const result = await apiInstanceID
        .put('hid-service/v1/update', body)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});
export const DeleteRecordsByTableAndIds = createAsyncThunk(
    'hid/deleteRecordsByTableAndIds',
    async ({ table, body }, { dispatch, getState }) => {
        const result = await apiInstanceID
            .post(`assets-service/delete-by-ids/by-table/${table}`, body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const AdvanceSearchByFilters = createAsyncThunk(
    'hid/advanceSearchByFilters',
    async ({ option, body }, { dispatch, getState }) => {
        window.postMessage(
            {
                /*  type: 'sessionStorageChangeLoader',
                newValueLoading: true, */
            },
            '*',
        );
        let result = await apiInstanceID
            .post(`advanced-search-service/${option}`, body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

export const ConnectWithHid = createAsyncThunk('hid/connectWithHid', async (params, { dispatch, getState }) => {
    const { id } = params;
    const result = await apiInstanceID
        .get(`hid-service/v1/connect/${id}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});
export const SendInformationSetting = createAsyncThunk(
    'hid/sendInformationSetting',
    async (params, { dispatch, getState }) => {
        const { id } = params;
        const result = await apiInstanceID
            .get(`hid-service/v1/settings/${id}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
export const ResetHid = createAsyncThunk('hid/resetHid', async (params, { dispatch, getState }) => {
    const { id } = params;
    const result = await apiInstanceID
        .delete(`hid-service/v1/reset/${id}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});
