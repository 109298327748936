/*
Author : Arman Ali
Module: create Vehicle
github: https://github.com/Arman-Arzoo
*/
import React, { useEffect, useState, useRef } from 'react';
import SidebarDropDownOption from '../../components/SidebarDropDownOption';
import payrollIcon from '../../assets/payrollBlackIcon.svg';
import eventicon from '../../assets/eventblackicon.svg';
import emoloyeeicon from '../../assets/employeeblackicon.svg';
import vehicleicon from '../../assets/vehicleblackicon.svg';
import contractoricon from '../../assets/contractorblackicon.svg';
import providericon from '../../assets/providerblackicon.svg';
import backupicon from '../../assets/backupblackicon.svg';
import holidayIcon from '../../assets/loginMenuIcon/holiday.svg';
import logsIcon from '../../assets/loginMenuIcon/file_lines_regular.svg';
import workOfficeIcon from '../../assets/loginMenuIcon/work_office.svg';
import profileicon from '../../assets/profileblackicon.svg';
import accessRicon from '../../assets/images/access_right.svg';
import logo from '../../assets/loginoptionweblogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RoleCheck } from '../../reduxToolkit/authentication/AuthenticatonApi';
import jwtDecode from 'jwt-decode';
import { GetProvidersByUserId, GetUserDocuments } from '../../reduxToolkit/Providers/providersApi';
import { GetUserDocumentsEmployee } from '../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';

import attendenceIcon from '../../assets/loginMenuIcon/attendance.svg';
import guardIcon from '../../assets/loginMenuIcon/guard_icon.svg';
import companyIcon from '../../assets/loginMenuIcon/company.svg';
import contractorIcon from '../../assets/loginMenuIcon/contractor.svg';
import databaseIcon from '../../assets/loginMenuIcon/database.svg';
import employeeIcons from '../../assets/loginMenuIcon/employees.svg';
import eventIcon from '../../assets/loginMenuIcon/events.svg';
import provfileIcon from '../../assets/loginMenuIcon/profile.svg';
import supplierIcon from '../../assets/loginMenuIcon/supplier.svg';
import vehicleIcon from '../../assets/loginMenuIcon/vehicles.svg';
import firstAccessIcon from '../../assets/loginMenuIcon/first_access.svg';
import cardsIcon from '../../assets/loginMenuIcon/cards-icon.svg';
import accessIcon from '../../assets/loginMenuIcon/access.svg';
import privilageSettingsIcon from '../../assets/loginMenuIcon/privilage_settings.svg';
import onBoardingIcon from '../../assets/loginMenuIcon/on_boarding.svg';
import departmentIcon from '../../assets/loginMenuIcon/departments.svg';
import workShiftIcons from '../../assets/loginMenuIcon/workshift_panel.svg';
import controllerIcons from '../../assets/loginMenuIcon/controllerIcons.svg';
import userDocPanelIcon from '../../assets/loginMenuIcon/user_document_panel.svg';
import vehicleDocPanelIcon from '../../assets/loginMenuIcon/vehicle_document_panel.svg';
import emailSettingIcon from '../../assets/loginMenuIcon/email_service_setting.svg';
import deviceIcon from '../../assets/loginMenuIcon/device.svg';
import icAnnnouncementIcon from '../../assets/loginMenuIcon/ic-announcement.svg';
import graphIcon from '../../assets/home/barchart.svg';
import brushIcon from '../../assets/loginMenuIcon/brush-solid.svg';
import cryptoJs from 'crypto-js';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import securekey from '../../config';
import { permissionObj } from '../../../src/Helpers/permission';
import vehiclesImg from '../../assets/home/VehiclesImg.png';
import employeesImg from '../../assets/home/EmployeesImg.png';
import firsAccessImg from '../../assets/home/FirstAccessImg.png';
import devicesImg from '../../assets/home/DevicesImg.png';
import attendanceImg from '../../assets/home/AttendanceImg.png';
import departmentsImg from '../../assets/home/DepartmentsImg.png';
import eventsImg from '../../assets/home/EventsImg.png';
import controllerImg from '../../assets/home/controllerImg.jpg';
import workshiftImg from '../../assets/home/WorkshiftImg.png';
import accessImg from '../../assets/home/AccessImg.png';
import privilegeImg from '../../assets/home/PrivilegeImg.png';
import onboardingImg from '../../assets/home/OnboardingImg.png';
import contractorsImg from '../../assets/home/ContractorsImg.png';
import suppliersImg from '../../assets/home/SuppliersImg.png';
import userDocumentsImg from '../../assets/home/UserDocumentsImg.png';
import vehicleDocumentsImg from '../../assets/home/VehicleDocumentsImg.png';
import emailSettingsImg from '../../assets/home/EmailSettingsImg.png';
import backupImg from '../../assets/home/BackupImg.png';
import profileImg from '../../assets/home/ProfileImg.png';
import accessRImg from '../../assets/home/AccessImg.png';
import companyImg from '../../assets/home/CompanyProfileImg.png';
import announcementImg from '../../assets/home/AnnouncementImg.png';
import graphImg from '../../assets/home/graphImg.jpg';
import cardDesignImg from '../../assets/home/CardDesignImg.png';
import guardImg from '../../assets/home/GuardImg.png';
import holidaysImg from '../../assets/home/HolidaysImg.png';
import homeOfficeImg from '../../assets/home/HomeOfficeImg.png';
import logsImg from '../../assets/home/LogsImg.png';

import { GetUserFullInformation } from '../../reduxToolkit/Commons/CommonsApi';

const LoginOptions = () => {
    const [changeStyle, setChangeStyle] = useState('company');
    const [isHovering, setIsHovering] = useState('1');
    const [hover, setHover] = useState({ isMenu: false });

    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const Etoken = sessionStorage.getItem('bearerToken') || '';
    const bytes = cryptoJs.AES.decrypt(Etoken, securekey);
    const token = bytes.toString(cryptoJs.enc.Utf8);

    // const { user } = useSelector(state => state.authenticatioauthennSlice);
    const { user, permission } = useSelector((state) => state.authenticatioauthennSlice);

    const { getProvidersByUserId } = useSelector((state) => state.providersSlice);
    const { getContractorsByUserId } = useSelector((state) => state.ContractorSlice);
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        if (token) {
            const tokeninfo = jwtDecode(token);
            const data = {
                roleId: tokeninfo?.role_id,
            };
            dispatch(RoleCheck(data));
        }
        dispatch(GetUserFullInformation(sessionStorage.getItem('id')));
    }, []);

    useEffect(() => {
        const userType = user?.data?.userType?.name;
        if (userType == 'SUPPLIER_IN_CHARGE') {
            dispatch(GetUserDocuments(user?.data?.id));

            if (user?.data?.status?.id === 3) {
                navigate('/dashboard/supplier/user-Documents');
            }
        }
        if (userType == 'CONTRACTOR_IN_CHARGE') {
            dispatch(GetUserDocuments(user?.data?.id));

            if (user?.data?.status?.id === 3) {
                navigate('/dashboard/contractor/user-Documents');
            }
        }
        if (userType == 'EMPLOYEE') {
            dispatch(GetUserDocumentsEmployee(user?.data?.id));

            if (user?.data?.status?.id === 3) {
                navigate('/dashboard/employee/user-Documents');
            }
        }
    }, [user?.data?.id]);

    useEffect(() => {
        const iconBaseGridElements = document.querySelectorAll('.icon-base-grid');
        const imageContainer = document.getElementById('imageContainer');
        const imageContainerBackgroundNo = document.querySelector('.image-container-background-no');
        const imageContainerBackground = document.querySelector('.image-container-background');

        let bg;

        // Handle function for mouseover
        const handleMouseOver = (el) => {
            bg = el.getAttribute('data-bg');
            if (imageContainer) {
                imageContainer.style.backgroundImage = bg;
            }
        };

        // Handle function for mouseout
        const handleMouseOut = () => {
            if (imageContainer) {
                imageContainer.style.backgroundImage = bg;
            }
        };

        iconBaseGridElements.forEach((el) => {
            el.addEventListener('mouseover', () => handleMouseOver(el));
            el.addEventListener('mouseout', handleMouseOut);
        });
        if (renderCount < 4) {
            setRenderCount((prevCount) => prevCount + 1);
        }

        // Cleanup function to unmount event listeners
        const cleanup = () => {
            iconBaseGridElements.forEach((el) => {
                el.removeEventListener('mouseover', () => handleMouseOver(el));
                el.removeEventListener('mouseout', handleMouseOut);
            });
        };
        // Clear event listeners when component is unmounted
        return cleanup;
    }, [hover, renderCount]);

    return (
        <div className='overflow-hidden'>
            <div className='login_option_container'>
                <div className='login_option_header mr-0'>
                    <SidebarDropDownOption changestyle={changeStyle} hovereffect={isHovering} menutop={true} />
                </div>

                <div className='login_option_menus position-relative'>
                    <div
                        id='imageContainer'
                        className={`${
                            hover.isMenu ? 'image-container-background-no' : 'image-container-background'
                        } image-container-transition`}
                    >
                        <div className='mask-image-background-login-1'></div>
                    </div>
                    <div className='overlay_mask_menu'>
                        <div className='menu_overflow_control w-100'>
                            {user?.data?.userType?.name === 'EMPLOYEE' && (
                                <>
                                    <div className='grid-menu-icon-container full-container mx-0'>
                                        <div className='grid-menu-icon-row row mt-5 mx-3'>
                                            {user?.data?.status?.id !== 3 && (
                                                <>
                                                    {permission?.includes(permissionObj?.WEB_COMPANY_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'COMPANY'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${companyImg}")`}
                                                                onClick={() => {
                                                                    navigate('/dashboard/employee/company/update-data');
                                                                    sessionStorage.setItem('sidebarOption', 'company');
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'COMPANY',
                                                                        url: '/dashboard/employee/company/update-data',
                                                                        isMenu: true,
                                                                        img: companyIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={companyIcon} alt='' />
                                                                <p>{t('company')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj?.WEB_ZONE_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'ZONES'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${firsAccessImg}")`}
                                                                onClick={() => {
                                                                    navigate('/dashboard/employee/zones');
                                                                    sessionStorage.setItem('sidebarOption', 'zones');
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'ZONES',
                                                                        url: '/dashboard/employee/zones',
                                                                        isMenu: true,
                                                                        img: firstAccessIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={firstAccessIcon} alt='' />
                                                                <p>{t('first_access')}</p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {permission?.includes(permissionObj.WEB_DEVICE_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'device setting'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${devicesImg}")`}
                                                                onClick={() => navigate('/dashboard/employee/devices')}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'device setting',
                                                                        url: '',
                                                                        isMenu: true,
                                                                        img: deviceIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={deviceIcon} alt='' />
                                                                <p>{t('devices')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_ATTENDANCE_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'PAYROLL'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${attendanceImg}")`}
                                                                onClick={() =>
                                                                    navigate('/dashboard/employee/attendance')
                                                                }
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'PAYROLL',
                                                                        url: '/dashboard/employee/attendance',
                                                                        isMenu: true,
                                                                        img: attendenceIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={payrollIcon} alt='' />
                                                                <p>{t('attendance')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_DEPARTMENT_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'DEPARTMENTS'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${departmentsImg}")`}
                                                                onClick={() =>
                                                                    navigate('/dashboard/employee/departments')
                                                                }
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'DEPARTMENTS',
                                                                        url: '/dashboard/employee/departments',
                                                                        isMenu: true,
                                                                        img: departmentIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={departmentIcon} alt='' />
                                                                <p>{t('departments')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_EMPLOYEE_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'EMPLOYEES'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${employeesImg}")`}
                                                                onClick={() => {
                                                                    navigate('/dashboard/employee/all-employees');
                                                                    sessionStorage.setItem(
                                                                        'sidebarOption',
                                                                        'employees',
                                                                    );
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'EMPLOYEES',
                                                                        url: '/dashboard/employee/all-employees',
                                                                        isMenu: true,
                                                                        img: employeeIcons,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={emoloyeeicon} alt='' />
                                                                <p>{t('employees')}</p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {permission?.includes(permissionObj.WEB_VEHICLE_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'VEHICLES'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${vehiclesImg}")`}
                                                                onClick={() => {
                                                                    navigate('/dashboard/employee/vehicle-fleet');
                                                                    sessionStorage.setItem('sidebarOption', 'vehicles');
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'VEHICLES',
                                                                        url: '/dashboard/employee/vehicle-fleet',
                                                                        isMenu: true,
                                                                        img: vehicleIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={vehicleicon} alt='' />
                                                                <p>{t('vehicle_fleet')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_EVENT_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'EVENTS'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${eventsImg}")`}
                                                                onClick={() => {
                                                                    navigate('/dashboard/employee/events');
                                                                    sessionStorage.setItem('sidebarOption', 'events');
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'EVENTS',
                                                                        url: '/dashboard/employee/events',
                                                                        isMenu: true,
                                                                        img: eventIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={eventicon} alt='' />
                                                                <p>{t('events')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_HID_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'CONTROLLER'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${controllerImg}")`}
                                                                onClick={() => {
                                                                    navigate('/dashboard/employee/controller');
                                                                    sessionStorage.setItem(
                                                                        'sidebarOption',
                                                                        'controller',
                                                                    );
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'CONTROLLER',
                                                                        url: '/dashboard/employee/controller',
                                                                        isMenu: true,
                                                                        img: controllerIcons,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={controllerIcons} alt='' />
                                                                <p>{t('controller')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_WORK_SHIFT_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'WORK SHIFT PANEL'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${workshiftImg}")`}
                                                                onClick={() =>
                                                                    navigate(
                                                                        '/dashboard/employee/company/workshift-panel',
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'WORK SHIFT PANEL',
                                                                        url: '/dashboard/employee/company/workshift-panel',
                                                                        isMenu: true,
                                                                        img: workShiftIcons,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={workShiftIcons} alt='' />
                                                                <p>{t('work_shift_panel')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(permission?.includes(permissionObj?.WEB_ACCESS_USER_READ) ||
                                                        permission?.includes(
                                                            permissionObj?.WEB_ACCESS_VEHICLE_READ,
                                                        )) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'access'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${accessImg}")`}
                                                                onClick={() => navigate('/dashboard/employee/access')}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'access',
                                                                        url: '',
                                                                        isMenu: true,
                                                                        img: accessIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={accessIcon} alt='' />
                                                                <p>{t('access')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_PRIVILEGE_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'privilage settings'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${privilegeImg}")`}
                                                                onClick={() =>
                                                                    navigate('/dashboard/employee/company/roles-panel')
                                                                }
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'privilage settings',
                                                                        url: '',
                                                                        isMenu: true,
                                                                        img: privilageSettingsIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={privilageSettingsIcon} alt='' />
                                                                <p>{t('privilage_settings')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_ONBOARDING_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'onBoarding'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${onboardingImg}")`}
                                                                onClick={() =>
                                                                    navigate('/dashboard/employee/company/onboarding')
                                                                }
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'onBoarding',
                                                                        url: '',
                                                                        isMenu: true,
                                                                        img: onBoardingIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={onBoardingIcon} alt='' />
                                                                <p>{t('on_boarding')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(permission?.includes(permissionObj.WEB_CONTRACTOR_READ) ||
                                                        permission?.includes(
                                                            permissionObj.WEB_CONTRACTOR_CONTRACT_READ,
                                                        )) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'CONTRACTORS'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${contractorsImg}")`}
                                                                onClick={() => {
                                                                    navigate('/dashboard/employee/contractors');
                                                                    sessionStorage.setItem(
                                                                        'sidebarOption',
                                                                        'contractor',
                                                                    );
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'CONTRACTORS',
                                                                        url: '/dashboard/employee/contractors',
                                                                        isMenu: true,
                                                                        img: contractorIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={contractoricon} alt='' />
                                                                <p>{t('contractors')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(permission?.includes(permissionObj.WEB_SUPPLIER_READ) ||
                                                        permission?.includes(
                                                            permissionObj.WEB_SUPPLIER_ORDER_READ,
                                                        )) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'PROVIDERS'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${suppliersImg}")`}
                                                                onClick={() => {
                                                                    navigate('/dashboard/employee/suppliers');
                                                                    sessionStorage.setItem('sidebarOption', 'provider');
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'PROVIDERS',
                                                                        url: '/dashboard/employee/suppliers',
                                                                        isMenu: true,
                                                                        img: supplierIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={providericon} alt='' />
                                                                <p>{t('suppliers')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(permission?.includes(permissionObj.WEB_SUPPLIER_DOCUMENT_READ) ||
                                                        permission?.includes(
                                                            permissionObj.WEB_EMPLOYEE_DOCUMENT_READ,
                                                        ) ||
                                                        permission?.includes(
                                                            permissionObj.WEB_CONTRACTOR_DOCUMENT_READ,
                                                        )) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'user document manager'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${userDocumentsImg}")`}
                                                                onClick={() =>
                                                                    navigate(
                                                                        '/dashboard/employee/company/user-doc-panel',
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'user document manager',
                                                                        url: '',
                                                                        isMenu: true,
                                                                        img: userDocPanelIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={userDocPanelIcon} alt='' />
                                                                <p>{t('user_document_manager')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(permission?.includes(
                                                        permissionObj.WEB_SUPPLIER_VEHICLE_DOCUMENT_READ,
                                                    ) ||
                                                        permission?.includes(
                                                            permissionObj.WEB_CONTRACTOR_VEHICLE_DOCUMENT_READ,
                                                        )) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'vehicle document manager'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${vehicleDocumentsImg}")`}
                                                                onClick={() =>
                                                                    navigate(
                                                                        '/dashboard/employee/company/vehicle-doc-panel',
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'vehicle document manager',
                                                                        url: '',
                                                                        isMenu: true,
                                                                        img: vehicleDocPanelIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={vehicleDocPanelIcon} alt='' />
                                                                <p>{t('vehicle_document_manager')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj?.WEB_EMAIL_SETTINGS_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'email setting'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${emailSettingsImg}")`}
                                                                onClick={() =>
                                                                    navigate(
                                                                        '/dashboard/employee/attendance/email-setting',
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'email setting',
                                                                        url: '',
                                                                        isMenu: true,
                                                                        img: emailSettingIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={emailSettingIcon} alt='' />
                                                                <p>{t('email_service_settings')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {permission?.includes(permissionObj.WEB_BACK_UP_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'BACK UP'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${backupImg}")`}
                                                                onClick={() => navigate('/dashboard/employee/backup')}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'BACK UP',
                                                                        url: '/dashboard/employee/backup',
                                                                        isMenu: true,
                                                                        img: databaseIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={backupicon} alt='' />
                                                                <p>{t('back_up')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/* {permission?.includes(permissionObj.WEB_HOLIDAY_READ) && (
                                                        <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                            <div
                                                                className={`${
                                                                    hover.menu === 'holiday'
                                                                        ? 'icon-base-grid-hovered'
                                                                        : 'icon-base-grid'
                                                                }`}
                                                                data-bg={`url("${holidaysImg}")`}
                                                                onClick={() => navigate('/dashboard/employee/holiday')}
                                                                onMouseEnter={() =>
                                                                    setHover({
                                                                        menu: 'holiday',
                                                                        url: '/dashboard/employee/holiday',
                                                                        isMenu: true,
                                                                        img: databaseIcon,
                                                                    })
                                                                }
                                                                onMouseLeave={() => setHover({})}
                                                            >
                                                                <img src={holidayIcon} alt='holiday' />
                                                                <p>{t('holidays')}</p>
                                                            </div>
                                                        </div>
                                                    )} */}
                                                </>
                                            )}
                                            {permission?.includes(permissionObj.WEB_HOME_OFFICE_READ) && (
                                                <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                    <div
                                                        className={`${
                                                            hover.menu === 'work_office'
                                                                ? 'icon-base-grid-hovered'
                                                                : 'icon-base-grid'
                                                        }`}
                                                        data-bg={`url("${homeOfficeImg}")`}
                                                        onClick={() => navigate('/dashboard/employee/work-office')}
                                                        onMouseEnter={() =>
                                                            setHover({
                                                                menu: 'work_office',
                                                                url: '/dashboard/employee/work-office',
                                                                isMenu: true,
                                                                img: databaseIcon,
                                                            })
                                                        }
                                                        onMouseLeave={() => setHover({})}
                                                    >
                                                        <img src={workOfficeIcon} alt='work_office' />
                                                        <p>{t('home_office')}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {permission?.includes(permissionObj.WEB_CARD_DESIGNER_READ) && (
                                                <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                    <div
                                                        className={`${
                                                            hover.menu === 'card_designer'
                                                                ? 'icon-base-grid-hovered'
                                                                : 'icon-base-grid'
                                                        }`}
                                                        data-bg={`url("${cardDesignImg}")`}
                                                        onClick={() => navigate('/dashboard/employee/card-designer')}
                                                        onMouseEnter={() =>
                                                            setHover({
                                                                menu: 'card_designer',
                                                                url: '/dashboard/employee/card-designer',
                                                                isMenu: true,
                                                                img: brushIcon,
                                                            })
                                                        }
                                                        onMouseLeave={() => setHover({})}
                                                    >
                                                        <img src={brushIcon} alt='card_designer' />
                                                        <p>{t('card_designer')}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {permission?.includes(permissionObj.WEB_LOG_PLATFORM_READ) && (
                                                <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                    <div
                                                        className={`${
                                                            hover.menu === 'LOGS'
                                                                ? 'icon-base-grid-hovered'
                                                                : 'icon-base-grid'
                                                        }`}
                                                        data-bg={`url("${logsImg}")`}
                                                        onClick={() => navigate('/dashboard/employee/logs')}
                                                        onMouseEnter={() =>
                                                            setHover({
                                                                menu: 'LOGS',
                                                                url: '/dashboard/employee/logs',
                                                                isMenu: true,
                                                                img: logsIcon,
                                                            })
                                                        }
                                                        onMouseLeave={() => setHover({})}
                                                    >
                                                        <img src={logsIcon} alt='' />
                                                        <p>{t('logs')}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {permission?.includes(permissionObj.WEB_GUARD_READ) && (
                                                <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                    <div
                                                        className={`${
                                                            hover.menu === 'GUARD'
                                                                ? 'icon-base-grid-hovered'
                                                                : 'icon-base-grid'
                                                        }`}
                                                        data-bg={`url("${guardImg}")`}
                                                        onClick={() => navigate('/dashboard/employee/guard')}
                                                        onMouseEnter={() =>
                                                            setHover({
                                                                menu: 'GUARD',
                                                                url: '/dashboard/employee/guard',
                                                                isMenu: true,
                                                                img: guardIcon,
                                                            })
                                                        }
                                                        onMouseLeave={() => setHover({})}
                                                    >
                                                        <img src={guardIcon} alt='' />
                                                        <p>{t('guard')}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {permission?.includes(permissionObj.WEB_ANNOUNCEMENT_READ) && (
                                                <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                    <div
                                                        className={`${
                                                            hover.menu === 'announcements'
                                                                ? 'icon-base-grid-hovered'
                                                                : 'icon-base-grid'
                                                        }`}
                                                        data-bg={`url("${announcementImg}")`}
                                                        onClick={() =>
                                                            navigate('/dashboard/employee/announcement-panel')
                                                        }
                                                        onMouseEnter={() =>
                                                            setHover({
                                                                menu: 'announcements',
                                                                url: '/dashboard/employee/announcement-panel',
                                                                isMenu: true,
                                                                img: icAnnnouncementIcon,
                                                            })
                                                        }
                                                        onMouseLeave={() => setHover({})}
                                                    >
                                                        <img src={icAnnnouncementIcon} alt='' />

                                                        <p>{t('announcements')}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {permission?.includes(permissionObj.WEB_ANNOUNCEMENT_READ) && (
                                                <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                    <div
                                                        className={`${
                                                            hover.menu === 'graphs'
                                                                ? 'icon-base-grid-hovered'
                                                                : 'icon-base-grid'
                                                        }`}
                                                        data-bg={`url("${graphImg}")`}
                                                        onClick={() => navigate('/dashboard/employee/graphs')}
                                                        onMouseEnter={() =>
                                                            setHover({
                                                                menu: 'graphs',
                                                                url: '/dashboard/employee/graphs',
                                                                isMenu: true,
                                                                img: graphIcon,
                                                            })
                                                        }
                                                        onMouseLeave={() => setHover({})}
                                                    >
                                                        <img src={graphIcon} alt='' />

                                                        <p>{t('graphs')}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {permission?.includes(permissionObj.WEB_CARD_READ) && (
                                                <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                    <div
                                                        className={`${
                                                            hover.menu === 'cards'
                                                                ? 'icon-base-grid-hovered'
                                                                : 'icon-base-grid'
                                                        }`}
                                                        data-bg={`url("${logsImg}")`}
                                                        onClick={() => navigate('/dashboard/employee/cards')}
                                                        onMouseEnter={() =>
                                                            setHover({
                                                                menu: 'cards',
                                                                url: '/dashboard/employee/cards',
                                                                isMenu: true,
                                                                img: cardsIcon,
                                                            })
                                                        }
                                                        onMouseLeave={() => setHover({})}
                                                    >
                                                        <img src={cardsIcon} alt='' />

                                                        <p>{t('card_management')}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {permission?.includes(permissionObj.WEB_PROFILE_READ) && (
                                                <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                    <div
                                                        className={`${
                                                            hover.menu === 'PROFILE'
                                                                ? 'icon-base-grid-hovered'
                                                                : 'icon-base-grid'
                                                        }`}
                                                        data-bg={`url("${profileImg}")`}
                                                        onClick={() => navigate('/dashboard/employee/profile')}
                                                        onMouseEnter={() =>
                                                            setHover({
                                                                menu: 'PROFILE',
                                                                url: '/dashboard/employee/profile',
                                                                isMenu: true,
                                                                img: provfileIcon,
                                                            })
                                                        }
                                                        onMouseLeave={() => setHover({})}
                                                    >
                                                        <img src={profileicon} alt='' />
                                                        <p>{t('profile')}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {user?.data?.userType?.name === 'SUPPLIER_IN_CHARGE' && (
                                <>
                                    <div className='grid-menu-icon-container full-container mx-0'>
                                        <div className='grid-menu-icon-row row mt-5 mx-3'>
                                            {user?.data?.status?.id !== 3 && (
                                                <>
                                                    <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                        <div
                                                            className={`${
                                                                hover.menu === 'EMPLOYEES'
                                                                    ? 'icon-base-grid-hovered'
                                                                    : 'icon-base-grid'
                                                            }`}
                                                            data-bg={`url("${employeesImg}")`}
                                                            onClick={() => navigate('/dashboard/supplier/employees')}
                                                            onMouseEnter={() =>
                                                                setHover({
                                                                    menu: 'EMPLOYEES',
                                                                    url: '/dashboard/supplier/employees',
                                                                    isMenu: true,
                                                                    img: employeeIcons,
                                                                })
                                                            }
                                                            onMouseLeave={() => setHover({})}
                                                        >
                                                            <img src={emoloyeeicon} alt='' />

                                                            <p>{t('employees')}</p>
                                                        </div>
                                                    </div>

                                                    <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                        <div
                                                            className={`${
                                                                hover.menu === 'VEHICLES'
                                                                    ? 'icon-base-grid-hovered'
                                                                    : 'icon-base-grid'
                                                            }`}
                                                            data-bg={`url("${vehiclesImg}")`}
                                                            onClick={() => navigate('/dashboard/supplier/vehicles')}
                                                            onMouseEnter={() =>
                                                                setHover({
                                                                    menu: 'VEHICLES',
                                                                    url: '/dashboard/supplier/vehicles',
                                                                    isMenu: true,
                                                                    img: vehicleicon,
                                                                })
                                                            }
                                                            onMouseLeave={() => setHover({})}
                                                        >
                                                            <img src={vehicleicon} alt='' />

                                                            <p>{t('vehicle_fleet')}</p>
                                                        </div>
                                                    </div>
                                                    <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                        <div
                                                            className={`${
                                                                hover.menu === 'Orders'
                                                                    ? 'icon-base-grid-hovered'
                                                                    : 'icon-base-grid'
                                                            }`}
                                                            data-bg={`url("${suppliersImg}")`}
                                                            onClick={() => navigate('/dashboard/supplier/orders')}
                                                            onMouseEnter={() =>
                                                                setHover({
                                                                    menu: 'Orders',
                                                                    url: '/dashboard/supplier/orders',
                                                                    isMenu: true,
                                                                    img: companyIcon,
                                                                })
                                                            }
                                                            onMouseLeave={() => setHover({})}
                                                        >
                                                            <img src={providericon} alt='' />

                                                            <p>{t('orders')}</p>
                                                        </div>
                                                    </div>

                                                    <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                        <div
                                                            className={`${
                                                                hover.menu === 'announcements'
                                                                    ? 'icon-base-grid-hovered'
                                                                    : 'icon-base-grid'
                                                            }`}
                                                            data-bg={`url("${firsAccessImg}")`}
                                                            onClick={() =>
                                                                navigate('/dashboard/supplier/announcements')
                                                            }
                                                            onMouseEnter={() =>
                                                                setHover({
                                                                    menu: 'announcements',
                                                                    url: '/dashboard/supplier/announcements',
                                                                    isMenu: true,
                                                                    img: icAnnnouncementIcon,
                                                                })
                                                            }
                                                            onMouseLeave={() => setHover({})}
                                                        >
                                                            <img src={icAnnnouncementIcon} alt='' />

                                                            <p>{t('announcements')}</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                <div
                                                    className={`${
                                                        hover.menu === 'profile'
                                                            ? 'icon-base-grid-hovered'
                                                            : 'icon-base-grid'
                                                    }`}
                                                    data-bg={`url("${profileImg}")`}
                                                    onClick={() => navigate('/dashboard/supplier/profile')}
                                                    onMouseEnter={() =>
                                                        setHover({
                                                            menu: 'profile',
                                                            url: '/dashboard/supplier/profile',
                                                            isMenu: true,
                                                            img: profileicon,
                                                        })
                                                    }
                                                    onMouseLeave={() => setHover({})}
                                                >
                                                    <img src={profileicon} alt='' />

                                                    <p>{t('profile')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {user?.data?.userType?.name === 'CONTRACTOR_IN_CHARGE' && (
                                <>
                                    <div className='grid-menu-icon-container full-container mx-0'>
                                        <div className='grid-menu-icon-row row mt-5 mx-3'>
                                            {user?.data?.status?.id !== 3 && (
                                                <>
                                                    <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                        <div
                                                            className={`${
                                                                hover.menu === 'employees'
                                                                    ? 'icon-base-grid-hovered'
                                                                    : 'icon-base-grid'
                                                            }`}
                                                            data-bg={`url("${employeesImg}")`}
                                                            onClick={() =>
                                                                navigate('/dashboard/contractor/search-employe')
                                                            }
                                                            onMouseEnter={() =>
                                                                setHover({
                                                                    menu: 'employees',
                                                                    url: '/dashboard/contractor/search-employe',
                                                                    isMenu: true,
                                                                    img: emoloyeeicon,
                                                                })
                                                            }
                                                            onMouseLeave={() => setHover({})}
                                                        >
                                                            <img src={emoloyeeicon} alt='' />

                                                            <p>{t('employees')}</p>
                                                        </div>
                                                    </div>
                                                    <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                        <div
                                                            className={`${
                                                                hover.menu === 'vehicles'
                                                                    ? 'icon-base-grid-hovered'
                                                                    : 'icon-base-grid'
                                                            }`}
                                                            data-bg={`url("${vehiclesImg}")`}
                                                            onClick={() =>
                                                                navigate('/dashboard/contractor/search-vehicle')
                                                            }
                                                            onMouseEnter={() =>
                                                                setHover({
                                                                    menu: 'vehicles',
                                                                    url: '/dashboard/contractor/search-vehicle',
                                                                    isMenu: true,
                                                                    img: vehicleicon,
                                                                })
                                                            }
                                                            onMouseLeave={() => setHover({})}
                                                        >
                                                            <img src={vehicleicon} alt='' />

                                                            <p>{t('vehicle_fleet')}</p>
                                                        </div>
                                                    </div>
                                                    <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                        <div
                                                            className={`${
                                                                hover.menu === 'Contracts'
                                                                    ? 'icon-base-grid-hovered'
                                                                    : 'icon-base-grid'
                                                            }`}
                                                            data-bg={`url("${contractorsImg}")`}
                                                            onClick={() => navigate('/dashboard/contractor/contracts')}
                                                            onMouseEnter={() =>
                                                                setHover({
                                                                    menu: 'Contracts',
                                                                    url: '/dashboard/contractor/contracts',
                                                                    isMenu: true,
                                                                    img: contractoricon,
                                                                })
                                                            }
                                                            onMouseLeave={() => setHover({})}
                                                        >
                                                            <img src={contractoricon} alt='' />

                                                            <p>{t('contracts')}</p>
                                                        </div>
                                                    </div>

                                                    <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                        <div
                                                            className={`${
                                                                hover.menu === 'announcements'
                                                                    ? 'icon-base-grid-hovered'
                                                                    : 'icon-base-grid'
                                                            }`}
                                                            data-bg={`url("${contractorsImg}")`}
                                                            onClick={() =>
                                                                navigate('/dashboard/contractor/announcementtab')
                                                            }
                                                            onMouseEnter={() =>
                                                                setHover({
                                                                    menu: 'announcements',
                                                                    url: '/dashboard/contractor/announcementtab',
                                                                    isMenu: true,
                                                                    img: icAnnnouncementIcon,
                                                                })
                                                            }
                                                            onMouseLeave={() => setHover({})}
                                                        >
                                                            <img src={icAnnnouncementIcon} alt='' />

                                                            <p>{t('announcements')}</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                <div
                                                    className={`${
                                                        hover.menu === 'profile'
                                                            ? 'icon-base-grid-hovered'
                                                            : 'icon-base-grid'
                                                    }`}
                                                    data-bg={`url("${profileImg}")`}
                                                    onClick={() => navigate('/dashboard/contractor/profile')}
                                                    onMouseEnter={() =>
                                                        setHover({
                                                            menu: 'profile',
                                                            url: '/dashboard/contractor/profile',
                                                            isMenu: true,
                                                            img: profileicon,
                                                        })
                                                    }
                                                    onMouseLeave={() => setHover({})}
                                                >
                                                    <img src={profileicon} alt='' />

                                                    <p>{t('profile')}</p>
                                                </div>
                                            </div>
                                            <div className='menu-icon-grid col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0 mx-0 mb-3'>
                                                <div
                                                    className={`${
                                                        hover.menu === 'access-rights'
                                                            ? 'icon-base-grid-hovered'
                                                            : 'icon-base-grid'
                                                    }`}
                                                    data-bg={`url("${accessRImg}")`}
                                                    onClick={() => navigate('/dashboard/contractor/access-rights')}
                                                    onMouseEnter={() =>
                                                        setHover({
                                                            menu: 'access-rights',
                                                            url: '/dashboard/contractor/access-rights',
                                                            isMenu: true,
                                                            img: accessRicon,
                                                        })
                                                    }
                                                    onMouseLeave={() => setHover({})}
                                                >
                                                    <img src={accessRicon} alt='' />

                                                    <p>{t('access-rights')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div
                                className={`${
                                    hover.isMenu
                                        ? 'bottom-logo-menu-icon-no position-sticky pb-2 pt-2'
                                        : 'bottom-logo-menu-icon position-sticky pb-2 pt-2'
                                }`}
                            >
                                <img src={logo} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginOptions;
