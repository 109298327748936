import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@mui/material';
import { DateTimePicker, ClockPicker } from '@mui/lab';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { Box } from '@mui/system';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import {
    CompleteOrderProvider,
    GetAllProviderEmployeeListDown,
    GetAllProviderVehicleListDown,
    GetOrderDetails,
} from '../../reduxToolkit/Providers/providersApi';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import ReturnArrow from '../../components/buttons/ReturnArrow';
import moment from 'moment';
import useStyle from '../../hooks/useStyle';

const CompleteOrder = ({ isUpdateOrder }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    // hook
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useState
    const [employeeName, setEmployeeName] = useState('');
    const [etaDate, setEtaDate] = useState(null);
    const [vehicleBrand, setVehicleBrand] = useState('');

    //useSelector

    const { textField, smallBoxStyle, textFieldCommon } = useStyle();

    const { getAllProviderEmployeeListDown } = useSelector((state) => state.providersSlice);

    const { getAllProviderVehicleListDown } = useSelector((state) => state.providersSlice);

    const { getOrderDetails } = useSelector((state) => state.providersSlice);

    const [isValidDate, setIsValidDate] = useState(true);

    const [showHelperText, setShowHelperText] = useState(false);

    // function

    const handleDeliveryDate = (newValue) => {
        setEtaDate(newValue);
    };

    const vehicleDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.brand || '') +
                    ' | ' +
                    (item?.subBrand || '') +
                    ', ' +
                    t('plates') +
                    ': ' +
                    (item?.plate || ''),
                id: item?.id,
            };
        });
        return newData;
    };

    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.name || '') +
                    ' ' +
                    (item?.firstLastName || '') +
                    (item?.lastName || '') +
                    (item?.secondLastName ? ' ' + item.secondLastName : ''),
                id: item?.id,
            };
        });
        return newData;
    };

    const handelCompleteOrder = () => {
        setShowHelperText(true);
        let time_in_miliseconds = Math.round(etaDate);

        const data = {
            userId: employeeName,
            eta: time_in_miliseconds,
            vehicleId: vehicleBrand,
        };
        const orderId = getOrderDetails?.id;
        if (!employeeName || !etaDate || !vehicleBrand || !isValidDate) {
            toast.warn(t('please_fill_all_fields'));
        } else {
            dispatch(CompleteOrderProvider({ data, orderId })).then(() => {
                navigate('/dashboard/supplier/order-detail');
            });
        }
    };
    const eta = new Date(getOrderDetails?.eta);

    useEffect(() => {
        if (isUpdateOrder) {
            setEmployeeName(getOrderDetails?.supplierEmployee?.user?.id || '');
            setVehicleBrand(getOrderDetails?.supplierVehicle?.vehicle?.id || '');
            setEtaDate(getOrderDetails?.eta);
        }
    }, [getOrderDetails?.id, isUpdateOrder]);

    useEffect(() => {
        dispatch(GetOrderDetails(sessionStorage.getItem('supplier_order_id')));
        dispatch(GetAllProviderVehicleListDown(sessionStorage.getItem('providerId')));
        dispatch(GetAllProviderEmployeeListDown(sessionStorage.getItem('providerId')));
    }, []);

    const handleEtaDateChange = (date) => {
        if (date) {
            const year = date.getFullYear();
            const adjustedYear = year < 100 ? year + 2000 : year;
            const newDate = new Date(date);
            newDate.setFullYear(adjustedYear);

            setEtaDate(newDate);
        } else {
            setEtaDate(null);
        }
    };

    // main File
    return (
        <>
            <div className='head '>
                <div className='headLeft'>
                    {/* <h2>
                        <Link to="/dashboard/supplier/order-detail">
                            <ArrowBackIcon
                                style={{
                                    color: "#146F62",
                                    fontSize: "30px",
                                    marginRight: "30px",
                                    transform: lCode === "ar" ? "scaleX(-1)" : "",
                                    margin: "0 10px"
                                }}
                            />
                        </Link>

                        {isUpdateOrder ? "UPDATE ORDER" : "COMPLETE ORDER"}
                        {/* {employeeDetails && t("employee_supplier_detail")} */}
                    {/* {approveDocument && "APPROVE DOCUMENTS"} */}
                    {/* </h2> */}
                    <ReturnArrow link={'/dashboard/supplier/orders'} />
                    <h2>{isUpdateOrder ? t('update_order') : t('complete_order')}</h2>
                </div>
            </div>
            <div className='complete_order_container'>
                <div className='row m-0 gx-4'>
                    {/* title */}
                    <div className='off-set-2 col-2'></div>
                    <div className='col-4'>
                        <h4>{t('data')}</h4>
                    </div>
                    <div className='col-4'>
                        <h4>{t('supplier_data')}</h4>
                    </div>
                    <div className='off-set-2 col-2'></div>
                    {/* card */}
                    <div className='off-set-2 col-2'></div>
                    <div className='col-4 left_conatiner '>
                        <div className='card_header'>
                            <h4>{t('supplier_information')}</h4>
                            <p>
                                <span>{getOrderDetails?.supplier?.acronym} | </span>
                                {getOrderDetails?.supplier?.supplierCompanyName}
                            </p>
                            <h6 style={{ textTransform: 'capitalize' }}>{getOrderDetails?.supplier?.user?.fullName}</h6>
                        </div>

                        <div className='card_body'>
                            <h4 className='mb-3'>{t('delivery_information')}</h4>
                            {isUpdateOrder && (
                                <>
                                    {/* <div className="card_body_item">
                                        <h5>{t("eta")}</h5>
                                        <p>{eta?.toLocaleString("en-GB")}</p>
                                    </div> */}
                                    {/* <div className="card_body_item">
                                        <h5>{t("corporate")}</h5>
                                        <p>IBL Corporate</p>
                                    </div> */}
                                </>
                            )}
                            <div className='card_body_item'>
                                <h5>{t('delivery_date')}</h5>
                                <p>
                                    {lCode === 'en'
                                        ? moment(getOrderDetails?.deliveryDate).format('MM/DD/YYYY')
                                        : moment(getOrderDetails?.deliveryDate).format('DD/MM/YYYY')}
                                </p>
                            </div>
                            <div className='card_body_item'>
                                <h5>{t('item')}</h5>
                                <p>{getOrderDetails?.item}</p>
                            </div>
                            <div className='card_body_item'>
                                <h5>{t('description')}</h5>
                                <p className='long_text'>{getOrderDetails?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 right_container ml-3'>
                        <Grid item xs={12} className='required-data-text-form'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    allowSameDateSelection
                                    disablePast
                                    ampm={false}
                                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                    label={t('eta')}
                                    inputFormat='dd/MM/yyyy HH:mm:ss'
                                    value={etaDate}
                                    onError={(error) => setIsValidDate(error === null || error === 'disablePast')}
                                    onChange={handleEtaDateChange}
                                    // renderInput={(props) => <TextField {...props} />}
                                    renderInput={(params) => (
                                        <TextField
                                            className='textfield-value'
                                            size='small'
                                            sx={textFieldCommon}
                                            fullWidth
                                            required
                                            {...params}
                                            // focused={!advancedSearch && etaDate === null && condition !== '' ? true : false}
                                            helperText={
                                                (!isValidDate || (showHelperText && etaDate === null)) &&
                                                t('date_invalid')
                                            }
                                            error={(showHelperText && etaDate === null) || !isValidDate}
                                        />
                                    )}
                                    renderTimeView={(props) => <ClockPicker {...props} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ mt: '2rem' }} className='required-data-text-form'>
                                <FormControl
                                    fullWidth
                                    required
                                    sx={textFieldCommon}
                                    error={showHelperText && employeeName === ''}
                                >
                                    <InputLabel id='demo-simple-select-label'>{t('employee')}</InputLabel>
                                    <Select
                                        size='small'
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        label={t('employee')}
                                        value={employeeName}
                                        onChange={(e) => setEmployeeName(e.target.value)}
                                    >
                                        {userDataTransform(getAllProviderEmployeeListDown)?.map((item) => {
                                            return <MenuItem value={item?.id}>{item?.label}</MenuItem>;
                                        })}
                                    </Select>
                                    {showHelperText && employeeName === '' && (
                                        <FormHelperText className='select_helper_text'>
                                            {t('selectOptionText')}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ mt: '2rem' }} className='required-data-text-form'>
                                <FormControl
                                    fullWidth
                                    required
                                    sx={textFieldCommon}
                                    error={showHelperText && vehicleBrand === ''}
                                >
                                    <InputLabel id='vehicleId'>{t('vehicle')}</InputLabel>
                                    <Select
                                        size='small'
                                        labelId='vehicleId'
                                        id='vehicleId_select'
                                        // defaultValue="EMPLOYEE"
                                        // value={employee}
                                        label={t('vehicle')}
                                        value={vehicleBrand}
                                        defaultValue={getOrderDetails?.supplierVehicle?.id || ''}
                                        onChange={(e) => setVehicleBrand(e.target.value)}
                                    >
                                        {vehicleDataTransform(getAllProviderVehicleListDown)?.map((item) => {
                                            return <MenuItem value={item?.id}>{item?.label}</MenuItem>;
                                        })}
                                    </Select>
                                    {showHelperText && vehicleBrand === '' && (
                                        <FormHelperText className='select_helper_text'>
                                            {t('selectOptionText')}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Box>
                        </Grid>
                    </div>
                    <div className='off-set-2 col-2'></div>
                    {/* button */}
                    <div className='off-set-6 col-4'></div>
                    <div className='col-4'>
                        <div className='footer'>
                            <button
                                // className='add-btn-1'
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handelCompleteOrder();
                                }}
                            >
                                {isUpdateOrder ? t('update').toUpperCase() : t('complete').toUpperCase()}
                            </button>
                        </div>
                    </div>
                    <div className='off-set-2 col-2'></div>
                </div>
            </div>
        </>
    );
};

export default CompleteOrder;
