import React from 'react';
import { Box, Typography } from '@mui/material';
import '../FailValidation/ValidatedIdentity';
import logo from '../../../assets/logo/logo 3.png';
import { useTranslation } from 'react-i18next';
import background from '../../../assets/defaultImages/building_2.png';

const SuccessValidation = () => {
    const { t } = useTranslation();

    return (
        <Box
            className='validated-identity-content'
            sx={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    bgcolor: 'white',
                    p: 4,
                    width: 600,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: 2,
                    zIndex: 1,
                    margin: '30px',
                }}
            >
                <img src={logo} alt='Identity Validated' />
                <h2> {t('Process_completed_successfully')}</h2>
                <Typography variant='body1' component='body1' gutterBottom>
                    {t('Company_arrival_instructions')}
                </Typography>
            </Box>
        </Box>
    );
};

export default SuccessValidation;
