export const accessMethod = {
    1: 'tag',
    2: 'fingerprint',
    3: 'nfc_card',
    4: 'qr_code',
    5: 'face_recognition',
    6: 'pin',
    7: 'fingerprint_pda',
    8: 'bluetooth',
    9: 'web_app',
    10: 'mobile_app',
    11: 'zone_opt',
    12: 'document',
    13: 'plates',
    14: 'fingerprint',
    15: 'fingerprint',
    16: 'signo_25B',
};
