import {
    Box,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormControl,
    Typography,
    Checkbox,
    FormControlLabel,
    Stack,
    Button,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Modal } from 'react-bootstrap';
import cross from '../../../../assets/images/ic-cancel.svg';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { permissionObj } from '../../../../Helpers/permission';
import { useEffect } from 'react';
import {
    CreateListWithProcess,
    CreateOnBoarding,
    CreateOnBoardingProcess,
    DeleteOnboardingProcess,
    GetAllEmployee,
    GetAllOnBoardingProcess,
    GetAllZone,
    GetOnBoardingById,
    UpdateOnBoarding,
} from '../../../../reduxToolkit/EmployeeOnBoarding/EmployeeOnBoardingApi';
import {
    ResetOnboardingById,
    ResetOnboardingProcess,
} from '../../../../reduxToolkit/EmployeeOnBoarding/EmployeeOnBoardingSlice';
import { toast } from 'react-toastify';
import NotInformation from '../../../../components/NotInformation';
import uuid from 'react-uuid';
import ReturnArrow from '../../../../components/buttons/ReturnArrow';
import { t } from 'i18next';
import AutoCompleteSearch from '../../../../components/AutoCompleteSearch';
import DeleteModal from '../../../Modals/DeleteModal';
import { DeleteItemsApi } from '../../../../reduxToolkit/Commons/CommonsApi';
import { getWorkStations } from '../../../../reduxToolkit/CompanyEmployees/CompanyEmployeesApi';
import { getAllZones } from '../../../../Apis/Zones';
import { set } from 'lodash';
/*
Author : Arman Ali
Module: onbarding ue
github: https://github.com/Arman-Arzoo
*/

// On Boarding UE module main funtion
const OnBoardingUE = () => {
    // assign a variable to value
    let max = 500;
    //hook
    const params = useParams();
    const dispatch = useDispatch();

    //use State hook for local state management
    const [show, setShow] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [message, setMessage] = useState(null);
    const [manager, setManager] = useState(null);
    const [workStation, setWorkStation] = useState(null);
    const [autoCompleteSearchSelectedWorkStation, setAutoCompleteSearchSelectedWorkStation] = useState(false);
    const [autoCompleteSearchSelectedManager, setAutoCompleteSearchSelectedManager] = useState(false);
    const [WelcomeMessageSelected, setWelcomeMessageSelected] = useState(false);
    const [workStationName, setWorkStationName] = useState('');
    const [managerName, setManagerName] = useState('');
    const [createStep, setCreateStep] = useState([]);
    const [isAllCheckedProcess, setIsAllCheckedProcess] = useState(false);
    const [selectProcessForDelete, setSelectProcessForDelete] = useState([]);
    const [isAllCheckedCustomProcess, setIsAllCheckedCustomProcess] = useState(false);
    const [selectCustomProcessForDelete, setSelectCustomProcessForDelete] = useState([]);
    const [processShow, setProcessShow] = useState(false);
    const [processCustomShow, setProcessCustomShow] = useState(false);

    //use Selector hook to get state for redux store
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { getallzone } = useSelector((state) => state.employeeOnBoardingSlice);

    const { getallEmployee } = useSelector((state) => state.employeeOnBoardingSlice);
    const { getOnBoardingById } = useSelector((state) => state.employeeOnBoardingSlice);
    const { createOnBoardingProcess } = useSelector((state) => state.employeeOnBoardingSlice);
    const { deleteOnboardingProcess } = useSelector((state) => state.employeeOnBoardingSlice);
    const { updateOnBoarding } = useSelector((state) => state.employeeOnBoardingSlice);
    const { createOnBoarding } = useSelector((state) => state.employeeOnBoardingSlice);
    const { getAllOnBoardingProcess } = useSelector((state) => state.employeeOnBoardingSlice);

    const { deleteItemsApi } = useSelector((state) => state.CommonsSlice);
    const lCode = Cookies.get('i18next') || 'en';
    const { employeeWorkStations } = useSelector((state) => state.CompanyEmployeesSlice);
    const [allZonesInList, setAllZonesInList] = useState();
    const userEmployeeId = sessionStorage.getItem('id');
    const createProcessIds = createStep?.map((item) => {
        return {
            process: item?.process,
            onboarding: {
                id: item?.id,
            },
        };
    });
    const navigate = useNavigate();

    const [isWorkStationHasValue, setIsWorkStationHasValue] = useState(false);
    const [isManagerHasValue, setIsManagerHasValue] = useState(false);
    const [workStationError, setWorkStationError] = useState('');
    const [managerError, setManagerError] = useState('');
    const [welcomeMessageError, setWelcomeMessageError] = useState('');

    const deleteProcess = (deleteItem) => {
        const tableName = 'onboarding_process';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body }));
        setSelectProcessForDelete([]);
        setIsAllCheckedProcess(false);
    };
    const deleteCustomProcess = () => {
        const updatedItems = createStep.filter((item) => !selectCustomProcessForDelete.includes(item.id));
        setCreateStep(updatedItems);
        setSelectCustomProcessForDelete([]);
    };
    // this function control select all id or unSelect all
    const handelDeleteAllProcess = (e) => {
        setIsAllCheckedProcess(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getAllOnBoardingProcess?.map((item) => {
                return item?.id;
            });
            setSelectProcessForDelete(selectAllIds);
        } else {
            setSelectProcessForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChangeProcess = (e) => {
        if (e.target.checked) {
            setSelectProcessForDelete([...selectProcessForDelete, e.target.id]);
        } else {
            setSelectProcessForDelete(selectProcessForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };
    const handelDeleteAllCustomProcess = (e) => {
        setIsAllCheckedCustomProcess(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = createStep?.map((item) => {
                return item?.id;
            });
            setSelectCustomProcessForDelete(selectAllIds);
        } else {
            setSelectCustomProcessForDelete([]);
        }
    };
    const handleCheckboxChangeCustomProcess = (e) => {
        if (e.target.checked) {
            setSelectCustomProcessForDelete([...selectCustomProcessForDelete, e.target.id]);
        } else {
            setSelectCustomProcessForDelete(
                selectCustomProcessForDelete.filter((removeid) => removeid !== e.target.id),
            );
        }
    };
    // object for create and update onboarding
    const createOnboardingActionData = {
        introduction: message,
        managerId: manager?.id,
        managerName: managerName,
        processNo: getOnBoardingById?.name,
        roleId: sessionStorage.getItem('onBoardingRoleId'),
        roleName: sessionStorage.getItem('onBoardingRoleName'),
        zoneId: workStation?.id,
        zoneName: workStationName,
    };
    const updateOnboardingActionData = {
        id: params?.id,
        introduction: message,
        managerId: manager?.id,
        zoneId: workStation?.id,
    };
    // handel the logic for object transformation for all user
    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.name || '') +
                    ' ' +
                    (item?.firstLastName || '') +
                    (item?.secondLastName ? ' ' + item.secondLastName : ''),
                id: item?.userId,
            };
        });

        return newData;
    };
    // handel the logic for object transformation for all user
    const userDataTransformWorkstation = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: item?.name,
                id: item?.id,
            };
        });

        return newData;
    };

    const WorkStationValue = (value) => {
        if (!workStation || workStation !== '' || workStation !== null || workStation !== undefined) {
            setIsWorkStationHasValue(true);
        } else {
            setIsWorkStationHasValue(false);
        }
    };

    const managerValue = () => {
        if (!manager || manager !== '' || manager !== null || manager !== undefined) {
            setIsManagerHasValue(true);
        } else {
            setIsManagerHasValue(false);
        }
    };
    // useEffect to check update data
    useEffect(() => {
        setManager({ id: getOnBoardingById?.user?.id || '', label: getOnBoardingById?.user?.name || '' });
        setMessage(getOnBoardingById?.introduction || '');
        setWorkStation({ id: getOnBoardingById?.zone?.id || '', label: getOnBoardingById?.zone?.name || '' });
    }, [getOnBoardingById?.id, params?.id]);
    // useEffect for get all on boarding  and process by it ID
    useEffect(() => {
        if (params?.id != 'null') {
            dispatch(GetOnBoardingById(params?.id));
            dispatch(GetAllOnBoardingProcess(params?.id));
        } else {
            dispatch(ResetOnboardingById());
            dispatch(ResetOnboardingProcess());
        }
    }, [deleteOnboardingProcess, createOnBoardingProcess, updateOnBoarding, createOnBoarding, deleteItemsApi]);

    useEffect(() => {
        dispatch(GetAllZone());
        dispatch(GetAllEmployee());
    }, []);

    useEffect(() => {
        const fetchGetAllZones = async () => {
            const response = await getAllZones();
            if (response.status === 200) {
                setAllZonesInList(response.data.data);
            } else {
                console.error('Error al obtener las zonas de la base de datos: ', response);
            }
        };
        fetchGetAllZones();
    }, []);
    const onBlurAutoCompleteSearchWorkStation = () => {
        setAutoCompleteSearchSelectedWorkStation(true);
    };

    const onBlurAutoCompleteSearchManager = () => {
        setAutoCompleteSearchSelectedManager(true);
    };

    const onBlurTextFieldWelcomeMessage = () => {
        setWelcomeMessageSelected(true);
    };

    //LOGICA DEL BOTÓN DE GUARDAR / ACTUALIZAR INFORMACIÓN
    const handleInformationToUpload = () => {
        const data = params?.id != 'null' ? updateOnboardingActionData : createOnboardingActionData;
        const checkId = params?.id;
        const createProcessIds = createStep;
        createOnboardingAction(checkId, data, createProcessIds);
    };
    const createOnboardingAction = (checkId, data, createProcessIds) => {
        if (workStation.id === '') {
            setWorkStationError('This Field Is Required!');
        } else {
            setWorkStationError('');
        }
        if (manager.id === '') {
            setManagerError('This Field Is Required!');
        } else {
            setManagerError('');
        }
        if (checkId === 'null') {
            if (
                data?.managerName &&
                data?.managerName != '' &&
                data?.managerName != null &&
                data?.managerName != undefined
            ) {
                dispatch(CreateOnBoarding(data)).then((res) => {
                    dispatch(
                        CreateListWithProcess(
                            createProcessIds?.map((item) => {
                                return {
                                    process: item?.process,
                                    onboarding: {
                                        id: res?.payload?.data?.data?.id,
                                    },
                                };
                            }),
                        ),
                    ).then(() => {
                        navigate('/dashboard/employee/company/onboarding');
                    });
                });
            } else {
                // Verifica si hay algún campo vacío, caso contrario te lo remarca de rojo
                toast.warn(t('please_fill_all_fields'));
                setWelcomeMessageSelected(true);
            }
        } else {
            dispatch(UpdateOnBoarding(data)).then(() => {
                navigate('/dashboard/employee/company/onboarding');
            });
        }
    };
    return (
        <>
            <Box>
                <div className='head'>
                    <div className='headLeft'>
                        <ReturnArrow link={'/dashboard/employee/company/onboarding'} />
                        <h2>{params?.id != 'null' ? t('update')?.toUpperCase() : t('create')?.toUpperCase()}</h2>
                    </div>

                    <div className='container-top-right-btns'>
                        {params?.id != 'null' ? (
                            <button
                                className='delete-btn-1'
                                disabled={selectProcessForDelete?.length === 0}
                                onClick={() => {
                                    setProcessShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        ) : (
                            <button
                                className='delete-btn-1'
                                disabled={selectCustomProcessForDelete?.length === 0}
                                onClick={() => {
                                    setProcessCustomShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>
                </div>

                <Box className='on_boarding_create_edit'>
                    <Box className='on_boarding_detail'>
                        <h4>{t('details')}</h4>
                        <h5>{t('privilege')}</h5>
                        <p>{getOnBoardingById?.role?.name || sessionStorage.getItem('onBoardingRoleName')}</p>

                        <Grid container gap='1.5rem'>
                            <Box sx={{ width: '100%' }}>
                                <AutoCompleteSearch
                                    required
                                    error={workStation?.id === '' && workStationError.length > 0}
                                    onBlur={onBlurAutoCompleteSearchWorkStation}
                                    data={userDataTransformWorkstation(allZonesInList)}
                                    value={workStation}
                                    handleFn={(e) => {
                                        WorkStationValue(e?.label);
                                    }}
                                    label={t('work_station')}
                                    setValue={setWorkStation}
                                    defaultState={getOnBoardingById?.zone?.id || null}
                                />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <AutoCompleteSearch
                                    error={manager?.id === '' && managerError.length > 0}
                                    onBlur={onBlurAutoCompleteSearchManager}
                                    data={userDataTransform(getallEmployee)}
                                    value={manager}
                                    label={t('manager')}
                                    setValue={setManager}
                                    handleFn={(e) => {
                                        managerValue();
                                    }}
                                    defaultState={getOnBoardingById?.user?.id || ''}
                                />
                            </Box>

                            <Grid sx={{ position: 'relative', width: '100%' }}>
                                <TextField
                                    onBlur={onBlurTextFieldWelcomeMessage}
                                    size='small'
                                    fullWidth
                                    type='text'
                                    label={t('welcome_message')?.toUpperCase()}
                                    id='message'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    multiline
                                    rows={5}
                                    maxRows={10}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '12px',
                                            fontWeight: 100,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        maxLength: 500,
                                        minLength: 124,
                                    }}
                                    sx={{
                                        textAlign: lCode === 'ar' ? 'right' : 'left',
                                        '& 	.MuiOutlinedInput-notchedOutline': {
                                            textAlign: lCode === 'ar' ? 'right' : 'left',
                                        },
                                        '& 	.MuiInputLabel-root': {
                                            fontSize: 12,
                                            left: lCode === 'ar' ? 'inherit' : '0',
                                            right: lCode === 'ar' ? '1.75rem' : '0',
                                            transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                        },
                                    }}
                                />
                                <Typography
                                    className='bottom_number'
                                    sx={{
                                        position: 'absolute',
                                        bottom: '-31px',
                                        right: 0,
                                    }}
                                >
                                    {message?.length}/{max} {t('max')?.toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='on_boarding_todo'>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' paddingTop='1rem'>
                            {params?.id != 'null' && getAllOnBoardingProcess?.length > 0 && (
                                <>
                                    <FormControlLabel
                                        className='grid-checkall'
                                        sx={{ marginLeft: '0.15rem' }}
                                        control={
                                            <Checkbox
                                                label='Label'
                                                checked={selectProcessForDelete?.length > 0}
                                                onChange={handelDeleteAllProcess}
                                                indeterminate={
                                                    selectProcessForDelete?.length > 0 &&
                                                    getAllOnBoardingProcess?.length !== selectProcessForDelete?.length
                                                }
                                                size='small'
                                            />
                                        }
                                        label={t('de_/_select_all')}
                                    />
                                    <Stack
                                        direction='column'
                                        alignItems='center'
                                        justifyContent='space-between'
                                        paddingBottom='3rem'
                                    >
                                        <h4>{t('to_do')?.toUpperCase()}</h4>
                                        <h6>
                                            {t('things_to_do')?.toUpperCase()}: {getAllOnBoardingProcess?.length}
                                        </h6>
                                    </Stack>
                                </>
                            )}
                            {params?.id === 'null' && createStep?.length > 0 && (
                                <>
                                    <FormControlLabel
                                        className='grid-checkall'
                                        sx={{ marginLeft: '0.15rem' }}
                                        control={
                                            <Checkbox
                                                label='Label'
                                                checked={selectCustomProcessForDelete?.length > 0}
                                                onChange={handelDeleteAllCustomProcess}
                                                indeterminate={
                                                    selectCustomProcessForDelete?.length > 0 &&
                                                    selectCustomProcessForDelete?.length !== createStep?.length
                                                }
                                                size='small'
                                            />
                                        }
                                        label={t('de_/_select_all')}
                                    />
                                    <h6>
                                        {t('things_to_do')?.toUpperCase()}: {createStep?.length}
                                    </h6>
                                </>
                            )}
                        </Stack>

                        <Box className='on_boarding_todo_list_container'>
                            {params?.id != 'null' &&
                                (getAllOnBoardingProcess?.length > 0 ? (
                                    getAllOnBoardingProcess?.map((item) => (
                                        <Box className='on_boarding_todo_lists'>
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={selectProcessForDelete?.includes(item?.id)}
                                                id={item?.id}
                                                onChange={handleCheckboxChangeProcess}
                                                size='small'
                                            />
                                            <p>{item?.process}</p>
                                        </Box>
                                    ))
                                ) : (
                                    <></>
                                ))}
                            {params?.id === 'null' &&
                                (createStep?.length > 0 ? (
                                    createStep?.map((item) => (
                                        <Box className='on_boarding_todo_lists'>
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={selectCustomProcessForDelete?.includes(item?.id)}
                                                id={item?.id}
                                                onChange={handleCheckboxChangeCustomProcess}
                                                size='small'
                                            />
                                            <p>{item?.process}</p>
                                        </Box>
                                    ))
                                ) : (
                                    <></>
                                ))}
                            <button
                                onClick={() => {
                                    setShow(true);
                                }}
                                className='add_new_step_btn'
                            >
                                {t('add_new_step')?.toUpperCase()}{' '}
                                <i className='fa fa-plus plus plus_icon' aria-hidden='true'></i>
                            </button>
                            <DeleteDeviceModal
                                show={deleteShow}
                                onHide={() => setDeleteShow(false)}
                                deleteid={deleteId}
                            />
                        </Box>

                        <AddStepModal
                            show={show}
                            onHide={() => setShow(false)}
                            onboardingId={params?.id}
                            setCreateStep={setCreateStep}
                        />

                        <br />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <button
                                style={{ width: '100%' }}
                                className='create_btn'
                                onClick={() => {
                                    handleInformationToUpload();
                                }}
                            >
                                {params?.id != 'null' ? t('update')?.toUpperCase() : t('create')?.toUpperCase()}
                            </button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <DeleteModal
                show={processShow}
                onHide={() => setProcessShow(false)}
                onClickFn={() => deleteProcess(selectProcessForDelete)}
                data={selectProcessForDelete}
                title_modal={'remove'}
                element_modal={'step'}
                isReset={setSelectProcessForDelete}
                isAllReset={setIsAllCheckedProcess}
            />

            <DeleteModal
                show={processCustomShow}
                onHide={() => setProcessCustomShow(false)}
                onClickFn={() => deleteCustomProcess(selectCustomProcessForDelete)}
                data={selectCustomProcessForDelete}
                title_modal={'remove'}
                element_modal={'step'}
                isReset={setSelectCustomProcessForDelete}
                isAllReset={setIsAllCheckedCustomProcess}
            />
        </>
    );
};

// modal for add step
export const AddStepModal = (props) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    let max = 500;

    const addStepAction = () => {
        const data = {
            process: message,
            onboarding: {
                id: props?.onboardingId,
            },
        };
        if (message) {
            if (props.onboardingId != 'null') {
                dispatch(CreateOnBoardingProcess(data)).then(() => {
                    toast.success(t('Process_created_Successfully').toUpperCase());

                    props.onHide();
                    setMessage('');
                });
                props?.onHide();
            } else {
                props?.setCreateStep((prevState) => [...prevState, { id: uuid(), process: message }]);
                setMessage('');
                props?.onHide();
            }
        } else {
            toast.warn(t('please_enter_an_instruction').toUpperCase());
        }
    };

    return (
        <Modal className='filter_device_model' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header className='fiter_event_model_head'>
                <Modal.Title
                    style={{
                        width: '100%',
                        color: '#146F62',
                        fontSize: '16px',
                        fontWeight: '600',
                        textAlign: 'center',
                        marginTop: '10px',
                        letterSpacing: '7px',
                    }}
                >
                    {t('add_step')}
                </Modal.Title>
                <img
                    src={cross}
                    style={{
                        position: 'absolute',
                        padding: '1px',
                        right: '10px',
                        width: '15px',
                        height: '15px',
                        top: '10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => props.onHide()}
                />
            </Modal.Header>
            <Modal.Body>
                <p className='title'>{t('fill_on_the_input_the_step_to_follow_it')}</p>
                <Grid sx={{ position: 'relative', width: '100%' }}>
                    <TextField
                        size='small'
                        fullWidth
                        type='text'
                        label={t('instruction')}
                        id='message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        rows={5}
                        maxRows={10}
                        InputLabelProps={{
                            style: {
                                fontSize: '10px',
                                fontWeight: 600,
                                background: '#ffffff',
                                padding: '0px 8px 0px 8px',
                            },
                        }} // font size of input label
                        inputProps={{
                            maxLength: 500,
                            sx: {
                                border: 'none',
                                outline: 'none',
                                fontSize: '10px',
                                letterSpacing: '0px',
                                innerHeight: '200px',

                                color: '#707070',
                                '&::placeholder': {
                                    color: '#707070',
                                    fontSize: '8px',
                                },
                            },
                        }}
                    />
                    <Typography
                        className='bottom_number_modal'
                        sx={{
                            position: 'absolute',
                            bottom: '-18px',
                            right: 0,
                            fontSize: '10px',
                            color: '#707070',
                        }}
                    >
                        {message.length}/{max} {t('max')?.toUpperCase()}
                    </Typography>
                </Grid>
                <div className='changeImgBottomDiv mt-4'>
                    <button
                        className='changeImgCancelBtn'
                        style={{ fontSize: '12px' }}
                        onClick={() => {
                            props.onHide();
                            setMessage('');
                        }}
                    >
                        {t('cancel')?.toUpperCase()}
                    </button>
                    <button
                        className='changeImgChangeBtn'
                        style={{ fontSize: '12px' }}
                        onClick={() => {
                            addStepAction();
                        }}
                    >
                        {t('confirm')?.toUpperCase()}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
//model for delete
export const DeleteDeviceModal = (props) => {
    const dispatch = useDispatch();
    // a function to handel delete device
    const handleDeleteProcess = (id) => {
        dispatch(DeleteOnboardingProcess(id)).then(() => {
            props.onHide();
        });
    };

    return (
        <Modal className='filter_device_model' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header className='fiter_event_model_head'>
                <Modal.Title
                    style={{
                        width: '100%',
                        color: '#146F62',
                        fontSize: '16px',
                        fontWeight: '600',
                        textAlign: 'center',
                        marginTop: '10px',
                    }}
                >
                    {t('REMOVE_STEP').toUpperCase()}
                </Modal.Title>
                <img
                    src={cross}
                    style={{
                        position: 'absolute',
                        padding: '1px',
                        right: '3px',
                        width: '15px',
                        height: '15px',
                        top: '3px',
                        cursor: 'pointer',
                    }}
                    onClick={() => props.onHide()}
                />
            </Modal.Header>
            <Modal.Body>
                <p style={{ fontSize: '14px', textAlign: 'center', paddingBottom: '1.5rem' }}>
                    {t('you_are_about_to_remove_the_step_from_the_list')}
                    <br /> {t('press_the_button')}
                    <span style={{ fontWeight: 'bold', color: '#707070' }}> {t('CONFIRM')} </span>
                    {t('the_action')}
                </p>
                <div className='changeImgBottomDiv mt-3'>
                    <button className='changeImgCancelBtn' style={{ fontSize: '12px' }} onClick={() => props.onHide()}>
                        {t('CANCEL')}
                    </button>
                    <button
                        onClick={() => {
                            handleDeleteProcess(props?.deleteid);
                        }}
                        className='changeImgChangeBtn'
                        style={{ fontSize: '12px' }}
                    >
                        {t('CONFIRM')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default OnBoardingUE;
