import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiInstance from '../../Apis/Axios';
import apiInstanceV2 from '../../Apis/AxiosV2';
import { t } from 'i18next';

/*
Author : Arman Ali
Module: Zone
github: https://github.com/Arman-Arzoo
*/

// payroll pagenation

export const GetAllListOfAccess = createAsyncThunk('employeePayRoll/getAllListOfAccess', async (params) => {
    const response = await apiInstanceV2
        .post(`log-service/user-access/get-all-pageable/from/${params?.from}/to/${params?.to}`, params.pagination)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
// device Api payroll

export const GetAllDevicePayroll = createAsyncThunk('employeePayRoll/getAllDevicePayroll', async () => {
    const response = await apiInstance
        .get('device-service/get-all')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});

export const GetAllSelectedDevice = createAsyncThunk('employeePayRoll/getAllSelectedDevice', async () => {
    const response = await apiInstance
        .get('email-settings-service/device-email/get-all/only-device-data')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
export const GetColorAttendance = createAsyncThunk('employeePayRoll/getColorAttendance ', async () => {
    const response = await apiInstance
        .get('attendance-service/v1/color/get')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
export const GetDefaultAttendanceDepartment = createAsyncThunk(
    'employeePayRoll/getDefaultAttendanceDepartment',
    async () => {
        const response = await apiInstance
            .get('attendance-service/v1/department/get')
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);
export const GetAttendanceDepartments = createAsyncThunk('employeePayRoll/getAttendanceDepartments', async () => {
    const response = await apiInstance
        .get('attendance-service/v1/department/get-all')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
export const CreateAttendanceDepartment = createAsyncThunk(
    'employeePayRoll/createAttendanceDepartment',
    async (params) => {
        const response = await apiInstance
            .post('attendance-service/v1/department/create', params)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);
export const UpdateAttendanceDepartment = createAsyncThunk(
    'employeePayRoll/updateAttendanceDepartment',
    async (params) => {
        const response = await apiInstance
            .put('attendance-service/v1/department/update', params)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);
export const GetEmailColor = createAsyncThunk('employeePayRoll/getEmailColor ', async () => {
    const response = await apiInstance
        .get('email-settings-service/v1/color/get')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});

export const AddDeviceToListPayroll = createAsyncThunk('employeePayRoll/addDeviceToListPayroll', async (params) => {
    const response = await apiInstance
        .post(`email-settings-service/device-email/create/by-device-id/${params?.device?.id}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});

export const DeleteDeviceToListPayroll = createAsyncThunk(
    'employeePayRoll/deleteDeviceToListPayroll',
    async (params) => {
        const response = await apiInstance
            .delete(`email-settings-service/device-email/delete-by-device-id/${params}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);

// employee Api payroll
export const GetAllEmployeesPayroll = createAsyncThunk('employeePayRoll/getAllEmployeesPayroll', async () => {
    const response = await apiInstance
        .get('employee-service/get-all/only-user-data')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
export const GetAllSelectedEmployees = createAsyncThunk('employeePayRoll/getAllSelectedEmployees', async (params) => {
    const response = await apiInstance
        .post('email-settings-service/user-email/get-all-pageable/only-user-data', params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;

    return { data, status };
});

export const AddEmployeesToListPayroll = createAsyncThunk(
    'employeePayRoll/addEmployeesToListPayroll',
    async (params) => {
        const response = await apiInstance
            .post(`email-settings-service/user-email/create/by-user-id/${params?.user?.id}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);

export const DeleteEmployeesToListPayroll = createAsyncThunk(
    'employeePayRoll/deleteEmployeesToListPayroll',
    async (params) => {
        const response = await apiInstance
            .delete(`email-settings-service/user-email/delete-by-user-id/${params}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);

// Email setting Api Payroll

export const GetEmailSetting = createAsyncThunk('employeePayRoll/getEmailSetting', async () => {
    const response = await apiInstance
        .get('email-settings-service/get')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});
export const UpdateEmailSetting = createAsyncThunk(
    'employeePayRoll/updateEmailSetting',
    async (params, { dispatch, getState }) => {
        let response = await apiInstance
            .put('email-settings-service/update/email-settings', params)
            .then((response) => {
                toast.success(t('has_been_updated_successfully').toUpperCase());
                return response;
            })
            .catch((error) => {
                toast.success(t('error_updating').toUpperCase());
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);

// change the time or checkbox payroll
export const ChangeTimeAndSelectedDaysUpdate = createAsyncThunk(
    'employeePayRoll/changeTimeAndSelectedDaysUpdate',
    async (params, { dispatch, getState }) => {
        const response = await apiInstance
            .put('email-settings-service/update', params)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);
// Update attendance Color
export const UpdateAttendanceColor = createAsyncThunk(
    'employeePayRoll/updateAttendanceColor',
    async (params, { dispatch, getState }) => {
        const response = await apiInstance
            .put('email-settings-service/v1/color/update', params)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);

// remove devices
export const RemoveDeviceAttendance = createAsyncThunk(
    'employeePayRoll/removeDeviceAttendance',
    async (params, { dispatch, getState }) => {
        const response = await apiInstance
            .post('email-settings-service/device-email/delete-list', params)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);

// remove employee
export const RemoveEmployeeAttendance = createAsyncThunk(
    'employeePayRoll/removeEmployeeAttendance',
    async (params, { dispatch, getState }) => {
        const response = await apiInstance
            .post('email-settings-service/user-email/delete-list', params)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        const { data, status } = response;
        return { data, status };
    },
);
//Export attendance module
export const CreateAttendanceExcelReport = createAsyncThunk(
    'employeePayRoll/createAttendanceExcelReport ',
    async ({ body }, { dispatch, getState }) => {
        let result = await apiInstance
            .post('file-service/attendance/excel', body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `AttendanceReport-${datetime}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
export const CreateAttendancePDFReport = createAsyncThunk(
    'employeePayRoll/createAttendancePDFReport ',
    async ({ body }, { dispatch, getState }) => {
        let result = await apiInstance
            .post('file-service/attendance/pdf', body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('AttendanceReport', `${datetime}.pdf`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

export const CreateSimpleAttendanceExcelReport = createAsyncThunk(
    'employeePayRoll/createSimpleAttendanceExcelReport ',
    async ({ body }, { dispatch, getState }) => {
        let result = await apiInstance
            .post('file-service/attendance/simple-excel', body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `SimpleAttendanceReport-${datetime}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
export const CreateSimpleAttendancePDFReport = createAsyncThunk(
    'employeePayRoll/createSimpleAttendancePDFReport ',
    async ({ body }, { dispatch, getState }) => {
        let result = await apiInstance
            .post('file-service/attendance/simple-pdf', body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('SimpleAttendanceReport', `${datetime}.pdf`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
