import React from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { t } from 'i18next';
import eyeIcon from '../../../assets/eye-solid.svg';
import { permissionObj } from '../../../Helpers/permission';
import { status } from '../../../enums/statusEnum';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Tooltip } from '@mui/material';
import { statusColor } from '../../../enums/statusColorEnum';
import moment from 'moment';
import Cookies from 'js-cookie';
import NotInformation from '../../../components/NotInformation';

const TableViewSuppliers = ({
    userFor,
    isAllCheckedOrder,
    handleCheckboxChangeOrder,
    dataApi,
    handelDeleteAllOrders,
    selectOrderForDelete,
}) => {
    const dispatch = useDispatch();
    const lCode = Cookies.get('i18next') || 'en';
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    return (
        <div className='panelTables animated-div px-1 mt-1' style={{ width: '100%', paddingTop: '0rem' }}>
            {dataApi?.content?.length > 0 ? (
                <table className='w-100'>
                    <thead className='no-border-thead'>
                        {permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_DELETE) && supplierId === null && (
                            <th className='first_head'>
                                <Tooltip title={t('de_/_select_all').toUpperCase()} placement='top' disableInteractive>
                                    <Checkbox
                                        className='grid-checkall checkbox'
                                        checked={isAllCheckedOrder}
                                        onChange={handelDeleteAllOrders}
                                        size='small'
                                    />
                                </Tooltip>
                            </th>
                        )}
                        <th className='first_head'>{t('Folio')}</th>
                        {supplierId === null && (
                            <>
                                <th>{t('supplier')}</th>
                                <th>{t('company')}</th>
                            </>
                        )}
                        <th>{t('status')}</th>
                        <th>{t('supplier_employee')}</th>
                        <th>{t('supplier_vehicle')}</th>
                        <th>{t('item')}</th>
                        <th>{t('received_by')}</th>
                        <th>{t('delivered_date')}</th>
                        <th className='last'>{supplierId === null ? t('details') : t('options')}</th>
                    </thead>

                    <tbody>
                        {dataApi?.content?.map((item, index) => {
                            const deliveryData = item?.deliveryDate ? item?.deliveryDate : null;

                            return (
                                <tr key={item?.id}>
                                    {permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_DELETE) &&
                                        supplierId === null && (
                                            <td className='first'>
                                                <Checkbox
                                                    className='grid-checkall checkbox'
                                                    checked={selectOrderForDelete?.includes(item?.id)}
                                                    id={item?.id}
                                                    onChange={handleCheckboxChangeOrder}
                                                    size='small'
                                                />
                                            </td>
                                        )}
                                    <td className='first align-middle'>{item?.folio || '-'}</td>
                                    {supplierId === null && (
                                        <>
                                            <td style={{ maxWidth: 250 }}>
                                                {(() => {
                                                    const supplierFullName =
                                                        (item.supplierName || '') +
                                                        ' ' +
                                                        (item.supplierLastName || '') +
                                                        ' ' +
                                                        (item.supplierSecondLastName || '');
                                                    return (
                                                        <span
                                                            className='align-middle'
                                                            title={supplierFullName}
                                                            style={{
                                                                textTransform: 'none',
                                                                maxWidth: '100%',
                                                                display: 'inline-block',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            {supplierFullName.trim() !== '' ? supplierFullName : '-'}
                                                        </span>
                                                    );
                                                })()}
                                            </td>
                                            <td>
                                                {(() => {
                                                    const companyFullName =
                                                        (item.supplierCompanyAcronym || '') +
                                                        ' | ' +
                                                        (item.supplierCompanyName || '');
                                                    return <>{companyFullName || '-'}</>;
                                                })()}
                                            </td>
                                        </>
                                    )}
                                    <td>
                                        <span
                                            className={'viewcard-container__status'}
                                            style={{ color: statusColor[item.statusId] }}
                                        >
                                            {t(status[item?.statusId])}
                                        </span>
                                    </td>

                                    <td style={{ maxWidth: 250 }}>
                                        {(() => {
                                            const supplierEmployeeFullName =
                                                (item.supplierEmployeeName || '') +
                                                ' ' +
                                                (item.supplierEmployeeLastName || '') +
                                                ' ' +
                                                (item.supplierEmployeeSecondLastName || '');
                                            return (
                                                <span
                                                    title={supplierEmployeeFullName}
                                                    style={{
                                                        textTransform: 'none',
                                                        maxWidth: '100%',
                                                        display: 'inline-block',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    {supplierEmployeeFullName.trim() !== ''
                                                        ? supplierEmployeeFullName
                                                        : '-'}
                                                </span>
                                            );
                                        })()}
                                    </td>
                                    <td>
                                        {(() => {
                                            const vehicleFullName =
                                                (item.supplierVehicleBrand || '') +
                                                ' | ' +
                                                (item.supplierVehicleSubBrand || '');
                                            return (
                                                <span
                                                    style={{
                                                        textTransform: 'none',
                                                        maxWidth: '100%',
                                                        display: 'inline-block',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    {vehicleFullName === '' || vehicleFullName.trim() === '|'
                                                        ? '-'
                                                        : vehicleFullName}
                                                </span>
                                            );
                                        })()}
                                    </td>
                                    <td> {item?.item || '-'}</td>
                                    <td>{item?.userReceivedName || '-'}</td>
                                    <td>
                                        {' '}
                                        {deliveryData === null
                                            ? '-'
                                            : lCode === 'en'
                                              ? moment(deliveryData).format('MM/DD/YYYY')
                                              : moment(deliveryData).format('DD/MM/YYYY')}
                                    </td>
                                    <td className='last_tr'>
                                        {supplierId === null ? (
                                            <Tooltip
                                                title={t('order_details').toUpperCase()}
                                                placement='top'
                                                disableInteractive
                                            >
                                                <Link
                                                    to={'order-details'}
                                                    state={{ state: item }}
                                                    onClick={() => {
                                                        sessionStorage.setItem('supplier_order_id', item?.id);
                                                    }}
                                                >
                                                    <img style={{ cursor: 'pointer' }} src={eyeIcon} alt='eye' />
                                                </Link>
                                            </Tooltip>
                                        ) : item?.statusId == 28 ? (
                                            <Tooltip
                                                title={t('complete_order').toUpperCase()}
                                                placement='top'
                                                disableInteractive
                                            >
                                                <Link
                                                    to='/dashboard/supplier/complete-order'
                                                    onClick={() => {
                                                        sessionStorage.setItem('supplier_order_id', item?.id);
                                                    }}
                                                >
                                                    <img style={{ cursor: 'pointer' }} src={eyeIcon} alt='eye' />
                                                </Link>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={t('order_details').toUpperCase()}
                                                placement='top'
                                                disableInteractive
                                            >
                                                <NavLink
                                                    to='/dashboard/supplier/order-detail'
                                                    onClick={() => {
                                                        sessionStorage.setItem('supplier_order_id', item?.id);
                                                    }}
                                                >
                                                    <img style={{ cursor: 'pointer' }} src={eyeIcon} alt='eye' />
                                                </NavLink>
                                            </Tooltip>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <NotInformation text={t('no_information')} card={true} />
            )}
        </div>
    );
};

export default TableViewSuppliers;
