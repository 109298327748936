export const fieldModel = {
    1: 'user',
    2: 'user',
    3: 'user',
    4: 'user',
    5: 'user',
    6: 'user',
    7: 'user',
    8: 'user',
    9: 'user',
    10: 'user',
    21: 'vehicle',
    22: 'vehicle',
    23: 'vehicle',
    24: 'vehicle',
    25: 'vehicle',
    26: 'vehicle',
    27: 'vehicle',
    28: 'vehicle',
    31: 'device',
    32: 'device',
    33: 'device',
    34: 'device',
    41: 'user_access_log',
    42: 'user_access_log',
    43: 'user_access_log',
    44: 'user_access_log',
    45: 'user_access_log',
    46: 'user_access_log',
    47: 'user_access_log',
    51: 'vehicle_access_log',
    52: 'vehicle_access_log',
    53: 'vehicle_access_log',
    54: 'vehicle_access_log',
    55: 'vehicle_access_log',
    56: 'vehicle_access_log',
    57: 'contractor_company_document',
    58: 'contractor_company_document',
    59: 'supplier_company_document',
    60: 'supplier_company_document',
    61: 'employee_company_document',
    62: 'employee_company_document',
    63: 'supplier_vehicle_company_document',
    64: 'supplier_vehicle_company_document',
    65: 'contractor_vehicle_company_document',
    66: 'contractor_vehicle_company_document',
    67: 'department',
    68: 'role',
    69: 'zone',
    70: 'work_shift',
    71: 'contract',
    72: 'contract',
    73: 'contract',
    74: 'contract',
    75: 'contract',
    76: 'order',
    77: 'order',
    78: 'order',
    79: 'order',
    80: 'order',
    81: 'order',
    82: 'order',
    83: 'order',
    84: 'onboarding',
    85: 'onboarding',
    86: 'onboarding',
    87: 'notification',
    88: 'notification',
    89: 'notification',
    91: 'user',
    92: 'user',
    93: 'user',
    94: 'user',
    95: 'user',
    96: 'user',
    97: 'user',
    98: 'user',
    99: 'user',
    100: 'user',
    101: 'contractor',
    102: 'contractor',
    103: 'contractor',
    104: 'user',
    105: 'user',
    106: 'user',
    107: 'user',
    108: 'user',
    109: 'user',
    110: 'user',
    111: 'user',
    112: 'user',
    113: 'user',
    114: 'supplier',
    115: 'supplier',
    116: 'supplier',
    117: 'user',
    118: 'user',
    119: 'user',
    120: 'user',
    121: 'user',
    122: 'user',
    123: 'user',
    124: 'user',
    125: 'user',
    126: 'user',
    127: 'employee',
    128: 'employee',
    129: 'employee',
    130: 'employee',
    131: 'employee',
    132: 'employee',
    133: 'event',
    134: 'event',
    135: 'event',
    136: 'event',
    137: 'event',
    138: 'event',
    139: 'event',
    140: 'event',
    141: 'event',
    142: 'event',
    155: 'attendance',
    156: 'attendance',
    157: 'attendance',
    158: 'attendance',
    159: 'attendance',
    160: 'attendance',
    161: 'attendance',
    162: 'attendance',
    163: 'attendance',
    164: 'attendance',
    170: 'template',
    171: 'template',
    172: 'template',
    176: 'resource',
    177: 'resource',
    178: 'user',
    179: 'user',
    180: 'user',
    181: 'user',
    182: 'user',
    183: 'user',
    184: 'user',
    185: 'user',
    186: 'user',
    190: 'vehicle',
    191: 'vehicle',
    192: 'vehicle',
    193: 'vehicle',
    194: 'vehicle',
    195: 'vehicle',
    196: 'vehicle',
    197: 'vehicle',
    200: 'user',
    201: 'user',
    202: 'user',
    203: 'user',
    204: 'user',
    205: 'user',
    206: 'user',
    207: 'user',
    208: 'user',
    212: 'vehicle',
    213: 'vehicle',
    214: 'vehicle',
    215: 'vehicle',
    216: 'vehicle',
    217: 'vehicle',
    218: 'vehicle',
    219: 'vehicle',
    220: 'log_module',
    221: 'log_module',
    222: 'log_module',
    223: 'log_module',
    230: 'log_in_log',
    231: 'log_in_log',
    232: 'log_in_log',
    233: 'user',
    234: 'user',
    235: 'user',
    236: 'user',
    240: 'card',
    242: 'card',
    243: 'card',
    244: 'card',
    245: 'card',
};
