export const columnCode = {
    1: 'name',
    2: 'last_name',
    3: 'second_last_name',
    4: 'GENDER_ID',
    5: 'phone_number',
    6: 'email',
    7: 'DOB',
    8: 'ACRONYM_CONTRACTOR_COMPANY',
    9: 'CONTRACTOR_COMPANY_NAME',
    10: 'address',
    11: 'description',
};
