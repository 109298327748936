import React, { useEffect, useRef } from 'react';
import { Group, Rect, Transformer } from 'react-konva';

const Square = ({
    square,
    index,
    selectedShapeId,
    handleDragMove,
    handleShapeClick,
    properties,
    currentStage,
    setStageProperties,
    setShapeHeight,
    setShapeWidth,
}) => {
    const shapeRef = useRef(null);
    const trRef = useRef(null);
    const isSelected = square.id === selectedShapeId;

    const MAX_WIDTH = 305;
    const MAX_HEIGHT = 462;

    useEffect(() => {
        if (selectedShapeId === square.id) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedShapeId, square.id]);

    return (
        <Group key={square.id} id={`group_${square.id}`}>
            <Rect
                x={square.x}
                y={square.y}
                rotation={square.rotation}
                width={square.width}
                height={square.height}
                cornerRadius={square.cornerRadius}
                stroke={square.stroke}
                strokeWidth={square.strokeWidth}
                id={square.id}
                ref={shapeRef}
                fill={square.fill}
                draggable={isSelected}
                onDragMove={(e) => handleDragMove(e, index)}
                onClick={() => handleShapeClick(square.id, 'squares')}
                onTap={() => handleShapeClick(square.id, 'squares')}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    node.scaleX(1);
                    node.scaleY(1);

                    const newWidth = Math.round(node.width() * scaleX);
                    const newHeight = Math.round(node.height() * scaleY);

                    setShapeHeight(newHeight);
                    setShapeWidth(newWidth);

                    setStageProperties((prevProperties) => {
                        const currentProperties = prevProperties[currentStage];
                        const updatedSquares = [...currentProperties.combinedObject];

                        updatedSquares[index] = {
                            ...updatedSquares[index],
                            width: newWidth,
                            height: newHeight,
                            x: node.x(),
                            y: node.y(),
                            rotation: node.rotation(),
                        };

                        return {
                            ...prevProperties,
                            [currentStage]: {
                                ...currentProperties,
                                combinedObject: updatedSquares,
                            },
                        };
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={true}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width > MAX_WIDTH || newBox.height > MAX_HEIGHT) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Group>
    );
};

export default Square;
