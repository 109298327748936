import React, { useEffect, useState, Fragment } from 'react';
import {
    Dialog,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import { DatePicker, DateRangePicker, LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import userregular from '../../../../../assets/images/user-regular.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ClearButton from '../../../../../components/ClearButton';
import { status } from '../../../../../enums/statusEnum';
import { Checkbox, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddWorkShiftsEnrollment from '../Modal/AddWorkShiftsEnrollment';
import AddCustomizedSchedule from '../Modal/AddCustomizedSchedule';
import DeleteIcon from '../../../../../assets/images/redTrash.svg';
import dayId from '../../../../../hooks/dayId';
import { DeleteItemsApi } from '../../../../../reduxToolkit/Commons/CommonsApi';
import DeleteModal from '../../../../Modals/DeleteModal';
import { toast } from 'react-toastify';
import {
    DeleteUserFromWorkShift,
    DeleteContractFromWorkShift,
} from '../../../../../reduxToolkit/CompanyWorkShift/CompanyWorkShiftApi';
import { permissionObj } from '../../../../../Helpers/permission';

const Step4AccessRights = ({
    employeeData,
    onChange,
    setEmployeeData,
    userData,
    workShiftsList,
    setWorkShiftsList,
    customizedList,
    setCustomizedList,
    details,
    contractId,
    contractorItemId,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    const dispatch = useDispatch();

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    // const [workShiftsList, setWorkShiftsList] = useState([]);
    // const [customizedList, setCustomizedList] = useState([]);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [addWorkShiftsDialogShow, setAddWorkShiftsDialogShow] = useState(false);
    const [addCustomizedDialogShow, setAddCustomizedDialogShow] = useState(false);
    const [checkedWorkshift, setCheckedWorkshift] = useState(false);
    const [selectWorkshiftForDelete, setSelectWorkshiftForDelete] = useState([]);
    const [isAllCheckedWorkshift, setIsAllCheckedWorkshift] = useState(false);
    const [deleteWorkShiftShow, setDeleteWorkShiftShow] = useState(false);
    const [deleteCustomizedShow, setDeleteCustomizedShow] = useState(false);
    const [singleWorkshiftForDelete, setSingleWorkshiftForDelete] = useState([]);
    const [singleCustomizedForDelete, setSingleCustomizedForDelete] = useState([]);
    const [deleteSingleWorkShiftShow, setDeleteSingleWorkShiftShow] = useState(false);
    const [deleteSingleCustomizedShow, setDeleteSingleCustomizedShow] = useState(false);

    // const [selectWorkshiftsForDelete, setSelectWorkshiftsForDelete] = useState([]);
    const [selectCustomizedForDelete, setSelectCustomizedForDelete] = useState([]);

    // const [isAllCheckedWorkShifts, setIsAllCheckedWorkShifts] = useState(false)
    const [isAllCheckedCustomized, setIsAllCheckedCustomized] = useState(false);

    // this function control select all id or unSelect all
    const handleRemoveAllWorkshifts = (e) => {
        setIsAllCheckedWorkshift(e.target.checked);
        if (e.target.checked) {
            const selectAllIndex = workShiftsList.map((item, index) => {
                return index;
            });
            setSelectWorkshiftForDelete(selectAllIndex);
        } else {
            setSelectWorkshiftForDelete([]);
        }
    };

    // this function control select all index or unSelect all
    const handleRemoveAllCustomized = (e) => {
        setIsAllCheckedCustomized(e.target.checked);
        if (e.target.checked) {
            const selectAllIndex = customizedList.map((item, index) => {
                return index;
            });
            setSelectCustomizedForDelete(selectAllIndex);
        } else {
            setSelectCustomizedForDelete([]);
        }
    };

    // this function handle only specific id base on selection
    const handleCheckboxChangeWorkshift = (e) => {
        if (e.target.checked) {
            setSelectWorkshiftForDelete([...selectWorkshiftForDelete, parseInt(e.target.id)]);
        } else {
            setSelectWorkshiftForDelete(
                selectWorkshiftForDelete.filter((removeid) => removeid !== parseInt(e.target.id)),
            );
        }
    };

    // this function handle only specific index base on selection
    const handleCheckboxChangeCustomized = (e) => {
        if (e.target.checked) {
            setSelectCustomizedForDelete([...selectCustomizedForDelete, parseInt(e.target.id)]);
        } else {
            setSelectCustomizedForDelete(
                selectCustomizedForDelete.filter((removeid) => removeid !== parseInt(e.target.id)),
            );
        }
    };

    // this function handle only specific id base on selection
    const removeWorkshift = (element) => {
        // setAssignedList(assignedList.filter((item) => item !== element));
        setWorkShiftsList(workShiftsList.filter((item) => item?.id !== element?.id));
    };

    //this function remove customized access according to the index
    const removeCustomized = (index) => {
        setCustomizedList((prevArray) => prevArray.filter((item, i) => i !== index));
    };

    // this function handle only specific id base on selection
    const removeWorkshiftsSelected = () => {
        const selectedIds = selectWorkshiftForDelete; // Get the selected IDs

        // Filters items that do NOT have the selected IDs
        const updatedList = workShiftsList.filter((item) => !selectedIds.includes(item?.id));

        // Updates the work shift roster with the updated roster
        setWorkShiftsList(updatedList);
        setSelectWorkshiftForDelete([]);
        setIsAllCheckedWorkshift(false);
    };

    const removeCustomizedSelected = () => {
        const selectedIndex = selectCustomizedForDelete; // Get selected indices

        const updatedList = customizedList.filter((item, index) => {
            return !selectedIndex.includes(index); // Filter out items that are NOT in the selected indices
        });

        setCustomizedList(updatedList); // Update the list with the filtered items
        setSelectCustomizedForDelete([]); // Reset selected indices
        setIsAllCheckedCustomized(false); // Reset the "all selected" state
    };

    useEffect(() => {
        if (isAllCheckedWorkshift) {
            setIsAllCheckedWorkshift(false);
        }
    }, [workShiftsList]);

    const deleteSelectedWorkShift = (deleteItem) => {
        const tableName = 'work_shift';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body })).then(() => {
            // dispatch(GetWorkTimeAccess({ id, contractPagination }));
            setSelectWorkshiftForDelete([]);
            setIsAllCheckedWorkshift(false);
            toast.success('Deleted Successfully');
        });
    };

    const deleteSelectedManageUser = (deleteItem) => {
        const selectedIndexNew = deleteItem.filter((index) => {
            return index >= 0 && index < workShiftsList.length && workShiftsList[index].new;
        });
        const updatedList = workShiftsList.filter((item, index) => {
            return !selectedIndexNew.includes(index);
        });
        setWorkShiftsList(updatedList);

        const body = deleteItem
            .map((index) => workShiftsList[index])
            .filter((item) => !item.new)
            .map((item) => item.id);
        if (body.length > 0) {
            if (contractorItemId === undefined) {
                const data = {
                    userIds: [userData.id],
                    workShiftIds: body,
                };
                dispatch(DeleteUserFromWorkShift(data)).then(
                    ({
                        payload: {
                            data: { success },
                        },
                    }) => {
                        if (success === true) {
                            const updatedList = workShiftsList.filter((item, index) => {
                                return !deleteItem.includes(index);
                            });
                            setWorkShiftsList(updatedList);
                        }
                    },
                );
            } else {
                const data = {
                    contractorIds: [contractorItemId],
                    workShiftIds: body,
                };
                dispatch(DeleteContractFromWorkShift(data)).then(
                    ({
                        payload: {
                            data: { success },
                        },
                    }) => {
                        if (success === true) {
                            const updatedList = workShiftsList.filter((item, index) => {
                                return !deleteItem.includes(index);
                            });
                            setWorkShiftsList(updatedList);
                        }
                    },
                );
            }
        }
        if (singleCustomizedForDelete.length > 0) {
            setSingleWorkshiftForDelete([]);
        } else {
            setSelectWorkshiftForDelete([]);
            setIsAllCheckedWorkshift(false);
        }
    };

    const deleteSelectedCustomized = (deleteItem) => {
        const tableName = contractId === undefined ? 'user_work_schedule' : 'contractor_work_schedule';
        const selectedIndexNew = deleteItem.filter((index) => {
            return index >= 0 && index < customizedList.length && customizedList[index].new;
        });
        const updatedList = customizedList.filter((item, index) => {
            return !selectedIndexNew.includes(index);
        });
        setCustomizedList(updatedList);
        const body = deleteItem
            .map((index) => customizedList[index])
            .filter((item) => !item.new)
            .map((item) => item.id);
        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    {
                        if (success === true) {
                            // const selectedIndex = deleteItem.filter((index) => {
                            //     return index >= 0 && index < customizedList.length && !customizedList[index].new;
                            // });
                            const updatedList = customizedList.filter((item, index) => {
                                return !deleteItem.includes(index);
                            });
                            setCustomizedList(updatedList);
                        }
                    }
                },
            );
        }
        if (singleCustomizedForDelete.length > 0) {
            setSingleCustomizedForDelete([]);
        } else {
            setSelectCustomizedForDelete([]);
            setIsAllCheckedCustomized(false);
        }
    };

    return (
        <>
            <div className='access-rights-enrollment'>
                <Grid container spacing={2.2}>
                    <Grid item xs={6} sm={6} alignItems={'center'} className='width50'>
                        {(userData?.id === '' ||
                            (userData?.id !== '' && permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))) && (
                            <div className='container-top-right-btns mb-1'>
                                <button
                                    className='delete-btn-1 mr-0'
                                    disabled={selectWorkshiftForDelete?.length === 0}
                                    // onClick={removeWorkshiftsSelected}
                                    id='btn_s4ar_remove'
                                    onClick={() => setDeleteWorkShiftShow(true)}
                                >
                                    <i className='fa fa-trash-o' aria-hidden='true'></i>
                                    {t('remove')}
                                </button>
                            </div>
                        )}

                        <span className='title-card'>{t('work_shifts')}</span>

                        {(userData?.id === '' ||
                            (userData?.id !== '' && permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))) && (
                            <div className='d-flex ml-1 pl-0 '>
                                <FormControlLabel
                                    className='grid-checkall mb-0 ml-1'
                                    control={
                                        <Checkbox
                                            className='mb-0'
                                            label='Label'
                                            checked={
                                                selectWorkshiftForDelete.length > 0 &&
                                                workShiftsList.length === selectWorkshiftForDelete.length
                                            }
                                            indeterminate={
                                                selectWorkshiftForDelete.length > 0 &&
                                                workShiftsList.length !== selectWorkshiftForDelete.length
                                            }
                                            onChange={handleRemoveAllWorkshifts}
                                            size='small'
                                        />
                                    }
                                    label={t('de_/_select_all')}
                                />
                            </div>
                        )}

                        <Card variant='outlined'>
                            <CardContent className='card-content-criteria pt-0'>
                                <List dense>
                                    {workShiftsList?.map((element, index) => (
                                        <ListItem
                                            className={
                                                userData?.id === '' ||
                                                (userData?.id !== '' &&
                                                    permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))
                                                    ? 'list-item-criteria'
                                                    : 'list-item-criteria pt-1 pb-1 pl-3'
                                            }
                                            key={index}
                                            // onClick={() => handleAssign(element)}
                                        >
                                            {(userData?.id === '' ||
                                                (userData?.id !== '' &&
                                                    permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))) && (
                                                <ListItemIcon>
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={selectWorkshiftForDelete?.includes(index)}
                                                        id={parseInt(index)}
                                                        onChange={handleCheckboxChangeWorkshift}
                                                        size='small'
                                                    />
                                                </ListItemIcon>
                                            )}
                                            <ListItemText primary={`${element?.name}`} />
                                            {(userData?.id === '' ||
                                                (userData?.id !== '' &&
                                                    permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))) && (
                                                <img
                                                    className='assign-icon'
                                                    // onClick={() => removeWorkshift(element)}
                                                    onClick={() => {
                                                        setDeleteSingleWorkShiftShow(true);
                                                        setSingleWorkshiftForDelete([index]);
                                                    }}
                                                    src={DeleteIcon}
                                                    alt='chevron_right_solid'
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                            {(userData?.id === '' ||
                                (userData?.id !== '' && permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))) && (
                                <CardActions className='card-actions'>
                                    <Button
                                        variant='text'
                                        id='btn_s4ar_addMore'
                                        onClick={() => setAddWorkShiftsDialogShow(true)}
                                    >
                                        {t('add_more').toUpperCase()}
                                    </Button>
                                </CardActions>
                            )}
                        </Card>
                    </Grid>

                    <Grid item xs={6} sm={6} className='width50'>
                        {(userData?.id === '' ||
                            (userData?.id !== '' && permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))) && (
                            <div className='container-top-right-btns mb-1'>
                                <button
                                    className='delete-btn-1 mr-0'
                                    disabled={selectCustomizedForDelete?.length === 0}
                                    // onClick={removeCustomizedSelected}
                                    onClick={() => setDeleteCustomizedShow(true)}
                                    id='btn_s4ar_remove'
                                >
                                    <i className='fa fa-trash-o' aria-hidden='true'></i>
                                    {t('remove')}
                                </button>
                            </div>
                        )}

                        <span className='title-card'>{t('customized')}</span>

                        {(userData?.id === '' ||
                            (userData?.id !== '' && permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))) && (
                            <div className='d-flex ml-1 pl-0'>
                                <FormControlLabel
                                    className='grid-checkall mb-0 ml-1'
                                    control={
                                        <Checkbox
                                            className='mb-0'
                                            label='Label'
                                            checked={
                                                selectCustomizedForDelete.length > 0 &&
                                                customizedList.length === selectCustomizedForDelete.length
                                            }
                                            indeterminate={
                                                selectCustomizedForDelete.length > 0 &&
                                                customizedList.length !== selectCustomizedForDelete.length
                                            }
                                            onChange={handleRemoveAllCustomized}
                                            size='small'
                                        />
                                    }
                                    label={t('de_/_select_all')}
                                />
                            </div>
                        )}

                        <Card variant='outlined'>
                            <CardContent className='card-content-criteria pt-0'>
                                <List dense>
                                    {customizedList.map(
                                        (element, index) =>
                                            element.added && (
                                                <ListItem
                                                    className={
                                                        userData?.id === '' ||
                                                        (userData?.id !== '' &&
                                                            permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))
                                                            ? 'list-item-criteria'
                                                            : 'list-item-criteria pt-1 pb-1 pl-3'
                                                    }
                                                    key={index}
                                                >
                                                    {(userData?.id === '' ||
                                                        (userData?.id !== '' &&
                                                            permission?.includes(
                                                                permissionObj?.WEB_EMPLOYEE_UPDATE,
                                                            ))) && (
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                className='grid-checkall checkbox'
                                                                checked={selectCustomizedForDelete?.includes(index)}
                                                                id={parseInt(index)}
                                                                onChange={handleCheckboxChangeCustomized}
                                                                size='small'
                                                            />
                                                        </ListItemIcon>
                                                    )}
                                                    <ListItemText
                                                        primary={`${dayId(parseInt(element?.dayId))}, ${
                                                            element?.from
                                                        } - ${element?.to}, ${element?.zoneName}`}
                                                    />
                                                    {(userData?.id === '' ||
                                                        (userData?.id !== '' &&
                                                            permission?.includes(
                                                                permissionObj?.WEB_EMPLOYEE_UPDATE,
                                                            ))) && (
                                                        <img
                                                            className='assign-icon'
                                                            // onClick={() => removeCustomized(index)}
                                                            onClick={() => {
                                                                setDeleteSingleCustomizedShow(true);
                                                                setSingleCustomizedForDelete([index]);
                                                            }}
                                                            src={DeleteIcon}
                                                            alt='chevron_right_solid'
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    )}
                                                </ListItem>
                                            ),
                                    )}
                                </List>
                            </CardContent>
                            {(userData?.id === '' ||
                                (userData?.id !== '' && permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE))) && (
                                <CardActions className='card-actions'>
                                    <Button
                                        variant='text'
                                        id='btn_s4ar_addMore'
                                        onClick={() => setAddCustomizedDialogShow(true)}
                                    >
                                        {t('add_more').toUpperCase()}
                                    </Button>
                                </CardActions>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <AddWorkShiftsEnrollment
                open={addWorkShiftsDialogShow}
                onClose={() => {
                    setAddWorkShiftsDialogShow(false);
                }}
                workshiftsList={workShiftsList}
                setWorkshiftsList={setWorkShiftsList}
            />
            <AddCustomizedSchedule
                open={addCustomizedDialogShow}
                onClose={() => {
                    setAddCustomizedDialogShow(false);
                }}
                customizedList={customizedList}
                setCustomizedList={setCustomizedList}
            />
            <DeleteModal
                show={deleteWorkShiftShow}
                onHide={() => setDeleteWorkShiftShow(false)}
                onClickFn={() => deleteSelectedManageUser(selectWorkshiftForDelete)}
                data={selectWorkshiftForDelete}
                description={'are_you_sure_you_want_to_remove'}
                title_modal={t('remove_workshifts')}
                element_modal={t('workshifts')}
            />
            <DeleteModal
                show={deleteSingleWorkShiftShow}
                onHide={() => setDeleteSingleWorkShiftShow(false)}
                onClickFn={() => deleteSelectedManageUser(singleWorkshiftForDelete)}
                data={singleWorkshiftForDelete}
                title_modal={t('remove_workshift')}
                description={'are_you_sure_you_want_to_remove'}
                element_modal={t('workshift')}
            />
            <DeleteModal
                show={deleteCustomizedShow}
                onHide={() => setDeleteCustomizedShow(false)}
                // onClick={() => deleteSelectedCustomized(selectCustomizedForDelete)}
                onClickFn={() => deleteSelectedCustomized(selectCustomizedForDelete)}
                data={selectCustomizedForDelete}
                title_modal={t('remove_customizeds')}
                description={'are_you_sure_you_want_to_remove'}
                element_modal={t('customizeds_access')}
                // isReset={setSelectWorkshiftForDelete}
            />
            <DeleteModal
                show={deleteSingleCustomizedShow}
                onHide={() => setDeleteSingleCustomizedShow(false)}
                // onClick={() => deleteSelectedCustomized(selectCustomizedForDelete)}
                onClickFn={() => deleteSelectedCustomized(singleCustomizedForDelete)}
                data={singleCustomizedForDelete}
                title_modal={t('remove_customized')}
                description={'are_you_sure_you_want_to_remove'}
                element_modal={t('customized_access')}
                // isReset={setSelectWorkshiftForDelete}
            />
        </>
    );
};

export default Step4AccessRights;
