import React, { useEffect, useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import contact_img from '../assets/images/id-badge.svg';
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser } from '../Apis/Authentication';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSelector from './LanguageSelector';
import uaeFlag from '../assets/images/uaeFlag.png';
import franceFlag from '../assets/images/france.png';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import userDefault from '../assets/images/person-4.png';
import { Tooltip } from '@mui/material';
import { userTypeDrop } from '../enums/userTypeEnumDrop';
import { GetUserFullInformation } from '../reduxToolkit/Commons/CommonsApi';

const SidebarDropDownOption = ({ changestyle, hovereffect, isMenuOpen, menutop }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const { getUserFullInformation } = useSelector((state) => state.CommonsSlice);
    const userType = user?.data?.userType?.name;

    const languages = [
        {
            id: 2,
            name: 'spanish',
            code: 'sp',
            flag: uaeFlag,
        },
        {
            id: 1,
            name: 'english',
            code: 'en',
            flag: uaeFlag,
        },
        {
            id: 3,
            name: 'french',
            code: 'fr',
            flag: franceFlag,
        },
    ];

    const currentLanguageCode = Cookies.get('i18next') || 'en';
    const [currentLanguage, setCurrentLanguage] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        setCurrentLanguage(languages.find((l) => l.code === currentLanguageCode));
        document.body.dir = currentLanguage?.dir || 'ltr';
        document.body.style.textAlign = currentLanguageCode === 'ar' ? 'right' : 'left';
        document.title = t('app_title');
        dispatch(GetUserFullInformation(sessionStorage.getItem('id')));
    }, []);
    return (
        <>
            <Dropdown drop='end'>
                <Dropdown.Toggle
                    variant='success'
                    id='dropdown-basic'
                    style={{
                        textAlign: currentLanguageCode === 'ar' ? 'right' : 'left',
                    }}
                    className={'profile_option_menutop btnProfile'}
                >
                    <Tooltip title={!isMenuOpen ? user?.data?.name : ''} placement='right'>
                        <img
                            src={
                                getUserFullInformation.selfie
                                    ? `data:image/png;base64,${getUserFullInformation?.selfie}`
                                    : userDefault
                            }
                            alt='profile'
                            width={'48px'}
                            height={'48px'}
                            style={{
                                margin: isMenuOpen ? '0px 0px 0px 0px' : menutop ? '0 -10px' : '0px 0px 0px 0px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                minHeight: '48px',
                                minWidth: '48px',
                            }}
                        />
                    </Tooltip>

                    <span className='dropSpan' style={{ textTransform: 'capitalize', width: '100%' }}>
                        {isMenuOpen &&
                            `${user?.data?.name.slice(0, 16)} ${user?.data?.name?.length > 16 ? '...' : ''} `}
                        {menutop && `${user?.data?.name.slice(0, 16)} ${user?.data?.name?.length > 16 ? '...' : ''} `}
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu
                    className={`sidebar_option_container_side ${
                        (menutop === false &&
                            (currentLanguageCode === 'ar'
                                ? isMenuOpen
                                    ? 'leftArbic'
                                    : 'leftArbicClose'
                                : 'notleft')) ||
                        (menutop === true && (currentLanguageCode === 'ar' ? 'leftArbicM' : 'notleftM'))
                    }`}
                >
                    <div className='sidebar_option_container_header'>
                        <img src={contact_img} alt='contact_img' />
                        <div style={{ marginLeft: '0.5rem' }}>
                            <h6>{user?.data?.name}</h6>
                            <p>{t(userTypeDrop[user?.data?.userType?.id]).toUpperCase()}</p>
                        </div>
                    </div>

                    <div className='sidebar_option_body_item'>
                        <LanguageSelector isMenuOpen={isMenuOpen} menutop={menutop} />
                    </div>
                    <div className='sidebar_option_body_item'>
                        <Dropdown.Item href='#/action-3' className='ml-0'>
                            <div
                                className='sidebar_option_body_item m-0 p-0'
                                onClick={() => logoutUser(navigate, dispatch)}
                            >
                                <p className='font-user-card ml-0 pl-1'>{t('log_out')}</p>
                                <LogoutIcon className='logoutIS' />
                            </div>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default SidebarDropDownOption;
