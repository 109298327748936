import { fabClasses } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';

const SearchSlice = createSlice({
    name: 'search',
    initialState: {
        getAttributesFilter: [],
        getConditionFilter: [],
        getValuesByOptionAndField: [],
        getValuesByOptionFieldAndUserId: [],
        searchByFilters: [],
        searchTemplates: [],
        searchByFiltersCustom: [],
        getListSearchModels: [],
        getValuesToFilterLogByField: [],
        searchCrudLogByModel: [],
        simpleSearch: [],
        simpleSearchWithId: [],
    },
    reducers: {
        clearSearchFilters: (state, action) => {
            state.searchByFilters = []; // Restablecer los filtros de búsqueda a un objeto vacío
            state.searchByFiltersCustom = []; // Restablecer los filtros de búsqueda a un objeto vacío
            state.searchCrudLogByModel = [];
        },
    },
    extraReducers: {
        'search/getAttributesFilter/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAttributesFilter = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'search/getConditionFilter/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getConditionFilter = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'search/getValuesByOptionAndField/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getValuesByOptionAndField = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'search/getValuesByOptionFieldAndUserId/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getValuesByOptionFieldAndUserId = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'search/searchByFilters/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.searchByFilters = data?.data;
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
            } else if (status >= 400 && status < 600) {
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
                toast.error(t('error_when_searching').toUpperCase());
            }
        },
        'search/searchTemplates/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.searchTemplates = data?.data;
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
            } else if (status >= 400 && status < 600) {
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
                toast.error(t('error_when_searching').toUpperCase());
            }
        },
        'search/searchByFiltersCustom/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.searchByFiltersCustom = data?.data;
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
            } else if (status >= 400 && status < 600) {
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
                toast.error(t('error_when_searching').toUpperCase());
            }
        },
        'search/getListSearchModels/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getListSearchModels = data?.data;
            } else if (status >= 400 && status < 600) {
            }
        },
        'search/getValuesToFilterLogByField/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getValuesToFilterLogByField = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'search/searchCrudLogByModel/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.searchCrudLogByModel = data?.data;
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
            } else if (status >= 400 && status < 600) {
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
                toast.error(t('error_when_searching').toUpperCase());
            }
        },
        'search/SimpleSearch/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.simpleSearch = data?.data;
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
            } else if (status >= 400 && status < 600) {
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
                toast.error(t('error_when_searching').toUpperCase());
            }
        },
        'search/SimpleSearchWithId/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.simpleSearchWithId = data?.data;
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
            } else if (status >= 400 && status < 600) {
                window.postMessage(
                    {
                        type: 'sessionStorageChangeLoader',
                        newValueLoading: false,
                    },
                    '*',
                );
                toast.error(t('error_when_searching').toUpperCase());
            }
        },
        clearSearchFilters: (state) => {
            state.searchByFilters = [];
            state.searchByFiltersCustom = [];
            state.searchCrudLogByModel = [];
            state.simpleSearch = [];
        },
    },
});

export const { clearSearchFilters } = SearchSlice.actions;

export default SearchSlice.reducer;
