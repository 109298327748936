import React, { useEffect, useState, Fragment } from 'react';
import {
    Dialog,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { DatePicker, DateRangePicker, LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import {
    GetWorkTimeAccess,
    GetAllWorkSchdule,
} from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import {
    getWorkTimeAccess,
    getAllWorkSchdule,
} from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsSlice';
import TablePagination from '@mui/material/TablePagination';

import dayId from '../../../../../hooks/dayId';

import NotInformation from '../../../../../components/NotInformation';

const AddWorkShiftsEnrollment = ({
    open,
    onClose,
    workshiftsList,
    setWorkshiftsList,
    onFiltered,
    moduleId,
    option,
    finalArray,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch();

    const workShiftAccessTime = useSelector(getWorkTimeAccess);
    const workShiftSchdule = useSelector(getAllWorkSchdule);

    const [workshift, setWorkshift] = useState('');
    const [element, setElement] = useState([]);

    const textField = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& 	.MuiFormLabel-filled': {
            marginTop: '-5px',
        },
    };

    // Pagination
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderby, setOrderby] = useState('id');
    let workshiftPagination = {
        order: true,
        page: page,
        size: rowsPerPage,
        sortBy: orderby,
    };
    // End Pagination

    useEffect(() => {
        if (workshift) {
            dispatch(GetWorkTimeAccess({ id: workshift, contractPagination: workshiftPagination }));
        } else {
            return null;
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        setPage(0);
        setRowsPerPage(10);
    }, [workshift]);

    const handleAddWorkshift = (element) => {
        setWorkshiftsList([...workshiftsList, element]);
    };

    useEffect(() => {
        dispatch(GetAllWorkSchdule());
    }, []);
    return (
        <Dialog
            className='add-workshift-enrollment-dialog'
            open={open}
            onClose={onClose}
            maxWidth='xs'
            style={{ margin: '0 150px' }}
        >
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ padding: '10px', paddingRight: '30px', flex: '1' }} className='search-title'>
                        {t('add_work_shift')?.toUpperCase()}
                    </p>
                    <img
                        onClick={() => {
                            onClose();
                            setWorkshift('');
                        }}
                        className='modal-close'
                        src={cancel}
                        alt=''
                        style={{ padding: '3px', cursor: 'pointer', marginTop: '9px' }}
                    />
                </div>
            </DialogTitle>

            <DialogContent className='dialog-content mt-0 pt-0'>
                <div className='create-enrollment-data'>
                    <div className='form-field position-relative start-50 translate-middle w-50 mt-5'>
                        <Box className='requiredData' sx={inputBox}>
                            <FormControl fullWidth sx={textField}>
                                <InputLabel
                                    id='demo-simple-select-label'
                                    className='select-input-field'
                                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                >
                                    {t('schedule_access')}
                                </InputLabel>
                                <Select
                                    size='small'
                                    label={t('schedule_access')}
                                    value={workshift}
                                    onChange={(e) => {
                                        setWorkshift(e.target.value);
                                        let id = e.target.value;
                                        dispatch(GetWorkTimeAccess({ id, contractPagination: workshiftPagination }));
                                        setElement({
                                            id: e.target.value,
                                            name: workShiftSchdule.find((item) => item?.id === e.target.value).name,
                                        });
                                    }}
                                    sx={{ minWidth: '200px' }}
                                >
                                    {workShiftSchdule &&
                                        workShiftSchdule
                                            .filter(
                                                (item) =>
                                                    !workshiftsList?.some((selected) => selected?.id === item?.id),
                                            )
                                            .map((item) => (
                                                <MenuItem key={item?.id} value={item?.id}>
                                                    {item?.name}
                                                </MenuItem>
                                            ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>

                {workshift && (
                    <div className='panelTables animated-div px-1' style={{ width: '100%', paddingTop: '0rem' }}>
                        <div className='position-relative'>
                            <div className='access-sec mt-3'>
                                <span className='contractor-access-heading'>{t('access_in_the_work_shift')}</span>{' '}
                            </div>
                        </div>
                        {workShiftAccessTime?.totalElements !== 0 ? (
                            <table justify-content-center style={{ width: '100%', paddingTop: '1rem' }}>
                                <thead className='no-border-thead'>
                                    <th className='first_head'>{t('zones')?.toUpperCase()}</th>
                                    <th>{t('day')?.toUpperCase()}</th>
                                    <th>{t('from')?.toUpperCase()}</th>
                                    <th>{t('to')?.toUpperCase()}</th>
                                </thead>
                                <tbody>
                                    {workShiftAccessTime &&
                                        workShiftAccessTime?.content?.map((item) => {
                                            return (
                                                <tr container sx={{ mt: 1 }}>
                                                    <td className='first_head'>{item?.zoneName || '-'}</td>
                                                    <td>{dayId(item?.dayId)}</td>
                                                    <td>{item?.from || '-'}</td>
                                                    <td>{item?.to || '-'}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                                <div className='d-flex justify-content-center'>
                                    <TablePagination
                                        component='div'
                                        rowsPerPageOptions={[5, 10, 15]}
                                        labelRowsPerPage={t('accesses_per_page')}
                                        count={workShiftAccessTime?.totalElements}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        style={{ paddingLeft: '300px', overflow: 'hidden' }}
                                    />
                                </div>
                            </table>
                        ) : (
                            <div className='no_content'>
                                <NotInformation text={t('no_information')} card={false} />
                            </div>
                        )}

                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => {
                                    onClose();
                                    setWorkshift('');
                                }}
                                style={{ color: '#BC0000' }}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                onClick={() => {
                                    handleAddWorkshift(element);
                                    onClose();
                                    setWorkshift('');
                                }}
                            >
                                {t('confirm').toUpperCase()}
                            </button>
                        </div>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AddWorkShiftsEnrollment;

const inputBox = {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    height: '50px',
};
