import { Box, Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import SubHeaderNav from '../../../components/SubHeaderNav';
import CustomTextWithLine from '../../../components/CustomTextWithLine';
import { useTranslation } from 'react-i18next';
import ClearButton from '../../../components/ClearButton';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserRestriction, UpdateUserRestriction } from '../../../reduxToolkit/restrictions/RestrictionApi';
import { useEffect } from 'react';
import { useState } from 'react';
import BackArrow from '../../../components/BackArrow';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
const UserRestriction = () => {
    // hook
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // useState - ExtraData
    // const [extraDataExternal, setExtraDataExternal] = useState(false);
    const [extraDataSupplier, setExtraDataSupplier] = useState(null);
    const [extraDataContractor, setExtraDataContractor] = useState(null);
    const [extraDataEmployee, setExtraDataEmployee] = useState(null);
    //useState - Send Email
    const [sendEmailWhenCreateContractor, setSendEmailWhenCreateContractor] = useState(false);
    const [sendEmailWhenCreateEmployee, setSendEmailWhenCreateEmployee] = useState(false);
    const [sendEmailWhenCreateSupplier, setSendEmailWhenCreateSupplier] = useState(false);
    const [sendEmailWhenUpdateContractor, setSendEmailWhenUpdateContractor] = useState(false);
    const [sendEmailWhenUpdateEmployee, setSendEmailWhenUpdateEmployee] = useState(false);
    const [sendEmailWhenUpdateSupplier, setSendEmailWhenUpdateSupplier] = useState(false);
    const [livenessEmployee, setLivenessEmployee] = useState(false);
    const [validationEmployee, setValidationEmployee] = useState(false);
    const [biometricEmployee, setBiometricEmployee] = useState(false);
    const [livenessContractor, setLivenessContractor] = useState(false);
    const [validationContractor, setValidationContractor] = useState(false);
    const [biometricContractor, setBiometricContractor] = useState(false);
    const [livenessSupplier, setLivenessSupplier] = useState(false);
    const [validationSupplier, setValidationSupplier] = useState(false);
    const [biometricSupplier, setBiometricSupplier] = useState(false);
    const [livenessVisitor, setLivenessVisitor] = useState(false);
    const [validationVisitor, setValidationVisitor] = useState(false);
    const [biometricVisitor, setBiometricVisitor] = useState(false);

    // use Reducer
    const { getUserRestriction, updateUserRestriction } = useSelector((state) => state?.RestrictionSlice);
    // functions
    const clearAllCheck = () => {
        setExtraDataEmployee(false);
        setExtraDataSupplier(false);
        setExtraDataContractor(false);
        setSendEmailWhenCreateContractor(false);
        setSendEmailWhenCreateEmployee(false);
        setSendEmailWhenCreateSupplier(false);
        setSendEmailWhenUpdateContractor(false);
        setSendEmailWhenUpdateEmployee(false);
        setSendEmailWhenUpdateSupplier(false);
        setLivenessEmployee(false);
        setValidationEmployee(false);
        setBiometricEmployee(false);
        setLivenessContractor(false);
        setValidationContractor(false);
        setBiometricContractor(false);
        setLivenessSupplier(false);
        setValidationSupplier(false);
        setBiometricSupplier(false);
        setLivenessVisitor(false);
        setValidationVisitor(false);
        setBiometricVisitor(false);
    };
    // update
    const handelUpdate = () => {
        const data = {
            extraDataEmployee,
            extraDataSupplier,
            extraDataContractor,
            id: getUserRestriction?.id,
            sendEmailWhenCreateContractor,
            sendEmailWhenCreateEmployee,
            sendEmailWhenCreateSupplier,
            sendEmailWhenUpdateContractor,
            sendEmailWhenUpdateEmployee,
            sendEmailWhenUpdateSupplier,
            livenessEmployee,
            validationEmployee,
            biometricEmployee,
            livenessContractor,
            validationContractor,
            biometricContractor,
            livenessSupplier,
            validationSupplier,
            biometricSupplier,
            livenessVisitor,
            validationVisitor,
            biometricVisitor,
        };
        const response = dispatch(UpdateUserRestriction(data));
    };
    // life cycle
    useEffect(() => {
        dispatch(GetUserRestriction());
    }, [updateUserRestriction]);
    // while update
    useEffect(() => {
        setExtraDataEmployee(getUserRestriction?.extraDataEmployee);
        setExtraDataSupplier(getUserRestriction?.extraDataSupplier);
        setExtraDataContractor(getUserRestriction?.extraDataContractor);
        setSendEmailWhenCreateEmployee(getUserRestriction?.sendEmailWhenCreateEmployee);
        setSendEmailWhenUpdateEmployee(getUserRestriction?.sendEmailWhenUpdateEmployee);
        setSendEmailWhenCreateContractor(getUserRestriction?.sendEmailWhenCreateContractor);
        setSendEmailWhenUpdateContractor(getUserRestriction?.sendEmailWhenUpdateContractor);
        setSendEmailWhenCreateSupplier(getUserRestriction?.sendEmailWhenCreateSupplier);
        setSendEmailWhenUpdateSupplier(getUserRestriction?.sendEmailWhenUpdateSupplier);
        //her
        setLivenessEmployee(getUserRestriction?.livenessEmployee);
        setValidationEmployee(getUserRestriction?.validationEmployee);
        setBiometricEmployee(getUserRestriction?.biometricEmployee);
        setLivenessContractor(getUserRestriction?.livenessContractor);
        setValidationContractor(getUserRestriction?.validationContractor);
        setBiometricContractor(getUserRestriction?.biometricContractor);
        setLivenessSupplier(getUserRestriction?.livenessSupplier);
        setValidationSupplier(getUserRestriction?.validationSupplier);
        setBiometricSupplier(getUserRestriction?.biometricSupplier);
        setLivenessVisitor(getUserRestriction?.livenessVisitor);
        setValidationVisitor(getUserRestriction?.validationVisitor);
        setBiometricVisitor(getUserRestriction?.biometricVisitor);
    }, [getUserRestriction?.id]);
    return (
        <Box className='restriction'>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={'/dashboard/employee/all-employees'} />
                    <h2>{t('user_restriction')}</h2>
                </div>
            </div>

            {/* <BackArrow isNav={true} linkTo={'user-restriction'} title={t("user_restriction")} /> */}
            {/* <SubHeaderNav isNav={true} linkTo={'user-restriction'} title={t("user_restriction")} /> */}
            <Box sx={{ padding: '2rem 4rem 0rem 4rem' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        className='custom_primary_btn_dark'
                        style={{ minWidth: '230px', marginBottom: '20px', marginTop: '10px' }}
                        onClick={() => handelUpdate()}
                    >
                        {t('update').toUpperCase()}
                    </button>
                </Box>
                <CustomTextWithLine title={t('options')} />
                <Box className='clearButton'>
                    <ClearButton
                        flagTooltip={true}
                        textTooltip={t('clean_all_inputs')}
                        handleClear={() => clearAllCheck()}
                    />
                </Box>

                <Grid
                    fullWidth
                    direction='flex'
                    alignItems='center'
                    justifyContent='center'
                    container
                    paddingTop='2rem'
                >
                    <Grid container className='containerRestriction'>
                        {/* Cuatro checkboxes en un lado */}
                        <Grid className='column-width' item md={6} marginRight={'3vw'}>
                            <Typography className='customTypography' variant='body1'>
                                {t('for_employees')?.toUpperCase()}
                            </Typography>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={extraDataEmployee}
                                    onChange={(e) => setExtraDataEmployee(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>{t('extra_data_for_employees')?.toUpperCase()}</Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={sendEmailWhenCreateEmployee}
                                    onChange={(e) => setSendEmailWhenCreateEmployee(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('send_email_to_employee_when_create')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={validationEmployee}
                                    onChange={(e) => setValidationEmployee(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('identity_validation_for_employee')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={livenessEmployee}
                                    onChange={(e) => setLivenessEmployee(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('liveness_validation_for_employee')?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Dos checkboxes al frente */}
                        <Grid className='column-width top-w' item md={6} marginLeft={'3vw'}>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={sendEmailWhenUpdateEmployee}
                                    onChange={(e) => setSendEmailWhenUpdateEmployee(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('send_email_to_employee_when_update')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={biometricEmployee}
                                    onChange={(e) => setBiometricEmployee(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('biometric_validation_for_employee')?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className='containerRestriction'>
                        {/* Cuatro checkboxes en un lado */}
                        <Grid className='column-width' item md={6} marginRight={'3vw'}>
                            <Typography className='customTypography' variant='body1'>
                                {t('for_suppliers')?.toUpperCase()}
                            </Typography>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={extraDataSupplier}
                                    onChange={(e) => setExtraDataSupplier(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>{t('extra_data_for_suppliers')?.toUpperCase()}</Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={sendEmailWhenCreateSupplier}
                                    onChange={(e) => setSendEmailWhenCreateSupplier(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('send_email_to_supplier_when_create')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={livenessSupplier}
                                    onChange={(e) => setLivenessSupplier(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('liveness_validation_for_supplier')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={validationSupplier}
                                    onChange={(e) => setValidationSupplier(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('identity_validation_for_supplier')?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Dos checkboxes al frente */}
                        <Grid className='column-width top-w' item md={6} marginLeft={'3vw'}>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={biometricSupplier}
                                    onChange={(e) => setBiometricSupplier(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('biometric_validation_for_supplier')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={sendEmailWhenUpdateSupplier}
                                    onChange={(e) => setSendEmailWhenUpdateSupplier(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('send_email_to_supplier_when_update')?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className='containerRestriction'>
                        {/* Cuatro checkboxes en un lado */}
                        <Grid className='column-width' item md={6} marginRight={'3vw'}>
                            <Typography className='customTypography' variant='body1'>
                                {t('for_contractors')?.toUpperCase()}
                            </Typography>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={sendEmailWhenCreateContractor}
                                    onChange={(e) => setSendEmailWhenCreateContractor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('send_email_to_contractor_when_create')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={extraDataContractor}
                                    onChange={(e) => setExtraDataContractor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('extra_data_for_contractors')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={sendEmailWhenUpdateContractor}
                                    onChange={(e) => setSendEmailWhenUpdateContractor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('send_email_to_contractor_when_update')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={livenessContractor}
                                    onChange={(e) => setLivenessContractor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('liveness_validation_for_contractor')?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Dos checkboxes al frente */}
                        <Grid className='column-width top-w' item md={6} marginLeft={'3vw'}>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={validationContractor}
                                    onChange={(e) => setValidationContractor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('identity_validation_for_contractor')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={biometricContractor}
                                    onChange={(e) => setBiometricContractor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('biometric_validation_for_contractor')?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className='containerRestriction'>
                        {/* Dos checkboxes en un lado */}
                        <Grid className='column-width' item md={6} marginRight={'3vw'}>
                            <Typography className='customTypography' variant='body1'>
                                {t('for_visitors')?.toUpperCase()}
                            </Typography>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={livenessVisitor}
                                    onChange={(e) => setLivenessVisitor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('liveness_validation_for_visitor')?.toUpperCase()}
                                </Typography>
                            </Box>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={validationVisitor}
                                    onChange={(e) => setValidationVisitor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('identity_validation_for_visitor')?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Un checkbox al frente */}
                        <Grid className='column-width top-w' item md={6} marginLeft={'3vw'}>
                            <Box className='check_box_with_title'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={biometricVisitor}
                                    onChange={(e) => setBiometricVisitor(e.target.checked)}
                                    size='small'
                                />
                                <Typography variant='body1'>
                                    {t('biometric_validation_for_visitor')?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>{' '}
                </Grid>
            </Box>
        </Box>
    );
};

export default UserRestriction;
