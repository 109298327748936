import { t } from 'i18next';
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ReusableTextField from '../../../../components/ReusableTextField ';
import { AdvanceSearchByFilters, CreateHidReader, CreateHidSioRecord } from '../../../../reduxToolkit/HID/HidApi';
import { useNavigate } from 'react-router-dom';
import { GetAllSiosByHidId, GetAllZones } from '../../../../reduxToolkit/HID/HidApi';

const CreateReaderForController = (props) => {
    const { controllerId, onHide, addNewReader, changed } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const controller = useSelector((state) => state.HidSlice.advanceSearchByFilters);
    const sioIds = useSelector((state) => state.HidSlice.getAllSiosByHidId);
    const getZones = useSelector((state) => state.HidSlice.getAllZones);

    const lCode = Cookies.get('i18next') || 'en';
    const [workshiftName, setWorkShiftName] = useState('');
    const [nameError, setNameError] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);

    const [address, setAddress] = useState(0);
    const [hidSioId, setHidSioId] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [accessType, setAccessType] = useState(0);
    const [hasKeyPad, setHasKeyPad] = useState(false);
    const [communicationProtocol, setCommunicationProtocol] = useState('');
    const [readerMode, setReaderMode] = useState(0);
    const [selectedZone, setSelectedZone] = useState('');
    const [readerNumber, setReaderNumber] = useState(0);
    const [relayOpen, setRelayOpen] = useState(0);
    const [keepOpen, setKeepOpen] = useState(5);

    const resetValues = () => {
        setAddress('');
        setHidSioId('');
        setName('');
        setId('');
        setAccessType(0);
        setHasKeyPad(false);
        setCommunicationProtocol('');
        setReaderMode(0);
        setSelectedZone('');
        setReaderNumber(0);
        setRelayOpen(0);
        setKeepOpen(0);
    };

    const handleRelayOpenChange = (event) => {
        setRelayOpen(parseInt(event.target.value, 10));
    };

    const handleChangeReaderNumber = (event) => {
        const value = parseInt(event.target.value, 10);
        setReaderNumber(value);

        let newAccessType = 0;
        let newAddress = 0;

        switch (value) {
            case 0:
            case 1:
                newAccessType = 1;
                newAddress = 0;
                break;
            case 2:
            case 3:
                newAccessType = 2;
                newAddress = 1;
                break;
            default:
                break;
        }

        setAccessType(newAccessType);
        setAddress(newAddress);
    };

    const handleChangeZone = (event) => {
        setSelectedZone(event.target.value);
    };
    const handleChangeReaderMode = (event) => {
        setReaderMode(event.target.value);
    };
    const handleChangeKeyPad = (event) => {
        setHasKeyPad(event.target.value === 'true');
    };

    const textField = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& 	.MuiFormLabel-filled': {
            marginTop: '-5px',
        },
    };

    //
    const handleNameChange = (value) => {
        if (value === '') {
            setNameError('name cannot be empty');
        } else {
            setNameError('');
        }

        setWorkShiftName(value);
    };

    const handleSubmit = () => {
        const body = {
            hidSioId: id,
            name: name,
            zoneName: selectedZone,
            deviceAccessTypeId: accessType,
            readerMode: readerMode,
            relay: relayOpen,
            readerNo: readerNumber,
            hasKeyPad: hasKeyPad,
            communicationProtocol: communicationProtocol,
            keepOpen: keepOpen,
            address: address,
        };

        dispatch(CreateHidReader(body))
            .then((response) => {
                const { payload } = response;
                if (payload && payload.data && payload.data.success === true) {
                    toast.success(t('Created_successfully'));

                    addNewReader(payload.data.newReader);
                    onHide();
                } else {
                    toast.error(t('Error_creating_SIO'));
                }
            })
            .catch((error) => {
                console.error(t('Error_creating_SIO'), error);
            });
    };

    useEffect(() => {
        dispatch(GetAllZones());
        dispatch(GetAllSiosByHidId({ hidId: controllerId }));
    }, [dispatch]);

    useEffect(() => {
        const body = {};
        dispatch(AdvanceSearchByFilters({ option: 'hid', body }))
            .then(({ payload }) => {
                if (payload?.data && payload?.status === 200 && payload?.data.success) {
                    const { data, success } = payload.data;
                } else {
                    console.error('Error fetching data: Response structure not as expected');
                }
            })
            .catch((error) => {
                console.error(t('Error_fetching_data:', error));
                setLoaded(false);
                setDeletedFlag(false);
            });
    }, [dispatch]);

    const handleControllerChange = (event) => {
        setHidSioId(event.target.value);
    };
    const handleIdChange = (event) => {
        setId(event.target.value);
    };
    useEffect(() => {
        if (controllerId) {
            dispatch(GetAllSiosByHidId({ hidId: controllerId })).catch((error) => {});
        }
    }, [controllerId, dispatch]);

    return (
        <Modal
            {...props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{ background: 'rgba(0,0,0,0.5)' }}
        >
            <div style={{ padding: '10px', width: '100%' }}>
                <button
                    id='btn_cr_back'
                    onClick={() => {
                        props.onHide();
                        setSubmitClicked(false);
                        resetValues();
                    }}
                    className='modal-close-btn'
                >
                    X
                </button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                    <span className='main-modal-heading'>{t('add_acr')}</span>
                </div>
                <div>
                    <span style={{ color: '#707070', fontSize: '10px', fontWeight: 'normal', textAlign: 'left' }}>
                        <span style={{ fontWeight: 'bold' }}>{props?.data?.name}</span>
                    </span>

                    <div className='mt-2' style={{ position: 'relative', padding: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    required
                                    label={t('name')}
                                    inputProps={{ maxLength: 128 }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    id='txt_cr_name'
                                />
                            </Grid>

                            <Grid item xs={6} style={{ width: '50%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>{t('SIO')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='slt_cr_id'
                                            label={t('SIO')}
                                            value={id}
                                            onChange={handleIdChange}
                                        >
                                            {Array.isArray(sioIds) &&
                                                sioIds.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name.toUpperCase()}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} style={{ width: '50%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>{t('zone')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='zone-select-label'
                                            id='slt_cr_zone'
                                            label={t('zone')}
                                            value={selectedZone}
                                            onChange={handleChangeZone}
                                        >
                                            {Array.isArray(getZones) &&
                                                getZones.map((zone) => (
                                                    <MenuItem key={zone.id} value={zone.id}>
                                                        {zone.name.toUpperCase()}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6} style={{ width: '50%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>{t('reader_n')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='reader-number-select-label'
                                            id='slt_crc_readerNumber'
                                            label={t('reader_n')}
                                            value={readerNumber}
                                            onChange={handleChangeReaderNumber}
                                        >
                                            <MenuItem value={0}>{t('reader1_entry')}</MenuItem>
                                            <MenuItem value={2}>{t('reader1_exit')}</MenuItem>
                                            <MenuItem value={1}>{t('reader2_entry')}</MenuItem>
                                            <MenuItem value={3}>{t('reader2_exit')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6} style={{ width: '50%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>{t('has_key_pad')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='slt_cr_hasKeyPad'
                                            label={t('has_key_pad')}
                                            value={hasKeyPad}
                                            onChange={handleChangeKeyPad}
                                        >
                                            <MenuItem value='true'>{t('YES')}</MenuItem>
                                            <MenuItem value='false'>{t('NO')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6} style={{ width: '50%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>
                                            {t('communication_protocol')}
                                        </InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='slt_cr_communicationProtocol'
                                            label={t('communication_protocol')}
                                            value={communicationProtocol}
                                            onChange={(event) => setCommunicationProtocol(event.target.value)}
                                        >
                                            <MenuItem value='OSDP_V2'>{t('OSDP V2')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6} style={{ width: '50%' }}>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    required
                                    id='txt_cr_relayOpen'
                                    label={t('relay_open')}
                                    name='relayOpen'
                                    type='number'
                                    inputProps={{ min: 0, max: 3, maxLength: 128 }}
                                    value={relayOpen}
                                    onChange={handleRelayOpenChange}
                                />
                            </Grid>

                            <Grid item xs={6} style={{ width: '50%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>{t('reader_mode')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='reader-mode-select-label'
                                            id='slt_cr_readerMode'
                                            value={readerMode}
                                            onChange={handleChangeReaderMode}
                                            label={t('reader_mode')}
                                        >
                                            <MenuItem value={1}>{t('DISABLE')}</MenuItem>
                                            <MenuItem value={2}>{t('UNLOCK')}</MenuItem>
                                            <MenuItem value={3}>{t('LOCKED')}</MenuItem>
                                            <MenuItem value={4}>{t('FACILITY_CODE')}</MenuItem>
                                            <MenuItem value={5}>{t('CARD_ONLY')}</MenuItem>
                                            <MenuItem value={6}>{t('PIN_ONLY')}</MenuItem>
                                            <MenuItem value={7}>{t('CARD_AND_PIN')}</MenuItem>
                                            <MenuItem value={8}>{t('CARD_OR_PIN')}</MenuItem>
                                            <MenuItem value={29}>{t('LINKING')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} style={{ width: '50%' }}>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    required
                                    label={t('keep_open')}
                                    id='txt_crc_keepOpen'
                                    name='keepOpen'
                                    type='number'
                                    inputProps={{ min: 5, max: 60 }}
                                    value={keepOpen}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        if (!isNaN(value) && value >= 5) {
                                            setKeepOpen(value);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className='btn-div' style={{ padding: '20px' }}>
                        <button
                            id='btn_cr_cancel'
                            className='custom_btn_cancel_gray_hover'
                            style={{ width: '100%' }}
                            onClick={() => {
                                props.onHide();
                                setSubmitClicked(false);
                                resetValues();
                            }}
                        >
                            {t('cancel')}
                        </button>
                        <button
                            id='btn_cr_add'
                            className='custom_primary_btn_dark'
                            style={{ width: '100%' }}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {t('add')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateReaderForController;
