export const MODELS = {
    Company: 1,
    Invitation: 2,
    Event: 3,
    Announcement: 4,
    Profile: 5,
    Contractor: 6,
    Supplier: 7,
    LogModule: 8,
    Employee: 9,
    EmployeeCompanyDocument: 10,
    SupplierCompanyDocument: 11,
    SupplierVehicleCompanyDocument: 12,
    WorkShift: 13,
    Privilege: 14,
    Zone: 16,
    Device: 17,
    Report: 18,
    BackUp: 19,
    HomeOffice: 20,
    Onboarding: 21,
    Hospitality: 22,
    EmailSettings: 23,
    User: 25,
    vehicle: 26,
    UserAccessLog: 27,
    VehicleAccessLog: 28,
    ContractorCompanyDocument: 29,
    ContractorVehicleCompanyDocument: 30,
    Department: 31,
    Contract: 32,
    Order: 33,
    Holliday: 34,
    Attendance: 35,
    Card: 36,
    Template: 37,
    Resource: 38,
    ContractorEmployee: 39,
    ContractorVehicle: 40,
    SupplierEmployee: 41,
    SupplierVehicle: 42,
    LogInLog: 43,
    LogModule: 8,
};
