export const Company = {
    acronym: '',
    address: '',
    createdAt: null,
    introduction: '',
    ip: null,
    id: null,
    name: '',
    image: null,
    statusId: null,
    updatedAt: null,
    latitud: null,
    longitud: null,
    mision: '',
    vision: '',
    noEmployees: null,
    noVehicles: null,
    noZones: null,

    toUpdate: function () {
        return {
            acronym: this.acronym,
            address: this.address,
            introduction: this.introduction !== '' ? this.introduction : null,
            id: this.id,
            name: this.name,
            statusId: this.statusId,
            latitud: this.latitud,
            longitud: this.longitud,
            mision: this.mision !== '' ? this.mision : null,
            vision: this.vision !== '' ? this.vision : null,
        };
    },
};
