import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, RadioGroup, Radio, Grid, InputLabel } from '@mui/material';
import cancel from '../../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateDashboard, GetGraphByUserId } from '../../../reduxToolkit/Graphs/GraphsApi';
import { TemplateUno, TemplateDos, TemplateTres, TemplateCuatro } from './../Charts/SectionRadioButton';

const AddDashboardModal = ({ show, onHide, templates, setChanged, changed }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.authenticatioauthennSlice.getUserByIdAtEntry.userId);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [dashboardName, setDashboardName] = useState('');
    const [getAvailable, setGetAvailable] = useState(false);
    const [availablePosition, setAvailablePosition] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch(GetGraphByUserId(userId)).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success === true) {
                    setData(data);
                }
            },
        );
    }, [dispatch, dashboardName]);
    useEffect(() => {
        if (data) {
            const usedPositions = new Set(data.map((item) => item.number));

            for (let i = 1; i <= 4; i++) {
                if (!usedPositions.has(i)) {
                    setAvailablePosition(i);

                    break;
                }
            }
        }
    }, [, getAvailable]);

    const handleCreate = async () => {
        if (availablePosition === null) {
            console.error('No available position found.');
            return;
        }

        const body = {
            name: dashboardName,
            number: availablePosition,
            templateId: selectedTemplate,
            userId: userId,
        };

        const result = await dispatch(CreateDashboard({ body }));
        const {
            data: { success },
        } = result.payload;

        if (success === true) {
            setChanged(true);
            onHide();
        }
    };

    const templateIdToComponent = {
        'b0dbd5a1-3bfa-4b23-871f-e159819414c1': <TemplateUno />,
        '0ba1b013-3111-4b0a-b5ba-1d74a1ff2a27': <TemplateDos />,
        '226d83d1-f82d-4be5-8347-f85aa7d483ea': <TemplateTres />,
        '6ebfbc2f-2532-4f44-b508-afaa9c514ec4': <TemplateCuatro />,
    };

    const handleRadioChange = (e) => {
        const id = e.target.value;
        setSelectedTemplate(id);

        setGetAvailable(true);
    };

    return (
        <Modal
            open={show}
            onClose={onHide}
            size='md'
            className='modal-dialog modal-lg modal-dialog-centered modal-content'
            style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
        >
            <Box sx={{ width: '500px', bgcolor: 'background.paper', p: 3 }}>
                <img className='close_icon' src={cancel} alt='close' onClick={onHide} style={{ cursor: 'pointer' }} />
                <Box>
                    <h4 className='header-graph' variant='h6'>
                        {t('add_dashboard')}
                    </h4>
                </Box>
                <Box mt={2}>
                    <h5 variant='body1' className='label-graph-top'>
                        {t('enterDashboardName')}
                    </h5>
                    <TextField
                        size='small'
                        fullWidth
                        label={t('dashboard_name')}
                        id='txt_adm_dashboardName'
                        value={dashboardName}
                        onChange={(e) => setDashboardName(e.target.value)}
                    />
                </Box>
                <Box mt={2}>
                    <h5 variant='body1' className='label-graph'>
                        {t('followTemplate')}
                    </h5>
                    <RadioGroup
                        className='graph-items-container'
                        aria-label={t('choose_a_template')}
                        name='template-selection'
                        value={selectedTemplate}
                        onChange={handleRadioChange}
                        id='rbtn_adm_template'
                    >
                        <Grid container spacing={2}>
                            {Object.keys(templateIdToComponent).map((id) => (
                                <Grid item xs={12} sm={6} md={3} key={id} className='template'>
                                    <div className='radioC'>
                                        <Radio className='radio-graph' checked={selectedTemplate === id} value={id} />
                                        <Box sx={{ mt: 2 }}>{templateIdToComponent[id]}</Box>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                </Box>
                <div className='d-flex mt-3' style={{ marginLeft: '10px' }}>
                    <button
                        style={{ width: '180px', height: '30px' }}
                        onClick={onHide}
                        className='custom_btn_cancel_gray_hover'
                        id='btn_adm_cancel'
                    >
                        {t('cancel')?.toUpperCase()}
                    </button>
                    <button
                        style={{ width: '180px', height: '30px' }}
                        className='custom_primary_btn_dark'
                        onClick={handleCreate}
                        disabled={!dashboardName}
                        id='rbtn_adm_confirm'
                    >
                        {t('confirm')?.toUpperCase()}
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default AddDashboardModal;
