export const Order = {
    deliveryDate: null,
    description: '',
    eta: null,
    folio: '',
    id: null,
    isDelivery: false,
    item: '',
    statusId: null,
    supplierEmployeeId: null,
    supplierId: '',
    supplierVehicleColor: null,
    supplierVehicleId: null,
    userReceivedId: null,

    toCreateUpdate: function () {
        return {
            deliveryDate:
                this.deliveryDate === null
                    ? null
                    : typeof this.deliveryDate === 'number'
                      ? this.deliveryDate
                      : this.deliveryDate.getTime(),
            description: this.description !== '' ? this.description : null,
            id: this.id,
            folio: this.folio !== '' ? this.folio : null,
            isDelivery: this.isDelivery,
            item: this.item,
            supplierId: this.supplierId,
        };
    },
};
