// Mui
import { Box, Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
// Modal
import DeleteModal from '../../Modals/DeleteModal';
import AddZoneModal from './Modal/AddZoneModal';
//Other Components
import NotInformation from '../../../components/NotInformation';
import ZonesCard from './ZonesCard';
// Redux Toolkit
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import { GetListFatherZones } from '../../../reduxToolkit/EmployeeZones/EmployeeZonesApi';
// Search Simple
import { SimpleSearch } from '../../../reduxToolkit/Search/SearchApi';
// Node Modules
import Cookies from 'js-cookie';
// React
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
// APIs
import { TABLES } from '../../../Apis/Tables';
//Helpers
import { permissionObj } from '../../../Helpers/permission';
import ExportDataBtn from '../../../components/buttons/ExportDataBtn';
import SearchSimple from '../../../components/SearchSimples/SearchSimple';

/*
Author : Arman Ali
Module: zone
github: https://github.com/Arman-Arzoo
website: https://www.toplinegeeks.com
*/

// Zone module main funtion
const Zones = () => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    // use hook importer
    const dispatch = useDispatch();

    //use Selector hook to get state for redux store
    const { getListFatherZones } = useSelector((state) => state.EmployeeZonesSlice);
    const { createFatherZone } = useSelector((state) => state.EmployeeZonesSlice);
    const { createChildZone } = useSelector((state) => state.EmployeeZonesSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { deleteItemsApi } = useSelector((state) => state.CommonsSlice);
    // use State hook for local state management
    const [modalShow, setModalShow] = useState(false);
    const [pageZone, setPageZone] = useState(0);
    const [rowsPerPageZone, setRowsPerPageZone] = useState(10);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [selectZoneForDelete, setSelectZoneForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [bodyElements, setBodyElements] = useState();
    const [userId, setUserId] = useState(sessionStorage.getItem('id'));
    const [isElementExists, setIsElementExists] = useState(true);

    // Simple Search
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState();
    // const body = { ...searchResults, userId: userId };
    const option = TABLES.ZONE;

    useEffect(() => {
        {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    sessionStorage.setItem('latitude', position.coords.latitude);
                    sessionStorage.setItem('longitude', position.coords.longitude);
                });
            } else {
            }
        }
    }, []);

    // custom Funtion
    // a funtion to control zone page
    const handleChangePageZone = (event, newPage) => {
        setPageZone(newPage);
    };
    // a funtion to control row per page
    const handleChangeRowsPerPageZone = (event) => {
        setRowsPerPageZone(parseInt(event.target.value));
        setPageZone(0);
    };

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getListFatherZones?.content?.map((item) => {
                return item?.id;
            });
            setSelectZoneForDelete(selectAllIds);
        } else {
            setSelectZoneForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectZoneForDelete([...selectZoneForDelete, e.target.id]);
        } else {
            setSelectZoneForDelete(selectZoneForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // delete workshif
    const deleteZone = (deleteItem) => {
        const tableName = 'zone';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body }));
    };

    // useEffect for api call incoming with pagination
    useEffect(() => {
        const body = {
            pagination: {
                order: sortBy === 'asc' ? true : false,
                page: pageZone,
                size: rowsPerPageZone,
                sortBy: orderBy ? orderBy : 'id',
            },
        };
        dispatch(GetListFatherZones(body)).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success === true) {
                    setSearchResults('');
                }
            },
        );
    }, [pageZone, rowsPerPageZone, orderBy, sortBy, createFatherZone, createChildZone, deleteItemsApi]);

    let resultsToShow;
    if (!searchResults || searchResults?.content?.length === 0) {
        resultsToShow = searchResults?.content?.length === 0 ? null : getListFatherZones;
    } else {
        resultsToShow = searchResults;
    }
    return (
        <>
            <section className='head'>
                <div className='headLeft'>
                    <h2>{t('first_access')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    {/* Cambiar la lógica por si se llega a implementar el AdvancedSearch */}
                    {searchResults && bodyForExport && <ExportDataBtn body={bodyForExport} option={false} />}
                    {permission?.includes(permissionObj?.WEB_ZONE_CREATE) && (
                        <button
                            style={{ width: '15%', height: '30px', cursor: 'pointer' }}
                            className='add-btn-1'
                            onClick={() => setModalShow(true)}
                        >
                            <i className='fa fa-plus' aria-hidden='true'></i>
                            {t('add')}
                        </button>
                    )}
                    {permission?.includes(permissionObj?.WEB_ZONE_DELETE) && (
                        <button
                            className='delete-btn-1'
                            disabled={selectZoneForDelete?.length === 0}
                            onClick={() => {
                                setDeleteShow(true);
                            }}
                        >
                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}
                </div>
            </section>
            <SearchSimple
                searchElement={searchElement}
                setSearchElement={setSearchElement}
                setIsElementExists={setIsElementExists}
                setSearchResults={setSearchResults}
                optionTable={option}
                page={pageZone}
                rowsPerPage={rowsPerPageZone}
                setBodyForExport={setBodyForExport}
            />
            <div className='panelTables animated-div px-1'>
                <table className='w-100'>
                    {resultsToShow?.content?.length > 0 && (
                        <thead className='no-border-thead'>
                            <tr className='table-header'>
                                <th className='first_head'>
                                    <Tooltip
                                        title={t('de_/_select_all').toUpperCase()}
                                        placement='top'
                                        disableInteractive
                                    >
                                        <Checkbox
                                            label='Label'
                                            checked={isAllChecked}
                                            onChange={handelDeleteAll}
                                            size='small'
                                        />
                                    </Tooltip>
                                </th>
                                <th>{t('name')}</th>
                                <th className='last'>{t('status')}</th>
                                <th className='last'>{t('details')}</th>
                                <th className='last'>{t('see_more')}</th>
                            </tr>
                        </thead>
                    )}
                </table>
            </div>
            {resultsToShow?.content?.length > 0 ? (
                <>
                    {resultsToShow?.content?.map((item, index) => {
                        // zone Card render list
                        return (
                            <ZonesCard
                                key={index}
                                item={item}
                                selectForDelete={selectZoneForDelete}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        );
                    })}
                </>
            ) : (
                <NotInformation text={t('no_information')} card={true} />
            )}
            {/* pagination for zone per page */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    left: '45%',
                    bottom: '0',
                }}
            >
                {resultsToShow?.content?.length > 0 && (
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[10, 15, 20, 30]}
                        count={resultsToShow?.totalElements}
                        page={pageZone}
                        onPageChange={handleChangePageZone}
                        labelRowsPerPage={t('zone_per_page')}
                        rowsPerPage={rowsPerPageZone}
                        onRowsPerPageChange={handleChangeRowsPerPageZone}
                    />
                )}
            </Box>
            {/* 
      Add Building Modal Start 
      this modal let you create a zone or sub zone 
      */}
            <AddZoneModal
                title={t('first_access')}
                sub_title={t('zone')}
                check='false'
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            {/* Add Building Modal End */}
            {/* Reemplazar el else o el if cuando se implmente bien el AdvancedSearch */}
            <DeleteModal
                show={deleteShow}
                onHide={() => setDeleteShow(false)}
                onClickFn={() => deleteZone(selectZoneForDelete)}
                data={selectZoneForDelete}
                title_modal={'first_access'}
                element_modal={'zone'}
                isReset={setSelectZoneForDelete}
                isAllReset={setIsAllChecked}
            />
        </>
    );
};
export default Zones;
