import React, { useState, useRef } from 'react';
import { Box, Grid, InputLabel, MenuItem, Select, Divider, TextField } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import SearchFor from '../Modals/SearchFor';
import { MODELS } from '../../Apis/Models';
import { TABLES } from '../../Apis/Tables';
import { SearchByFilters } from '../../reduxToolkit/Search/SearchApi';
import { status } from '../../enums/statusEnum';
import { toast } from 'react-toastify';
import { GoPrimitiveDot } from 'react-icons/go';
import { Checkbox, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import SettingButton from '../../components/SettingButton';
import SearchFilterBtn from '../../components/buttons/SearchFilterBtn';
import { permissionObj } from '../../Helpers/permission';
import { clearSearchFilters } from '../../reduxToolkit/Search/SearchSlice';
import AddResource from './modals/AddResource';
import employee_4 from '../../assets/defaultImages/userDef.svg';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import DeleteModal from '../Modals/DeleteModal';
import { DeleteItemsApi } from '../../reduxToolkit/Commons/CommonsApi';
import NotInformation from '../../components/NotInformation';
import { SimpleSearch } from '../../reduxToolkit/Search/SearchApi';
import SearchIcon from '@mui/icons-material/Search';
import SearchSimple from '../../components/SearchSimples/SearchSimple';
import ExportDataBtn from '../../components/buttons/ExportDataBtn';

const ResourcesPanel = (props) => {
    const lCode = Cookies.get('i18next') || 'en';
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { searchByFilters } = useSelector((state) => state.SearchSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [addResourseShow, setAddResourceShow] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [selectResourceForDelete, setSelectResourceForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [openViewer, setOpenViewer] = React.useState(false);
    const [currImg, setCurrImg] = useState(null);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [deleteResourceShow, setDeleteResourceShow] = useState(false);
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState('');
    const [resourceNames, setResourceNames] = useState([]);

    const option = `${TABLES.RESOURCES}`;

    let resultsToShow;

    if (
        !searchResults ||
        searchResults?.content?.length === 0 ||
        searchResults === null ||
        searchResults === undefined
    ) {
        if (!searchResults) {
            resultsToShow = searchByFilters;
        } else {
            resultsToShow = null;
        }
    } else if (searchResults?.content?.length > 0) {
        resultsToShow = searchResults;
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            filterResourcesByElement();
        }
    };

    const filterResourcesByElement = async () => {
        if (searchElement) {
            let body = {
                key: searchElement,
                model: TABLES.RESOURCES,
                pagination: {
                    page: 0,
                    size: 8,
                },
            };
            await dispatch(SimpleSearch({ body }))
                .then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            setSearchResults(data);
                        }
                        {
                        }
                    },
                )
                .catch((error) => {
                    console.log('Error in WORKSHIFT: ', error);
                });
        } else {
            setSearchResults('');
        }
    };
    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = searchByFilters?.content?.map((item) => {
                return item?.id;
            });
            setSelectResourceForDelete(selectAllIds);
        } else {
            setSelectResourceForDelete([]);
        }
    };

    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectResourceForDelete([...selectResourceForDelete, e.target.id]);
        } else {
            setSelectResourceForDelete(selectResourceForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    useEffect(() => {
        if (loaded && props.toggleState === 2) {
            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: [],
            };
            dispatch(SearchByFilters({ option, body }));
            setLoaded(false);
        }
    }, [loaded, page, rowsPerPage, props.toggleState, deletedFlag]);

    useEffect(() => {
        if (searchByFilters?.content) {
            const names = searchByFilters.content.map((item) => item.name);
            setResourceNames(names);
        }
    }, [searchByFilters]);

    const imageArray = (searchByFilters?.content || []).map((item) => ({
        src: `data:image/png;base64,${item?.image}`,
    }));

    // const index = imageArray.findIndex((item) => item.src === currImg);

    const deleteSelectedResources = (deleteItem) => {
        const tableName = 'resource';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        // toast.success(t('device_deleted_success'));
                        setLoaded(true);
                        setSelectResourceForDelete([]);
                        setIsAllChecked(false);
                    } else {
                    }
                },
            );
        }
    };

    return (
        <>
            <div className='providers'>
                <div
                    className='container-top-right-btns-template mb-1'
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    {/*  here */}
                    <section className='searchSimple-section--form'>
                        <SearchSimple
                            setBodyForExport={setBodyForExport}
                            setSearchResults={setSearchResults}
                            searchElement={searchElement}
                            setSearchElement={setSearchElement}
                            optionTable={option}
                            page={page}
                            rowsPerPage={rowsPerPage}
                        />
                    </section>
                    <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* ¿Tiene o no tendrá permisos? */}
                        <div></div>

                        <div style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                            {' '}
                            {permission?.includes(permissionObj?.WEB_RESOURCES_CREATE) && (
                                <button className='add-btn-1' onClick={() => setAddResourceShow(true)}>
                                    <i class='fa fa-plus' aria-hidden='true'></i>
                                    {t('create')}
                                </button>
                            )}
                        </div>
                        {permission?.includes(permissionObj?.WEB_RESOURCES_DELETE) && (
                            <button
                                className='delete-btn-1'
                                disabled={selectResourceForDelete?.length === 0}
                                onClick={() => {
                                    setDeleteResourceShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                        <div style={{ paddingLeft: '10px' }}>
                            {' '}
                            <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                        </div>
                    </section>
                </div>

                <div className='row  mt-0 ml-1 px-0'>
                    {resultsToShow?.content?.length > 0 && (
                        <div className='d-flex ml-0 pl-0'>
                            <FormControlLabel
                                className='grid-checkall'
                                control={
                                    <Checkbox
                                        label='Label'
                                        checked={isAllChecked}
                                        onChange={handelDeleteAll}
                                        size='small'
                                    />
                                }
                                label={t('de_/_select_all')}
                            />
                        </div>
                    )}
                    {resultsToShow?.content?.length > 0 ? (
                        resultsToShow?.content?.map((item) => (
                            <>
                                <div
                                    className='panel-grid  col-md-3 col-lg-2 px-0 pr-1 mb-3'
                                    key={item?.id}
                                    id={item?.id}
                                >
                                    <div className='card-base-grid mb-3 pb-2 h-100' style={{ padding: '10px' }}>
                                        <div className='top-heading-card'>
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={selectResourceForDelete?.includes(item?.id)}
                                                id={item?.id}
                                                onChange={handleCheckboxChange}
                                                size='small'
                                            />
                                        </div>
                                        <div
                                            className='card-body-grid px-2 pb-2 d-flex justify-content-center align-center'
                                            style={{ height: '80%' }}
                                        >
                                            <div className='img-resource'>
                                                <img
                                                    onClick={() => {
                                                        setOpenViewer(true);
                                                        setCurrImg(`data:image/png;base64,${item?.image}`);
                                                    }}
                                                    src={
                                                        item?.image != null
                                                            ? `data:image/png;base64,${item?.image}`
                                                            : employee_4
                                                    }
                                                    alt='employee_4'
                                                />
                                            </div>
                                            <div className='p-0'>
                                                <div className='card-resource-path d-flex justify-content-center align-center'>
                                                    <Tooltip title={item.name} placement='top' arrow>
                                                        <p className='mb-1 truncate-paragraph'>{item.name} </p>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                    ) : (
                        <NotInformation text={t('no_information')} card={true} />
                    )}
                </div>
                <div className='pagination-container'>
                    {resultsToShow?.content?.length > 0 && (
                        <div className='d-flex justify-content-center'>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={[12, 24, 36, 48]}
                                // count={resultsToShow?.total}
                                count={resultsToShow?.totalElements}
                                page={page}
                                onPageChange={handleChangePage}
                                labelRowsPerPage={t('resources_per_page')}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    )}{' '}
                </div>
                <div className='row'>
                    <div className='col-12'></div>
                </div>
            </div>

            <AddResource
                resourceNames={resourceNames}
                open={addResourseShow}
                onClose={() => {
                    setAddResourceShow(false);
                }}
                onUpdated={() => {
                    setLoaded(true);
                }}
            />

            <Lightbox
                open={openViewer}
                close={() => setOpenViewer(false)}
                slides={imageArray}
                index={imageArray.findIndex((item) => item.src === currImg)}
            />
            <DeleteModal
                show={deleteResourceShow}
                onHide={() => setDeleteResourceShow(false)}
                onClickFn={() => deleteSelectedResources(selectResourceForDelete)}
                data={selectResourceForDelete}
                title_modal={t('delete_resources')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('resource(s)')}
            />
        </>
    );
};
export default ResourcesPanel;
