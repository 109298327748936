import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const CommonsSlice = createSlice({
    name: 'commons',
    initialState: {
        deleteItemsApi: null,
        getAllMedia: [],
        getUserFullInformation: [],
        updateUserProfile: [],
        webSocketGuard: [],
    },
    reducers: {
        clearUserInformation: (state, action) => {
            state.getUserFullInformation = []; // Restablecer user information
        },
    },
    reducers: {
        clearUserInformation: (state, action) => {
            state.getUserFullInformation = []; // Restablecer user information
        },
    },
    extraReducers: {
        'commons/deleteItemsApi/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.deleteItemsApi = data || true;
                toast.success(t('has_been_deleted_successfully').toUpperCase());
            } else if (status >= 400 && status < 500) {
                toast.error(t('error_deleting').toUpperCase());
            }
        },
        'commons/getAllMedia/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllMedia = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'commons/getUserFullInformation/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getUserFullInformation = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'commons/updateUserProfile/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateUserProfile = data?.data;
                toast.success(t('has_been_updated_successfully').toUpperCase());
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_updating').toUpperCase());
            }
        },
        'commons/webSocketGuard/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.webSocketGuard = data;
            } else if (status >= 400 && status < 600) {
            }
        },
        clearUserInformation: (state) => {
            state.getUserFullInformation = []; // Restablecer user information
        },
    },
});

export const { clearUserInformation } = CommonsSlice.actions;

export default CommonsSlice.reducer;
