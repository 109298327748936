import { Grid, Checkbox, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ic_edit from '../../../assets/icon/ic-edit-pencil.svg';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    CreateAttendanceDepartment,
    GetAttendanceDepartments,
    GetDefaultAttendanceDepartment,
    UpdateAttendanceDepartment,
} from '../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import CustomTextWithLine from '../../../components/CustomTextWithLine';
import ShiftTimePicker from './ShiftTimePicker';
import DeleteScheduleModal from './Employees/Modal/DeleteScheduleModal';
import AddNewWorkshift from './Employees/Modal/AddNewWorkshift';
import UpdateSchedule from './Employees/Modal/UpdateSchedule';

const ScheduleSection = () => {
    const dispatch = useDispatch();
    const lCode = Cookies.get('i18next') || 'en';
    const [shiftPickers, setShiftPickers] = useState([]);
    const [updatedPickerState, setUpdatedPickerState] = useState({});
    const [flagUpdate, setFlagUpdate] = useState(false);
    const [attendance, setAttendance] = useState([]);
    const [dataUpdate, setDataUpdate] = useState();
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [createdFlag, setCreatedFlag] = useState(false);
    const [updatedFlag, setUpdatedFlag] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdatedModal, setShowUpdatedModal] = useState(false);
    const [changed, setChanged] = useState(false);

    const addShiftPicker = () => {
        setShiftPickers((prev) => [
            ...prev,
            {
                id: Date.now(),
                startShiftAt: null,
                endShiftAt: null,
                range: '',
                departmentsId: '',
            },
        ]);
    };

    const handleUpdate = (data) => {
        setDataUpdate(data);
        setShowUpdatedModal(true);
    };

    const removeShiftPicker = (id) => {
        setShiftPickers((prev) => prev.filter((picker) => picker.id !== id));
    };

    useEffect(() => {
        dispatch(GetAttendanceDepartments()).then(({ payload }) => {
            const { success, data } = payload.data;
            if (success) {
                setAttendance(data || []);
            }
        });
    }, [dispatch, createdFlag, deletedFlag, updatedFlag]);

    const updatePicker = (picker, key, value) => {
        const updatedPicker = { ...picker, [key]: value };
        setShiftPickers((prev) => prev.map((p) => (p.id === picker.id ? updatedPicker : p)));
        setUpdatedPickerState(updatedPicker);
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds((prev) => {
            const newSelectedIds = prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id];
            return newSelectedIds;
        });
    };
    const handleRemoveSchedule = () => {
        setShowDeleteModal(true);
    };
    const handleAddSchedule = () => {
        setShowCreateModal(true);
    };
    const nullDepartmentPickers = attendance.filter((picker) => picker.departmentId === null);
    const otherPickers = attendance.filter((picker) => picker.departmentId !== null);

    const sortedAttendance = [...nullDepartmentPickers, ...otherPickers];
    return (
        <>
            <div className='manage_attendence_container'>
                <div className='pt-4 pb-4'>
                    <CustomTextWithLine title={t('schedules')} />
                    <div className='container-top-right-btns'>
                        <button
                            className='delete-btn-1'
                            onClick={() => {
                                handleRemoveSchedule();
                            }}
                            id='btn_ss_delete'
                        >
                            <i className='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                        <button
                            className='add-btn-1'
                            onClick={() => {
                                handleAddSchedule();
                            }}
                            id='btn_ss_add'
                        >
                            <i class='fa fa-plus' aria-hidden='true'></i>
                            {t('add')}
                        </button>
                    </div>
                    <div className='row p-4' style={{ paddingTop: '1rem', paddingBottom: '1.5rem' }}>
                        <Grid container spacing={2}>
                            {sortedAttendance.map((picker) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={picker.id}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        marginLeft: picker.departmentId === null ? '35px' : '0',
                                    }}
                                >
                                    {picker.departmentId !== null && (
                                        <Checkbox
                                            className='grid-checkall checkbox'
                                            size='small'
                                            onChange={() => handleCheckboxChange(picker.id)}
                                            sx={{
                                                marginTop: '0px !important',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                            }}
                                        />
                                    )}
                                    <ShiftTimePicker
                                        startShiftAt={picker.startShiftAt}
                                        setStartShiftAt={(date) => updatePicker(picker, 'startShiftAt', date)}
                                        endShiftAt={picker.endShiftAt}
                                        setEndShiftAt={(date) => updatePicker(picker, 'endShiftAt', date)}
                                        range={picker.range}
                                        setRange={(value) => updatePicker(picker, 'range', value)}
                                        selectedDepartment={picker.departmentId}
                                        setSelectedDepartment={(value) => updatePicker(picker, 'departmentsId', value)}
                                        readOnly={true}
                                    />
                                    <Button
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            marginLeft: picker.departmentId === null ? '15px' : '0',
                                        }}
                                        id='btn_ss_editItem'
                                        variant='contained'
                                        onClick={() => handleUpdate(picker)}
                                        className='edit-item'
                                        aria-label={`Edit picker ${picker.id}`}
                                    >
                                        <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            </div>
            {showDeleteModal && (
                <DeleteScheduleModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    selectedIds={selectedIds}
                    changed={changed}
                    setChanged={setChanged}
                    flag={deletedFlag}
                    setDeletedFlag={setDeletedFlag}
                />
            )}
            {showCreateModal && (
                <AddNewWorkshift
                    show={showCreateModal}
                    onHide={() => setShowCreateModal(false)}
                    flag={createdFlag}
                    setCreatedFlag={setCreatedFlag}
                />
            )}
            {showUpdatedModal && (
                <UpdateSchedule
                    show={showUpdatedModal}
                    onHide={() => setShowUpdatedModal(false)}
                    flag={updatedFlag}
                    setUpdatedFlag={setUpdatedFlag}
                    dataUpdate={dataUpdate}
                />
            )}
        </>
    );
};

export default ScheduleSection;
