import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { ExportDataPDF } from '../../reduxToolkit/Files/FilesApi';
import { ExportDataExcel } from '../../reduxToolkit/Files/FilesApi';
import { ExportDataExcelCustom } from '../../reduxToolkit/Files/FilesApi';
import { ExportDataPDFCustom } from '../../reduxToolkit/Files/FilesApi';
import { useDispatch } from 'react-redux';
import pdf_image from '../../assets/images/pdf.svg';
import ic_excel from '../../assets/images/ic-excel.svg';

const ImportDataBtn = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));

    const handleClick = (e) => {
        props?.onClickFn(e);
    };

    return (
        <div>
            <button className='import-file-btn-1' onClick={handleClick}>
                {t('import_file')
                    .split(' ')
                    .map((word, index) => (
                        <React.Fragment key={index}>
                            {word}
                            <br />
                        </React.Fragment>
                    ))}
            </button>
        </div>
    );
};

export default ImportDataBtn;
