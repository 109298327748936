import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import mobileIcon from '../../assets/icon/ic-mobile.svg';
import UnlinkDeviceModal from '../../pages/Modals/UnlinkDeviceModal';
import { UnlinkUserDevice } from '../../reduxToolkit/Providers/providersApi';

const UnlinkDeviceBtn = ({ updated, userId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);

    const unlinkDevice = () => {
        dispatch(UnlinkUserDevice(userId)).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                if (success === true) {
                    updated();
                }
            },
        );
    };

    return (
        <div>
            <button className='delete-btn-1' onClick={() => setShowModal(true)}>
                <img
                    src={mobileIcon}
                    alt='mobileIcon'
                    style={{ width: '18px', height: '18px', marginBottom: '2px', marginLeft: '0px' }}
                />
                {t('unlink_device')}
            </button>

            <UnlinkDeviceModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onClickFn={() => unlinkDevice()}
                title_modal={t('unlink_device')}
                description={'unlink_confirmation_msg'}
                element_modal={t('employees')}
            />
        </div>
    );
};

export default UnlinkDeviceBtn;
