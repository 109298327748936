import TextField from '@mui/material/TextField';
import React from 'react';

const InputProperty = ({
    property,
    shapeProperty,
    disabledCondition,
    label,
    selectedShape,
    icon,
    value,
    handleChangeProperty,
    max,
}) => {
    const handleInputChange = (event) => {
        let newValue = Number(event.target.value);
        if (newValue > max) {
            newValue = max;
        }
        handleChangeProperty(shapeProperty, newValue);
    };
    return (
        <div className='d-flex align-items-center'>
            {/* <img className='mx-1' style={{ minWidth: '15px', height: '15px' }} src={icon} /> */}
            <TextField
                disabled={disabledCondition}
                type='number'
                size='small'
                fullWidth
                name={property}
                label={label}
                id={property}
                value={value}
                max={{ max: max }}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default InputProperty;
