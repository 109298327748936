import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TablePagination from '@mui/material/TablePagination';
import { clearSearchFilters } from '../../../../reduxToolkit/Search/SearchSlice';
import SearchSimple from '../../../../components/SearchSimples/SearchSimple';
import CreateSIOSModal from './CreateSIOSModal';
import NotInformation from '../../../../components/NotInformation';
import { permissionObj } from '../../../../Helpers/permission';
import { TABLES } from '../../../../Apis/Tables';
import { MODELS } from '../../../../Apis/Models';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { SearchByFilters } from '../../../../reduxToolkit/Search/SearchApi';
import { DeleteItemsApi } from '../../../../reduxToolkit/Commons/CommonsApi';
import { AdvanceSearchByFilters, DeleteRecordsByTableAndIds, GetSio } from '../../../../reduxToolkit/HID/HidApi';
import DeleteModal from '../../../Modals/DeleteModal';

const SIOS = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const lCode = Cookies.get('i18next') || 'en';
    const title_modal = `MODEL`;
    const element_modal = `Model`;
    const [modalShow, setModalShow] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchVehicle, setSearchVehicle] = useState('');
    const [view, setView] = useState('list');
    const [selectSioForDelete, setSelectSioForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteSioShow, setDeleteSioShow] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [importFileShow, setImportFileShow] = useState(false);
    const [creationErrorsShow, setCreationErrorsShow] = useState(false);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { uploadImage } = useSelector((state) => state.sharedSlice);
    const { searchByFilters } = useSelector((state) => state.SearchSlice);
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState('');
    const [workShiftModalShow, setWorkShiftModalShow] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [selectedSioForDelete, setSelectedSioForDelete] = useState([]);

    const moduleId = `${MODELS.hid}`;
    const option = 'hid_sio';

    const [criteria, setCriteria] = useState();
    useEffect(() => {
        setLoaded(false);
    }, [page, rowsPerPage, finalArray, deletedFlag]);
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);
    useEffect(() => {
        if (isAllChecked) {
            const selectAllIds = searchByFilters?.content?.map((item) => item?.id);
            setSelectSioForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [searchByFilters]);
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = searchByFilters?.content?.map((item) => {
                return item?.id;
            });
            setSelectSioForDelete(selectAllIds);
        } else {
            setSelectSioForDelete([]);
        }
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectSioForDelete([...selectedSioForDelete, e.target.id]);
        } else {
            setSelectSioForDelete(selectedSioForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        const currentUrl = window.location.pathname;
        const parts = currentUrl.split('/');
        const modulePart = parts[3] ? parts[3] : '' + parts[4] ? '/' + parts[4] : '';
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (loaded) {
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                };
            });

            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: criteriaList,
            };
            dispatch(SearchByFilters({ option, body })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    {
                    }
                },
            );
            setCriteria({ searchCriteriaList: criteriaList });
            setLoaded(false);
            setDeletedFlag(false);
        }
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray, deletedFlag]);

    const deleteSelectedSio = (deleteItem) => {
        const body = deleteItem;
        const table = 'hid';

        if (body.length > 0) {
            dispatch(DeleteRecordsByTableAndIds({ table, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectedSioForDelete([]);
                        setIsAllChecked(false);
                    } else {
                    }
                },
            );
        }
    };

    const deleteSio = (deleteItem) => {
        const tableName = 'hid';
        const body = deleteItem;
    };

    const handleCreateSios = () => {
        setWorkShiftModalShow(false);
        setLoaded(true);
    };
    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('SIOs')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    {permission?.includes(permissionObj?.WEB_HID_CREATE) && (
                        <button
                            id='btn_as_create'
                            className='add-btn-1'
                            onClick={() => {
                                setWorkShiftModalShow(true);
                            }}
                        >
                            <i className='fa fa-plus' aria-hidden='true'></i>
                            {t('add')}
                        </button>
                    )}
                    {permission?.includes(permissionObj?.WEB_HID_DELETE) && (
                        <button
                            id='btn_ds_delete'
                            className='delete-btn-1'
                            disabled={selectSioForDelete?.length === 0}
                            onClick={() => {
                                setDeleteSioShow(true);
                            }}
                        >
                            <i className='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}
                </div>
            </div>
            <SearchSimple
                setSearchResults={setSearchResults}
                page={page}
                optionTable={option}
                rowsPerPage={rowsPerPage}
                searchElement={searchElement}
                setSearchElement={setSearchElement}
                setBodyForExport={setBodyForExport}
            />

            <div className='panelTables px-1 animated-div' ref={elementRef}>
                {searchByFilters?.content?.length > 0 ? (
                    <table className='w-100'>
                        <thead className='no-border-thead'>
                            <tr>
                                {permission?.includes(permissionObj?.WEB_HID_DELETE) && (
                                    <th className='first_head'>
                                        <Tooltip title={t('de_/_select_all').toUpperCase()} placement='top'>
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={isAllChecked}
                                                onChange={handelDeleteAll}
                                                size='small'
                                            />
                                        </Tooltip>
                                    </th>
                                )}
                                <th className='first_head'>{t('name')}</th>

                                <th>{t('controller')}</th>
                                <th>{t('number')}</th>
                                <th>{t('sio_type')}</th>
                                <th>{t('device_type')}</th>
                            </tr>
                        </thead>
                        {searchByFilters?.content?.length > 0 &&
                            searchByFilters?.content?.map((item) => {
                                return (
                                    <tr key={item?.id}>
                                        {permission?.includes(permissionObj?.WEB_HID_DELETE) && (
                                            <td className='first' style={{ verticalAlign: 'middle', width: '50px' }}>
                                                <Checkbox
                                                    className='grid-checkall checkbox'
                                                    checked={selectSioForDelete?.includes(item?.id)}
                                                    id={item?.id}
                                                    onChange={handleCheckboxChange}
                                                    size='small'
                                                />
                                            </td>
                                        )}
                                        <td className='first'>{item?.name}</td>
                                        <td>{item?.hidName}</td>
                                        <td>{item?.number}</td>
                                        <td>{item?.sioType}</td>
                                        <td>{item?.deviceType}</td>
                                    </tr>
                                );
                            })}
                    </table>
                ) : (
                    <NotInformation text={t('no_information')} card={true} />
                )}
            </div>

            <CreateSIOSModal title={''} onHide={handleCreateSios} check='false' show={workShiftModalShow} />
            <DeleteModal
                show={deleteSioShow}
                onHide={() => setDeleteSioShow(false)}
                onClickFn={() => deleteSelectedSio(selectSioForDelete)}
                data={selectSioForDelete}
                title_modal={t('delete_sio')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('sios')}
            />
            {searchByFilters?.content?.length > 0 && (
                <div className='d-flex justify-content-center'>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[20, 40, 60]}
                        count={searchByFilters?.totalElements}
                        page={page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage={t('sios_per_page')}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}
        </>
    );
};

export default SIOS;
