// All color variables

export const colors = {
    'error-text-input': '#FF0000',
    'help-text-input': '#f2a100',
    'search-button': '#263238',
    'search-button-hover': '#091820',
    dark: '#146F62',
    'dark-hover': '#114E45',
    yellow: '#f2a100',
    'accent-grey': '#E1E1E1',
    grey: '#707070',
    background: '#FCFCFC',
    primary: '#178A7B',
    accent: '#65ABA0',
    'accent-hover': '#4E847C',
    'delete-hover': '#AC3E3D',
    'delete-red': '#E24C4B',
    white: '#ffffff',
    red: '#BC0000',
    green: '#0C4523',
    blue: '#006594',
    'dark-blue': '#004667',
    orange: '#EE8013',
    black: '#000000',
    transparent: '#00000000',
    'modal-grey': '#707070B3',
};
