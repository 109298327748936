function convertirDobAFechaString(timestamp) {
    const fecha = new Date(timestamp);
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear();

    const fechaString = `${año}-${mes}-${dia}`;

    return fechaString;
}

export default convertirDobAFechaString;
