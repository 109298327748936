import React from 'react';
import { t } from 'i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ReusableTextField from '../../../../components/ReusableTextField ';
import { AdvanceSearchByFilters, CreateFormat, CreateHidSioRecord, GetSio } from '../../../../reduxToolkit/HID/HidApi';
import { TABLES } from '../../../../Apis/Tables';
import { MODELS } from '../../../../Apis/Models';
import { useNavigate } from 'react-router-dom';
import { setSelectedSio } from '../../../../reduxToolkit/HID/HidSlice';

const CreateFormats = (props) => {
    const { controllerId, onHide, addNewFormat, changed } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const lCode = Cookies.get('i18next') || 'en';
    const [format, setFormat] = useState('');
    const [facilityCode, setFacilityCode] = useState('');

    const [nameError, setNameError] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const getSio = useSelector((state) => state.HidSlice.getSio);
    const getFormat = useSelector((state) => state.CardsSlice.getAllCardFormats);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        const body = {
            formatId: format,
            facilityCode: facilityCode,
            hidId: controllerId,
        };
        dispatch(CreateFormat(body)).then((response) => {
            const { payload } = response;
            if (payload && payload.data && payload.data.success === true) {
                toast.success(t('Format_created_successfully').toUpperCase());
                onHide();
                addNewFormat(payload.data.addNewFormat);
                resetValues();
            } else {
                toast.error(t('Error_creating_format').toUpperCase());
            }
        });
    };
    const resetValues = () => {
        setFormat('');
        setFacilityCode('');
    };
    return (
        <Modal
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{ background: 'rgba(0,0,0,0.5)' }}
        >
            <div style={{ padding: '20px', width: '100%' }}>
                <button
                    id='btn_cc_back'
                    onClick={() => {
                        props.onHide();
                        setSubmitClicked(false);
                        resetValues();
                    }}
                    className='modal-close-btn'
                >
                    X
                </button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                    <span className='main-modal-heading'>{t('add_format')}</span>
                </div>
                <div className='unlink-modal-body'>
                    <span
                        className='modal-desc-text'
                        style={{ color: '#707070', fontSize: '10px', fontWeight: 'normal', textAlign: 'left' }}
                    >
                        <span style={{ fontWeight: 'bold' }}>{props?.data?.name}</span>
                    </span>

                    <div className='mt-2' style={{ position: 'relative' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='device-type-select-label'>{t('format')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='device-type-select-label'
                                            id='slt_cc_name'
                                            label={t('format')}
                                            value={format}
                                            onChange={(e) => setFormat(e.target.value)}
                                        >
                                            {getFormat.map((format) => (
                                                <MenuItem key={format.id} value={format.id}>
                                                    {format.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>{' '}
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    required
                                    id='txt_cc_facilityCode'
                                    label={t('facility_code')}
                                    inputProps={{ maxLength: 128, inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={facilityCode}
                                    onChange={(e) => setFacilityCode(e.target.value.replace(/\D/g, ''))}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className='btn-div'>
                        <button
                            className='custom_btn_cancel_gray_hover'
                            style={{ width: '100%' }}
                            onClick={() => {
                                props.onHide();
                                setSubmitClicked(false);
                                resetValues();
                            }}
                        >
                            {t('cancel')}
                        </button>
                        <button className='custom_primary_btn_dark' style={{ width: '100%' }} onClick={handleSubmit}>
                            {t('add')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateFormats;
