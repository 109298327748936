import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';

export const companyDataToUpdate = createAsyncThunk('updateCompany/companyDataToUpdate', async (companyId) => {
    const result = await apiInstance
        .get(`company-service/get`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

export const getSelectRestrictionObj = createAsyncThunk('updateCompany/getSelectRestrictionObj', async () => {
    const result = await apiInstance
        .get('restriction-service/get-all')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

export const getCompanyRestrictionObj = createAsyncThunk('updateCompany/getCompanyRestrictionObj', async (id) => {
    const result = await apiInstance
        .get(`company-service/company-restriction/get-by-id/${id}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

export const updateCompany = createAsyncThunk('updateCompany/updateCompany', async (params) => {
    const result = await apiInstance
        .put('company-service/v1/app/update', params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

export const updateCompanyRestrictions = createAsyncThunk('updateCompany/updateCompanyRestrictions', async (params) => {
    const result = await apiInstance
        .put('company-service/company-restriction/update', params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});
