export const Supplier = {
    acronym: '',
    address: '',
    comment: null,
    supplierCompanyName: '',
    supplierStatusId: '',
    createdAt: null,
    description: '',
    deviceId: null,
    dob: null,
    email: '',
    firebaseId: null,
    genderId: '',
    id: null,
    lastName: '',
    name: '',
    password: null,
    phoneNumber: '',
    secondLastName: '',
    selfie: null,
    statusId: '',
    updatedAt: null,
    userId: null,
    documentId: '',

    toCreateUpdate: function () {
        return {
            acronym: this.acronym,
            address: this.address !== '' ? this.address : null,
            supplierCompanyName: this.supplierCompanyName,
            supplierStatusId: this.supplierStatusId !== '' ? this.supplierStatusId : null,
            description: this.description !== '' ? this.description : null,
            dob: this.dob === null ? null : typeof this.dob === 'number' ? this.dob : this.dob.getTime(),
            email: this.email,
            genderId: this.genderId !== '' ? this.genderId : null,
            id: this.id,
            lastName: this.lastName,
            name: this.name,
            phoneNumber: this.phoneNumber,
            secondLastName: this.secondLastName !== '' ? this.secondLastName : null,
            statusId: this.statusId !== '' ? this.statusId : null,
            userId: this.userId,
            documentId: this.documentId,
        };
    },
};
