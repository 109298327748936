/*
Author : Arman Ali
Module: Document Table
github: https://github.com/Arman-Arzoo
*/

// import libarary and other
import React from 'react';
import { t } from 'i18next';
import ProviderDropDown from '../Dashboard/Providers/SubComponents/providerDropDown';
import { useState, useEffect } from 'react';
import cryptoJs from 'crypto-js';
import securekey from '../../config';
// import icon
import downloadIcon from '../../assets/icon/DownloadIcon.svg';
import lineIcon from '../../assets/icon/LineIcon.svg';
import checkIcon from '../../assets/icon/checkIcon.svg';
import cancelIcon from '../../assets/icon/cancelIcon.svg';
import approveIcon from '../../assets/icon/ic-approve.svg';
import jpgIcon from '../../assets/fileIcon/jpg.png';
import pdfIcon from '../../assets/fileIcon/pdf.png';
import pngIcon from '../../assets/fileIcon/png.png';
import wordIcon from '../../assets/fileIcon/word.png';
import exelIcon from '../../assets/fileIcon/xls.png';
import { DownloadEmployeeProviderOrderFiles } from '../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTooltip from '../../utils/BootstrapTooltip';
import { Tooltip } from '@mui/material';
import DropDownMenuProfile from '../../components/DropDownMenuProfile';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ApproveDenyModal from '../Dashboard/Providers/ProviderModels/ApproveDenyModal';
import DenyDocumentModal from '../Dashboard/Company/Employees/Modal/DenyDocumentModal';
import ApproveDocumentModal from '../Dashboard/Company/Employees/Modal/ApproveDocumentModal';
import { DownloadDocumentById } from '../../reduxToolkit/DocumentPanel/DocumentPanelApi';
import { DownloadProviderImage } from '../../reduxToolkit/Providers/providersApi';
import { GetSingleProvider } from '../../reduxToolkit/Providers/providersApi';
import { userTypeDrop } from '../../enums/userTypeEnumDrop';
// Main Component
const DocumentTable = ({
    dataTable,
    approve,
    optionDownload,
    documentId,
    docValue,
    employeeName,
    updated,
    setUpdated,
    isVehicleDocuments,
}) => {
    // props type
    // dataTable: dataTable is the api fetched data,
    // approve : approve is a check mark for approve doc. is either true or false
    // optionDownload : optionDownload is the which type of doc e.g document-external
    // documentId: is the id of a document for set function.
    // docValue: is a props which tell us is employee or vehicle doc it value should be string valueType

    // check document object if we want more logic we add here
    const docType = {
        approve: approve,
    };
    // use hook
    const dispatch = useDispatch();

    const { getSingleProvider } = useSelector((state) => state?.providersSlice);
    // usestate for local state
    const [showModalDeny, setShowModalDeny] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);

    const [documentIdForApprove, setDocumentIdForApprove] = useState(null);
    const [documentIdForDeny, setDocumentIdForDeny] = useState(null);
    const [documentPathForApprove, setDocumentPathForApprove] = useState(null);
    const [documentPathForDeny, setDocumentPathForDeny] = useState(null);
    const [userId, setUserId] = useState(dataTable[0].userId);
    const [userType, setUserType] = useState(null);
    const [fullName, setFullName] = useState(null);

    // get data from sessionStorage and decrypt
    const employeedata = sessionStorage.getItem('employeeEntryData');
    const bytess = cryptoJs.AES.decrypt(employeedata || '', securekey);
    const userstring = bytess.toString(cryptoJs.enc.Utf8);
    const employee = userstring ? JSON.parse(userstring) : '';

    // pass this if you have dropdown inside table option
    const dropDownProps = {
        panel: 'providerFileOption',
        firstItem: 'DOWNLOAD FILE',
        secondItem: 'VIEW DETAILS ',
    };

    // Action Api dispatch
    // need logic here to dispatch different api base on documentType
    // return item from component

    useEffect(() => {
        dispatch(GetSingleProvider(userId)).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success) {
                    setUserType(data.userType.id);
                    setFullName(data.fullName);
                }
            },
        );
        // }
    }, []);
    return (
        <>
            <table className='approve_doc_table'>
                {/* table Head */}
                <thead className='no-border-thead'>
                    <th className='first_head'>{t('name')?.toUpperCase()}</th>
                    {docValue == 'valueType' && <th>{t('value')?.toUpperCase()}</th>}
                    <th>{t('file')?.toUpperCase()}</th>
                    <th>{docType?.approve ? t('options')?.toUpperCase() : t('status')?.toUpperCase()}</th>
                    {docType?.approve && <th>{t('comment')?.toUpperCase()}</th>}
                </thead>
                {/* table body */}
                <tbody>
                    {dataTable?.map((item) => {
                        const date = new Date(item?.createdAt);
                        const updatedAt = new Date(item?.updatedAt);
                        return (
                            <tr>
                                <td className='first'>
                                    <Tooltip
                                        title={item?.companyDocument?.length > 30 ? item?.companyDocument : ''}
                                        placement='top'
                                    >
                                        <strong>
                                            {item?.companyDocument.slice(0, 30)}{' '}
                                            {item?.companyDocument?.length > 30 && '...'}
                                        </strong>
                                    </Tooltip>
                                    {docValue != 'valueType' && <p>{item?.document || '-'}</p>}
                                </td>
                                {docValue == 'valueType' && <td>{item.document || '-'}</td>}
                                <td>
                                    {item?.path ? (
                                        <div className='files d-flex'>
                                            <img
                                                src={
                                                    (item?.path?.split('.').pop() === 'pdf' && pdfIcon) ||
                                                    (item?.path?.split('.').pop() === 'jpg' && jpgIcon) ||
                                                    (item?.path?.split('.').pop() === 'png' && pngIcon) ||
                                                    (item?.path?.split('.').pop() === 'xlsx' && exelIcon) ||
                                                    (item?.path?.split('.').pop() === 'docx' && wordIcon) ||
                                                    (item?.path?.split('.').pop() === 'pptx' && wordIcon)
                                                }
                                                width='18px'
                                                height='24px'
                                            />
                                            <Tooltip title={item?.path} placement='top'>
                                                <p>
                                                    {/* {item?.path?.slice(0, 20) + '...'} */}
                                                    {item?.path?.slice(0, 20)}
                                                    <br />
                                                    <span>{date.toLocaleString('en-GB')}</span>
                                                </p>
                                            </Tooltip>
                                            {item?.path &&
                                                (item?.departmentId === null ||
                                                    employee?.departmentId === item?.departmentId) && (
                                                    <img
                                                        src={downloadIcon}
                                                        alt='download'
                                                        className='d_icon'
                                                        onClick={() => {
                                                            const data = {
                                                                id: item?.id,
                                                                option:
                                                                    userType === 1
                                                                        ? 'employee_document'
                                                                        : userType === 3
                                                                          ? 'contractor_document'
                                                                          : 'supplier_document',
                                                                type: item?.path,
                                                            };
                                                            dispatch(DownloadProviderImage(data));
                                                        }}
                                                    />
                                                )}
                                        </div>
                                    ) : (
                                        <p className='no_file'>{t('no_file')}</p>
                                    )}
                                </td>
                                <td>
                                    {docType?.approve &&
                                        item?.statusId === 18 &&
                                        item?.departmentId !== null &&
                                        (employee?.departmentId === null ||
                                            employee?.departmentId !== item?.departmentId) && (
                                            <p className='headText'>{t('department_no_authorized_to_approve')}</p>
                                        )}
                                    {docType?.approve &&
                                        item?.statusId === 18 &&
                                        (item?.departmentId === null ||
                                            employee?.departmentId === item?.departmentId) && (
                                            <>
                                                <DropDownMenuProfile
                                                    menuList={[
                                                        {
                                                            name: t('approve_document'),
                                                            icon: <img src={approveIcon} alt='cancel' />,
                                                            onClick: () => {
                                                                setShowModalApprove(true);
                                                                setDocumentIdForApprove(item?.id);
                                                                setDocumentPathForApprove(item?.path);
                                                            },
                                                        },
                                                        {
                                                            name: t('deny_document'),
                                                            icon: <img src={cancelIcon} alt='cancel' />,
                                                            onClick: () => {
                                                                setShowModalDeny(true);
                                                                setDocumentIdForDeny(item?.id);
                                                                setDocumentPathForDeny(item?.path);
                                                            },
                                                        },
                                                    ].filter(Boolean)}
                                                />
                                            </>
                                        )}

                                    {item?.statusId === 19 && (
                                        <div className='status'>
                                            <img src={checkIcon} alt='checked' />
                                            <p className='headline'>
                                                {t('approved_by')}: {item?.validatedByName || '-'}
                                            </p>
                                            <p className='baseline'>{updatedAt.toLocaleString('en-GB')}</p>
                                        </div>
                                    )}
                                    {item?.statusId === 20 && (
                                        <div className='status'>
                                            <img src={cancelIcon} alt='cancel' />
                                            <p className='headline'>
                                                {t('denied_by')}: {item?.validatedByName || '-'}
                                            </p>
                                            <p className='baseline'>{updatedAt.toLocaleString('en-GB')}</p>
                                        </div>
                                    )}
                                    {item?.statusId === null && (
                                        <div className='status'>
                                            <img src={lineIcon} alt='lineIcon' />
                                            <p className='headline'>{t('pending_to_upload')}</p>
                                        </div>
                                    )}
                                </td>
                                {docType?.approve && (
                                    <td className='comment'>
                                        {item?.statusId !== 19 ? item?.validationComment || '-' : '-'}
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <ApproveDocumentModal
                isVehicleDocuments={isVehicleDocuments ? isVehicleDocuments : false}
                show={showModalApprove}
                onHide={() => setShowModalApprove(false)}
                userType={userType}
                userName={employee?.name}
                documentId={documentIdForApprove}
                documentName={documentPathForApprove || ''}
                employeeName={fullName}
                updated={updated}
                setUpdated={setUpdated}
            />
            <DenyDocumentModal
                isVehicleDocuments={isVehicleDocuments ? isVehicleDocuments : false}
                show={showModalDeny}
                onHide={() => setShowModalDeny(false)}
                userType={userType}
                userName={employee?.name}
                documentId={documentIdForDeny}
                documentName={documentPathForDeny || ''}
                employeeName={fullName}
                updated={updated}
                setUpdated={setUpdated}
            />
        </>
    );
};

export default DocumentTable;
