export const sidebarSupplierOptionsEnum = {
    // "company": "company",
    employees: 'employees',
    'supplier-order-detail': 'employees',
    'create-employee': 'employees',
    'update-employee': 'employees',
    'complete-document': 'employees',
    // "vehicle-fleet": "vehicles",
    vehicles: 'vehicles',
    'add-vehicles': 'vehicles',
    'vehicle-documents': 'vehicles',
    'vehicles-details': 'vehicles',
    'vehicle-detail': 'vehicles',
    'update-vehicles': 'vehicles',
    announcements: 'announcements',
    orders: 'orders',
    'order-detail': 'orders',
    'update-order': 'orders',
    'complete-order': 'orders',
    'suppliers/upload-contractor': 'provider',
    'suppliers/order-details': 'provider',
    'suppliers/vehicle-detail': 'provider',
    'suppliers/add-suppliers': 'provider',
    'suppliers/update-suppliers': 'provider',
    'suppliers/employee-suppliers-details': 'provider',
    'suppliers/approve-documents': 'provider',
    'suppliers/upload-supplier': 'provider',
    'suppliers/suppliers_detail_page': 'provider',
    payroll: 'payroll',
    'payroll/manage-attendence': 'payroll',
    'payroll/email-setting': 'emailSetting',
    backup: 'backup',
    departments: 'departments',
    'company/workshift-panel': 'workshift',
    'company/roles-panel': 'previlageSetting',
    'company/add-new-role': 'previlageSetting',
    'company/onboarding': 'onBoarding',
    'company/user-doc-panel': 'userDocument',
    'company/vehicle-doc-panel': 'vehicleDocument',
    devices: 'devices',
    'devices/create': 'devices',
    'devices/update': 'devices',
    'announcement-panel': 'announcements',
    profile: 'profile',
};
