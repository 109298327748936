import React from 'react';
import { Outlet } from 'react-router-dom';

const ProviderLayout = () => {
    return (
        // <main>
        <Outlet />
        // </main>
    );
};

export default ProviderLayout;
