import React, { useEffect, useState, useRef } from 'react';
import { Tooltip, Checkbox } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import userregular from '../../../../../assets/images/user-regular.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ClearButton from '../../../../../components/ClearButton';
import siluetPerson from '../../../../../assets/defaultImages/silueta_persona.png';
import icCamera from '../../../../../assets/defaultImages/ic-camera.svg';
import icFingerPrint from '../../../../../assets/defaultImages/ic-fingerprint.svg';
import ellipsis67 from '../../../../../assets/defaultImages/Ellipse67.svg';
import { TABLES } from '../../../../../Apis/Tables';
import { MODELS } from '../../../../../Apis/Models';
import { colors } from '../../../../../enums/colors';
import { GetFreeCards } from '../../../../../reduxToolkit/Cards/CardsApi';
import { clearSearchFilters } from '../../../../../reduxToolkit/Search/SearchSlice';
import { permissionObj } from '../../../../../Helpers/permission';
import moment from 'moment';

import { ReactComponent as LinkIcon } from '../../../../../assets/images/link-solid.svg';
import { ReactComponent as UnlinkIcon } from '../../../../../assets/images/link-slash-solid.svg';
import { ReactComponent as EyeIcon } from '../../../../../assets/images/eye-solid.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/images/print-solid.svg';
import { ReactComponent as DashIcon } from '../../../../../assets/images/minus-solid.svg';
import AssignFreeCard from '../Modal/AssignFreeCard';
import DeleteModal from '../../../../Modals/DeleteModal';
import { RemoveCardToUser } from '../../../../../reduxToolkit/Cards/CardsApi';
import PreviewModal from '../Modal/PreviewModal';
import { GetPreview } from '../../../../../reduxToolkit/Cards/CardsApi';
import { clearPreview } from '../../../../../reduxToolkit/Cards/CardsSlice';
import NotInformation from '../../../../../components/NotInformation';

const Step7Card = ({
    extraData,
    onChange,
    setExtraData,
    cardsList,
    userData,
    updated,
    setUpdated,
    onPrint,
    currentPrint,
    setCurrentPrint,
    setCardData,
    setCardDataInfo,
    componentAction,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [qrData, setQrData] = useState(null);
    const [qrName, setQrName] = useState(null);
    const [creationErrorsShow, setCreationErrorsShow] = useState(false);
    const [assignCardShow, setAssignCardShow] = useState(false);
    const [cardToAssign, setCardToAssign] = useState();
    const [unassignModalShow, setUnassignModalShow] = useState(false);
    const [cardToUnassign, setCardToUnassign] = useState([]);
    const [deleteCardShow, setDeleteCardShow] = useState(false);
    const [selectCardForDelete, setSelectCardForDelete] = useState([]);
    const [assignFreeCardShow, setAssignFreeCardShow] = useState(false);
    const [cardsAssign, setCardsAssign] = useState(cardsList);
    const [excludedId, setExcludedId] = useState([]);
    const [previewShow, setPreviewShow] = useState(false);
    const [currentRaw, setCurrentRaw] = useState(null);
    const [currentId, setCurrentId] = useState(null);

    // useSelector
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { searchByFilters } = useSelector((state) => state.SearchSlice);
    const { getPreview } = useSelector((state) => state.CardsSlice);

    // Props to the filter window
    const moduleId = `${MODELS.Card}`;
    const option = `${TABLES.CARD}`;
    const [criteria, setCriteria] = useState();

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage, finalArray, deletedFlag]);

    useEffect(() => {
        if (cardsList === undefined) {
            setCardsAssign([]);
        } else {
            setCardsAssign(cardsList);
        }
    }, [cardsList]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    // useEffect to check automatically all the items when page, rowsPerPage, or search change
    useEffect(() => {
        if (isAllChecked) {
            const selectAllIds = searchByFilters?.content?.map((item) => item?.id);
            setSelectCardForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [searchByFilters]);

    useEffect(() => {
        const selectAllIds = cardsAssign?.map((item) => {
            return { id: item?.id };
        });
        setCardData(selectAllIds);
        setCardDataInfo(cardsAssign);
    }, [cardsAssign]);

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = cardsAssign?.map((item) => {
                return item?.id;
            });
            setSelectCardForDelete(selectAllIds);
        } else {
            setSelectCardForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e, item) => {
        if (e.target.checked) {
            setSelectCardForDelete([...selectCardForDelete, e.target.id]);
        } else {
            setSelectCardForDelete(selectCardForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // This section help us to determine the height of the main table
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        const currentUrl = window.location.pathname; // Get the current URL
        const parts = currentUrl.split('/');
        const modulePart = parts[3] ? parts[3] : '' + parts[4] ? '/' + parts[4] : '';
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //Create body for the respectively search
    useEffect(() => {
        if (loaded) {
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                };
            });

            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: criteriaList,
            };

            setCriteria({ searchCriteriaList: criteriaList });
            setLoaded(false);
            setDeletedFlag(false);
        }
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray, deletedFlag]);

    const unassignCard = (deleteItem) => {
        if (componentAction === 'update') {
            const body = {
                cardIds: selectCardForDelete,
                userId: userData.id,
            };
            dispatch(RemoveCardToUser(body)).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        window.postMessage(
                            {
                                type: 'sessionStorageChangeLoader',
                                newValueLoading: true,
                            },
                            '*',
                        );
                        // setExcludedId((prev) => [...prev, selectCardForDelete]);
                        // setExcludedId(selectCardForDelete);
                        // setExcludedId(...selectCardForDelete);
                        setCardsAssign([]);
                        setUpdated(true);
                        setSelectCardForDelete([]);
                    }
                },
            );
        } else {
            const filteredCards = cardsAssign.filter((card) => !selectCardForDelete.includes(card.id));
            setCardsAssign(filteredCards);
        }
    };

    return (
        <div>
            {/* <label htmlFor="file-input" className="dottedborderbox"> */}
            <div className='container-top-right-btns'>
                {cardsList?.length > 0 && (
                    <button
                        className='delete-btn-1'
                        disabled={selectCardForDelete?.length === 0}
                        onClick={() => {
                            setDeleteCardShow(true);
                        }}
                        id='btn_s7c_unassign'
                    >
                        <i class='fa fa-trash-o' aria-hidden='true'></i>
                        {t('unassign')}
                    </button>
                )}
                {componentAction == 'create' ? (
                    <button
                        className='delete-btn-1'
                        disabled={selectCardForDelete?.length === 0}
                        onClick={() => {
                            setDeleteCardShow(true);
                        }}
                        id='btn_s7c_unassign'
                    >
                        <i class='fa fa-trash-o' aria-hidden='true'></i>
                        {t('unassign')}
                    </button>
                ) : null}
            </div>
            {
                <div className='panelTables'>
                    {selectCardForDelete?.length > 0 ? (
                        <div>
                            {selectCardForDelete.map((item) => {
                                <h2>{item}</h2>;
                            })}
                        </div>
                    ) : null}
                    {cardsAssign?.length > 0 ? (
                        <table className='w-100'>
                            <thead className='no-border-thead'>
                                <th className='first_head' style={{ width: '50px' }}>
                                    <Tooltip
                                        title={t('de_/_select_all').toUpperCase()}
                                        placement='top'
                                        disableInteractive
                                    >
                                        <Checkbox
                                            className='grid-checkall checkbox'
                                            checked={
                                                selectCardForDelete?.length > 0 &&
                                                cardsAssign?.length === selectCardForDelete?.length
                                            }
                                            indeterminate={
                                                selectCardForDelete?.length > 0 &&
                                                cardsAssign?.length !== selectCardForDelete?.length
                                            }
                                            onChange={handelDeleteAll}
                                            size='small'
                                        />
                                    </Tooltip>
                                </th>

                                <th className='first_head'>{t('number')}</th>
                                <th>{t('facility_code')}</th>
                                <th>{t('format')}</th>
                                <th>{t('created_at')}</th>
                                <th className='last'>{t('view')}</th>
                                {/* <th className='last'>{t('print')}</th> */}
                            </thead>
                            {cardsAssign?.length > 0 &&
                                cardsAssign.map((item) => {
                                    if (!excludedId.includes(item.id)) {
                                        return (
                                            <tr key={item?.id}>
                                                <td
                                                    className='first'
                                                    style={{ verticalAlign: 'middle', width: '50px' }}
                                                >
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={selectCardForDelete?.includes(item?.id)}
                                                        id={item?.id}
                                                        onChange={(e) => {
                                                            handleCheckboxChange(e, item);
                                                        }}
                                                        size='small'
                                                    />
                                                </td>
                                                <td className='first'>{item?.number}</td>
                                                <td>{item?.facilityCode}</td>
                                                <td>{item?.cardTypeName}</td>
                                                <td>
                                                    {lCode === 'en' ? (
                                                        <span>
                                                            {moment(item?.createdAt).format('MM/DD/YYYY')}
                                                            <br />
                                                            {moment(item?.createdAt).format('HH:mm:ss')}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {moment(item?.createdAt).format('DD/MM/YYYY')}
                                                            <br />
                                                            {moment(item?.createdAt).format('HH:mm:ss')}
                                                        </span>
                                                    )}
                                                </td>

                                                <td className='tableIcon'>
                                                    <Tooltip
                                                        title={
                                                            item?.templateId !== null
                                                                ? t('view').toUpperCase()
                                                                : t('no_template').toUpperCase()
                                                        }
                                                        placement='top'
                                                        arrow
                                                        disableInteractive={true}
                                                        // disableHoverListener={item?.templateId === null}
                                                    >
                                                        <button
                                                            className='btn-option'
                                                            onClick={() => {
                                                                if (item.templateId !== null) {
                                                                    // dispatch(GetPreview(item.id)).then(
                                                                    //     ({
                                                                    //         payload: {
                                                                    //             data: { data, success },
                                                                    //         },
                                                                    //     }) => {
                                                                    //         if (success === true) {
                                                                    //             setCurrentRaw(data);
                                                                    //         }
                                                                    //     },
                                                                    // );
                                                                    setCurrentId(item?.id);
                                                                    setPreviewShow(true);
                                                                    // setCurrentRaw(item?.raw);
                                                                }
                                                            }}
                                                        >
                                                            {item?.templateId !== null ? (
                                                                <EyeIcon
                                                                    width='18px'
                                                                    height='14px'
                                                                    fill={colors.grey}
                                                                />
                                                            ) : (
                                                                <DashIcon
                                                                    width='14px'
                                                                    height='16px'
                                                                    fill={colors.grey}
                                                                />
                                                            )}
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                                {/* <td className='tableIcon'>
                                                    <Tooltip
                                                        title={
                                                            item?.templateId !== null
                                                                ? t('print').toUpperCase()
                                                                : t('no_template').toUpperCase()
                                                        }
                                                        placement='top'
                                                        arrow
                                                        disableInteractive={true}
                                                    >
                                                        <button
                                                            className='btn-option'
                                                            onClick={() => {
                                                                if (item.userId !== null) {
                                                                    setCardToUnassign([
                                                                        { cardId: item?.id, userId: item?.userId },
                                                                    ]);
                                                                    setUnassignModalShow(true);
                                                                } else {
                                                                    setAssignCardShow(true);
                                                                    setCardToAssign({
                                                                        id: item?.id,
                                                                        number: item?.number,
                                                                        facilityCode: item?.facilityCode,
                                                                        cardTypeName: item?.cardTypeName,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {item?.templateId !== null ? (
                                                                <PrintIcon
                                                                    width='16px'
                                                                    height='16px'
                                                                    fill={colors.grey}
                                                                />
                                                            ) : (
                                                                <DashIcon
                                                                    width='14px'
                                                                    height='16px'
                                                                    fill={colors.grey}
                                                                />
                                                            )}
                                                        </button>
                                                    </Tooltip>
                                                </td> */}
                                            </tr>
                                        );
                                        // }
                                    }
                                })}
                        </table>
                    ) : (
                        <NotInformation text={t('no_information')} card={true} />
                    )}
                </div>
            }

            <label
                className='dottedborderbox'
                onClick={() => {
                    setAssignFreeCardShow(true);
                }}
            >
                <div className='dragAndDrop'>
                    <p>{t('assign_card')}</p>
                </div>

                <div className='dragAndDrop'>
                    <i class='fa fa-plus' aria-hidden='true'></i>
                </div>
            </label>
            <DeleteModal
                show={deleteCardShow}
                onHide={() => setDeleteCardShow(false)}
                onClickFn={() => unassignCard(selectCardForDelete)}
                data={selectCardForDelete}
                title_modal={t('unassign_cards')}
                description={'are_you_sure_you_want_to_unassign'}
                element_modal={t('cards')}
            />
            <AssignFreeCard
                show={assignFreeCardShow}
                onHide={() => {
                    setAssignFreeCardShow(false);
                    // setLogFlag(false);
                }}
                userData={userData}
                cardsAssign={cardsAssign}
                SetCardsAssign={setCardsAssign}
                onUpdated={() => {
                    setUpdated(true);
                }}
                componentAction={componentAction}
            />
            <PreviewModal
                show={previewShow}
                onHide={() => {
                    setPreviewShow(false);
                    dispatch(getPreview());
                    // setLogFlag(false);
                }}
                // currentRaw={JSON.parse(currentRaw)}
                currentId={currentId}
                userData={userData}
                onUpdated={() => {
                    setUpdated(true);
                }}
                onPrint={(print) => {
                    setCurrentPrint(print);
                    onPrint();
                }}
            />
        </div>
    );
};

export default Step7Card;
