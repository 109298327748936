import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../assets/images/ic-cancel.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const CreationErrorsModal = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { getDeviceLog, getDeviceLogPageable } = useSelector((state) => state.DevicesSlice);
    const { uploadFormFile } = useSelector((state) => state.FilesSlice);
    const { analizeCards } = useSelector((state) => state.CardsSlice);

    const errorsArray = props.cards
        ? analizeCards
            ? analizeCards?.data?.filter((item) => item.comment !== null)
            : []
        : uploadFormFile
          ? uploadFormFile?.data?.filter((item) => item.comment !== null)
          : [];
    return (
        <Modal
            className='log-modal'
            {...props}
            //
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            backdrop='static'
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('creation_errors')}</Modal.Title>
                <img
                    onClick={() => {
                        props.onHide();
                        props.onLog();
                    }}
                    className='modalClose'
                    src={cancel}
                    alt=''
                />
                <p className='paragraph_title mt-3'>
                    {props.cards ? (
                        <>
                            {t('some_errors_appeared_when_creating')}
                            {'.'}
                            {t('read_in_the_box_correct_them_and_try_the_creation_again')}
                        </>
                    ) : (
                        <>
                            {t('some_errors_appeared_when_creating')}
                            {'.'}
                            {t('read_in_the_box_correct_them_and_upload_the_file_again')}
                        </>
                    )}
                </p>
            </Modal.Header>
            <Modal.Body className='log-modal-body'>
                <ul className='log-list'>
                    {errorsArray.length > 0 &&
                        errorsArray?.map((item) => {
                            return (
                                <li key={item?.id}>
                                    <span className='log-item-text' style={{ maxWidth: props.cards && '800px' }}>
                                        {item?.comment}
                                    </span>
                                </li>
                            );
                        })}
                </ul>
            </Modal.Body>
        </Modal>
    );
};

export default CreationErrorsModal;
