import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Modal } from '@mui/material';
import '../../../../scss/devicesConfiguration.scss';
import noImageAvailable from '../../../../assets/NoImageAvailable.png';
import noImageAvailableSpanish from '../../../../assets/noImageAvailableSpanish.png';
import noImageAvailableFrench from '../../../../assets/noImageAvailableFrench.png';
import i18next, { t } from 'i18next';
import { imageSize } from '../../../../constant/variable';
import cloud from '../../../../assets/images/cloud.svg';
import { toast } from 'react-toastify';
import { UploadFileToServer } from '../../../../reduxToolkit/Contractor/ContractorApi';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0.5,
    outline: 'none',
    border: 'none',
    '&:focus': {
        outline: 'none',
        border: 'none',
    },
};

const ChangeBackgroundModal = ({ open, handleClose, isIDLEBackgroundToChange, isPhotoinTimeClock, isPhotoInIdle }) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [actualLanguage, setActualLanguage] = useState(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        const currentLanguage = i18n.language;
        setActualLanguage(currentLanguage);
    }, [i18n.language]);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            fileInputRef.current.files = files;
            onImageChange({ target: fileInputRef.current });
        }
    };

    const onImageChange = (e) => {
        const selectedFiles = e.target.files;
        const imageRegex = /\.(jpg|jpeg|png|gif)$/i;

        if (selectedFiles && selectedFiles.length > 0) {
            if (!imageRegex.test(selectedFiles[0].name)) {
                toast.error(t('error_file_format').toUpperCase());
            } else if (selectedFiles[0].size >= imageSize) {
                toast.error(`${t('the_file_size_should_not_exceed').toUpperCase()} ${imageSize / 1024}KB.`);
            } else {
                setSelectedImage(selectedFiles[0]);
                setUploadSuccess(true);
            }
        }
    };

    const changeBackgroundForIdle = async () => {
        if (selectedImage) {
            try {
                let formData = new FormData();
                formData.append('id', 'daf51955-baa7-4aca-93a4-97d56799b810');
                if (isIDLEBackgroundToChange) {
                    formData.append('option', 'idle_image');
                } else {
                    formData.append('option', 'clock_image');
                }
                formData.append('file', selectedImage);
                await dispatch(UploadFileToServer(formData));
                // Cerrar el modal
                setUploadSuccess(false);
                handleClose(false);
                window.location.reload();
            } catch (error) {
                toast.error(t('background_change_error').toUpperCase());
            }
        } else {
            toast.info(t('please_select_an_image_to_upload').toUpperCase());
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                setUploadSuccess(false);
                handleClose(false);
                setSelectedImage(null);
            }}
            sx={{
                outline: 'none',
            }}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className='modal--changeBackgroundModal'
        >
            <Box sx={style} className='section-modal'>
                <Box className='section--headerModal'>
                    <Typography className='typography--h1-titleModalChangeBackground' variant='h2' color='initial'>
                        {t('change_background')}
                    </Typography>
                    <section className='closeModalBtn'>
                        <i
                            className='fa fa-times cross fa-2x closeBtn-header'
                            aria-hidden='true'
                            onClick={() => {
                                handleClose(true);
                                setSelectedImage(null);
                            }}
                        ></i>
                    </section>
                </Box>
                <Box className='section--upload-and-preview-image'>
                    <Box className='section--uploadImage centerElements'>
                        <label
                            htmlFor='file-input'
                            className='dotted-border-box input-file--upload centerElements'
                            style={{ maxWidth: '481px' }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <img src={cloud} alt='submitupload' />
                            <input
                                type='file'
                                ref={fileInputRef}
                                id='file-input'
                                accept='image/*'
                                onChange={onImageChange}
                            />
                            <div
                                className='drag-and-drop'
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                                <p className='paragraph--dragAndDropUrImage'>
                                    {t('drag_&_drop').toUpperCase()} <br />
                                    {t('your_image').toUpperCase()}
                                    <br />{' '}
                                    <span className='span-imageSizeKB'>{`${t('max_size')} ${imageSize / 1024}KB`}</span>
                                </p>
                            </div>
                        </label>
                    </Box>
                    <Box className='section--previewImage  centerElements'>
                        <Box className='preview-title--section centerElements'>
                            <Typography variant='h2' color='initial' className='h2-preview--titlePreview'>
                                {t('preview')}
                            </Typography>
                        </Box>
                        {selectedImage && uploadSuccess ? (
                            <Box className='section-img--preview centerElements'>
                                <img
                                    className='img-preview'
                                    src={URL.createObjectURL(selectedImage)}
                                    alt={t('uploaded_image')}
                                />
                            </Box>
                        ) : actualLanguage === 'en' ? (
                            <Box className='section-img--preview centerElements'>
                                <img
                                    className='img-preview--noImageAvailable'
                                    src={noImageAvailable}
                                    alt={t('the_image_does_not_exist')}
                                />
                            </Box>
                        ) : actualLanguage === 'sp' ? (
                            <Box className='section-img--preview centerElements'>
                                <img
                                    className='img-preview--noImageAvailable'
                                    src={noImageAvailableSpanish}
                                    alt={t('the_image_does_not_exist')}
                                />
                            </Box>
                        ) : (
                            <Box className='section-img--preview centerElements'>
                                <img
                                    className='img-preview--noImageAvailable'
                                    src={noImageAvailableFrench}
                                    alt={t('the_image_does_not_exist')}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box className='section-buttons--cancelAndChange'>
                    <button
                        className='cancel_btn'
                        onClick={() => {
                            handleClose(false);
                            setSelectedImage(null);
                        }}
                    >
                        {t('cancel').toUpperCase()}
                    </button>
                    <button className='change_btn' onClick={changeBackgroundForIdle}>
                        {t('change').toUpperCase()}
                    </button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ChangeBackgroundModal;
