import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddDepartmentById } from '../../../../reduxToolkit/Department/DepartmentApi';
import DeleteIcon from '../../../../assets/images/redTrash.svg';
import AutoCompleteSearch from '../../../../components/AutoCompleteSearch';

const ManageEmployeeDepartment = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [addUserQuery, setAddUserQuery] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);

    const { getAllNonDepartmentUser, listOfUsersDepartment } = useSelector((state) => state.DepartmentSectionSlice);

    // add selected user handler function
    const handleSelectedUser = (user) => {
        const alreadyAdded =
            selectedUser.find((item) => item.id === user.id) ||
            listOfUsersDepartment.content.find((item) => item.id === user.id);

        if (alreadyAdded) {
            toast.warn(`${alreadyAdded.label || alreadyAdded.name} ${t('is_already_added')}`);
        } else {
            setSelectedUser((prev) => [...prev, user]);
        }
    };

    // remove user handler function
    const handleUserRemove = (remove) => {
        const filteredUser = selectedUser.filter((item) => item.id !== remove.id);
        setSelectedUser(filteredUser);
    };

    // add user handler function
    const handleAddUser = () => {
        if (selectedUser.length > 0) {
            const ids = selectedUser.map((item) => item.id);

            const data = {
                id: props.manageEmploye?.id,
                userids: ids,
            };

            dispatch(AddDepartmentById(data));
            props.onHide();
            setAddUserQuery('');
            setSelectedUser([]);
        } else {
            toast.warn('Select Atleast One user');
        }
    };

    // handel the logic for object transformation for all user
    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.name || '') +
                    ' ' +
                    (item?.firstLastName || '') +
                    (item?.secondLastName ? ' ' + item.secondLastName : ''),
                id: item?.id,
            };
        });
        return newData;
    };
    useEffect(() => {}, []);
    return (
        <Modal
            className='manage_department_modal'
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('employees')}</Modal.Title>
                <i
                    className='fa fa-times cross'
                    aria-hidden='true'
                    onClick={() => {
                        props.onHide();
                        setAddUserQuery('');
                        setSelectedUser([]);
                    }}
                ></i>
            </Modal.Header>
            <Modal.Body className='manage_role_modal_body'>
                {' '}
                <p className='desciptions'>{t('add_all_employees_to_department')}</p>
                <AutoCompleteSearch
                    data={userDataTransform(getAllNonDepartmentUser)}
                    handleFn={handleSelectedUser}
                    value={addUserQuery}
                    setValue={setAddUserQuery}
                />
                <div className='add_some_one'>
                    <div className='add_some_one_inner'>
                        {selectedUser.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className='d-flex justify-content-between pr-2 w-100 align-items-center pt-2'
                                >
                                    <div className='badge_container gap-2'>
                                        <div className='c_badge'></div>
                                        <p>{item.label}</p>
                                    </div>

                                    <img
                                        width='20px'
                                        height='25px'
                                        className='delete-icon-style'
                                        src={DeleteIcon}
                                        style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => handleUserRemove(item)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className='line_buttons'>
                        <span
                            onClick={() => {
                                setSelectedUser([]);
                                setAddUserQuery('');
                            }}
                        >
                            {t('clear_all_the_Selected')}
                        </span>
                    </div>
                </div>
                <div className='d-flex mt-4'>
                    <button
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '184px' }}
                        onClick={() => {
                            props.onHide();
                            setAddUserQuery('');
                            setSelectedUser([]);
                        }}
                    >
                        {t('cancel')}
                    </button>
                    <button style={{ width: '184px' }} className='custom_primary_btn_dark' onClick={handleAddUser}>
                        {t('add_employees')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ManageEmployeeDepartment;
