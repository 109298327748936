/*
Author : Arman Ali
Module: AddUserType
github: https://github.com/Arman-Arzoo
*/
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Contractor } from '../../../models/Contractor';
import { ContractorEmployee } from '../../../models/ContractorEmployee';
import BadgeIcon from '@mui/icons-material/Badge';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Supplier } from '../../../models/Supplier';
import { SupplierEmployee } from '../../../models/SupplierEmployee';

import {
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    FormHelperText,
    Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import broomIcon from '../../../assets/icon/broom-solid.svg';
import CustomTextWithLine from '../../../components/CustomTextWithLine';
import { contractorFile, supplierFile } from '../../../constant/userType';
import useStyle from '../../../hooks/useStyle';
import {
    CreateEmployeeContractor,
    GetAllContractors,
    GetAllToContractor,
    GetAllWorkSchdule,
    GetEmployeeContractorByItId,
    GetStatus,
    UpdateEmployeeContractor,
} from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import {
    CreateEmployeeSupplier,
    GetEmployeeSupplierByItId,
    GetGenderListProvider,
    GetStatusListProvider,
    UpdateEmployeeSupplier,
} from '../../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import UploadImageModal from '../../../components/UploadImageModal';
import { userInfoStatus } from '../../../reduxToolkit/CompanyEmployees/CompanyEmployeesApi';
import DeleteModal from '../../Modals/DeleteModal';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import person5 from '../../../assets/images/user-png-140.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import dateInputLanguage from '../../../hooks/dateInputLanguage';
import phoneInputLanguage from '../../../hooks/phoneInputLanguage';
import { status } from '../../../enums/statusEnum';
import { ClearGetEmployeeContractorByItId } from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsSlice';
import { ClearGetEmployeeSupplierByItId } from '../../../reduxToolkit/EmployeeProviders/EmployeeProviderSlice';
import { ClearGetContractorInfoById } from '../../../reduxToolkit/Contractor/ContractorSlice';
import { ClearGetSupplierInfoById } from '../../../reduxToolkit/Providers/providerSlice';
import {
    CreateContractorUserRelationship,
    UpdateContractorUserRelationship,
    GetContractorInfoById,
} from '../../../reduxToolkit/Contractor/ContractorApi';
import {
    CreateProviderUserRelationship,
    GetSupplierInfoById,
    UpdateProviderData,
} from '../../../reduxToolkit/Providers/providersApi';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import Step4AccessRights from './../Company/Employees/EnrollmentSteps/Step4AccessRights';
import { id } from 'date-fns/locale';
const AddUserType = ({ userType, isUpdate, isEmployee }) => {
    // use hook importer
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const params = useParams();

    // use State hook for local state management
    const { getStatusListProvider, getGnderListProvider, getEmployeeSupplierByItId } = useSelector(
        (state) => state.EmployeeProviderSlice,
    );
    const { getAllToContractor, getEmployeeContractorByItId } = useSelector((state) => state.EmployeeContractorsSlice);
    const userInfoStatusList = useSelector((state) => state?.CompanyEmployeesSlice?.userInfoStatusList);
    const { getContractorInfoById } = useSelector((state) => state.ContractorSlice);
    const { getUserFullInformation } = useSelector((state) => state.CommonsSlice);
    const { getSupplierInfoById } = useSelector((state) => state?.providersSlice);
    //use Selector hook to get state for redux store
    const { textField, smallBoxStyle, textFieldCommon } = useStyle();
    const [previewImage, setPreviewImage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [imageFile, setImageFile] = useState('');
    const [deleteUser, setDeleteUser] = useState(false);
    const [emptyFields, setEmptyFields] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidDocumentId, setIsValidDocumentId] = useState(true);
    const [selectElementForDelete, setSelectElementForDelete] = useState();
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [isValidDob, setIsValidDob] = useState(true);
    const [language, setLanguage] = useState('');
    const [workShiftsList, setWorkShiftsList] = useState([]);
    const [customizedList, setCustomizedList] = useState([]);
    const [contractorItemId, setContractorItemId] = useState('');
    const [user, setUser] = useState(
        userType == contractorFile
            ? isEmployee
                ? ContractorEmployee
                : Contractor
            : userType == supplierFile && isEmployee
              ? SupplierEmployee
              : Supplier,
    );
    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));

    let companyName;

    if (isEmployee !== true) {
        if (userType === contractorFile) {
            companyName = user.contractorCompanyName;
        } else if (userType === supplierFile) {
            companyName = user.supplierCompanyName;
        }
    }

    //  reset function
    const resetForm = () => {
        setUser(
            userType == contractorFile
                ? isEmployee
                    ? ContractorEmployee
                    : Contractor
                : userType == supplierFile && isEmployee
                  ? SupplierEmployee
                  : Supplier,
        );
    };

    // Form handeler Function
    const FormHandler = () => {
        setEmptyFields(true);
        if (
            (isEmployee !== true && !user.acronym) ||
            (isEmployee !== true && !companyName) ||
            !user.name ||
            !user.lastName ||
            !user.phoneNumber ||
            !user.documentId ||
            !isValidDob ||
            !isValidPhoneNumber ||
            !isValidEmail
        ) {
            toast.warn(t('please_fill_all_fields'));
        } else {
            if (isUpdate) {
                if (userType == contractorFile) {
                    if (isEmployee === true) {
                        dispatch(
                            UpdateContractorUserRelationship({
                                data: user.toCreateUpdate(),
                                file: imageFile,
                                updateImage: user.selfie !== null,
                            }),
                        ).then((res) => {
                            if (res.payload.data.code === 200) {
                                dispatch(GetContractorInfoById(params?.id));
                                navigate(-1);
                                setEmptyFields(false);
                            }
                        });
                    } else {
                        const customs = customizedList
                            .filter((element) => element.added === true && element.new === true)
                            .map(({ zoneId, zoneName, dayId, from, to }) => ({
                                zoneId: zoneId,
                                zoneName: zoneName,
                                dayId: dayId,
                                from: from,
                                to: to,
                            }));

                        const requestData = {
                            data: {
                                ...user.toCreateUpdate(),
                                workShifts: workShiftsList,
                                customs: customs,
                            },
                            file: imageFile,
                            updateImage: user.selfie !== null,
                        };

                        dispatch(UpdateEmployeeContractor(requestData)).then((res) => {
                            if (res.payload.data.code === 200) {
                                dispatch(GetEmployeeContractorByItId(params?.id));

                                navigate(-1);
                                setEmptyFields(false);
                            }
                        });
                    }
                }
                if (userType == supplierFile) {
                    if (isEmployee === true) {
                        dispatch(
                            UpdateProviderData({
                                data: user.toCreateUpdate(),
                                file: imageFile,
                                updateImage: user.selfie !== null,
                            }),
                        ).then((res) => {
                            if (res.payload.data.code === 200) {
                                dispatch(GetSupplierInfoById(params?.id));
                                navigate(-1);
                            }
                        });
                    } else {
                        dispatch(
                            UpdateEmployeeSupplier({
                                data: user.toCreateUpdate(),
                                file: imageFile,
                                updateImage: user.selfie !== null,
                            }),
                        ).then((res) => {
                            if (res.payload.data.code === 200) {
                                dispatch(GetEmployeeSupplierByItId(params?.id));
                                navigate(-1);
                                setEmptyFields(false);
                            }
                        });
                    }
                }
            } else {
                if (userType == contractorFile) {
                    if (isEmployee === true) {
                        dispatch(
                            CreateContractorUserRelationship({ data: user.toCreateUpdate(), file: imageFile }),
                        ).then((res) => {
                            if (res.payload.data.code === 201) {
                                dispatch(GetContractorInfoById(params?.id));
                                navigate(-1);
                                setEmptyFields(false);
                            }
                        });
                    } else {
                        const customs = customizedList
                            .filter((element) => element.added === true && element.new === true)
                            .map(({ zoneId, zoneName, dayId, from, to }) => ({
                                zoneId: zoneId,
                                zoneName: zoneName,
                                dayId: dayId,
                                from: from,
                                to: to,
                            }));

                        const requestData = {
                            data: {
                                ...user.toCreateUpdate(),
                                workShifts: workShiftsList,
                                customs: customs,
                            },
                            file: imageFile,
                        };

                        dispatch(CreateEmployeeContractor(requestData)).then((res) => {
                            if (res.payload.data.code === 201) {
                                navigate(-1);
                                setEmptyFields(false);
                            }
                        });
                    }
                }
                if (userType == supplierFile) {
                    if (isEmployee === true) {
                        dispatch(CreateProviderUserRelationship({ data: user.toCreateUpdate(), file: imageFile })).then(
                            (res) => {
                                if (res.payload.data.code === 201) {
                                    navigate(-1);
                                    setEmptyFields(false);
                                }
                            },
                        );
                    } else {
                        dispatch(CreateEmployeeSupplier({ data: user.toCreateUpdate(), file: imageFile })).then(
                            (res) => {
                                if (res.payload.data.code === 201) {
                                    navigate(-1);
                                    setEmptyFields(false);
                                }
                            },
                        );
                    }
                }
            }
        }
    };

    const handleDateChange = (selectedDate) => {
        setUser((prevData) => ({
            ...prevData,
            dob: selectedDate,
        }));
    };

    const handleEmailChange = (email) => {
        validateEmail(email);
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        setIsValidEmail(isValid);
    };
    const validatePhone = (phone) => {
        const phoneRegex = /^\+[0-9]{10,14}$/;
        let isValid;
        if (phone.includes('+')) {
            isValid = phoneRegex.test(phone);
        } else {
            isValid = phoneRegex.test('+' + phone);
        }
        setIsValidPhoneNumber(isValid);
    };

    const handlePhoneNumberChange = (phone) => {
        handlePhoneChange(phone);
        validatePhone(phone);
    };

    const handlePhoneChange = (phone) => {
        setUser((prevData) => ({
            ...prevData,
            phoneNumber: phone !== '' ? '+' + phone : '',
        }));
    };

    useEffect(() => {
        dispatch(GetGenderListProvider());

        if (isUpdate && userType == supplierFile && isEmployee !== true) {
            dispatch(GetStatusListProvider());
            dispatch(GetEmployeeSupplierByItId(params?.id));
            dispatch(userInfoStatus());
        }
        if (isUpdate && userType == contractorFile && isEmployee !== true) {
            dispatch(GetEmployeeContractorByItId(params?.id)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        if (data?.workShifts?.length > 0) {
                            const responseShifts = data?.workShifts;
                            const shiftsList = responseShifts.map((shift) => ({
                                ...shift,
                                added: true,
                            }));

                            setWorkShiftsList(shiftsList);
                        }
                    }
                },
            );
            dispatch(GetAllToContractor());
            dispatch(userInfoStatus());
        }
        if (isUpdate && userType == contractorFile && isEmployee !== true) {
            dispatch(GetEmployeeContractorByItId(params?.id)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        setContractorItemId(data?.id);
                        if (data?.customs?.length > 0) {
                            setCustomizedList(data?.customs?.map((item) => ({ ...item, added: true, new: false })));
                        }
                    }
                },
            );
            dispatch(GetAllToContractor());
            dispatch(userInfoStatus());
        }
        if (isUpdate && userType == contractorFile && isEmployee === true) {
            dispatch(GetContractorInfoById(params?.id));
            dispatch(GetAllToContractor());
            dispatch(userInfoStatus());
        }
        if (isUpdate && userType == supplierFile && isEmployee === true) {
            dispatch(GetStatusListProvider());
            dispatch(GetSupplierInfoById(params?.id));
            dispatch(userInfoStatus());
        }
    }, []);

    useEffect(() => {
        const updateCurrentValues = () => {
            setCustomizedList(customizedList);
            setWorkShiftsList(workShiftsList);
        };

        updateCurrentValues();
    }, [customizedList.length, workShiftsList.length]);

    useEffect(() => {
        if (isUpdate && userType === contractorFile && isEmployee !== true) {
            setUser((prevData) => ({
                ...prevData,
                ...getEmployeeContractorByItId,
            }));
        }
        if (isUpdate && userType === supplierFile && isEmployee !== true) {
            setUser((prevData) => ({
                ...prevData,
                ...getEmployeeSupplierByItId,
            }));
        }
        if (isUpdate && userType === contractorFile && isEmployee === true) {
            setUser((prevData) => ({
                ...prevData,
                ...getContractorInfoById,
            }));
            setSelectElementForDelete([sessionStorage.getItem('elementForDelete')]);
        }
        if (isUpdate && userType === supplierFile && isEmployee === true) {
            setUser((prevData) => ({
                ...prevData,
                ...getSupplierInfoById,
            }));
            setSelectElementForDelete([sessionStorage.getItem('elementForDelete')]);
        }
    }, [getEmployeeSupplierByItId, getEmployeeContractorByItId, getContractorInfoById, getSupplierInfoById]);

    useLayoutEffect(() => {
        window.addEventListener('message', handleLanguageChange);
        return () => {
            window.removeEventListener('message', handleLanguageChange);
            dispatch(ClearGetEmployeeContractorByItId());
            dispatch(ClearGetEmployeeSupplierByItId());
            dispatch(ClearGetContractorInfoById());
            dispatch(ClearGetSupplierInfoById());
        };
    }, []);

    // Function to handle the message received from another window
    const handleLanguageChange = (event) => {
        // Check if the received message is of the expected type
        if (event.data && event.data.type === 'languageChange') {
            // Update the variable with the new value
            let newValue = event.data.newValue !== null ? event.data.newValue : '';
            setLanguage(newValue);
        }
    };

    const deleteSelectedElement = (deleteItem) => {
        const tableName =
            contractorId !== null ? 'contractor_employee' : supplierId !== null ? 'supplier_employee' : '';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then((response) => {
                const success = response?.payload?.data?.success;
                if (success === true) {
                    if (contractorId !== null) {
                        navigate('/dashboard/contractor/search-employe');
                    } else if (supplierId !== null) {
                        navigate('/dashboard/supplier/employees');
                    }
                }
            });
        }
    };

    return (
        <>
            <div className='head '>
                <div className='headLeft'>
                    <ReturnArrow
                        link={
                            isEmployee
                                ? isUpdate
                                    ? false
                                    : userType === supplierFile
                                      ? '/dashboard/supplier/employees'
                                      : userType == contractorFile && '/dashboard/contractor/search-employe'
                                : userType === supplierFile
                                  ? '/dashboard/employee/suppliers'
                                  : userType == contractorFile && '/dashboard/employee/contractors'
                        }
                    />

                    <h2>
                        {isUpdate == false && userType == supplierFile && !isEmployee && t('add_supplier')}
                        {isUpdate == false && userType == contractorFile && !isEmployee && t('add_contractor')}
                        {isUpdate && userType == contractorFile && !isEmployee && t('update_contractor')}
                        {isUpdate && userType == supplierFile && !isEmployee && t('update_supplier')}
                        {isEmployee && isUpdate === false && t('create_employee')}
                        {isEmployee && isUpdate === true && t('update_employee')}
                    </h2>
                </div>
                {isUpdate && (
                    <button
                        className='delete-btn-1'
                        onClick={() => {
                            setDeleteUser(true);
                        }}
                    >
                        <i class='fa fa-trash-o' aria-hidden='true'></i>
                        {t('delete')}
                    </button>
                )}
            </div>

            <div className='mt-5  add_user_type' style={{ marginTop: '20px' }}>
                <div
                    className={` ${
                        userType === contractorFile ? 'add_provider_content' : 'col-md-6 add_provider_content'
                    }`}
                    style={userType === contractorFile ? { width: '1300px' } : {}}
                >
                    <div className='image_upload_container'>
                        <div className='image_upload'>
                            {isUpdate ? (
                                <img
                                    src={
                                        previewImage
                                            ? previewImage
                                            : user?.selfie
                                              ? `data:image/png;base64,${user?.selfie}`
                                              : person5
                                    }
                                    alt='dd'
                                    style={{ objectFit: 'cover' }}
                                />
                            ) : (
                                <img
                                    src={previewImage ? previewImage : person5}
                                    alt='vehicle'
                                    style={{ objectFit: 'cover' }}
                                />
                            )}
                            <div className='upload_icon_btn' onClick={() => setShowModal(true)}>
                                <i className='fa fa-long-arrow-right height' aria-hidden='true'></i>
                                <i className='fa fa-long-arrow-left' aria-hidden='true'></i>
                            </div>
                        </div>
                    </div>
                    {isUpdate !== true && (
                        <Tooltip title={t('clean_all_inputs').toUpperCase()} placement='right'>
                            <button
                                className='clear_all'
                                style={userType === contractorFile ? { marginTop: '110px', marginRight: '43px' } : {}}
                                onClick={() => {
                                    resetForm();
                                }}
                            >
                                <img src={broomIcon} alt='' />
                            </button>
                        </Tooltip>
                    )}

                    <div className={userType === contractorFile ? 'contractor-space' : 'space'}>
                        {isEmployee !== true && (
                            /*  aqui esta el primer contenedor */
                            <Grid
                                sx={{
                                    ...(userType === contractorFile && {
                                        width: '150%',
                                        maxWidth: '150%',
                                        marginRight: '30px',
                                    }),
                                }}
                            >
                                <CustomTextWithLine
                                    title={(userType == supplierFile || userType == contractorFile) && t('company')}
                                />

                                <Box
                                    className='add_provider_text_field required-data'
                                    style={{ marginTop: '28.5px' }}
                                    sx={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        fontSize: '20px',
                                        height: '40px',
                                    }}
                                >
                                    <TextField
                                        size='small'
                                        // focused={acronym}
                                        error={emptyFields && user.acronym === ''}
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        label={t('acronym')}
                                        id='Acronym'
                                        value={user.acronym}
                                        onChange={(e) =>
                                            setUser((prevData) => ({
                                                ...prevData,
                                                acronym: e.target.value,
                                            }))
                                        }
                                        sx={textFieldCommon}
                                        inputProps={{ maxLength: 128 }}
                                        helperText={emptyFields && user.acronym === '' ? t('requiredField') : ''}
                                    />
                                </Box>
                                <Box
                                    className='add_provider_text_field required-data'
                                    sx={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        fontSize: '20px',
                                        height: '40px',
                                    }}
                                >
                                    <TextField
                                        size='small'
                                        error={emptyFields && companyName === ''}
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        sx={textFieldCommon}
                                        label={t('company_name')}
                                        id='Company Name'
                                        value={companyName}
                                        onChange={(e) => {
                                            if (userType === contractorFile) {
                                                setUser((prevData) => ({
                                                    ...prevData,
                                                    contractorCompanyName: e.target.value,
                                                }));
                                            } else if (userType === supplierFile) {
                                                setUser((prevData) => ({
                                                    ...prevData,
                                                    supplierCompanyName: e.target.value,
                                                }));
                                            }
                                        }}
                                        inputProps={{ maxLength: 128 }}
                                        helperText={emptyFields && companyName === '' ? t('requiredField') : ''}
                                    />
                                </Box>
                                {isUpdate && userType == contractorFile && (
                                    <Box className='add_provider_text_field required-data'>
                                        <FormControl fullWidth sx={textFieldCommon}>
                                            <InputLabel id='contractor_status'>{t('contractor_status')}</InputLabel>
                                            <Select
                                                size='small'
                                                labelId='contractor_status'
                                                id='demo-simple-select'
                                                defaultValue='employe'
                                                label={t('contractor_status')}
                                                value={user.contractorStatusId}
                                                onChange={(e) =>
                                                    setUser((prevData) => ({
                                                        ...prevData,
                                                        contractorStatusId: e.target.value,
                                                    }))
                                                }
                                            >
                                                {getAllToContractor?.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.id}>
                                                            {t(status[item.id]).toUpperCase()}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                                {isUpdate && userType == supplierFile && (
                                    <Box className='add_provider_text_field required-data'>
                                        <FormControl fullWidth sx={textFieldCommon}>
                                            <InputLabel id='supplier_status'>{t('supplier_status')}</InputLabel>
                                            <Select
                                                size='small'
                                                labelId='supplier_status'
                                                id='demo-simple-select'
                                                defaultValue='employe'
                                                label={t('supplier_status')}
                                                value={user.supplierStatusId}
                                                onChange={(e) =>
                                                    setUser((prevData) => ({
                                                        ...prevData,
                                                        supplierStatusId: e.target.value,
                                                    }))
                                                }
                                            >
                                                {getStatusListProvider?.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.id}>
                                                            {t(status[item.id]).toUpperCase()}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                                <Box className='add_provider_text_field'>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        sx={textFieldCommon}
                                        label={t('address')}
                                        autoComplete='off'
                                        id='address'
                                        value={user.address || ''}
                                        onChange={(e) =>
                                            setUser((prevData) => ({
                                                ...prevData,
                                                address: e.target.value,
                                            }))
                                        }
                                    />
                                </Box>
                                <Box className='add_provider_text_field'>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        type='text'
                                        label={t('description')}
                                        id='description'
                                        value={user.description || ''}
                                        autoComplete='off'
                                        onChange={(e) =>
                                            setUser((prevData) => ({
                                                ...prevData,
                                                description: e.target.value,
                                            }))
                                        }
                                        multiline
                                        rows={userType === contractorFile ? 8 : 5}
                                        sx={textFieldCommon}
                                        maxRows={10}
                                        inputProps={{ maxLength: 512 }}
                                    />
                                </Box>
                            </Grid>
                        )}

                        <Grid
                            sx={{
                                ...(userType === contractorFile && {
                                    width: '150%',
                                    maxWidth: '150%',
                                }),
                            }}
                        >
                            <CustomTextWithLine
                                title={
                                    isEmployee === true
                                        ? t('user_data')
                                        : (userType == supplierFile || userType == contractorFile) && t('manager')
                                }
                            />

                            <Box
                                className='add_provider_text_field required-data'
                                style={{ marginTop: '28.5px' }}
                                sx={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    fontSize: '20px',
                                    height: '40px',
                                }}
                            >
                                <TextField
                                    size='small'
                                    error={emptyFields && user.name === ''}
                                    required
                                    sx={textFieldCommon}
                                    fullWidth
                                    autoComplete='off'
                                    label={t('name')}
                                    id='NAME'
                                    value={user.name}
                                    onChange={(e) =>
                                        setUser((prevData) => ({
                                            ...prevData,
                                            name: e.target.value,
                                        }))
                                    }
                                    inputProps={{ maxLength: 128 }}
                                    helperText={emptyFields && user.name === '' ? t('requiredField') : ''}
                                />
                            </Box>
                            <Box
                                className='add_provider_text_field required-data'
                                sx={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    fontSize: '20px',
                                    height: '40px',
                                }}
                            >
                                <TextField
                                    size='small'
                                    error={emptyFields && user.lastName === ''}
                                    required
                                    sx={textFieldCommon}
                                    fullWidth
                                    autoComplete='off'
                                    label={t('last_name')}
                                    id='LASTNAME'
                                    value={user.lastName}
                                    onChange={(e) =>
                                        setUser((prevData) => ({
                                            ...prevData,
                                            lastName: e.target.value,
                                        }))
                                    }
                                    inputProps={{ maxLength: 128 }}
                                    helperText={emptyFields && user.lastName === '' ? t('requiredField') : ''}
                                />
                            </Box>
                            <Box
                                className='add_provider_text_field'
                                sx={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    fontSize: '20px',
                                    height: '40px',
                                }}
                            >
                                <TextField
                                    size='small'
                                    fullWidth
                                    sx={textFieldCommon}
                                    label={t('second_last_name')}
                                    autoComplete='off'
                                    id='SECONDLASTNAME'
                                    value={user.secondLastName || ''}
                                    inputProps={{ maxLength: 128 }}
                                    onChange={(e) =>
                                        setUser((prevData) => ({
                                            ...prevData,
                                            secondLastName: e.target.value,
                                        }))
                                    }
                                />
                            </Box>
                            <Box className='add_provider_text_field required-data'>
                                <TextField
                                    size='small'
                                    sx={textFieldCommon}
                                    fullWidth
                                    autoComplete='off'
                                    error={emptyFields && user.documentId === ''}
                                    label={t('documentId')}
                                    id='DocumentId'
                                    value={user.documentId || ''}
                                    onChange={(e) => {
                                        setUser((prevData) => ({
                                            ...prevData,
                                            documentId: e.target.value,
                                        }));
                                    }}
                                    helperText={emptyFields && user.documentId === '' && t('requiredField')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <BadgeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box
                                className='add_provider_text_field required-data'
                                sx={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    fontSize: '20px',
                                    height: '40px',
                                }}
                            >
                                <TextField
                                    size='small'
                                    error={(emptyFields && user.email === '') || (emptyFields && !isValidEmail)}
                                    sx={textFieldCommon}
                                    fullWidth
                                    autoComplete='off'
                                    required
                                    label={t('email')}
                                    id='Email'
                                    disabled={isUpdate}
                                    value={user.email}
                                    onChange={(e) => {
                                        setUser((prevData) => ({
                                            ...prevData,
                                            email: e.target.value,
                                        }));
                                        handleEmailChange(e.target.value);
                                    }}
                                    helperText={
                                        emptyFields && user.email === ''
                                            ? t('requiredField')
                                            : emptyFields && !isValidEmail
                                              ? t('email_format_invalid')
                                              : ''
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <Box
                                className='add_provider_text_field required-data'
                                sx={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    fontSize: '20px',
                                    height: '40px',
                                }}
                            >
                                <PhoneInput
                                    className={`
                                    ${
                                        (emptyFields && user.phoneNumber === '') ||
                                        (emptyFields && !isValidPhoneNumber) ||
                                        (isUpdate && !isValidPhoneNumber)
                                            ? 'error-border'
                                            : ''
                                    }
                                `}
                                    country={'mx'}
                                    required
                                    enableSearch={true}
                                    placeholder=''
                                    name='phoneNumber'
                                    specialLabel={(t('employee_phone_number') + '*').toUpperCase()}
                                    value={user.phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    localization={phoneInputLanguage(language)}
                                    inputProps={{
                                        name: 'phoneNumber',
                                        required: true,
                                        autoComplete: 'off',
                                    }}
                                />
                                {(emptyFields && user.phoneNumber === '') || (isUpdate && user.phoneNumber === '') ? (
                                    <FormHelperText className='select_helper_text'>{t('requiredField')}</FormHelperText>
                                ) : (
                                    ((emptyFields && !isValidPhoneNumber) || (isUpdate && !isValidPhoneNumber)) && (
                                        <FormHelperText className='select_helper_text'>
                                            {t('phone_number_format_invalid')}
                                        </FormHelperText>
                                    )
                                )}{' '}
                            </Box>
                            {isUpdate && userType == contractorFile && user.statusId > 3 && (
                                <Box
                                    className='add_provider_text_field required-data'
                                    sx={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        fontSize: '20px',
                                        height: '40px',
                                    }}
                                >
                                    <FormControl fullWidth sx={textFieldCommon}>
                                        <InputLabel id='demo-simple-select-label'>{t('status')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='demo-simple-select'
                                            value={user.statusId}
                                            onChange={(e) =>
                                                setUser((prevData) => ({
                                                    ...prevData,
                                                    statusId: e.target.value,
                                                }))
                                            }
                                            label={t('status')}
                                        >
                                            {userInfoStatusList?.map((item) => {
                                                return (
                                                    <MenuItem value={item?.id}>
                                                        {t(status[item.id]).toUpperCase()}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}
                            {isUpdate && userType == supplierFile && user.statusId > 3 && (
                                <Box
                                    className='add_provider_text_field required-data'
                                    sx={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        fontSize: '20px',
                                        height: '40px',
                                    }}
                                >
                                    <FormControl fullWidth sx={textFieldCommon}>
                                        <InputLabel id='demo-simple-select-label'>{t('status')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='demo-simple-select'
                                            value={user.statusId}
                                            onChange={(e) =>
                                                setUser((prevData) => ({
                                                    ...prevData,
                                                    statusId: e.target.value,
                                                }))
                                            }
                                            label={t('status')}
                                        >
                                            {userInfoStatusList?.map((item) => {
                                                return (
                                                    <MenuItem value={item?.id}>
                                                        {t(status[item.id]).toUpperCase()}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}
                            <Box className='add_provider_text_field required-data'>
                                <FormControl fullWidth sx={textFieldCommon}>
                                    <InputLabel id='genderId'>{t('gender')}</InputLabel>
                                    <Select
                                        size='small'
                                        labelId='genderId'
                                        id='demo-simple-select'
                                        label={t('gender')}
                                        value={user.genderId}
                                        onChange={(e) =>
                                            setUser((prevData) => ({
                                                ...prevData,
                                                genderId: e.target.value,
                                            }))
                                        }
                                    >
                                        <MenuItem value={1}>{t('male').toUpperCase()}</MenuItem>
                                        <MenuItem value={2}>{t('female').toUpperCase()}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className='add_provider_text_field required-data'>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateInputLanguage(lCode)}>
                                    <DatePicker
                                        allowSameDateSelection
                                        disableFuture
                                        name='dob'
                                        label={t('dob')}
                                        inputFormat='dd/MM/yyyy'
                                        value={user.dob}
                                        onChange={handleDateChange}
                                        onError={(error) => setIsValidDob(error === null)}
                                        renderInput={(params) => (
                                            <TextField
                                                name='dob'
                                                size='small'
                                                sx={textFieldCommon}
                                                fullWidth
                                                helperText={!isValidDob && t('date_invalid')}
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                    </div>
                    <div>
                        {getUserFullInformation.userTypeId !== 3 && getUserFullInformation.userTypeId !== 2 && (
                            <div className='cards-second-row'>
                                <CustomTextWithLine title={t('access_rights')} />
                                <Box sx={{ margin: '2rem 0rem' }}>
                                    <Step4AccessRights
                                        details={true}
                                        workShiftsList={workShiftsList}
                                        setWorkShiftsList={setWorkShiftsList}
                                        customizedList={customizedList}
                                        setCustomizedList={setCustomizedList}
                                        contractorItemId={contractorItemId}
                                    />
                                </Box>
                            </div>
                        )}
                    </div>
                    <div className='footer'>
                        <button
                            className='custom_primary_btn_dark'
                            style={{ width: '100%' }}
                            onClick={() => {
                                validateEmail(user.email);
                                validatePhone(user.phoneNumber);
                                FormHandler();
                            }}
                        >
                            {' '}
                            {isUpdate && t('update').toUpperCase()} {!isUpdate && t('create').toUpperCase()}
                        </button>
                    </div>
                </div>
            </div>
            <UploadImageModal
                titlen={t('add_image')}
                show={showModal}
                setImage={setImageFile}
                preview={previewImage}
                setPreview={setPreviewImage}
                onHide={() => setShowModal(false)}
            />
            <DeleteModal
                title_modal={isEmployee ? t('delete_employee') : ''}
                show={deleteUser}
                onHide={() => setDeleteUser(false)}
                onClickFn={() => deleteSelectedElement(selectElementForDelete)}
            />
        </>
    );
};

export default AddUserType;
