import React from 'react';
import { Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import cloudsvg from '../assets/images/cloud.svg';
import { toast } from 'react-toastify';
import { fileSize } from '../constant/variable';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import jpgIcon from '../assets/fileIcon/jpg.png';
import pdfIcon from '../assets/fileIcon/pdf.png';
import pngIcon from '../assets/fileIcon/png.png';
import wordIcon from '../assets/fileIcon/word.png';
import exelIcon from '../assets/fileIcon/xls.png';
import { useDispatch } from 'react-redux';
import { CreateSupplierDocValue } from '../reduxToolkit/Providers/providersApi';
import { UploadImage } from '../reduxToolkit/ShareSlice/shareApi';
import cancel from '../assets/images/ic-cancel.svg';

const UploadFileModal = (props) => {
    // destructure from prop
    const { titlen, ApiType, data, createDocValue, show, onUpdate } = props;
    // title: title of your modal
    // ApiType: type of calling model from module
    // data: response data from api
    // createDocValue : api to upload doc

    // use hook
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [fileData, setFileData] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileDate, setFileDate] = useState('');
    const validFileTypes = ['pdf', 'docx', 'xlsx', 'xlm', 'png', 'jpg', 'jpeg'];

    useEffect(() => {
        if (show) {
            setFileData('');
        }
    }, [show]);

    // funtion to handle file upload
    const handleImageUpload = (event) => {
        if (event.type !== 'change') {
            event.map((file) => {
                const checkFile = file?.name?.split('.')?.pop();
                const fileImage = validFileTypes.includes(checkFile);
                const date = new Date(file?.lastModified); // current date and time
                const utcString = date.toUTCString();
                setFileDate(utcString);
                if (fileImage) {
                    if (file?.size <= fileSize) {
                        setFileData(file);
                        setFileType(file?.name?.split('.')?.pop());
                    } else {
                        setFileData('');
                        toast.error(
                            `${t('the_file_size_should_not_exceed').toUpperCase()} ${fileSize / (1024 * 1024)}MB.`,
                        );
                    }
                } else {
                    setFileData('');
                    toast.error(t('error_file_format').toUpperCase()).toUpperCase();
                }
            });
        } else {
            const files = event.target.files;
            // setUploadFile(e.target.files[0])
            Array.from(files).forEach((file) => {
                const checkFile = file?.name?.split('.')?.pop();
                const fileImage = validFileTypes.includes(checkFile);
                const date = new Date(file?.lastModified); // current date and time
                const utcString = date.toUTCString();
                setFileDate(utcString);
                if (fileImage) {
                    if (file?.size <= fileSize) {
                        setFileData(file);
                        setFileType(file?.name?.split('.')?.pop());
                    } else {
                        setFileData('');
                        toast.error(
                            `${t('the_file_size_should_not_exceed').toUpperCase()} ${fileSize / (1024 * 1024)}MB.`,
                        );
                    }
                } else {
                    setFileData('');
                    toast.error(t('error_file_format').toUpperCase());
                }
            });
        }
    };
    // funtion to call api or handle image
    const handelUploadImageAction = () => {
        // right logic here
        if (fileData) {
            let documentId = data?.id;

            switch (ApiType) {
                case ApiType:
                    const payload = {
                        id: data?.userId || data?.vehicleId,
                        document: '',
                        companyDocumentId: data?.companyDocumentId,
                    };
                    if (documentId) {
                        // if (params?.file) {

                        let formData = new FormData();
                        formData.append('id', documentId);
                        formData.append('option', 'employee_document');
                        formData.append('file', fileData);
                        dispatch(UploadImage(formData)).then(
                            ({
                                payload: {
                                    data: { data, success },
                                },
                            }) => {
                                if (success === true) {
                                    toast.success(t('file_uploaded_successfully').toUpperCase());
                                    onUpdate();
                                } else {
                                    toast.error(t('fail_to_upload_file').toUpperCase());
                                }
                            },
                        );
                        // }
                    } else {
                        dispatch(createDocValue({ data: payload, file: fileData })).then(
                            ({
                                payload: {
                                    data: { data, success },
                                },
                            }) => {
                                if (success === true) {
                                    toast.success(t('file_uploaded_successfully').toUpperCase());
                                    onUpdate();
                                } else {
                                    toast.error(t('fail_to_upload_file').toUpperCase());
                                }
                            },
                        );
                    }
                    props.onHide();
                    break;
                default:
                    toast.error('Mix match module');
            }
        } else {
            toast?.warn(t('please_select_a_file').toUpperCase());
        }
    };
    return (
        <Modal
            {...props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className='files_upload_conatiner'
        >
            <Modal.Header className='header_top'>
                <Modal.Title id='contained-modal-title-vcenter '>{titlen}</Modal.Title>
                <div className='modal-close-custom'>
                    <img
                        onClick={() => {
                            props.onHide();
                            setFileData('');
                        }}
                        src={cancel}
                        alt=''
                    />
                </div>
            </Modal.Header>

            <Modal.Body>
                <Dropzone
                    onDrop={(acceptedFiles) => {
                        handleImageUpload(acceptedFiles);
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section className='drop_zone_area'>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} onChange={(e) => handleImageUpload(e)} />
                                <div className='drop_zone_item'>
                                    <img src={cloudsvg} alt='' />
                                    <p>
                                        {t('drag_your_file_or')}
                                        <br />
                                        {t('click_to_upload')}
                                        <br /> <span>{`${t('max_size')} ${fileSize / (1024 * 1024)}MB`}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                {fileData && (
                    <div className='preview_file '>
                        <div className='preview_file_item'>
                            <img
                                src={
                                    (fileType === 'pdf' && pdfIcon) ||
                                    (fileType === 'jpg' && jpgIcon) ||
                                    (fileType === 'jpeg' && jpgIcon) ||
                                    (fileType === 'png' && pngIcon) ||
                                    (fileType === 'xlsx' && exelIcon) ||
                                    (fileType === 'docx' && wordIcon)
                                }
                                alt='pdf'
                            />
                            <div>
                                <p className='file_type'>
                                    {fileData?.name?.length > 18
                                        ? fileData?.name.slice(0, 18) + '.' + fileType
                                        : fileData?.name}
                                </p>

                                <p className='time_line'>{fileDate}</p>
                            </div>
                            <i onClick={() => setFileData('')} className='fa fa-times' aria-hidden='true'></i>
                        </div>
                    </div>
                )}

                <div className='footer'>
                    <button
                        onClick={() => {
                            props.onHide();
                            setFileData('');
                        }}
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '180px' }}
                    >
                        {t('cancel')}
                    </button>
                    <button
                        onClick={() => {
                            handelUploadImageAction();
                        }}
                        className='custom_primary_btn_dark'
                        style={{ width: '178px' }}
                    >
                        {t('upload').toUpperCase()}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default UploadFileModal;
