import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import cancel from '../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { FormControlLabel, Checkbox } from '@mui/material';
import {
    ExportDataExcel,
    ExportDataExcelCustom,
    ExportDataPDF,
    ExportDataPDFCustom,
    ExportExcelSimple,
    ExportExcelSimpleCustom,
    exportPDFSimple,
    ExportPDFSimpleCustom,
} from '../../reduxToolkit/Files/FilesApi';
import {
    CreateAttendanceExcelReport,
    CreateAttendancePDFReport,
    CreateSimpleAttendanceExcelReport,
    CreateSimpleAttendancePDFReport,
} from '../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import { toast } from 'react-toastify';
const OptionModal = ({ show, onClose, excel, option, body, searchElement, exportAttendance, search }) => {
    const { t } = useTranslation();
    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const optionsCheckbox = useSelector((state) => state.FilesSlice.getExcelHeadersByOption);
    const checkboxes = optionsCheckbox?.data || [];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const open = Boolean(anchorEl);

    const handleCheckboxChange = (checkboxId, checkboxName) => {
        const isChecked = selectedOptions.includes(checkboxName);

        if (isChecked) {
            setSelectedOptions(selectedOptions.filter((name) => name !== checkboxName));
        } else {
            setSelectedOptions([...selectedOptions, checkboxName]);
        }
    };

    const clearSelectedOptions = () => {
        setSelectedOptions([]);
    };

    const dispatch = useDispatch();

    const handleDownloadPDF = () => {
        if (option !== false) {
            let bodyToSend = { ...body };
            if (selectedOptions.length > 0) {
                bodyToSend.headers = selectedOptions;
            }

            if (contractorId === null && supplierId === null) {
                setAnchorEl(null);

                if (searchElement !== undefined) {
                    dispatch(
                        exportPDFSimple({
                            body: bodyToSend,
                        }),
                    )
                        .then(({ payload: { data, status } }) => {
                            if (status === 200) {
                                clearSelectedOptions();
                                onClose();
                            } else {
                                console.error('Descarga fallida:', data);
                            }
                        })
                        .catch((error) => {
                            console.error('Error manejando la promesa:', error);
                        });
                } else {
                    dispatch(
                        ExportDataPDF({
                            option: option,
                            body: bodyToSend,
                        }),
                    )
                        .then(({ payload: { data, status } }) => {
                            if (status === 200) {
                                clearSelectedOptions();
                                onClose();
                            } else {
                                console.error('Descarga fallida:', data);
                            }
                        })
                        .catch((error) => {
                            console.error('Error manejando la promesa:', error);
                        });
                }
            } else if (contractorId !== null) {
                setAnchorEl(null);
                const updatedBody = {
                    ...bodyToSend,
                    userToFindId: contractorId,
                };
                dispatch(
                    ExportDataPDFCustom({
                        option: option,
                        body: updatedBody,
                    }),
                )
                    .then(({ payload: { data, status } }) => {
                        if (status === 200) {
                            clearSelectedOptions();
                            onClose();
                        } else {
                            console.error('Descarga fallida:', data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error manejando la promesa:', error);
                    });
            } else if (supplierId !== null) {
                setAnchorEl(null);
                const updatedBody = {
                    ...bodyToSend,
                    userToFindId: supplierId,
                };
                dispatch(
                    ExportDataPDFCustom({
                        option: option,
                        body: updatedBody,
                    }),
                )
                    .then(({ payload: { data, status } }) => {
                        if (status === 200) {
                            clearSelectedOptions();
                            onClose();
                        } else {
                            console.error('Descarga fallida:', data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error manejando la promesa:', error);
                    });
            }
        } else {
            if (contractorId === null && supplierId === null) {
                setAnchorEl(null);
                dispatch(
                    exportPDFSimple({
                        body: body,
                    }),
                )
                    .then(({ payload: { data, status } }) => {
                        if (status === 200) {
                            clearSelectedOptions();
                            onClose();
                        } else {
                            console.error('Descarga fallida:', data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error manejando la promesa:', error);
                    });
            } else {
                let bodyWithId = {
                    ...body,
                    userId: contractorId !== null ? contractorId : supplierId,
                };
                setAnchorEl(null);
                dispatch(
                    ExportPDFSimpleCustom({
                        body: bodyWithId,
                    }),
                )
                    .then(({ payload: { data, status } }) => {
                        if (status === 200) {
                            clearSelectedOptions();
                            onClose();
                        } else {
                            console.error('Descarga fallida:', data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error manejando la promesa:', error);
                    });
            }
        }
    };

    const handleDownloadExcel = () => {
        let bodyToSend = { ...body };
        if (selectedOptions.length > 0) {
            bodyToSend.headers = selectedOptions;
        }

        if (option !== false) {
            if (contractorId === null && supplierId === null) {
                setAnchorEl(null);
                if (searchElement !== undefined) {
                    dispatch(
                        ExportExcelSimple({
                            body: bodyToSend,
                        }),
                    )
                        .then(({ payload: { data, status } }) => {
                            if (status === 200) {
                                clearSelectedOptions();
                                onClose();
                            } else {
                                console.error('Descarga fallida:', data);
                            }
                        })
                        .catch((error) => {
                            console.error('Error manejando la promesa:', error);
                        });
                } else {
                    dispatch(
                        ExportDataExcel({
                            option: option,
                            body: bodyToSend,
                        }),
                    )
                        .then(({ payload: { data, status } }) => {
                            if (status === 200) {
                                clearSelectedOptions();
                                onClose();
                            } else {
                                console.error('Descarga fallida:', data);
                            }
                        })
                        .catch((error) => {
                            console.error('Error manejando la promesa:', error);
                        });
                }
            } else if (contractorId !== null) {
                setAnchorEl(null);
                const updatedBody = {
                    ...bodyToSend,
                    userToFindId: contractorId,
                };
                dispatch(
                    ExportDataExcelCustom({
                        option: option,
                        body: updatedBody,
                    }),
                )
                    .then(({ payload: { data, status } }) => {
                        if (status === 200) {
                            clearSelectedOptions();
                            onClose();
                        } else {
                            console.error('Descarga fallida:', data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error manejando la promesa:', error);
                    });
            } else if (supplierId !== null) {
                setAnchorEl(null);
                const updatedBody = {
                    ...bodyToSend,
                    userToFindId: supplierId,
                };
                dispatch(
                    ExportDataExcelCustom({
                        option: option,
                        body: updatedBody,
                    }),
                )
                    .then(({ payload: { data, status } }) => {
                        if (status === 200) {
                            clearSelectedOptions();
                            onClose();
                        } else {
                            console.error('Descarga fallida:', data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error manejando la promesa:', error);
                    });
            }
        } else {
            if (contractorId === null && supplierId === null) {
                setAnchorEl(null);
                dispatch(
                    ExportExcelSimple({
                        body: bodyToSend,
                    }),
                )
                    .then(({ payload: { data, status } }) => {
                        if (status === 200) {
                            clearSelectedOptions();
                            onClose();
                        } else {
                            console.error('Descarga fallida:', data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error manejando la promesa:', error);
                    });
            } else {
                let bodyWithId = {
                    ...body,
                    userId: contractorId !== null ? contractorId : supplierId,
                };
                setAnchorEl(null);
                dispatch(
                    ExportExcelSimpleCustom({
                        body: bodyWithId,
                    }),
                )
                    .then(({ payload: { data, status } }) => {
                        if (status === 200) {
                            clearSelectedOptions();
                            onClose();
                        } else {
                            console.error('Descarga fallida:', data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error manejando la promesa:', error);
                    });
            }
        }
    };
    const handleDownloadPDFAttendance = () => {
        let bodyToSend = { ...body };
        if (selectedOptions.length > 0) {
            bodyToSend.headers = selectedOptions;
        }
        if (exportAttendance && searchElement) {
            bodyToSend.model = option;
            bodyToSend.key = searchElement;
        }
        if (searchElement !== '') {
            setAnchorEl(null);
            dispatch(
                CreateSimpleAttendancePDFReport({
                    body: bodyToSend,
                }),
            )
                .then(({ payload: { data, status } }) => {
                    if (status === 200) {
                        clearSelectedOptions();
                        onClose();
                    } else {
                        console.error('Descarga fallida:', data);
                    }
                })
                .catch((error) => {
                    console.error('Error manejando la promesa:', error);
                });
        } else {
            dispatch(
                CreateAttendancePDFReport({
                    body: bodyToSend,
                }),
            )
                .then(({ payload: { data, status } }) => {
                    if (status === 200) {
                        clearSelectedOptions();
                        onClose();
                    } else {
                        console.error('Descarga fallida:', data);
                    }
                })
                .catch((error) => {
                    console.error('Error manejando la promesa:', error);
                });
        }
    };
    const handleDownloadExcelAttendance = () => {
        let bodyToSend = { ...body };
        if (selectedOptions.length > 0) {
            bodyToSend.headers = selectedOptions;
        }
        if (exportAttendance && searchElement) {
            bodyToSend.model = option;
            bodyToSend.key = searchElement;
        }
        if (searchElement !== '') {
            setAnchorEl(null);
            dispatch(
                CreateSimpleAttendanceExcelReport({
                    body: bodyToSend,
                }),
            )
                .then(({ payload: { data, status } }) => {
                    if (status === 200) {
                        clearSelectedOptions();
                        onClose();
                    } else {
                        toast.error(t('download_failed').toUpperCase());
                    }
                })
                .catch((error) => {
                    toast.error(t('error_handling_promise').toUpperCase());
                });
        } else {
            dispatch(
                CreateAttendanceExcelReport({
                    body: bodyToSend,
                }),
            )
                .then(({ payload: { data, status } }) => {
                    if (status === 200) {
                        clearSelectedOptions();
                        onClose();
                    } else {
                        toast.error(t('download_failed').toUpperCase());
                    }
                })
                .catch((error) => {
                    toast.error(t('error_handling_promise').toUpperCase());
                });
        }
    };
    return (
        <Modal
            className='import-file-modal px-5'
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            show={show}
            onHide={onClose}
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('export_file')}</Modal.Title>
                <img className='modalClose' src={cancel} alt='' onClick={onClose} />
            </Modal.Header>
            <Modal.Body className='department_modal_body mx-4'>
                <p className='paragraph_deps mt-3' style={{ textAlign: 'center' }}>
                    {t('instruction_headers')}
                </p>
                <p className='paragraph_deps mt-3'>
                    {t('option')} <span>{excel ? t('excel') : t('pdf')}</span>
                </p>

                <p className='paragraph_deps mt-3'>
                    <p className='paragraph_deps mt-3'>
                        {t('model')} <span>{t(option)}</span>
                    </p>
                </p>
                <div className='update_options-excel'>
                    {checkboxes.map((checkbox) => (
                        <div key={checkbox.id} className='checkbox-item col-md-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={checkbox.id}
                                        name={checkbox.name}
                                        onChange={() => handleCheckboxChange(checkbox.id, checkbox.name)}
                                    />
                                }
                                label={<span style={{ fontSize: '12px' }}>{t(checkbox.name)}</span>}
                            />
                        </div>
                    ))}
                </div>

                <div className='d-flex justify-content-center mt-5'>
                    <button className='custom_btn_cancel_gray_hover' style={{ width: '180px' }} onClick={onClose}>
                        {t('cancel')}
                    </button>
                    <button
                        onClick={
                            excel
                                ? exportAttendance
                                    ? handleDownloadExcelAttendance
                                    : handleDownloadExcel
                                : exportAttendance
                                  ? handleDownloadPDFAttendance
                                  : handleDownloadPDF
                        }
                        className='custom_primary_btn_dark'
                        style={{ width: '180px' }}
                    >
                        {t('export').toUpperCase()}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default OptionModal;
