import React, { useState } from 'react';
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    Select,
    Typography,
    TextField,
    MenuItem,
    Button,
    IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/logo/logo 3.png';
import './SuccessValidationForm.scss';
import { useSelector, useDispatch } from 'react-redux';
import convertirDobAFechaString from '../../../utils/ConvertDobToDate';
import { updateVeridasUser } from '../../../reduxToolkit/Visitor/VisitorApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { errorCode } from '../../../enums/errorCodeEnum';
import { id } from 'date-fns/locale';

const SuccessValidationForm = () => {
    const dispatch = useDispatch();
    const userDataSuccess = useSelector((state) => state.VisitorSlice.userDataDocument);
    const validationData = useSelector((state) => state.VisitorSlice.getValidationId);
    const userIdRedux = useSelector((state) => state.VisitorSlice.userId);
    const { t } = useTranslation();
    const [requestCompleted, setRequestCompleted] = useState(false);
    const [modifiedData, setModifiedData] = useState(userDataSuccess);
    const [birthday, setBirthday] = useState(convertirDobAFechaString(userDataSuccess.dob));
    const navigate = useNavigate();
    const handleFirstNameChange = (event) => {
        setModifiedData({ ...modifiedData, name: event.target.value });
    };

    const handleLastNameChange = (event) => {
        setModifiedData({ ...modifiedData, lastName: event.target.value });
    };

    const handleMiddleNameChange = (event) => {
        setModifiedData({ ...modifiedData, secondLastName: event.target.value });
    };
    const handleDocumentIdChange = (event) => {
        setModifiedData({ ...modifiedData, documentId: event.target.value });
    };

    const handleGenderChange = (event) => {
        setModifiedData({ ...modifiedData, gender: event.target.value });
    };

    const handleBirthdayChange = (event) => {
        const dobValue = convertirDobAFechaString(event.target.value);
        setBirthday(dobValue);
    };

    const handleSubmit = () => {
        if (JSON.stringify(modifiedData) !== JSON.stringify(userDataSuccess)) {
            dispatch(
                updateVeridasUser({
                    userId: userIdRedux,
                    validationId: validationData,
                    ocrAndScoresResponse: modifiedData,
                }),
            ).then((response) => {
                const { data, success, code, message } = response.payload;

                if (success === true) {
                    navigate(`/success-validation`);
                } else {
                    navigate(`/validation-fail`);
                    const errorKey = errorCode[code];
                }
            });
        } else {
            dispatch(
                updateVeridasUser({
                    userId: userIdRedux,
                    validationId: validationData,
                    ocrAndScoresResponse: userDataSuccess,
                }),
            ).then((response) => {
                const { data, success, code, message } = response.payload;

                if (success === true) {
                    navigate(`/success-validation`);
                } else {
                    navigate(`/validation-fail`);
                    const errorKey = errorCode[code];
                }
            });
        }
    };

    return (
        <Box className='background-document-select'>
            <Box className='background-document-select__modal'>
                <Grid container direction='column' alignItems='center'>
                    <img
                        src={logo}
                        alt='Identity Validated'
                        className='responsive-image background-document-select__image'
                    />

                    <Grid item>
                        <Typography
                            className='document-validation background-document-select__validation'
                            variant='h6'
                            gutterBottom
                        >
                            {t('identity_validation')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            className='document-process background-document-select__process'
                            variant='body1'
                            sx={{ textAlign: 'center' }}
                            component='body1'
                        >
                            {t('validate_user_message')}
                        </Typography>
                    </Grid>

                    <div className='form-control-container background-document-select__form-control'>
                        <TextField
                            id='txt_sv_name'
                            label={t('name')}
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={modifiedData.name}
                            onChange={handleFirstNameChange}
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            id='txt_sv_firstName'
                            label={t('first_surname')}
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={modifiedData.lastName}
                            onChange={handleLastNameChange}
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            id='txt_sv_secondSurname'
                            label={t('second_surname')}
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={modifiedData.secondLastName}
                            onChange={handleMiddleNameChange}
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            id='txt_sv_documentId'
                            label={t('documentId')}
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={modifiedData.documentId}
                            onChange={handleDocumentIdChange}
                            style={{ marginBottom: '10px' }}
                        />
                        <FormControl fullWidth size='small' style={{ marginBottom: '10px' }}>
                            <InputLabel id='genero-label'>{t('gender')}</InputLabel>
                            <Select
                                labelId='genero-label'
                                id='txt_sv_genero'
                                label={t('gender')}
                                variant='outlined'
                                fullWidth
                                value={modifiedData.gender}
                                onChange={handleGenderChange}
                            >
                                <MenuItem value='1'>{t('male')}</MenuItem>
                                <MenuItem value='2'>{t('female')}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            id='txt_sv_birthday'
                            label={t('birthday')}
                            type='date'
                            variant='outlined'
                            fullWidth
                            value={birthday}
                            onChange={handleBirthdayChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size='small'
                            style={{ marginBottom: '10px' }}
                        />

                        <Button
                            fullWidth
                            className='document-button-continue background-document-select__button'
                            variant='contained'
                            color='primary'
                            id='btn_sv_AcceptAndContinue'
                            onClick={handleSubmit}
                        >
                            {t('accept_and_continue')}
                        </Button>
                    </div>
                </Grid>
            </Box>
        </Box>
    );
};

export default SuccessValidationForm;
