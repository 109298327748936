/*
Author : Arman Ali
Module: create Vehicle
github: https://github.com/Arman-Arzoo
*/
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useStyle from '../../../../../hooks/useStyle';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, FormHelperText } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import DeleteModal from '../../../../Modals/DeleteModal';
import RemoveDriver from './RemoveDriver';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddDriver, UpdateDriver } from '../../../../../reduxToolkit/Vehicle/VehicleApi';
import SelectWithSearch from '../../../../../components/SelectWithSearch';
import { toast } from 'react-toastify';
import { GetAllEmployeesPayroll } from '../../../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import { Driver } from '../../../../../models/Driver';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import dateInputLanguage from '../../../../../hooks/dateInputLanguage';

const DriverModal = (props) => {
    // destructure from prop
    const { id, data, title, checkmodal, show, updateDriver } = props;
    // id : id of the current vehicle details
    // title: title of your modal
    // checkmodal: checkmodal is other add or update
    // data: data of current card driver

    // use hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const lCode = Cookies.get('i18next') || 'en';

    const { textField, smallBoxStyle, textFieldCommon } = useStyle();
    const [showDriver, setShowDriver] = useState(false);
    const [showDatePickers, setShowDatePickers] = useState(true);

    const [driver, setDriver] = useState({ ...Driver, vehicleId: id, id: data.id });
    const [emptyFields, setEmptyFields] = useState(false);
    const [isValidFrom, setIsValidFrom] = useState(true);
    const [isValidTo, setIsValidTo] = useState(true);

    // useSlector
    const { getallEmployee } = useSelector((state) => state.employeeOnBoardingSlice);
    const { premmissionType } = useSelector((state) => state.VehicleSlice);
    const { getAllEmployeesPayroll } = useSelector((state) => state.employeePayrollSlice);
    //reset
    const reset = () => {
        setDriver({ ...Driver, vehicleId: id, id: data.id });
    };

    // funtion for handle select
    const handleSelect = (selectedOption) => {
        // setEmployee(selectedOption?.id)
        setDriver((prevData) => ({
            ...prevData,
            userId: selectedOption?.id,
        }));
    };

    // handel driver function
    const handelDriver = () => {
        setEmptyFields(true);

        if (checkmodal == 'update') {
            if (driver.vehiclePermissionTypeId === 2) {
                if (
                    driver.from !== null &&
                    driver.to !== null &&
                    driver.vehiclePermissionTypeId !== '' &&
                    isValidFrom &&
                    isValidTo
                ) {
                    dispatch(UpdateDriver(driver.toCreateUpdate()));
                    props.onHide();
                } else {
                    toast.warn(t('please_fill_all_fields').toUpperCase());
                }
            } else {
                if (driver.userId !== '' || driver.vehiclePermissionTypeId !== '') {
                    dispatch(UpdateDriver(driver.toCreateUpdate()));
                    props.onHide();
                } else {
                    toast.warn(t('please_fill_all_fields').toUpperCase());
                }
            }
        } else {
            if (driver.vehiclePermissionTypeId === 2) {
                if (
                    driver.from === null ||
                    driver.to === null ||
                    driver.userId === '' ||
                    driver.vehiclePermissionTypeId === '' ||
                    !isValidFrom ||
                    !isValidTo
                ) {
                    toast.warn(t('please_fill_all_fields').toUpperCase());
                } else {
                    dispatch(AddDriver(driver.toCreateUpdate()));
                    reset();
                    props.onHide();
                }
            } else {
                if (driver.userId === '' || driver.vehiclePermissionTypeId === '') {
                    toast.warn(t('please_fill_all_fields').toUpperCase());
                } else {
                    dispatch(AddDriver(driver.toCreateUpdate()));
                    reset();
                    props.onHide();
                }
            }
        }
    };

    // handel the logic for object transformation for all user
    const userDataTransformUser = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.name || ''} ${item?.firstLastName || ''} ${item?.secondLastName || ''}`.trim(),
                id: item?.id || item?.userId,
            };
        });
        return newData;
    };

    const handleFromDateChange = (selectedDate) => {
        setDriver((prevData) => ({
            ...prevData,
            from: selectedDate,
        }));
        if (driver.to !== null && driver.to < selectedDate) {
            setIsValidTo(false);
        } else if (driver.to !== null && driver.to > selectedDate) {
            setIsValidTo(true);
        }
    };

    const handleToDateChange = (selectedDate) => {
        setDriver((prevData) => ({
            ...prevData,
            to: selectedDate,
        }));
    };

    // dispatch to get result
    useEffect(() => {
        dispatch(GetAllEmployeesPayroll());
    }, []);

    // dispatch to get result
    useEffect(() => {
        if (driver.vehiclePermissionTypeId === 1) {
            setDriver((prevData) => ({
                ...prevData,
                to: null,
                from: null,
            }));
        }
    }, [driver]);

    // dispatch to get result
    useEffect(() => {
        setEmptyFields(false);
        if (checkmodal === 'update') {
            setDriver({
                ...Driver,
                vehicleId: id,
                id: data.id,
                from: data?.from || null,
                to: data?.to || null,
                vehiclePermissionTypeId: data?.vehiclePermissionTypeId || '',
            });
        } else {
            setDriver({ ...Driver, vehicleId: id });
        }
        setIsValidFrom(true);
        setIsValidTo(true);
    }, [props.show]);

    const handleErrorTo = (error) => {
        debugger;
    };

    // Function to manage the state of the permission Type
    useEffect(() => {
        setShowDatePickers(driver.vehiclePermissionTypeId !== 1);
    }, [driver.vehiclePermissionTypeId]);

    const handlePermissionTypeChange = (e) => {
        setDriver((prevData) => ({
            ...prevData,
            vehiclePermissionTypeId: e.target.value,
        }));
    };
    return (
        <>
            <Modal
                {...props}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                className='files_upload_conatiner'
            >
                <Modal.Header className='header_top'>
                    <Modal.Title id='contained-modal-title-vcenter' className='mt-4'>
                        {title}
                    </Modal.Title>
                    <img
                        onClick={() => {
                            props.onHide();
                            props?.isReset([]);
                            props?.isAllReset(false);
                        }}
                        className='modalClose'
                        src={cancel}
                        alt=''
                    />
                </Modal.Header>

                <Modal.Body>
                    <div className='driver_form'>
                        {checkmodal !== 'update' ? (
                            <Box sx={smallBoxStyle} className='required-data box-text-field'>
                                <SelectWithSearch
                                    options={userDataTransformUser(getAllEmployeesPayroll)}
                                    label={t('employee')}
                                    update={data}
                                    checkUpdate={checkmodal}
                                    onSelect={handleSelect}
                                    emptyFields={emptyFields}
                                />
                            </Box>
                        ) : (
                            <Box sx={smallBoxStyle} className='box-text-field'>
                                <TextField
                                    size='small'
                                    disabled
                                    fullWidth
                                    sx={textFieldCommon}
                                    label={t('employee')}
                                    id='name'
                                    value={`${data?.name || ''} ${data?.lastName || ''} ${
                                        data?.secondLastName || ''
                                    }`.trim()}
                                />
                            </Box>
                        )}
                        <Box sx={smallBoxStyle} className='box-text-field required-data'>
                            <FormControl
                                fullWidth
                                sx={textFieldCommon}
                                error={emptyFields && driver.vehiclePermissionTypeId === ''}
                            >
                                <InputLabel id='PERMISSIONTYPE'>{t('permission_type')}</InputLabel>
                                <Select
                                    size='small'
                                    labelId='PERMISSIONTYPE'
                                    id='PERMISSIONTYPEId'
                                    label={t('permission_type')}
                                    value={driver.vehiclePermissionTypeId}
                                    onChange={(e) =>
                                        setDriver((prevData) => ({
                                            ...prevData,
                                            vehiclePermissionTypeId: e.target.value,
                                            handlePermissionTypeChange,
                                        }))
                                    }
                                >
                                    {premmissionType?.map((item, index) => {
                                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                    })}
                                </Select>
                                {emptyFields && driver.vehiclePermissionTypeId === '' && (
                                    <FormHelperText className='select_helper_text'>
                                        {t('selectOptionText')}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        {showDatePickers && (
                            <>
                                <Box sx={smallBoxStyle} className='box-text-field required-data'>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={dateInputLanguage(lCode)}
                                    >
                                        <DatePicker
                                            allowSameDateSelection
                                            name='from'
                                            label={t('from')}
                                            inputFormat={lCode === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                                            disablePast
                                            value={driver.from}
                                            disabled={
                                                driver.vehiclePermissionTypeId === 1 ||
                                                driver.vehiclePermissionTypeId === ''
                                            }
                                            onChange={handleFromDateChange}
                                            onError={(error) =>
                                                setIsValidFrom(error === null || error === 'disablePast')
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    name='from'
                                                    size='small'
                                                    sx={textFieldCommon}
                                                    fullWidth
                                                    helperText={
                                                        emptyFields &&
                                                        driver.from === null &&
                                                        driver.vehiclePermissionTypeId === 2
                                                            ? t('please_select_date')
                                                            : !isValidFrom
                                                              ? t('date_invalid')
                                                              : ''
                                                    }
                                                    {...params}
                                                    error={
                                                        (emptyFields &&
                                                            driver.from === null &&
                                                            driver.vehiclePermissionTypeId === 2) ||
                                                        !isValidFrom
                                                    }
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box sx={smallBoxStyle} className='box-text-field required-data'>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={dateInputLanguage(lCode)}
                                    >
                                        <DatePicker
                                            allowSameDateSelection
                                            minDate={driver.from}
                                            name='to'
                                            label={t('to')}
                                            inputFormat={lCode === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                                            disablePast
                                            value={driver.to}
                                            disabled={driver.from === null}
                                            onChange={handleToDateChange}
                                            onError={(error) => setIsValidTo(error === null || error === 'disablePast')}
                                            // onError={(error) => handleErrorTo(error)}
                                            renderInput={(params) => (
                                                <TextField
                                                    name='dob'
                                                    size='small'
                                                    sx={textFieldCommon}
                                                    fullWidth
                                                    helperText={
                                                        emptyFields &&
                                                        driver.to === null &&
                                                        driver.vehiclePermissionTypeId === 2
                                                            ? t('please_select_date')
                                                            : !isValidTo
                                                              ? t('date_invalid')
                                                              : ''
                                                    }
                                                    {...params}
                                                    error={
                                                        (emptyFields &&
                                                            driver.to === null &&
                                                            driver.vehiclePermissionTypeId === 2) ||
                                                        !isValidTo
                                                    }
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </>
                        )}

                        {checkmodal == 'update' && (
                            <p
                                className='remove'
                                onClick={() => {
                                    setShowDriver(true);
                                    props.onHide();
                                }}
                            >
                                {t('remove_driver')}
                            </p>
                        )}
                    </div>

                    <div className='footer'>
                        <button
                            onClick={() => {
                                props.onHide();
                                reset();
                            }}
                            className='custom_btn_cancel_gray_hover'
                            style={{ width: '180px' }}
                        >
                            {t('cancel')}
                        </button>
                        <button
                            onClick={() => {
                                handelDriver();
                            }}
                            className='custom_primary_btn_dark'
                            style={{ width: '178px' }}
                        >
                            {checkmodal == 'update' ? t('update').toUpperCase() : t('add')}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <RemoveDriver
                show={showDriver}
                data={{
                    id: data?.id,
                    name: `${data?.name || ''} ${data?.lastName || ''} ${data?.secondLastName || ''}`.trim(),
                }}
                onHide={() => setShowDriver(false)}
                title_modal={t('remove_driver')}
                modal_name='remove_driver'
            />
        </>
    );
};

export default DriverModal;
