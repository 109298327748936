import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import Konva from 'konva';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { Button, ButtonGroup, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, Rect, Stage, Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TABLES } from '../../Apis/Tables';
import circleIcon from '../../assets/icon/circle-line-icon.svg';
import lineIcon from '../../assets/icon/grip-lines.svg';
import backCardIcon from '../../assets/icon/ic-back-card.svg';
import frontCardIcon from '../../assets/icon/ic-front-card.svg';
import heightIcon from '../../assets/icon/ic-heigh.svg';
import tIcon from '../../assets/icon/ic-t.svg';
import widthIcon from '../../assets/icon/ic-weight.svg';
import xIcon from '../../assets/icon/ic-x-line.svg';
import yIcon from '../../assets/icon/ic-y-line.svg';
import imageIcon from '../../assets/icon/image-icon.svg';
import orientationIcon from '../../assets/icon/page-rotate-icon.svg';
import squareIcon from '../../assets/icon/square-line-icon.svg';
import userImage from '../../assets/images/userDemoImg.png';
import ColorPicker from '../../components/ColorPicker';
import ReturnArrow from '../../components/buttons/ReturnArrow';
import { CreateTemplateCard, UpdateTemplateCard } from '../../reduxToolkit/Cards/CardsApi';
import { SearchByFilters } from '../../reduxToolkit/Search/SearchApi';
import Ellipsis from './CardElements/Ellipsis';
import ImageComponent from './CardElements/ImageComponent';
import InputProperty from './CardElements/InputProperty';
import Square from './CardElements/Square';
import StageAccordion from './CardElements/StageAccordion';
import TextComponent from './CardElements/Text';
import { clearSearchFilters } from '../../reduxToolkit/Search/SearchSlice';
import { permissionObj } from '../../Helpers/permission';
import { Spa } from '@mui/icons-material';
import FontSelect from './CardElements/Font';

export const CreateTemplate = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { searchByFilters } = useSelector((state) => state.SearchSlice);
    const userData = useSelector((state) => state?.authenticatioauthennSlice?.user.data);
    //tamaño de la card
    const cardWidth = 305;
    const cardHeight = 462;
    const cardBackgroundColor = 'white';

    const stageFront = useRef();
    const stageBack = useRef();
    // se guardan los elementos en arrays para el vertical y horizontal
    const [stageProperties, setStageProperties] = useState({
        1: {
            orientation: 'vertical',
            width: cardWidth,
            height: cardHeight,
            squares: [],
            ellipsis: [],
            lines: [],
            texts: [],
            resources: [],
            combinedObject: [],
        },
        2: {
            orientation: 'horizontal',
            width: cardWidth,
            height: cardHeight,
            squares: [],
            ellipsis: [],
            lines: [],
            texts: [],
            resources: [],
            combinedObject: [],
        },
    });

    const [currentStage, setCurrentStage] = useState(1);
    const [selectedShape, setSelectedShape] = useState(null);
    const [expanded, setExpanded] = useState('panel1');
    const [activeFontFamily, setActiveFontFamily] = useState('');
    const [fontSize, setFontSize] = useState(0);
    const [templateName, setTemplateName] = useState('');
    //tamaño de las formas
    const [shapeHeight, setShapeHeight] = useState(0);
    const [shapeWidth, setShapeWidth] = useState(0);
    //tamaños de vertical y horizonatal
    const [shapeHorizontal, setShapeHorizontal] = useState(0);
    const [shapeVertical, setShapeVertical] = useState(0);
    const [shapeCornerRadius, setShapeCornerRadius] = useState(0);
    const [shapeRadius, setShapeRadius] = useState(0);
    const [shapeStrokeWidth, setShapeStrokeWidth] = useState(0);
    const [shapeAlign, setShapeAlign] = useState('');
    const [shapeFontStyle, setShapeFontStyle] = useState('');
    const [shapeTextDecoration, setShapeTextDecoration] = useState('');
    const [folio, setFolio] = useState('');
    const [showResources, setShowResources] = useState(false);
    const [emptyTemplateName, setEmptyTemplateName] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState();
    let { state } = navigate;
    const location = useLocation();
    const stateFromLink = location.state;
    const [countertRectangle, setcountertRectangle] = useState(1);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    useEffect(() => {
        let state = stateFromLink;
        if (state !== null) {
            state = stateFromLink.state;
            let raw = JSON.parse(state.raw);
            setStageProperties(raw);
            setTemplateName(state.name);
            setIsEdit(true);
            setEditId(state.id);
            for (let key in raw) {
                raw[key].combinedObject.forEach((item) => {
                    if (item.liveContent === 'userSelfie') {
                        item.base64 = '/static/media/userDemoImg.1a05a7d972162c340842.png';
                    }
                });
            }
        }
    }, [stateFromLink]);
    //cambia la orientacion
    const toggleOrientation = () => {
        setStageProperties((prevProperties) => {
            const currentProperties = prevProperties[currentStage];
            const newOrientation = currentProperties.orientation === 'vertical' ? 'horizontal' : 'vertical';

            return {
                ...prevProperties,
                [currentStage]: {
                    ...currentProperties,
                    orientation: newOrientation,
                    //ajusta nuevo tamaño segun la orientacion
                    width: newOrientation === 'vertical' ? cardWidth : cardHeight,
                    height: newOrientation === 'vertical' ? cardHeight : cardWidth,
                },
            };
        });
    };
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleButtonClick = () => {
        setCurrentStage((prevStage) => (prevStage === 1 ? 2 : 1));
    };
    //maneja el trop establece los datos que se deben transferir durante el evento
    const handleDragStart = (e, buttonType, base64 = null, resourceId = null, attribute = null) => {
        e.dataTransfer.setData('buttonType', buttonType);
        if (base64) {
            e.dataTransfer.setData('base64', base64);
        }

        if (resourceId) {
            e.dataTransfer.setData('resourceId', resourceId);
        }

        if (attribute) {
            e.dataTransfer.setData('attribute', attribute);
        }
    };
    // aqui se maneja el evento de soltar un elemento
    const handleDrop = (e) => {
        e.preventDefault();
        //obtine los datos transferidos
        const buttonType = e.dataTransfer.getData('buttonType');
        const base64 = e.dataTransfer.getData('base64');
        const resourceId = e.dataTransfer.getData('resourceId');
        const attribute = e.dataTransfer.getData('attribute');
        const stage = currentStage === 1 ? stageFront.current : stageBack.current;
        // teremina donde se agrego el elemento si delante o detras
        const stagePos = {
            x: e.clientX - stage.container().getBoundingClientRect().left,
            y: e.clientY - stage.container().getBoundingClientRect().top,
        };
        //verificar que este en una posicion valida
        if (stagePos) {
            let shape;
            let property;
            //crea la forma dependiendo el caso con estos estilos predeterminados
            if (buttonType === 'rectangleTemplate') {
                shape = {
                    id: `squares_${searchId()}`,
                    x: Math.round(stagePos.x),
                    y: Math.round(stagePos.y),
                    width: 50,
                    height: 50,
                    type: buttonType,
                    fill: '#146F62',
                    property: 'squares',
                    textName: 'Cuadrado',
                    cornerRadius: 0,
                    stroke: 'black',
                    strokeWidth: 0,
                    skipTransform: true,
                };
                property = 'squares';
                setcountertRectangle(countertRectangle + 1);
            } else if (buttonType === 'ellipseTemplate') {
                shape = {
                    id: `ellipsis_${searchId()}`,
                    x: Math.round(stagePos.x),
                    y: Math.round(stagePos.y),
                    radius: 50,
                    type: buttonType,
                    fill: '#146F62',
                    property: 'ellipsis',
                    textName: `Circulo `,
                    stroke: 'black',
                    strokeWidth: 0,
                };
                property = 'ellipsis';
            } else if (buttonType === 'lineTemplate') {
                shape = {
                    id: `lines_${searchId()}`,
                    x: Math.round(stagePos.x),
                    y: Math.round(stagePos.y),
                    width: 100,
                    height: 5,
                    type: buttonType,
                    fill: '#146F62',
                    property: 'lines',
                    textName: `Línea `,
                    cornerRadius: 0,
                    stroke: 'black',
                    strokeWidth: 0,
                };

                property = 'lines';
            } else if (buttonType === 'textTemplate') {
                shape = {
                    id: `texts_${searchId()}`,
                    x: Math.round(stagePos.x),
                    y: Math.round(stagePos.y),
                    content: 'Your Text Here',
                    fontSize: 30,
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    text: 'centeredScaling = true',
                    textDecoration: 'none',
                    fill: 'blackkkkkkk',
                    type: buttonType,
                    property: 'texts',
                    textName: `Texto `,
                    align: 'center',
                    width: 123,
                    height: 111,
                    allowEdit: true,
                };
                //actualiza el contenido del texto con el atributo y deshabilita la edición
                if (attribute) {
                    shape.liveContent = attribute;
                    shape.content = attribute + '*';
                    shape.allowEdit = false;
                }

                property = 'texts';
            } else if (buttonType === 'imageTemplate') {
                shape = {
                    id: `resources_${searchId()}`,
                    x: Math.round(stagePos.x),
                    y: Math.round(stagePos.y),
                    type: buttonType,
                    property: 'resources',
                    textName: `Imagen `,
                    width: 50,
                    height: 50,
                    rotation: 0,
                    base64: base64,
                    resourceId: resourceId,
                };

                if (attribute) {
                    shape.liveContent = attribute;
                    shape.base64 = userImage;
                }

                property = 'resources';
            }

            updateStageProperties(shape, property);
            setSelectedShape(shape);
            updateForm(shape);
            setShowResources(false);
        }
    };
    //actualiza las propiedades de una forma
    const updateStageProperties = (shape, property, index = undefined, x = undefined, y = undefined) => {
        if (shape === undefined) {
            setShowResources(true);
            return;
        }
        setFontSize(fontSize);
        setShapeVertical(y);
        setShapeHorizontal(x);
        setStageProperties((prevProperties) => {
            const currentProperties = prevProperties[currentStage];
            const updatedShapes = [...currentProperties[property]];
            let updatedCombinedObject;

            if (index !== undefined) {
                updatedShapes[index] = { ...updatedShapes[index], x, y };
                updatedCombinedObject = currentProperties.combinedObject.map((item) => {
                    if (item?.id === (shape?.id || (selectedShape && selectedShape.id))) {
                        return { ...item, x, y };
                    }

                    return item;
                });
            } else {
                if (shape?.id) {
                    updatedShapes.push(shape);
                    updatedCombinedObject = currentProperties.combinedObject.concat(shape);
                }
            }
            //objeto nuevo con propiedades actualizadas
            return {
                ...prevProperties,
                [currentStage]: {
                    ...currentProperties,
                    [property]: updatedShapes,
                    combinedObject: updatedCombinedObject,
                },
            };
        });
    };

    const handleDragMove = (e, index) => {
        if (selectedShape !== null) {
            const { width, height } = stageProperties[currentStage];
            const shape = e.target;

            const stage = currentStage === 1 ? stageFront.current : stageBack.current;

            if (shape instanceof Konva.Circle) {
                const shapeClientRect = shape.getClientRect();
                const radiusX = shapeClientRect.radiusX || shapeClientRect.width / 2;
                const radiusY = shapeClientRect.radiusY || shapeClientRect.height / 2;

                let centerX = shape.x() + radiusX;
                let centerY = shape.y() + radiusY;

                let x = shape.x();
                let y = shape.y();

                updateStageProperties(selectedShape, selectedShape?.property, index, x, y);
                shape.position({ x, y });
                stage.batchDraw();
            } else {
                const shapeClientRect = shape.getClientRect();
                const radiusX = shapeClientRect.radiusX || shapeClientRect.width / 2;
                const radiusY = shapeClientRect.radiusY || shapeClientRect.height / 2;

                let centerX = shape.x() + radiusX;
                let centerY = shape.y() + radiusY;

                let x = shape.x();
                let y = shape.y();

                x = Math.round(x);
                y = Math.round(y);
                // Limitar la posición de la forma para que no salga
                x = Math.max(0, Math.min(x, width - shapeClientRect.width));
                y = Math.max(0, Math.min(y, height - shapeClientRect.height));

                x = Math.round(x);
                y = Math.round(y);

                updateStageProperties(selectedShape, selectedShape?.property, index, x, y);

                if (x < 0) {
                    x = 0;
                }

                if (y < 0) {
                    y = 0;
                }

                if (x + shapeClientRect.width > width) {
                    x = width - shapeClientRect.width;
                }

                if (y + shapeClientRect.height > height) {
                    y = height - shapeClientRect.height;
                }

                shape.position({ x, y });
                stage.batchDraw();
            }
        }
    };

    const { orientation, width: stageWidth, height: stageHeight } = stageProperties[currentStage];

    const handleShapeClick = (shape) => {
        setSelectedShape(shape);
        updateForm(shape);
    };
    //actualiza el formaulario con los detalles de la forma seleccionada
    const updateForm = (shape) => {
        if (shape != undefined) {
            if (shape.height === undefined || shape.width === undefined) {
                setShapeHeight('');
                setShapeWidth('');
            } else {
                setShapeHeight((prevHeight) => (shape.height !== undefined ? shape.height : prevHeight));
                setShapeWidth((prevWidth) => (shape.width !== undefined ? shape.width : prevWidth));
            }
            if (shape.width !== undefined && shape.height !== undefined) {
                const newRadius = Math.max(shape.width / 2, shape.height / 2);
                setShapeRadius(newRadius);
            }
            if (shape.strokeWidth === undefined) {
                setShapeStrokeWidth('');
            } else {
                setShapeStrokeWidth((prevRadius) => prevRadius || shape.strokeWidth);
            }
            setFontSize(shape.fontSize);
            setShapeRadius(shape.radius);
            setShapeCornerRadius(shape.cornerRadius);
            setShapeHorizontal(shape.x);
            setShapeVertical(shape.y);
            setActiveFontFamily(shape.fontFamily);
            setShapeAlign(shape.align);
            setShapeFontStyle(shape.fontStyle);
        }
    };
    //cambio de color de relleno
    const handleChangeFill = (color, property) => {
        if (selectedShape !== null) {
            setStageProperties((prevProperties) => {
                const updatedProperties = { ...prevProperties };
                const currentCombinedObject = updatedProperties[currentStage].combinedObject;
                const shapeIndex = currentCombinedObject.findIndex((shape) => shape.id === selectedShape?.id);

                if (shapeIndex !== -1) {
                    const updatedShape = { ...currentCombinedObject[shapeIndex] };
                    updatedShape[property] = color.hex;

                    const updatedCombinedObject = [...currentCombinedObject];
                    updatedCombinedObject[shapeIndex] = updatedShape;

                    updatedProperties[currentStage].combinedObject = updatedCombinedObject;
                }

                return updatedProperties;
            });
        }
    };
    //mapea cada tipo de propiedad con su respectiva funcion de actualizacion
    const handleChangeProperty = (type, value) => {
        const propertyUpdater = {
            height: setShapeHeight,
            width: setShapeWidth,
            x: setShapeHorizontal,
            y: setShapeVertical,
            radius: setShapeRadius,
            cornerRadius: setShapeCornerRadius,
            strokeWidth: setShapeStrokeWidth,
            fontFamily: setActiveFontFamily,
            fontSize: setFontSize,
            align: setShapeAlign,
            textDecoration: setShapeTextDecoration,
            fontStyle: setShapeFontStyle,
        };

        const maxValues = {
            width: 305,
            height: 462,
            x: 279,
            y: 436,
        };
        if (type in maxValues && value > maxValues[type]) {
            value = maxValues[type];
        }
        if (type === 'x' || type === 'y') {
            value = Math.round(value);
        }
        if (type === 'x' && value < 0) {
            value = Math.round(0);
        }
        if (type === 'x' && value > 279) {
            value = Math.round(279);
        }
        if (type === 'y' && value < 0) {
            value = Math.round(0);
        }

        if (type === 'y' && value > 436) {
            value = Math.round(436);
        }
        const updater = propertyUpdater[type];
        if (updater) {
            updater(value);
        }

        //actualiza las propiedades
        setStageProperties((prevProperties) => {
            const currentProperties = prevProperties[currentStage];

            const updatedCombinedObject = currentProperties.combinedObject.map((shape, index) => {
                if (shape.id === selectedShape.id) {
                    let updatedShape = {
                        ...shape,
                        [type]: value,
                    };
                    if (type === 'fontStyle' || type === 'textDecoration') {
                        updatedShape = { ...shape };

                        updatedShape = {
                            ...shape,
                            [type]: updateTextProperty(shape[type], value),
                        };
                    }
                    setSelectedShape(updatedShape);
                    return updatedShape;
                } else {
                    return shape;
                }
            });

            return {
                ...prevProperties,
                [currentStage]: {
                    ...currentProperties,
                    combinedObject: updatedCombinedObject,
                },
            };
        });
    };

    const updateTextProperty = (currentFontStyle, newValue) => {
        if (currentFontStyle.includes(newValue)) {
            return currentFontStyle.replace(newValue, '').trim();
        } else {
            return [currentFontStyle, newValue].join(' ');
        }
    };

    const handleGetResources = () => {
        const option = `${TABLES.RESOURCES}`;
        const body = {
            pagination: {
                page: 0,
                size: 100,
            },
            searchCriteriaList: [],
        };
        dispatch(SearchByFilters({ option, body }));
        setShowResources(true);
    };
    //creacion del template
    const handleCreate = () => {
        if (templateName === '') {
            setEmptyTemplateName(true);
        } else {
            const updatedState = stageProperties;

            for (const key in updatedState) {
                if (updatedState[key].hasOwnProperty('combinedObject')) {
                    updatedState[key].combinedObject.forEach((combinedItem) => {
                        if (combinedItem.property === 'resources') {
                            delete combinedItem.base64;
                        }
                    });
                }

                if (updatedState[key].hasOwnProperty('resources')) {
                    updatedState[key].resources.forEach((resource) => {
                        if (resource.property === 'resources') {
                            delete resource.base64;
                        }
                    });
                }
            }

            const body = {
                name: templateName,
                whoCreatedId: userData?.id,
                raw: JSON.stringify(updatedState),
            };
            dispatch(CreateTemplateCard(body)).then(
                ({
                    payload: {
                        data: { data },
                    },
                }) => {
                    if (data !== null) {
                        dispatch(clearSearchFilters());
                        toast.success(t('has_been_created_successfully').toUpperCase());
                        navigate('/dashboard/employee/card-designer');
                    }
                },
            );
        }
    };

    const handleEdit = () => {
        if (templateName === '') {
            setEmptyTemplateName(true);
        } else {
            const updatedState = stageProperties;

            for (const key in updatedState) {
                if (updatedState[key].hasOwnProperty('combinedObject')) {
                    updatedState[key].combinedObject.forEach((combinedItem) => {
                        if (combinedItem.property === 'resources') {
                            delete combinedItem.base64;
                        }
                    });
                }

                if (updatedState[key].hasOwnProperty('resources')) {
                    updatedState[key].resources.forEach((resource) => {
                        if (resource?.property === 'resources') {
                            delete resource.base64;
                        }
                    });
                }
            }

            const body = {
                name: templateName,
                whoCreatedId: userData?.id,
                raw: JSON.stringify(updatedState),
                id: editId,
            };

            dispatch(UpdateTemplateCard(body)).then(
                ({
                    payload: {
                        data: { data },
                    },
                }) => {
                    if (data !== null) {
                        navigate('/dashboard/employee/card-designer');
                        toast.success(t('has_been_updated_successfully').toUpperCase());
                    }
                },
            );
        }
    };
    function searchId(stringVar) {
        return Date.now().toString(16).slice(-3);
    }

    const handleStageClick = (e) => {
        const clickedOnShape =
            e.target.attrs.id.includes('squares') ||
            e.target.attrs.id.includes('ellipsis') ||
            e.target.attrs.id.includes('lines') ||
            e.target.attrs.id.includes('texts') ||
            e.target.attrs.id.includes('resources');

        if (!clickedOnShape) {
            setSelectedShape(null);
        }
    };

    const handleDeleteShape = (id, property) => {
        if (id) {
            setStageProperties((prevProperties) => {
                const currentProperties = prevProperties[currentStage];
                const updatedSpecificProperty = currentProperties[property].filter((figure) => figure?.id !== id);
                const updatedCombinedObject = currentProperties.combinedObject.filter((figure) => figure?.id !== id);
                return {
                    ...prevProperties,
                    [currentStage]: {
                        ...currentProperties,
                        [property]: updatedSpecificProperty,
                        combinedObject: updatedCombinedObject,
                    },
                };
            });
        }
    };

    const resourcesList = (searchByFilters?.content || [])
        .filter((item) => item?.image !== null)
        .map((item) => ({
            src: `data:image/png;base64,${item?.image}`,
            id: item.id,
        }));
    useEffect(() => {
        const isTemplateNameEmpty = templateName.trim().length === 0;
        setEmptyTemplateName(isTemplateNameEmpty);
    }, [templateName]);

    return (
        <>
            <section className='row create-template vh-100'>
                <div className='col-3 my-3 d-flex flex-column justify-content-between'>
                    <div style={{ marginRight: '5px', marginLeft: '10px' }}>
                        <div className=' m-0'>
                            <div className=' d-flex '>
                                <ReturnArrow link={'/dashboard/employee/card-designer'} />
                                <h3 className='title-section'>{t('template')}</h3>
                            </div>
                            <div className='divider-common mt-1'></div>
                        </div>
                        <div className='custom-scroll'>
                            <div className=''>
                                <h4 className='mb-3 title-property'>{t('properties')}</h4>
                                <TextField
                                    size='small'
                                    fullWidth
                                    name='templateName'
                                    inputProps={{ maxLength: 30 }}
                                    label={t('name')}
                                    id='btn_ct_templateName'
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                    className='requiredData'
                                    error={emptyTemplateName}
                                    helperText={emptyTemplateName && t('requiredField')}
                                />
                            </div>
                            {showResources && (
                                <div className='mt-3'>
                                    <h4 className='mb-3 title-property'>{t('resources')}</h4>
                                    <Grid container spacing={2}>
                                        {resourcesList.map((image, index) => (
                                            <Grid key={index} item xs={6} md={6}>
                                                <img
                                                    src={image.src}
                                                    alt=''
                                                    className='w-100'
                                                    onDragStart={(e) =>
                                                        handleDragStart(e, 'imageTemplate', image.src, image.id)
                                                    }
                                                    data-transfer-type='imageTemplate'
                                                    data-button-type='imageTemplate'
                                                    data-base={image.src}
                                                    data-resource={image.id}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            )}

                            <div className='mt-3'>
                                {selectedShape !== null && <h4 className='mb-3 title-property'>{t('position')}</h4>}
                                <div className='mt-3'>
                                    <Grid container spacing={2}>
                                        {/* Verifica si la forma seleccionada es un cuadrado, texto o línea  y establece el height*/}
                                        {(selectedShape?.property === 'squares' ||
                                            selectedShape?.property === 'texts' ||
                                            selectedShape?.property === 'lines') && (
                                            <Grid item xs={6} md={6}>
                                                <div className='d-flex align-items-center'>
                                                    <InputProperty
                                                        property='shapeHeight'
                                                        shapeProperty='height'
                                                        disabledCondition={
                                                            selectedShape?.property !== 'squares' &&
                                                            selectedShape?.property !== 'texts' &&
                                                            selectedShape?.property !== 'lines'
                                                        }
                                                        label={t('height')}
                                                        selectedShape={selectedShape}
                                                        icon={heightIcon}
                                                        value={shapeHeight}
                                                        handleChangeProperty={handleChangeProperty}
                                                    />
                                                </div>
                                            </Grid>
                                        )}

                                        {/*  establece el font size */}
                                        {selectedShape?.property === 'texts' && (
                                            <Grid item xs={6} md={6}>
                                                <div className='d-flex align-items-center'>
                                                    <InputProperty
                                                        property='fontSize'
                                                        shapeProperty='fontSize'
                                                        disabledCondition={
                                                            selectedShape?.property !== 'squares' &&
                                                            selectedShape?.property !== 'texts' &&
                                                            selectedShape?.property !== 'lines'
                                                        }
                                                        label={t('font_size')}
                                                        selectedShape={selectedShape}
                                                        icon={widthIcon}
                                                        value={fontSize}
                                                        handleChangeProperty={handleChangeProperty}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                        {/*   establece el width */}
                                        {(selectedShape?.property === 'squares' ||
                                            selectedShape?.property === 'texts' ||
                                            selectedShape?.property === 'lines') && (
                                            <Grid item xs={6} md={6}>
                                                <div className='d-flex align-items-center'>
                                                    <InputProperty
                                                        property='shapeWidth'
                                                        shapeProperty='width'
                                                        disabledCondition={
                                                            selectedShape?.property !== 'squares' &&
                                                            selectedShape?.property !== 'texts' &&
                                                            selectedShape?.property !== 'lines'
                                                        }
                                                        label={t('width')}
                                                        selectedShape={selectedShape}
                                                        icon={widthIcon}
                                                        value={shapeWidth}
                                                        handleChangeProperty={handleChangeProperty}
                                                        max={305}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                        {/*   //establece la posicion en x horizontalmmente */}
                                        {selectedShape !== null && (
                                            <Grid item xs={6} md={6}>
                                                <div className='d-flex align-items-center'>
                                                    <InputProperty
                                                        property='shapeHorizontal'
                                                        shapeProperty='x'
                                                        id='txt_ct_x'
                                                        disabledCondition={selectedShape === null}
                                                        label={t('X')}
                                                        selectedShape={selectedShape}
                                                        icon={xIcon}
                                                        value={shapeHorizontal}
                                                        handleChangeProperty={handleChangeProperty}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                        {/*   //establece la posicion en x vertical */}
                                        {selectedShape !== null && (
                                            <Grid item xs={6} md={6}>
                                                <div className='d-flex align-items-center'>
                                                    <InputProperty
                                                        property='shapeVertical'
                                                        shapeProperty='y'
                                                        id='txt_ct_y'
                                                        disabledCondition={selectedShape === null}
                                                        label={t('Y')}
                                                        selectedShape={selectedShape}
                                                        icon={yIcon}
                                                        value={shapeVertical}
                                                        handleChangeProperty={handleChangeProperty}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                        {/*     borde de esquinas */}
                                        {(selectedShape?.property === 'squares' ||
                                            selectedShape?.property === 'lines') && (
                                            <Grid item xs={6} md={6}>
                                                <div className='d-flex align-items-center'>
                                                    <InputProperty
                                                        property='shapeCornerRadius'
                                                        shapeProperty='cornerRadius'
                                                        id='txt_ct_cornerRadius'
                                                        disabledCondition={
                                                            selectedShape?.property !== 'squares' &&
                                                            selectedShape?.property !== 'lines'
                                                        }
                                                        label={t('corner_radius')}
                                                        selectedShape={selectedShape}
                                                        icon={yIcon}
                                                        value={shapeCornerRadius}
                                                        handleChangeProperty={handleChangeProperty}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                        {/*  radio de elipse */}
                                        {selectedShape?.property == 'ellipsis' && (
                                            <Grid item xs={6} md={6}>
                                                <div className='d-flex align-items-center'>
                                                    <InputProperty
                                                        property='shapeRadius'
                                                        shapeProperty='radius'
                                                        id='txt_ct_radius'
                                                        disabledCondition={selectedShape?.property !== 'ellipsis'}
                                                        label={t('radius')}
                                                        selectedShape={selectedShape}
                                                        icon={yIcon}
                                                        value={shapeRadius}
                                                        handleChangeProperty={handleChangeProperty}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                        {/*                                         stroke width */}
                                        {(selectedShape?.property == 'squares' ||
                                            selectedShape?.property == 'ellipsis' ||
                                            selectedShape?.property == 'lines') && (
                                            <Grid item xs={6} md={6}>
                                                <div className='d-flex align-items-center'>
                                                    <InputProperty
                                                        property='shapeStrokeWidth'
                                                        shapeProperty='strokeWidth'
                                                        id='txt_ct_strokeWidth'
                                                        disabledCondition={
                                                            selectedShape?.property !== 'squares' &&
                                                            selectedShape?.property !== 'ellipsis' &&
                                                            selectedShape?.property !== 'lines'
                                                        }
                                                        label={t('stroke_width')}
                                                        selectedShape={selectedShape}
                                                        icon={heightIcon}
                                                        value={shapeStrokeWidth}
                                                        handleChangeProperty={handleChangeProperty}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            </div>
                            {/* alineacion del texto */}
                            {selectedShape?.property === 'texts' && (
                                <div className='mt-3'>
                                    {/* <h4 className='mb-3 title-property'>{t('texts')}</h4> */}
                                    {/* <FontPicker
                                        apiKey='AIzaSyC1i-MFMEpdILfGdAE-ImRbTtHKWHpxXWw'
                                        activeFontFamily={activeFontFamily}
                                        onChange={(nextFont) => handleChangeProperty('fontFamily', nextFont.family)}
                                    /> */}

                                    <h4 className='mb-3 title-property'>{t('alignment')}</h4>
                                    <div className='d-flex'>
                                        <ButtonGroup variant='text' aria-label='text button group'>
                                            <Button
                                                id='btn_ct_aling'
                                                className={`shadow-none ${
                                                    selectedShape?.align.includes('left') ? 'active-button' : ''
                                                }`}
                                                onClick={() => handleChangeProperty('align', 'left')}
                                            >
                                                <FormatAlignLeftIcon
                                                    style={{
                                                        color: '#707070',
                                                        transition: 'color 0.3s',
                                                    }}
                                                    className='hover-white-icon'
                                                />
                                            </Button>
                                            <Button
                                                className={`shadow-none ${
                                                    selectedShape?.align.includes('center') ? 'active-button' : ''
                                                }`}
                                                onClick={() => handleChangeProperty('align', 'center')}
                                            >
                                                <FormatAlignCenterIcon
                                                    style={{
                                                        color: '#707070',
                                                        transition: 'color 0.3s',
                                                    }}
                                                    className='hover-white-icon'
                                                />
                                            </Button>
                                            <Button
                                                className={`shadow-none ${
                                                    selectedShape?.align.includes('right') ? 'active-button' : ''
                                                }`}
                                                onClick={() => handleChangeProperty('align', 'right')}
                                            >
                                                <FormatAlignRightIcon
                                                    style={{
                                                        color: '#707070',
                                                        transition: 'color 0.3s',
                                                    }}
                                                    className='hover-white-icon'
                                                />
                                            </Button>
                                        </ButtonGroup>

                                        <ButtonGroup variant='text' aria-label='text button group'>
                                            <Button
                                                className={`shadow-none ${
                                                    selectedShape?.fontStyle.includes('bold') ? 'active-button' : ''
                                                }`}
                                                onClick={() => handleChangeProperty('fontStyle', 'bold')}
                                            >
                                                <FormatBoldIcon
                                                    style={{
                                                        color: '#707070',
                                                        transition: 'color 0.3s',
                                                    }}
                                                    className='hover-white-icon'
                                                />
                                            </Button>
                                            <Button
                                                className={`shadow-none ${
                                                    selectedShape?.fontStyle.includes('italic') ? 'active-button' : ''
                                                }`}
                                                onClick={() => handleChangeProperty('fontStyle', 'italic')}
                                            >
                                                <FormatItalicIcon
                                                    style={{
                                                        color: '#707070',
                                                        transition: 'color 0.3s',
                                                    }}
                                                    className='hover-white-icon'
                                                />
                                            </Button>
                                        </ButtonGroup>
                                    </div>

                                    <div className='mt-3'>
                                        <ButtonGroup variant='text' aria-label='text button group'>
                                            <Button
                                                className={`shadow-none ${
                                                    selectedShape?.textDecoration.includes('underline')
                                                        ? 'active-button'
                                                        : ''
                                                }`}
                                                onClick={() => handleChangeProperty('textDecoration', 'underline')}
                                            >
                                                <FormatUnderlinedIcon
                                                    style={{
                                                        color: '#707070',
                                                        transition: 'color 0.3s',
                                                    }}
                                                    className='hover-white-icon'
                                                />
                                            </Button>
                                            <Button
                                                className={`shadow-none ${
                                                    selectedShape?.textDecoration.includes('line-through')
                                                        ? 'active-button'
                                                        : ''
                                                }`}
                                                onClick={() => handleChangeProperty('textDecoration', 'line-through')}
                                            >
                                                <FormatStrikethroughIcon
                                                    style={{
                                                        color: '#707070',
                                                        transition: 'color 0.3s',
                                                    }}
                                                    className='hover-white-icon'
                                                />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            )}
                            {/* <div className='mt-3'></div> */}

                            <div className='mt-3'>
                                {selectedShape &&
                                selectedShape?.property !== 'texts' &&
                                selectedShape?.property !== 'resources' ? (
                                    <div>
                                        <h4 className='mb-3 title-property'>{t('colors')}</h4>

                                        <p>{t('fill_color')}</p>
                                        <ColorPicker
                                            getColor={(color) => handleChangeFill(color, 'fill')}
                                            initialColor={selectedShape?.fill}
                                            selectedShape={selectedShape}
                                            id='clp_ct_fillColor'
                                        />
                                        <p>x{t('border_color')}</p>
                                        <ColorPicker
                                            getColor={(color) => handleChangeFill(color, 'stroke')}
                                            initialColor={selectedShape?.stroke}
                                            selectedShape={selectedShape}
                                            id='clp_ct_borderColor'
                                        />
                                    </div>
                                ) : selectedShape?.property === 'texts' ? (
                                    <div>
                                        <p className='mb-3 title-property'>{t('text_color')}</p>
                                        <ColorPicker
                                            getColor={(color) => handleChangeFill(color, 'fill')}
                                            initialColor={selectedShape?.fill}
                                            selectedShape={selectedShape}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {isEdit ? (
                            <span>
                                {permission?.includes(permissionObj?.WEB_CARD_DESIGNER_UPDATE) && (
                                    <button
                                        className='create custom_primary_btn_dark w-100 text-uppercase'
                                        onClick={handleEdit}
                                        id='btn_ct_update'
                                    >
                                        {t('update')}
                                    </button>
                                )}
                            </span>
                        ) : (
                            <button
                                className='create custom_primary_btn_dark w-100 text-uppercase'
                                onClick={() => {
                                    if (templateName.trim() !== '') {
                                        handleCreate();
                                    } else {
                                        toast.warn(t('please_enter_a_name').toUpperCase());
                                    }
                                }}
                                id='btn_ct_create'
                            >
                                {t('create')}
                            </button>
                        )}
                    </div>
                </div>
                <div className='col-5 my-3 content-canvas '>
                    <div className='mb-4 text-center'>
                        <button className='btn' onClick={toggleOrientation}>
                            <img className='mx-0 px-0' src={orientationIcon} alt='' style={{ width: '17px' }} />
                        </button>

                        <button className='btn' onClick={handleButtonClick}>
                            <img
                                className=' mx-0 px-0'
                                src={currentStage === 1 ? frontCardIcon : backCardIcon}
                                alt=''
                            />
                        </button>
                    </div>

                    <p className='text-center'>{currentStage === 1 ? t('front_card') : t('back_card')}</p>
                    <div
                        className='d-flex justify-content-center'
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        {/*  //canvas, aqui se contienen los elementos  */}
                        <Stage
                            ref={currentStage === 1 ? stageFront : stageBack}
                            width={stageWidth}
                            height={stageHeight}
                            onClick={handleStageClick}
                        >
                            <Layer>
                                {/* Background color */}
                                <Rect
                                    id='background'
                                    width={stageWidth}
                                    height={stageHeight}
                                    fill={cardBackgroundColor}
                                />
                                {stageProperties[currentStage].orientation === 'vertical' ? (
                                    <>
                                        <Line
                                            key='0980'
                                            points={[152, 0, 152, 500]}
                                            stroke='#000'
                                            strokeWidth={1}
                                            opacity={0.05}
                                        />
                                        <Line
                                            key='0981'
                                            points={[0, 230, 505, 230]}
                                            stroke='#000'
                                            strokeWidth={1}
                                            opacity={0.05}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Line
                                            key='0980'
                                            points={[240, 0, 240, 805]}
                                            stroke='#000'
                                            strokeWidth={1}
                                            opacity={0.05}
                                        />
                                        <Line
                                            key='0981'
                                            points={[0, 145, 825, 145]}
                                            stroke='#000'
                                            strokeWidth={1}
                                            opacity={0.05}
                                        />
                                    </>
                                )}

                                {stageProperties[currentStage].combinedObject.map((shape, index) => {
                                    if (shape.property === 'squares' || shape.property === 'lines') {
                                        return (
                                            <Square
                                                key={shape.id}
                                                square={shape}
                                                index={index}
                                                currentStage={currentStage}
                                                properties={stageProperties}
                                                selectedShapeId={selectedShape?.id}
                                                handleDragMove={handleDragMove}
                                                handleShapeClick={() => handleShapeClick(shape, index)}
                                                setStageProperties={setStageProperties}
                                                setShapeHeight={setShapeHeight}
                                                setShapeWidth={setShapeWidth}
                                            />
                                        );
                                    } else if (shape.property === 'ellipsis') {
                                        return (
                                            <Ellipsis
                                                key={shape.id}
                                                ellipsis={shape}
                                                index={index}
                                                currentStage={currentStage}
                                                properties={stageProperties}
                                                selectedShapeId={selectedShape?.id}
                                                handleDragMove={handleDragMove}
                                                handleShapeClick={() => handleShapeClick(shape, index)}
                                                setStageProperties={setStageProperties}
                                                setShapeRadius={setShapeRadius}
                                                setShapeHeight={setShapeHeight}
                                                setShapeWidth={setShapeWidth}
                                            />
                                        );
                                    } else if (shape.property === 'texts') {
                                        return (
                                            <TextComponent
                                                key={shape.id}
                                                text={shape}
                                                index={index}
                                                selectedShapeId={selectedShape?.id}
                                                properties={stageProperties}
                                                handleDragMove={handleDragMove}
                                                handleShapeClick={() => handleShapeClick(shape, index)}
                                                currentStage={currentStage}
                                                setStageProperties={setStageProperties}
                                                setShapeHeight={setShapeHeight}
                                                setShapeWidth={setShapeWidth}
                                                setFontSize={setFontSize}
                                            />
                                        );
                                    } else if (shape.property === 'resources') {
                                        return (
                                            <ImageComponent
                                                key={shape.id}
                                                image={shape}
                                                index={index}
                                                currentStage={currentStage}
                                                properties={stageProperties}
                                                selectedShapeId={selectedShape?.id}
                                                handleDragMove={handleDragMove}
                                                handleShapeClick={() => handleShapeClick(shape, index)}
                                                setStageProperties={setStageProperties}
                                                setShapeHeight={setShapeHeight}
                                                setShapeWidth={setShapeWidth}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </Layer>
                        </Stage>
                    </div>
                </div>

                <div className='col-4 my-3 d-flex'>
                    <div className='mr-3'>
                        <div className='d-flex flex-column'>
                            <button
                                className='button-option btn'
                                draggable
                                onDragStart={(e) => handleDragStart(e, 'textTemplate')}
                                data-transfer-type='textTemplate'
                                onClick={() => setShowResources(false)}
                            >
                                <img className='' src={tIcon} alt='textTemplate' />
                            </button>
                            <button
                                className='button-option btn mt-1'
                                draggable
                                onDragStart={(e) => handleDragStart(e, 'lineTemplate')}
                                data-transfer-type='lineTemplate'
                                onClick={() => setShowResources(false)}
                            >
                                <img className='' src={lineIcon} alt='lineTemplate' />
                            </button>
                            <button
                                className='button-option btn mt-1'
                                draggable
                                onDragStart={(e) => handleDragStart(e, 'rectangleTemplate')}
                                data-transfer-type='rectangleTemplate'
                                onClick={() => setShowResources(false)}
                            >
                                <img className='' src={squareIcon} alt='rectangleTemplate' />
                            </button>
                            <button
                                className='button-option btn mt-1'
                                draggable
                                onDragStart={(e) => handleDragStart(e, 'ellipseTemplate')}
                                data-transfer-type='ellipseTemplate'
                                data-button-type='ellipseTemplate'
                                onClick={() => setShowResources(false)}
                            >
                                <img className='' src={circleIcon} alt='ellipseTemplate' />
                            </button>
                            <button
                                className='button-option btn mt-1'
                                onClick={handleGetResources}
                                onDragStart={(e) => setShowResources(true)}
                            >
                                <img className='' src={imageIcon} alt='imageTemplate' />
                            </button>
                        </div>
                    </div>
                    <div className='w-100 d-flex flex-column justify-content-between'>
                        <div style={{ marginRight: '25px' }}>
                            <h3 className='title-section'>{t('layers')}</h3>
                            <div className='divider-common mt-1'></div>
                            <div className='mt-3 '>
                                {currentStage === 1 ? (
                                    <StageAccordion
                                        stageIndex={1}
                                        stageProperties={stageProperties}
                                        setStageProperties={setStageProperties}
                                        expanded={expanded}
                                        handleChange={handleChange}
                                        currentStage={currentStage}
                                        handleDeleteShape={handleDeleteShape}
                                        setSelectedShape={setSelectedShape}
                                        selectedShape={selectedShape}
                                        setShowResources={setShowResources}
                                        updateForm={updateForm}
                                        style={{ overflowY: 'auto', maxHeight: '300px' }}
                                    />
                                ) : (
                                    <StageAccordion
                                        stageIndex={2}
                                        stageProperties={stageProperties}
                                        setStageProperties={setStageProperties}
                                        expanded={expanded}
                                        handleChange={handleChange}
                                        currentStage={currentStage}
                                        handleDeleteShape={handleDeleteShape}
                                        setSelectedShape={setSelectedShape}
                                        selectedShape={selectedShape}
                                        setShowResources={setShowResources}
                                        updateForm={updateForm}
                                        style={{ overflowY: 'auto', maxHeight: '300px' }}
                                    />
                                )}
                            </div>
                        </div>
                        <div style={{ margin: '10px' }}>
                            <h3 className='title-section'>{t('attributes')}</h3>
                            <div className='divider-common mt-1'></div>
                            <div className='template-attributes-list mt-3'>
                                <div
                                    className='d-flex align-items-center mt-1'
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, 'imageTemplate', null, null, 'userSelfie')}
                                >
                                    <img className='mx-2' src={imageIcon} alt='' />
                                    <h4>{t('user_photography')}</h4>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-1'
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, 'textTemplate', null, null, 'name')}
                                >
                                    <img className='mx-2' src={tIcon} alt='' />
                                    <h4>{t('name')}</h4>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-1'
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, 'textTemplate', null, null, 'lastName')}
                                >
                                    <img className='mx-2' src={tIcon} alt='' />
                                    <h4>{t('last_name')}</h4>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-1'
                                    draggable
                                    onDragStart={(e) =>
                                        handleDragStart(e, 'textTemplate', null, null, 'secondLastName')
                                    }
                                >
                                    <img className='mx-2' src={tIcon} alt='' />
                                    <h4>{t('second_last_name')}</h4>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-1'
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, 'textTemplate', null, null, 'employeeId')}
                                >
                                    <img className='mx-2' src={tIcon} alt='' />
                                    <h4>{t('employee_id')}</h4>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-1'
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, 'textTemplate', null, null, 'privilege')}
                                >
                                    <img className='mx-2' src={tIcon} alt='' />
                                    <h4>{t('privilege')}</h4>
                                </div>
                                <div
                                    className='d-flex align-items-center mt-1'
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, 'textTemplate', null, null, 'department')}
                                >
                                    <img className='mx-2' src={tIcon} alt='' />
                                    <h4>{t('department')}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
