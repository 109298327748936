import React from 'react';
import { useTranslation } from 'react-i18next';
import ic_search from '../../assets/icon/ic-search.svg';

const SearchFilterBtn = ({ fnclick }) => {
    const { t } = useTranslation();
    return (
        <button className='search-btn-1' onClick={fnclick}>
            <img className='mx-0' src={ic_search} alt='search' width={'20px'} height={'20px'} />
            {t('search')}
        </button>
    );
};

export default SearchFilterBtn;
