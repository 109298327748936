/*
Author : Arman Ali
Module: create Vehicle
github: https://github.com/Arman-Arzoo
*/

import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { permissionObj } from '../../../../Helpers/permission';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextWithLine from '../../../../components/CustomTextWithLine';
import VehicleStatus from '../../../../components/VehicleStatus';
import defaultUser from '../../../../assets/defaultImages/defaultUser.png';
import TablePagination from '@mui/material/TablePagination';
import DriverModal from './modal/DriverModal';
import { useState } from 'react';
import {
    GetAllDriverRelationship,
    GetListOfVehicleImages,
    GetVehicleById,
    PremmissionType,
} from '../../../../reduxToolkit/Vehicle/VehicleApi';
import ic_edit from '../../../../assets/icon/ic-edit-pencil.svg';
import { GetAllEmployee } from '../../../../reduxToolkit/EmployeeOnBoarding/EmployeeOnBoardingApi';
import ReturnArrow from '../../../../components/buttons/ReturnArrow';
import { Vehicle } from '../../../../models/Vehicle';
import useStyle from '../../../../hooks/useStyle';
import { blockInvalidNumericChar } from '../../../../utils/blockInvalidNumericChar';
import { permissionType } from '../../../../enums/permissionTypeEnum';
import moment from 'moment';
import NotInformation from '../../../../components/NotInformation';

const EmployeeVehicleDetail = () => {
    // use hook
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // useState
    const [showModal, setShowModal] = useState(false);
    const [isDriverAdd, setIsDriverAdd] = useState('');
    const [updateDriverId, setUpdateDriverId] = useState('');
    const [driverNameUpdate, setDriverNameUpdate] = useState('');
    const [pageImage, setPageImage] = useState(0);
    const [rowsPerPageImage, setRowsPerPageImage] = useState(6);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [vehicle, setVehicle] = useState(Vehicle);
    const [emptyFields, setEmptyFields] = useState(false);
    const [isValidModel, setIsvalidModel] = useState(false);
    const [updateEmployee, setUpdtaEmployee] = useState(sessionStorage.getItem('updateEmployee'));
    const [previousPath, setPreviousPath] = useState();

    // const [updated, setUpdated] = useState(0)

    //  state from store reducer
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const {
        getAllDriverRelationship,
        getListOfVehicleImages,
        getVehicleById,
        addDriver,
        removeDriverEmployee,
        updateDriver,
        updateVehicleEmployee,
        updateVehicleV1,
        deleteVehicleImage,
    } = useSelector((state) => state.VehicleSlice);
    const { uploadImage } = useSelector((state) => state.sharedSlice);

    // functions
    // a funtion to control zone page
    const handleChangePageImage = (event, newPage) => {
        setPageImage(newPage);
    };
    // a funtion to control row per page
    const handleChangeRowsPerPageImage = (event) => {
        setRowsPerPageImage(parseInt(event.target.value));
        setPageImage(0);
    };

    useEffect(() => {
        setPreviousPath(sessionStorage.getItem('previousPath'));
    }, []);

    useEffect(() => {
        dispatch(GetAllEmployee());
        dispatch(PremmissionType());
        dispatch(GetVehicleById(params?.id));
    }, [updateVehicleV1]);
    useEffect(() => {
        dispatch(GetAllDriverRelationship(params?.id));
    }, [addDriver, removeDriverEmployee, updateDriver]);
    useEffect(() => {
        const data = {
            pagination: {
                order: sortBy === 'asc' ? true : false,
                page: pageImage,
                size: rowsPerPageImage,
                sortBy: orderBy ? orderBy : 'id',
            },
            vehicleId: params?.id,
        };
        dispatch(GetListOfVehicleImages(data));
    }, [sortBy, pageImage, rowsPerPageImage, orderBy, deleteVehicleImage, uploadImage]);
    return (
        <>
            {/* top header */}
            <div className='vehicle_detail_container'>
                <div className='head'>
                    <div className='headLeft'>
                        <ReturnArrow link={null} />
                        <h2>{t('vehicle_details')}</h2>
                    </div>
                </div>
                {/* vehicle detail */}
                <div className='d-flex justify-content-center'>
                    <div className='vehicle_detail_items'>
                        {permission?.includes(permissionObj?.WEB_VEHICLE_UPDATE) && (
                            <div className='container-top-right-btns' style={{ marginTop: '-20px' }}>
                                <button
                                    className='add-btn-1'
                                    onClick={() =>
                                        navigate(`/dashboard/employee/vehicle-fleet/update-vehicle/${params?.id}`)
                                    }
                                >
                                    <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                                    {t('update')}
                                </button>
                            </div>
                        )}
                        {/* @Data */}
                        <CustomTextWithLine title={t('data')} />
                        <div className='data_items'>
                            <VehicleStatus
                                statusName={getVehicleById?.status?.name?.replaceAll('_', ' ') || '-'}
                                top={'5px'}
                                right={'25px'}
                                data={getVehicleById}
                            />
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='vehical_data'>
                                        <h4>{t('brand')}</h4>
                                        <p>{getVehicleById?.brand || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='vehical_data'>
                                        <h4>{t('sub_brand')}</h4>
                                        <p>{getVehicleById?.subBrand || '-'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='vehical_data'>
                                        <h4>{t('color')}</h4>
                                        <p>{getVehicleById?.color || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='vehical_data'>
                                        <h4>{t('model')}</h4>
                                        <p>{getVehicleById?.model || '-'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='vehical_data'>
                                        <h4>{t('plates')}</h4>
                                        <p>{getVehicleById?.plate || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='vehical_data'>
                                        <h4>{t('vin')}</h4>
                                        <p>{getVehicleById?.vin || '-'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='vehical_data'>
                                        <h4>{t('serial_number')}</h4>
                                        <p>{getVehicleById?.serialNumber || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-6 d-none'>
                                    <div className='vehical_data'>
                                        <h4>{t('tag')}</h4>
                                        <p>{getVehicleById?.tag || '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* @driver */}
                        <CustomTextWithLine title={t('drivers')} />
                        <div className='driver_items_container'>
                            <div className='driver_item_card'>
                                {getAllDriverRelationship?.map((item) => {
                                    const fromDate = item?.from ? new Date(item?.from) : null;
                                    const toDate = item?.to ? new Date(item?.to) : null;
                                    return (
                                        <div className='driver_item' key={item?.id}>
                                            <div
                                                className='edit'
                                                onClick={() => {
                                                    setShowModal(true);
                                                    setIsDriverAdd('update');
                                                    setUpdateDriverId(item?.id);
                                                    setDriverNameUpdate(item);
                                                }}
                                            >
                                                <i className='fa fa-pencil ' aria-hidden='true'></i>
                                            </div>
                                            <div className='driver_avatar'>
                                                <img
                                                    src={
                                                        item?.selfie
                                                            ? `data:image/png;base64,${item?.selfie}`
                                                            : defaultUser
                                                    }
                                                    alt=''
                                                />
                                                <Link
                                                    to={`/dashboard/employee/all-employees/update-employee/${item?.userId}`}
                                                >
                                                    {t('see_details')}
                                                </Link>
                                            </div>

                                            <div className='driver_info'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='driver_data'>
                                                            <h4>{t('name')}</h4>
                                                            <p>{item?.name || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='driver_data'>
                                                            <h4>{t('employee_id')}</h4>
                                                            <p>{item?.employeeId || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='driver_data'>
                                                            <h4>{t('email')}</h4>
                                                            <p>{item?.email || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='driver_data'>
                                                            <h4>{t('phone_number')}</h4>
                                                            <p>{item?.phoneNumber || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <div className='driver_data'>
                                                                <h4>{t('permission_type')}</h4>
                                                                <p>
                                                                    {t(permissionType[item?.vehiclePermissionTypeId]) ||
                                                                        '-'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <div className='driver_data'>
                                                                <h4>{t('from')}</h4>
                                                                <p>
                                                                    {fromDate === null
                                                                        ? '-'
                                                                        : lCode === 'en'
                                                                          ? moment(fromDate).format('MM/DD/YYYY')
                                                                          : moment(fromDate).format('DD/MM/YYYY')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='driver_data'>
                                                                <h4>{t('to')}</h4>
                                                                <p>
                                                                    {toDate === null || toDate === undefined
                                                                        ? '-'
                                                                        : lCode === 'en'
                                                                          ? moment(toDate).format('MM/DD/YYYY')
                                                                          : moment(toDate).format('DD/MM/YYYY')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className='add_driver_container'>
                                <div
                                    className='add_driver_item'
                                    onClick={() => {
                                        setShowModal(true);
                                        setIsDriverAdd('add');
                                    }}
                                >
                                    <p>{t('add_driver')}</p>
                                    <span>
                                        <i className='fa fa-plus' aria-hidden='true'></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* @images */}
                        <CustomTextWithLine title={t('images')} />
                        <div className='vehicle_images_container'>
                            <div className='vehicle_images_item'>
                                {getListOfVehicleImages?.content?.length > 0 ? (
                                    getListOfVehicleImages?.content?.map((item) => {
                                        return (
                                            <div className='vehicle_image' key={item?.id}>
                                                <img
                                                    src={item?.image ? `data:image/png;base64,${item?.image}` : ''}
                                                    alt=''
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <>
                                        <NotInformation text={t('no_information')} card={true} />
                                    </>
                                )}
                            </div>

                            <div className='d-flex justify-content-center pt-3'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[4, 8]}
                                    count={getListOfVehicleImages?.totalElements}
                                    page={pageImage}
                                    onPageChange={handleChangePageImage}
                                    labelRowsPerPage={t('images_per_page')}
                                    rowsPerPage={rowsPerPageImage}
                                    onRowsPerPageChange={handleChangeRowsPerPageImage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DriverModal
                title={isDriverAdd == 'add' ? t('add_driver') : t('driver_detail')}
                checkmodal={isDriverAdd}
                // id={isDriverAdd == "add" ? params?.id : updateDriverId}
                // onUpdated={() => setUpdated(updated + 1)}
                id={params?.id}
                updateDriver={updateDriver}
                show={showModal}
                data={driverNameUpdate}
                onHide={() => setShowModal(false)}
            />
        </>
    );
};

export default EmployeeVehicleDetail;
