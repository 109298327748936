import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import tIcon from '../../../assets/icon/ic-t.svg';
import { useTranslation } from 'react-i18next';
import lineIcon from '../../../assets/icon/grip-lines.svg';
import squareIcon from '../../../assets/icon/square-line-icon.svg';
import circleIcon from '../../../assets/icon/circle-line-icon.svg';
import imageIcon from '../../../assets/icon/image-icon.svg';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function procesName(cadena) {
    return cadena.replace(/ \d+/g, '');
}

const StageAccordion = ({
    stageIndex,
    stageProperties,
    expanded,
    handleChange,
    setStageProperties,
    currentStage,
    handleDeleteShape,
    setSelectedShape,
    selectedShape,
    setShowResources,
    updateForm,
}) => {
    const getListStyle = (isDraggingOver) => ({
        width: 250,
    });
    const { t, i18n } = useTranslation();
    const getItemStyle = (draggableStyle, isDragging) => ({
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        padding: '8px',
        background: isDragging ? '#E0E0E0' : '#FFFFFF',
        ...draggableStyle,
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const shapesList = stageProperties[currentStage].combinedObject.reverse();
        const reorderedObjects = reorder(shapesList, result.source.index, result.destination.index);
        const originalShapeList = reorderedObjects.reverse();

        setStageProperties((prevProperties) => ({
            ...prevProperties,
            [currentStage]: {
                ...prevProperties[currentStage],
                combinedObject: originalShapeList,
            },
        }));
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <Accordion
            className='m-0'
            style={{ overflowY: 'auto', maxHeight: '400px' }}
            expanded={expanded === `panel${stageIndex}`}
            onChange={handleChange(`panel${stageIndex}`)}
        >
            <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon />}
                aria-controls={`panel${stageIndex}d-content`}
                id={`panel${stageIndex}d-header`}
            >
                <Typography className='subtitle-layer'>{stageIndex === 1 ? 'CARD FRONT' : 'CARD BACK'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`droppable-${stageIndex}`}>
                        {(provided, snapshot) => (
                            <div
                                className='layer-list'
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {stageProperties[stageIndex].combinedObject
                                    .slice()
                                    .reverse()
                                    .map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={`d-flex justify-content-between with-margin ${
                                                        selectedShape?.id === item?.id
                                                            ? 'activeShape  selectedShape'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedShape(item);
                                                        setShowResources(false);
                                                    }}
                                                    style={{
                                                        padding: '10px',
                                                        background:
                                                            selectedShape?.id === item.id ? '#E0E0E0' : 'transparent',
                                                        ...getItemStyle(
                                                            provided.draggableProps.style,
                                                            snapshot.isDragging,
                                                        ),
                                                    }}
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    <div
                                                        className='w-100 d-flex align-items-center'
                                                        onClick={() => {
                                                            setSelectedShape(item);
                                                            updateForm(item);
                                                            setShowResources(false);
                                                        }}
                                                    >
                                                        {item.type === 'textTemplate' && (
                                                            <img
                                                                className='card-designer-image'
                                                                src={tIcon}
                                                                alt='textTemplate'
                                                            />
                                                        )}
                                                        {item.type === 'lineTemplate' && (
                                                            <img
                                                                className='card-designer-image'
                                                                src={lineIcon}
                                                                alt='lineTemplate'
                                                            />
                                                        )}
                                                        {item.type === 'rectangleTemplate' && (
                                                            <img
                                                                className='card-designer-image'
                                                                src={squareIcon}
                                                                alt='rectangleTemplate'
                                                            />
                                                        )}
                                                        {item.type === 'ellipseTemplate' && (
                                                            <img
                                                                className='card-designer-image'
                                                                src={circleIcon}
                                                                alt='ellipseTemplate'
                                                            />
                                                        )}
                                                        {item.type === 'imageTemplate' && (
                                                            <img
                                                                className='card-designer-image'
                                                                src={imageIcon}
                                                                alt='imageTemplate'
                                                            />
                                                        )}
                                                        <p
                                                            className='layer-text'
                                                            style={{
                                                                color: snapshot.isDragging ? '#707070' : '#707070',
                                                            }}
                                                        >
                                                            {item.type === 'imageTemplate' && !item.image
                                                                ? t('image_card_undefined')
                                                                : procesName(item.textName) + ' ' + (index + 1)}
                                                        </p>
                                                    </div>

                                                    <button
                                                        className='btn h-100 rounded-0 btn-delete-accordion'
                                                        onClick={() => handleDeleteShape(item.id, item.property)}
                                                    >
                                                        <i className='fa fa-trash-o fs-4' aria-hidden='true'></i>
                                                    </button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </AccordionDetails>
        </Accordion>
    );
};

export default StageAccordion;
