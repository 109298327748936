import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import { t } from 'i18next';
import CustomTextWithLine from '../../../components/CustomTextWithLine';
import '../../../scss/devicesConfiguration.scss';
import '../../../scss/colors.scss';
import noImageAvailable from '../../../assets/NoImageAvailable.png';
import noImageAvailableSpanish from '../../../assets/noImageAvailableSpanish.png';
import noImageAvailableFrench from '../../../assets/noImageAvailableFrench.png';
import ChangeBackgroundModal from './Modals/ChangeBackgroundModal';
import {
    getDeviceConfiguration,
    getDownloadImageDeviceIdle,
    getDownloadImageDeviceManageClock,
} from '../../../Apis/backgroundChange';
import cryptoJs from 'crypto-js';
import securekey from '../../../config';
import { ReadMoreSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const DevicesConfiguration = () => {
    const [loadTimeClock, setLoadTimeClock] = useState(false);
    const token = sessionStorage.getItem('bearerToken');
    const bytes = cryptoJs.AES.decrypt(token, securekey);
    const bearerToken = bytes.toString(cryptoJs.enc.Utf8);
    const [idleBackground, setIdleBackground] = useState('null');
    const [deviceConfiguration, setDeviceConfiguration] = useState(null);
    const [deviceConfigurationIDLE, setDeviceConfigurationIDLE] = useState(null);
    const [deviceConfigurationTimeClock, setDeviceConfigurationTimeClock] = useState(null);
    const isPhotoInIdle = false;
    const isPhotoinTimeClock = false;

    const [open, setOpen] = useState(false);
    const [isIDLEBackgroundToChange, setIsIDLEBackgroundToChange] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [actualLanguage, setActualLanguage] = useState(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        const currentLanguage = i18n.language;
        setActualLanguage(currentLanguage);
    }, [i18n.language]);

    useEffect(() => {
        const fetchDeviceConfigurationForIDLE = async () => {
            try {
                const response = await getDownloadImageDeviceIdle();
                if (response.status === 200) {
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data); // Leer el blob como Base64
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        setDeviceConfigurationIDLE(base64data);
                    };
                } else {
                    console.error('Error al obtener la configuración del dispositivo IDLE\n', response.status);
                }
            } catch (error) {
                console.error('Error al obtener la configuración del dispositivo IDLE', error);
            }
        };
        const fetchDeviceConfigurationForTimeClock = async () => {
            try {
                const response = await getDownloadImageDeviceManageClock();
                if (response.status === 200) {
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data); // Leer el blob como Base64
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        setDeviceConfigurationTimeClock(base64data);
                    };
                } else {
                    console.error('Error al obtener la configuración del dispositivo Time Clock:', response.status);
                }
            } catch (error) {
                console.error('Error al obtener la configuración del dispositivo Time Clock:', error);
            }
        };
        fetchDeviceConfigurationForIDLE();
        fetchDeviceConfigurationForTimeClock();
    }, []);

    useEffect(() => {
        const fetchDeviceConfiguration = async () => {
            try {
                const response = await getDeviceConfiguration();
                if (response.status === 200) {
                    setDeviceConfiguration(response.data);
                } else {
                    console.error('Error al obtener la configuración del dispositivo\n', response.status);
                }
            } catch (error) {
                console.error('Error al obtener la configuración del dispositivo', error);
            }
        };

        fetchDeviceConfiguration();
    }, []);

    const showChangeIDLEModal = () => {
        setIsIDLEBackgroundToChange(true);
        if (isIDLEBackgroundToChange) {
            handleOpen(); // Open the modal when this function is called
        }
    };

    const showChangeBackgroundTimeCLockModal = () => {
        setIsIDLEBackgroundToChange(false);
        if (isIDLEBackgroundToChange === false) {
            handleOpen();
        }
    };

    return (
        <Box className='complete--configuration--screen center-elements'>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={'/dashboard/employee/devices'} />
                    <h2>{t('configuration')}</h2>
                </div>
            </div>
            <Box sx={{ padding: '2rem 4rem 0rem 4rem' }}>
                <CustomTextWithLine title={t('background')} />
            </Box>
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-around'}
                className='section-configuration--background'
            >
                <Box className='idle-section--uploadImage centerElements halfScreen--sectionsDivider'>
                    <Typography variant='h1' color='initial' className='titles-h1--eachSection'>
                        {t('IDLE')}
                    </Typography>
                    <section className='photoSection centerElements'>
                        {deviceConfigurationIDLE ? (
                            <img
                                className='img--backgroundDevices img--backgroundIDLE'
                                src={deviceConfigurationIDLE}
                                alt={t('background_for_IDLE')}
                            />
                        ) : actualLanguage === 'en' ? (
                            <img
                                src={noImageAvailable}
                                className='img--noImageAvailable'
                                alt={t('the_image_does_not_exist')}
                            />
                        ) : actualLanguage === 'sp' ? (
                            <img
                                src={noImageAvailableSpanish}
                                className='img--noImageAvailable'
                                alt={t('the_image_does_not_exist')}
                            />
                        ) : (
                            <img
                                src={noImageAvailableFrench}
                                className='img--noImageAvailable'
                                alt={t('the_image_does_not_exist')}
                            />
                        )}
                    </section>
                    {/* < href='' onClick={showChangeIDLEModal}> */}
                    <p className='paragraph-to-changeImage' onClick={showChangeIDLEModal}>
                        {t('click_here_to_change_the_image').toUpperCase()}
                    </p>

                    <ChangeBackgroundModal
                        open={open}
                        handleClose={handleClose}
                        isIDLEBackgroundToChange={isIDLEBackgroundToChange}
                        isPhotoinTimeClock={isPhotoinTimeClock}
                        isPhotoInIdle={isPhotoInIdle}
                    />
                </Box>
                <Box className='section--timeClock centerElements halfScreen--sectionsDivider'>
                    <Typography variant='h1' color='initial' className='titles-h1--eachSection'>
                        {t('time_clock').toUpperCase()}
                    </Typography>
                    <section className='photoSection centerElements'>
                        {deviceConfigurationTimeClock ? (
                            <img
                                className='img--backgroundDevices img--backgroundTimeClock'
                                src={deviceConfigurationTimeClock}
                                alt={t('background_for_time_clock')}
                            />
                        ) : (
                            <img
                                src={noImageAvailable}
                                className='img--noImageAvailable'
                                alt={t('the_image_does_not_exist')}
                            />
                        )}
                    </section>
                    <p className='paragraph-to-changeImage' onClick={showChangeBackgroundTimeCLockModal}>
                        {t('click_here_to_change_the_image').toUpperCase()}
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default DevicesConfiguration;
