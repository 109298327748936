import React from 'react';
import { Line, Pie, Bar, Doughnut, Radar, PolarArea } from 'react-chartjs-2';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadarController,
    RadialLinearScale,
    PolarAreaController,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    RadarController,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend,
    PolarAreaController,
    ArcElement,
);
const ChartComponent = ({ data, label, typo, onRemove }) => {
    const fontSize = 8;
    const rotationAngle = data.labels.length > 35 ? 90 : data.labels.length <= 6 ? 0 : 45;

    const wrapLabel = (text) => {
        return text.length > 15 ? text.match(/.{1,15}/g).join('\n') : text;
    };

    const updatedData = {
        ...data,
        labels: data.labels.map(wrapLabel),
        datasets: data.datasets.map((dataset) => ({
            ...dataset,
            borderColor: '#146f62',
            backgroundColor: 'rgba(20, 111, 98, 0.2)',
            pointBackgroundColor: '#146f62',
            pointBorderColor: '#fff',
            pointBorderWidth: 2,
            pointHoverBackgroundColor: '#ee8013',
            pointHoverBorderColor: '#000',
            pointHoverBorderWidth: 3,
            pointHoverRadius: 7,
        })),
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: label,
                font: {
                    size: fontSize,
                },
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y}`;
                        }
                        return label;
                    },
                },
            },
            legend: {
                display: true,
                position: 'top',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    autoSkip: false,
                    font: {
                        size: fontSize,
                    },
                    maxRotation: rotationAngle,
                    minRotation: rotationAngle,
                },
            },
            y: {
                min: 0,
                max: 100,
                beginAtZero: true,
                stacked: true,
                ticks: {
                    font: {
                        size: fontSize,
                    },
                },
            },
        },
    };

    return (
        <>
            {typo === 'line' && <Line data={updatedData} options={options} />}
            {typo === 'bar' && <Bar data={updatedData} options={options} />}
            {typo === 'pie' && <Pie data={updatedData} options={options} />}
            {typo === 'donut' && <Doughnut data={updatedData} options={options} />}
            {typo === 'radar' && <Radar data={updatedData} options={options} />}
            {typo === 'polar' && <PolarArea data={updatedData} options={options} />}
        </>
    );
};

export default ChartComponent;
