import { Box, Grid, Stack, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { permissionObj } from '../../../Helpers/permission';
import { t } from 'i18next';
import Cookies from 'js-cookie';

import entry_ic from '../../../assets/images/arrow-up-solid-image.png';
import exit_ic from '../../../assets/images/arrow-down-solid-image.png';
import both_ic from '../../../assets/images/ic-both-entries-image.png';
import cryptoJs from 'crypto-js';
import securekey from '../../../config';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import SockJsClient from 'react-stomp';
import { WebSocketGuard } from '../../../reduxToolkit/Commons/CommonsApi';
import { GuardSizes } from '../../../models/GuardSizes';
import { GuardLists } from '../../../models/GuardLists';
import { GoPrimitiveDot } from 'react-icons/go';
import moment from 'moment';
import { colors } from '../../../enums/colors';
import { accessMethod } from '../../../enums/accessMethodEnum';
import tagIcon from '../../../assets/icon/tag-solid.svg';
import faceIcon from '../../../assets/icon/ic-face.svg';
import cardIcon from '../../../assets/icon/ic-card.svg';
import fingerIcon from '../../../assets/icon/ic-fingerprint.svg';
import qrIcon from '../../../assets/icon/ic-qr-code.svg';
import pinIcon from '../../../assets/icon/pin-icon.png';
import bluetoothIcon from '../../../assets/icon/bluetooth-icon.svg';
import laptopIcon from '../../../assets/laptop-solid.svg';
import mobileIcon from '../../../assets/mobile-screen-solid.svg';
import { userType } from '../../../enums/userTypeEnum';
import checkIcon from '../../../assets/icon/checkIcon.svg';
import cancelIcon from '../../../assets/icon/cancelIcon.svg';
import minusIcon from '../../../assets/icon/ic-minus.svg';
import { status } from '../../../enums/statusEnum';
import { statusColor } from '../../../enums/statusColorEnum';
import { Divider } from '@mui/material';
import NotInformation from '../../../components/NotInformation';
import { guardURL } from '../../../Apis/Constants';

// Manage Attendence module main funtion
const Guard = () => {
    // use hook importer
    const dispatch = useDispatch();
    const lCode = Cookies.get('i18next') || 'en';
    //use Selector hook to get state for redux store

    const [guardSizes, setGuardSizes] = useState(GuardSizes);
    const [sizeChanged, setSizeChanged] = useState(false);
    // const [guardLists, setGuardLists] = useState(GuardLists);
    const [guardLists, setGuardLists] = useState(GuardLists);

    const [firstLoad, setFirstLoad] = useState(true);
    let isConnected;

    var clientRef = null;
    let socket;
    let stompClient;
    const token = sessionStorage.getItem('bearerToken');
    const bytes = cryptoJs.AES.decrypt(token, securekey);
    const tokenDecrpt = bytes.toString(cryptoJs.enc.Utf8);

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    function handleWebSocketMessage(response) {
        const valuesNotNull = Object.values(response).every((valor) => valor !== null);
        if (valuesNotNull) {
            setGuardLists((prevData) => ({
                ...prevData,
                ...response,
            }));

            setFirstLoad(false);
        } else {
            if (response.userAccessLogs != null) {
                if (sessionStorage.getItem('guardAccessSize')) {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        userAccessLogs: [
                            response.userAccessLogs[0],
                            ...prevData.userAccessLogs.slice(0, sessionStorage.getItem('guardAccessSize') - 1),
                        ],
                    }));
                } else {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        userAccessLogs: [
                            response.userAccessLogs[0],
                            ...prevData.userAccessLogs.slice(0, guardSizes.userAccessLogSize - 1),
                        ],
                    }));
                }
            }

            if (response.userInvitations != null) {
                if (sessionStorage.getItem('guardInvitationsSize')) {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        userInvitations: [
                            response.userInvitations[0],
                            ...prevData.userInvitations.slice(0, sessionStorage.getItem('guardInvitationsSize') - 1),
                        ],
                    }));
                } else {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        userInvitations: [
                            response.userInvitations[0],
                            ...prevData.userInvitations.slice(0, guardSizes.userInvitationSize - 1),
                        ],
                    }));
                }
            }

            if (response.events != null) {
                if (sessionStorage.getItem('guardEventsSize')) {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        events: [
                            response.events[0],
                            ...prevData.events.slice(0, sessionStorage.getItem('guardEventsSize') - 1),
                        ],
                    }));
                } else {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        events: [response.events[0], ...prevData.events.slice(0, guardSizes.eventSize - 1)],
                    }));
                }
            }
            if (response.vehicleAccessLogs != null) {
                setGuardLists((prevData) => ({
                    ...prevData,
                    vehicleAccessLogs: [
                        response.vehicleAccessLogs[0],
                        ...prevData.vehicleAccessLogs.slice(0, guardSizes.vehicleAccessLogSize - 1),
                    ],
                }));
            }
            if (response.vehicleInvitations != null) {
                setGuardLists((prevData) => ({
                    ...prevData,
                    vehicleInvitations: [
                        response.vehicleInvitations[0],
                        ...prevData.vehicleInvitations.slice(0, guardSizes.vehicleInvitationSize - 1),
                    ],
                }));
            }
            if (response.orders != null) {
                if (sessionStorage.getItem('guardOrdersSize')) {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        orders: [
                            response.orders[0],
                            ...prevData.orders.slice(0, sessionStorage.getItem('guardOrdersSize') - 1),
                        ],
                    }));
                } else {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        orders: [response.orders[0], ...prevData.orders.slice(0, guardSizes.orderSize - 1)],
                    }));
                }
            }
            if (response.contracts != null) {
                if (sessionStorage.getItem('guardContractsSize')) {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        contracts: [
                            response.contracts[0],
                            ...prevData.contracts.slice(0, sessionStorage.getItem('guardContractsSize') - 1),
                        ],
                    }));
                } else {
                    setGuardLists((prevData) => ({
                        ...prevData,
                        contracts: [response.contracts[0], ...prevData.contracts.slice(0, guardSizes.orderSize - 1)],
                    }));
                }
            }
        }
    }

    // handel the logic for object transformation for all user

    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.name || '') +
                    ' ' +
                    (item?.firstLastName || '') +
                    (item?.secondLastName ? ' ' + item.secondLastName : ''),
                id: item?.id || item?.userId,
            };
        });
        return newData;
    };

    // handel the logic for object transformation for all user

    const userDataTransformGuest = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.guestName || ''} ${item?.guestLastName || ''} ${
                    item?.guestSecondLastName || ''
                }`.trim(),
            };
        });
        return newData;
    };

    const userDataTransformHost = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.hostName || ''} ${item?.hostLastName || ''} ${item?.hostSecondLastName || ''}`.trim(),
            };
        });
        return newData;
    };

    const userDataTransformValidatedBy = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.validatedByName || ''} ${item?.validatedByLastName || ''} ${
                    item?.validatedBySecondLastName || ''
                }`.trim(),
            };
        });
        return newData;
    };

    const userDataTransformSupplierEmployee = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.supplierEmployeeName || ''} ${item?.supplierEmployeeLastName || ''} ${
                    item?.supplierEmployeeSecondLastName || ''
                }`.trim(),
            };
        });
        return newData;
    };

    const userDataTransformSupplierVehicle = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.supplierVehicleBrand || ''} ${item?.supplierVehicleSubBrand || ''} ${
                    item?.supplierVehicleModel === 0 || item?.supplierVehicleModel === null
                        ? ''
                        : item?.supplierVehicleModel
                } | ${item?.supplierVehiclePlate || ''}`.trim(),
            };
        });
        return newData;
    };

    const userDataTransformContractor = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.contractorName || ''} ${item?.contractorLastName || ''} ${
                    item?.contractorSecondLastName || ''
                }`.trim(),
            };
        });
        return newData;
    };

    // dispatch to get result
    useEffect(() => {
        // Conexión a SockJS
        socket = new SockJS(guardURL);
        stompClient = Stomp.over(socket);

        // // Conecta con el servidor WebSocket
        stompClient.connect(
            {
                headers: {
                    Authorization: `Bearer ${tokenDecrpt}`,
                },
            },
            function (frame) {
                // Suscríbete al canal de destino
                stompClient.subscribe('/topic/guard', function (response) {
                    // Maneja el mensaje WebSocket
                    handleWebSocketMessage(JSON.parse(response.body));
                });
            },
        );

        (async () => {
            isConnected = false;

            while (!isConnected) {
                isConnected = stompClient.connected;
                if (isConnected) {
                    dispatch(WebSocketGuard(guardSizes));
                }
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        })();
        return () => {
            sessionStorage.removeItem('guardAccessSize');
            sessionStorage.removeItem('guardInvitationsSize');
            sessionStorage.removeItem('guardEventsSize');
            sessionStorage.removeItem('guardContractsSize');
            sessionStorage.removeItem('guardOrdersSize');
        };
    }, []);

    useEffect(() => {
        if (sizeChanged) {
            dispatch(WebSocketGuard(guardSizes));
            setSizeChanged(false);
        }
    }, [sizeChanged]);
    return (
        <>
            <div
                className='manage_attendence_container vh-100 pb-3'
                style={{ overflowY: 'hidden', overflowX: 'scroll' }}
            >
                <Grid container columns={15} className='h-100 pb-3' style={{ minWidth: '1600px' }}>
                    {/* device section */}
                    <Grid
                        item
                        xs={5}
                        className={
                            guardLists?.userAccessLogs?.length > 0
                                ? 'device px-3 vh-100 pb-5'
                                : 'receiver px-3 vh-100 pb-5 border-0'
                        }
                    >
                        <Stack direction='row' justifyContent='space-around' alignItems='center'>
                            <h4>{t('last_accesses')}</h4>
                            <div className='custom-numeric-dropdown'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                    count={''}
                                    page={''}
                                    onPageChange={''}
                                    labelRowsPerPage={''}
                                    rowsPerPage={guardSizes.userAccessLogSize}
                                    onRowsPerPageChange={(e) => {
                                        setSizeChanged(true);
                                        setFirstLoad(true);
                                        setGuardSizes((prevData) => ({
                                            ...prevData,
                                            userAccessLogSize: e.target.value,
                                        }));
                                        sessionStorage.setItem('guardAccessSize', e.target.value);
                                    }}
                                />
                            </div>
                        </Stack>
                        <Box className='list_container pt-3 px-1 scroll-guard-list pb-5'>
                            {guardLists?.userAccessLogs?.length > 0 ? (
                                <>
                                    {guardLists?.userAccessLogs?.map((access) => {
                                        return (
                                            <Box className='access_card px-1'>
                                                <div className='d-flex justify-content-between mt-1'>
                                                    <GoPrimitiveDot
                                                        className='ml-0'
                                                        style={{
                                                            color: access.success === true ? colors.green : colors.red,
                                                        }}
                                                    />
                                                    <span>
                                                        {lCode === 'en'
                                                            ? moment(access?.createdAt).format('MM/DD/YYYY')
                                                            : moment(access?.createdAt).format('DD/MM/YYYY')}{' '}
                                                        {moment(access?.createdAt).format('HH:mm:ss')}
                                                    </span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div style={{ maxWidth: '70%' }}>
                                                        <span style={{ display: 'block' }}>{access?.userFullName}</span>
                                                        <span style={{ display: 'block' }}>
                                                            {t(userType[access?.userTypeId]).toUpperCase()}
                                                        </span>
                                                        <span style={{ display: 'block' }}>{access?.zoneName}</span>
                                                    </div>
                                                    <div className='d-flex justify-content-around'>
                                                        <Tooltip
                                                            title={
                                                                (access?.deviceAccessTypeId === 3 &&
                                                                    t('entry_exit').toUpperCase()) ||
                                                                (access?.deviceAccessTypeId === 2 &&
                                                                    t('exit').toUpperCase()) ||
                                                                (access?.deviceAccessTypeId === 1 &&
                                                                    t('entry').toUpperCase())
                                                            }
                                                            placement='right'
                                                            arrow
                                                            disableInteractive
                                                            className='m-2'
                                                        >
                                                            <img
                                                                src={
                                                                    (access?.deviceAccessTypeId === 3 && both_ic) ||
                                                                    (access?.deviceAccessTypeId === 2 && exit_ic) ||
                                                                    (access?.deviceAccessTypeId === 1 && entry_ic)
                                                                }
                                                                alt=''
                                                                style={{ height: '20px' }}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip
                                                            className='mx-2'
                                                            title={t(accessMethod[access.accessMethodId]).toUpperCase()}
                                                            placement='right'
                                                            disableInteractive
                                                        >
                                                            <img
                                                                src={
                                                                    access.accessMethodId === 1
                                                                        ? tagIcon
                                                                        : access.accessMethodId === 2
                                                                          ? fingerIcon
                                                                          : access.accessMethodId === 3
                                                                            ? cardIcon
                                                                            : access.accessMethodId === 4
                                                                              ? qrIcon
                                                                              : access.accessMethodId === 5
                                                                                ? faceIcon
                                                                                : access.accessMethodId === 6
                                                                                  ? pinIcon
                                                                                  : access.accessMethodId === 7
                                                                                    ? fingerIcon
                                                                                    : access.accessMethodId === 8
                                                                                      ? bluetoothIcon
                                                                                      : access.accessMethodId === 9
                                                                                        ? laptopIcon
                                                                                        : access.accessMethodId ===
                                                                                              10 && mobileIcon
                                                                }
                                                                alt='AccessMethod'
                                                                style={{
                                                                    width:
                                                                        [6, 8, 9, 10].includes(access.accessMethodId) &&
                                                                        '24px',
                                                                    height:
                                                                        [6, 8, 9, 10].includes(access.accessMethodId) &&
                                                                        '24px',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </Box>
                                        );
                                    })}
                                </>
                            ) : (
                                <NotInformation text={t('no_information')} card={true} />
                            )}
                        </Box>
                    </Grid>
                    {/* receiver section */}
                    <Grid item xs={3} className='receiver vh-100 pb-5'>
                        <Stack direction='row' justifyContent='space-around' alignItems='center'>
                            <h4> {t('incoming_invitations')}</h4>
                            <div className='custom-numeric-dropdown'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                    count={''}
                                    page={''}
                                    onPageChange={''}
                                    labelRowsPerPage={''}
                                    rowsPerPage={guardSizes.userInvitationSize}
                                    onRowsPerPageChange={(e) => {
                                        setSizeChanged(true);
                                        setFirstLoad(true);
                                        setGuardSizes((prevData) => ({
                                            ...prevData,
                                            userInvitationSize: e.target.value,
                                        }));
                                        sessionStorage.setItem('guardInvitationsSize', e.target.value);
                                    }}
                                />
                            </div>
                        </Stack>
                        <Box className='list_container scroll-guard-list pt-3 px-1 pb-5'>
                            {guardLists?.userInvitations?.length > 0 ? (
                                <>
                                    {guardLists?.userInvitations?.map((invitation) => {
                                        return (
                                            <Box className='invitation_card px-1'>
                                                <div className='d-flex justify-content-between mt-1'>
                                                    <div></div>
                                                    <span>
                                                        {lCode === 'en'
                                                            ? moment(invitation?.startDate).format('MM/DD/YYYY')
                                                            : moment(invitation?.startDate).format('DD/MM/YYYY')}{' '}
                                                        {moment(invitation?.startDate).format('HH:mm:ss')}
                                                    </span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-1'>
                                                    <div style={{ maxWidth: '70%' }}>
                                                        <p className='paragraph_deps' style={{ display: 'block' }}>
                                                            {t('name')}
                                                            {': '}
                                                            <span>{userDataTransformGuest([invitation])[0].label}</span>
                                                        </p>
                                                        <p className='paragraph_deps' style={{ display: 'block' }}>
                                                            {t('telephone')}
                                                            {': '}
                                                            <span>{invitation?.guestPhoneNumber}</span>
                                                        </p>
                                                    </div>
                                                    <div className='d-flex justify-content-around pr-4'>
                                                        <img
                                                            src={
                                                                invitation?.statusId === 38
                                                                    ? checkIcon
                                                                    : invitation?.statusId === 39
                                                                      ? cancelIcon
                                                                      : minusIcon
                                                            }
                                                            alt='AccessMethod'
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Box>
                                        );
                                    })}
                                </>
                            ) : (
                                <NotInformation text={t('no_information')} card={true} />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={3} className='receiver vh-100 pb-5'>
                        <Stack direction='row' justifyContent='space-around' alignItems='center'>
                            <h4> {t('incoming_events')}</h4>
                            <div className='custom-numeric-dropdown'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                    count={''}
                                    page={''}
                                    onPageChange={''}
                                    labelRowsPerPage={''}
                                    rowsPerPage={guardSizes.eventSize}
                                    onRowsPerPageChange={(e) => {
                                        setSizeChanged(true);
                                        setFirstLoad(true);
                                        setGuardSizes((prevData) => ({
                                            ...prevData,
                                            eventSize: e.target.value,
                                        }));
                                        sessionStorage.setItem('guardEventsSize', e.target.value);
                                    }}
                                />
                            </div>
                        </Stack>

                        <Box className='list_container pt-3 px-1 scroll-guard-list pb-5'>
                            {guardLists?.events?.length > 0 ? (
                                <>
                                    {guardLists?.events?.map((event) => {
                                        return (
                                            <div className='panel-grid mb-2'>
                                                <div className='card-base-grid w-100 pl-2'>
                                                    <div className='top-heading-card'>
                                                        <div className='input_check'></div>

                                                        <div
                                                            className={'status'}
                                                            style={{ color: statusColor[event?.statusId] }}
                                                        >
                                                            <p style={{ color: statusColor[event?.statusId] }}>
                                                                {t(status[event?.statusId]).toUpperCase()}
                                                            </p>
                                                            <GoPrimitiveDot className='ml-1' />
                                                        </div>
                                                    </div>
                                                    <div className='card-body-grid'>
                                                        <div className='card-content-grid'>
                                                            <span className='viewcard-container-courier'>
                                                                {t('information')}
                                                            </span>
                                                        </div>

                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('name')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>{event?.name}</span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('host')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                {userDataTransformHost([event])[0].label}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('start_date')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                {lCode === 'en'
                                                                    ? moment(event?.startDate).format('MM/DD/YYYY')
                                                                    : moment(event?.startDate).format(
                                                                          'DD/MM/YYYY',
                                                                      )}{' '}
                                                                {moment(event?.startDate).format('HH:mm:ss')}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('end_date')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                {lCode === 'en'
                                                                    ? moment(
                                                                          event?.startDate + event?.duration * 60000,
                                                                      ).format('MM/DD/YYYY')
                                                                    : moment(
                                                                          event?.startDate + event?.duration * 60000,
                                                                      ).format('DD/MM/YYYY')}{' '}
                                                                {moment(
                                                                    event?.startDate + event?.duration * 60000,
                                                                ).format('HH:mm:ss')}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('booking')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                {event?.reservationZoneName}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('validated_by')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                {event?.validatedById === null
                                                                    ? '-'
                                                                    : userDataTransformValidatedBy([event])[0].label}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <NotInformation text={t('no_information')} card={true} />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={3} className='receiver vh-100 pb-5'>
                        <Stack direction='row' justifyContent='space-around' alignItems='center'>
                            <h4> {t('active_contracts')}</h4>
                            <div className='custom-numeric-dropdown'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                    count={''}
                                    page={''}
                                    onPageChange={''}
                                    labelRowsPerPage={''}
                                    rowsPerPage={guardSizes.contractSize}
                                    onRowsPerPageChange={(e) => {
                                        setSizeChanged(true);
                                        setFirstLoad(true);
                                        setGuardSizes((prevData) => ({
                                            ...prevData,
                                            contractSize: e.target.value,
                                        }));
                                        sessionStorage.setItem('guardContractsSize', e.target.value);
                                    }}
                                />
                            </div>
                        </Stack>
                        <Box className='list_container pt-3 px-1 scroll-guard-list pb-5'>
                            {guardLists?.contracts?.length > 0 ? (
                                <>
                                    {guardLists?.contracts?.map((contract) => {
                                        return (
                                            <div className='panel-grid mb-2'>
                                                <div className='card-base-grid w-100 pl-2'>
                                                    <div className='top-heading-card'>
                                                        <div className='card-content-grid'>
                                                            <span className='viewcard-container-heading text-left'>
                                                                {t('Folio')}{' '}
                                                            </span>
                                                            <span className='viewcard-container-heading text-left'>
                                                                #{contract?.folio || '-'}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={'status'}
                                                            style={{ color: statusColor[contract?.statusId] }}
                                                        >
                                                            <p style={{ color: statusColor[contract?.statusId] }}>
                                                                {t(status[contract?.statusId]).toUpperCase()}
                                                            </p>
                                                            <GoPrimitiveDot className='ml-1' />
                                                        </div>
                                                    </div>
                                                    <div className='card-body-grid'>
                                                        <div className='card-content-grid'>
                                                            <span className='viewcard-container-courier'>
                                                                {t('contractor')}
                                                            </span>
                                                        </div>

                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('name')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                {userDataTransformContractor([contract])[0].label}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('company')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                <span className='font-weight-bold'>
                                                                    {contract?.contractorCompanyAcronym} |
                                                                </span>{' '}
                                                                {contract?.contractorCompanyName}
                                                            </span>
                                                        </div>
                                                        <Divider
                                                            className='mr-2'
                                                            sx={{
                                                                transform: 'translateY(-50%)',
                                                                right: 0,
                                                                background: '#146F62',
                                                                height: '1px !important',
                                                                opacity: '1',
                                                                borderRadius: '10px',
                                                            }}
                                                        />
                                                        <div className='card-content-grid mt-1'>
                                                            <span className='viewcard-container-courier'>
                                                                {t('information')}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('start_contract')}</p>
                                                            <span className='mr-2'>
                                                                {contract.startDate === null
                                                                    ? '-'
                                                                    : lCode === 'en'
                                                                      ? moment(contract.startDate).format('MM/DD/YYYY')
                                                                      : moment(contract.startDate).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('end_contract')}</p>
                                                            <span className='mr-2'>
                                                                {contract.endDate === null
                                                                    ? '-'
                                                                    : lCode === 'en'
                                                                      ? moment(contract.endDate).format('MM/DD/YYYY')
                                                                      : moment(contract.endDate).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('num_employees')}</p>
                                                            <span className='mr-2'>{contract?.noEmployees || '-'}</span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('num_vehicles')}</p>
                                                            <span className='mr-2'>{contract?.noVehicles || '-'}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <NotInformation text={t('no_information')} card={true} />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={3} className='receiver'>
                        <Stack direction='row' justifyContent='space-around' alignItems='center'>
                            <h4> {t('incoming_orders')}</h4>
                            <div className='custom-numeric-dropdown'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                    count={''}
                                    page={''}
                                    onPageChange={''}
                                    labelRowsPerPage={''}
                                    rowsPerPage={guardSizes.orderSize}
                                    onRowsPerPageChange={(e) => {
                                        setSizeChanged(true);
                                        setFirstLoad(true);
                                        setGuardSizes((prevData) => ({
                                            ...prevData,
                                            orderSize: e.target.value,
                                        }));
                                        sessionStorage.setItem('guardOrdersSize', e.target.value);
                                    }}
                                />
                            </div>
                        </Stack>
                        <Box className='list_container pt-3 px-1 scroll-guard-list pb-5'>
                            {guardLists?.orders?.length > 0 ? (
                                <>
                                    {guardLists?.orders?.map((order) => {
                                        return (
                                            <div className='panel-grid mb-2'>
                                                <div className='card-base-grid w-100 pl-2'>
                                                    <div className='top-heading-card'>
                                                        <div className='card-content-grid'>
                                                            <span className='viewcard-container-heading text-left'>
                                                                {t('Folio')}{' '}
                                                            </span>
                                                            <span className='viewcard-container-heading text-left'>
                                                                #{order?.folio || '-'}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={'status'}
                                                            style={{ color: statusColor[order?.statusId] }}
                                                        >
                                                            <p style={{ color: statusColor[order?.statusId] }}>
                                                                {t(status[order?.statusId]).toUpperCase()}
                                                            </p>
                                                            <GoPrimitiveDot className='ml-1' />
                                                        </div>
                                                    </div>
                                                    <div className='card-body-grid'>
                                                        <div className='card-content-grid'>
                                                            <span className='viewcard-container-courier'>
                                                                {t('courier_information')}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('company')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                <span className='font-weight-bold'>
                                                                    {order?.supplierCompanyAcronym} |
                                                                </span>{' '}
                                                                {order?.supplierCompanyName}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('employee')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                {order?.supplierEmployeeId === null
                                                                    ? '-'
                                                                    : userDataTransformSupplierEmployee([order])[0]
                                                                          .label}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('vehicle')}</p>
                                                        </div>
                                                        <div className='card-content-grid'>
                                                            <span style={{ textTransform: 'none' }}>
                                                                {order?.supplierVehicleId === null
                                                                    ? '-'
                                                                    : userDataTransformSupplierVehicle([order])[0]
                                                                          .label}
                                                            </span>
                                                        </div>

                                                        <Divider
                                                            className='mr-2'
                                                            sx={{
                                                                transform: 'translateY(-50%)',
                                                                right: 0,
                                                                background: '#146F62',
                                                                height: '1px !important',
                                                                opacity: '1',
                                                                borderRadius: '10px',
                                                            }}
                                                        />
                                                        <div className='card-content-grid mt-1'>
                                                            <span className='viewcard-container-courier'>
                                                                {t('delivery_information')}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('eta')}</p>
                                                            <span className='mr-2'>
                                                                {order.eta === null
                                                                    ? '-'
                                                                    : lCode === 'en'
                                                                      ? moment(order.eta).format('MM/DD/YYYY HH:mm:ss')
                                                                      : moment(order.eta).format('DD/MM/YYYY HH:mm:ss')}
                                                            </span>
                                                        </div>
                                                        <div className='card-content-grid mt-1'>
                                                            <p>{t('item')}</p>
                                                            <span className='mr-2'>{order?.item || '-'}</span>
                                                        </div>
                                                        <div className='card-content-grid mb-1'>
                                                            <p>{t('description')} </p>
                                                            <span className='text-right mr-2'>
                                                                {order?.description || '-'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <NotInformation text={t('no_information')} card={true} />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Guard;
