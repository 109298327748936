import React, { useState, useEffect } from 'react';
import { Modal, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import cancel from '../../../src/assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AddCharts, GetChartsAvailableByDashboardId } from '../../reduxToolkit/Graphs/GraphsApi';

const AddGraphModal = ({ show, onHide, chartComponents, setChanged, changed, sectionPosition, dashboardIdSection }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedGraph, setSelectedGraph] = useState('');
    const [availableCharts, setAvailableCharts] = useState([]);
    const [selectedChartType, setSelectedChartType] = useState('');

    const handleChartTypeChange = (event) => {
        setSelectedChartType(event.target.value);
    };

    const handleGraphChange = (event) => {
        setSelectedGraph(event.target.value);
    };

    useEffect(() => {
        if (dashboardIdSection) {
            dispatch(GetChartsAvailableByDashboardId(dashboardIdSection)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        setAvailableCharts(data);
                    }
                },
            );
        }
    }, [dispatch, dashboardIdSection]);

    const handleCreate = async () => {
        const body = {
            charts: [
                {
                    chartType: selectedChartType,
                    id: selectedGraph,
                    position: sectionPosition,
                },
            ],
            dashboardId: dashboardIdSection,
        };

        const result = await dispatch(AddCharts({ body }));
        const {
            data: { success },
        } = result.payload;

        if (success === true) {
            setChanged(true);
            onHide();
        }
    };
    const isCreateButtonDisabled = !selectedChartType || !selectedGraph;
    return (
        <Modal
            open={show}
            onClose={onHide}
            size='md'
            className='modal-dialog modal-lg modal-dialog-centered modal-content'
            style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
        >
            <Box sx={{ width: '500px', bgcolor: 'background.paper', p: 3 }}>
                <img className='close_icon' src={cancel} alt='close' onClick={onHide} style={{ cursor: 'pointer' }} />
                <Box>
                    <h4 className='header-graph' variant='h6'>
                        {t('add_graph')}
                    </h4>
                </Box>
                <Box mt={2}>
                    <h5 variant='body1' className='label-graph'>
                        {t('choose_a_data')}
                    </h5>
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id='data-select-label'>{t('data')}</InputLabel>
                            <Select
                                size='small'
                                required
                                labelId='data-select-label'
                                id='slt_agm_data'
                                label={t('data')}
                                value={selectedChartType}
                                onChange={(e) => {
                                    handleChartTypeChange(e);
                                }}
                            >
                                {Object.entries(chartComponents).map(([key, value]) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                            {t(value)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box mt={2}>
                    <h5 variant='body1' className='label-graph'>
                        {t('choose_a_graph')}
                    </h5>
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id='graph-select-label'>{t('graph')}</InputLabel>
                            <Select
                                required
                                size='small'
                                labelId='graph-select-label'
                                id='slt_agm_graph'
                                value={selectedGraph}
                                onChange={handleGraphChange}
                                label={t('graph')}
                            >
                                {availableCharts?.map((chart) => {
                                    return (
                                        <MenuItem key={chart.id} value={chart.id}>
                                            {t(chart.title)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <div className='d-flex mt-3'>
                    <button
                        style={{ width: '180px', height: '30px' }}
                        onClick={onHide}
                        id='btn_agm_cancel'
                        className='custom_btn_cancel_gray_hover'
                    >
                        {t('cancel')?.toUpperCase()}
                    </button>
                    <button
                        style={{ width: '180px', height: '30px' }}
                        className='custom_primary_btn_dark'
                        onClick={handleCreate}
                        disabled={isCreateButtonDisabled}
                        id='btn_agm_create'
                    >
                        {t('create')?.toUpperCase()}
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default AddGraphModal;
