import React, { useEffect, useRef } from 'react';
import { Circle, Transformer } from 'react-konva';

const Ellipsis = ({
    ellipsis,
    index,
    selectedShapeId,
    handleDragMove,
    handleShapeClick,
    currentStage,
    setStageProperties,
    setShapeRadius,
    cardWidth,
    cardHeight,
}) => {
    const shapeRef = useRef(null);
    const trRef = useRef(null);
    const isSelected = ellipsis.id === selectedShapeId;
    const MAX_RADIUS = 305;

    useEffect(() => {
        if (selectedShapeId === ellipsis.id) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedShapeId, ellipsis.id]);

    const handleDragMoveWithLimit = (e, index) => {
        const shape = e.target;
        const radius = shape.radius();
        const stage = shape.getStage();
        const width = stage.width();
        const height = stage.height();
        let x = shape.x();
        let y = shape.y();
        x = Math.round(x);
        y = Math.round(y);
        // Limitar la posición para que no sobresalga del stage
        if (x - radius < 0) x = radius;
        if (x + radius > width) x = width - radius;
        if (y - radius < 0) y = radius;
        if (y + radius > height) y = height - radius;

        shape.x(x);
        shape.y(y);

        handleDragMove(e, index);
    };

    const handleTransformEnd = (e) => {
        e.preventDefault();
        const node = shapeRef.current;
        const stage = node.getStage();
        const currentRadius = node.radius();
        const newRadius = Math.max(node.radius() * node.scaleX(), node.radius() * node.scaleY());

        let x = node.x();
        let y = node.y();

        // Limitar el tamaño para que no sobresalga del tamaño de la tarjeta
        const maxRadiusX = Math.min(x, stage.width() - x);
        const maxRadiusY = Math.min(y, stage.height() - y);
        const maxRadius = Math.min(maxRadiusX, maxRadiusY);
        const finalRadius = Math.min(newRadius, maxRadius);
        // Restablecer la escala
        node.scaleX(1);
        node.scaleY(1);

        setShapeRadius(finalRadius);
        setStageProperties((prevProperties) => {
            const currentProperties = prevProperties[currentStage];
            const updatedEllipsis = [...currentProperties.combinedObject];
            updatedEllipsis[index] = {
                ...updatedEllipsis[index],
                radius: finalRadius,
                x: node.x(),
                y: node.y(),
                rotation: node.rotation(),
            };

            return {
                ...prevProperties,
                [currentStage]: {
                    ...currentProperties,
                    combinedObject: updatedEllipsis,
                },
            };
        });
    };

    return (
        <>
            <Circle
                x={ellipsis.x}
                y={ellipsis.y}
                rotation={ellipsis.rotation}
                radius={ellipsis.radius}
                fill={ellipsis.fill}
                stroke={ellipsis.stroke}
                strokeWidth={ellipsis.strokeWidth}
                id={ellipsis.id}
                ref={shapeRef}
                draggable={isSelected}
                onDragMove={(e) => handleDragMoveWithLimit(e, index)}
                onClick={() => handleShapeClick(ellipsis.id, 'ellipsis')}
                onTap={() => handleShapeClick(ellipsis.id, 'ellipsis')}
                onTransformEnd={handleTransformEnd}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={true}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width > MAX_RADIUS || newBox.height > MAX_RADIUS) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};

export default Ellipsis;
