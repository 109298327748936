import { fabClasses } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';

const FilesSlice = createSlice({
    name: 'files',
    initialState: {
        exportPDFSimple: {},
        exportDataPDF: {},
        exportDataPDFCustom: {},
        exportExcelSimple: {},
        exportDataExcel: {},
        exportDataExcelCustom: {},
        downloadEmployeeForm: [],
        uploadFormFile: null,
        createListEmployee: null,
        excelFile: {},
        getExcelHeadersByOption: {},
    },
    reducers: {
        setExcelFile: (state, action) => {
            state.excelFile = action.payload;
        },
    },
    extraReducers: {
        // PDF SIMPLE
        'files/exportPDFSimple/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.exportPDFSimple = data;
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_downloading').toUpperCase());
            }
        },
        // EXCEL SIMPLE
        'files/exportExcelSimple/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.exportExcelSimple = data;
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_downloading').toUpperCase());
            }
        },
        // EXCEL options GetExcelHeadersByOption
        'files/getExcelHeadersByOption/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getExcelHeadersByOption = data;
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_downloading').toUpperCase());
            }
        },
        'files/exportDataPDF/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.exportDataPDF = data;
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_downloading').toUpperCase());
            }
        },
        'files/exportDataPDFCustom/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.exportDataPDFCustom = data;
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_downloading').toUpperCase());
            }
        },
        'files/exportDataExcel/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.exportDataExcel = data;
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_downloading').toUpperCase());
            }
        },
        'files/exportDataExcelCustom/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.exportDataExcelCustom = data;
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_downloading').toUpperCase());
            }
        },
        'files/downloadEmployeeForm/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.downloadEmployeeForm = data;
            } else if (status >= 400 && status < 600) {
                toast.error(t('error_downloading').toUpperCase());
            }
        },
        'files/uploadFormFile/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.uploadFormFile = data;
            } else if (status >= 400 && status < 600) {
            }
        },
        'files/createListEmployee/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createListEmployee = data;
            } else if (status >= 400 && status < 600) {
            }
        },
    },
});
export const { setExcelFile } = FilesSlice.actions;
export default FilesSlice.reducer;
