import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    TextField,
    InputAdornment,
    Tooltip,
    Checkbox,
    Button,
    FormHelperText,
} from '@mui/material';
import React, { useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TimePicker from '@mui/lab/TimePicker';
import { useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import ic_edit from '../../../assets/icon/ic-edit-pencil.svg';
import { useDispatch, useSelector } from 'react-redux';

import {
    AddDeviceToListPayroll,
    AddEmployeesToListPayroll,
    ChangeTimeAndSelectedDaysUpdate,
    GetAllDevicePayroll,
    GetAllEmployeesPayroll,
    GetAllSelectedDevice,
    GetAllSelectedEmployees,
    GetEmailColor,
    GetEmailSetting,
    RemoveDeviceAttendance,
    RemoveEmployeeAttendance,
    UpdateAttendanceColor,
} from '../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import { toast } from 'react-toastify';
import {
    ClearDeleteDeviceToListPayroll,
    ClearDeleteEmployeesToListPayroll,
} from '../../../reduxToolkit/EmployeePayRoll/EmployeePayRollSlice';
import { permissionObj } from '../../../Helpers/permission';
import { t } from 'i18next';
import { LocalizationProvider } from '@mui/lab';
import Cookies from 'js-cookie';
import useStyle from '../../../hooks/useStyle';
import CustomTextWithLine from '../../../components/CustomTextWithLine';
import ClearButton from '../../../components/ClearButton';
import DeleteModal from '../../Modals/DeleteModal';
import AutoCompleteSearch from '../../../components/AutoCompleteSearch';
import entry_ic from '../../../assets/images/arrow-up-solid-image.png';
import exit_ic from '../../../assets/images/arrow-down-solid-image.png';
import both_ic from '../../../assets/images/ic-both-entries-image.png';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import NotInformation from '../../../components/NotInformation';
import ColorPicker from '../../../components/ColorPicker';
import { Schedule } from '@mui/icons-material';
import ScheduleSection from './ScheduleSection';

const ManageAttendence = () => {
    const dispatch = useDispatch();
    const lCode = Cookies.get('i18next') || 'en';

    const { getAllEmployeesPayroll } = useSelector((state) => state.employeePayrollSlice);
    const { getAllSelectedEmployees } = useSelector((state) => state.employeePayrollSlice);
    const { addEmployeesToListPayroll } = useSelector((state) => state.employeePayrollSlice);

    const { getAllDevicePayroll } = useSelector((state) => state.employeePayrollSlice);
    const { getAllSelectedDevice } = useSelector((state) => state.employeePayrollSlice);
    const { addDeviceToListPayroll } = useSelector((state) => state.employeePayrollSlice);
    const { getEmailSetting, removeEmployeeAttendance, removeDeviceAttendance } = useSelector(
        (state) => state.employeePayrollSlice,
    );
    const { changeTimeAndSelectedDaysUpdate } = useSelector((state) => state.employeePayrollSlice);
    const [formDate, setFormDate] = useState('1970-01-01T00:00:00');
    const [device, setDevice] = useState('');
    const [employee, setEmployee] = useState('');
    const [pageIncoming, setPageIncoming] = useState(0);
    const [rowsPerPageIncoming, setRowsPerPageincoming] = useState(10);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const { textField, smallBoxStyle } = useStyle();
    const [isAllCheckedDevice, setIsAllCheckedDevice] = useState(false);
    const [isAllCheckedEmployee, setIsAllCheckedEmployee] = useState(false);
    const [selectedDeviceForDelete, setSelectDeviceForDelete] = useState([]);
    const [selectEmployeeForDelete, setSelectEmployeeForDelete] = useState([]);
    const [deleteShowDevice, setDeleteShowDevice] = useState(false);
    const [deleteShowEmployee, setDeleteShowEmployee] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [startShiftAt, setStartShiftAt] = useState(null);
    const [endShiftAt, setEndShiftAt] = useState(null);
    const [dayArray, setDayArray] = useState([]);
    const [range, setRange] = useState(0);
    const [dayAgoToSendEmail, setDayAgoToSendEmail] = useState(0);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const [selectedOption, setSelectedOption] = useState('day');
    const [frequency, setFrequency] = useState({
        daily: true,
        weekly: false,
        biweekly: false,
        monthly: false,
    });

    const [dayOfWeek, setDayOfWeek] = useState('');
    const [endDate, setEndDate] = useState(0);
    const [startDate, setStartDate] = useState(0);
    const [inTimeColor, setInTimeColor] = useState('#008435');
    const [toleranceTimeColor, setToleranceTimeColor] = useState('#FFC450');
    const [afterHoursColor, setAfterHoursColor] = useState('#BC0000');
    const [absence, setAbsence] = useState('#BC0000');
    const [dayOfMonth, setDayOfMonth] = useState('');

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        if (event.target.value !== 'week') {
            setStartDate(null);
            setEndDate(null);
        }
        setFrequency({
            daily: value === 'day',
            weekly: value === 'week',
            biweekly: value === 'biweekly',
            monthly: value === 'month',
        });
    };
    useEffect(() => {
        if (getEmailSetting) {
            if (getEmailSetting.daily) {
                setSelectedOption('day');
            } else if (getEmailSetting.weekly) {
                setSelectedOption('week');
            } else if (getEmailSetting.biweekly) {
                setSelectedOption('biweekly');
            } else if (getEmailSetting.monthly) {
                setSelectedOption('month');
            }
        }
    }, [getEmailSetting]);
    // this function control select all id or unSelect all
    const handelDeleteAllDevice = (e) => {
        setIsAllCheckedDevice(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getAllSelectedDevice?.map((item) => {
                return item?.id;
            });
            setSelectDeviceForDelete(selectAllIds);
        } else {
            setSelectDeviceForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChangeDevice = (e) => {
        if (e.target.checked) {
            setSelectDeviceForDelete([...selectedDeviceForDelete, e.target.id]);
        } else {
            setSelectDeviceForDelete(selectedDeviceForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // this function control select all id or unSelect all
    const handelDeleteAllEmployee = (e) => {
        setIsAllCheckedEmployee(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getAllSelectedEmployees?.content?.map((item) => {
                return item?.id;
            });
            setSelectEmployeeForDelete(selectAllIds);
        } else {
            setSelectEmployeeForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChangeEmployee = (e) => {
        if (e.target.checked) {
            setSelectEmployeeForDelete([...selectEmployeeForDelete, e.target.id]);
        } else {
            setSelectEmployeeForDelete(selectEmployeeForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };
    // delete device
    const deleteDevice = (deleteItem) => {
        const body = deleteItem;
        dispatch(RemoveDeviceAttendance(body))?.then(() => {
            dispatch(ClearDeleteDeviceToListPayroll());
        });
    };
    // delete employee
    const deleteEmployee = (deleteItem) => {
        const body = deleteItem;
        dispatch(RemoveEmployeeAttendance(body))?.then(() => {
            dispatch(ClearDeleteEmployeesToListPayroll());
        });
    };

    // clear all state
    const clearAllState = () => {
        setFormDate('1970-01-01T00:00:00');
        setDayAgoToSendEmail(0);
        setEndDate(0);
        setStartDate(0);
        setDayOfWeek('');
        setDayOfMonth('');
    };

    // set page if change detect
    const handleChangePageIcoming = (event, newPage) => {
        setPageIncoming(newPage);
    };
    // set row per page if change detect
    const handleChangeRowsPerPageIncoming = (event) => {
        setRowsPerPageincoming(parseInt(event.target.value));
        setPageIncoming(0);
    };

    // handel the logic for object transformation for all user

    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.name || '') +
                    ' ' +
                    (item?.firstLastName || '') +
                    (item?.secondLastName ? ' ' + item.secondLastName : ''),
                id: item?.id || item?.userId,
            };
        });
        return newData;
    };

    // handel the logic for object transformation for all user

    const userDataTransformUser = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.name || ''} ${item?.firstLastName || ''} ${item?.secondLastName || ''}`.trim(),
                id: item?.id || item?.userId,
            };
        });
        return newData;
    };

    // funtion to handel add employee
    const handleAddEmployee = (e) => {
        // Find the employee that already Selected
        const checkUserList = getAllSelectedEmployees.content.find((employeeList) => {
            return employeeList?.id === e?.id;
        });

        //  payload
        const data = {
            user: {
                id: e?.id,
            },
        };
        // codition to notify and dispatch API
        if (checkUserList != undefined) {
            toast.warn(
                `${checkUserList?.name || ''} ${checkUserList?.firstLastName || ''} ${
                    checkUserList?.secondLastName || ''
                } already Selected`,
            );
        } else {
            dispatch(AddEmployeesToListPayroll(data));
        }
    };

    // function to handel add device
    const handelAddDevice = (e) => {
        const checkDeviceList = getAllSelectedDevice.find((deviceList) => {
            return deviceList?.id === e?.id;
        });

        //  payload
        const data = {
            device: {
                id: e?.id,
            },
        };
        // codition to notify and dispatch API
        if (checkDeviceList != undefined) {
            toast.warn(`${checkDeviceList?.name} already Selected`);
        } else {
            dispatch(AddDeviceToListPayroll(data));
        }
    };

    // function to handle time and checkbox
    const handelUpdateTimeAndCheckbox = () => {
        const data = {
            arrivalDays: dayAgoToSendEmail,
            biweekly: frequency.biweekly,
            daily: frequency.daily,
            email: getEmailSetting?.email,
            firstDay: startDate || 0,
            id: getEmailSetting?.id,
            imapHost: getEmailSetting?.imapHost,
            monthly: frequency.monthly,
            password: getEmailSetting?.password,
            port: getEmailSetting?.port,
            secondDay: endDate || 0,
            sendDay:
                selectedOption === 'week'
                    ? dayOfWeek
                    : selectedOption === 'month'
                      ? dayOfMonth
                      : selectedOption === 'day'
                        ? dayAgoToSendEmail
                        : 0,
            time: formDate?.toLocaleTimeString('it-IT'),
            timeZone: `GMT-${formDate?.toLocaleTimeString('it-IT')}`,
            weekly: frequency.weekly,
        };

        const attendanceBody = {
            absence: absence,
            delay: 'string',
            id: 0,
            effectiveTimeOver: afterHoursColor,
            effectiveTimeUnder: toleranceTimeColor,
            onTime: inTimeColor,
        };

        Promise.all([
            dispatch(ChangeTimeAndSelectedDaysUpdate(data)),
            dispatch(UpdateAttendanceColor(attendanceBody)),
        ]).then(([timeUpdateResponse, attendanceResponse]) => {
            const timeUpdateSuccess = timeUpdateResponse.payload.data.success;
            const attendanceSuccess = attendanceResponse.payload.data.success;

            if (timeUpdateSuccess && attendanceSuccess) {
                toast.success(t('has_been_added_successfully'));
            } else {
                toast.error(t('error_updating').toUpperCase());
            }
        });
    };

    // dispatch to get result
    useEffect(() => {
        dispatch(GetAllDevicePayroll());
        dispatch(GetAllEmployeesPayroll());
    }, []);

    //useEffect to set the time and checkbox
    useEffect(() => {
        const allDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        // Use the filter function to get the enabled days
        const enabledDays = allDays.filter((day) => getEmailSetting?.[day]);
        setDayArray(enabledDays);
        setFormDate(new Date(`2014-08-18T${getEmailSetting?.time}`) || '');
        setDayAgoToSendEmail(getEmailSetting?.daysToSendEmail);
        setStartShiftAt(new Date(`2014-08-18T${getEmailSetting?.startShiftAt}`) || '');
        setEndShiftAt(new Date(`2014-08-18T${getEmailSetting?.endShiftAt}`) || '');
        setRange(getEmailSetting?.range);
    }, [getEmailSetting, changeTimeAndSelectedDaysUpdate]);

    // dispatch emloyee selected once and re dispatch base on add or delete
    useEffect(() => {
        const pagination = {
            order: sortBy === 'asc' ? true : false,
            page: pageIncoming,
            size: rowsPerPageIncoming,
            sortBy: orderBy ? orderBy : 'id',
        };
        dispatch(GetAllSelectedEmployees(pagination));
    }, [addEmployeesToListPayroll, removeEmployeeAttendance, pageIncoming, rowsPerPageIncoming]);

    // dispatch Device selected once and re dispatch base on add or delete
    useEffect(() => {
        dispatch(GetAllSelectedDevice());
    }, [addDeviceToListPayroll, removeDeviceAttendance]);

    useEffect(() => {
        dispatch(GetEmailSetting());
    }, [changeTimeAndSelectedDaysUpdate]);

    const [maxWidth, setMaxWidth] = useState('100px'); // Valor inicial

    useEffect(() => {
        // Función para actualizar el maxWidth basado en el tamaño del contenedor padre
        const updateMaxWidth = () => {
            const containerWidth = document.getElementById('container-id').clientWidth; // Cambia 'container-id' al ID real
            const calculatedMaxWidth = Math.min(containerWidth * 0.25, 250); // Ejemplo de cálculo
            setMaxWidth(`${calculatedMaxWidth}px`);
        };

        // Llama a la función al montar el componente y cuando cambia el tamaño del contenedor
        updateMaxWidth();
        window.addEventListener('resize', updateMaxWidth);

        // Limpia el evento al desmontar el componente
        return () => {
            window.removeEventListener('resize', updateMaxWidth);
        };
    }, []);
    useEffect(() => {
        dispatch(GetEmailColor()).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                {
                    setToleranceTimeColor(data.effectiveTimeUnder);
                    setAfterHoursColor(data.effectiveTimeOver);
                    setInTimeColor(data.onTime);
                    setAbsence(data.absence);
                }
            },
        );
    }, [dispatch]);

    useEffect(() => {
        if (getEmailSetting) {
            setDayAgoToSendEmail(getEmailSetting.sendDay);
        }
    }, [getEmailSetting]);
    useEffect(() => {
        if (getEmailSetting) {
            const { sendDay, weekly } = getEmailSetting;
            if (weekly) {
                setDayOfWeek(sendDay);
            }
        }
    }, [getEmailSetting]);

    useEffect(() => {
        if (getEmailSetting) {
            const { sendDay, monthly } = getEmailSetting;
            if (monthly) {
                setDayOfMonth(sendDay);
            }
        }
    }, [getEmailSetting]);
    useEffect(() => {
        if (getEmailSetting) {
            const { firstDay, secondDay, biweekly } = getEmailSetting;
            if (biweekly) {
                setStartDate(firstDay);
                setEndDate(secondDay);
            }
        }
    }, [getEmailSetting]);

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={'/dashboard/employee/attendance'} />
                    <h2>{t('manage_attendance')}</h2>
                </div>{' '}
                <button
                    onClick={() => {
                        handelUpdateTimeAndCheckbox();
                    }}
                    className='add-btn-1'
                    id='btn_ma_update'
                >
                    <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                    {t('update')}
                </button>
            </div>
            <div className='manage_attendence_container'>
                <ScheduleSection />
                <div sx={{ paddingTop: '1rem' }} className='pt-4 pb-4'>
                    <CustomTextWithLine title={t('colors')} />
                    <div container paddingTop={'1rem'} paddingBottom='1.5rem' className='row p-4'>
                        <div item xs={12} className='col-xl-12'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} sx={{ width: '330px' }}>
                                    <FormControl fullWidth>
                                        <FormLabel className='label-color'>{t('in_time')?.toUpperCase()}</FormLabel>
                                        <ColorPicker
                                            id='clp_ma_colorInTime'
                                            initialColor={inTimeColor}
                                            getColor={setInTimeColor}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ width: '330px' }}>
                                    <FormControl fullWidth>
                                        <FormLabel className='label-color'>
                                            {t('tolerance_time')?.toUpperCase()}
                                        </FormLabel>
                                        <ColorPicker
                                            id='clp_ma_colorInTime'
                                            initialColor={toleranceTimeColor}
                                            getColor={setToleranceTimeColor}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ width: '330px' }}>
                                    <FormControl fullWidth>
                                        <FormLabel className='label-color'>{t('after_hours')?.toUpperCase()}</FormLabel>
                                        <ColorPicker
                                            id='clp_ma_colorInTime'
                                            initialColor={afterHoursColor}
                                            getColor={setAfterHoursColor}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ width: '330px' }}>
                                    <FormControl fullWidth>
                                        <FormLabel className='label-color'>{t('abscence')?.toUpperCase()}</FormLabel>
                                        <ColorPicker
                                            id='clp_ma_colorInTime'
                                            initialColor={absence}
                                            getColor={setAbsence}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>
                        <div item xs={12} className='col-xl-12'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} sx={{ width: '330px' }}>
                                    <FormControl fullWidth>
                                        <div className='color-preview' style={{ backgroundColor: inTimeColor }}>
                                            <span>{t('time_format')?.toUpperCase()}</span>
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ width: '330px' }}>
                                    <FormControl fullWidth>
                                        <div className='color-preview' style={{ backgroundColor: toleranceTimeColor }}>
                                            {' '}
                                            <span>{t('time_format')?.toUpperCase()}</span>
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ width: '330px' }}>
                                    <FormControl fullWidth>
                                        <div className='color-preview' style={{ backgroundColor: afterHoursColor }}>
                                            {' '}
                                            <span>{t('time_format')?.toUpperCase()}</span>
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ width: '330px' }}>
                                    <FormControl fullWidth>
                                        <div className='color-preview' style={{ backgroundColor: absence }}>
                                            {' '}
                                            <span>{t('time_format')?.toUpperCase()}</span>
                                        </div>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div sx={{ paddingTop: '1rem' }} className='pt-4 pb-4'>
                    <CustomTextWithLine title={t('email_settings')} />
                    <Box sx={{ paddingBottom: '1rem' }}>
                        <ClearButton
                            flagTooltip={true}
                            textTooltip={t('clear_all_inputs')}
                            handleClear={clearAllState}
                        />
                    </Box>
                    <div container paddingTop={'1rem'} paddingBottom='1.5rem' className='row p-4'>
                        <div item xs={12} className='col-xl-12'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} sx={{ width: '450px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                            {t('type')}
                                        </InputLabel>
                                        <Select
                                            id='slt_ma_type'
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            value={selectedOption}
                                            label={t('type')}
                                            onChange={handleSelectChange}
                                            style={{ width: '100%' }}
                                        >
                                            <MenuItem value='day'>{t('daily').toUpperCase()}</MenuItem>
                                            <MenuItem value='week'>{t('weekly').toUpperCase()}</MenuItem>
                                            <MenuItem value='month'>{t('monthly').toUpperCase()}</MenuItem>
                                            <MenuItem value='biweekly'>{t('biweekly').toUpperCase()}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ width: '450px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            renderInput={(props) => <TextField size='small' {...props} />}
                                            ampm={false}
                                            openTo='hours'
                                            id='tp_ma_time'
                                            views={['hours', 'minutes', 'seconds']}
                                            inputFormat='HH:mm:ss'
                                            mask='__:__:__'
                                            label={t('time')?.toUpperCase()}
                                            defaultValue={new Date(`2014-08-18T${getEmailSetting?.time}`)}
                                            value={formDate}
                                            onChange={(date) => setFormDate(date)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                {selectedOption === 'day' && (
                                    <Grid item xs={12} sm={4} sx={{ width: '450px' }}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            className='helper_color'
                                            type='number'
                                            id='txt_ma_daysAgoToSendTheEmail'
                                            label={t('day_after_to_send_the_email')}
                                            helperText={t('leave_0_to_avoid_send_it')?.toUpperCase()}
                                            value={dayAgoToSendEmail}
                                            onChange={(e) => {
                                                let newValue = e.target.value;
                                                // Constrain the value between 0 and 10
                                                newValue = Math.max(0, Math.min(newValue, 10));
                                                setDayAgoToSendEmail(newValue);
                                            }}
                                            InputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                                                            {' '}
                                                            {t('days')?.toUpperCase()}
                                                        </span>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={textField}
                                        />
                                    </Grid>
                                )}
                                {selectedOption === 'week' && (
                                    <Grid item xs={12} sm={4} sx={{ width: '450px' }}>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id='select-day-label'>{t('select_day_of_week')}</InputLabel>
                                            <Select
                                                labelId='select-day-label'
                                                id='slt_ma_dayOfWeek'
                                                value={dayOfWeek}
                                                onChange={(e) => setDayOfWeek(e.target.value)}
                                                label={t('select_day_of_week')}
                                            >
                                                <MenuItem value={1}>{t('monday').toUpperCase()}</MenuItem>
                                                <MenuItem value={2}>{t('tuesday').toUpperCase()}</MenuItem>
                                                <MenuItem value={3}>{t('wednesday').toUpperCase()}</MenuItem>
                                                <MenuItem value={4}>{t('thursday').toUpperCase()}</MenuItem>
                                                <MenuItem value={5}>{t('friday').toUpperCase()}</MenuItem>
                                                <MenuItem value={6}>{t('saturday').toUpperCase()}</MenuItem>
                                                <MenuItem value={7}>{t('sunday').toUpperCase()}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormHelperText>{t('select_a_day_to_send_email')}</FormHelperText>
                                    </Grid>
                                )}
                                {selectedOption === 'month' && (
                                    <Grid item xs={12} sm={4} sx={{ width: '450px' }}>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id='select-day-label'>{t('select_day_of_month')}</InputLabel>
                                            <Select
                                                labelId='select-day-label'
                                                id='slt_ma_dayOfMonth'
                                                value={dayOfMonth}
                                                onChange={(e) => setDayOfMonth(e.target.value)}
                                                label={t('select_day_of_month')}
                                            >
                                                {[...Array(28)].map((_, index) => (
                                                    <MenuItem key={index + 1} value={index + 1}>
                                                        {index + 1}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormHelperText>{t('select_a_day_to_send_email')}</FormHelperText>
                                    </Grid>
                                )}
                                {selectedOption === 'biweekly' && (
                                    <Grid item xs={12} sm={6} sx={{ width: '450px' }}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            label={t('first_day')}
                                            type='number'
                                            id='txt_ma_firstDay'
                                            value={startDate}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                if (value === '' || (Number(value) >= 1 && Number(value) <= 15)) {
                                                    setStartDate(value);
                                                }
                                            }}
                                            inputProps={{
                                                min: 1,
                                                max: 15,
                                                pattern: '[0-9]*',
                                            }}
                                            sx={{ height: '40px' }}
                                            helperText={t('recommended_first_day') + ' (1 - 15)'}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </div>
                    <div container paddingBottom='1.5rem' className='row'>
                        <div item xs={12} className='col-xl-12'>
                            <Grid container spacing={2} style={{ marginLeft: '10px' }}>
                                {selectedOption === 'biweekly' && (
                                    <>
                                        <Grid item xs={12} sm={6} sx={{ width: '450px' }}>
                                            <TextField
                                                size='small'
                                                fullWidth
                                                id='txt_ma_secondDay'
                                                variant='outlined'
                                                label={t('second_day')}
                                                type='number'
                                                value={endDate}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    if (value === '' || (Number(value) >= 16 && Number(value) <= 31)) {
                                                        setEndDate(value);
                                                    }
                                                }}
                                                inputProps={{
                                                    min: 16,
                                                    max: 31,
                                                    pattern: '[0-9]*',
                                                }}
                                                sx={{ height: '40px' }}
                                                helperText={t('recommended_second_day') + ' (16 - 31)'}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </div>
                    </div>
                </div>

                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
            </div>

            <div container className='row p-2'>
                <div item xs={8} className='receiver col-xl-6 centerlineVertical'>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <h4 className='devices-tittle'>{t('devices_attendance')}</h4>
                        {permission?.includes(permissionObj?.WEB_ATTENDANCE_DELETE) && (
                            <button
                                className='delete-btn-1'
                                id='btn_ma_delete'
                                disabled={selectedDeviceForDelete?.length === 0}
                                onClick={() => {
                                    setDeleteShowDevice(true);
                                }}
                            >
                                <i className='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </Stack>
                    <Box marginTop='1rem' marginBottom='1rem' sx={smallBoxStyle}>
                        <AutoCompleteSearch
                            data={userDataTransform(getAllDevicePayroll)}
                            handleFn={(e) => handelAddDevice(e)}
                            value={device}
                            setValue={setDevice}
                        />
                    </Box>
                    <div className='panelTables w-100 animated-div px-1'>
                        <table className='w-100' id='container-id'>
                            {' '}
                            {getAllSelectedDevice?.length > 0 ? (
                                <thead className='no-border-thead'>
                                    <th className='first_head'>
                                        <Tooltip
                                            title={t('de_/_select_all').toUpperCase()}
                                            placement='top'
                                            disableInteractive
                                        >
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={selectedDeviceForDelete?.length > 0}
                                                indeterminate={
                                                    selectedDeviceForDelete?.length > 0 &&
                                                    getAllSelectedDevice?.length !== selectedDeviceForDelete?.length
                                                }
                                                onChange={handelDeleteAllDevice}
                                                size='small'
                                                id='cb_ma_select'
                                            />
                                        </Tooltip>{' '}
                                        {t('data')?.toUpperCase()}
                                    </th>

                                    <th className='type-device'>{t('type')?.toUpperCase()}</th>
                                </thead>
                            ) : null}
                            <tbody>
                                {getAllSelectedDevice?.length > 0 ? (
                                    getAllSelectedDevice?.map((device, index) => (
                                        <tr key={device?.id || index}>
                                            <td
                                                className='first'
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'row',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <Checkbox
                                                    className='grid-checkall checkbox'
                                                    checked={selectedDeviceForDelete?.includes(device?.id)}
                                                    id={device?.id}
                                                    onChange={handleCheckboxChangeDevice}
                                                    size='small'
                                                />{' '}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <p>
                                                        {t('zone')}:{' '}
                                                        <span className='devices_zone'>{device?.zoneName || '-'}</span>
                                                    </p>
                                                    <p>
                                                        {t('name')}:{' '}
                                                        <span className='devices_zone'>{device?.name}</span>
                                                    </p>
                                                </div>
                                            </td>

                                            <td>
                                                <Tooltip
                                                    title={
                                                        (device?.deviceAccessTypeId === 3 &&
                                                            t('entry_exit').toUpperCase()) ||
                                                        (device?.deviceAccessTypeId === 2 && t('exit').toUpperCase()) ||
                                                        (device?.deviceAccessTypeId === 1 && t('entry').toUpperCase())
                                                    }
                                                    placement='left'
                                                    arrow
                                                    disableInteractive
                                                >
                                                    <img
                                                        src={
                                                            (device?.deviceAccessTypeId === 1 && entry_ic) ||
                                                            (device?.deviceAccessTypeId === 2 && exit_ic) ||
                                                            (device?.deviceAccessTypeId === 3 && both_ic) ||
                                                            '-'
                                                        }
                                                        alt='access type'
                                                        style={{ width: '14px', height: '16px' }}
                                                    />
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan='3'
                                            style={{
                                                backgroundColor: '#FCFCFC',
                                            }}
                                        >
                                            <NotInformation text={t('no_information')} card={true} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* receiver section */}
                <div item xs={8} className='receiver col-xl-6'>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <h4 className='devices-tittle'> {t('send_to')}</h4>
                        {permission?.includes(permissionObj?.WEB_ATTENDANCE_DELETE) && (
                            <button
                                className='delete-btn-1'
                                disabled={selectEmployeeForDelete?.length === 0}
                                onClick={() => {
                                    setDeleteShowEmployee(true);
                                }}
                                id='btn_ma_delete'
                            >
                                <i className='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </Stack>

                    {/* table */}
                    <Box marginTop='1rem' marginBottom='1rem' sx={smallBoxStyle}>
                        {/* <FormControl
                                fullWidth
                                size='small'
                                sx={textField}
                            >
                                <InputLabel>{t("employee")}</InputLabel>
                                <Select
                                    size="small"
                                    // value={employee}
                                    label="EMPLOYEE"
                                    onChange={(e) => handleAddEmployee(e)}
                                >
                                    {
                                        getAllEmployeesPayroll?.map(employee => (

                                            <MenuItem key={employee?.userId} value={employee?.userId}>{employee?.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl> */}

                        <AutoCompleteSearch
                            data={userDataTransformUser(getAllEmployeesPayroll)}
                            handleFn={(e) => handleAddEmployee(e)}
                            value={employee}
                            setValue={setEmployee}
                        />
                    </Box>
                    <div className='panelTables w-100 animated-div px-1'>
                        <table className='w-100' id='container-id'>
                            {getAllSelectedEmployees?.content?.length > 0 ? (
                                <thead className='no-border-thead'>
                                    <th className='first_head'>
                                        <Tooltip
                                            title={t('de_/_select_all').toUpperCase()}
                                            placement='top'
                                            disableInteractive
                                        >
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={selectEmployeeForDelete?.length > 0}
                                                indeterminate={
                                                    selectEmployeeForDelete?.length > 0 &&
                                                    getAllSelectedEmployees?.content?.length !==
                                                        selectEmployeeForDelete?.length
                                                }
                                                onChange={handelDeleteAllEmployee}
                                                size='small'
                                            />
                                        </Tooltip>
                                        {t('name').toUpperCase()}
                                    </th>
                                    <th className='th-devices'>{t('email').toUpperCase()}</th>
                                    <th className='th-devices'>{t('employee_phone_number').toUpperCase()}</th>
                                    {/* <th>{t("privilege")}</th> */}
                                    {/* <th className='last'>options</th> */}
                                </thead>
                            ) : null}

                            <tbody className='table_body'>
                                {getAllSelectedEmployees?.content?.length > 0 ? (
                                    getAllSelectedEmployees?.content?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td
                                                    className='first'
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={selectEmployeeForDelete?.includes(item?.id)}
                                                        id={item?.id}
                                                        onChange={handleCheckboxChangeEmployee}
                                                        size='small'
                                                    />
                                                    {/* <span id='span_container' style={{ textTransform: 'none' }} title={ span_container.scrollWidth > span_container.clientWidth && ' '}> */}
                                                    {`${item?.name || ''} ${item?.lastName || ''} ${
                                                        item?.secondLastName || ''
                                                    }`.trim()}
                                                    {/* </span> */}
                                                </td>
                                                <td>{item?.email}</td>
                                                <td className='last'>{item?.phoneNumber}</td>
                                                {/* <td>{item?.role}</td> */}

                                                {/* <td onClick={() => {
                                                            setDeleteId(item?.id);
                                                            setDeleteShow(true);
                                                            setIsState('employee')
                                                            setEname(item?.name)
                                                        }}><i className="fa fa-trash" aria-hidden="true"></i></td> */}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            colSpan='10'
                                            style={{
                                                backgroundColor: '#FCFCFC',
                                            }}
                                        >
                                            <NotInformation text={t('no_information')} card={true} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* <DeleteDeviceModal
                            show={deleteShow}
                            onHide={() => setDeleteShow(false)}
                            deleteid={deleteId}
                            isState={isState}
                            name={eName}



                        /> */}
                    {/* pagination */}
                    {getAllSelectedEmployees?.content?.length > 0 ? (
                        <div className='payroll_pagination d-flex justify-content-center'>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={[10, 15, 20, 30]}
                                count={getAllSelectedEmployees?.totalElements}
                                page={pageIncoming}
                                onPageChange={handleChangePageIcoming}
                                labelRowsPerPage={t('employees_per_page')}
                                rowsPerPage={rowsPerPageIncoming}
                                onRowsPerPageChange={handleChangeRowsPerPageIncoming}
                            />
                        </div>
                    ) : null}
                </div>
            </div>

            <DeleteModal
                show={deleteShowDevice}
                onHide={() => setDeleteShowDevice(false)}
                data={selectedDeviceForDelete}
                onClickFn={() => deleteDevice(selectedDeviceForDelete)}
                title_modal={t('manage_attendance')}
                element_modal={t('device')}
                isReset={setSelectDeviceForDelete}
                isAllReset={setIsAllCheckedDevice}
            />
            <DeleteModal
                show={deleteShowEmployee}
                onHide={() => setDeleteShowEmployee(false)}
                data={selectEmployeeForDelete}
                onClickFn={() => deleteEmployee(selectEmployeeForDelete)}
                title_modal={t('manage_attendance')}
                element_modal={t('employee')}
                isReset={setSelectEmployeeForDelete}
                isAllReset={setIsAllCheckedEmployee}
            />
        </>
    );
};

export default ManageAttendence;
const inputBox = {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    height: '50px',
};
