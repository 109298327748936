import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Material Ui Components
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

// images
import exchangealt from '../../../../assets/images/exchange-alt-solid.svg';
import person4 from '../../../../assets/images/user-png.png';
import person5 from '../../../../assets/images/user-png-140.png';
import userregular from '../../../../assets/images/user-regular.svg';

// modals
import NewCard from './Modal/NewCard';
import ChangeImage from './Modal/ChangeImage';
import { getUserByEmail, createEmployee, preRegisterUser, UpdateExtraData } from '../../../../Apis/CompanyEmployee';
import { Divider, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
    getRoles,
    getWorkStations,
    getDepartments,
    addWorkShift,
    addCustomWorkShift,
    userInfoStatus,
    createImgObj,
    uploadNewImage,
    getSelfie,
    downloadSelfie,
    GetContractStatus,
    documentListing,
} from '../../../../reduxToolkit/CompanyEmployees/CompanyEmployeesApi';
import ContractorAccessCard from '../../Contractors/ContractorAccessCard';
import {
    GetAllWorkSchdule,
    GetWorkTimeAccess,
} from '../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import {
    getAllWorkSchdule,
    getcustomSchdulTime,
    getWorkTimeAccess,
} from '../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsSlice';
import { useTranslation } from 'react-i18next';
import { GetHeaders } from '../../../../reduxToolkit/headers/HeadersApi';
import Cookies from 'js-cookie';
import { Stepper, Step, StepLabel } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Step1Details from './EnrollmentSteps/Step1Details';
import Step2OtherDetails from './EnrollmentSteps/Step2OtherDetails';
import Step3EmployeeContract from './EnrollmentSteps/Step3EmployeeContract';
import SwipeableViews from 'react-swipeable-views';
import Step4AccessRights from './EnrollmentSteps/Step4AccessRights';
import Step5TakeSelfie from './EnrollmentSteps/Step5TakeSelfie';
import Step6CapturerFinger from './EnrollmentSteps/Step6CaptureFinger';
import Step7Card from './EnrollmentSteps/Step7Card';
import Step8Summary from './EnrollmentSteps/Step8Summary';
import { CreateEmployeeUser } from '../../../../reduxToolkit/Employee/EmployeeApi';
import moment from 'moment';
import { GetUserRestriction } from '../../../../reduxToolkit/restrictions/RestrictionApi';
import { SnackbarProvider } from 'notistack';
import { useSnackbar } from 'notistack';
import { use } from 'i18next';
import FullPageLoader from '../../../../utils/FullPageLoader';
import ic_left_arrow from '../../../../assets/images/ic_left_arrow.svg';
import { errorCode } from '../../../../enums/errorCodeEnum';
import ReturnArrow from '../../../../components/buttons/ReturnArrow';
import { pluginInstance } from '../../../../Apis/Axios';
import PluginWarnModal from '../../../Modals/PluginWarnModal';
import SelfieInstructions from './EmployeeDetails/SelfieInstructions';
import { base64ToBlob } from '../../../../utils/Base64ToBlob';
import FingerPrintLoader from '../../../../utils/FingerPrintLoader';

const LIST_CAMERAS = 'LIST_CAMERAS';
const START_STREAM = 'START_STREAM';
const TAKE_SELFIE = 'TAKE_SELFIE';
const CLOSE_CAMERA = 'CLOSE_CAMERA';
const LIST_READERS = 'LIST_READERS';
const CREATE_FINGERPRINT = 'CREATE_FINGERPRINT';

// const sockjs = new WebSocket('ws://localhost:8001/hardware-v1/integrate');

// // let sockjs;

// sockjs.onopen = (e) => {
// };

const smallBoxStyle = {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    height: '40px',
};

const CreateEmployee = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const companyRestrictionsData = useSelector((state) => state?.EmployeeEventsSlice?.companyRestrictionsData);
    const employeeRoles = useSelector((state) => state?.CompanyEmployeesSlice?.employeeRoles);
    const employeeWorkStations = useSelector((state) => state?.CompanyEmployeesSlice?.employeeWorkStations);
    const userInfoStatusList = useSelector((state) => state?.CompanyEmployeesSlice?.userInfoStatusList);
    const { contractStatusList } = useSelector((state) => state?.CompanyEmployeesSlice);
    const employeeDepartments = useSelector((state) => state?.CompanyEmployeesSlice?.employeeDepartments);
    const { getUserRestriction } = useSelector((state) => state?.RestrictionSlice);
    const { headersList } = useSelector((state) => state.headersSlice);
    const workShiftAccessTime = useSelector(getWorkTimeAccess);
    const workShiftSchdule = useSelector(getAllWorkSchdule);
    const customSchdulTime = useSelector(getcustomSchdulTime);
    const companyId = 'a6bd2887-0f4a-4e5f-b0b5-000d9817ab23';
    const [changeImageModal, setChangeImageModal] = useState();
    const [userPrivilege, setUserPrivilege] = useState();
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [cellular, setCellular] = useState('');
    const [status, setStatus] = useState('');
    const [email, setEmail] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [dob, setDob] = useState('');
    const [field1, setField1] = useState('');
    const [field2, setField2] = useState('');
    const [field3, setField3] = useState('');
    const [field4, setField4] = useState('');
    const [field5, setField5] = useState('');
    const [field6, setField6] = useState('');
    const [field7, setField7] = useState('');
    const [field8, setField8] = useState('');
    const [field9, setField9] = useState('');
    const [field10, setField10] = useState('');
    const [field11, setField11] = useState('');
    const [field12, setField12] = useState('');
    const [field13, setField13] = useState('');
    const [field14, setField14] = useState('');
    const [field15, setField15] = useState('');
    const [role, setRole] = useState();
    const [workStation, setWorkStation] = useState();
    const [employeeId, setEmployeeId] = useState();
    const [contractStatus, setContractStatus] = useState();
    const [startdate, setStartdate] = useState();
    const [endDate, setEndDate] = useState();
    const [newCardModal, setNewCardModal] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [checkboxState, setCheckboxState] = useState(false);
    const [WorkShift, setWorkShift] = useState();
    const [imageToUpload, setImageToUpload] = useState(null);
    const [imgUpload, setImgUpload] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [workShiftsList, setWorkShiftsList] = useState([]);
    const [customizedList, setCustomizedList] = useState([]);
    const [emptyFields, setEmptyFields] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidDocumentId, setIsValidDocumentId] = useState(false);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const [isValidDob, setIsValidDob] = useState(true);
    const [isValidStartDate, setIsValidStartDate] = useState(true);
    const [isValidEndDate, setIsValidEndDate] = useState(true);
    const [extraDataEnabled, setExtraDataEnabled] = useState(false);
    const [steps, setSteps] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const websocketRef = useRef(null); // To store the WebSocket instance
    const [selectedImage, setSelectedImage] = useState(null);
    const [capturedSelfie, setCapturedSelfie] = useState(null);
    const [selectedReader, setSelectedReader] = useState('');
    const [availableReaders, setAvailableReaders] = useState([]);
    const [fingerprintName, setFingerprintName] = useState('');
    const [capturedFingerprint, setCapturedFingerprint] = useState(null);
    const [fingerprintsList, setFingerprintsList] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [availableCameras, setAvailableCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');
    const [isSocketOpen, setIsSocketOpen] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [switchSocket, setSwitchSocket] = useState('');
    const [capturing, setCapturing] = useState(false);
    const [createEmployee, setCreateEmployee] = useState(true);
    // const [language, setLanguage] = useState(Cookies.get("i18next") || "en")
    const [language, setLanguage] = useState('');
    const [showPluginWarn, setShowPluginWarn] = useState(false);
    const [takeCamera, setTakeCamera] = useState(false);
    const [selfieSrc, setSelfieSrc] = useState('');
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const userType = user?.data?.userType?.name;

    const [userData, setUserData] = useState({
        id: '',
        name: '',
        lastName: '',
        secondLastName: '',
        genderId: '',
        phoneNumber: '',
        statusId: '',
        email: '',
        documentId: '',
        dob: null,
    });

    const [extraData, setExtraData] = useState({
        field1: '',
        field2: '',
        field3: '',
        field4: '',
        field5: '',
        field6: '',
        field7: '',
        field8: '',
        field9: '',
        field10: '',
        field11: '',
        field12: '',
        field13: '',
        field14: '',
        field15: '',
    });

    const [employeeData, setEmployeeData] = useState({
        departmentId: '',
        employeeId: '',
        startDate: null,
        endDate: null,
        roleId: '',
        zoneId: '',
        contractStatusId: '',
        id: '',
    });

    const [cardData, setCardData] = useState([]);
    const [cardDataInfo, setCardDataInfo] = useState([]);

    const handleFormChangeUserData = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormChangeExtraData = (e) => {
        const { name, value } = e.target;
        setExtraData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormChangeEmployeeData = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // useEffect()
    // if (extraDataEnable) {
    // const steps = [t("details"), t("other_details"), t("employee_contract"), t("access_rights"), t("take_photography"), t("capture_fingerprint"), t("card"), t("summary")];
    // } else{
    //   const steps = [t("details"), t("employee_contract"), t("access_rights"), t("take_photography"), t("capture_fingerprint"), t("card"), t("summary")];
    // }

    useLayoutEffect(() => {
        if (extraDataEnabled) {
            setSteps([
                t('details'),
                t('other_details'),
                t('employee_contract'),
                t('access_rights'),
                t('take_photography'),
                t('capture_fingerprint'),
                t('card'),
                t('summary'),
            ]);
        } else {
            setSteps([
                t('details'),
                t('employee_contract'),
                t('access_rights'),
                t('take_photography'),
                t('capture_fingerprint'),
                t('card'),
                t('summary'),
            ]);
        }
    }, [extraDataEnabled, language]);

    const isStepOptionalExtraData = (step) => {
        return step === 1 || step === 3 || step === 4 || step === 5 || step === 6;
    };

    const isStepOptional = (step) => {
        return step === 2 || step === 3 || step === 4 || step === 5;
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const checkPlugin = async () => {
        await pluginInstance
            .get(`/hardware-v1/connection-service/test`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                setShowPluginWarn(true);
                return error.response;
            });
    };

    let contractPagination = {
        order: true,
        page: page,
        size: rowsPerPage,
        sortBy: 'id',
    };

    useEffect(() => {
        let timer;
        if (loadingProgress) {
            timer = setTimeout(() => setLoadingProgress(false), 2000);
        } else {
            clearTimeout(timer);
        }
        return () => clearTimeout(timer);
    }, [loadingProgress]);

    useEffect(() => {
        // Add the 'message' event to the component to listen for messages from other windows
        // window.addEventListener("message", handleLanguageChange);

        checkPlugin();

        dispatch(getRoles());
        dispatch(getWorkStations());
        dispatch(getDepartments());
        dispatch(GetAllWorkSchdule());
        dispatch(GetUserRestriction()).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success === true) {
                    let extraDataProperty =
                        userType === 'EMPLOYEE'
                            ? data?.extraDataEmployee
                            : userType === 'CONTRACTOR_IN_CHARGE'
                              ? data?.extraDataContractor
                              : userType === 'SUPPLIER_IN_CHARGE'
                                ? data?.extraDataSupplier
                                : null;

                    setExtraDataEnabled(extraDataProperty);
                }
            },
        );
        dispatch(userInfoStatus());
        dispatch(GetHeaders());
        // setExtraDataEnabled(dispatch(GetUserRestriction()).extraDataExternal)
        return () => {
            // window.removeEventListener("message", handleLanguageChange);
            if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
                websocketRef.current.close();
                setIsSocketOpen(false);
                setAvailableCameras([]);
                setAvailableReaders([]);
                setCapturing(false);
                setSelectedReader('');
                setCapturedFingerprint('');
                setFingerprintSrc('');
            }
        };
    }, []);

    useLayoutEffect(() => {
        // Add the 'message' event to the component to listen for messages from other windows
        window.addEventListener('message', handleLanguageChange);
        return () => {
            window.removeEventListener('message', handleLanguageChange);
        };
    }, []);

    // Function to handle the message received from another window
    const handleLanguageChange = (event) => {
        // Check if the received message is of the expected type
        if (event.data && event.data.type === 'languageChange') {
            // Update the variable with the new value
            let newValue = event.data.newValue !== null ? event.data.newValue : '';
            setLanguage(newValue);
        }
    };
    useEffect(() => {
        sessionStorage.setItem('capturing', capturing);
        window.postMessage(
            {
                type: 'sessionStorageChange',
                newValue: capturing,
            },
            '*',
        );
    }, [capturing]);
    const handleSaveChanges = () => {
        if (
            email &&
            name &&
            cellular &&
            gender &&
            status &&
            dob &&
            role &&
            workStation &&
            employeeId &&
            contractStatus &&
            startdate &&
            endDate &&
            documentId
        ) {
            getUserByEmail(email)
                .then(({ data }) => {
                    toast.error(data.message).toUpperCase();
                })
                .catch((error) => {
                    const preRegisterUserObj = {
                        name: name,
                        email: email,
                        phoneNumber: cellular,
                        // gender:{}
                    };
                    preRegisterUser(preRegisterUserObj)
                        .then(
                            (
                                // { data: { data } }
                                res,
                            ) => {
                                // toast.success("Pre-Register User Created Successfully..!");
                                if (res?.data?.data != null) {
                                    if (checkboxState) {
                                        let customSchdule = [];
                                        customSchdulTime?.map((item) => {
                                            customSchdule.push({
                                                user: { id: res?.data?.data?.id },
                                                zone: { id: item?.zone?.id.split(',', 1)[0] },
                                                day: { id: item?.day?.id },
                                                from: item?.from,
                                                to: item?.to,
                                            });
                                        });
                                        if (customSchdulTime?.length !== 0) {
                                            dispatch(addCustomWorkShift(customSchdule));
                                        }
                                    } else {
                                        if (WorkShift) {
                                            const workShiftBody = {
                                                userId: res?.data?.data?.id,
                                                workShiftId: WorkShift,
                                            };
                                            dispatch(addWorkShift(workShiftBody));
                                        }
                                    }

                                    const employeeObj = {
                                        user: {
                                            id: res?.data?.data?.id, // pre-register user id
                                        },
                                        company: {
                                            id: companyId,
                                        },
                                        zone: {
                                            id: workStation,
                                        },
                                        role: {
                                            id: role,
                                        },
                                        employeeId: employeeId,
                                        startDate: startdate?.getTime(),
                                        endDate: endDate?.getTime(),
                                    };
                                    createEmployee(employeeObj)
                                        .then(({ data: { data } }) => {
                                            toast.success('Employee Created Successfully..!');
                                            const body = {
                                                accessMethod: {
                                                    id: 5,
                                                },
                                                user: {
                                                    id: data?.user?.id,
                                                },
                                                description: 'Face recognition',
                                            };

                                            if (imgUpload?.size) {
                                                dispatch(createImgObj(body)).then((res) => {
                                                    let formData = new FormData();
                                                    formData.append('id', res?.payload?.data?.data?.id);
                                                    formData.append('option', 'user');
                                                    formData.append('file', imgUpload);

                                                    dispatch(uploadNewImage(formData));
                                                    // .then(() => {
                                                    //   dispatch(getSelfie(data?.id)).then(({ payload: { data: { data } } }) => {
                                                    //     dispatch(downloadSelfie(data?.id));
                                                    //     setImgUpload(null)
                                                    //   })
                                                    // })
                                                });
                                            }
                                            const extraDatabody = {
                                                header1: field1 || '',
                                                header2: field2 || '',
                                                header3: field3 || '',
                                                header4: field4 || '',
                                                header5: field5 || '',
                                                header6: field6 || '',
                                                header7: field7 || '',
                                                header8: field8 || '',
                                                header9: field9 || '',
                                                header10: field10 || '',
                                                header11: field11 || '',
                                                header12: field12 || '',
                                                header13: field13 || '',
                                                header14: field14 || '',
                                                header15: field15 || '',
                                                id: '',
                                            };

                                            UpdateExtraData(extraDatabody, data?.user?.id)
                                                .then(() => {
                                                    // toast.success("Extra Data updated successfully..!")
                                                })
                                                .catch((error) => {
                                                    // toast.error("something went wrong in extra data section.")
                                                });
                                            navigate('/dashboard/employee/all-employees', { replace: true });
                                        })
                                        .catch((error) => {
                                            toast.error('something went wrong in creating employee.');
                                        });
                                } else {
                                    toast.error(res?.data?.message);
                                }
                            },
                        )
                        .catch((error) => {
                            // toast.error("something went wrong in pre-register user. Please check your fields")
                        });
                });
        } else {
            toast.warn('Please Fill All The Feilds');
        }
    };

    const handleUploadFinger = (id) => {
        if (fingerprintSrc !== '') {
            const body = {
                accessMethod: {
                    id: 2,
                },
                user: {
                    id,
                },
                description: fingerprintName,
            };
            dispatch(createImgObj(body)).then((res) => {
                const newFingerToUpload = fingerprintSrc !== '' ? capturedFingerprint : null;
                let formData = new FormData();
                formData.append('id', res?.payload?.data?.data?.id);
                formData.append('option', 'user');
                formData.append('file', newFingerToUpload);

                dispatch(uploadNewImage(formData));
                setSelectedReader('');
                setAvailableReaders([]);
                setFingerprintSrc('');
                setFingerprintName('');
            });
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(GetWorkTimeAccess({ id: WorkShift, contractPagination }));
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        dispatch(GetWorkTimeAccess({ id: WorkShift, contractPagination }));
    };

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 13px)',
            right: 'calc(50% + 13px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#707070',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#707070',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 1,
            borderRadius: 1,
        },
    }));

    const handleCreateEmployee = () => {
        const workShifts = workShiftsList.map(({ id, name }) => ({
            id: id,
        }));

        const customs = customizedList
            .filter((element) => element.added === true) // Filtra los elementos con la propiedad "added" en true
            .map(({ zoneId, zoneName, dayId, from, to }) => ({
                zoneId: zoneId,
                zoneName: zoneName,
                dayId: dayId,
                from: from,
                to: to,
            }));

        const body = {
            employee: {
                employeeId: employeeData.employeeId,
                departmentId: employeeData.departmentId,
                departmentName: employeeDepartments.find((item) => item.id === employeeData?.departmentId)?.name,
                startDate: employeeData.startDate.getTime(),
                endDate:
                    employeeData.endDate === null ? employeeData.startDate.getTime() : employeeData.endDate.getTime(),
                roleId: employeeData.roleId,
                roleName: employeeRoles.find((item) => item.id === employeeData?.roleId)?.name,
                zoneId: employeeData.zoneId,
                zoneName: employeeWorkStations.find((item) => item.id === employeeData?.zoneId)?.name,
            },
            extraData: {
                field1: extraData.field1,
                field2: extraData.field2,
                field3: extraData.field3,
                field4: extraData.field4,
                field5: extraData.field5,
                field6: extraData.field6,
                field7: extraData.field7,
                field8: extraData.field8,
                field9: extraData.field9,
                field10: extraData.field10,
                field11: extraData.field11,
                field12: extraData.field12,
                field13: extraData.field13,
                field14: extraData.field14,
                field15: extraData.field15,
            },
            user: {
                dob: userData.dob !== null ? userData.dob.getTime() : null,
                email: userData.email,
                genderId: userData.genderId,
                lastName: userData.lastName,
                name: userData.name,
                phoneNumber: userData.phoneNumber !== '' ? '+' + userData.phoneNumber : '',
                password: '',
                secondLastName: userData.secondLastName,
                statusId: userData.statusId,
                documentId: userData.documentId,
            },
            customs: customs,
            workShifts: workShifts,
            cards: cardData,
        };

        dispatch(CreateEmployeeUser(body))
            .then(
                ({
                    payload: {
                        data: { data, success, code, message },
                    },
                }) => {
                    if (success === true) {
                        toast.success(t('employee_created_successfully').toUpperCase());
                        const id = data?.user?.id;
                        if (selectedImage !== null || selfieSrc !== '') {
                            const body = {
                                accessMethod: {
                                    id: 5,
                                },
                                user: {
                                    id,
                                },
                                description: 'Face recognition',
                            };
                            dispatch(createImgObj(body)).then((res) => {
                                const newimageToUpload =
                                    selectedImage !== null
                                        ? selectedImage
                                        : selfieSrc !== ''
                                          ? base64ToBlob(selfieSrc)
                                          : null;
                                let formData = new FormData();
                                formData.append('id', res?.payload?.data?.data?.id);
                                formData.append('option', 'user');
                                formData.append('file', newimageToUpload);
                                dispatch(uploadNewImage(formData));
                            });
                        }
                        handleUploadFinger(id);

                        navigate('/dashboard/employee/all-employees');
                    } else {
                        switch (code) {
                            case 1010:
                                toast.error(t(errorCode[code]));
                                // toast.error(t('ip_already_registered_please_use_another'));
                                break;
                            case 1020:
                                toast.error(t(errorCode[1020]));
                                break;
                            case 832:
                                toast.error(t('document_ID_already_exists').toUpperCase());
                                break;
                            case 2007:
                                toast.error(t('document_ID_is_not_valid').toUpperCase());
                                break;
                            case 2006:
                                toast.error(t(errorCode[2006]));
                                break;
                            default:
                                toast.error(t('something_went_wrong_in_creating_employee').toUpperCase());
                                break;
                        }
                    }
                },
            )
            .catch((error) => {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 500) {
                        toast.error(t('server_error').toUpperCase());
                    } else {
                        toast.error(t('unexpected_error').toUpperCase());
                    }
                } else if (error.request) {
                    toast.error(t('network_error').toUpperCase());
                } else {
                    toast.error(t('unexpected_error').toUpperCase());
                }
            });
    };

    const [videoStreamSrc, setVideoStreamSrc] = useState('');

    const [fingerprintSrc, setFingerprintSrc] = useState('');

    // const getAvailableCameras = () => {
    //   sockjs.send(JSON.stringify({
    //     message: LIST_CAMERAS
    //   }));
    // };

    // const captureStream = (index) => {
    //   sockjs.send(JSON.stringify({
    //     message: START_STREAM,
    //     data: index
    //   }));
    // };

    // const captureImage = () => {
    //   sockjs.send(JSON.stringify({
    //     message: TAKE_SELFIE
    //   }));
    // };

    // const saveSelfieToFile = () => {
    //   const link = document.createElement('a');
    //   link.href = selfieSrc;
    //   link.download = 'selfie.jpg';
    //   link.target = '_blank';
    //   link.click();
    // };

    // const getAvailableReaders = () => {
    //   sockjs.send(JSON.stringify({
    //     message: LIST_READERS
    //   }));
    // };

    // const captureFingerprint = (id) => {
    //   sockjs.send(JSON.stringify({
    //     message: CREATE_FINGERPRINT,
    //     //data: '{1A675C29-06D7-49C0-8EC5-9562DE8AA985}'
    //     // data: '{4FAEB59D-4175-D744-B856-21B45AFE09CF}'
    //     data: id
    //   }));
    // };

    // sockjs.onmessage = (event) => {
    //   const message = JSON.parse(event.data);

    //   if (message.type === LIST_CAMERAS) {
    //     const camerasArray = Object.entries(message.base64).map(([key, value]) => ({
    //       id: key,
    //       name: value
    //     }));
    //     setAvailableCameras(camerasArray);
    //   }
    //   else if (message.type === START_STREAM) {
    //     const base64Image = message.base64;
    //     setVideoStreamSrc(`data:image/jpeg;base64,${base64Image}`);
    //   }
    //   else if (message.type === TAKE_SELFIE) {
    //     const base64Image = message.base64;
    //     const mimeType = "image/jpeg";

    //     // Function to convert a base64 to a Blob
    //     function base64ToBlob(base64, mimeType) {
    //       const binStr = atob(base64);
    //       const len = binStr.length;
    //       const arr = new Uint8Array(len);

    //       for (let i = 0; i < len; i++) {
    //         arr[i] = binStr.charCodeAt(i);
    //       }

    //       return new Blob([arr], { type: mimeType });
    //     }

    //     // Convert base64 to Blob
    //     const imageBlob = base64ToBlob(base64Image, mimeType);

    //     // Create a File object (simulating a local file) from the Blob
    //     const imageFile = new File([imageBlob], "selfie.jpg", { type: mimeType });
    //     setCapturedSelfie(imageFile);
    //     setSelfieSrc(`data:image/jpeg;base64,${base64Image}`);
    //     enqueueSnackbar(t('selfie_captured_successfully').toUpperCase())
    //   }
    //   else if (message.type === LIST_READERS) {
    //     const readersArray = Object.entries(message.base64).map(([index, item]) => ({
    //       // id: value,
    //       id: item.id,
    //       // name: "Fingerprint Reader " + (parseInt(index, 10) + 1).toString()
    //       name: item.name
    //     }));
    //     setAvailableReaders(readersArray);
    //   }
    //   else if (message.type === CREATE_FINGERPRINT) {
    //     const base64Fingerprint = message.base64;
    //     const mimeType = "image/jpeg";

    //     // Function to convert a base64 to a Blob
    //     function base64ToBlob(base64, mimeType) {
    //       const binStr = atob(base64);
    //       const len = binStr.length;
    //       const arr = new Uint8Array(len);

    //       for (let i = 0; i < len; i++) {
    //         arr[i] = binStr.charCodeAt(i);
    //       }

    //       return new Blob([arr], { type: mimeType });
    //     }

    //     // Convert base64 to Blob
    //     const imageBlob = base64ToBlob(base64Fingerprint, mimeType);

    //     // Create a File object (simulating a local file) from the Blob
    //     const imageFile = new File([imageBlob], "fingerprint.jpg", { type: mimeType });
    //     setCapturedFingerprint(imageFile);
    //     setFingerprintSrc(`data:image/jpeg;base64,${base64Fingerprint}`);
    //     enqueueSnackbar(t('fingerprint_captured_successfully').toUpperCase())
    //   }
    // };

    // sockjs.onclose = (e) => {
    // };

    // sockjs.onerror = (e) => {
    // };

    const handleWebSocketOpen = () => {
        setIsSocketOpen(true);
    };

    const handleWebSocketMessage = (event) => {
        const message = JSON.parse(event.data);

        if (message.type === LIST_CAMERAS) {
            const camerasArray = Object.entries(message.base64).map(([key, value]) => ({
                id: key,
                name: value,
            }));
            setAvailableCameras(camerasArray);
        } else if (message.type === START_STREAM) {
            setLoadingProgress(false);
            const base64Image = message.base64;
            setSelfieSrc('');
            setVideoStreamSrc(`data:image/jpeg;base64,${base64Image}`);
        } else if (message.type === TAKE_SELFIE) {
            const base64Image = message.base64;
            const mimeType = 'image/jpeg';

            // Function to convert a base64 to a Blob
            function base64ToBlob(base64, mimeType) {
                const binStr = atob(base64);
                const len = binStr.length;
                const arr = new Uint8Array(len);

                for (let i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }

                return new Blob([arr], { type: mimeType });
            }

            // Convert base64 to Blob
            const imageBlob = base64ToBlob(base64Image, mimeType);

            // Create a File object (simulating a local file) from the Blob
            const imageFile = new File([imageBlob], 'selfie.jpg', { type: mimeType });
            setCapturedSelfie(imageFile);
            setSelfieSrc(`data:image/jpeg;base64,${base64Image}`);
            enqueueSnackbar(t('selfie_captured_successfully').toUpperCase(), { variant: 'success' });
            setVideoStreamSrc('');
            setTakeCamera(false);

            // Close the WebSocket when receiving the TAKE_SELFIE message
            if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
                websocketRef.current.close();
                setIsSocketOpen(false);
                setAvailableCameras([]);
            }
        } else if (message.type === LIST_READERS) {
            // const readersArray = Object.entries(message.base64).map(([key, value]) => ({
            const readersArray = Object.entries(message.base64).map(([index, item]) => ({
                // id: value,
                id: item.id,
                // name: "Fingerprint Reader " + (parseInt(index, 10) + 1).toString()
                name: item.name,
            }));
            setAvailableReaders(readersArray);
        } else if (message.type === CREATE_FINGERPRINT) {
            const base64Fingerprint = message.base64;
            const mimeType = 'image/jpeg';

            // Function to convert a base64 to a Blob
            function base64ToBlob(base64, mimeType) {
                const binStr = atob(base64);
                const len = binStr.length;
                const arr = new Uint8Array(len);

                for (let i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }

                return new Blob([arr], { type: mimeType });
            }

            // Convert base64 to Blob
            const imageBlob = base64ToBlob(base64Fingerprint, mimeType);

            // Create a File object (simulating a local file) from the Blob
            const imageFile = new File([imageBlob], 'fingerprint.jpg', { type: mimeType });
            setCapturedFingerprint(imageFile);
            setFingerprintSrc(`data:image/jpeg;base64,${base64Fingerprint}`);
            enqueueSnackbar(t('fingerprint_captured_successfully').toUpperCase(), { variant: 'success' });
            setVideoStreamSrc('');

            // setCapturedFingerprint(imageFile);
            // Close the WebSocket when receiving the CREATE_FINGERPRINT message
            if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
                websocketRef.current.close();
                setIsSocketOpen(false);
                setAvailableReaders([]);
                setCapturing(false);
            }
        } else if (message.type === CLOSE_CAMERA) {
            setTakeCamera(false);
        }
    };

    const handleWebSocketClose = (event) => {
        // Clear the WebSocket reference after it's closed
        websocketRef.current = null;
        setIsSocketOpen(false);
        setCapturing(false);
    };

    const getAvailableCameras = () => {
        setSwitchSocket('selfie');

        // Check if the WebSocket is already open
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            // Close the WebSocket if it's already open
            if (selfieSrc !== '') {
                websocketRef.current.close();
            }
            // websocketRef.current.close();
        } else {
            // Open a new WebSocket
            const newWebSocket = new WebSocket('ws://localhost:8001/hardware-v1/integrate');
            newWebSocket.onopen = handleWebSocketOpen;
            newWebSocket.onmessage = handleWebSocketMessage;
            newWebSocket.onclose = handleWebSocketClose;
            websocketRef.current = newWebSocket;
        }
    };

    const getAvailableReaders = () => {
        setSwitchSocket('finger');
        // Check if the WebSocket is already open
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            if (selectedReader !== '' && fingerprintSrc === '') {
                websocketRef.current.close();
                setSelectedReader('');
            }
        } else {
            // Open a new WebSocket
            const newWebSocket = new WebSocket('ws://localhost:8001/hardware-v1/integrate');
            newWebSocket.onopen = handleWebSocketOpen;
            newWebSocket.onmessage = handleWebSocketMessage;
            newWebSocket.onclose = handleWebSocketClose;
            websocketRef.current = newWebSocket;
        }
    };

    useEffect(() => {
        if (isSocketOpen) {
            if (switchSocket === 'selfie') {
                // Send the LIST_CAMERAS message
                if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
                    websocketRef.current.send(
                        JSON.stringify({
                            message: LIST_CAMERAS,
                        }),
                    );
                } else {
                }
            } else if (switchSocket === 'finger') {
                // Send the LIST_READERS message
                if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
                    websocketRef.current.send(
                        JSON.stringify({
                            message: LIST_READERS,
                        }),
                    );
                } else {
                }
            }
        }
    }, [isSocketOpen, switchSocket]);

    useEffect(() => {
        // if (isSocketOpen) {
        if (videoStreamSrc !== '') {
            // Send the TAKE_SELFIE message
            // captureImage()
            if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
                websocketRef.current.close();
                setIsSocketOpen(false);
                setSelfieSrc('');
                setSelectedCamera('');
                setVideoStreamSrc('');
            }
        } else if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            // Send the LIST_CAMERAS message
            // Close the WebSocket if it's already open
            websocketRef.current.close();
            setIsSocketOpen(false);
            setSelectedReader('');
        }
        // }
    }, [activeStep]);

    const captureFingerprint = (id) => {
        // Send the CREATE_FINGERPRINT message
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            websocketRef.current.send(
                JSON.stringify({
                    message: CREATE_FINGERPRINT,
                    data: id,
                }),
            );
            enqueueSnackbar(t('instructions_capture_fingerprint').toUpperCase(), { variant: 'info', persist: true });
            setCapturing(true);
        }
    };

    const captureStream = (index) => {
        // Send the START_STREAM message
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            websocketRef.current.send(
                JSON.stringify({
                    message: START_STREAM,
                    data: index,
                }),
            );
        }
    };

    const captureImage = () => {
        // Send the TAKE_SELFIE message
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            websocketRef.current.send(
                JSON.stringify({
                    message: TAKE_SELFIE,
                }),
            );
        }
    };

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(userData.email);
        setIsValidEmail(isValid);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\+[0-9]{10,14}$/;
        const isValid = phoneRegex.test('+' + userData.phoneNumber);
        setIsValidPhoneNumber(isValid);
    };
    const handleStepClick = (index) => {
        if (index < activeStep) {
            setActiveStep(index);
        }
    };

    return (
        <>
            {loadingProgress && <FullPageLoader />}
            <div className='head'>
                <div className='headLeft'>
                    {/* <Link to="/dashboard/employee/all-employees">
            <i className="fa fa-arrow-left" aria-hidden="true" style={{
              transform: lCode === "ar" ? "scaleX(-1)" : "",
              margin: "0 10px",
            }}></i>
          </Link> */}
                    <ReturnArrow link={'/dashboard/employee/all-employees'} />
                    {/* <Link className="top-left-arrow-container" to="/dashboard/employee/all-employees"

          >
            <button className='btn-left-arrow'
            >
              <img className="left-arrow" src={ic_left_arrow} alt="ic_left_arrow" />
            </button>
          </Link> */}
                    <h2>{t('enrollment')}</h2>
                    {/* <Link className='top-left-arrow-container' to="/dashboard/employee/all-employees"
          >
            <button className='btn-left-arrow'
            >
              <img className="mx-0" src={ic_left_arrow} alt="ic_left_arrow" style={{
                transform: lCode === "ar" ? "scaleX(-1)" : "",
                margin: "0 10px",
              }} />
            </button>
          </Link>
          <h2>{t("enrollment")}</h2> */}
                </div>
            </div>

            <div className='text-center exchange_icon'>
                <img
                    src={imageToUpload === null ? person5 : imageToUpload}
                    className='img-fluid'
                    // style={{ width: 240, height: 240, borderRadius: "100%" }}
                    alt='employeedetail-person4'
                />
            </div>

            {extraDataEnabled ? (
                <>
                    <Stepper
                        className='stepper-font mt-3'
                        activeStep={activeStep}
                        alternativeLabel
                        connector={<QontoConnector />}
                    >
                        {steps.map((label, index) => {
                            const labelProps = {};
                            if (isStepOptionalExtraData(index)) {
                                labelProps.optional = <Typography variant='caption'>{t('(optional)')}</Typography>;
                            }
                            return (
                                <Step key={index}>
                                    <StepLabel
                                        className='text-center stepper-font'
                                        {...labelProps}
                                        onClick={() => handleStepClick(index)}
                                    >
                                        {t(label)}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    {/* {activeStep === 0 && ( */}
                    <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
                        <div
                            className='enrollment-wrapper justify-content-right mt-5 mb-4'
                            style={{ display: activeStep === 0 ? 'block' : 'none' }}
                        >
                            <Step1Details
                                userData={userData}
                                onChange={handleFormChangeUserData}
                                emptyFields={emptyFields}
                                isValidEmail={isValidEmail}
                                setIsValidEmail={setIsValidEmail}
                                isValidDocumentId={isValidDocumentId}
                                setIsValidDocumentId={setIsValidDocumentId}
                                isValidPhoneNumber={isValidPhoneNumber}
                                setIsValidPhoneNumber={setIsValidPhoneNumber}
                                isValidDob={isValidDob}
                                setIsValidDob={setIsValidDob}
                                language={language}
                                setUserData={setUserData}
                                createEmployee={createEmployee}
                            />
                            <div className='create-enrollment-footer mt-3 row pr-2'>
                                <button
                                    className='col-6'
                                    style={{ background: '#fcfcfc', border: 'none', boxShadow: 'none' }}
                                ></button>
                                <button
                                    className='custom_primary_btn_dark col-6'
                                    // disabled={activeStep === steps.length - 1}
                                    id='btn_ce_next'
                                    onClick={() => {
                                        validatePhone(userData.phoneNumber);

                                        if (
                                            userData.name !== '' &&
                                            userData.lastName !== '' &&
                                            userData.documentId !== '' &&
                                            isValidDocumentId !== '' &&
                                            isValidPhoneNumber &&
                                            isValidDob
                                        ) {
                                            setActiveStep((prevStep) => prevStep + 1);
                                            setEmptyFields(false);
                                        } else {
                                            setEmptyFields(true);
                                        }
                                    }}
                                >
                                    {t('next').toUpperCase()}
                                </button>
                            </div>
                        </div>
                        {/* } */}
                        <div
                            className='enrollment-wrapper justify-content-right mt-5 mb-4'
                            style={{ display: activeStep === 1 ? 'block' : 'none' }}
                        >
                            <Step2OtherDetails
                                extraData={extraData}
                                onChange={handleFormChangeExtraData}
                                setExtraData={setExtraData}
                                headersList={headersList}
                            />

                            <div className='create-enrollment-footer mt-3 row pr-2'>
                                <button
                                    className='btn_cancel_background_gray_hover col-6'
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    style={{ color: '#BC0000' }}
                                    id='btn_ce_back'
                                >
                                    {t('back')}
                                </button>
                                <button
                                    className='custom_primary_btn_dark col-6'
                                    disabled={activeStep === steps.length - 1}
                                    onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                                    id='btn_ce_next'
                                >
                                    {t('next').toUpperCase()}
                                </button>
                            </div>
                        </div>

                        <div
                            className='enrollment-wrapper justify-content-right mt-5 mb-4'
                            style={{ display: activeStep === 2 ? 'block' : 'none' }}
                        >
                            <Step3EmployeeContract
                                employeeData={employeeData}
                                employeeRoles={employeeRoles}
                                employeeWorkStations={employeeWorkStations}
                                contractStatusList={contractStatusList}
                                employeeDepartments={employeeDepartments}
                                emptyFields={emptyFields}
                                isValidStartDate={isValidStartDate}
                                setIsValidStartDate={setIsValidStartDate}
                                isValidEndDate={isValidEndDate}
                                setIsValidEndDate={setIsValidEndDate}
                                onChange={handleFormChangeEmployeeData}
                                setEmployeeData={setEmployeeData}
                            />
                            <div className='create-enrollment-footer mt-3 row pr-2'>
                                <button
                                    className='btn_cancel_background_gray_hover col-6'
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    style={{ color: '#BC0000' }}
                                    id='btn_ce_back'
                                >
                                    {t('back')}
                                </button>
                                <button
                                    className='custom_primary_btn_dark col-6'
                                    disabled={activeStep === steps.length - 1}
                                    onClick={() => {
                                        if (
                                            employeeData.roleId !== '' &&
                                            employeeData.employeeId !== '' &&
                                            employeeData.startDate !== null &&
                                            isValidStartDate &&
                                            isValidEndDate
                                        ) {
                                            setActiveStep((prevStep) => prevStep + 1);
                                            setEmptyFields(false);
                                        } else {
                                            setEmptyFields(true);
                                        }
                                    }}
                                    id='btn_ce_next'
                                >
                                    {t('next').toUpperCase()}
                                </button>
                            </div>
                        </div>

                        <div
                            className='enrollment-wrapper justify-content-right mt-5 mb-4'
                            style={{ display: activeStep === 3 ? 'block' : 'none' }}
                        >
                            <Step4AccessRights
                                userData={userData}
                                employeeData={employeeData}
                                workShiftsList={workShiftsList}
                                setWorkShiftsList={setWorkShiftsList}
                                customizedList={customizedList}
                                setCustomizedList={setCustomizedList}
                                onChange={handleFormChangeEmployeeData}
                                setEmployeeData={setEmployeeData}
                            />
                            <div className='create-enrollment-footer mt-3 row pr-2'>
                                <button
                                    className='btn_cancel_background_gray_hover col-6'
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    style={{ color: '#BC0000' }}
                                    id='btn_ce_back'
                                >
                                    {t('back')}
                                </button>
                                <button
                                    className='custom_primary_btn_dark col-6'
                                    disabled={activeStep === steps.length - 1}
                                    onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                                    id='btn_ce_next'
                                >
                                    {t('next').toUpperCase()}
                                </button>
                            </div>
                        </div>

                        <div
                            className='enrollment-wrapper justify-content-right mt-5 mb-4'
                            style={{ display: activeStep === 4 ? 'block' : 'none' }}
                        >
                            <Step5TakeSelfie
                                employeeData={employeeData}
                                onChange={handleFormChangeEmployeeData}
                                getAvailableCameras={getAvailableCameras}
                                captureStream={captureStream}
                                videoStreamSrc={videoStreamSrc}
                                setVideoStreamSrc={setVideoStreamSrc}
                                captureImage={captureImage}
                                selfieSrc={selfieSrc}
                                setSelfieSrc={setSelfieSrc}
                                availableCameras={availableCameras}
                                setAvailableCameras={setAvailableCameras}
                                selectedCamera={selectedCamera}
                                setSelectedCamera={setSelectedCamera}
                                selectedImage={selectedImage}
                                setSelectedImage={setSelectedImage}
                                isSocketOpen={isSocketOpen}
                                setTakeCamera={setTakeCamera}
                                takeCamera={takeCamera}
                                setIsSocketOpen={setIsSocketOpen}
                                websocketRef={websocketRef}
                                loadingProgress={loadingProgress}
                                setLoadingProgress={setLoadingProgress}
                                setEmployeeData={setEmployeeData}
                            />
                            <div className='create-enrollment-footer mt-3 row pr-2'>
                                <button
                                    className='btn_cancel_background_gray_hover col-6'
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    style={{ color: '#BC0000' }}
                                    id='btn_ce_back'
                                >
                                    {t('back')}
                                </button>
                                <button
                                    className='custom_primary_btn_dark col-6'
                                    disabled={activeStep === steps.length - 1}
                                    onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                                    id='btn_ce_next'
                                >
                                    {t('next').toUpperCase()}
                                </button>
                            </div>
                        </div>

                        <div
                            className='enrollment-wrapper justify-content-right mt-5 mb-4'
                            style={{ display: activeStep === 5 ? 'block' : 'none' }}
                        >
                            <Step6CapturerFinger
                                employeeData={employeeData}
                                userData={userData}
                                onChange={handleFormChangeEmployeeData}
                                selectedReader={selectedReader}
                                setSelectedReader={setSelectedReader}
                                availableReaders={availableReaders}
                                setAvailableReaders={setAvailableReaders}
                                getAvailableReaders={getAvailableReaders}
                                captureFingerprint={captureFingerprint}
                                fingerprintSrc={fingerprintSrc}
                                fingerprintsList={fingerprintsList}
                                handleUploadFinger={handleUploadFinger}
                                fingerprintName={fingerprintName}
                                setFingerprintName={setFingerprintName}
                                setFingerprintSrc={setFingerprintSrc}
                                updated={updated}
                                setUpdated={setUpdated}
                                isSocketOpen={isSocketOpen}
                                setIsSocketOpen={setIsSocketOpen}
                                websocketRef={websocketRef}
                                loadingProgress={loadingProgress}
                                setLoadingProgress={setLoadingProgress}
                                capturing={capturing}
                                setCapturing={setCapturing}
                                emptyFields={emptyFields}
                                setEmployeeData={setEmployeeData}
                            />
                            <div className='create-enrollment-footer mt-3 row pr-2'>
                                <button
                                    className='btn_cancel_background_gray_hover col-6'
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    style={{ color: '#BC0000' }}
                                    id='btn_ce_back'
                                >
                                    {t('back')}
                                </button>
                                <button
                                    className='custom_primary_btn_dark col-6'
                                    disabled={activeStep === steps.length - 1}
                                    onClick={() => {
                                        if (
                                            selectedReader === '' ||
                                            (selectedReader !== '' && fingerprintName !== '')
                                        ) {
                                            setActiveStep((prevStep) => prevStep + 1);
                                            setEmptyFields(false);
                                        } else {
                                            setEmptyFields(true);
                                        }
                                    }}
                                    id='btn_ce_next'
                                    // onClick={() => setActiveStep(prevStep => prevStep + 1)}
                                >
                                    {t('next').toUpperCase()}
                                </button>
                            </div>
                        </div>

                        <div
                            className='enrollment-wrapper justify-content-right mt-5 mb-4'
                            style={{ display: activeStep === 6 ? 'block' : 'none' }}
                        >
                            <Step7Card
                                employeeData={employeeData}
                                onChange={handleFormChangeEmployeeData}
                                setEmployeeData={setEmployeeData}
                                setCardData={setCardData}
                                setCardDataInfo={setCardDataInfo}
                                componentAction='create'
                            />
                            <div className='create-enrollment-footer mt-3 row pr-2'>
                                <button
                                    className='btn_cancel_background_gray_hover col-6'
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    style={{ color: '#BC0000' }}
                                    id='btn_ce_back'
                                >
                                    {t('back')}
                                </button>
                                <button
                                    className='custom_primary_btn_dark col-6'
                                    disabled={activeStep === steps.length - 1}
                                    onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                                    id='btn_ce_next'
                                >
                                    {t('next').toUpperCase()}
                                </button>
                            </div>
                        </div>

                        <div
                            className='enrollment-wrapper justify-content-right mt-5 mb-4'
                            style={{ display: activeStep === 7 ? 'block' : 'none' }}
                        >
                            <Step8Summary
                                userData={userData}
                                extraData={extraData}
                                employeeRoles={employeeRoles}
                                employeeWorkStations={employeeWorkStations}
                                contractStatusList={contractStatusList}
                                employeeDepartments={employeeDepartments}
                                workShiftsList={workShiftsList}
                                customizedList={customizedList}
                                onChange={handleFormChangeEmployeeData}
                                extraDataEnabled={extraDataEnabled}
                                employeeData={employeeData}
                                selectedImage={selectedImage}
                                selfieSrc={selfieSrc}
                                fingerprintSrc={fingerprintSrc}
                                fingerprintName={fingerprintName}
                                headersList={headersList}
                                cardDataInfo={cardDataInfo}
                            />
                            <div className='create-enrollment-footer mt-3 row pr-2 ml-0'>
                                <button
                                    className='btn_cancel_background_gray_hover col-6'
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    style={{ color: '#BC0000' }}
                                    id='btn_ce_back'
                                >
                                    {t('back')}
                                </button>
                                <button
                                    className='custom_primary_btn_dark col-6'
                                    onClick={() => {
                                        handleCreateEmployee();
                                    }}
                                    id='btn_ce_enroll'
                                >
                                    {t('enroll').toUpperCase()}
                                </button>
                            </div>
                        </div>
                    </SwipeableViews>
                </>
            ) : (
                <>
                    <Stepper
                        className='stepper-font mt-3'
                        activeStep={activeStep}
                        alternativeLabel
                        connector={<QontoConnector />}
                    >
                        {steps.map((label, index) => {
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = <Typography variant='caption'>{t('(optional)')}</Typography>;
                            }
                            return (
                                <Step key={index}>
                                    <StepLabel className='text-center stepper-font' {...labelProps}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    {/* {activeStep === 0 && ( */}
                    {/*  <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}> */}
                    <div
                        className='enrollment-wrapper justify-content-right mt-5 mb-4'
                        style={{ display: activeStep === 0 ? 'block' : 'none' }}
                    >
                        <Step1Details
                            userData={userData}
                            onChange={handleFormChangeUserData}
                            emptyFields={emptyFields}
                            isValidEmail={isValidEmail}
                            setIsValidEmail={setIsValidEmail}
                            isValidPhoneNumber={isValidPhoneNumber}
                            setIsValidPhoneNumber={setIsValidPhoneNumber}
                            isValidDocumentId={isValidDocumentId}
                            setIsValidDocumentId={setIsValidDocumentId}
                            isValidDob={isValidDob}
                            setIsValidDob={setIsValidDob}
                            language={language}
                            setUserData={setUserData}
                        />
                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='col-6'
                                style={{ background: '#fcfcfc', border: 'none', boxShadow: 'none' }}
                            ></button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                // disabled={activeStep === steps.length - 1}
                                onClick={() => {
                                    validatePhone(userData.phoneNumber);
                                    if (
                                        userData.name !== '' &&
                                        userData.lastName !== '' &&
                                        isValidPhoneNumber &&
                                        userData.documentId !== '' &&
                                        isValidDob
                                    ) {
                                        setActiveStep((prevStep) => prevStep + 1);
                                        setEmptyFields(false);
                                    } else {
                                        setEmptyFields(true);
                                    }
                                }}
                                id='btn_ce_next'
                            >
                                {t('next').toUpperCase()}
                            </button>
                        </div>
                    </div>
                    {/* } */}

                    <div
                        className='enrollment-wrapper justify-content-right mt-5 mb-4'
                        style={{ display: activeStep === 1 ? 'block' : 'none' }}
                    >
                        <Step3EmployeeContract
                            employeeData={employeeData}
                            employeeRoles={employeeRoles}
                            employeeWorkStations={employeeWorkStations}
                            contractStatusList={contractStatusList}
                            employeeDepartments={employeeDepartments}
                            emptyFields={emptyFields}
                            isValidStartDate={isValidStartDate}
                            setIsValidStartDate={setIsValidStartDate}
                            isValidEndDate={isValidEndDate}
                            setIsValidEndDate={setIsValidEndDate}
                            onChange={handleFormChangeEmployeeData}
                            setEmployeeData={setEmployeeData}
                        />
                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                style={{ color: '#BC0000' }}
                                id='btn_ce_back'
                            >
                                {t('back')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                disabled={activeStep === steps.length - 1}
                                onClick={() => {
                                    if (
                                        employeeData.roleId !== '' &&
                                        employeeData.employeeId !== '' &&
                                        employeeData.startDate !== null &&
                                        isValidStartDate &&
                                        isValidEndDate
                                    ) {
                                        setActiveStep((prevStep) => prevStep + 1);
                                        setEmptyFields(false);
                                    } else {
                                        setEmptyFields(true);
                                    }
                                }}
                                id='btn_ce_next'
                            >
                                {t('next').toUpperCase()}
                            </button>
                        </div>
                    </div>

                    <div
                        className='enrollment-wrapper justify-content-right mt-5 mb-4'
                        style={{ display: activeStep === 2 ? 'block' : 'none' }}
                    >
                        <Step4AccessRights
                            userData={userData}
                            employeeData={employeeData}
                            workShiftsList={workShiftsList}
                            setWorkShiftsList={setWorkShiftsList}
                            customizedList={customizedList}
                            setCustomizedList={setCustomizedList}
                            onChange={handleFormChangeEmployeeData}
                            setEmployeeData={setEmployeeData}
                        />
                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                style={{ color: '#BC0000' }}
                                id='btn_ce_back'
                            >
                                {t('back')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                disabled={activeStep === steps.length - 1}
                                onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                                id='btn_ce_next'
                            >
                                {t('next').toUpperCase()}
                            </button>
                        </div>
                    </div>

                    <div
                        className='enrollment-wrapper justify-content-right mt-5 mb-4'
                        style={{ display: activeStep === 3 ? 'block' : 'none' }}
                    >
                        <Step5TakeSelfie
                            employeeData={employeeData}
                            onChange={handleFormChangeEmployeeData}
                            getAvailableCameras={getAvailableCameras}
                            captureStream={captureStream}
                            videoStreamSrc={videoStreamSrc}
                            setVideoStreamSrc={setVideoStreamSrc}
                            captureImage={captureImage}
                            setTakeCamera={setTakeCamera}
                            takeCamera={takeCamera}
                            selfieSrc={selfieSrc}
                            setSelfieSrc={setSelfieSrc}
                            availableCameras={availableCameras}
                            setAvailableCameras={setAvailableCameras}
                            selectedCamera={selectedCamera}
                            setSelectedCamera={setSelectedCamera}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                            isSocketOpen={isSocketOpen}
                            setIsSocketOpen={setIsSocketOpen}
                            websocketRef={websocketRef}
                            loadingProgress={loadingProgress}
                            setLoadingProgress={setLoadingProgress}
                            setEmployeeData={setEmployeeData}
                        />
                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                style={{ color: '#BC0000' }}
                                id='btn_ce_back'
                            >
                                {t('back')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                disabled={activeStep === steps.length - 1}
                                onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                                id='btn_ce_next'
                            >
                                {t('next').toUpperCase()}
                            </button>
                        </div>
                    </div>

                    <div
                        className='enrollment-wrapper justify-content-right mt-5 mb-4'
                        style={{ display: activeStep === 4 ? 'block' : 'none' }}
                    >
                        <Step6CapturerFinger
                            employeeData={employeeData}
                            userData={userData}
                            onChange={handleFormChangeEmployeeData}
                            selectedReader={selectedReader}
                            setSelectedReader={setSelectedReader}
                            availableReaders={availableReaders}
                            setAvailableReaders={setAvailableReaders}
                            getAvailableReaders={getAvailableReaders}
                            captureFingerprint={captureFingerprint}
                            fingerprintSrc={fingerprintSrc}
                            fingerprintsList={fingerprintsList}
                            handleUploadFinger={handleUploadFinger}
                            fingerprintName={fingerprintName}
                            setFingerprintName={setFingerprintName}
                            setFingerprintSrc={setFingerprintSrc}
                            updated={updated}
                            setUpdated={setUpdated}
                            isSocketOpen={isSocketOpen}
                            setIsSocketOpen={setIsSocketOpen}
                            websocketRef={websocketRef}
                            loadingProgress={loadingProgress}
                            setLoadingProgress={setLoadingProgress}
                            capturing={capturing}
                            setCapturing={setCapturing}
                            emptyFields={emptyFields}
                            setEmployeeData={setEmployeeData}
                        />
                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                style={{ color: '#BC0000' }}
                                id='btn_ce_back'
                            >
                                {t('back')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                disabled={activeStep === steps.length - 1}
                                onClick={() => {
                                    if (selectedReader === '' || (selectedReader !== '' && fingerprintName !== '')) {
                                        setActiveStep((prevStep) => prevStep + 1);
                                        setEmptyFields(false);
                                    } else {
                                        setEmptyFields(true);
                                    }
                                }}
                                // onClick={() => setActiveStep(prevStep => prevStep + 1)}
                                id='btn_ce_next'
                            >
                                {t('next').toUpperCase()}
                            </button>
                        </div>
                    </div>

                    <div
                        className='enrollment-wrapper justify-content-right mt-5 mb-4'
                        style={{ display: activeStep === 5 ? 'block' : 'none' }}
                    >
                        <Step7Card
                            employeeData={employeeData}
                            onChange={handleFormChangeEmployeeData}
                            setEmployeeData={setEmployeeData}
                            setCardData={setCardData}
                            setCardDataInfo={setCardDataInfo}
                            componentAction='create'
                        />
                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                style={{ color: '#BC0000' }}
                                id='btn_ce_back'
                            >
                                {t('back')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                disabled={activeStep === steps.length - 1}
                                onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                                id='btn_ce_next'
                            >
                                {t('next').toUpperCase()}
                            </button>
                        </div>
                    </div>

                    <div
                        className='enrollment-wrapper justify-content-right mt-5 mb-4'
                        style={{ display: activeStep === 6 ? 'block' : 'none' }}
                    >
                        <Step8Summary
                            userData={userData}
                            extraData={extraData}
                            employeeRoles={employeeRoles}
                            employeeWorkStations={employeeWorkStations}
                            contractStatusList={contractStatusList}
                            employeeDepartments={employeeDepartments}
                            workShiftsList={workShiftsList}
                            customizedList={customizedList}
                            onChange={handleFormChangeEmployeeData}
                            extraDataEnabled={extraDataEnabled}
                            employeeData={employeeData}
                            selectedImage={selectedImage}
                            selfieSrc={selfieSrc}
                            fingerprintSrc={fingerprintSrc}
                            fingerprintName={fingerprintName}
                            headersList={headersList}
                            cardDataInfo={cardDataInfo}
                        />
                        <div className='create-enrollment-footer mt-3 row pr-2 ml-0'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                style={{ color: '#BC0000' }}
                                id='btn_ce_back'
                            >
                                {t('back')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                onClick={() => {
                                    handleCreateEmployee();
                                }}
                                id='btn_ce_enroll'
                            >
                                {t('enroll').toUpperCase()}
                            </button>
                        </div>
                    </div>
                    {/*  </SwipeableViews> */}
                </>
            )}

            <NewCard title='New Card' show={newCardModal} onHide={() => setNewCardModal(false)} />
        </>
    );
};

export default CreateEmployee;
