import { useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import {
    DetailsEmployeeProviderOrder,
    GetAllPageableProvider,
    GetIncomingProvidersPaginable,
    GetRecordsProvidersPaginable,
} from '../../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import ProviderTable from './ProviderTable';
import { t } from 'i18next';

import DeleteModal from '../../Modals/DeleteModal';
import { permissionObj } from '../../../Helpers/permission';
import DisplayView from '../../../components/DisplayView';
import TableViewSuppliers from './TableViewSuppliers';
import SearchFor from '../../Modals/SearchFor';
import { MODELS } from '../../../Apis/Models';
import { TABLES } from '../../../Apis/Tables';
import { SearchByFilters, SearchByFiltersCustom, SimpleSearch } from '../../../reduxToolkit/Search/SearchApi';
import { status } from '../../../enums/statusEnum';
import { toast } from 'react-toastify';
import { GoPrimitiveDot } from 'react-icons/go';
import { Divider, TextField } from '@mui/material';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Checkbox, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import SettingButton from '../../../components/SettingButton';
import SearchFilterBtn from '../../../components/buttons/SearchFilterBtn';
import { clearSearchFilters } from '../../../reduxToolkit/Search/SearchSlice';
import { statusColor } from '../../../enums/statusColorEnum';
import ExportDataBtn from '../../../components/buttons/ExportDataBtn';
import ImportDataBtn from '../../../components/buttons/ImportDataBtn';
import ImportFileModal from '../../Modals/ImportFileModal';
import CreationErrorsModal from '../../Modals/CreationErrorsModal';
import moment from 'moment';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import NotInformation from '../../../components/NotInformation';
import SearchIcon from '@mui/icons-material/Search';
import SearchSimple from '../../../components/SearchSimples/SearchSimple';
import SearchSimpleWithId from '../../../components/SearchSimples/SearchSimpleWithId';
import { logDOM } from '@testing-library/react';

/*
Author : Arman Ali
Module: subplier
github: https://github.com/Arman-Arzoo
*/

const ProvidersPanel = () => {
    // use hook importer
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lCode = Cookies.get('i18next') || 'en';
    const { t } = useTranslation();

    //use Selector hook to get state for redux store
    // const { permission } = useSelector(state => state.authenticatioauthennSlice);
    const { incomingProviders } = useSelector((state) => state.EmployeeProviderSlice);
    const { recordsProviders } = useSelector((state) => state.EmployeeProviderSlice);

    // use State hook for local state management
    const [show, setShow] = useState(false);
    const [toggleState, setToggleState] = useState(1);
    const [pageIncoming, setPageIncoming] = useState(0);
    const [rowsPerPageIncoming, setRowsPerPageincoming] = useState(8);
    const [pageRecord, setPageRecord] = useState(0);
    const [rowsPerPageRecord, setRowsPerPageRecord] = useState(8);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectSupplierForDelete, setSelectSupplierForDelete] = useState([]);
    const [deleteSupplierShow, setDeleteSupplierShow] = useState(false);
    const [deleteOrderShow, setDeleteOrderShow] = useState(false);
    const [isAllCheckedOrderIncoming, setIsAllCheckedOrderIncoming] = useState(false);
    const [isAllCheckedOrder, setIsAllCheckedOrder] = useState(false);
    const [selectOrderForDeleteIncoming, setSelectOrderForDeleteIncoming] = useState([]);
    const [selectOrderForDelete, setSelectOrderForDelete] = useState([]);
    const [deleteOrderShowIncomingShow, setDeleteOrderShowIncomingShow] = useState(false);

    const [isAllCheckedOrderRecord, setIsAllCheckedOrderRecord] = useState(false);
    const [selectOrderForDeleteRecord, setSelectOrderForDeleteRecord] = useState([]);
    const [deleteOrderShowRecordShow, setDeleteOrderShowRecordShow] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [checked, setChecked] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [importFileShow, setImportFileShow] = useState(false);
    const [creationErrorsShow, setCreationErrorsShow] = useState(false);
    const [searchElement, setSearchElement] = useState('');

    const [view, setView] = useState('list');
    const title_modal = 'DELETE_SUPPLIERS';
    const element_modal = 'SUPPLIERS';

    //use selector
    const { getAllPageableProvider } = useSelector((state) => state.EmployeeProviderSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { searchByFilters, searchByFiltersCustom } = useSelector((state) => state.SearchSlice);

    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState('');

    let selectedData;

    if (supplierId === null) {
        if (
            !searchResults ||
            searchResults?.content?.length === 0 ||
            searchResults === null ||
            searchResults === undefined
        ) {
            if (!searchResults) {
                selectedData = searchByFilters;
            } else {
                selectedData = null;
            }
        } else if (searchResults?.content?.length > 0) {
            selectedData = searchResults;
        }
    } else {
        if (
            !searchResults ||
            searchResults?.content?.length === 0 ||
            searchResults === null ||
            searchResults === undefined
        ) {
            if (!searchResults) {
                selectedData = searchByFiltersCustom;
            } else {
                selectedData = null;
            }
        } else {
            selectedData = searchResults;
        }
    }

    useEffect(() => {
        if (toggleState === 1 || toggleState === 2) {
            setSearchElement('');
        }
    }, [toggleState]);

    // Props to the filter window
    const [moduleId, setModuleId] = useState(`${MODELS.Order}`);
    const [option, setOption] = useState(`${TABLES.ORDERS}`);
    const [criteria, setCriteria] = useState();

    // funtion to set filter
    const handlFilters = (order, sort) => {
        setOrderBy(order);
        setSortBy(sort);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const options = {
        filterType: 'checkbox',
    };

    // pagination change input fn

    const handleChangePageIcoming = (event, newPage) => {
        setPageIncoming(newPage);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleChangeRowsPerPageIncoming = (event) => {
        setRowsPerPageincoming(parseInt(event.target.value));
        setPageIncoming(0);
    };

    const handleChangePageRecord = (event, newPage) => {
        setPageRecord(newPage);
    };

    const handleChangeRowsPerPageRecord = (event) => {
        setRowsPerPageRecord(parseInt(event.target.value));
        setPageRecord(0);
    };

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [toggleState, view, page, rowsPerPage, finalArray]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    // useEffect to check automatically all the items when page, rowsPerPage, or search change
    useEffect(() => {
        if (isAllChecked) {
            const selectAllIds = selectedData?.content?.map((item) => item?.id);
            setSelectSupplierForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
        if (isAllCheckedOrder) {
            const selectAllIds = selectedData?.content?.map((item) => item?.id);
            setSelectOrderForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [searchByFilters, searchByFiltersCustom]);

    // this function control select all id or unSelect all for supplier
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = selectedData?.content?.map((item) => {
                return item?.id;
            });
            setSelectSupplierForDelete(selectAllIds);
        } else {
            setSelectSupplierForDelete([]);
        }
    };

    // this function handle only specific id base on selection for supplier
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectSupplierForDelete([...selectSupplierForDelete, e.target.id]);
        } else {
            setSelectSupplierForDelete(selectSupplierForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // This components are used to calculate the distance between the top of the window and the top of the table panel
    const elementRef = useRef(null);
    useEffect(() => {
        const rect = elementRef.current.getBoundingClientRect();
        const distanceTop = rect.top + 15;
        elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
    }, [toggleState]);
    // this function control select all id or unSelect all for incoming
    const handelDeleteAllOrder = (e) => {
        setIsAllCheckedOrder(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = selectedData?.content?.map((item) => {
                return item?.id;
            });
            setSelectOrderForDelete(selectAllIds);
        } else {
            setSelectOrderForDelete([]);
        }
    };

    // this function handle only specific id base on selection for incoming
    const handleCheckboxChangeOrder = (e) => {
        if (e.target.checked) {
            setSelectOrderForDelete([...selectOrderForDelete, e.target.id]);
        } else {
            setSelectOrderForDelete(selectOrderForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // this function control select all id or unSelect all for incoming
    const handelDeleteAllOrderRecord = (e) => {
        setIsAllCheckedOrderRecord(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = recordsProviders?.content?.map((item) => {
                return item?.id;
            });
            setSelectOrderForDeleteRecord(selectAllIds);
        } else {
            setSelectOrderForDeleteRecord([]);
        }
    };

    // this function handle only specific id base on selection for incoming
    const handleCheckboxChangeOrderRecord = (e) => {
        if (e.target.checked) {
            setSelectOrderForDeleteRecord([...selectOrderForDeleteRecord, e.target.id]);
        } else {
            setSelectOrderForDeleteRecord(selectOrderForDeleteRecord.filter((removeid) => removeid !== e.target.id));
        }
    };

    //This fragment makes uncheked all the checkboxes when toggleState change
    const resetAllCheckboxes = () => {
        const checkboxes = document.querySelectorAll('.checkbox');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    };

    // check permission check
    useEffect(() => {
        if (!permission?.includes(permissionObj?.WEB_SUPPLIER_READ)) {
            setToggleState(1);
        } else if (!permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_READ)) {
            setToggleState(2);
        }
    }, []);
    //Clean any checkbox when toggle change
    useEffect(() => {
        dispatch(clearSearchFilters());
        resetAllCheckboxes();
        setSelectSupplierForDelete([]);
        setSelectOrderForDelete([]);
        setIsAllChecked(false);
        setIsAllCheckedOrder(false);
    }, [toggleState, view]);

    useEffect(() => {
        if (view === 'grid') {
            setRowsPerPage(8);
        } else {
            setRowsPerPage(20);
        }
    }, [view, toggleState]);

    //Create body to get the respectively search
    useEffect(() => {
        if (loaded) {
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                    from: item.from,
                    to: item.to,
                };
            });
            if (supplierId === null) {
                const body = {
                    pagination: {
                        page: page,
                        size: rowsPerPage,
                    },
                    searchCriteriaList: criteriaList,
                };
                dispatch(SearchByFilters({ option, body })).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            setSearchResults('');
                        }
                    },
                );
                setCriteria({ searchCriteriaList: criteriaList });
            } else {
                const body = {
                    userToFindId: supplierId,
                    pagination: {
                        page: page,
                        size: rowsPerPage,
                    },
                    searchCriteriaList: criteriaList,
                };
                dispatch(SearchByFiltersCustom({ option, body })).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        {
                        }
                    },
                );
                setCriteria({ searchCriteriaList: criteriaList });
            }
            setLoaded(false);
        }
    }, [loaded, checked, page, rowsPerPage, orderBy, sortBy, finalArray, toggleState]);

    // useEffect for api call incoming with pagination
    useEffect(() => {
        const d = new Date();
        let time_in_miliseconds = Math.round(d.getTime());

        const body = {
            date: time_in_miliseconds,
            pagination: {
                order: sortBy === 'asc' ? true : false,
                page: pageIncoming,
                size: rowsPerPageIncoming,
                sortBy: orderBy ? orderBy : 'id',
            },
        };
        dispatch(GetIncomingProvidersPaginable(body));
    }, [pageIncoming, rowsPerPageIncoming, orderBy, sortBy]);
    // useEffect for api call records with pagination
    useEffect(() => {
        const d = new Date();
        let time_in_miliseconds = Math.round(d.getTime());

        const body = {
            date: time_in_miliseconds,
            pagination: {
                order: sortBy === 'asc' ? true : false,
                page: pageRecord,
                size: rowsPerPageRecord,
                sortBy: orderBy ? orderBy : 'id',
            },
        };
        dispatch(GetRecordsProvidersPaginable(body));
    }, [pageRecord, rowsPerPageRecord, orderBy, sortBy]);

    const deleteSelectedOrders = (deleteItem) => {
        const tableName = 'purchase';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectOrderForDelete([]);
                        setIsAllCheckedOrder(false);
                    } else {
                    }
                },
            );
        }
    };

    const deleteSelectedSuppliers = (deleteItem) => {
        const tableName = 'supplier';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectSupplierForDelete([]);
                        setIsAllChecked(false);
                    } else {
                    }
                },
            );
        }
    };
    return (
        <div className='providersPanel'>
            <div className='head'>
                <div className='headLeft'>
                    <h2 className='mr-2'>{supplierId === null ? t('suppliers_panel') : t('orders')}</h2>
                    {supplierId === null ? (
                        <SettingButton
                            onAction={() => navigate('/dashboard/employee/supplier-restriction')}
                            title={t('restrictions').toUpperCase()}
                            placement={'right'}
                        />
                    ) : (
                        <DisplayView view={view} setView={setView} />
                    )}
                </div>
                {supplierId !== null && (
                    <section>
                        <div className='container-top-right-btns'>
                            {!searchResults || !bodyForExport ? (
                                <ExportDataBtn body={criteria} option={option} />
                            ) : (
                                <ExportDataBtn searchElement={searchElement} body={bodyForExport} option={option} />
                            )}
                            <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                        </div>
                    </section>
                )}
            </div>
            {/* portfolio-grid */}
            <div>
                {supplierId !== null && (
                    <section className='SearchSimpleForOrders--supplier'>
                        <SearchSimple
                            searchElement={searchElement}
                            setSearchElement={setSearchElement}
                            setSearchResults={setSearchResults}
                            setBodyForExport={setBodyForExport}
                            optionTable={option}
                            page={page}
                            rowsPerPage={rowsPerPage}
                        />
                    </section>
                )}
                <div className='row steps-row mb-3 mx-0' id='pills-tab' role='tablist'>
                    {permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_READ) && (
                        <div
                            className={`col-6 text-center p-0 tap_hover ${
                                toggleState === 1 ? 'active_tap' : 'deactive_tap'
                            }`}
                            role='presentation'
                        >
                            <a
                                className={`steps-global btn ${toggleState === 1 ? 'btn-bordered-global' : ''}`}
                                onClick={() => {
                                    if (toggleState !== 1) {
                                        setToggleState(1);
                                        setPage(0);
                                        setView('list');
                                        setRowsPerPage(20);

                                        setOption(`${TABLES.ORDERS}`);
                                        setModuleId(`${MODELS.Order}`);
                                        setFinalArray([]);
                                    }
                                }}
                                id='pills-home-tab'
                                data-bs-toggle='pill'
                                data-bs-target='#pills-home'
                                type='button'
                                role='tab'
                                aria-controls='pills-home'
                                aria-selected='true'
                            >
                                <span>{t('purchase_orders')}</span>
                            </a>
                        </div>
                    )}
                    {permission?.includes(permissionObj?.WEB_SUPPLIER_READ) && (
                        <div
                            className={`col-6 text-center p-0 tap_hover ${
                                toggleState === 2 ? 'active_tap' : 'deactive_tap'
                            }`}
                            role='presentation'
                        >
                            <a
                                className={`steps-global btn ${toggleState === 2 ? 'btn-bordered-global' : ''}`}
                                onClick={() => {
                                    if (toggleState !== 2) {
                                        setToggleState(2);
                                        setRowsPerPage(20);
                                        setPage(0);
                                        setOption(`${TABLES.SUPPLIERS}`);
                                        setModuleId(`${MODELS.Supplier}`);
                                        setFinalArray([]);
                                    }
                                }}
                                id='pills-home-tab'
                                data-bs-toggle='pill'
                                data-bs-target='#pills-home'
                                type='button'
                                role='tab'
                                aria-controls='pills-home'
                                aria-selected='true'
                            >
                                <span>{t('suppliers')}</span>
                            </a>
                        </div>
                    )}
                </div>

                <div className='tab-content' id='pills-tabContent'>
                    <div
                        className={`${toggleState === 1 ? 'tab-pane fade show active ' : 'tab-pane fade'}`}
                        id='pills-home'
                        role='tabpanel'
                        aria-labelledby='pills-home-tab'
                    >
                        <div className='orders animated-div'>
                            {supplierId === null && (
                                <div className=' order_header '>
                                    <div className='right_header'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <p>{t('options')}</p>
                                            <DisplayView view={view} setView={setView} />
                                        </div>
                                    </div>
                                    <section
                                        style={{ marginRight: '10px', marginLeft: '10px' }}
                                        className='simple-searcher--sectionSuppliersOrder'
                                    >
                                        <SearchSimple
                                            searchElement={searchElement}
                                            setSearchElement={setSearchElement}
                                            setSearchResults={setSearchResults}
                                            setBodyForExport={setBodyForExport}
                                            optionTable={option}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                        />
                                    </section>
                                    <div className='container-top-right-btns'>
                                        {/*
                                        De preferencia poner privilegio de usuario normal 
                                        Export Data del Simple Search 
                                        */}
                                        {!searchResults || !bodyForExport ? (
                                            <ExportDataBtn body={criteria} option={option} />
                                        ) : (
                                            <ExportDataBtn
                                                searchElement={searchElement}
                                                body={bodyForExport}
                                                option={option}
                                            />
                                        )}
                                        {permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_CREATE) && (
                                            <button className='add-btn-1' onClick={() => navigate('create-order')}>
                                                <i class='fa fa-plus' aria-hidden='true'></i>
                                                {t('add')}
                                            </button>
                                        )}
                                        {permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_DELETE) && (
                                            <button
                                                className='delete-btn-1'
                                                disabled={selectOrderForDelete?.length === 0}
                                                onClick={() => {
                                                    setDeleteOrderShow(true);
                                                }}
                                            >
                                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                                {t('delete')}
                                            </button>
                                        )}

                                        <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                                    </div>
                                </div>
                            )}

                            {view === 'grid' && (
                                <div className='d-flex mr-0 pl-0'>
                                    {permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_DELETE) && (
                                        <Tooltip
                                            title={t('de_/_select_all').toUpperCase()}
                                            placement='top'
                                            disableInteractive
                                        >
                                            <FormControlLabel
                                                className='grid-checkall pl-1 ml-1'
                                                control={
                                                    <Checkbox
                                                        label='Label'
                                                        checked={isAllCheckedOrder}
                                                        onChange={handelDeleteAllOrder}
                                                        size='small'
                                                    />
                                                }
                                                label={t('de_/_select_all')}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                            {/* incoming order list */}
                            {selectedData?.content?.length > 0 ? (
                                <>
                                    {view === 'grid' && (
                                        <div className='row mt-0 gap-0 px-0 mx-0'>
                                            {selectedData?.content?.map((item, index) => {
                                                return (
                                                    <div className='panel-grid animated-div-left col-md-6 col-lg-4 col-xl-3 px-0 mx-0 mb-3'>
                                                        <div className='card-base-grid pb-3 h-100 ml-2'>
                                                            <div className='top-heading-card'>
                                                                <div className='input_check'>
                                                                    {permission?.includes(
                                                                        permissionObj?.WEB_SUPPLIER_ORDER_DELETE,
                                                                    ) && (
                                                                        <Checkbox
                                                                            className='grid-checkall checkbox'
                                                                            checked={selectOrderForDelete?.includes(
                                                                                item?.id,
                                                                            )}
                                                                            id={item?.id}
                                                                            onChange={handleCheckboxChangeOrder}
                                                                            size='small'
                                                                        />
                                                                    )}
                                                                </div>

                                                                <div
                                                                    className={'status'}
                                                                    style={{ color: statusColor[item?.statusId] }}
                                                                >
                                                                    <p>{t(status[item?.statusId]).toUpperCase()}</p>
                                                                    <GoPrimitiveDot className='ml-1' />
                                                                </div>
                                                            </div>

                                                            <div className='card-body-grid'>
                                                                <div className='px-2 pb-2'>
                                                                    <div className='card-content-grid'>
                                                                        <span className='viewcard-container-heading'>
                                                                            {`${t('Folio')} #${item?.folio || '-'}`}
                                                                        </span>
                                                                    </div>

                                                                    <div className='card-content-grid mt-1'>
                                                                        <span className='viewcard-container-courier'>
                                                                            {t('courier_information')}
                                                                        </span>
                                                                    </div>
                                                                    <div className='card-content-grid mt-1'>
                                                                        <p>{t('company')}</p>
                                                                    </div>
                                                                    <div className='card-content-grid'>
                                                                        <span style={{ textTransform: 'none' }}>
                                                                            <span className='font-weight-bold'>
                                                                                {item?.supplierCompanyAcronym} |
                                                                            </span>{' '}
                                                                            {item?.supplierCompanyName}
                                                                        </span>
                                                                    </div>
                                                                    {item?.statusId !== 28 && (
                                                                        <>
                                                                            <div className='card-content-grid'>
                                                                                <p>{t('employee')}</p>
                                                                            </div>
                                                                            <div className='card-content-grid'>
                                                                                {(() => {
                                                                                    const supplierFullName =
                                                                                        (item.supplierEmployeeName ||
                                                                                            '') +
                                                                                        ' ' +
                                                                                        (item.supplierEmployeeLastName ||
                                                                                            '') +
                                                                                        ' ' +
                                                                                        (item.supplierEmployeeSecondLastName ||
                                                                                            '');
                                                                                    return (
                                                                                        <span
                                                                                            title={supplierFullName}
                                                                                            style={{
                                                                                                textTransform: 'none',
                                                                                                maxWidth: '100%',
                                                                                                display: 'inline-block',
                                                                                                textOverflow:
                                                                                                    'ellipsis',
                                                                                                whiteSpace: 'nowrap',
                                                                                                overflow: 'hidden',
                                                                                            }}
                                                                                        >
                                                                                            {supplierFullName.trim() ||
                                                                                                '-'}
                                                                                        </span>
                                                                                    );
                                                                                })()}
                                                                            </div>
                                                                            <div className='card-content-grid'>
                                                                                <p>{t('vehicles')}</p>
                                                                            </div>
                                                                            <div className='card-content-grid mb-1'>
                                                                                {(() => {
                                                                                    const vehicleFullName =
                                                                                        (item.supplierVehicleBrand ||
                                                                                            '') +
                                                                                        ' ' +
                                                                                        (item.supplierVehicleSubBrand ||
                                                                                            '') +
                                                                                        ' ' +
                                                                                        (item.supplierVehicleModel ||
                                                                                            '') +
                                                                                        ' | ' +
                                                                                        (item.supplierVehiclePlate ||
                                                                                            '');
                                                                                    return (
                                                                                        <span
                                                                                            style={{
                                                                                                textTransform: 'none',
                                                                                                maxWidth: '100%',
                                                                                                display: 'inline-block',
                                                                                                textOverflow:
                                                                                                    'ellipsis',
                                                                                                whiteSpace: 'nowrap',
                                                                                                overflow: 'hidden',
                                                                                            }}
                                                                                        >
                                                                                            {vehicleFullName === '' ||
                                                                                            vehicleFullName.trim() ===
                                                                                                '|'
                                                                                                ? '-'
                                                                                                : vehicleFullName}
                                                                                        </span>
                                                                                    );
                                                                                })()}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    <Divider
                                                                        sx={{
                                                                            transform: 'translateY(-50%)',
                                                                            right: 0,
                                                                            left: `${t('data').length * 1 + 1}rem`,
                                                                            background: '#146F62',
                                                                            height: '1px !important',
                                                                            opacity: '1',
                                                                            borderRadius: '10px',
                                                                        }}
                                                                    />
                                                                    <div className='card-content-grid mt-1'>
                                                                        <span className='viewcard-container-courier'>
                                                                            {t('delivery_information')}
                                                                        </span>
                                                                    </div>
                                                                    <div className='card-content-grid mt-1'>
                                                                        <p>{t('eta')}</p>
                                                                        <span>
                                                                            {item.eta === null
                                                                                ? '-'
                                                                                : lCode === 'en'
                                                                                  ? moment(item.eta).format(
                                                                                        'MM/DD/YYYY HH:mm:ss',
                                                                                    )
                                                                                  : moment(item.eta).format(
                                                                                        'DD/MM/YYYY HH:mm:ss',
                                                                                    )}
                                                                        </span>
                                                                    </div>
                                                                    <div className='card-content-grid mt-1'>
                                                                        <p>{t('item')}</p>
                                                                        <span>{item?.item || '-'}</span>
                                                                    </div>
                                                                    <div className='card-content-grid mb-1'>
                                                                        <p>{t('description')} </p>
                                                                        <span className='text-right'>
                                                                            {item?.description || '-'}
                                                                        </span>
                                                                    </div>
                                                                    <Divider
                                                                        sx={{
                                                                            transform: 'translateY(-50%)',
                                                                            right: 0,
                                                                            left: `${t('data').length * 1 + 1}rem`,
                                                                            background: '#146F62',
                                                                            height: '1px !important',
                                                                            opacity: '1',
                                                                            borderRadius: '10px',
                                                                        }}
                                                                    />

                                                                    {item?.statusId == 30 && (
                                                                        <>
                                                                            <div className='card-content-grid mt-1'>
                                                                                <span className='viewcard-container-courier'>
                                                                                    {t('received_information')}
                                                                                </span>
                                                                            </div>
                                                                            <div className='card-content-grid mt-1'>
                                                                                {(() => {
                                                                                    const receiverFullName =
                                                                                        (item.userReceivedName || '') +
                                                                                        ' ' +
                                                                                        (item.userReceivedLastName ||
                                                                                            '') +
                                                                                        ' ' +
                                                                                        (item.userReceivedSecondLastName ||
                                                                                            '');
                                                                                    return (
                                                                                        <>
                                                                                            <p className='w-75'>
                                                                                                {t('received_by')}
                                                                                            </p>
                                                                                            <span
                                                                                                title={receiverFullName}
                                                                                                style={{
                                                                                                    textTransform:
                                                                                                        'none',
                                                                                                    maxWidth: '100%',
                                                                                                    display:
                                                                                                        'inline-block',
                                                                                                    textOverflow:
                                                                                                        'ellipsis',
                                                                                                    whiteSpace:
                                                                                                        'nowrap',
                                                                                                    overflow: 'hidden',
                                                                                                }}
                                                                                            >
                                                                                                {receiverFullName ||
                                                                                                    '-'}
                                                                                            </span>
                                                                                        </>
                                                                                    );
                                                                                })()}
                                                                            </div>
                                                                            <div className='card-content-grid mb-1'>
                                                                                <p>{t('delivery_date')}</p>
                                                                                <span>
                                                                                    {item.deliveryDate
                                                                                        ? new Date(
                                                                                              item.deliveryDate,
                                                                                          ).toLocaleString()
                                                                                        : '-'}
                                                                                </span>
                                                                            </div>
                                                                            <Divider
                                                                                sx={{
                                                                                    transform: 'translateY(-50%)',
                                                                                    right: 0,
                                                                                    left: `${
                                                                                        t('data').length * 1 + 1
                                                                                    }rem`,
                                                                                    background: '#146F62',
                                                                                    height: '1px !important',
                                                                                    opacity: '1',
                                                                                    borderRadius: '10px',
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <span className='viewcard-container-link mt-2 d-flex'>
                                                                {supplierId === null ? (
                                                                    <Link
                                                                        to={'order-details'}
                                                                        state={{ state: item }}
                                                                        onClick={() => {
                                                                            sessionStorage.setItem(
                                                                                'supplier_order_id',
                                                                                item?.id,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t('details')}{' '}
                                                                        <KeyboardArrowRightIcon
                                                                            style={{
                                                                                transform:
                                                                                    lCode === 'ar' ? 'scaleX(-1)' : '',
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                ) : item?.statusId == 28 ? (
                                                                    <Link
                                                                        to='/dashboard/supplier/complete-order'
                                                                        onClick={() => {
                                                                            sessionStorage.setItem(
                                                                                'supplier_order_id',
                                                                                item?.id,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t('complete')}{' '}
                                                                        <KeyboardArrowRightIcon
                                                                            style={{
                                                                                transform:
                                                                                    lCode === 'ar' ? 'scaleX(-1)' : '',
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                ) : (
                                                                    <NavLink
                                                                        to='/dashboard/supplier/order-detail'
                                                                        onClick={() => {
                                                                            sessionStorage.setItem(
                                                                                'supplier_order_id',
                                                                                item?.id,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t('details')}{' '}
                                                                        <KeyboardArrowRightIcon
                                                                            style={{
                                                                                transform:
                                                                                    lCode === 'ar' ? 'scaleX(-1)' : '',
                                                                            }}
                                                                        />
                                                                    </NavLink>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {view === 'list' && (
                                        <div style={{ height: '650px' }}>
                                            {' '}
                                            <TableViewSuppliers
                                                userFor='employee'
                                                dataApi={selectedData}
                                                selectOrderForDelete={selectOrderForDelete}
                                                isAllCheckedOrder={isAllCheckedOrder}
                                                handelDeleteAllOrders={handelDeleteAllOrder}
                                                handleCheckboxChangeOrder={handleCheckboxChangeOrder}
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                // !isSwitchOn &&
                                <div className='mt-4'>
                                    <NotInformation text={t('no_information')} card={true} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${toggleState === 2 ? 'tab-pane fade show active ' : 'tab-pane fade'}`}
                        id='pills-profile'
                        role='tabpanel'
                        aria-labelledby='pills-profile-tab'
                    >
                        <div className='providers animated-div-left'>
                            <div className='row top-buttons'>
                                <div className='container-top-right-btns'>
                                    <section className='simple-searcher--sectionSuppliers'>
                                        <SearchSimple
                                            searchElement={searchElement}
                                            setSearchElement={setSearchElement}
                                            setSearchResults={setSearchResults}
                                            setBodyForExport={setBodyForExport}
                                            optionTable={option}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                        />
                                    </section>
                                    <ImportDataBtn onClickFn={() => setImportFileShow(true)} />
                                    {!searchResults || !bodyForExport ? (
                                        <ExportDataBtn body={criteria} option={option} />
                                    ) : (
                                        <ExportDataBtn
                                            searchElement={searchElement}
                                            body={bodyForExport}
                                            option={option}
                                        />
                                    )}
                                    {permission?.includes(permissionObj?.WEB_SUPPLIER_CREATE) && (
                                        <button className='add-btn-1' onClick={() => navigate('add-suppliers')}>
                                            <i class='fa fa-plus' aria-hidden='true'></i>
                                            {t('add')}
                                        </button>
                                    )}
                                    {permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_DELETE) && (
                                        <button
                                            className='delete-btn-1'
                                            disabled={selectSupplierForDelete?.length === 0}
                                            onClick={() => {
                                                setDeleteSupplierShow(true);
                                            }}
                                        >
                                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                                            {t('delete')}
                                        </button>
                                    )}

                                    <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                                </div>
                            </div>
                            <div className='row' ref={elementRef}>
                                <div className='col-12'>
                                    <ProviderTable
                                        toggleState={toggleState}
                                        getAllPageableProvider={getAllPageableProvider}
                                        selectSupplierForDelete={selectSupplierForDelete}
                                        isAllChecked={isAllChecked}
                                        handelDeleteAll={handelDeleteAll}
                                        handleCheckboxChange={handleCheckboxChange}
                                        searchByFilters={selectedData}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact-form */}
            {toggleState == 1 && selectedData?.content?.length > 0 && (
                <div className='d-flex justify-content-center'>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={view === 'grid' ? [8, 16, 24, 32] : [20, 40, 60]}
                        count={selectedData?.totalElements}
                        page={page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage={t('purchase_orders_per_page')}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}

            <DeleteModal
                show={deleteOrderShow}
                onHide={() => setDeleteOrderShow(false)}
                onClickFn={() => deleteSelectedOrders(selectOrderForDelete)}
                data={selectOrderForDelete}
                title_modal={t('delete_orders')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('orders')}
            />
            <DeleteModal
                show={deleteSupplierShow}
                onHide={() => setDeleteSupplierShow(false)}
                onClickFn={() => deleteSelectedSuppliers(selectSupplierForDelete)}
                data={selectSupplierForDelete}
                title_modal={t('delete_suppliers')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('suppliers')}
            />
            {/* dialog for advance search */}
            <SearchFor
                open={filterDialogShow}
                onClose={() => {
                    setFilterDialogShow(false);
                }}
                onFiltered={(originalArray) => {
                    setFilterDialogShow(false);
                    setFinalArray(originalArray);
                }}
                moduleId={moduleId}
                option={option}
                finalArray={finalArray}
            />
            <ImportFileModal
                show={importFileShow}
                onHide={() => setImportFileShow(false)}
                onCreationErrors={() => setCreationErrorsShow(true)}
                onCreated={() => setLoaded(true)}
                title_modal={t('upload_form')}
                element_modal={t('suppliers')}
                model={'supplier'}
            />
        </div>
    );
};
export default ProvidersPanel;
