import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { GetDeviceLog, GetDeviceLogPageable } from '../../../../../reduxToolkit/Devices/DevicesApi';
import TablePagination from '@mui/material/TablePagination';
import { GetFreeCards } from '../../../../../reduxToolkit/Cards/CardsApi';
import { Tooltip, Checkbox } from '@mui/material';
import moment from 'moment';

import { colors } from '../../../../../enums/colors';
import { AssignCardToUser } from '../../../../../reduxToolkit/Cards/CardsApi';
import { update } from 'lodash';

const AssignFreeCard = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { getDeviceLog, getDeviceLogPageable } = useSelector((state) => state.DevicesSlice);
    const { getFreeCards } = useSelector((state) => state.CardsSlice);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectCardToAssign, setSelectCardToAssign] = useState([]);
    const [selectItems, setSelectItems] = useState(props.cardsAssign);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const boxStyle = {
        boxShadow: `0px 8px 0px 0px ${colors['accent-grey']}`,
    };

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getFreeCards?.map((item) => {
                return item?.id;
            });
            setSelectCardToAssign(selectAllIds);
            setSelectItems(getFreeCards);
        } else {
            setSelectCardToAssign([]);
            setSelectItems([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e, item) => {
        if (e.target.checked) {
            setSelectCardToAssign([...selectCardToAssign, e.target.id]);
            setSelectItems([...selectItems, item]);
        } else {
            setSelectCardToAssign(selectCardToAssign.filter((removeid) => removeid !== e.target.id));
            setSelectItems(selectItems.filter((removeid) => removeid.id !== e.target.id));
        }
    };

    const closeModal = () => {
        setSelectItems(props.cardsAssign);
        props.onHide();
        props.onLog();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const uuid = props?.data?.id;

    const flag = props.flag;

    //This section call to the api to obtain the respectively logs
    useEffect(() => {
        if (flag) {
            // dispatch(GetDeviceLog(uuid));
            const data = {
                id: uuid,
                body: {
                    page: page,
                    size: rowsPerPage,
                },
            };
            dispatch(GetDeviceLogPageable(data));
        } else {
            setPage(0);
            setRowsPerPage(20);
        }
    }, [flag, page, rowsPerPage]);

    //function to handle confirm
    const handleAssign = () => {
        if (props.componentAction === 'update') {
            const body = {
                cardIds: selectCardToAssign,
                userId: props.userData.id,
            };
            dispatch(AssignCardToUser(body)).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        window.postMessage(
                            {
                                type: 'sessionStorageChangeLoader',
                                newValueLoading: true,
                            },
                            '*',
                        );
                        setSelectCardToAssign([]);
                        props.onUpdated();
                        props.onHide();
                    }
                },
            );
        } else {
            props.SetCardsAssign(selectItems);
            props.onHide();
        }
    };

    useEffect(() => {
        setSelectCardToAssign([]);
        if (props.show) {
            dispatch(GetFreeCards());
        }
    }, [props.show]);

    useEffect(() => {
        if (props.cardsAssign === undefined) {
            setSelectItems([]);
        } else {
            setSelectItems(props.cardsAssign);
        }
        if (props.show) {
            setSelectItems(props.cardsAssign);
        }
    }, [props.cardsAssign]);

    return (
        <Modal
            className='log-modal'
            {...props}
            //
            size='xl'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('assign_cards')}</Modal.Title>
                <img
                    onClick={() => {
                        closeModal();
                    }}
                    className='modalClose'
                    src={cancel}
                    alt=''
                />
                <div className='panelTables ml-1' style={{ width: '96%', marginBottom: '-20px' }}>
                    {getFreeCards?.length > 0 ? (
                        <table className='w-100'>
                            <thead className='no-border-thead'>
                                <th className='first_head' style={{ width: '50px' }}>
                                    <Tooltip
                                        title={t('de_/_select_all').toUpperCase()}
                                        placement='top'
                                        disableInteractive
                                    >
                                        <Checkbox
                                            className='grid-checkall checkbox'
                                            // checked={isAllChecked}
                                            checked={getFreeCards?.length === selectItems?.length}
                                            indeterminate={
                                                selectCardToAssign?.length > 0 &&
                                                getFreeCards?.length !== selectCardToAssign.length
                                            }
                                            onChange={handelDeleteAll}
                                            size='small'
                                        />
                                    </Tooltip>
                                </th>
                                <th className='first_head'>{t('number')}</th>
                                <th>{t('facility_code')}</th>
                                <th>{t('format')}</th>
                                <th className='last'>{t('created_at')}</th>
                            </thead>
                        </table>
                    ) : null}
                </div>
            </Modal.Header>
            <Modal.Body className='log-modal-body' style={boxStyle}>
                {
                    <div className='panelTables' style={{ marginTop: '-90px' }}>
                        {getFreeCards?.length > 0 ? (
                            <table className='w-100'>
                                <thead style={{ top: '0px' }}>
                                    <th className='first_head' style={{ width: '50px' }}>
                                        <Tooltip
                                            title={t('de_/_select_all').toUpperCase()}
                                            placement='top'
                                            disableInteractive
                                        >
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={isAllChecked}
                                                onChange={handelDeleteAll}
                                                size='small'
                                            />
                                        </Tooltip>
                                    </th>
                                    <th className='first_head'>{t('number')}</th>
                                    <th>{t('facility_code')}</th>
                                    <th>{t('format')}</th>
                                    <th className='last'>{t('created_at')}</th>
                                </thead>
                                {getFreeCards?.length > 0 &&
                                    getFreeCards.map((item) => {
                                        return (
                                            <tr key={item?.id}>
                                                <td
                                                    className='first'
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        width: '50px',
                                                        borderRadius: '6px 0 0 6px',
                                                    }}
                                                >
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={selectItems?.some(
                                                            (selectItem) => selectItem.id === item.id,
                                                        )}
                                                        id={item.id}
                                                        onChange={(e) => {
                                                            handleCheckboxChange(e, item);
                                                        }}
                                                        size='small'
                                                    />
                                                </td>
                                                <td className='first' style={{ borderRadius: '0' }}>
                                                    {item?.number}
                                                </td>
                                                <td style={{ borderRadius: '0' }}>{item?.facilityCode}</td>
                                                <td style={{ borderRadius: '0' }}>{item?.cardTypeName}</td>
                                                <td style={{ borderRadius: '0 6px 6px 0' }}>
                                                    {lCode === 'en' ? (
                                                        <span>
                                                            {moment(item?.createdAt).format('MM/DD/YYYY')}
                                                            <br />
                                                            {moment(item?.createdAt).format('HH:mm:ss')}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {moment(item?.createdAt).format('DD/MM/YYYY')}
                                                            <br />
                                                            {moment(item?.createdAt).format('HH:mm:ss')}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                        // }
                                    })}
                            </table>
                        ) : null}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer style={{ border: 0 }}>
                {getFreeCards?.length > 0 && (
                    <button
                        className='assign-btn'
                        style={{ width: '100%' }}
                        onClick={() => {
                            handleAssign();
                        }}
                    >
                        {t('assign')?.toUpperCase()}
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default AssignFreeCard;
