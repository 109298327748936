import React from 'react';
import '../../scss/workoffice.scss';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import person4 from '../../assets/images/person-4.png';
import { CheckInWorkOffice, CheckOutWorkOffice, GetWorkOffice } from '../../reduxToolkit/Department/DepartmentApi';
import moment from 'moment';
import { getUserSelfie } from '../../Apis/backgroundChange';
import { set } from 'lodash';
import { GetUserFullInformation } from '../../reduxToolkit/Commons/CommonsApi';

const WorkOffice = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const { getWorkOffice, checkInWorkOffice, checkOutWorkOffice } = useSelector(
        (state) => state.DepartmentSectionSlice,
    );
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const [profilePic, setProfilePic] = useState(null);
    const [userEmployeeId, setUserEmployeeId] = useState(sessionStorage.getItem('id'));
    const { getUserFullInformation } = useSelector((state) => state.CommonsSlice);

    const checkInFn = () => {
        const data = {
            accessMethodIdIn: 9,
        };
        dispatch(CheckInWorkOffice(data)).then(({ payload: { data } }) => {
            setCheckIn(data?.data?.checkIn);
        });
    };
    const checkOutFn = () => {
        const data = {
            accessMethodIdOut: 9,
        };
        dispatch(CheckOutWorkOffice(data)).then(({ payload: { data } }) => {
            setCheckOut(data?.data?.checkOut);
        });
    };

    useEffect(() => {
        dispatch(GetWorkOffice()).then(({ payload: { data } }) => {});
    }, [checkIn, checkOut]);

    useEffect(() => {
        const fetchGetPhotoFromUser = async () => {
            await dispatch(GetUserFullInformation(userEmployeeId));

            if (userEmployeeId) {
                fetchGetPhotoFromUser();
            }
        };
    }, [userEmployeeId]); // Dependencia: se llama cuando userEmployeeId cambia

    return (
        <div className='work_office_container'>
            <div className='work_office_items'>
                <div className='card'>
                    <h3>{t('home_office').toUpperCase()}</h3>
                    <img
                        className='avatar avatar-fit-container'
                        width='118px'
                        height='118px'
                        src={
                            getUserFullInformation.selfie
                                ? `data:image/png;base64,${getUserFullInformation?.selfie}`
                                : person4
                        }
                        alt=''
                    />
                    {(getWorkOffice.checkIn === null || getWorkOffice.checkOut === null) && (
                        <h4>{t('!Welcome').toUpperCase()}</h4>
                    )}
                    {getWorkOffice.checkIn != null && getWorkOffice.checkOut != null && (
                        <h4>{t('today_shift_has_ended').toUpperCase()}</h4>
                    )}
                    {getWorkOffice.checkIn && (
                        <div className='check_in'>
                            <h2>{t('check_in_at').toUpperCase()}</h2>
                            <h2>{moment(getWorkOffice.checkIn).format('HH:mm:ss')}</h2>
                        </div>
                    )}
                    {getWorkOffice.checkIn && getWorkOffice.checkOut && (
                        <div className='check_in'>
                            <h2>{t('check_out_at').toUpperCase()}</h2>
                            <h2>{moment(getWorkOffice.checkOut).format('HH:mm:ss')}</h2>
                        </div>
                    )}
                    {getWorkOffice.checkIn === null && (
                        <Box sx={{ width: '100%' }}>
                            <button onClick={() => checkInFn()}>
                                {t('check-in')?.toUpperCase()}
                                <ExitToAppIcon />
                            </button>
                        </Box>
                    )}
                    {getWorkOffice.checkIn != null && getWorkOffice.checkOut === null && (
                        <Box sx={{ width: '100%' }}>
                            <button onClick={() => checkOutFn()}>
                                {t('check-out')?.toUpperCase()}
                                <ExitToAppIcon />
                            </button>
                        </Box>
                    )}
                </div>
                <div className='overlay'></div>
            </div>
        </div>
    );
};

export default WorkOffice;
