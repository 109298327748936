import { Tooltip } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Circle, Image, Layer, Rect, Stage, Text } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/images/print-solid.svg';
import { colors } from '../../../../../enums/colors';
import { AssignCardToUser, GetFreeCards } from '../../../../../reduxToolkit/Cards/CardsApi';
import { GetDeviceLogPageable } from '../../../../../reduxToolkit/Devices/DevicesApi';
import { ReactComponent as DashIcon } from '../../../../../assets/images/minus-solid.svg';
import { GetPreview } from '../../../../../reduxToolkit/Cards/CardsApi';
import { clearPreview } from '../../../../../reduxToolkit/Cards/CardsSlice';

const PreviewModal = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { getDeviceLog, getDeviceLogPageable } = useSelector((state) => state.DevicesSlice);
    const { getFreeCards, getPreview } = useSelector((state) => state.CardsSlice);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectCardToAssign, setSelectCardToAssign] = useState([]);
    const [previewRaw, setPreviewRaw] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const stageFront = useRef();
    const stageBack = useRef();

    const cardWidth = 204.09448818897638;
    const cardHeight = 325.0393700787401;
    const cardBackgroundColor = 'white';

    const boxStyle = {
        boxShadow: `0px 8px 0px 0px ${colors['accent-grey']}`,
    };

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getFreeCards?.map((item) => {
                return item?.id;
            });
            setSelectCardToAssign(selectAllIds);
        } else {
            setSelectCardToAssign([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectCardToAssign([...selectCardToAssign, e.target.id]);
        } else {
            setSelectCardToAssign(selectCardToAssign.filter((removeid) => removeid !== e.target.id));
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const uuid = props?.data?.id;

    const flag = props.flag;

    //This section call to the api to obtain the respectively logs
    useEffect(() => {
        if (flag) {
            // dispatch(GetDeviceLog(uuid));
            const data = {
                id: uuid,
                body: {
                    page: page,
                    size: rowsPerPage,
                },
            };
            dispatch(GetDeviceLogPageable(data));
        } else {
            setPage(0);
            setRowsPerPage(20);
        }
    }, [flag, page, rowsPerPage]);

    //function to handle confirm
    const handleAssign = () => {
        const body = {
            cardIds: selectCardToAssign,
            userId: props.userData.id,
        };
        dispatch(AssignCardToUser(body)).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                if (success === true) {
                    window.postMessage(
                        {
                            type: 'sessionStorageChangeLoader',
                            newValueLoading: true,
                        },
                        '*',
                    );
                    setSelectCardToAssign([]);
                    props.onUpdated();
                    props.onHide();
                }
            },
        );
    };

    useEffect(() => {
        if (props.show === true) {
            dispatch(GetPreview(props.currentId)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        setPreviewRaw(JSON.parse(data.raw));
                    }
                },
            );
        }
        if (props.show === false) {
            setPreviewRaw(null);
        }
    }, [props.show]);

    const handleExport = (stage) => {
        const uri = stage.current.toDataURL();
        // we also can save uri as file
        // but in the demo on Konva website it will not work
        // because of iframe restrictions
        // but feel free to use it in your apps:
        // downloadURI(uri, 'stage.png');
    };

    return (
        <Modal
            className='log-modal'
            {...props}
            //
            size='xl'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            backdrop='static'
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('preview')}</Modal.Title>
                <img
                    onClick={() => {
                        props.onHide();
                        props.onLog();
                    }}
                    className='modalClose'
                    src={cancel}
                    alt=''
                />
            </Modal.Header>
            <Modal.Body
                style={{
                    marginTop: '4px',
                    padding: 0,
                    display: 'flex',
                }}
            >
                <div className='row w-100'>
                    <div className='col-6 preview'>
                        <div className='d-flex justify-content-evenly w-100'>
                            <div style={{ width: '24px' }}></div>
                            <p className='preview-tilte mt-2'>{t('front_view')}</p>
                            <div className='icon-btn'>
                                <Tooltip
                                    title={t('print_front_view').toUpperCase()}
                                    placement='top'
                                    arrow
                                    disableInteractive={true}
                                >
                                    <button
                                        className='btn-option'
                                        onClick={() => {
                                            props.onPrint(stageFront.current.toDataURL());
                                        }}
                                    >
                                        <PrintIcon width='16px' height='16px' fill={colors.grey} />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='preview-container'>
                            <Stage ref={stageFront} width={cardWidth} height={cardHeight}>
                                <Layer>
                                    {/* Background color */}
                                    <Rect
                                        id='background'
                                        width={cardWidth}
                                        height={cardHeight}
                                        fill={cardBackgroundColor}
                                    />

                                    {previewRaw &&
                                        previewRaw[1].combinedObject.map((shape, index) => {
                                            if (shape.property === 'squares' || shape.property === 'lines') {
                                                return (
                                                    <Rect
                                                        x={shape.x - 55}
                                                        y={shape.y - 25}
                                                        rotation={shape.rotation}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        cornerRadius={shape.cornerRadius}
                                                        stroke={shape.stroke}
                                                        strokeWidth={shape.strokeWidth}
                                                        id={shape.id}
                                                        fill={shape.fill}
                                                    />
                                                );
                                            } else if (shape.property === 'ellipsis') {
                                                return (
                                                    <Circle
                                                        x={shape.x - 55}
                                                        y={shape.y - 25}
                                                        rotation={shape.rotation}
                                                        radius={shape.radius}
                                                        fill={shape.fill}
                                                        stroke={shape.stroke}
                                                        strokeWidth={shape.strokeWidth}
                                                        id={shape.id}
                                                    />
                                                );
                                            } else if (shape.property === 'texts') {
                                                return (
                                                    <Text
                                                        x={shape.x - 55}
                                                        y={shape.y - 25}
                                                        text={shape.content}
                                                        fontSize={shape.fontSize}
                                                        fontFamily={shape.fontFamily}
                                                        fontStyle={shape.fontStyle}
                                                        textDecoration={shape.textDecoration}
                                                        fill={shape.fill}
                                                        align={shape.align}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        wrap={true}
                                                        id={shape.id}
                                                    />
                                                );
                                            } else if (shape.property === 'resources') {
                                                const imageObject = new window.Image();
                                                imageObject.src = shape.base64;
                                                return (
                                                    <Image
                                                        image={imageObject}
                                                        x={shape.x - 55}
                                                        y={shape.y - 25}
                                                        rotation={shape.rotation}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        id={shape.id}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                </Layer>
                            </Stage>
                        </div>
                    </div>
                    <div className='col-6 preview'>
                        <div className='d-flex justify-content-evenly w-100'>
                            <div style={{ width: '24px' }}></div>
                            <p className='preview-tilte mt-2'>{t('back_view')}</p>
                            <div className='icon-btn'>
                                <Tooltip
                                    title={t('print_back_view').toUpperCase()}
                                    placement='top'
                                    arrow
                                    disableInteractive={true}
                                >
                                    <button
                                        className='btn-option'
                                        onClick={() => {
                                            props.onPrint(stageBack.current.toDataURL());
                                        }}
                                    >
                                        <PrintIcon width='16px' height='16px' fill={colors.grey} />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='preview-container'>
                            <Stage ref={stageBack} width={cardWidth} height={cardHeight}>
                                <Layer>
                                    {/* Background color */}
                                    <Rect
                                        id='background'
                                        width={cardWidth}
                                        height={cardHeight}
                                        fill={cardBackgroundColor}
                                    />

                                    {previewRaw &&
                                        previewRaw[2].combinedObject.map((shape, index) => {
                                            if (shape.property === 'squares') {
                                                return (
                                                    <Rect
                                                        x={shape.x - 55}
                                                        y={shape.y - 25}
                                                        rotation={shape.rotation}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        cornerRadius={shape.cornerRadius}
                                                        stroke={shape.stroke}
                                                        strokeWidth={shape.strokeWidth}
                                                        id={shape.id}
                                                        //ref={shapeRef}
                                                        fill={shape.fill}
                                                    />
                                                );
                                            } else if (shape.property === 'ellipsis') {
                                                return (
                                                    <Circle
                                                        x={shape.x - 55}
                                                        y={shape.y - 25}
                                                        rotation={shape.rotation}
                                                        radius={shape.radius}
                                                        fill={shape.fill}
                                                        stroke={shape.stroke}
                                                        strokeWidth={shape.strokeWidth}
                                                        id={shape.id}
                                                    />
                                                );
                                            } else if (shape.property === 'texts') {
                                                return (
                                                    <Text
                                                        x={shape.x - 55}
                                                        y={shape.y - 25}
                                                        text={shape.content}
                                                        fontSize={shape.fontSize}
                                                        fontFamily={shape.fontFamily}
                                                        fontStyle={shape.fontStyle}
                                                        textDecoration={shape.textDecoration}
                                                        fill={shape.fill}
                                                        align={shape.align}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        wrap={true}
                                                        id={shape.id}
                                                    />
                                                );
                                            } else if (shape.property === 'resources') {
                                                const imageObject = new window.Image();
                                                imageObject.src = shape.base64;
                                                return (
                                                    <Image
                                                        image={imageObject}
                                                        x={shape.x - 55}
                                                        y={shape.y - 25}
                                                        rotation={shape.rotation}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        id={shape.id}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                </Layer>
                            </Stage>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer style={{ border: 0 }}></Modal.Footer> */}
        </Modal>
    );
};

export default PreviewModal;
