import React, { useEffect, useRef } from 'react';
import { Group, Image, Transformer } from 'react-konva';

const ImageComponent = ({
    image,
    index,
    selectedShapeId,
    handleDragMove,
    handleShapeClick,
    properties,
    currentStage,
    setStageProperties,
    setShapeHeight,
    setShapeWidth,
}) => {
    const shapeRef = useRef(null);
    const trRef = useRef(null);
    const MAX_WIDTH = 305;
    const MAX_HEIGHT = 462;
    const isSelected = image.id === selectedShapeId;

    useEffect(() => {
        if (selectedShapeId === image.id) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedShapeId, image.id]);

    const imageObject = new window.Image();
    imageObject.src = image.base64;

    return (
        <Group key={image.id} id={`group_${image.id}`}>
            <Image
                image={imageObject}
                x={image.x}
                y={image.y}
                rotation={image.rotation}
                width={image.width}
                height={image.height}
                id={image.id}
                ref={shapeRef}
                draggable={isSelected}
                onDragMove={(e) => handleDragMove(e, index)}
                onClick={() => handleShapeClick(image.id, 'resources')}
                onTap={() => handleShapeClick(image.id, 'resources')}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    node.scaleX(1);
                    node.scaleY(1);

                    const newWidth = Math.round(node.width() * scaleX);
                    const newHeight = Math.round(node.height() * scaleY);

                    // setStageProperties((prevProperties) => {
                    //     const newProperties = { ...prevProperties };

                    //     if (newProperties[currentStage] && typeof newProperties[currentStage] === 'object') {
                    //         newProperties[currentStage].squares[index].width = newWidth;
                    //         newProperties[currentStage].squares[index].height = newHeight;
                    //         newProperties[currentStage].squares[index].x = node.x();
                    //         newProperties[currentStage].squares[index].y = node.y();
                    //     }

                    //     return newProperties;
                    // });

                    setShapeHeight(newHeight);
                    setShapeWidth(newWidth);

                    setStageProperties((prevProperties) => {
                        const currentProperties = prevProperties[currentStage];
                        const updatedSquares = [...currentProperties.combinedObject];

                        updatedSquares[index] = {
                            ...updatedSquares[index],
                            width: newWidth,
                            height: newHeight,
                            x: node.x(),
                            y: node.y(),
                            rotation: node.rotation(),
                        };

                        return {
                            ...prevProperties,
                            [currentStage]: {
                                ...currentProperties,
                                combinedObject: updatedSquares,
                            },
                        };
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={true}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width > MAX_WIDTH || newBox.height > MAX_HEIGHT) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Group>
    );
};

export default ImageComponent;
