import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
    TextField,
    Box,
    FormControl,
    FormControlLabel,
    Checkbox,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    GetAccessType,
    GetDeviceSmartlock,
    GetDeviceType,
    GetDeviceZone,
    GetListStatusZone,
    UpdateDeviceSmartlock,
    UpdateDeviceZone,
} from '../../../reduxToolkit/EmployeeZones/EmployeeZonesApi';
import {
    GetAntiPassBackType,
    GetEscortMode,
    GetAuthentication,
    GetDeviceDetails,
    GetDeviceAirbnkLock,
    CreateAirbnkLock,
    CreateDeviceApi,
    UpdateDeviceApi,
    DeleteAirbnk,
    UpdateAirbnk,
    DeleteSmartLock,
    GetListStatusDevice,
} from '../../../reduxToolkit/Devices/DevicesApi';
import { getWorkStations } from '../../../reduxToolkit/CompanyEmployees/CompanyEmployeesApi';
import { GetAllZone } from '../../../reduxToolkit/EmployeeOnBoarding/EmployeeOnBoardingApi';
import { GetAllMedia } from '../../../reduxToolkit/Commons/CommonsApi';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import ClearButton from '../../../components/ClearButton';
import DeleteModal from '../../Modals/DeleteModal';
import { TABLES } from '../../../Apis/Tables';
import ic_left_arrow from '../../../assets/images/ic_left_arrow.svg';
import { permissionObj } from '../../../Helpers/permission';
import FullPageLoader from '../../../utils/FullPageLoader';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DividerWithText from '../../../components/DividerWithText';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import { errorCode } from '../../../enums/errorCodeEnum';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import { timeZone } from '../../../enums/timeZoneEnum';
const CreateUpdateDevices = () => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { getAccessType } = useSelector((state) => state.EmployeeZonesSlice);
    const {
        getAntiPassBackType,
        getEscortMode,
        getAuthentication,
        getDeviceAirbnkLock,
        deleteAirbnk,
        deleteSmartLock,
        getListStatusDevice,
    } = useSelector((state) => state.DevicesSlice);
    const { getDeviceType, getListStatusZone } = useSelector((state) => state.EmployeeZonesSlice);
    const { getallzone } = useSelector((state) => state.employeeOnBoardingSlice);
    const { getAllMedia } = useSelector((state) => state.CommonsSlice);
    const { employeeWorkStations } = useSelector((state) => state.CompanyEmployeesSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    // Props to the delete modal window
    const title_modal = `delete_devices`;
    const element_modal = `device_s`;
    const delete_table = `${TABLES.DEVICES}`;

    // use State hook for local state management
    const [lockDeleted, setLockDeleted] = useState(false);
    const [deleteDeviceShow, setDeleteDeviceShow] = useState(false);
    const [selectDeviceForDelete, setSelectDeviceForDelete] = useState([]);
    const [deletedFlag, setDeletedFlag] = useState(false);

    const { zoneDetailFatherAndChild } = useSelector((state) => state.EmployeeZonesSlice);

    const [deviceName, setDeviceName] = React.useState('');
    const [deviceId, setDeviceId] = React.useState('');
    const [idDevice, setIdDevice] = React.useState('');
    const [zoneName, setZoneName] = React.useState('');
    const [zone, setZone] = React.useState('');
    const [deviceType, setDeviceType] = React.useState('');
    const [statusValue, setStatusValue] = React.useState('');
    const [ip, setIp] = React.useState('');
    const [accessType, setAccessType] = React.useState('');
    const [antiPassBackType, setAntiPassBackType] = React.useState('');
    const [timeZoneValue, setTimeZoneValue] = React.useState('');
    const [cameraTypeId, setCameraTypeId] = React.useState('');
    const [antiPassBackTime, setAntiPassBackTime] = React.useState('');
    const [antiPassBackAlert, setAntiPassBackAlert] = React.useState('');
    const [escortMode, setEscortMode] = React.useState('');
    const [sn, setSn] = React.useState('');
    const [mac, setMac] = React.useState('');
    const [firebaseId, setFirebaseId] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [syncTime, setSyncTime] = React.useState('');
    const [validationNumber, setValidationNumber] = React.useState('');
    const [description, setDescription] = React.useState(null);
    const [smartCard, setSmartCard] = React.useState(false);
    const [facialRecognition, setFacialRecognition] = React.useState(false);
    const [liveness, setLiveness] = React.useState(false);
    const [qr, setQr] = React.useState(false);
    const [pin, setPin] = React.useState(false);
    const [fingerprint, setFingerprint] = React.useState(false);
    const [bluetooth, setBluetooth] = React.useState(false);
    const [showLogo, setShowLogo] = React.useState(false);
    const [showUserList, setShowUserList] = React.useState(false);
    const [synchorizeSelfies, setSynchorizeSelfies] = React.useState(false);
    const [playSounds, setPlaySounds] = React.useState(false);
    const [smartLockId, setSmartLockId] = React.useState('');
    const [smartLockData, setSmartLockData] = React.useState('');
    const [smartLockBattery, setSmartLockBattery] = React.useState('');
    const [smartLockMac, setSmartLockMac] = React.useState('');
    const [smartLockBatteryTime, setSmartLockBatteryTime] = React.useState('');
    const [airbnkLockId, setAirbnkLockId] = React.useState('');
    const [airbnkLockKey, setAirbnkLockKey] = React.useState('');
    const [airbnkLockSerialNumber, setAirbnkLockSerialNumber] = React.useState('');

    const [requestSignature, setRequestSignature] = React.useState('');
    const [requestSignatureAlways, setRequestSignatureAlways] = React.useState('');
    const [enableTTLock, setEnableTTLock] = React.useState(false);
    const [enableAirbnk, setEnableAirbnk] = React.useState(false);
    const [enableRelay, setEnableRelay] = React.useState('');
    const [enableRelay0, setEnableRelay0] = React.useState(false);
    const [enableRelay1, setEnableRelay1] = React.useState(false);
    const [enableRelaySR3, setEnableRelaySR3] = React.useState('');
    const [enableRelaySR34ch, setEnableRelaySR34ch] = React.useState('');
    const [linked, setLinked] = React.useState(false);
    const [deviceRestriction, setDeviceRestriction] = React.useState('');
    const [loadingProgress, setLoadingProgress] = useState(true);
    const [showPassword, setShowPassword] = React.useState(false);
    const [authentication, setAuthentication] = useState('');
    const [readAfter, setReadAfter] = useState('');
    const [delayToOpen, setDelayToOpen] = useState('');
    const [timeForRelay0, setTimeForRelay0] = useState('');
    const [consecutiveOpeningsAllowed, setConsecutiveOpeningsAllowed] = useState('');
    const [timeForRelay1, setTimeForRelay1] = useState('');
    const [unlinkShow, setUnlinkShow] = useState(false);
    const [showWeapon, setShowWeapon] = useState(false);
    const [showVehicle, setShowVehicle] = useState(false);
    const [showLed, setShowLed] = useState(false);
    const [showAccessDialog, setShowAccessDialog] = useState(false);
    const [printer, setPrinter] = useState(false);
    const [deviceTypeAuthentications, setDeviceTypeAuthentications] = useState([]);

    const handleTimeZoneChange = (event) => {
        setTimeZoneValue(event.target.value);
    };

    //Enum to determine what type of reader and lock is able for each type of device
    const allowedCheckboxes = {
        1: ['fingerprint', 'rfid', 'qr', 'airbnk', 'ttlock', 'relay0'],
        2: ['fingerprint', 'rfid', 'qr', 'airbnk', 'ttlock'],
        3: ['facialRecognition', 'fingerprint', 'rfid', 'qr', 'airbnk', 'ttlock', 'relay0', 'relay1'],
        4: ['facialRecognition', 'rfid', 'qr', 'airbnk', 'ttlock', 'relay0'],
        5: ['fingerprint', 'rfid', 'qr', 'airbnk', 'ttlock'],
        6: ['facialRecognition', 'rfid', 'qr', 'airbnk', 'ttlock', 'relay0'],
        7: ['facialRecognition', 'rfid', 'qr', 'airbnk', 'ttlock', 'relay0'],
        8: ['facialRecognition', 'rfid', 'qr', 'airbnk', 'ttlock', 'relay0', 'relay1'],
        9: ['facialRecognition', 'rfid', 'qr'],
        10: [],
        11: [],
        12: [],
        13: [],
    };

    const lockChecked = enableAirbnk || enableRelay0 || enableRelay1 || enableTTLock;

    //Enum to deteterminate what type of athentication is able for each type of device
    const allowedAuthentications = {
        1: [1, 2],
        2: [1],
        3: [1, 2, 3],
        4: [1],
        5: [1, 2],
        6: [1],
        7: [1, 3],
        8: [1, 3],
        9: [1, 3],
        10: [1, 3],
        11: [1, 3],
        12: [1, 3],
        13: [1, 3],
    };

    // This section cleans the Airbnk required fields if enableAirbnk is false
    useEffect(() => {
        if (enableAirbnk === false) {
            setAirbnkLockKey('');
            setAirbnkLockSerialNumber('');
        }
    }, [enableAirbnk]);

    useEffect(() => {
        if (antiPassBackType === 1) {
            setAntiPassBackAlert(1);
            setAntiPassBackTime(30);
        }
    }, [antiPassBackType]);

    useEffect(() => {
        setSmartCard(false);
        setFacialRecognition(false);
        setLiveness(false);
        setFingerprint(false);
        setQr(false);
        setPin(false);
        setBluetooth(false);
        setEnableRelay0(false);
        setEnableRelay1(false);
        setEnableAirbnk(false);
        setEnableTTLock(false);
        setShowLed(false);
        setPrinter(false);
        setAuthentication('');
        setDeviceTypeAuthentications(allowedAuthentications[deviceType]);
    }, [deviceType]);

    // This section re-route us when a device is deleted
    useEffect(() => {
        if (deletedFlag) {
            location?.state?.zoneNameProps !== undefined
                ? navigate('/dashboard/employee/zones/singlezonedetails')
                : navigate('/dashboard/employee/devices');
        }
    }, [deletedFlag]);

    useEffect(() => {
        dispatch(GetListStatusZone());
        dispatch(GetListStatusDevice());
        dispatch(GetAccessType());
        dispatch(GetDeviceType());
        dispatch(GetAntiPassBackType());
        dispatch(GetEscortMode());
        dispatch(GetAuthentication());
        dispatch(GetAllZone());
        dispatch(GetAllMedia());
        dispatch(getWorkStations());
        {
            if (location?.state?.zoneNameProps !== undefined) {
                setZoneName(zoneDetailFatherAndChild?.id);
            }
        }

        if (id !== undefined) {
            dispatch(GetDeviceDetails(id)).then(
                ({
                    payload: {
                        data: { data },
                    },
                }) => {
                    setDeviceName(data?.name);
                    setIdDevice(data?.deviceId);
                    setDeviceId(data?.id);
                    setZoneName(data?.zoneId);
                    setZone(data?.zoneName);
                    setDeviceType(data?.deviceTypeId);
                    setStatusValue(data?.statusId);
                    setIp(data?.ip);
                    setDescription(data?.description);
                    setPassword(data?.password);
                    setAccessType(data?.deviceAccessTypeId);
                    setAntiPassBackType(data?.antiPassBackId);
                    setTimeZoneValue(data?.timeZoneId);
                    setCameraTypeId(data?.cameraTypeId);
                    setAntiPassBackTime(data?.antiPassBackTime);
                    setAntiPassBackAlert(data?.antiPassBackAlertById);
                    setEscortMode(data?.escortId);
                    setAuthentication(data?.authenticationId);
                    setReadAfter(data?.readAfter);
                    setSn(data?.serialNumber);
                    setMac(data?.mac);
                    setFirebaseId(data?.firebaseId);
                    setPassword(data?.password);
                    setSyncTime(data?.syncTime);
                    setValidationNumber(data?.validationNumber);
                    setSmartCard(data?.rfidReader);
                    setFacialRecognition(data?.facialRecognition);
                    setLiveness(data?.liveness);
                    setQr(data?.qrCodeReader);
                    setPin(data?.pin);
                    setFingerprint(data?.fingerprintReader);
                    setBluetooth(data?.bluetoothReader);
                    setShowLogo(data?.showLogo);
                    setShowUserList(data?.showUserList);
                    setSynchorizeSelfies(data?.synchronizeSelfie);
                    setPlaySounds(data?.playSound);
                    setRequestSignature(data?.requestSignature);
                    setRequestSignatureAlways(data?.requestSignatureAlways);
                    setEnableTTLock(data?.enableTTLock);
                    setEnableAirbnk(data?.enableAirbnk);
                    setEnableRelay(data?.enableRelay);
                    setEnableRelay0(data?.enableRelay0);
                    setEnableRelay1(data?.enableRelay1);
                    setEnableRelaySR3(data?.enableRelaySR3);
                    setEnableRelaySR34ch(data?.enableRelaySR34ch);
                    setDeviceRestriction(data?.deviceRestrictionId);
                    setDelayToOpen(data?.delayToOpen);
                    setTimeForRelay0(data?.enableRelay0 ? data?.timeForRelay0 : '');
                    setConsecutiveOpeningsAllowed(data?.consecutiveOpeningsAllowed);
                    setTimeForRelay1(data?.enableRelay1 ? data?.timeForRelay1 : '');
                    setShowWeapon(data?.showWeapon);
                    setShowVehicle(data?.showVehicle);
                    setShowLed(data?.showLed);
                    setShowAccessDialog(data?.showAccessDialog);
                    setPrinter(data?.printer);
                    {
                        data?.enableAirbnk &&
                            !lockDeleted &&
                            dispatch(GetDeviceAirbnkLock(id)).then(
                                ({
                                    payload: {
                                        data: { data },
                                    },
                                }) => {
                                    setAirbnkLockId(data?.id);
                                    setAirbnkLockKey(data?.key);
                                    setAirbnkLockSerialNumber(data?.serialNumber);
                                    setSmartLockMac(data?.mac);
                                    setSmartLockBatteryTime(data?.batteryTime);
                                    if (data?.id !== undefined) {
                                        setLinked(true);
                                    }
                                },
                            );
                    }
                    {
                        data?.enableTTLock &&
                            !lockDeleted &&
                            dispatch(GetDeviceSmartlock(id)).then(
                                ({
                                    payload: {
                                        data: { data },
                                    },
                                }) => {
                                    setSmartLockId(data?.id);
                                    setSmartLockData(data?.data);
                                    setSmartLockBattery(data?.battery);
                                    setSmartLockMac(data?.mac);
                                    setSmartLockBatteryTime(data?.batteryTime);
                                    if (data?.id !== undefined) {
                                        setLinked(true);
                                    }
                                },
                            );
                    }
                    setLoadingProgress(false);
                },
            );
        } else {
            setLoadingProgress(false);
        }
    }, []);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [showHelperText, setShowHelperText] = useState(false);

    const handleClickAirbnk = (e) => {
        {
            !linked ? setEnableAirbnk(e.target.checked) : toast.warn(t('please_unlink_the_current_lock'));
        }
    };

    //This section indicates what to do when we click TTLock checkbox
    const handleClickTTLock = (e) => {
        {
            !linked ? setEnableTTLock(e.target.checked) : toast.warn(t('please_unlink_the_current_lock'));
        }
    };

    //This section indicates what to do when we click clean button
    const handleClear = () => {
        setDeviceName('');
        if (location?.state?.zoneNameProps === undefined) {
            setZoneName('');
        }
        setDeviceType('');
        setStatusValue('');
        setIdDevice('');
        setIp('');
        setAccessType('');
        setSn('');
        setMac('');
        setPassword('');
        setDescription('');
        setAntiPassBackType('');
        setAntiPassBackTime('');
        setTimeZoneValue('');
        setCameraTypeId('');
        setAntiPassBackAlert('');
        setEscortMode('');
        setAuthentication('');
        setReadAfter('');
        setRequestSignature('');
        setRequestSignatureAlways('');
        setSyncTime('');
        setValidationNumber('');
        setShowLogo(false);
        setShowUserList(false);
        setSynchorizeSelfies(false);
        setPlaySounds(false);
        setFacialRecognition(false);
        setLiveness(false);
        setFingerprint(false);
        setQr(false);
        setBluetooth(false);
        setSmartCard(false);
        setPin(false);
        setEnableRelay0(false);
        setEnableRelay1(false);
        setDelayToOpen('');
        setTimeForRelay0('');
        setConsecutiveOpeningsAllowed('');
        setTimeForRelay1('');
        setShowWeapon(false);
        setShowVehicle(false);
        setShowLed(false);
        setShowAccessDialog(false);
        setPrinter(false);
        if (!linked) {
            setEnableAirbnk(false);
            setEnableTTLock(false);
            setAirbnkLockKey('');
            setAirbnkLockSerialNumber('');
        }
    };

    //This section indicates what to do when we click UNLINK
    const handleChangeLink = () => {
        setUnlinkShow(true);

        // setLinked(false)
        // if (enableAirbnk) {
        //     dispatch(DeleteAirbnk(deviceId)).then(({ payload: { data: { data, success } } }) => {
        //         {
        //             success === true ? setLockDeleted(true) : setLinked(true)
        //         }
        //         {
        //             success === true ? toast.success(t('airbnk_unlinked_and_deleted_successfully')) : toast.error(t('something_went_wrong_unlinking_the_lock'))
        //         }
        //         {
        //             success === true && setEnableAirbnk(false)
        //         }
        //     })
        // }

        // if (enableTTLock) {
        //     dispatch(DeleteSmartLock(deviceId)).then(({ payload: { data: { data, success } } }) => {
        //         {
        //             success === true ? setLockDeleted(true) : setLinked(true)
        //         }
        //         {
        //             success === true ? toast.success(t('smartlock_unlinked_and_deleted_successfully')) : toast.error(t('something_went_wrong_unlinking_the_lock'))
        //         }
        //         {
        //             success === true && setEnableTTLock(false)
        //         }
        //     })
        // }
    };

    const handleUnlink = () => {
        setLinked(false);
        if (enableAirbnk) {
            dispatch(DeleteAirbnk(deviceId)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    {
                        success === true ? setLockDeleted(true) : setLinked(true);
                    }
                    {
                        success === true
                            ? toast.success(t('airbnk_unlinked_and_deleted_successfully').toUpperCase())
                            : toast.error(t('something_went_wrong_unlinking_the_lock').toUpperCase());
                    }
                    {
                        success === true && setEnableAirbnk(false);
                    }
                },
            );
        }

        if (enableTTLock) {
            dispatch(DeleteSmartLock(deviceId)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    {
                        success === true ? setLockDeleted(true) : setLinked(true);
                    }
                    {
                        success === true
                            ? toast.success(t('smartlock_unlinked_and_deleted_successfully').toUpperCase())
                            : toast.error(t('something_went_wrong_unlinking_the_lock').toUpperCase());
                    }
                    {
                        success === true && setEnableTTLock(false);
                    }
                },
            );
        }
    };
    const validateDeviceData = (body) => {
        if (!body.deviceTypeId) {
            return t('deviceTypeId_required');
        }
        if (!body.deviceAccessTypeId) {
            return t('deviceAccessTypeId_required');
        }
        if (!body.serialNumber) {
            return t('serialNumber_required');
        }
        if (!body.ip) {
            return t('ip_required');
        }
        if (!body.mac) {
            return t('mac_required');
        }
        return null;
    };

    const handelCreateDeviceVeridas = () => {
        const body = {
            antiPassBackAlertById: 1,
            antiPassBackId: 1,
            antiPassBackTime: 1,
            authenticationId: 1,
            enableAirbnk: true,
            deviceAccessTypeId: accessType,
            deviceRestrictionId: deviceRestriction,
            deviceTypeId: deviceType,
            escortId: 1,
            ip,
            description: description,
            serialNumber: 1,
            liveness: true,
            enableRelay1: true,
            mac,
            password,
            name: deviceName,
            deviceId: deviceId,
            bluetoothReader: true,
            pin: true,
            readAfter: 1,
            enableRelay0: true,
            requestSignature: 1,
            rfidReader: true,
            showAccessDialog: false,
            showLed: true,
            serialNumber: sn,
            enableTTLock: true,
            printer: true,
            enableRelaySR3: true,
            showLogo: false,
            showUserList: true,
            showVehicle: false,
            showWeapon: true,
            statusId: statusValue,
            syncTime: 1,
            timeZoneId: 1,
            timeZoneValue: 1,
            consecutiveOpeningsAllowed: 1,
            timeForRelay0: 1,
            timeForRelay1: 1,
            updatedAt: 1,
            facialRecognition: true,
            validationNumber: 1,
            fingerprintReader: true,
            enableRelaySR34ch: true,
            zoneId: zoneName === '' ? null : zoneName,
            qrCodeReader: true,
            playSound: false,
            requestSignatureAlways: false,
            synchronizeSelfie: false,
        };
        const validationError = validateDeviceData(body);
        if (validationError) {
            toast.error(validationError);
            return;
        }
        setFormSubmitted(true);
        setShowHelperText(true);

        if (id) {
            body.id = id;
            dispatch(UpdateDeviceApi(body))
                .then(
                    ({
                        payload: {
                            data: { data, success, code },
                        },
                    }) => {
                        if (success === true) {
                            toast.success(t('device_created_successfully').toUpperCase());
                        } else {
                            switch (code) {
                                case 1000:
                                    toast.error(t(errorCode[code]));

                                    break;
                                case 1001:
                                    toast.error(t(errorCode[code]));

                                    break;
                                case 1002:
                                    toast.error(t(errorCode[code]));

                                    break;
                                case 1005:
                                    toast.error(t(errorCode[code]));

                                    break;
                                default:
                                    toast.error(t('fail_to_update_device').toUpperCase());
                                    break;
                            }
                        }

                        if (success) {
                            navigate(
                                location?.state?.zoneNameProps !== undefined
                                    ? '/dashboard/employee/zones/singlezonedetails'
                                    : '/dashboard/employee/devices',
                            );
                        }
                    },
                )
                .catch((error) => {
                    if (error.response) {
                        const statusCode = error.response.status;
                        if (statusCode === 500) {
                            toast.error(t('server_error').toUpperCase());
                        } else {
                            toast.error(t('unexpected_error').toUpperCase());
                        }
                    } else if (error.request) {
                        toast.error(t('network_error').toUpperCase());
                    } else {
                        toast.error(t('unexpected_error').toUpperCase());
                    }
                });
        } else {
            dispatch(CreateDeviceApi(body))
                .then(
                    ({
                        payload: {
                            data: { data, success, code },
                        },
                    }) => {
                        if (success === true) {
                            toast.success(t('device_created_successfully').toUpperCase());
                        } else {
                            switch (code) {
                                case 1000:
                                    toast.error(t(errorCode[code]));

                                    break;
                                case 1001:
                                    toast.error(t(errorCode[code]));

                                    break;
                                case 1002:
                                    toast.error(t(errorCode[code]));

                                    break;
                                case 1005:
                                    toast.error(t(errorCode[code]));

                                    break;
                                default:
                                    toast.error(t('fail_to_create_device').toUpperCase());
                                    break;
                            }
                        }

                        if (success) {
                            navigate(
                                location?.state?.zoneNameProps !== undefined
                                    ? '/dashboard/employee/zones/singlezonedetails'
                                    : '/dashboard/employee/devices',
                            );
                        }
                    },
                )
                .catch((error) => {
                    if (error.response) {
                        const statusCode = error.response.status;
                        if (statusCode === 500) {
                            toast.error(t('server_error').toUpperCase());
                        } else {
                            toast.error(t('unexpected_error').toUpperCase());
                        }
                    } else if (error.request) {
                        toast.error(t('network_error').toUpperCase());
                    } else {
                        toast.error(t('unexpected_error').toUpperCase());
                    }
                });
        }
    };
    const handelCreateDevice = () => {
        const body = {
            deviceAccessTypeId: accessType,
            bluetoothReader: bluetooth,
            rfidReader: smartCard,
            description:
                description && typeof description === 'string' && description.trim() !== '' ? description : null,
            deviceTypeId: deviceType,
            facialRecognition,
            liveness: liveness,
            fingerprintReader: fingerprint,
            firebaseId: null,
            ip,
            mac,
            name: deviceName,
            deviceId: idDevice,
            validationNumber: validationNumber,
            password,
            pin,
            qrCodeReader: qr,
            serialNumber: sn,
            showLogo: showLogo,
            showUserList,
            statusId: statusValue,
            escortId: escortMode,
            authenticationId: authentication,
            readAfter: readAfter,
            antiPassBackId: antiPassBackType,
            timeZoneId: timeZoneValue,
            cameraTypeId: cameraTypeId,
            antiPassBackTime: antiPassBackType == 1 ? 30 : antiPassBackTime,
            antiPassBackAlertById: antiPassBackType == 1 ? 1 : antiPassBackAlert,
            syncTime: syncTime,
            synchronizeSelfie: synchorizeSelfies,
            playSound: playSounds,
            zoneId: zoneName == '' ? null : zoneName,
            requestSignature: requestSignature,
            requestSignatureAlways: requestSignatureAlways,
            enableTTLock: enableTTLock,
            enableAirbnk: enableAirbnk,
            enableRelay: enableRelay,
            enableRelay0: enableRelay0,
            enableRelay1: enableRelay1,
            delayToOpen: delayToOpen,
            timeForRelay0: timeForRelay0 === '' ? 1 : timeForRelay0,
            consecutiveOpeningsAllowed: consecutiveOpeningsAllowed,
            timeForRelay1: timeForRelay1 === '' ? 1 : timeForRelay1,
            enableRelaySR3: false,
            enableRelaySR34ch: false,
            deviceRestrictionId: deviceRestriction,
            showWeapon: showWeapon,
            showVehicle: showVehicle,
            showLed: showLed,
            showAccessDialog: showAccessDialog,
            printer: printer,
        };
        setFormSubmitted(true);
        setShowHelperText(true);
        if (id) {
            body.id = id;
            if (
                ip !== '' &&
                mac !== '' &&
                deviceName !== '' &&
                statusValue !== '' &&
                accessType !== '' &&
                sn !== '' &&
                antiPassBackType !== '' &&
                timeZoneValue !== '' &&
                cameraTypeId !== '' &&
                escortMode !== '' &&
                authentication !== '' &&
                delayToOpen !== '' &&
                readAfter !== '' &&
                readAfter !== 0 &&
                validationNumber !== '' &&
                validationNumber !== 0 &&
                (antiPassBackType === 1 ||
                    (antiPassBackTime !== '' && antiPassBackTime !== 0 && antiPassBackAlert !== '')) &&
                ((enableAirbnk && airbnkLockKey !== '' && airbnkLockSerialNumber !== '') ||
                    (!enableAirbnk && airbnkLockKey === '' && airbnkLockSerialNumber === ''))
            ) {
                dispatch(UpdateDeviceApi(body))
                    .then(
                        ({
                            payload: {
                                data: { data, success, code },
                            },
                        }) => {
                            if (success === true) {
                                toast.success(t('device_updated_successfully').toUpperCase());
                            } else {
                                switch (code) {
                                    case 1000:
                                        toast.error(t(errorCode[code]));

                                        break;
                                    case 1001:
                                        toast.error(t(errorCode[code]));

                                        break;
                                    case 1002:
                                        toast.error(t(errorCode[code]));

                                        break;
                                    case 1005:
                                        toast.error(t(errorCode[code]));

                                        break;
                                    default:
                                        toast.error(t('fail_to_update_device').toUpperCase());
                                        break;
                                }
                            }

                            if (enableAirbnk === true && success === true && linked) {
                                const body = {
                                    id: airbnkLockId,
                                    deviceId: data?.id,
                                    key: airbnkLockKey,
                                    serialNumber: airbnkLockSerialNumber,
                                };
                                dispatch(UpdateAirbnk(body)).then(
                                    ({
                                        payload: {
                                            data: { data, success },
                                        },
                                    }) => {
                                        {
                                            success !== true &&
                                                toast.error(t('fail_to_update_airbnk_Lock').toUpperCase());
                                        }
                                    },
                                );
                            }
                            if (enableAirbnk === true && success === true && !linked) {
                                const body = {
                                    deviceId: data?.id,
                                    key: airbnkLockKey,
                                    serialNumber: airbnkLockSerialNumber,
                                };
                                dispatch(CreateAirbnkLock(body)).then(
                                    ({
                                        payload: {
                                            data: { data, success },
                                        },
                                    }) => {
                                        {
                                            success !== true &&
                                                toast.error(t('fail_to_create_airbnk_lock').toUpperCase());
                                        }
                                    },
                                );
                            }
                            if (success) {
                                location?.state?.zoneNameProps !== undefined
                                    ? navigate('/dashboard/employee/zones/singlezonedetails')
                                    : navigate('/dashboard/employee/devices');
                            }
                        },
                    )
                    .catch((error) => {
                        if (error.response) {
                            const statusCode = error.response.status;
                            if (statusCode === 500) {
                                toast.error(t('server_error').toUpperCase());
                            } else {
                                toast.error(t('unexpected_error').toUpperCase());
                            }
                        } else if (error.request) {
                            toast.error(t('network_error').toUpperCase());
                        } else {
                            toast.error(t('unexpected_error').toUpperCase());
                        }
                    });
            } else {
                toast.warn(t('fill_required_fields').toUpperCase());
            }
        } else {
            if (
                ip !== '' &&
                mac !== '' &&
                deviceName !== '' &&
                statusValue !== '' &&
                accessType !== '' &&
                sn !== '' &&
                antiPassBackType !== '' &&
                timeZoneValue !== '' &&
                cameraTypeId !== '' &&
                escortMode !== '' &&
                authentication !== '' &&
                delayToOpen !== '' &&
                readAfter !== '' &&
                readAfter !== 0 &&
                validationNumber !== '' &&
                validationNumber !== '0' &&
                (antiPassBackType === 1 ||
                    (antiPassBackTime !== '' && antiPassBackTime !== '0' && antiPassBackAlert !== '')) &&
                ((enableAirbnk && airbnkLockKey !== '' && airbnkLockSerialNumber !== '') ||
                    (!enableAirbnk && airbnkLockKey === '' && airbnkLockSerialNumber === ''))
            ) {
                dispatch(CreateDeviceApi(body))
                    .then(
                        ({
                            payload: {
                                data: { data, success, code },
                            },
                        }) => {
                            if (success === true) {
                                toast.success(t('device_created_successfully').toUpperCase());
                            } else {
                                switch (code) {
                                    case 1000:
                                        toast.error(t(errorCode[code]));

                                        break;
                                    case 1001:
                                        toast.error(t(errorCode[code]));

                                        break;
                                    case 1002:
                                        toast.error(t(errorCode[code]));

                                        break;
                                    case 1005:
                                        toast.error(t(errorCode[code]));

                                        break;
                                    default:
                                        toast.error(t('fail_to_create_device').toUpperCase());
                                        break;
                                }
                            }

                            if (enableAirbnk === true && data !== undefined) {
                                const body = {
                                    batteryTime: Number(smartLockBatteryTime),
                                    device: {
                                        id: data?.id,
                                    },
                                    deviceId: data?.id,
                                    key: airbnkLockKey,
                                    serialNumber: airbnkLockSerialNumber,
                                };
                                dispatch(CreateAirbnkLock(body)).then(
                                    ({
                                        payload: {
                                            data: { data },
                                        },
                                    }) => {
                                        {
                                            success !== true && toast.error(t('fail_to_create_airbnk_lock'));
                                        }
                                    },
                                );
                            }
                            if (success) {
                                location?.state?.zoneNameProps !== undefined
                                    ? navigate('/dashboard/employee/zones/singlezonedetails')
                                    : navigate('/dashboard/employee/devices');
                            }
                        },
                    )
                    .catch((error) => {
                        if (error.response) {
                            const statusCode = error.response.status;
                            if (statusCode === 500) {
                                toast.error(t('server_error').toUpperCase());
                            } else {
                                toast.error(t('unexpected_error').toUpperCase());
                            }
                        } else if (error.request) {
                            toast.error(t('network_error').toUpperCase());
                        } else {
                            toast.error(t('unexpected_error').toUpperCase());
                        }
                    });
            } else {
                toast.warn(t('fill_required_fields').toUpperCase());
            }
        }
    };

    const textField = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& 	.MuiFormLabel-filled': {
            marginTop: '-5px',
        },
        '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
            backgroundColor: '#e6e6e6',
        },
    };

    const textFieldAdornment = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& 	.MuiFormLabel-filled': {
            marginTop: '-5px',
        },
        '&  .css-u30uf2-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: '#e6e6e6',
        },
    };

    useEffect(() => {
        if (!enableRelay0) {
            setTimeForRelay0('');
        }
        if (!enableRelay1) {
            setTimeForRelay1('');
        }
    }, [enableRelay0, enableRelay1, enableTTLock, enableAirbnk]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleCheckboxChange = (event) => {
        const checkboxValue = event.target.value;
        if (selectedCheckboxes.includes(checkboxValue)) {
            setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== checkboxValue));
        } else {
            setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
        }
    };

    const deleteSelectedDevices = (deleteItem) => {
        const tableName = 'device';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setDeletedFlag(true);
                        setSelectDeviceForDelete([]);
                    } else {
                    }
                },
            );
        }
    };
    const isDeviceTypeAllowed = [7, 8, 6, 3].includes(deviceType);

    return (
        <div>
            {loadingProgress && <FullPageLoader />}
            <div className='head'>
                <div className='headLeft'>
                    {location?.state?.zoneNameProps !== undefined ? (
                        <ReturnArrow link={'/dashboard/employee/zones/singlezonedetails'} />
                    ) : (
                        <ReturnArrow link={'/dashboard/employee/devices'} />
                    )}
                    <h2>{id === undefined ? t('create_device')?.toUpperCase() : t('update_device')?.toUpperCase()}</h2>
                </div>

                {id !== undefined && (
                    <div className='container-top-right-btns mr-5'>
                        {permission?.includes(permissionObj?.WEB_DEVICE_DELETE) && (
                            <button
                                className='delete-btn-1'
                                onClick={() => {
                                    setSelectDeviceForDelete([deviceId]);
                                    setDeleteDeviceShow(true);
                                    setDeletedFlag(false);
                                }}
                                id='btn_cud_add'
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div className='divice_wrapper'>
                <div className='container-fluid'>
                    <div className='create_device_data'>
                        <Box
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <DividerWithText title={t('data')} />
                            <div className='px-4'>
                                <ClearButton
                                    style={{ paddingRight: '16px' }}
                                    handleClear={handleClear}
                                    flagTooltip={true}
                                    textTooltip={t('clean_all_inputs').toUpperCase()}
                                    disabled={
                                        deviceName === '' &&
                                        idDevice === '' &&
                                        zoneName === '' &&
                                        deviceType === '' &&
                                        statusValue === '' &&
                                        ip === '' &&
                                        accessType === '' &&
                                        sn === '' &&
                                        mac === '' &&
                                        password === '' &&
                                        description === '' &&
                                        antiPassBackType === '' &&
                                        timeZoneValue !== '' &&
                                        cameraTypeId == '' &&
                                        antiPassBackTime === '' &&
                                        validationNumber === '' &&
                                        airbnkLockKey === '' &&
                                        timeZoneValue === '' &&
                                        airbnkLockSerialNumber === '' &&
                                        escortMode === '' &&
                                        requestSignatureAlways === '' &&
                                        requestSignature === '' &&
                                        syncTime === '' &&
                                        linked === false &&
                                        showLogo === false &&
                                        showUserList === false &&
                                        synchorizeSelfies === false &&
                                        playSounds === false &&
                                        showWeapon === false &&
                                        showVehicle === false &&
                                        showLed === false &&
                                        showAccessDialog === false &&
                                        printer === false &&
                                        facialRecognition === false &&
                                        liveness === false &&
                                        fingerprint === false &&
                                        authentication === '' &&
                                        readAfter === '' &&
                                        timeForRelay0 === '' &&
                                        consecutiveOpeningsAllowed === '' &&
                                        timeForRelay1 === '' &&
                                        delayToOpen === ''
                                    }
                                />
                            </div>
                        </Box>
                        {id !== undefined ? (
                            <>
                                <div className='form_field px-4'>
                                    <Box sx={inputBox}>
                                        <TextField
                                            size='small'
                                            error={formSubmitted && !deviceName === ''}
                                            fullWidth
                                            disabled
                                            label={t('id')}
                                            id='txt_cud_id'
                                            value={deviceId}
                                            onChange={(e) => setDeviceId(e.target.value)}
                                            sx={textField}
                                        />
                                    </Box>
                                    {location?.state?.zoneNameProps !== undefined ? (
                                        <Box sx={inputBox}>
                                            <FormControl fullWidth sx={textField}>
                                                <InputLabel
                                                    id='demo-simple-select-label'
                                                    className='select_input_field'
                                                >
                                                    {t('zone')}
                                                </InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId='demo-simple-select-label'
                                                    id='slt_cud_zone'
                                                    label={t('zone')}
                                                    disabled
                                                    value={zoneName}
                                                    onChange={(e) => setZoneName(e.target.value)}
                                                >
                                                    <MenuItem value=''>
                                                        <em>--</em>
                                                    </MenuItem>
                                                    {employeeWorkStations?.map((item) => (
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    ) : (
                                        <Box sx={inputBox}>
                                            <FormControl fullWidth sx={textField}>
                                                <InputLabel
                                                    id='demo-simple-select-label'
                                                    className='select_input_field'
                                                >
                                                    {t('zone')}
                                                </InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId='demo-simple-select-label'
                                                    id='slt_cud_zone'
                                                    label={t('zone')}
                                                    value={zoneName}
                                                    onChange={(e) => setZoneName(e.target.value)}
                                                >
                                                    <MenuItem value=''>
                                                        <em>--</em>
                                                    </MenuItem>
                                                    {employeeWorkStations?.map((item) => (
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    )}
                                </div>
                                <div className='form_field px-4'>
                                    <Box className='requiredData' sx={inputBox}>
                                        <TextField
                                            size='small'
                                            error={formSubmitted && deviceName === ''}
                                            fullWidth
                                            required
                                            id='txt_cud_deviceName'
                                            label={t('device_name')}
                                            value={deviceName}
                                            inputProps={{ maxLength: 128 }}
                                            onChange={(e) => setDeviceName(e.target.value)}
                                            helperText={formSubmitted && deviceName === '' ? t('requiredField') : ''}
                                            sx={textField}
                                        />
                                    </Box>
                                    <Box className='requiredData' sx={inputBox}>
                                        <FormControl
                                            fullWidth
                                            required
                                            error={formSubmitted && statusValue === ''}
                                            sx={textField}
                                        >
                                            <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                                {t('status')}
                                            </InputLabel>
                                            <Select
                                                size='small'
                                                labelId='demo-simple-select-label'
                                                id='slt_cud_status'
                                                label={t('status')}
                                                value={statusValue}
                                                onChange={(e) => setStatusValue(e.target.value)}
                                            >
                                                {getListStatusDevice?.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id}>
                                                            {item.id === 11
                                                                ? t('active').toUpperCase()
                                                                : t('inactive').toUpperCase()}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {showHelperText && statusValue === '' && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className='form_field px-4'>
                                    <Box className='requiredData' sx={inputBox}>
                                        <FormControl
                                            fullWidth
                                            required
                                            error={formSubmitted && deviceType === ''}
                                            sx={textField}
                                        >
                                            <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                                {t('device_type')}
                                            </InputLabel>
                                            <Select
                                                className='select_form_field'
                                                size='small'
                                                labelId='demo-simple-select-label'
                                                id='slt_cud_deviceType'
                                                label={t('device_type')}
                                                disabled
                                                value={deviceType}
                                                onChange={(e) => setDeviceType(e.target.value)}
                                            >
                                                {getDeviceType?.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id}>
                                                            {item.name.replace(/_/g, ' ')}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {showHelperText && deviceType === '' && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>

                                    <Box className='requiredData' sx={inputBox}>
                                        <FormControl
                                            fullWidth
                                            required
                                            error={formSubmitted && accessType === ''}
                                            sx={textField}
                                        >
                                            <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                                {t('device_access_type')}
                                            </InputLabel>
                                            <Select
                                                size='small'
                                                labelId='demo-simple-select-label'
                                                id='slt_cud_deviceAccessType'
                                                label={t('device_access_type')}
                                                value={accessType}
                                                onChange={(e) => setAccessType(e.target.value)}
                                            >
                                                {getAccessType?.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id}>
                                                            {(item?.id === 3 && t('entry_exit').toUpperCase()) ||
                                                                (item?.id === 2 && t('exit').toUpperCase()) ||
                                                                (item?.id === 1 && t('entry').toUpperCase())}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {showHelperText && accessType === '' && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className='form_field px-4'>
                                    <Box className='requiredData' sx={inputBox}>
                                        <TextField
                                            size='small'
                                            error={formSubmitted && ip === ''}
                                            fullWidth
                                            label={t('ip_domain')}
                                            required
                                            id='txt_cud_ipDomain'
                                            value={ip}
                                            inputProps={{ maxLength: 64 }}
                                            onChange={(e) => setIp(e.target.value)}
                                            helperText={formSubmitted && ip === '' ? t('requiredField') : ''}
                                            sx={textField}
                                        />
                                    </Box>
                                    <Box className='requiredData' sx={inputBox}>
                                        <TextField
                                            size='small'
                                            error={formSubmitted && mac === ''}
                                            fullWidth
                                            required
                                            label={t('mac')}
                                            id='txt_cud_mac'
                                            value={mac}
                                            inputProps={{ maxLength: 64 }}
                                            onChange={(e) => setMac(e.target.value)}
                                            helperText={formSubmitted && mac === '' ? t('requiredField') : ''}
                                            sx={textField}
                                        />
                                    </Box>
                                </div>
                                <div className='form_field px-4'>
                                    <Box className='requiredData' sx={inputBox}>
                                        <TextField
                                            size='small'
                                            error={formSubmitted && sn === ''}
                                            fullWidth
                                            required
                                            label={t('s_n')}
                                            id='txt_cud_s/n'
                                            value={sn}
                                            inputProps={{ maxLength: 128 }}
                                            onChange={(e) => setSn(e.target.value)}
                                            helperText={formSubmitted && sn === '' ? t('requiredField') : ''}
                                            sx={textField}
                                        />
                                    </Box>
                                    <Box sx={inputBox}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('device_id')}
                                            id='txt_cud_deviceId'
                                            value={idDevice}
                                            inputProps={{ maxLength: 128 }}
                                            onChange={(e) => setIdDevice(e.target.value)}
                                            sx={textField}
                                        />
                                    </Box>{' '}
                                    <Box sx={inputBox}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            name='password'
                                            type={showPassword ? 'text' : 'password'}
                                            inputProps={{ maxLength: 128 }}
                                            label={t('password')}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            sx={textField}
                                            id='txt_cud_password'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge='end'
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='form_field px-4'>
                                    <Box className='requiredData' sx={inputBox}>
                                        <TextField
                                            size='small'
                                            error={formSubmitted && deviceName === ''}
                                            fullWidth
                                            required
                                            label={t('device_name')}
                                            id='txt_cud_deviceName'
                                            value={deviceName}
                                            inputProps={{ maxLength: 128 }}
                                            onChange={(e) => setDeviceName(e.target.value)}
                                            helperText={formSubmitted && deviceName === '' ? t('requiredField') : ''}
                                            sx={textField}
                                        />
                                    </Box>
                                    {location?.state?.zoneNameProps !== undefined ? (
                                        <Box sx={inputBox}>
                                            <FormControl fullWidth sx={textField}>
                                                <InputLabel
                                                    id='demo-simple-select-label'
                                                    className='select_input_field'
                                                >
                                                    {t('zone')}
                                                </InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId='demo-simple-select-label'
                                                    id='slt_cud_zone'
                                                    label={t('zone')}
                                                    disabled
                                                    value={zoneDetailFatherAndChild?.id}
                                                >
                                                    <MenuItem value=''>
                                                        <em>--</em>
                                                    </MenuItem>
                                                    {employeeWorkStations?.map((item) => (
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    ) : (
                                        <Box sx={inputBox}>
                                            <FormControl fullWidth sx={textField}>
                                                <InputLabel
                                                    id='demo-simple-select-label'
                                                    className='select_input_field'
                                                >
                                                    {t('zone')}
                                                </InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId='demo-simple-select-label'
                                                    id='slt_cud_zone'
                                                    label={t('zone')}
                                                    value={zoneName}
                                                    onChange={(e) => setZoneName(e.target.value)}
                                                >
                                                    <MenuItem value=''>
                                                        <em>--</em>
                                                    </MenuItem>
                                                    {employeeWorkStations?.map((item) => (
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    )}
                                </div>
                                <div className='form_field px-4'>
                                    <Box className='requiredData' sx={inputBox}>
                                        <FormControl
                                            fullWidth
                                            required
                                            error={formSubmitted && deviceType === ''}
                                            sx={textField}
                                        >
                                            <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                                {t('device_type')}
                                            </InputLabel>
                                            <Select
                                                className='select_form_field'
                                                size='small'
                                                labelId='demo-simple-select-label'
                                                id='slt_cud_deviceType'
                                                label={t('device_type')}
                                                value={deviceType}
                                                onChange={(e) => setDeviceType(e.target.value)}
                                            >
                                                {getDeviceType?.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id}>
                                                            {item.name.replace(/_/g, ' ')}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {showHelperText && deviceType === '' && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>{' '}
                                    <Box className='requiredData' sx={inputBox}>
                                        <FormControl
                                            fullWidth
                                            required
                                            error={formSubmitted && statusValue === ''}
                                            sx={textField}
                                        >
                                            <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                                {t('status')}
                                            </InputLabel>
                                            <Select
                                                size='small'
                                                labelId='demo-simple-select-label'
                                                id='slt_cud_status'
                                                label={t('status')}
                                                value={statusValue}
                                                onChange={(e) => setStatusValue(e.target.value)}
                                            >
                                                {getListStatusDevice?.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.id}>
                                                            {item.id === 11
                                                                ? t('active').toUpperCase()
                                                                : t('inactive').toUpperCase()}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {showHelperText && statusValue === '' && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className='form_field px-4'>
                                    <Box className='requiredData' sx={inputBox}>
                                        <TextField
                                            size='small'
                                            error={formSubmitted && ip === ''}
                                            fullWidth
                                            label={t('ip_domain')}
                                            required
                                            id='txt_cud_ipDomain'
                                            value={ip}
                                            inputProps={{ maxLength: 64 }}
                                            onChange={(e) => setIp(e.target.value)}
                                            helperText={formSubmitted && !ip ? t('requiredField') : ''}
                                            sx={textField}
                                        />
                                    </Box>

                                    <Box className='requiredData' sx={inputBox}>
                                        <FormControl
                                            fullWidth
                                            required
                                            error={formSubmitted && accessType === ''}
                                            sx={textField}
                                        >
                                            <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                                {t('device_access_type')}
                                            </InputLabel>
                                            <Select
                                                size='small'
                                                labelId='demo-simple-select-label'
                                                id='slt_cud_deviceAccessType'
                                                label={t('device_access_type')}
                                                value={accessType}
                                                onChange={(e) => setAccessType(e.target.value)}
                                            >
                                                {getAccessType?.map((item, index) => (
                                                    <MenuItem key={index} value={item.id}>
                                                        {(item?.id === 3 && t('entry_exit').toUpperCase()) ||
                                                            (item?.id === 2 && t('exit').toUpperCase()) ||
                                                            (item?.id === 1 && t('entry').toUpperCase())}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                            {showHelperText && accessType === '' && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className='form_field px-4'>
                                    <Box className='requiredData' sx={inputBox}>
                                        <TextField
                                            size='small'
                                            error={formSubmitted && sn === ''}
                                            fullWidth
                                            required
                                            id='txt_cud_s/n'
                                            label={t('s_n')}
                                            value={sn}
                                            inputProps={{ maxLength: 128 }}
                                            onChange={(e) => setSn(e.target.value)}
                                            helperText={formSubmitted && sn === '' ? t('requiredField') : ''}
                                            sx={textField}
                                        />
                                    </Box>
                                    <Box className='requiredData' sx={inputBox}>
                                        {' '}
                                        <TextField
                                            size='small'
                                            error={formSubmitted && mac === ''}
                                            fullWidth
                                            required
                                            label={t('mac')}
                                            id='txt_cud_mac'
                                            value={mac}
                                            inputProps={{ maxLength: 64 }}
                                            onChange={(e) => setMac(e.target.value)}
                                            helperText={formSubmitted && mac === '' ? t('requiredField') : ''}
                                            sx={textField}
                                        />
                                    </Box>
                                </div>
                                <div className='form_field px-4'>
                                    <Box sx={inputBox}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            name='password'
                                            type={showPassword ? 'text' : 'password'}
                                            inputProps={{ maxLength: 128 }}
                                            label={t('password')}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            sx={textField}
                                            id='txt_cud_password'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge='end'
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                    <Box sx={inputBox}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('device_id')}
                                            id='txt_cud_deviceId'
                                            value={idDevice}
                                            inputProps={{ maxLength: 128 }}
                                            onChange={(e) => setIdDevice(e.target.value)}
                                            sx={textField}
                                        />
                                    </Box>{' '}
                                </div>
                            </>
                        )}
                        <div className='form_field px-4'>
                            <Box sx={inputBox}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    multiline
                                    rows={3}
                                    label={t('description')}
                                    id='txt_cud_description'
                                    value={description}
                                    inputProps={{ maxLength: 512 }}
                                    onChange={(e) => setDescription(e.target.value)}
                                    sx={textField}
                                />
                            </Box>
                        </div>
                    </div>
                </div>

                <div className='container-fluid'>
                    <div className='zone_update_options'>
                        <DividerWithText title={t('options')} />

                        <div sx={{ paddingButton: '40px' }} className='row mt-4 px-4 mr-1'>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id='cb_cud_showLogos'
                                            checked={showLogo}
                                            onChange={(e) => {
                                                setShowLogo(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('show_logos')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showUserList}
                                            id='cb_cud_showUserList'
                                            onChange={(e) => {
                                                setShowUserList(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('show_user_list')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                            <div className=' checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id='cb_cud_synchorizeSelfies'
                                            checked={synchorizeSelfies}
                                            onChange={(e) => {
                                                setSynchorizeSelfies(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('synchronize_photographs')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={playSounds}
                                            id='cb_cud_playSounds'
                                            onChange={(e) => {
                                                setPlaySounds(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('play_sounds')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showWeapon}
                                            id='cb_cud_showWeapon'
                                            onChange={(e) => {
                                                setShowWeapon(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('show_weapon')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id='cb_cud_showVehicles'
                                            checked={showVehicle}
                                            onChange={(e) => {
                                                setShowVehicle(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('show_vehicle')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showLed}
                                            id='cb_cud_showLed'
                                            onChange={(e) => {
                                                setShowLed(e.target.checked);
                                            }}
                                            disabled={deviceType !== 7 && deviceType !== 3}
                                        />
                                    }
                                    label={t('show_led')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showAccessDialog}
                                            id='cb_cud_showAccessDialog'
                                            onChange={(e) => {
                                                setShowAccessDialog(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('show_access_dialog')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id='cb_cud_printer'
                                            checked={printer}
                                            onChange={(e) => {
                                                setPrinter(e.target.checked);
                                            }}
                                            disabled={deviceType !== 6}
                                        />
                                    }
                                    label={t('enable_printer')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', width: '100%' }}>
                        {' '}
                        <Box className='requiredData' sx={{ ...inputBox, width: '50%', marginLeft: '22px' }}>
                            <FormControl
                                fullWidth
                                required
                                error={
                                    showHelperText &&
                                    timeZoneValue === '' &&
                                    deviceType !== 10 &&
                                    deviceType !== 11 &&
                                    deviceType !== 12 &&
                                    deviceType !== 13
                                }
                                sx={{ ...textField, marginTop: '20px' }}
                            >
                                <InputLabel id='time-zone-select-label' className='select_input_field'>
                                    {t('time_zone')}
                                </InputLabel>
                                <Select
                                    className='select_form_field'
                                    size='small'
                                    labelId='time-zone-select-label'
                                    id='slt_cud_timeZone'
                                    label={t('time_zone')}
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                    value={timeZoneValue}
                                    onChange={handleTimeZoneChange}
                                >
                                    {Object.entries(timeZone).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {t(value)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {showHelperText &&
                                    timeZoneValue === '' &&
                                    deviceType !== 10 &&
                                    deviceType !== 11 &&
                                    deviceType !== 12 &&
                                    deviceType !== 13 && (
                                        <FormHelperText className='select_helper_text'>
                                            {t('requiredField')}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                        </Box>
                        <Box
                            className='requiredData'
                            sx={{ ...inputBox, width: '50%', marginLeft: '20px', marginTop: '20px' }}
                        >
                            <FormControl
                                fullWidth
                                required
                                error={
                                    showHelperText &&
                                    deviceType !== 10 &&
                                    deviceType !== 11 &&
                                    deviceType !== 12 &&
                                    deviceType !== 13 &&
                                    consecutiveOpeningsAllowed === ''
                                }
                            >
                                <TextField
                                    size='small'
                                    required
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                    inputProps={{ maxLength: 4 }}
                                    onChange={(e) => {
                                        const regex = /^[0-9]*$/;
                                        if (regex.test(e.target.value)) {
                                            setConsecutiveOpeningsAllowed(e.target.value);
                                        }
                                    }}
                                    fullWidth
                                    error={
                                        showHelperText &&
                                        deviceType !== 10 &&
                                        deviceType !== 11 &&
                                        deviceType !== 12 &&
                                        deviceType !== 13 &&
                                        consecutiveOpeningsAllowed === ''
                                    }
                                    label={t('number_attemps')}
                                    id='txt_cud_consecutiveOpeningsAllowed'
                                    value={consecutiveOpeningsAllowed}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                className='endAdornmentStyle'
                                                position='end'
                                            ></InputAdornment>
                                        ),
                                    }}
                                    sx={textFieldAdornment}
                                />{' '}
                                {showHelperText &&
                                    consecutiveOpeningsAllowed === '' &&
                                    deviceType !== 10 &&
                                    deviceType !== 11 &&
                                    deviceType !== 12 &&
                                    deviceType !== 13 && <FormHelperText>{t('selectOptionText')}</FormHelperText>}
                            </FormControl>
                        </Box>
                    </div>
                    <div className='zone_update_options mt-4'>
                        <DividerWithText title={t('security')} />
                        <div className='create_device_data'>
                            <div className='form_field px-4'>
                                <Box className='requiredData' sx={inputBox}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={
                                            showHelperText &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 &&
                                            antiPassBackType === ''
                                        }
                                        sx={textField}
                                    >
                                        <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                            {t('anti_pass_back')}
                                        </InputLabel>
                                        <Select
                                            className='select_form_field'
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='slt_cud_antiPassBack'
                                            label={t('anti_pass_back')}
                                            value={antiPassBackType}
                                            onChange={(e) => setAntiPassBackType(e.target.value)}
                                            disabled={
                                                deviceType === 10 ||
                                                deviceType === 11 ||
                                                deviceType === 12 ||
                                                deviceType === 13
                                            }
                                        >
                                            {getAntiPassBackType?.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>
                                                    {item.id === 1 ? t('none') : item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {showHelperText &&
                                            antiPassBackType === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </Box>

                                <Box className='requiredData' sx={inputBox}>
                                    <TextField
                                        size='small'
                                        error={
                                            formSubmitted &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 &&
                                            antiPassBackType !== 1 &&
                                            (antiPassBackTime === '' || antiPassBackTime === 0)
                                        }
                                        required
                                        disabled={deviceType === 10 || deviceType === 11 || antiPassBackType === 1}
                                        fullWidth
                                        id='txt_cud_antiPassBackTime'
                                        label={t('anti_pass_back_time')}
                                        value={antiPassBackTime}
                                        inputProps={{ maxLength: 4 }}
                                        onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                                setAntiPassBackTime(+e.target.value);
                                            }
                                        }}
                                        helperText={
                                            formSubmitted &&
                                            antiPassBackType !== 1 &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 &&
                                            antiPassBackTime === ''
                                                ? t('requiredField')
                                                : antiPassBackTime === 0
                                                  ? t('this_value_must_be_greater_than_0')
                                                  : ''
                                        }
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>{t('sec')}</InputAdornment>,
                                        }}
                                        sx={textFieldAdornment}
                                    />
                                </Box>
                            </div>

                            <div className='form_field px-4'>
                                <Box className='requiredData' sx={inputBox}>
                                    <FormControl
                                        fullWidth
                                        required
                                        error={
                                            showHelperText &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 &&
                                            antiPassBackType !== 1 &&
                                            antiPassBackAlert === ''
                                        }
                                        sx={textField}
                                    >
                                        <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                            {t('anti_pass_back_alert_by')}
                                        </InputLabel>
                                        <Select
                                            className='select_form_field'
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='slt_cud_antiPassBackAlert'
                                            disabled={
                                                antiPassBackType === 1 ||
                                                deviceType === 10 ||
                                                deviceType === 11 ||
                                                deviceType === 12 ||
                                                deviceType === 13
                                            }
                                            label={t('anti_pass_back_alert_by')}
                                            value={antiPassBackAlert}
                                            onChange={(e) => setAntiPassBackAlert(e.target.value)}
                                        >
                                            {getAllMedia?.map((item, index) => {
                                                return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                            })}
                                        </Select>
                                        {showHelperText &&
                                            antiPassBackType !== 1 &&
                                            antiPassBackAlert === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </Box>
                                <Box className='requiredData' sx={inputBox}>
                                    <FormControl
                                        fullWidth
                                        required
                                        disabled={
                                            deviceType === 10 ||
                                            deviceType === 11 ||
                                            deviceType === 12 ||
                                            deviceType === 13
                                        }
                                        error={
                                            showHelperText &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 &&
                                            escortMode === ''
                                        }
                                        sx={textField}
                                    >
                                        <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                            {t('escort')}
                                        </InputLabel>
                                        <Select
                                            className='select_form_field'
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='slt_cud_escort'
                                            label={t('escort')}
                                            value={escortMode}
                                            onChange={(e) => setEscortMode(e.target.value)}
                                        >
                                            {getEscortMode?.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.id}>
                                                        {(item?.id === 2 && t('needed').toUpperCase()) ||
                                                            (item?.id === 1 && t('none').toUpperCase())}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {showHelperText &&
                                            escortMode === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 10 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </Box>
                            </div>
                            <div className='form_field px-4'>
                                <Box sx={inputBox}>
                                    <FormControl fullWidth sx={textField}>
                                        <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                            {t('request_signature')}
                                        </InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='slt_cud_requestSignature'
                                            label={t('request_signature')}
                                            value={requestSignatureAlways}
                                            disabled={
                                                deviceType === 10 ||
                                                deviceType === 11 ||
                                                deviceType === 12 ||
                                                deviceType === 13
                                            }
                                            onChange={(e) => setRequestSignatureAlways(e.target.value)}
                                        >
                                            <MenuItem value={true}>{t('YES')}</MenuItem>
                                            <MenuItem value={false}>{t('NO')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={inputBox}>
                                    <TextField
                                        size='small'
                                        inputProps={{ maxLength: 4 }}
                                        onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                                setRequestSignature(+e.target.value);
                                            }
                                        }}
                                        fullWidth
                                        disabled={
                                            deviceType === 10 ||
                                            deviceType === 11 ||
                                            deviceType === 12 ||
                                            deviceType === 13 ||
                                            requestSignatureAlways === false
                                        }
                                        label={t('request_signature_every')}
                                        id='txt_cud_requestSignature'
                                        value={requestSignature}
                                        helperText={t('fill_0_to_make_it_everytime')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className='endAdornmentStyle' position='end'>
                                                    {t('days')}
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={textField}
                                    />
                                </Box>
                            </div>
                            <div className='form_field px-4'>
                                <Box sx={inputBox}>
                                    <TextField
                                        size='small'
                                        inputProps={{ maxLength: 4 }}
                                        disabled={
                                            deviceType === 10 ||
                                            deviceType === 11 ||
                                            deviceType === 12 ||
                                            deviceType === 13 ||
                                            requestSignatureAlways === false
                                        }
                                        onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                                setSyncTime(+e.target.value);
                                            }
                                        }}
                                        fullWidth
                                        label={t('sync_time')}
                                        id='txt_cud_syncTime'
                                        value={syncTime}
                                        helperText={t('leave_0_to_make_it_in_real_time')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className='endAdornmentStyle' position='end'>
                                                    min
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={textField}
                                    />
                                </Box>
                                <Box className='requiredData' sx={inputBox}>
                                    <TextField
                                        size='small'
                                        error={
                                            (formSubmitted &&
                                                validationNumber === '' &&
                                                deviceType !== 10 &&
                                                deviceType !== 11) ||
                                            (validationNumber === 0 &&
                                                deviceType !== 10 &&
                                                deviceType !== 12 &&
                                                deviceType !== 13)
                                        }
                                        inputProps={{ maxLength: 4 }}
                                        disabled={
                                            deviceType === 10 ||
                                            deviceType === 11 ||
                                            deviceType === 12 ||
                                            deviceType === 13
                                        }
                                        onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                                setValidationNumber(+e.target.value);
                                            }
                                        }}
                                        required
                                        fullWidth
                                        label={t('validation_number')}
                                        id='txt_cud_validationNumber'
                                        value={validationNumber}
                                        helperText={
                                            formSubmitted &&
                                            validationNumber === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13
                                                ? t('requiredField')
                                                : validationNumber === 0 &&
                                                    deviceType !== 10 &&
                                                    deviceType !== 11 &&
                                                    deviceType !== 12 &&
                                                    deviceType !== 13
                                                  ? t('this_value_must_be_greater_than_0')
                                                  : ''
                                        }
                                        sx={textField}
                                    />
                                </Box>
                            </div>
                            <div className='form_field px-4'>
                                <Box className='requiredData' sx={{ ...inputBox, width: '70%' }}>
                                    <FormControl
                                        fullWidth
                                        required
                                        disabled={!isDeviceTypeAllowed}
                                        error={
                                            showHelperText &&
                                            cameraTypeId === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13
                                        }
                                        sx={{ ...textField }}
                                    >
                                        <InputLabel id='camera-type-select-label' className='select_input_field'>
                                            {t('camera_type_id')}
                                        </InputLabel>
                                        <Select
                                            className='select_form_field'
                                            size='small'
                                            labelId='camera-type-select-label'
                                            id='slt_cud_cameraType'
                                            label={t('camera_type_id')}
                                            value={cameraTypeId}
                                            onChange={(e) => setCameraTypeId(e.target.value)}
                                        >
                                            <MenuItem value={0}>{t('RGB')}</MenuItem>
                                            <MenuItem value={1}>{t('RGB + IR')}</MenuItem>
                                        </Select>
                                        {showHelperText &&
                                            cameraTypeId === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </Box>
                                <div
                                    style={{ marginTop: '-15px', marginLeft: '-10px' }}
                                    className='checkbox-common col-md-6 mtc'
                                >
                                    <FormControlLabel
                                        required
                                        disabled={!isDeviceTypeAllowed}
                                        control={
                                            <Checkbox
                                                id='cb_cud_liveness'
                                                checked={liveness}
                                                onChange={(e) => {
                                                    setLiveness(e.target.checked);
                                                }}
                                            />
                                        }
                                        label={t('liveness')}
                                    />
                                    {showHelperText &&
                                        !liveness &&
                                        deviceType !== 10 &&
                                        deviceType !== 11 &&
                                        deviceType !== 12 &&
                                        deviceType !== 13 && <FormHelperText>{t('requiredField')}</FormHelperText>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='zone_update_reader'>
                        <DividerWithText title={t('authentication')} />
                        <div className='row mt-4 px-4 mr-1'>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id='cb_cud_facialRecognition'
                                            checked={facialRecognition}
                                            onChange={(e) => {
                                                setFacialRecognition(e.target.checked);
                                            }}
                                            disabled={
                                                deviceType === 10 ||
                                                deviceType === 11 ||
                                                deviceType === 12 ||
                                                deviceType === 13 ||
                                                !deviceType ||
                                                !allowedCheckboxes[deviceType] ||
                                                !allowedCheckboxes[deviceType].includes('facialRecognition')
                                            }
                                        />
                                    }
                                    label={t('facial_recognition')}
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                    control={
                                        <Checkbox
                                            id='cb_cud_fingerPrint'
                                            checked={fingerprint}
                                            onChange={(e) => {
                                                setFingerprint(e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t('fingerprint_reader')}
                                />
                            </div>
                        </div>

                        <div className='row mt-0 pt-0 px-4 mr-1'>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                    control={
                                        <Checkbox
                                            id='cb_cud_qr'
                                            checked={qr}
                                            onChange={(e) => {
                                                setQr(e.target.checked);
                                            }}
                                            disabled={!deviceType || !allowedCheckboxes[deviceType].includes('qr')}
                                        />
                                    }
                                    label={t('qr_code_reader')}
                                />
                            </div>

                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                    control={
                                        <Checkbox
                                            id='cb_cud_bluetooth'
                                            checked={bluetooth}
                                            onChange={(e) => {
                                                setBluetooth(e.target.checked);
                                            }}
                                            disabled={
                                                !deviceType || !allowedCheckboxes[deviceType].includes('bluetooth')
                                            }
                                        />
                                    }
                                    label={t('bluetooth_reader')}
                                />
                            </div>
                        </div>

                        <div className='row px-4 mr-1'>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    disabled={
                                        deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13
                                    }
                                    control={
                                        <Checkbox
                                            id='cb_cud_rfid'
                                            checked={smartCard}
                                            onChange={(e) => {
                                                setSmartCard(e.target.checked);
                                            }}
                                            disabled={!deviceType || !allowedCheckboxes[deviceType].includes('rfid')}
                                        />
                                    }
                                    label={t('rfid_reader')}
                                />
                            </div>
                            <div className='checkbox-common col-md-4'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={pin}
                                            id='cb_cud_pin'
                                            onChange={(e) => {
                                                setPin(e.target.checked);
                                            }}
                                            disabled={
                                                !deviceType ||
                                                deviceType === 10 ||
                                                deviceType === 11 ||
                                                deviceType === 12 ||
                                                deviceType === 13 ||
                                                !allowedCheckboxes[deviceType].includes('pin')
                                            }
                                        />
                                    }
                                    label={t('pin')}
                                />
                            </div>
                        </div>

                        <div className='create_device_data'>
                            <div className='form_field px-4'>
                                <Box className='requiredData' sx={inputBox}>
                                    <FormControl
                                        fullWidth
                                        required
                                        sx={textField}
                                        error={
                                            showHelperText &&
                                            authentication === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13
                                        }
                                    >
                                        <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                            {t('authentication')}
                                        </InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='slt_cud_authentication'
                                            label={t('authentication')}
                                            value={authentication}
                                            disabled={[10, 11, 12, 13].includes(deviceType) || !deviceType}
                                            onChange={(e) => setAuthentication(e.target.value)}
                                        >
                                            {deviceTypeAuthentications &&
                                                deviceTypeAuthentications.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {(item === 1 && t('none').toUpperCase()) ||
                                                            (item === 3 && t('qr_+_face').toUpperCase()) ||
                                                            (item === 2 && t('qr_+_fingerprint').toUpperCase())}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {showHelperText &&
                                            authentication === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13 && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </Box>
                                <Box sx={inputBox} className='requiredData'>
                                    <TextField
                                        size='small'
                                        error={
                                            (formSubmitted &&
                                                readAfter === '' &&
                                                deviceType !== 10 &&
                                                deviceType !== 11 &&
                                                deviceType !== 12 &&
                                                deviceType !== 13) ||
                                            (readAfter === 0 &&
                                                deviceType !== 10 &&
                                                deviceType !== 11 &&
                                                deviceType !== 12 &&
                                                deviceType !== 13)
                                        }
                                        required
                                        disabled={
                                            deviceType === 10 ||
                                            deviceType === 11 ||
                                            deviceType === 12 ||
                                            deviceType === 13
                                        }
                                        inputProps={{ maxLength: 4 }}
                                        onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                                setReadAfter(+e.target.value);
                                            }
                                        }}
                                        fullWidth
                                        label={t('read_again_after')}
                                        id='txt_cud_readAgainAfter'
                                        value={readAfter}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className='endAdornmentStyle' position='end'>
                                                    {t('sec')}
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={
                                            formSubmitted &&
                                            readAfter === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13
                                                ? t('requiredField')
                                                : readAfter === 0 &&
                                                    deviceType !== 10 &&
                                                    deviceType !== 11 &&
                                                    deviceType !== 12 &&
                                                    deviceType !== 13
                                                  ? t('this_value_must_be_greater_than_0')
                                                  : ''
                                        }
                                        sx={textField}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>

                    <div className='zone_update_lock'>
                        <DividerWithText title={t('locks')} />
                        <div className='row mt-3 px-4 mr-1'>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableRelay0}
                                            id='cb_cud_enableRelay0'
                                            onChange={(e) => {
                                                setEnableRelay0(e.target.checked);
                                            }}
                                            disabled={
                                                !deviceType ||
                                                deviceType === 10 ||
                                                deviceType === 11 ||
                                                deviceType === 12 ||
                                                deviceType === 13 ||
                                                !allowedCheckboxes[deviceType].includes('relay0') ||
                                                lockChecked !== enableRelay0
                                            }
                                        />
                                    }
                                    label={t('enableRelay0')}
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableRelay1}
                                            id='cb_cud_enableRelay1'
                                            onChange={(e) => {
                                                setEnableRelay1(e.target.checked);
                                            }}
                                            disabled={
                                                !deviceType ||
                                                deviceType === 10 ||
                                                deviceType === 11 ||
                                                deviceType === 12 ||
                                                deviceType === 13 ||
                                                !allowedCheckboxes[deviceType].includes('relay1') ||
                                                lockChecked !== enableRelay1
                                            }
                                        />
                                    }
                                    label={t('enableRelay1')}
                                />
                            </div>
                        </div>
                        <div className='create_device_data'>
                            <div className='form_field px-4'>
                                <Box sx={inputBox}>
                                    <TextField
                                        size='small'
                                        inputProps={{ maxLength: 4 }}
                                        disabled={
                                            deviceType === 10 ||
                                            deviceType === 11 ||
                                            deviceType === 12 ||
                                            deviceType === 13 ||
                                            !enableRelay0
                                        }
                                        onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                                setTimeForRelay0(+e.target.value);
                                            }
                                        }}
                                        fullWidth
                                        label={t('keep_open_R0')}
                                        id='txt_cud_KeepOpen'
                                        value={timeForRelay0}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className='endAdornmentStyle' position='end'>
                                                    {t('sec')}
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={textFieldAdornment}
                                    />
                                </Box>
                                <Box sx={inputBox}>
                                    <TextField
                                        size='small'
                                        inputProps={{ maxLength: 4 }}
                                        disabled={
                                            deviceType === 10 ||
                                            deviceType === 11 ||
                                            deviceType === 12 ||
                                            deviceType === 13 ||
                                            !enableRelay1
                                        }
                                        onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                                setTimeForRelay1(+e.target.value);
                                            }
                                        }}
                                        fullWidth
                                        label={t('keep_open_R1')}
                                        id='txt_cud_KeepOpen'
                                        value={timeForRelay1}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className='endAdornmentStyle' position='end'>
                                                    {t('sec')}
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={textFieldAdornment}
                                    />
                                </Box>
                            </div>
                        </div>

                        {/*  <div className='row mt-3 px-4 mr-1'>
                            <div className='checkbox-common col-md-6'>
                                <input type="checkbox"
                                    value={enableAirbnk}
                                    checked={enableAirbnk}
                                    readOnly={linked}
                                    disabled={!deviceType || !allowedCheckboxes[deviceType].includes("airbnk") || lockChecked !== enableAirbnk}
                                    onClick={handleClickAirbnk}
                                />
                                <label htmlFor="">{t("enable_Airbnk_lock")}</label> 
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            // value={enableAirbnk}
                                            checked={enableAirbnk}
                                            readOnly={linked}
                                            onChange={(e) => {
                                                handleClickAirbnk(e);
                                            }}
                                            // onChange={e => {
                                            //     setEnableRelay1(e.target.checked);
                                            // }}
                                            disabled={
                                                !deviceType ||
                                                !allowedCheckboxes[deviceType].includes('airbnk') ||
                                                lockChecked !== enableAirbnk
                                            }
                                        />
                                    }
                                    label={t('enable_Airbnk_lock')}
                                />
                            </div>
                            <div className='checkbox-common col-md-6'>
                                {/* <input type="checkbox"
                                    value={enableTTLock}
                                    checked={enableTTLock}
                                    readOnly={linked}
                                    disabled={!deviceType || !allowedCheckboxes[deviceType].includes("ttlock") || lockChecked !== enableTTLock}
                                    onClick={handleClickTTLock}
                                />
                                <label htmlFor="">{t("enableTTLock")}</label> 
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableTTLock}
                                            readOnly={linked}
                                            onChange={(e) => {
                                                handleClickTTLock(e);
                                            }}
                                            disabled={
                                                !deviceType ||
                                                !allowedCheckboxes[deviceType].includes('ttlock') ||
                                                lockChecked !== enableTTLock
                                            }
                                        />
                                    }
                                    label={t('enableTTLock')}
                                /> 
                            </div>
                        </div>*/}

                        <div className='create_device_data'>
                            <div className='form_field px-4'>
                                <Box sx={inputBox} className='requiredData'>
                                    <TextField
                                        size='small'
                                        inputProps={{ maxLength: 4 }}
                                        disabled={
                                            deviceType === 10 ||
                                            deviceType === 11 ||
                                            deviceType === 12 ||
                                            deviceType === 13
                                        }
                                        error={
                                            formSubmitted &&
                                            delayToOpen === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13
                                        }
                                        onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                                setDelayToOpen(+e.target.value);
                                            }
                                        }}
                                        fullWidth
                                        required
                                        label={t('delay_to_open')}
                                        id='txt_cud_delayToOpen'
                                        value={delayToOpen}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className='endAdornmentStyle' position='end'>
                                                    {t('sec')}
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={
                                            formSubmitted &&
                                            delayToOpen === '' &&
                                            deviceType !== 10 &&
                                            deviceType !== 11 &&
                                            deviceType !== 12 &&
                                            deviceType !== 13
                                                ? t('requiredField')
                                                : ''
                                        }
                                        sx={textField}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>

                {enableAirbnk && linked && (
                    <div className='container-fluid mt-3'>
                        <div className='smart_lock_setting'>
                            <DividerWithText title={t('smart_lock_settings')} />
                            <div className='container-top-right-btns mt-4'>
                                <label>{t('UNLINK')}</label>
                                <Switch
                                    checked={linked}
                                    onChange={handleChangeLink}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color='primary'
                                />
                                <label>{t('LINK')}</label>
                            </div>

                            <div className='container-fluid'>
                                <div className='create_device_data'>
                                    <div className='create_device_data'>
                                        <div className='form_field px-4'>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    disabled={
                                                        deviceType === 10 ||
                                                        deviceType === 11 ||
                                                        deviceType === 12 ||
                                                        deviceType === 13
                                                    }
                                                    label={t('id')}
                                                    id='id'
                                                    value={airbnkLockId}
                                                    onChange={(e) => setAirbnkLockId(e.target.value)}
                                                    sx={textField}
                                                />
                                            </Box>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    error={
                                                        formSubmitted &&
                                                        deviceType !== 10 &&
                                                        deviceType !== 11 &&
                                                        deviceType !== 12 &&
                                                        deviceType !== 13 &&
                                                        airbnkLockSerialNumber === '' &&
                                                        enableAirbnk
                                                    }
                                                    fullWidth
                                                    required
                                                    disabled={
                                                        deviceType === 10 ||
                                                        deviceType === 11 ||
                                                        deviceType === 12 ||
                                                        deviceType === 13
                                                    }
                                                    label={t('s_n')}
                                                    id='s/n'
                                                    value={airbnkLockSerialNumber}
                                                    onChange={(e) => setAirbnkLockSerialNumber(e.target.value)}
                                                    helperText={
                                                        formSubmitted && airbnkLockSerialNumber === '' && enableAirbnk
                                                            ? t('requiredField')
                                                            : ''
                                                    }
                                                    sx={textField}
                                                />
                                            </Box>
                                        </div>
                                        <div className='form_field px-4'>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    error={
                                                        formSubmitted &&
                                                        deviceType !== 10 &&
                                                        deviceType !== 11 &&
                                                        deviceType !== 12 &&
                                                        deviceType !== 13 &&
                                                        airbnkLockKey === '' &&
                                                        enableAirbnk
                                                    }
                                                    fullWidth
                                                    disabled={
                                                        deviceType === 10 ||
                                                        deviceType === 11 ||
                                                        deviceType === 12 ||
                                                        deviceType === 13
                                                    }
                                                    required
                                                    label={t('key')}
                                                    id='key'
                                                    value={airbnkLockKey}
                                                    onChange={(e) => setAirbnkLockKey(e.target.value)}
                                                    helperText={
                                                        formSubmitted && airbnkLockKey === '' && enableAirbnk
                                                            ? t('requiredField')
                                                            : ''
                                                    }
                                                    sx={textField}
                                                />
                                            </Box>
                                            <Box sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    disabled={
                                                        deviceType === 10 ||
                                                        deviceType === 11 ||
                                                        deviceType === 12 ||
                                                        deviceType === 13
                                                    }
                                                    hidden
                                                    label={t('blank_space')}
                                                    id='blank'
                                                    sx={textField}
                                                />
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {enableAirbnk && !linked && (
                    <div className='container-fluid mt-3'>
                        <div className='smart_lock_setting'>
                            <DividerWithText title={t('smart_lock_settings')} />
                            <div className='container-fluid'>
                                <div className='create_device_data'>
                                    <div className='create_device_data'>
                                        <div className='form_field px-4'>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    error={
                                                        formSubmitted &&
                                                        deviceType !== 10 &&
                                                        deviceType !== 11 &&
                                                        deviceType !== 12 &&
                                                        deviceType !== 13 &&
                                                        airbnkLockKey === '' &&
                                                        enableAirbnk
                                                    }
                                                    fullWidth
                                                    required
                                                    disabled={
                                                        deviceType === 10 ||
                                                        deviceType === 11 ||
                                                        deviceType === 12 ||
                                                        deviceType === 13
                                                    }
                                                    label={t('key')}
                                                    id='key'
                                                    value={airbnkLockKey}
                                                    onChange={(e) => setAirbnkLockKey(e.target.value)}
                                                    helperText={
                                                        formSubmitted && airbnkLockKey === '' && enableAirbnk
                                                            ? t('requiredField')
                                                            : ''
                                                    }
                                                    sx={textField}
                                                />
                                            </Box>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    error={
                                                        formSubmitted && airbnkLockSerialNumber === '' && enableAirbnk
                                                    }
                                                    fullWidth
                                                    required
                                                    disabled={
                                                        deviceType === 10 ||
                                                        deviceType === 11 ||
                                                        deviceType === 12 ||
                                                        deviceType === 13
                                                    }
                                                    label={t('s_n')}
                                                    id='s/n'
                                                    value={airbnkLockSerialNumber}
                                                    onChange={(e) => setAirbnkLockSerialNumber(e.target.value)}
                                                    helperText={
                                                        formSubmitted && airbnkLockSerialNumber === '' && enableAirbnk
                                                            ? t('requiredField')
                                                            : ''
                                                    }
                                                    sx={textField}
                                                />
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {enableTTLock && linked && (
                    <div className='container-fluid mt-3'>
                        <div className='smart_lock_setting'>
                            <DividerWithText title={t('smart_lock_settings')} />
                            <div className='container-top-right-btns mt-4'>
                                <label>{t('UNLINK')}</label>
                                <Switch
                                    checked={linked}
                                    onChange={handleChangeLink}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color='primary'
                                />
                                <label>{t('LINK')}</label>
                            </div>

                            <div className='container-fluid'>
                                <div className='create_device_data'>
                                    <div className='create_device_data'>
                                        <div className='form_field px-4'>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    required
                                                    disabled
                                                    label={t('id')}
                                                    id='txt_cud_id'
                                                    value={smartLockId}
                                                    onChange={(e) => setSmartLockId(e.target.value)}
                                                    sx={textField}
                                                />
                                            </Box>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    required
                                                    disabled
                                                    label={t('mac')}
                                                    id='txt_cud_mac'
                                                    value={smartLockMac}
                                                    onChange={(e) => setSmartLockMac(e.target.value)}
                                                    sx={textField}
                                                />
                                            </Box>
                                        </div>
                                        <div className='form_field px-4'>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    disabled
                                                    label={t('battery')}
                                                    id='txt_cud_battery'
                                                    value={smartLockBattery}
                                                    onChange={(e) => setSmartLockBattery(e.target.value)}
                                                    sx={textField}
                                                />
                                            </Box>
                                            <Box className='requiredData' sx={inputBox}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    disabled
                                                    required
                                                    label={t('battery_time')}
                                                    id='txt_cud_batteryTime'
                                                    value={smartLockBatteryTime}
                                                    onChange={(e) => setSmartLockBatteryTime(e.target.value)}
                                                    sx={textField}
                                                />
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className='create_device_footer mt-4'>
                {location?.state?.zoneNameProps !== undefined ? (
                    <Link to='/dashboard/employee/zones/singlezonedetails'>
                        <button
                            className='btn_cancel_background_gray_hover'
                            style={{ width: '349px', color: '#BC0000' }}
                            id='btn_cud_cancel'
                        >
                            {t('cancel')}
                        </button>
                    </Link>
                ) : (
                    <Link to='/dashboard/employee/devices'>
                        <button
                            className='btn_cancel_background_gray_hover'
                            style={{ width: '349px', color: '#BC0000' }}
                            id='btn_cud_cancel'
                        >
                            {t('cancel')}
                        </button>
                    </Link>
                )}
                {deviceType === 10 || deviceType === 11 || deviceType === 12 || deviceType === 13 ? (
                    <button
                        className='custom_primary_btn_dark'
                        style={{ width: '349px' }}
                        id='btn_cud_createAndUpdate'
                        onClick={() => {
                            handelCreateDeviceVeridas();
                        }}
                    >
                        {id === undefined ? t('create_device')?.toUpperCase() : t('update_device')?.toUpperCase()}
                    </button>
                ) : (
                    <button
                        className='custom_primary_btn_dark'
                        style={{ width: '349px' }}
                        id='btn_cud_createAndUpdate'
                        onClick={() => {
                            handelCreateDevice();
                        }}
                    >
                        {id === undefined ? t('create_device')?.toUpperCase() : t('update_device')?.toUpperCase()}
                    </button>
                )}
            </div>
            <DeleteModal
                show={deleteDeviceShow}
                onHide={() => setDeleteDeviceShow(false)}
                onClickFn={() => deleteSelectedDevices(selectDeviceForDelete)}
                data={selectDeviceForDelete}
                title_modal={t('delete_device')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('device')}
                onDelete={() => setDeletedFlag(true)}
            />

            <DeleteModal
                show={unlinkShow}
                onHide={() => setUnlinkShow(false)}
                onClickFn={() => handleUnlink()}
                data={['smart-lock']}
                description={t('are_you_sure_you_want_to_unlink')}
                title_modal={t('unlink_smart_lock')}
                element_modal={t('smart_lock')}
            />
        </div>
    );
};

export default CreateUpdateDevices;

const inputBox = {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    height: '50px',
};
