import React from 'react';
import ic_clean from '../assets/images/ic-clean.svg';
import camera_plugin from '../assets/images/camera-plugin.svg';
import reader_finger_plugin from '../assets/images/reader-finger-plugin.svg';
import Tooltip from '@mui/material/Tooltip';

const ReaderFingerButton = ({ handleCaptureFinger, flagTooltip, textTooltip }) => {
    return (
        <div className='camera-plugin-icon'>
            {flagTooltip ? (
                <Tooltip title={textTooltip?.toUpperCase()} placement='right' arrow>
                    <button className='btn-option' onClick={handleCaptureFinger}>
                        <img src={reader_finger_plugin} alt='ic_clean' />
                    </button>
                </Tooltip>
            ) : (
                <button className='btn-option' onClick={handleCaptureFinger}>
                    <img src={reader_finger_plugin} alt='ic_clean' />
                </button>
            )}
        </div>
    );
};

export default ReaderFingerButton;
