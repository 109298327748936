import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../../../reduxToolkit/EmployeeEvents/EmployeeEventsApi';
import {
    updateEmailPhoneSearchList,
    updateSelectedEmployees,
} from '../../../../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import ic_add from '../../../../assets/icon/ic-add.svg';
import remove from '../../../../assets/images/ic-delete-red.svg';
import ManageOthersModal from '../subComponents/ManageOthersModal';
import DeleteModal from '../../../Modals/DeleteModal';
import AuthorizedEmployeesModal from '../../../Modals/AuthorizedEmployeesModal';

import NotInformation from '../../../../components/NotInformation';

const Visitors = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const emailPhoneSearchList = useSelector((state) => state?.EmployeeEventsSlice?.emailPhoneSearchList);
    const employeesSelectedSave = useSelector((state) => state?.EmployeeEventsSlice?.selectedEmployees);
    const [showEmployeeModal, setShowEmployeeModal] = useState(false);
    const [showVisitorModal, setShowVisitor] = useState(false);
    const [employeesSelected, setEmployeesSelected] = useState([]);
    const [employeeToDelete, setEmployeeToDelete] = useState(false);
    const [employeeSelectedDelete, setSelectDocumentsForDelete] = useState({});

    useEffect(() => {
        if (employeesSelectedSave.length > 0) {
            employeesSelectedSave.map((item) => {
                setEmployeesSelected((prevData) => [...prevData, item]);
            });
        }
    }, []);

    const handleConfirmEmployees = (employees) => {
        dispatch(updateSelectedEmployees(employees));
        employees.map((item) => {
            setEmployeesSelected((prevData) => [...prevData, item]);
        });
        setShowEmployeeModal(false);
    };

    const handleDeleteEmployee = (id) => {
        let employeeForDelete = employeesSelected.filter((employee) => employee.id !== id);
        setEmployeesSelected(employeeForDelete);
        dispatch(updateSelectedEmployees(employeeForDelete));
    };

    const handleShowModalDelete = (id) => {
        let employeeForDelete = employeesSelected.find((employee) => employee.id === id);
        setSelectDocumentsForDelete(employeeForDelete);
        setEmployeeToDelete(true);
    };

    const handleHideVisitorModal = () => {
        setShowVisitor(false);
    };

    return (
        <>
            <div className='d-flex mt-5 buildingdetail_access_d '>
                <h1 className='title-section'>{t('employees')?.toUpperCase()}</h1>

                <span
                    className='device_button'
                    onClick={() => {
                        dispatch(getAllEmployees());
                        setShowEmployeeModal(true);
                    }}
                >
                    {t('add_remove')?.toUpperCase()}
                    <img className='ml-2' src={ic_add} alt='' />
                </span>

                <AuthorizedEmployeesModal
                    title={t('employees')}
                    show={showEmployeeModal}
                    onHide={() => setShowEmployeeModal(false)}
                    personalizedAction={true}
                    onConfirm={handleConfirmEmployees}
                    personalizedRemoveAction={true}
                    removeEmployeesList={employeesSelected}
                    personalizedDeleteAction={true}
                    onRemoveElement={handleShowModalDelete}
                />
            </div>

            {employeesSelected.length !== 0 ? (
                <div className='panelTables normalVistor'>
                    <table className='w-100'>
                        <thead className='no-border-thead'>
                            <th className='first_head'>{t('name')}</th>
                            <th>{t('phone_number')}</th>
                            <th className='last'>{t('remove')}</th>
                        </thead>
                        <tbody>
                            {employeesSelected?.map((item) => (
                                <tr key={item.id}>
                                    <td className='first'>{item.label}</td>
                                    <td>{item.phoneNumber}</td>
                                    <td className='last'>
                                        <img
                                            src={remove}
                                            alt='remove'
                                            onClick={() => handleShowModalDelete(item?.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <NotInformation text={t('no_information')} card={true} />
            )}

            <div className='d-flex mt-3 buildingdetail_access_d '>
                <h1 className='title-section'>{t('visitors')?.toUpperCase()}</h1>

                <span
                    className='device_button'
                    onClick={() => {
                        dispatch(getAllEmployees());
                        setShowVisitor(true);
                    }}
                >
                    {t('add')?.toUpperCase()}
                    <img className='ml-2' src={ic_add} alt='' />
                </span>
                <ManageOthersModal show={showVisitorModal} onHide={handleHideVisitorModal} />
            </div>
            {emailPhoneSearchList.length !== 0 ? (
                <div className='panelTables normalVistor'>
                    <table className='w-100'>
                        <thead className='no-border-thead'>
                            <th className='first_head'>{t('name')}</th>
                            <th>{t('phone_number')}</th>
                            <th className='last'>{t('remove')}</th>
                        </thead>
                        <tbody>
                            {emailPhoneSearchList?.map((user) => (
                                <tr key={user.id}>
                                    <td className='first'>{user.name}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td className='last'>
                                        <img
                                            src={remove}
                                            alt='remove'
                                            onClick={() => {
                                                dispatch(
                                                    updateEmailPhoneSearchList(
                                                        emailPhoneSearchList.filter((item) => item.id !== user.id),
                                                    ),
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <NotInformation text={t('no_information')} card={true} />
            )}
            <DeleteModal
                title_modal={t('delete_employee')}
                data={employeeSelectedDelete}
                show={employeeToDelete}
                onHide={() => setEmployeeToDelete(false)}
                element_modal={employeeSelectedDelete.label}
                isReset={employeeSelectedDelete}
                onClickFn={() => handleDeleteEmployee(employeeSelectedDelete.id)}
            />
        </>
    );
};

export default Visitors;
