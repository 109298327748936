/*
Author : Arman Ali
Module: Role
github: https://github.com/Arman-Arzoo
website: https://www.toplinegeeks.com,
modify every implementation
*/

import { Box, TextField } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { iconStyle } from '../../../../../Helpers/arabicStyle';
import chevron_right_solid from '../../../../../assets/images/chevron-right-solid.svg';
import ic_cancel from '../../../../../assets/images/ic-cancel.svg';
import ClearButton from '../../../../../components/ClearButton';
import {
    GetSingleRole,
    UpdateRole,
    creatRole,
    roleAvailableTasks,
} from '../../../../../reduxToolkit/EmployeeRoles/EmployeeRolesApi';
import DeleteRoleModal from './DeleteRoleModal';
import ManageRoleModal from './ManageRoleModal';
import AvailableRoleDragADrop from './AvailableRoleDragADrop';
import { companyMobile } from './RoleData';
import ChooseRole from './ChooseRole';
import ReusableTextField from '../../../../../components/ReusableTextField ';
import ReturnArrow from '../../../../../components/buttons/ReturnArrow';
import { useDrag, useDrop, DndProvider } from 'react-dnd';

const DroppableContainer = ({ children, onDrop, accepts }) => {
    const [{ isOver }, drop] = useDrop({
        accept: accepts,
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <div
            ref={drop}
            style={{
                backgroundColor: isOver ? 'rgba(225,225,225,.2)' : '#e1e1e1',
                borderRadius: '10px 10px 0px 0px',
            }}
        >
            {children}
        </div>
    );
};

const CreateNewRole = () => {
    // use hook
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    // useSelector
    const { roleListData, singleRoleObj, updateRoleSuccess, creteRoleSuccess } = useSelector(
        (state) => state?.EmployeeRolesSlice,
    );
    // useState
    const [mobileAvailableList, setMobileAvaliableList] = useState([]);
    const [websiteAvailableList, setWebsiteAvailableList] = useState([]);
    const [mobileChooseList, setMobileChooseList] = useState([]);
    const [websiteChooseList, setWebsiteChooseList] = useState([]);
    const [mobileSelected, setMobileSelected] = useState([]);
    const [websiteSelected, setWebsiteSelected] = useState([]);
    const [deleteTask, setDeleteTask] = useState([]);
    const [roleRestrictions, setroleRestrictions] = useState([
        {
            name: 'biocrValidation',
            res: t('biocr_validation'),
            check: false,
            info: t('use_the_biocr_validatiaon_to_confirm_your_identity'),
        },
        {
            name: 'eventValidation',
            res: t('event_validation'),
            check: false,
            info: t('validate_the_event_who_created_in_this_role'),
        },
        {
            name: 'extraDataEmployee',
            res: t('extra_data_employee'),
            check: false,
            info: t('add_extra_data_to_the_employee'),
        },
        {
            name: 'keepSessionActiveWebApp',
            res: t('keep_session_active'),
            check: false,
            info: t('to_keep_the_session_al_the_time_active_in_the_web_app'),
        },
        // {
        //     name: "twoWayValidationFactor",
        //     res: "Two way validation factor",
        //     check: false,
        //     info: "To use the 6 digits code to log in on the web app",
        // },
        {
            name: 'sharePdfInMobileApp',
            res: t('share_pdf_in_mobile_app'),
            check: false,
            info: t('to_use_the_6_degits_code_to_log_in_on_the_web_app'),
        },
        {
            name: 'useTokenWebApp',
            res: t('use_token_webapp'),
            check: false,
            info: t('to_use_the_6_degits_code_to_log_in_on_the_web_app'),
        },
    ]);
    const [roleName, setRoleName] = useState('');
    const [deleteId, setDeleteId] = useState();
    const [deleteItemName, setdeleteItemName] = useState();
    const [roleDataById, setRoleDataById] = useState();
    const [show, setShow] = useState(false);
    const [manageShow, setManageShow] = useState(false);
    const [selectedRestrictions, setSelectedRestrictions] = useState({
        biocrValidation: false,
        eventValidation: false,
        extraDataEmployee: false,
        id: '',
        keepSessionActiveWebApp: false,
        // twoWayValidationFactor: false,
        sharePdfInMobileApp: false,
        useTokenWebApp: false,
    });
    const [isInitialSetup, setIsInitialSetup] = useState(true);
    const [nameError, setNameError] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false);

    // clear all field
    const clearField = () => {
        setRoleName('');
        setroleRestrictions((prevState) => {
            const updatedCheckboxList = prevState.map((item) => ({ ...item, check: false }));
            return updatedCheckboxList;
        });

        setSelectedRestrictions({
            biocrValidation: false,
            eventValidation: false,
            extraDataEmployee: false,
            id: '',
            keepSessionActiveWebApp: false,
            sharePdfInMobileApp: false,
            useTokenWebApp: false,
        });

        const mr = roleListData?.filter((item) => item?.isMobileApp);
        const wr = roleListData?.filter((item) => !item?.isMobileApp);
        setMobileAvaliableList(mr);
        setWebsiteAvailableList(wr);
        setWebsiteChooseList([]);
        setMobileChooseList([]);

        let db = [...mr, ...wr];
        const deleteState = db.filter((item) =>
            singleRoleObj?.currentTasks?.some((selectedItem) => selectedItem.id === item.id),
        );
        setDeleteTask(deleteState);
    };

    // const handleCheckBox = (e, item) => {
    //     const { checked, name } = e.target;
    //     let checkboxList = [...roleRestrictions]; // Create a copy of roleRestrictions

    //     checkboxList.forEach(chkItem => {
    //         if (chkItem.res === item.res) {
    //             chkItem.check = checked;
    //         }
    //     });

    //     setSelectedRestrictions(prevState => ({
    //         ...prevState,
    //         [name]: checked
    //     }));

    //     setroleRestrictions(checkboxList);
    // };
    const handleCheckBox = (e, item) => {
        const { checked, name } = e.target;

        // Update roleRestrictions
        const updatedRoleRestrictions = roleRestrictions.map((chkItem) => {
            if (chkItem.res === item.res) {
                return { ...chkItem, check: checked };
            }
            return chkItem;
        });

        setroleRestrictions(updatedRoleRestrictions);

        // Update selectedRestrictions
        setSelectedRestrictions((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    // //  handle add and delete task
    const handleTaskListClick = (payload) => {
        switch (payload.check) {
            case 1:
                if (payload.selected.option) {
                    setWebsiteChooseList((prevArray) => [...prevArray, payload.selected]);
                    setWebsiteAvailableList((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                } else {
                    // toast.warn("Do'not need to add it already in database")
                    setDeleteTask((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                    setWebsiteChooseList((prevArray) => [...prevArray, payload.selected]);
                    setWebsiteAvailableList((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                }
                break;
            case 2:
                if (payload.selected.option) {
                    setMobileChooseList((prevArray) => [...prevArray, payload.selected]);
                    setMobileAvaliableList((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                } else {
                    // toast.warn("Do'not need to add it already in database")
                    setDeleteTask((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                    setMobileChooseList((prevArray) => [...prevArray, payload.selected]);
                    setMobileAvaliableList((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                }
                break;
            case 3:
                if (payload.selected.option) {
                    setMobileAvaliableList((prevArray) => [...prevArray, payload.selected]);
                    setMobileChooseList((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                } else {
                    setDeleteTask((prev) => [...prev, payload.selected]);
                    setMobileAvaliableList((prevArray) => [...prevArray, payload.selected]);
                    setMobileChooseList((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                }
                break;
            case 4:
                if (payload.selected.option) {
                    setWebsiteAvailableList((prevArray) => [...prevArray, payload.selected]);
                    setWebsiteChooseList((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                } else {
                    setDeleteTask((prev) => [...prev, payload.selected]);
                    setWebsiteAvailableList((prevArray) => [...prevArray, payload.selected]);
                    setWebsiteChooseList((prevArray) => prevArray.filter((obj) => obj.id !== payload.selected?.id));
                }

                break;
            case 5:
                const mr = roleListData?.filter((item) => item?.isMobileApp);
                const wr = roleListData?.filter((item) => !item?.isMobileApp);
                setMobileAvaliableList([]);
                setWebsiteAvailableList([]);
                setWebsiteChooseList(wr);
                setMobileChooseList(mr);
                break;
            case 6:
                const ma = roleListData?.filter((item) => item?.isMobileApp);
                const wa = roleListData?.filter((item) => !item?.isMobileApp);
                setMobileAvaliableList(ma);
                setWebsiteAvailableList(wa);
                setWebsiteChooseList([]);
                setMobileChooseList([]);
                let db = [...ma, ...wa];
                const deleteState = db.filter((item) =>
                    singleRoleObj?.currentTasks?.some((selectedItem) => selectedItem.id === item.id),
                );
                setDeleteTask(deleteState);
                break;
            default:
                // Handle unknown payload.check values, if needed
                toast.warn('invalid check');
                break;
        }
    };
    const handleNameChange = (value) => {
        if (value.trim() === '') {
            setNameError('privilege_name_required');
        } else {
            setNameError('');
        }

        setRoleName(value);
    };

    // handel create and update role
    const handleCreateRole = () => {
        if (roleName.trim() !== '') {
            if (id) {
                const addTasks = [...mobileChooseList, ...websiteChooseList].map((item) => ({ id: item.id }));
                const removeTasks = [...deleteTask].map((item) => ({ id: item.id }));
                const updateData = {
                    id: singleRoleObj?.id,
                    addTasks,
                    removeTasks,
                    name: roleName,
                    roleRestriction: {
                        roleId: singleRoleObj?.roleRestriction?.roleId,
                        id: singleRoleObj?.roleRestriction?.id,
                        biocrValidation: selectedRestrictions?.biocrValidation,
                        eventValidation: selectedRestrictions?.eventValidation,
                        extraDataEmployee: selectedRestrictions?.extraDataEmployee,
                        keepSessionActiveWebApp: selectedRestrictions?.keepSessionActiveWebApp,
                        sharePdfInMobileApp: selectedRestrictions?.sharePdfInMobileApp,
                        useTokenWebApp: selectedRestrictions?.useTokenWebApp,
                    },
                };
                dispatch(UpdateRole(updateData)).then((res) => {
                    if (res.payload?.status == 200) {
                        navigate(-1);
                    }
                });
            } else {
                const addTasks = [...mobileChooseList, ...websiteChooseList].map((item) => ({ id: item.id }));
                const data = {
                    addTasks,
                    name: roleName,
                    removeTasks: null,
                    roleRestriction: {
                        biocrValidation: selectedRestrictions?.biocrValidation,
                        eventValidation: selectedRestrictions?.eventValidation,
                        extraDataEmployee: selectedRestrictions?.extraDataEmployee,
                        keepSessionActiveWebApp: selectedRestrictions?.keepSessionActiveWebApp,
                        sharePdfInMobileApp: selectedRestrictions?.sharePdfInMobileApp,
                        useTokenWebApp: selectedRestrictions?.useTokenWebApp,
                    },
                };
                dispatch(creatRole(data)).then((res) => {
                    if (res.payload?.status == 201) {
                        navigate(-1);
                    }
                });
            }
        } else {
            // toast.warn("Role Name is Required")
            setSubmitClicked(true);
        }
    };
    // // makeSure some sate when initailize is empty
    useEffect(() => {
        setDeleteTask([]);
        setMobileChooseList([]);
        setWebsiteChooseList([]);
    }, []);
    // // transforming data to available and choose for get display
    useEffect(() => {
        if (roleListData) {
            const m = roleListData.filter((item) => item?.isMobileApp);
            const w = roleListData.filter((item) => !item?.isMobileApp);

            setMobileAvaliableList(m || []);
            setWebsiteAvailableList(w || []);
        }
    }, [roleListData]);

    useEffect(() => {
        dispatch(roleAvailableTasks());
    }, [dispatch]);
    // // before update data
    useEffect(() => {
        if (id) {
            dispatch(GetSingleRole(id));

            const arrObj = [
                {
                    res: t('biocr_validation'),
                    check: singleRoleObj?.roleRestriction?.biocrValidation,
                    name: 'biocrValidation',
                    info: t('use_the_biocr_validatiaon_to_confirm_your_identity'),
                },
                {
                    res: t('event_validation'),
                    check: singleRoleObj?.roleRestriction?.eventValidation,
                    name: 'eventValidation',
                    info: t('validate_the_event_who_created_in_this_role'),
                },
                {
                    res: t('extra_data_employee'),
                    check: singleRoleObj?.roleRestriction?.extraDataEmployee,
                    name: 'extraDataEmployee',
                    info: t('add_extra_data_to_the_employee'),
                },
                {
                    res: t('keep_session_active'),
                    check: singleRoleObj?.roleRestriction?.keepSessionActiveWebApp,
                    name: 'keepSessionActiveWebApp',
                    info: t('to_keep_the_session_al_the_time_active_in_the_web_app'),
                },
                {
                    res: t('share_pdf_in_mobile_app'),
                    check: singleRoleObj?.roleRestriction?.sharePdfInMobileApp,
                    name: 'sharePdfInMobileApp',
                    info: t('to_use_the_6_degits_code_to_log_in_on_the_web_app'),
                },
                {
                    res: t('use_token_webapp'),
                    check: singleRoleObj?.roleRestriction?.useTokenWebApp,
                    name: 'useTokenWebApp',
                    info: t('to_use_the_6_degits_code_to_log_in_on_the_web_app'),
                },
            ];

            setroleRestrictions(arrObj);

            // Initialize role data and lists
            setRoleName(singleRoleObj?.name || '');
            setRoleDataById(singleRoleObj);
            setSelectedRestrictions(singleRoleObj?.roleRestriction || {});

            let arr1 = [];
            let arr2 = [];
            singleRoleObj?.currentTasks?.forEach((item) => {
                if (item?.isMobileApp) {
                    arr1.push({ ...item, option: false });
                } else {
                    arr2.push({ ...item, option: false });
                }
            });

            setMobileChooseList(arr1);
            setWebsiteChooseList(arr2);
        }
    }, [id, dispatch, singleRoleObj?.id, creteRoleSuccess, updateRoleSuccess]);

    // // filter out to avoid confilt and smooth filter base on currentTasks
    useEffect(() => {
        if (isInitialSetup) {
            setIsInitialSetup(false);
            return;
        }

        if (singleRoleObj && mobileAvailableList && websiteAvailableList) {
            // Filter out from mobile available
            const removeAvailableMobile = mobileAvailableList.filter(
                (item) => !mobileChooseList.some((selectedItem) => selectedItem.id === item.id),
            );
            setMobileAvaliableList(removeAvailableMobile);

            // Filter out from web available
            const removeAvailableWeb = websiteAvailableList.filter(
                (item) => !websiteChooseList.some((selectedItem) => selectedItem.id === item.id),
            );
            setWebsiteAvailableList(removeAvailableWeb);
        }
    }, [isInitialSetup, singleRoleObj, mobileChooseList, websiteChooseList]);

    const handleDropAvailable = (item) => {
        if (item.column != 'Available') {
            handleTaskListClick({
                selected: item.selected,
                check: item.check,
            });
        }
    };
    const handleDropChoosed = (item) => {
        if (item.column != 'Choosed') {
            handleTaskListClick({
                selected: item.selected,
                check: item.check,
            });
        }
    };
    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={'/dashboard/employee/company/roles-panel'} />
                    {/* <Link to="/dashboard/employee/company/roles-panel">
                        <i className="fa fa-arrow-left" aria-hidden="true" style={iconStyle}></i>
                    </Link> */}
                    <h2>{id ? t('update_privilege') : t('create_privilege')}</h2>
                </div>
            </div>
            <div className='create_new_role'>
                <div className='row'>
                    {/* role restriction */}
                    <div className='row'>
                        <div className='col-12 col-md-4'>
                            <div className='create_new_role_data'>
                                <div className='header_clear'>
                                    <h3>{t('data')}</h3>
                                    <ClearButton
                                        flagTooltip={true}
                                        handleClear={() => clearField()}
                                        textTooltip={t('clean_all_inputs')?.toUpperCase()}
                                    />
                                </div>
                                <div className='create_new_role_data_item'>
                                    <Box
                                        component='form'
                                        sx={{
                                            width: '100%',
                                            maxWidth: '100%',
                                            fontSize: '20px',
                                            height: '40px',
                                        }}
                                        noValidate
                                        autoComplete='off'
                                    >
                                        <ReusableTextField
                                            label={t('privilege_name')}
                                            onChange={handleNameChange}
                                            value={roleName}
                                            isRequired={true}
                                            submitClicked={submitClicked}
                                        />
                                    </Box>
                                </div>
                                {/* <h3 className='mt-4'>{t('restrictions')}</h3>
                                {roleRestrictions?.map((item, index) => (
                                    <div className='create_new_role_data_restrictions' key={index}>
                                        <div className='data_restrictions_item'>
                                            <p className='roleName'>{item.res}</p>
                                            <label className='checkboxLabel'>
                                                <input
                                                    type='checkbox'
                                                    name={item.name}
                                                    checked={item.check}
                                                    onChange={(e) => handleCheckBox(e, item)}
                                                />
                                                <span
                                                    className='checkmark'
                                                    style={{
                                                        right: lCode === 'ar' ? '' : '10px',
                                                        left: lCode === 'ar' ? '10px' : '',
                                                    }}
                                                ></span>
                                            </label>
                                        </div>
                                        <p className='roleInfo'>
                                            <span>{t('info')}: </span>
                                            {item.info}
                                        </p>
                                    </div>
                                ))} */}
                            </div>
                            <div className='create_new_role_data mt-4'>
                                <h3>{t('permissions')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        {/* available */}
                        <div className='col-6 new_role_available'>
                            <p className='mb-2'>{t('available')}</p>
                            <div className='contentBoxShadow'>
                                <DroppableContainer onDrop={handleDropAvailable} accepts='box'>
                                    <div className='pTR6'>
                                        <div className='new_role_available_container'>
                                            <AvailableRoleDragADrop
                                                onHandler={handleTaskListClick}
                                                data={roleListData}
                                                mobileAvailableList={mobileAvailableList}
                                                setMobileAvailableList={setMobileAvaliableList}
                                                websiteAvailableList={websiteAvailableList}
                                                setWebsiteAvailableList={setWebsiteAvailableList}
                                                mobileChooseList={mobileChooseList}
                                                setMobileChooseList={setMobileChooseList}
                                                websiteChooseList={websiteChooseList}
                                                setWebsiteChooseList={setWebsiteChooseList}
                                                mobileSelected={mobileSelected}
                                                setMobileSelected={setMobileSelected}
                                                websiteSelected={websiteSelected}
                                                setWebsiteSelected={setWebsiteSelected}
                                            />
                                        </div>
                                    </div>
                                </DroppableContainer>
                                <div
                                    className='accodion_footer_role'
                                    onClick={() =>
                                        handleTaskListClick({
                                            check: 5,
                                        })
                                    }
                                >
                                    <span>{t('add_all')}</span>
                                </div>
                            </div>
                        </div>
                        {/* chooce */}
                        <div className='col-6 new_role_available'>
                            <p className='mb-2'>{t('choosed')}</p>
                            <div className='contentBoxShadow'>
                                <DroppableContainer onDrop={handleDropChoosed} accepts='box'>
                                    <div className='pTR6'>
                                        <div className='new_role_available_container'>
                                            <ChooseRole
                                                onHandler={handleTaskListClick}
                                                data={roleListData}
                                                mobileAvailableList={mobileAvailableList}
                                                setMobileAvailableList={setMobileAvaliableList}
                                                websiteAvailableList={websiteAvailableList}
                                                setWebsiteAvailableList={setWebsiteAvailableList}
                                                mobileChooseList={mobileChooseList}
                                                setMobileChooseList={setMobileChooseList}
                                                websiteChooseList={websiteChooseList}
                                                setWebsiteChooseList={setWebsiteChooseList}
                                                mobileSelected={mobileSelected}
                                                setMobileSelected={setMobileSelected}
                                                websiteSelected={websiteSelected}
                                                setWebsiteSelected={setWebsiteSelected}
                                            />
                                        </div>
                                    </div>
                                </DroppableContainer>
                                <div className='accodion_footer_role' onClick={() => handleTaskListClick({ check: 6 })}>
                                    <span>{t('remove_all')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-center mt-12 mb-12 pt-4 pb-4'>
                    <Link to='/dashboard/employee/company/roles-panel'>
                        <button className='btn_cancel_background_gray_hover' style={{ minWidth: '304px' }}>
                            {t('cancel')}
                        </button>
                    </Link>
                    <button
                        className='custom_primary_btn_dark'
                        onClick={() => handleCreateRole()}
                        style={{ minWidth: '304px' }}
                    >
                        {id ? t('update')?.toUpperCase() : t('create')?.toUpperCase()}
                    </button>
                </div>
                <DeleteRoleModal
                    show={show}
                    onHide={() => setShow(false)}
                    deleteid={deleteId}
                    deleteitemname={deleteItemName}
                />

                <ManageRoleModal
                    show={manageShow}
                    onHide={() => setManageShow(false)}
                    deleteid={id}
                    // roleid={roleId}
                />
            </div>
        </>
    );
};

export default CreateNewRole;
