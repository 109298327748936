import React from 'react';
import settingIcon from '../assets/icon/ic-setting.svg';
import { Tooltip } from '@mui/material';
const SettingButton = ({ onAction, title, placement }) => {
    return (
        <Tooltip title={title} placement={placement || 'left'} disableInteractive>
            <div className='mini_btn' onClick={() => onAction()}>
                <img src={settingIcon} alt='setting' />
            </div>
        </Tooltip>
    );
};

export default SettingButton;
