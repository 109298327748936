import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/iEntry_corporate.png';
import background from '../../../assets/images/mountain.png';
import '../FailValidation/ValidatedFail.scss';
import { useTranslation } from 'react-i18next';
import DocumentValidation from '../DocumentValidation/DocumentValidation';

const BrowserCheck = () => {
    const { t } = useTranslation();
    const userAgent = window.navigator.userAgent;
    const browserMatch = userAgent.match(/(Chrome|Firefox|Safari|Opera|Edge)\/(\d+(\.\d+)?)/);
    let isValidBrowser = false;

    if (browserMatch) {
        const browserName = browserMatch[1];
        const browserVersion = browserMatch[2] ? parseFloat(browserMatch[2]) : 0;

        isValidBrowser = () => {
            switch (browserName) {
                // Desktop Browsers
                case 'Chrome':
                    return browserVersion >= 64;
                case 'Firefox':
                    return browserVersion >= 69;
                case 'Safari':
                    return browserVersion >= 13;
                case 'Opera':
                    return browserVersion >= 51;
                case 'Edge':
                    return browserVersion >= 79;
                //mobile
                case 'Mobile Chrome':
                case 'Tablet Chrome':
                    return browserVersion >= 64;
                case 'Mobile Firefox':
                case 'Tablet Firefox':
                    return browserVersion >= 79;
                case 'Tablet Safari':
                    return browserVersion >= 13.4;
                case 'Tablet Opera':
                    return browserVersion >= 47;
                case 'Samsung Internet':
                    return browserVersion >= 9.0;
                case 'Mobile Edge':
                    return browserVersion >= 79;
                // iOS
                case 'Chrome (iOS)':
                    return browserVersion >= 64;
                case 'Firefox (iOS)':
                    return browserVersion >= 79;
                case 'Safari (iOS)':
                    return browserVersion >= 79;
                case 'Edge (iOS)':
                    return browserVersion >= 47;
                case 'Opera Mobile (iOS)':
                    return browserVersion >= 13.4;
                default:
                    return false;
            }
        };
    }

    return isValidBrowser() ? (
        <DocumentValidation />
    ) : (
        <Box
            className='validation-fail'
            sx={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                className='validation-fail__content'
                sx={{
                    bgcolor: 'white',
                    p: 4,
                    width: 650,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: 2,
                    zIndex: 1,
                    mb: 4,
                }}
            >
                <img src={logo} alt='' />
                <h2 className='validation-fail__title'> {t('browser_not_supported_1')}</h2>
                <ul className='validation-fail__reasons'>
                    <li className='margin-button'>{t('browser_not_supported_2')}</li>
                </ul>
            </Box>
        </Box>
    );
};

export default BrowserCheck;
