import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo/logo 3.png';
import background from '../../../assets/defaultImages/building_2.png';
import { Box, Button } from '@mui/material';
import './ValidatedFail.scss';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { id } from 'date-fns/locale';

const ValidatedFail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showOnboarding, setShowOnboarding] = useState(false);
    const userIdRedux = useSelector((state) => state.VisitorSlice.userId);
    const dispatch = useDispatch();
    const handleSubmit = () => {
        setShowOnboarding(true);
        navigate(`/verify-visitor/${userIdRedux}`);
    };
    return (
        <Box
            className='validation-fail'
            sx={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {!showOnboarding && (
                <Box
                    className='validation-fail__content'
                    sx={{
                        bgcolor: 'white',
                        p: 4,
                        width: 650,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: 2,
                        zIndex: 1,
                        mb: 4,
                    }}
                >
                    <img src={logo} alt='Identity Validated' className='validation-fail__logo' />
                    <h2 className='validation-fail__title'> {t('document_validation_failed')}</h2>
                    <ul className='validation-fail__reasons'>
                        <li className='margin-button'>{t('document_authentication_error')}</li>
                        <li className='margin-button'>
                            <span className='dot' /> {t('validation_fail_reason')}
                        </li>
                    </ul>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        size='small'
                        id='btn_vf_acceptAndContinue'
                        className='validation-fail__button'
                        onClick={handleSubmit}
                    >
                        {t('accept_and_continue')}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default ValidatedFail;
