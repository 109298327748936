import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import { getAllEmployeeContractors } from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsSlice';
import { GetAllEmployeeContractors } from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { Link, Navigate, useNavigate } from 'react-router-dom';
import DropDownMenuProfile from '../../../components/DropDownMenuProfile';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { MODELS } from '../../../Apis/Models';
import { TABLES } from '../../../Apis/Tables';
import { status } from '../../../enums/statusEnum';
import { Checkbox, Tooltip } from '@mui/material';
import { permissionObj } from '../../../Helpers/permission';
import NotInformation from '../../../components/NotInformation';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import DeleteModal from '../../Modals/DeleteModal';

const ContractorTable = ({
    isAllCheckedContractor,
    selectContractorForDelete,
    setSelectContractorForDelete,
    setIsAllCheckedContractor,
    searchByFilters,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    setLoaded,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const lCode = Cookies.get('i18next') || 'en';
    const { t } = useTranslation();

    const [pagePagination, setPagePagination] = useState(0);
    const [rowsPerPageProvider, setRowsPerProvider] = useState(20);
    const [searchContractor, setSearchContractor] = useState('');
    const [deleteContractorShow, setDeleteContractorShow] = useState(false);

    useEffect(() => {
        const body = {
            pagination: {
                order: true,
                page: pagePagination,
                size: rowsPerPageProvider,
                sortBy: 'id',
            },
        };
        dispatch(GetAllEmployeeContractors(body));
    }, [pagePagination, rowsPerPageProvider]);

    const handleDeleteAll = (e) => {
        setIsAllCheckedContractor(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = searchByFilters?.content?.map((item) => item?.id);
            setSelectContractorForDelete(selectAllIds);
        } else {
            setSelectContractorForDelete([]);
        }
    };

    const handleCheckboxChange = (e) => {
        const contractorId = e.target.id;
        if (e.target.checked) {
            setSelectContractorForDelete([...selectContractorForDelete, contractorId]);
        } else {
            setSelectContractorForDelete(selectContractorForDelete.filter((id) => id !== contractorId));
        }
    };

    const handleChangePageProvider = (event, newPage) => {
        setPagePagination(newPage);
    };

    const handleChangeRowsPerPageProvider = (event) => {
        setRowsPerProvider(parseInt(event.target.value));
        setPagePagination(0);
    };

    const deleteSelectedContractors = (deleteItemIds) => {
        const tableName = 'contractor';
        const body = deleteItemIds;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectContractorForDelete([]);
                        setIsAllCheckedContractor(false);
                    } else {
                    }
                },
            );
        }
    };

    // Handle navigation to approve document or detail page
    const handleDetailOrApprove = (id, statusUser, userId) => {
        const route =
            statusUser <= 3
                ? `/dashboard/employee/contractors/contractor-approve-document/${id}`
                : `/dashboard/employee/contractors/contractor-detail/${id}`;

        sessionStorage.setItem('userId', userId);
        navigate(route);
    };

    // Handle navigation to update contractor
    const handleUpdate = (id) => {
        navigate(`/dashboard/employee/contractors/update-contractor/${id}`);
    };

    return (
        <>
            {searchByFilters?.content?.length > 0 ? (
                <div className='panelTables px-1'>
                    <table style={{ width: '100%', tableLayout: 'fixed' }}>
                        <thead className='no-border-thead'>
                            <th className='first_head' style={{ width: '50px' }}>
                                <Tooltip title={t('de_/_select_all').toUpperCase()} placement='top'>
                                    <Checkbox
                                        className='grid-checkall checkbox'
                                        checked={isAllCheckedContractor}
                                        onChange={handleDeleteAll}
                                        size='small'
                                    />
                                </Tooltip>
                            </th>
                            <th className='first_head' style={{ width: '150px' }}>
                                {t('company_name')}
                            </th>
                            <th style={{ width: '150px' }}>{t('name')}</th>
                            <th style={{ width: '150px' }}>{t('last_name')}</th>
                            <th style={{ width: '150px' }}>{t('second_last_name')}</th>
                            <th style={{ width: '100px' }}>{t('status')}</th>
                            <th style={{ width: '200px' }}>{t('email')}</th>
                            <th style={{ width: '200px' }}>{t('employee_phone_number')}</th>
                            <th style={{ width: '150px' }}>{t('contractor_status')}</th>
                            <th style={{ width: '100px' }}>{t('options')}</th>
                        </thead>
                        <tbody>
                            {searchByFilters?.content
                                ?.filter((user) => {
                                    if (searchContractor === '') {
                                        return user?.contractorCompanyName;
                                    } else {
                                        return user?.contractorCompanyName
                                            ?.toLowerCase()
                                            .includes(searchContractor?.toLowerCase());
                                    }
                                })
                                ?.map((item, index) => (
                                    <tr key={index}>
                                        <td className='first' style={{ width: '50px' }}>
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={selectContractorForDelete?.includes(item?.id)}
                                                id={item?.id}
                                                onChange={handleCheckboxChange}
                                                size='small'
                                            />
                                        </td>
                                        <td
                                            className='first'
                                            style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                        >
                                            <div className='text-left'>
                                                <span style={{ textTransform: 'none' }}>{item?.acronym} |</span>
                                                <span className='font-weight-normal' style={{ textTransform: 'none' }}>
                                                    {' ' + item?.contractorCompanyName}
                                                </span>
                                            </div>
                                        </td>
                                        <td style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {item?.name}
                                        </td>
                                        <td style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {item?.lastName || '-'}
                                        </td>
                                        <td style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {item?.secondLastName || '-'}
                                        </td>
                                        <td style={{ width: '100px' }}>
                                            <span className={'viewcard-container__status ' + status[item?.statusId]}>
                                                {t(status[item?.statusId])}
                                            </span>
                                        </td>
                                        <td style={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {item?.email}
                                        </td>
                                        <td style={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {item?.phoneNumber}
                                        </td>
                                        <td style={{ width: '150px' }}>
                                            <span
                                                className={
                                                    'viewcard-container__status ' + status[item?.contractorStatusId]
                                                }
                                            >
                                                {t(status[item?.contractorStatusId])}
                                            </span>
                                        </td>
                                        <td className='tableIcon' style={{ width: '100px' }}>
                                            <DropDownMenuProfile
                                                menuList={[
                                                    item?.statusId !== 2 && {
                                                        name:
                                                            item?.statusId <= 3
                                                                ? t('approve_document')
                                                                : t('show_details'),
                                                        icon:
                                                            item?.statusId <= 3 ? (
                                                                <InsertDriveFileIcon fontSize='small' />
                                                            ) : (
                                                                <TextSnippetIcon fontSize='small' />
                                                            ),
                                                        onClick: () =>
                                                            handleDetailOrApprove(
                                                                item?.id,
                                                                item?.statusId,
                                                                item?.userId,
                                                            ),
                                                    },

                                                    {
                                                        name: t('update_contractor'),
                                                        icon: <ModeEditOutlinedIcon fontSize='small' />,
                                                        onClick: () => handleUpdate(item?.id),
                                                    },

                                                    {
                                                        name: t('delete_contractor'),
                                                        icon: <ListAltIcon fontSize='small' />,
                                                        onClick: () => {
                                                            setDeleteContractorShow(true);
                                                            setSelectContractorForDelete([item?.id]);
                                                        },
                                                    },
                                                ].filter(Boolean)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <NotInformation text={t('no_information')} card={true} />
            )}

            {searchByFilters?.content?.length > 0 && (
                <div className='d-flex justify-content-center'>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[20, 40, 60]}
                        count={searchByFilters?.totalElements}
                        page={page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage={t('contractors_per_page')}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}

            <DeleteModal
                show={deleteContractorShow}
                onHide={() => setDeleteContractorShow(false)}
                onClickFn={() => deleteSelectedContractors(selectContractorForDelete)}
                data={selectContractorForDelete}
                title_modal={t('delete_contractors')}
                description={t('are_you_sure_you_want_to_delete')}
                element_modal={t('contractors')}
            />
        </>
    );
};

export default ContractorTable;
