import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import cancel from '../../assets/images/ic-cancel.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const PluginWarnModal = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    return (
        <Modal
            className='log-modal modal-custom-height'
            {...props}
            //
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            backdrop='static'
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('warning')}</Modal.Title>
                <img
                    onClick={() => {
                        props.onHide();
                        props.onLog();
                    }}
                    className='dialog-close-custom'
                    src={cancel}
                    alt=''
                />
                <p className='paragraph_title mt-3'>{t('plugin_warn')}</p>
            </Modal.Header>
        </Modal>
    );
};

export default PluginWarnModal;
