import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import AddCardModal from '../../pages/Dashboard/Company/Employees/Modal/AddCardModal';

const CreateCardBtn = ({ body, option, loaded, setLoaded, creationErrorsShow, setCreationErrorsShow }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [addCustomizedDialogShow, setAddCustomizedDialogShow] = useState(false);
    const [customizedList, setCustomizedList] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFormat = () => {};

    const handleCard = () => {
        setAddCustomizedDialogShow(true);
    };

    return (
        <div>
            <button className='add-btn-1' onClick={handleClick}>
                <i class='fa fa-plus' aria-hidden='true'></i>
                {t('create')
                    .split(' ')
                    .map((word, index) => (
                        <React.Fragment key={index}>
                            {word}
                            <br />
                        </React.Fragment>
                    ))}
            </button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleCard}>
                    <span className='export-data-menu'>{t('card')}</span>
                </MenuItem>
                <MenuItem onClick={handleFormat}>
                    <span className='export-data-menu'>{t('format')}</span>
                </MenuItem>
            </Menu>
            <AddCardModal
                open={addCustomizedDialogShow}
                onClose={() => {
                    setAddCustomizedDialogShow(false);
                }}
                loaded={loaded}
                setLoaded={setLoaded}
                customizedList={customizedList}
                setCustomizedList={setCustomizedList}
                creationErrorsShow={creationErrorsShow}
                setCreationErrorsShow={setCreationErrorsShow}
            />
        </div>
    );
};

export default CreateCardBtn;
