import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstanceValidas, apiInstance, apiInstanceUpdate } from '../../Apis/Axios';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errorCode } from '../../enums/errorCodeEnum';

// get all document types /available-types/{countryCode} veridas-module validas-controller
export const GetAllDocumentFormats = createAsyncThunk(
    'document/getAllAvailableTypes',
    async (countryCode, { dispatch, getState }) => {
        const result = await apiInstanceValidas
            .get(`available-types/${countryCode}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                const status = error.response?.status;

                const errorMessage = status && errorCode[status] ? errorCode[status] : null;
                const { t } = useTranslation();
                toast.error(t(errorMessage));
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
/* /validation veridas-module validas-controller */
export const GetValidationId = createAsyncThunk('document/getValidationId', async (params, { dispatch, getState }) => {
    const result = await apiInstanceValidas
        .get('validation')
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            const status = error.response?.status;

            const errorMessage = status && errorCode[status] ? errorCode[status] : null;
            const { t } = useTranslation();
            toast.error(t(errorMessage));
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

/* /user-service/restriction/get user-module user-controller */
export const GetUserRestrictionVisitor = createAsyncThunk(
    'document/getUserRestrictionVisitor',
    async (params, { dispatch, getState }) => {
        const result = await apiInstanceUpdate
            .get('user-service/restriction/get')
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                const status = error.response?.status;

                const errorMessage = status && errorCode[status] ? errorCode[status] : null;
                const { t } = useTranslation();
                toast.error(t(errorMessage));
                return error.response;
            });

        const { data, status } = result;

        return { data, status };
    },
);
/* /user-service/identity-validation/{userId}  user service  */
export const GetValidateIdentifyById = createAsyncThunk(
    'document/getValidateIdentifyById',
    async (userId, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`user-service/identity-validation/${userId}`)
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                const status = error.response?.status;

                const errorMessage = status && errorCode[status] ? errorCode[status] : null;
                const { t } = useTranslation();
                toast.error(t(errorMessage));
                return error.response;
            });

        const { data, status } = result;

        return { data, status };
    },
);
export const ValidationProcessCancel = createAsyncThunk(
    'document/validationProcessCancel',
    async (validationId, { dispatch, getState }) => {
        const result = await apiInstanceValidas
            .get(`cancellation/${validationId}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                const status = error.response?.status;

                const errorMessage = status && errorCode[status] ? errorCode[status] : null;
                const { t } = useTranslation();
                toast.error(t(errorMessage));
                return error.response;
            });

        const { data, status } = result;

        return { data, status };
    },
);

export const GetDocumentValidationStatus = createAsyncThunk(
    'document/getDocumentValidationStatus',
    async ({ validationId }, { dispatch, getState }) => {
        const response = await apiInstance
            .post('validas-service/v1/process', null, {
                params: { validationId },
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                const status = error.response?.status;

                const errorMessage = status && errorCode[status] ? errorCode[status] : null;
                const { t } = useTranslation();
                toast.error(t(errorMessage));
                return error.response;
            });
        const { data, status } = response;

        return { data, status };
    },
);
/* /* /* /validation/obseve-document veridas-module validas-controller */
export const ValidateObverseDocument = createAsyncThunk(
    'document/validateObverseDocument',
    async ({ analysisType, documentImage, documentType, validationId }) => {
        try {
            const blob = await fetch(documentImage).then((res) => res.blob());
            const file = new File([blob], 'filename.jpeg', { type: 'image/jpeg' });

            const formData = new FormData();
            formData.append('analysisType', analysisType);
            formData.append('documentImage', file);
            formData.append('documentType', documentType);
            formData.append('validationId', validationId);

            const response = await apiInstanceValidas.put('/validation/obverse-document', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
);
/* /* /* /validation/obseve-document veridas-module validas-controller */
export const ValidateReverseDocument = createAsyncThunk(
    'document/validateReverseDocument',
    async ({ analysisType, documentImage, validationId }) => {
        try {
            const blob = await fetch(documentImage).then((res) => res.blob());
            const file = new File([blob], 'filename.jpeg', { type: 'image/jpeg' });

            const formData = new FormData();
            formData.append('analysisType', analysisType);
            formData.append('documentImage', file);
            formData.append('validationId', validationId);

            const response = await apiInstanceValidas.put('/validation/reverse-document', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
);

export const ValidateSelfie = createAsyncThunk(
    'document/validateSelfie',
    async ({ image, imageAlive, validationId }) => {
        try {
            const blobImage = await fetch(image).then((res) => res.blob());
            const fileImage = new File([blobImage], 'filename.jpeg', { type: 'image/jpeg' });

            const blobAlive = await fetch(imageAlive).then((res) => res.blob());
            const fileAlive = new File([blobAlive], 'filename.jpeg', { type: 'image/jpeg' });

            const formData = new FormData();

            formData.append('image', fileImage);
            formData.append('imageAlive', fileAlive);
            formData.append('validationId', validationId);

            const response = await apiInstanceValidas.put('/validation/selfie', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            throw Error(error.message);
        }
    },
);
/* /validation/{id}/token
id: Mismo que se obuvo de la petición pasada
veridas-module validas-controller */
export const GetTokenChallenge = createAsyncThunk(
    'document/getTokenChallenge',
    async (validationId, { dispatch, getState }) => {
        const result = await apiInstanceValidas
            .get(`validation/${validationId}/token`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                const status = error.response?.status;

                const errorMessage = status && errorCode[status] ? errorCode[status] : null;
                const { t } = useTranslation();
                toast.error(t(errorMessage));
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
/* /validation/video-photo
veridas-module validas-controller */
export const ValidateVideoPhoto = createAsyncThunk(
    'document/validateVideoPhoto',
    async ({ annotations, challenge, selfie, validationId, video }) => {
        try {
            const blobImage = await fetch(selfie).then((res) => res.blob());
            const fileImage = new File([blobImage], 'filename.jpeg', { type: 'image/jpeg' });

            /*  const blobAlive = await fetch(video).then((res) => res.blob());
            const fileAlive = new File([blobAlive], 'filename.jpeg', { type: 'image/jpeg' }); */

            const annotationsBlob = new Blob([annotations], { type: 'text/vtt' });

            const formData = new FormData();

            formData.append('annotations', annotationsBlob);
            formData.append('selfie', fileImage);
            formData.append('video', video);
            formData.append('challenge', challenge);
            formData.append('validationId', validationId);

            const response = await apiInstanceValidas.put('validation/video-photo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    },
);

/* /v1/validation/update-data
veridas-module validas-controller */

export const updateContextualData = createAsyncThunk(
    'document/updateContextualData',
    async (validationId, { dispatch, getState }) => {
        const result = await apiInstanceValidas
            .put(`validation/${validationId}/contextual_data`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                const status = error.response?.status;

                const errorMessage = status && errorCode[status] ? errorCode[status] : null;
                const { t } = useTranslation();
                toast.error(t(errorMessage));
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
export const updateVeridasUser = createAsyncThunk(
    'document/updateVeridasUser',
    async ({ userId, validationId, ocrAndScoresResponse }, { dispatch, getState }) => {
        try {
            const url = `/user-service/veridas-user/${userId}/${validationId}`;

            const response = await apiInstanceUpdate.put(url, ocrAndScoresResponse, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    },
);
