import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import car from '../../assets/defaultImages/defaultCar.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DocumentStatusThree from '../../components/DocumentStatusThree';
import {
    CreateSupplierDocValue,
    CreateToExternalVehicle,
    DownloadProviderImage,
    GetProviderVehicleDetail,
    GetProviderVehicleStatus,
    GetSingleProviderVehicle,
    SetSupplierDocValue,
    SetToExternalVehicle,
} from '../../reduxToolkit/Providers/providersApi';
import UnlinkDeviceChangeModal from './Modal/UnlinkDeviceChangeModal';
import DeleteModal from '../Modals/DeleteModal';
import genderId from '../../hooks/genderId';
import ReturnArrow from '../../components/buttons/ReturnArrow';
import { DeleteItemsApi } from '../../reduxToolkit/Commons/CommonsApi';
import ic_edit from '../../../src/assets/icon/ic-edit-pencil.svg';
import { status } from '../../enums/statusEnum';
import { GoPrimitiveDot } from 'react-icons/go';
import { statusColor } from '../../enums/statusColorEnum';

const VehicleDetail = ({ approveDocumentVehicle }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use State
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectDocumentsForDelete, setSelectDocumentsForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteDocShow, setDeleteDocShow] = useState(false);
    const [unlinkShow, setUnlinkShow] = useState(false);
    const [selectVehicleForDelete, setSelectVehicleForDelete] = useState();
    const [deleteVehicleShow, setDeleteVehicleShow] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(0);
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const userType = user?.data?.userType?.name;
    // use Selector
    const {
        getProviderVehicleDetail,
        createToExternalVehicle,
        setToExternalVehicle,
        updateProviderVehicleData,
        saveProviderVehicleImage,
    } = useSelector((state) => state?.providersSlice);

    const { uploadImage, changeCount } = useSelector((state) => state.sharedSlice);

    useEffect(() => {
        dispatch(GetProviderVehicleDetail(sessionStorage.getItem('vehicleidfordetail'))).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success === true) {
                    setSelectVehicleForDelete([data.vehicle.id]);
                }
            },
        );
    }, [
        updateProviderVehicleData,
        createToExternalVehicle,
        setToExternalVehicle,
        saveProviderVehicleImage,
        deletedFlag,
        uploadImage,
        changeCount,
    ]);

    useEffect(() => {
        dispatch(GetProviderVehicleStatus());
    }, []);

    const deleteSelectedDocuments = (deleteItem) => {
        const tableName = 'supplier_vehicle_document';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setDeletedFlag(deletedFlag + 1);
                        setSelectDocumentsForDelete([]);
                        setIsAllChecked(false);
                    }
                },
            );
        }
    };

    const deleteSelectedElement = (deleteItem) => {
        const tableName = 'supplier_vehicle';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        navigate('/dashboard/supplier/vehicles');
                    }
                },
            );
        }
    };

    return (
        <>
            {/* head with back link */}
            <div className='head'>
                <div className='headLeft'>
                    {userType === 'EMPLOYEE' && <ReturnArrow link={'/dashboard/employee/suppliers/order-details'} />}
                    {userType === 'SUPPLIER_IN_CHARGE' && <ReturnArrow link={'/dashboard/supplier/vehicles'} />}

                    <h2>
                        {getProviderVehicleDetail?.vehicle?.status?.id == 3
                            ? t('complete_documents')
                            : t('vehicle_details')}
                    </h2>
                </div>
                <div className='container-top-right-btns'>
                    {/* {
            !approveDocumentVehicle &&
            <button className="unlink-btn-1"
            onClick={()=> setUnlinkShow(true)}
             >
              <i class="fa fa-mobile" aria-hidden="true"></i>
              {t("unlink_device")}
            </button>
          } */}
                    {userType === 'SUPPLIER_IN_CHARGE' && (
                        <Link to={`/dashboard/supplier/update-vehicles/${getProviderVehicleDetail?.vehicle?.id}`}>
                            <button
                                className='add-btn-1'
                                onClick={() => {
                                    dispatch(GetSingleProviderVehicle(getProviderVehicleDetail?.vehicle?.id));
                                }}
                            >
                                <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                                {t('update')}
                            </button>
                        </Link>
                    )}
                    {approveDocumentVehicle && (
                        <button
                            className='delete-btn-1'
                            onClick={() => {
                                setDeleteVehicleShow(true);
                            }}
                        >
                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}
                </div>
            </div>
            <div className='row employee_provider_detail_flow' style={{ gap: lCode === 'ar' ? '1rem' : '' }}>
                <div className='col-md-3 __userData '>
                    <img
                        style={{ boxShadow: '0px 0px 6px #00000066', objectFit: 'contain' }}
                        src={
                            getProviderVehicleDetail?.vehicle?.image
                                ? `data:image/png;base64,${getProviderVehicleDetail?.vehicle?.image}`
                                : car
                        }
                        className='__userImage'
                    />

                    <div className='__body vehicle_body'>
                        <div
                            className={'status, status-vehicle'}
                            style={{ color: statusColor[getProviderVehicleDetail?.vehicle?.status?.id] }}
                        >
                            <p style={{ color: statusColor[getProviderVehicleDetail?.vehicle?.status?.id] }}>
                                {t(status[getProviderVehicleDetail?.vehicle?.status?.id])}
                            </p>
                            <GoPrimitiveDot className='ml-1' />
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <p className='ishead'>{t('brand')}</p>
                                <span>{getProviderVehicleDetail?.vehicle?.brand || '-'}</span>
                            </div>
                            <div className='col-6'>
                                <p className='ishead'>{t('sub_brand')}</p>
                                <span>{getProviderVehicleDetail?.vehicle?.subBrand || '-'}</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <p className='ishead'>{t('color')}</p>
                                <span>{getProviderVehicleDetail?.vehicle?.color || '-'}</span>
                            </div>
                            <div className='col-6'>
                                <p className='ishead'>{t('model')}</p>
                                <span>{getProviderVehicleDetail?.vehicle?.model || '-'}</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <p className='ishead'>{t('plates')}</p>
                                <span>{getProviderVehicleDetail?.vehicle?.plate || '-'}</span>
                            </div>
                            <div className='col-6'>
                                <p className='ishead'>{t('vin')}</p>
                                <span>{getProviderVehicleDetail?.vehicle?.vin || '-'}</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <p className='ishead'>{t('serial_number')}</p>
                                <span>{getProviderVehicleDetail?.vehicle?.serialNumber || '-'}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-8  documents_status_item'>
                    <div className='document_header'>
                        <p className='document_title'>{t('documents')}</p>
                        {approveDocumentVehicle && (
                            <button
                                className='delete-btn-1'
                                disabled={selectDocumentsForDelete?.length === 0}
                                onClick={() => {
                                    setDeleteDocShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>

                    <DocumentStatusThree
                        dataTable={getProviderVehicleDetail?.documents}
                        approve={approveDocumentVehicle}
                        userType={'supplier_vehicle'}
                        setDocValue={SetToExternalVehicle}
                        createDocValue={CreateToExternalVehicle}
                        downloadImg={DownloadProviderImage}
                        optionDownload={'supplier_vehicle_document'}
                        isAllChecked={isAllChecked}
                        setIsAllChecked={setIsAllChecked}
                        selectDocumentsForDelete={selectDocumentsForDelete}
                        setSelectDocumentsForDelete={setSelectDocumentsForDelete}
                    />
                </div>
            </div>

            <DeleteModal
                title_modal={t('delete_documents')}
                data={selectDocumentsForDelete}
                show={deleteDocShow}
                element_modal={t('documents')}
                onHide={() => setDeleteDocShow(false)}
                isReset={setSelectDocumentsForDelete}
                isAllReset={setIsAllChecked}
                onClickFn={() => deleteSelectedDocuments(selectDocumentsForDelete)}
            />

            <DeleteModal
                title_modal={t('delete_vehicle')}
                show={deleteVehicleShow}
                onHide={() => setDeleteVehicleShow(false)}
                onClickFn={() => deleteSelectedElement(selectVehicleForDelete)}
            />

            <UnlinkDeviceChangeModal show={unlinkShow} onHide={() => setUnlinkShow(false)} />
        </>
    );
};

export default VehicleDetail;
