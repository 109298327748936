import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { t } from 'i18next';
import { useRef, useState, useEffect } from 'react';
import fingerIcon from '../../../assets/icon/ic-fingerprint.svg';
import tagIcon from '../../../assets/icon/tag-solid.svg';

import { permissionObj } from '../../../Helpers/permission';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import SearchFilterBtn from '../../../components/buttons/SearchFilterBtn';
import { SearchByFilters } from '../../../reduxToolkit/Search/SearchApi';
import SearchFor from '../../Modals/SearchFor';
import { TABLES } from '../../../Apis/Tables';
import { MODELS } from '../../../Apis/Models';
import { clearSearchFilters } from '../../../reduxToolkit/Search/SearchSlice';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import moment from 'moment';
import { accessMethod } from '../../../enums/accessMethodEnum';
import { Tooltip, MenuItem, Select, InputLabel, FormControl, Box } from '@mui/material';
import faceIcon from '../../../assets/icon/ic-face.svg';
import cardIcon from '../../../assets/icon/ic-card.svg';
import qrIcon from '../../../assets/icon/ic-qr-code.svg';
import pinIcon from '../../../assets/icon/pin-icon.png';
import bluetoothIcon from '../../../assets/icon/bluetooth-icon.svg';
import eyeIcon from '../../../assets/eye-solid.svg';
import checkIcon from '../../../assets/icon/checkIcon.svg';
import cancelIcon from '../../../assets/icon/cancelIcon.svg';
import ExportDataBtn from '../../../components/buttons/ExportDataBtn';
import { GetListSearchModels } from '../../../reduxToolkit/Search/SearchApi';
import { SearchCrudLogByModel } from '../../../reduxToolkit/Search/SearchApi';
import useStyle from '../../../hooks/useStyle';
import { userTypeDrop } from '../../../enums/userTypeEnumDrop';
import NotInformation from '../../../components/NotInformation';

const Logs = () => {
    const [toggleState, setToggleState] = useState(1);
    // const elementRef = useRef(null);
    const { user, permission } = useSelector((state) => state.authenticatioauthennSlice);
    const dispatch = useDispatch();
    const { searchByFilters, getListSearchModels, searchCrudLogByModel } = useSelector((state) => state.SearchSlice);
    const lCode = Cookies.get('i18next') || 'en';
    const { textField, smallBoxStyle, textFieldCommon } = useStyle();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modelType, setModelType] = useState('');

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Props to the filter window

    const [moduleId, setModuleId] = useState(`${MODELS.UserAccessLog}`);
    const [option, setOption] = useState(`${TABLES.USER_ACCESS_LOG}`);
    // const [moduleId, setModuleId] = useState(`${MODELS.LogInLog}`);
    // const [option, setOption] = useState(`${TABLES.LOG_IN_LOG}`);
    const [criteria, setCriteria] = useState();

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage, finalArray, deletedFlag]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, [toggleState]);

    // This components are used to calculate the distance between the top of the window and the top of the table panel
    const elementRef = useRef(null);
    const elementRefer = useRef(null);
    useEffect(() => {
        if (elementRefer.current && toggleState === 1) {
            const rect = elementRefer.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRefer.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        if (elementRef.current && toggleState === 2) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
    }, [toggleState, rowsPerPage, page, sortBy, searchByFilters]);

    //Create body for the respectively search
    useEffect(() => {
        if (loaded) {
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                    from: item.from,
                    to: item.to,
                };
            });

            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: criteriaList,
            };
            if (toggleState === 1) {
                if (modelType !== '') {
                    dispatch(SearchCrudLogByModel({ model: modelType, body: body })).then(
                        ({
                            payload: {
                                data: { success },
                            },
                        }) => {
                            {
                                setLoading(false);
                            }
                        },
                    );
                }
            } else {
                dispatch(SearchByFilters({ option, body })).then(
                    ({
                        payload: {
                            data: { success },
                        },
                    }) => {
                        {
                            setLoading(false);
                        }
                    },
                );
            }
            setCriteria({ searchCriteriaList: criteriaList });
            setLoaded(false);
            setDeletedFlag(false);
        }
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray, deletedFlag, moduleId, modelType]);

    useEffect(() => {
        setModelType('');
        if (toggleState === 1) {
            setModuleId(`${MODELS.LogModule}`);
            setOption(`${TABLES.LOG_MODULE}`);
        } else {
            setModuleId(`${MODELS.LogInLog}`);
            setOption(`${TABLES.LOG_IN_LOG}`);
        }
    }, [toggleState]);

    // check permission check
    useEffect(() => {
        dispatch(GetListSearchModels());
        if (!permission?.includes(permissionObj?.WEB_ACCESS_VEHICLE_READ)) {
            setToggleState(1);
        } else if (!permission?.includes(permissionObj?.WEB_ACCESS_USER_READ)) {
            setToggleState(2);
        }
    }, []);

    return (
        <div>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('logs')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    {(toggleState === 2 || (toggleState === 1 && modelType !== '')) && (
                        <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                    )}
                </div>
            </div>
            <div className='row steps-row mb-3 mx-0' id='pills-tab' role='tablist'>
                {permission?.includes(permissionObj?.WEB_ACCESS_USER_READ) && (
                    <div
                        className={`col-6 text-center p-0 tap_hover ${
                            toggleState === 1 ? 'active_tap' : 'deactive_tap'
                        }`}
                        role='presentation'
                    >
                        <a
                            className={`steps-global btn ${toggleState === 1 ? 'btn-bordered-global' : ''}`}
                            onClick={() => {
                                if (toggleState !== 1) {
                                    setPage(0);
                                    setToggleState(1);
                                    setRowsPerPage(20);

                                    setOption(`${TABLES.USER_ACCESS_LOG}`);
                                    setModuleId(`${MODELS.UserAccessLog}`);
                                    setFinalArray([]);
                                }
                            }}
                            id='pills-home-tab'
                            data-bs-toggle='pill'
                            data-bs-target='#pills-home'
                            type='button'
                            role='tab'
                            aria-controls='pills-home'
                            aria-selected='true'
                        >
                            <span>{t('actions')}</span>
                        </a>
                    </div>
                )}
                {permission?.includes(permissionObj?.WEB_LOG_PLATFORM_READ) && (
                    <div
                        className={`col-6 text-center p-0 tap_hover ${
                            toggleState === 2 ? 'active_tap' : 'deactive_tap'
                        }`}
                        role='presentation'
                    >
                        <a
                            className={`steps-global btn ${toggleState === 2 ? 'btn-bordered-global' : ''}`}
                            onClick={() => {
                                if (toggleState !== 2) {
                                    setPage(0);
                                    setToggleState(2);
                                    setRowsPerPage(20);
                                    setOption(`${TABLES.VEHICLE_ACCESS_LOG}`);
                                    setModuleId(`${MODELS.VehicleAccessLog}`);
                                    setFinalArray([]);
                                }
                            }}
                            id='pills-home-tab'
                            data-bs-toggle='pill'
                            data-bs-target='#pills-home'
                            type='button'
                            role='tab'
                            aria-controls='pills-home'
                            aria-selected='true'
                        >
                            <span>{t('platform_access')}</span>
                        </a>
                    </div>
                )}
            </div>
            <div className='tab-content' id='pills-tabContent'>
                <div
                    className={`${toggleState === 1 ? 'tab-pane fade show active ' : 'tab-pane fade'}`}
                    id='pills-home'
                    role='tabpanel'
                    aria-labelledby='pills-home-tab'
                >
                    <Box className='model-selector w-50' sx={smallBoxStyle}>
                        <FormControl fullWidth sx={textFieldCommon}>
                            <InputLabel id='demo-simple-select-label' className='select_input_field'>
                                {t('model')}
                            </InputLabel>
                            <Select
                                className='select_form_field'
                                size='small'
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                label={t('model')}
                                value={modelType}
                                onChange={(e) => {
                                    setModelType(e.target.value);
                                    setLoaded(true);
                                }}
                            >
                                {getListSearchModels?.map((item, index) => {
                                    return <MenuItem value={item}>{t(item).toLocaleUpperCase()}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <div
                        className='panelTables animated-div px-1'
                        ref={elementRefer}
                        style={{ width: '100%', paddingTop: '0rem' }}
                    >
                        {searchCrudLogByModel?.content?.length > 0 ? (
                            <table className='w-100'>
                                <thead className='no-border-thead'>
                                    {/* <th className='first_head'>{t('object')}</th> */}
                                    {/* <th>{t("last_name")}</th> */}
                                    {/* <th>{t("second_last_name")}</th> */}
                                    <th className='first_head'>{t('user')}</th>
                                    <th>{t('action')}</th>
                                    <th>{t('platform')}</th>
                                    <th>{t('date')}</th>
                                    {/* <th>{t('success')}</th> */}
                                    {/* <th className='last'>{t('details')}</th> */}
                                </thead>
                                <tbody>
                                    {searchCrudLogByModel?.content?.length > 0 &&
                                        searchCrudLogByModel?.content?.map((item) => {
                                            return (
                                                <tr>
                                                    <td className='first align-middle'>
                                                        {/* {`${item?.user?.name || ''} ${item?.user?.lastName || ''} ${
                                                            item?.user?.secondLastName || ''
                                                        }`.trim()} */}
                                                        {item?.userFullName}
                                                    </td>
                                                    {/* <td style={{ maxWidth: 250 }}>{item?.user?.lastName ? item?.user?.lastName : "-"}</td> */}
                                                    {/* <td >{item?.user?.secondLastName ? item?.user?.secondLastName : "-"}</td> */}
                                                    <td>
                                                        {/* {lCode === 'en'
                                                            ? moment(item?.createdAt).format('MM/DD/YYYY')
                                                            : moment(item?.createdAt).format('DD/MM/YYYY')}
                                                        <br />
                                                        {moment(item?.createdAt).format('HH:mm:ss')} */}
                                                        {item?.action}
                                                    </td>
                                                    <td>{item?.platform}</td>
                                                    <td>
                                                        {lCode === 'en'
                                                            ? moment(item?.createdAt).format('MM/DD/YYYY')
                                                            : moment(item?.createdAt).format('DD/MM/YYYY')}
                                                        <br />
                                                        {moment(item?.createdAt).format('HH:mm:ss')}
                                                    </td>

                                                    {/* <td className='last_tr tableIcon'>
                                                        <Tooltip
                                                            title={t('details').toUpperCase()}
                                                            placement='left'
                                                            disableInteractive
                                                        >
                                                            <button className='btn-option' onClick={() => {}}>
                                                                <img src={eyeIcon} alt='eye' />
                                                            </button>
                                                        </Tooltip>
                                                    </td> */}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        ) : (
                            <NotInformation text={t('no_information')} card={true} />
                        )}
                    </div>
                    {searchCrudLogByModel?.content?.length > 0 && (
                        <div className='d-flex justify-content-center'>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={[20, 40, 60]}
                                count={searchCrudLogByModel?.totalElements}
                                page={page}
                                onPageChange={handleChangePage}
                                labelRowsPerPage={t('actions_per_page')}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={`${toggleState === 2 ? 'tab-pane fade show active ' : 'tab-pane fade'}`}
                    id='pills-profile'
                    role='tabpanel'
                    aria-labelledby='pills-profile-tab'
                >
                    <div
                        className='panelTables animated-div-left px-1 mt-1'
                        ref={elementRef}
                        style={{ width: '100%', paddingTop: '0rem' }}
                    >
                        {searchByFilters?.content?.length > 0 ? (
                            <table className='w-100'>
                                <thead className='no-border-thead'>
                                    <th className='first_head'>{t('name')}</th>
                                    <th>{t('last_name')}</th>
                                    <th>{t('second_last_name')}</th>
                                    <th>{t('user_type')}</th>
                                    <th>{t('successful_attempt')}</th>
                                    {/* <th>{t("tag")}</th> */}
                                    <th>{t('platform')}</th>
                                    {/* <th>{t('device')}</th> */}
                                    {/* <th>{t('zone')}</th> */}
                                    {/* <th>{t('success')}</th> */}
                                    <th>{t('date')}</th>
                                </thead>
                                <tbody>
                                    {searchByFilters?.content?.length > 0 &&
                                        searchByFilters?.content?.map((item) => {
                                            return (
                                                <tr>
                                                    <td className='first align-middle'>{item?.userName}</td>
                                                    <td style={{ maxWidth: 250 }}>{item?.userLastName}</td>
                                                    <td>{item?.secondLastName || '-'}</td>
                                                    <td style={{ maxWidth: 250 }}>
                                                        {t(userTypeDrop[item?.userTypeId])}
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={item?.wasSuccessfully ? checkIcon : cancelIcon}
                                                            alt='AccessMethod'
                                                        />
                                                    </td>
                                                    <td>{item?.platform}</td>

                                                    <td style={{ maxWidth: 250 }}>
                                                        {lCode === 'en'
                                                            ? moment(item?.createdAt).format('MM/DD/YYYY')
                                                            : moment(item?.createdAt).format('DD/MM/YYYY')}
                                                        <br />
                                                        {moment(item?.createdAt).format('HH:mm:ss')}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        ) : (
                            <NotInformation text={t('no_information')} card={true} />
                        )}
                    </div>
                    {searchByFilters?.content?.length > 0 && (
                        <div className='d-flex justify-content-center'>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={[20, 40, 60]}
                                count={searchByFilters?.totalElements}
                                page={page}
                                onPageChange={handleChangePage}
                                labelRowsPerPage={t('platform_access_per_page')}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    )}
                </div>
            </div>
            <SearchFor
                open={filterDialogShow}
                onClose={() => {
                    setFilterDialogShow(false);
                }}
                onFiltered={(originalArray) => {
                    setFilterDialogShow(false);
                    setFinalArray(originalArray);
                }}
                moduleId={moduleId}
                option={option}
                modelType={modelType}
                finalArray={finalArray}
            />
        </div>
    );
};

export default Logs;
