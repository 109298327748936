import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ResetByAttemps } from '../../reduxToolkit/CompanyEmployees/CompanyEmployeesApi';

const BtnResetByAttemps = ({ id }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const sendResetByAttemps = () => {
        dispatch(ResetByAttemps(id));
    };

    return (
        <div>
            <div>
                <button className='send-qr-btn-1' onClick={() => sendResetByAttemps()}>
                    <RestartAltIcon style={{ width: '18px', height: '18px', marginBottom: '2px', marginLeft: '0px' }} />
                    {t('reset_by_attempts')}
                </button>
            </div>
        </div>
    );
};

export default BtnResetByAttemps;
