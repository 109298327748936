export const Contract = {
    contractorEmail: '',
    contractorId: '',
    contractorLastName: '',
    contractorName: '',
    contractorPhoneNumber: '',
    contractorSecondLastName: '',
    customs: [
        {
            dayId: null,
            from: null,
            to: null,
            id: null,
            zoneId: null,
            zoneName: '',
            userOrContractIdOrVehicle: '',
        },
    ],
    description: '',
    endDate: null,
    folio: '',
    id: null,
    orderId: null,
    startDate: null,
    statusId: null,
    workShifts: [
        {
            id: null,
            name: null,
        },
    ],

    toCreateUpdate: function ({ workShiftsList, customizedList }) {
        return {
            contractorId: this.contractorId,
            folio: this.folio !== '' ? this.folio : null,
            startDate:
                this.startDate === null
                    ? null
                    : typeof this.startDate === 'number'
                      ? this.startDate
                      : this.startDate.getTime(),
            endDate:
                this.endDate === null ? null : typeof this.endDate === 'number' ? this.endDate : this.endDate.getTime(),
            description: this.description !== '' ? this.description : null,
            id: this.id,
            customs: customizedList?.length > 0 ? customizedList : null,
            workShifts: workShiftsList?.length > 0 ? workShiftsList : null,
        };
    },
};
