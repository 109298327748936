import React, { useState, useEffect, useRef } from 'react';
import AddGraphModal from './AddGraphModal';
import { createSwapy } from 'swapy';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Tab,
    Tabs,
    IconButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TextField,
    Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import '../../scss/Graph/Graph.scss';
import { useDispatch, useSelector } from 'react-redux';
import { GetGraphByUserId, GetTemplates, UpdateDashboardName } from './../../reduxToolkit/Graphs/GraphsApi';
import DeleteIcon from '@mui/icons-material/Delete';
import ChartComponent from './Charts/ChartComponent';
import DeleteDeviceModal from '../Dashboard/Devices/Modals/DeleteDeviceModal';
import DeleteChartModal from './Modals/DeleteDeviceModal';
import { getUserByIdAtEntry } from '../../../src/reduxToolkit/authentication/authenticationSlice';
import AddDashboardModal from './Modals/AddDashboardModal';
import NotInformation from '../../components/NotInformation';
import DeleteDashboardModal from './Modals/DeleteDashboardModal';
import { Button } from 'react-bootstrap';
import { Delete } from '@mui/icons-material';

const chartComponents = {
    1: 'line',
    2: 'bar',
    3: 'pie',
    4: 'donut',
    5: 'radar',
    6: 'polar',
};

const templates = {
    template1: ['section-a', 'section-b', 'section-c'],
    template2: ['section-d', 'section-e', 'section-f', 'section-g'],
    template3: ['section-h', 'section-i', 'section-j', 'section-k', 'section-l'],
    template4: ['section-m', 'section-n', 'section-o', 'section-p', 'section-q', 'section-r', 'section-s'],
};
const GraphsPanel = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [modalShowAddDashboard, setModalShowAddDashboard] = useState(false);
    const [selectedSection, setSelectedSection] = useState(null);
    const [dashboardIdSection, setDashboardIdSection] = useState();
    const [dashboardToDelete, setDashboardToDelete] = useState(null);
    const [filteredDashboards, setFilteredDashboards] = useState([]);

    const [selectedChartId, setSelectedChartId] = useState();
    const [sectionPosition, setSectionPosition] = useState();
    const [showDeleteChartModal, setShowDeleteChartModal] = useState(false);
    const [showDeleteDashboardModal, setShowDeleteDashboardModal] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [charts, setCharts] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [templatesByTab, setTemplatesByTab] = useState({
        0: 'template1',
        1: 'template2',
        2: 'template3',
        3: 'template4',
    });
    const [sectionsByTab, setSectionsByTab] = useState({
        0: templates.template1,
        1: templates.template2,
        2: templates.template3,
        3: templates.template4,
    });
    const [templateLocked, setTemplateLocked] = useState({});
    const [tabLabels, setTabLabels] = useState({});
    const [editingTab, setEditingTab] = useState(null);
    const [changed, setChanged] = useState(false);
    const [newTabName, setNewTabName] = useState('');
    const containerRef = useRef(null);
    const graphData = useSelector((state) => state.GraphsSlice.getGraphByUserId);
    const template = useSelector((state) => state.GraphsSlice.getTemplates);
    const userId = useSelector((state) => state.authenticatioauthennSlice.getUserByIdAtEntry.userId);
    /* const userIdTest = 'e3456e59-ffc4-4a54-a319-e389cd7af380'; */
    useEffect(() => {
        dispatch(GetGraphByUserId(userId));
        dispatch(GetTemplates());
        if (changed) {
            setChanged(false);
        }
    }, [dispatch, changed]);
    useEffect(() => {
        if (graphData && graphData.length > 0) {
            const updatedTabLabels = {};
            const updatedTemplatesByTab = {};
            const updatedCharts = {};

            const templateIdToTemplateName = {
                'b0dbd5a1-3bfa-4b23-871f-e159819414c1': 'template1',
                '0ba1b013-3111-4b0a-b5ba-1d74a1ff2a27': 'template2',
                '226d83d1-f82d-4be5-8347-f85aa7d483ea': 'template3',
                '6ebfbc2f-2532-4f44-b508-afaa9c514ec4': 'template4',
            };

            const dashboards = graphData.filter((dashboard) => [1, 2, 3, 4].includes(dashboard.number));

            dashboards.forEach((dashboard) => {
                const templateName = templateIdToTemplateName[dashboard.templateId];

                updatedTabLabels[dashboard.number] = dashboard.name;
                updatedTemplatesByTab[dashboard.number - 1] = templateName;

                dashboard.charts.forEach((chart) => {
                    if (!updatedCharts[dashboard.number - 1]) {
                        updatedCharts[dashboard.number - 1] = {};
                    }

                    updatedCharts[dashboard.number - 1][chart.position] = {
                        component: chartComponents[chart.chartType],
                        data: chart,
                    };
                });
            });

            setTabLabels((prevLabels) => ({
                ...prevLabels,
                ...updatedTabLabels,
            }));
            setTemplatesByTab((prevTemplates) => ({
                ...prevTemplates,
                ...updatedTemplatesByTab,
            }));
            setCharts((prevCharts) => ({
                ...prevCharts,
                ...updatedCharts,
            }));

            const currentDashboard = dashboards.find((dashboard) => dashboard.number === tabIndex + 1);

            setFilteredDashboards(dashboards);

            if (currentDashboard) {
                setDashboardToDelete(currentDashboard.id);
            }
        }
    }, [graphData, tabIndex]);

    useEffect(() => {
        if (containerRef.current) {
            createSwapy(containerRef.current, { animation: 'dynamic' });
        }
    }, [containerRef.current, tabIndex]);

    const handleTemplateChange = (event) => {
        const newTemplate = event.target.value;
        if (!templateLocked[tabIndex]) {
            setTemplatesByTab((prevTemplates) => ({
                ...prevTemplates,
                [tabIndex]: newTemplate,
            }));
            setSectionsByTab((prevSections) => ({
                ...prevSections,
                [tabIndex]: templates[newTemplate],
            }));
            setTemplateLocked((prevLocked) => ({
                ...prevLocked,
                [tabIndex]: true,
            }));
        }
    };

    const handleTabLabelChange = (index) => {
        setEditingTab(index);
        setNewTabName(tabLabels[index]);
    };

    const handleTabLabelSave = (index) => {
        const dashboard = graphData.find((dashboard) => dashboard.number === index);

        if (!dashboard) {
            return;
        }

        const body = {
            id: dashboard.id,
            name: newTabName,
            number: dashboard.number,
            templateId: dashboard.templateId,
            userId: userId,
        };

        dispatch(UpdateDashboardName({ body })).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success === true) {
                    setChanged(true);
                }
            },
        );

        setEditingTab(null);
        setNewTabName('');
    };

    const handleChartSelection = (graphType, dataType) => {
        setCharts((prevCharts) => {});
        setModalShow(false);
    };

    const handleSectionClick = (section) => {
        const selectedDashboard = graphData.find((dashboard) => dashboard.number === tabIndex + 1);
        const sectionIndex = sectionsByTab[tabIndex].indexOf(section);

        if (selectedDashboard && !charts[`${tabIndex}-${section}`]) {
            setSelectedSection(section);
            setModalShow(true);
            setDashboardIdSection(selectedDashboard.id);
            setSectionPosition(sectionIndex);
        }
    };
    const handleCreateClick = () => {
        setModalShowAddDashboard(true);
    };

    const handleRemoveChart = (chartId) => {
        setShowDeleteChartModal(true);
        setSelectedChartId(chartId);
    };

    const handleRemoveDashboard = () => {
        setShowDeleteDashboardModal(true);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box>
                    <div className='charts-option-container'>
                        <div className='head'>
                            <div className='headLeft'>
                                <h2>{t('graph')}</h2>
                            </div>
                        </div>
                        <div style={{ marginRight: '15px' }} className='container-top-right-btns'>
                            <button
                                className='add-btn-1'
                                id='btn_gp_createDashboard'
                                onClick={() => handleCreateClick()}
                            >
                                <i class='fa fa-plus' aria-hidden='true'></i>
                                {t('create_dashboard')}
                            </button>{' '}
                            <button
                                className='delete-btn-1'
                                style={{ width: '100px' }}
                                id='btn_gp_deleteDashboard'
                                onClick={() => handleRemoveDashboard()}
                            >
                                <i className='fa fa-trash-o' aria-hidden='true'></i>
                                <span className='split-text'>{t('delete_dashboard')}</span>
                            </button>
                        </div>

                        <Tabs
                            value={tabIndex}
                            onChange={(event, newValue) => {
                                setTabIndex(newValue);
                            }}
                            aria-label='tabs'
                        >
                            {Object.keys(tabLabels).map((index) => (
                                <Tab
                                    key={index}
                                    label={
                                        editingTab === Number(index) ? (
                                            <div>
                                                <TextField
                                                    id='txt_gp_editDashboardName'
                                                    value={newTabName}
                                                    onChange={(e) => setNewTabName(e.target.value)}
                                                    onBlur={() => handleTabLabelSave(Number(index))}
                                                    autoFocus
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                {' '}
                                                <Tooltip title={t('delete_dashboard')}>
                                                    <IconButton
                                                        size='small'
                                                        id='btn_gp_deleteDashboard'
                                                        onClick={() =>
                                                            handleRemoveDashboard(
                                                                filteredDashboards.find(
                                                                    (d) => d.number === tabIndex + 1,
                                                                )?.id,
                                                            )
                                                        }
                                                    ></IconButton>
                                                </Tooltip>
                                                {tabLabels[index].toLocaleUpperCase()}
                                                <Tooltip title={t('edit_tab_name')}>
                                                    <IconButton
                                                        size='small'
                                                        id='btn_gp_editTabName'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleTabLabelChange(Number(index));
                                                        }}
                                                    >
                                                        <EditIcon fontSize='small' style={{ fontSize: 13 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        )
                                    }
                                />
                            ))}
                        </Tabs>

                        {graphData.length === 0 ? (
                            <NotInformation text={t('no_information')} card={false} />
                        ) : (
                            <>
                                <div className='container' ref={containerRef}>
                                    {' '}
                                    {sectionsByTab[tabIndex].map((section, index) => {
                                        const chartData =
                                            charts[tabIndex] && charts[tabIndex][index]
                                                ? charts[tabIndex][index].data
                                                : null;
                                        const chartType =
                                            charts[tabIndex] && charts[tabIndex][index]
                                                ? charts[tabIndex][index].component
                                                : 'bar';
                                        const position =
                                            charts[tabIndex] && charts[tabIndex][index]
                                                ? charts[tabIndex][index].data.position
                                                : index;
                                        const label =
                                            chartData && chartData.datasets && chartData.datasets.length > 0
                                                ? chartData.datasets[0].label
                                                : 'No Label';

                                        return (
                                            <div key={section} className={section} data-swapy-slot={section}>
                                                <div
                                                    className={`content-${section.charAt(section.length - 1)}`}
                                                    data-swapy-item={section.charAt(section.length - 1)}
                                                >
                                                    {chartData ? (
                                                        <>
                                                            <Tooltip title={t('delete')}>
                                                                <IconButton
                                                                    id='btn_gp_deleteChart'
                                                                    style={{ position: 'absolute', top: 10, right: 10 }}
                                                                    onClick={() => handleRemoveChart(chartData.id)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>

                                                            <ChartComponent
                                                                data={chartData}
                                                                label={label}
                                                                typo={chartType}
                                                            />
                                                        </>
                                                    ) : (
                                                        <div
                                                            className='add-graphic-message'
                                                            onClick={() => handleSectionClick(section)}
                                                            id='btn_gp_addChart'
                                                        >
                                                            <div>{t('click_to_add_chart').toLocaleUpperCase()}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </Box>
            </Box>
            {modalShow && (
                <AddGraphModal
                    show={modalShow}
                    chartComponents={chartComponents}
                    sectionPosition={sectionPosition}
                    dashboardIdSection={dashboardIdSection}
                    onHide={() => setModalShow(false)}
                    changed={changed}
                    setChanged={setChanged}
                />
            )}
            {modalShowAddDashboard && (
                <AddDashboardModal
                    show={modalShowAddDashboard}
                    onHide={() => setModalShowAddDashboard(false)}
                    templates={templates}
                    userId={userId}
                    dashboardId={dashboardIdSection}
                    graphData={graphData}
                    changed={changed}
                    setChanged={setChanged}
                />
            )}
            {showDeleteChartModal && (
                <DeleteChartModal
                    show={showDeleteChartModal}
                    onHide={() => setShowDeleteChartModal(false)}
                    chartId={selectedChartId}
                    changed={changed}
                    setChanged={setChanged}
                    flag={deletedFlag}
                    onDelete={() => setDeletedFlag(true)}
                />
            )}
            {showDeleteDashboardModal && (
                <DeleteDashboardModal
                    show={showDeleteDashboardModal}
                    onHide={() => setShowDeleteDashboardModal(false)}
                    changed={changed}
                    setChanged={setChanged}
                    dashboardId={dashboardToDelete}
                />
            )}
        </>
    );
};

export default GraphsPanel;
