/*
Author : Arman Ali
Module: create Vehicle
github: https://github.com/Arman-Arzoo
*/

// import libarary and other
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import useStyle from '../../../../hooks/useStyle';
import UploadImageModal from '../../../../components/UploadImageModal';
import defaultImages from '../../../../assets/defaultImages/defaultCar.svg';
import broomIcon from '../../../../assets/icon/broom-solid.svg';
import BootstrapTooltip from '../../../../utils/BootstrapTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { CreateVehicleEmployee, GetVehicleStatus, GetVehicleTags } from '../../../../reduxToolkit/Vehicle/VehicleApi';
import { toast } from 'react-toastify';
import ReturnArrow from '../../../../components/buttons/ReturnArrow';
import { Vehicle } from '../../../../models/Vehicle';
// import vehicleDefault from '../../../../assets/defaultImages/vehicle.svg'
import { blockInvalidNumericChar } from '../../../../utils/blockInvalidNumericChar';
import {
    CreateVehicleAndRelationSupplier,
    GetSingleProviderVehicle,
    UpdateProviderVehicleData,
} from '../../../../reduxToolkit/Providers/providersApi';
import { CreateVehicleAndRelationContractor } from '../../../../reduxToolkit/Contractor/ContractorApi';
import { UpdateVehicleV1 } from '../../../../reduxToolkit/Vehicle/VehicleApi';
import ClearButton from '../../../../components/ClearButton';
import { ClearGetSupplierInfoById } from '../../../../reduxToolkit/Providers/providerSlice';
import { status } from '../../../../enums/statusEnum';

// Main Component
const CreateVehicle = ({ isUpdate }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { smallBoxStyle, textFieldCommon } = useStyle();
    const [showModal, setShowModal] = useState(false);
    const [imageFile, setImageFile] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [vehicle, setVehicle] = useState(Vehicle);
    const [emptyFields, setEmptyFields] = useState(false);
    const [isValidModel, setIsvalidModel] = useState(false);
    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));

    // useSelctor state from store slice vehicleSlice
    const { getVehicleStatus, getVehicleTags } = useSelector((state) => state.VehicleSlice);
    const { getSingleProviderVehicle } = useSelector((state) => state?.providersSlice);
    const resetForm = () => {
        setVehicle(Vehicle);
    };
    const handelCreateVehicle = () => {
        setEmptyFields(true);
        validateModel();
        if (
            vehicle.brand === '' ||
            vehicle.subBrand === '' ||
            vehicle.color === '' ||
            vehicle.plate === '' ||
            (vehicle.model !== '' && !isValidModel)
        ) {
            toast.warn(t('please_fill_all_fields').toUpperCase());
        } else {
            if (isUpdate) {
                if (contractorId !== null) {
                    dispatch(
                        UpdateVehicleV1({
                            updateImage: vehicle.image !== null,
                            vehicleData: vehicle.toCreateUpdate(),
                            imageFile,
                        }),
                    ).then((res) => {
                        if (res.payload.data.code === 200) {
                            toast.success(t('has_been_updated_successfully').toUpperCase());
                            navigate(-1);
                            dispatch(GetSingleProviderVehicle(sessionStorage.getItem('vehicleidfordetail')));
                        } else {
                            toast.error(t('error_updating').toUpperCase());
                        }
                    });
                } else if (supplierId !== null) {
                    dispatch(
                        UpdateVehicleV1({
                            updateImage: vehicle.image !== null,
                            vehicleData: vehicle.toCreateUpdate(),
                            imageFile,
                        }),
                    ).then((res) => {
                        if (res.payload.data.code === 200) {
                            toast.success(t('has_been_updated_successfully').toUpperCase());
                            navigate(-1);
                            dispatch(GetSingleProviderVehicle(sessionStorage.getItem('vehicleidfordetail')));
                        } else {
                            toast.error(t('error_updating').toUpperCase());
                        }
                    });
                }
            } else {
                if (contractorId !== null) {
                    dispatch(
                        CreateVehicleAndRelationContractor({ vehicleData: vehicle.toCreateUpdate(), imageFile }),
                    ).then((res) => {
                        if (res.payload.data.code === 201) {
                            toast.success(t('has_been_created_successfully').toUpperCase());
                            navigate('/dashboard/contractor/search-vehicle');
                        } else {
                            toast.error(t('error_creating'.toUpperCase()));
                        }
                    });
                } else if (supplierId !== null) {
                    dispatch(
                        CreateVehicleAndRelationSupplier({ vehicleData: vehicle.toCreateUpdate(), imageFile }),
                    ).then((res) => {
                        if (res.payload.data.code === 201) {
                            toast.success(t('has_been_created_successfully').toUpperCase());
                            navigate('/dashboard/supplier/vehicles');
                        } else {
                            toast.error(t('error_creating').toUpperCase());
                        }
                    });
                } else {
                    dispatch(CreateVehicleEmployee({ vehicleData: vehicle.toCreateUpdate(), imageFile })).then(
                        (res) => {
                            toast.success(t('has_been_created_successfully').toUpperCase());
                            if (res.payload.data.code === 201) {
                                navigate('/dashboard/employee/vehicle-fleet');
                            } else {
                                toast.error(t('error_creating').toUpperCase());
                            }
                        },
                    );
                }
            }
        }
    };

    const validateModel = (newValue) => {
        let value;
        if (newValue) {
            value = newValue;
        } else {
            value = vehicle.model;
        }
        if (value <= 2100 && value >= 1950) {
            setIsvalidModel(true);
        } else {
            setIsvalidModel(false);
        }
    };

    useEffect(() => {
        if (isUpdate) {
            setVehicle((prevData) => ({
                ...prevData,
                ...getSingleProviderVehicle,
                statusId: getSingleProviderVehicle?.status?.id,
            }));
            validateModel(getSingleProviderVehicle?.model);
        }
    }, [getSingleProviderVehicle]);

    useEffect(() => {
        dispatch(GetVehicleStatus());
        dispatch(GetVehicleTags());
        dispatch(GetSingleProviderVehicle(sessionStorage.getItem('vehicleidfordetail')));
        return () => {
            dispatch(ClearGetSupplierInfoById());
            resetForm();
        };
    }, []);
    return (
        <>
            {/* head with back link */}
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow
                        // link={isUpdate ? contractorId !== null ? `/dashboard/contractor/vehicle-contract-detail/${sessionStorage.getItem("vehicleidfordetail")}` : `/dashboard/supplier/vehicles-details/${sessionStorage.getItem("vehicleidfordetail")}` : contractorId !== null ? '/dashboard/contractor/search-vehicle' : supplierId !== null ? '/dashboard/supplier/vehicles' : "/dashboard/employee/vehicle-fleet"}
                        link={
                            isUpdate
                                ? false
                                : contractorId !== null
                                  ? '/dashboard/contractor/search-vehicle'
                                  : supplierId !== null
                                    ? '/dashboard/supplier/vehicles'
                                    : '/dashboard/employee/vehicle-fleet'
                        }
                    />
                    <h2>{isUpdate ? t('update_vehicle') : t('create_vehicle')}</h2>
                </div>
            </div>
            {/* vehicle create card */}
            <div className='vehicle_create_container'>
                <div className='vehice_create_container_main'>
                    <div className='create_vehicle_card'>
                        <button
                            className='create custom_primary_btn_dark'
                            onClick={() => {
                                handelCreateVehicle();
                            }}
                            style={{ width: '230px' }}
                        >
                            {isUpdate ? t('update').toUpperCase() : t('create').toUpperCase()}
                        </button>
                        {isUpdate !== true && (
                            <div className='mr-1'>
                                <ClearButton
                                    flagTooltip={true}
                                    textTooltip={t('clean_all_inputs')}
                                    handleClear={() => resetForm()}
                                />
                            </div>
                        )}
                        <div className='image_upload_container'>
                            <div className='image_upload'>
                                <img
                                    src={
                                        previewImage
                                            ? previewImage
                                            : isUpdate
                                              ? vehicle?.image
                                                  ? `data:image/png;base64,${vehicle?.image}`
                                                  : defaultImages
                                              : defaultImages
                                    }
                                    alt='vehicle'
                                    style={{ objectFit: 'contain' }}
                                />
                            </div>
                            <div className='upload_icon_btn' onClick={() => setShowModal(true)}>
                                <i className='fa fa-long-arrow-right height' aria-hidden='true'></i>
                                <i className='fa fa-long-arrow-left' aria-hidden='true'></i>
                            </div>
                        </div>

                        <div className='input_form_container p-4'>
                            <div className='row pt-2'>
                                <div className='col-md-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.brand === ''}
                                            fullWidth
                                            required
                                            label={t('brand')}
                                            name='brand'
                                            value={vehicle.brand}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    brand: e.target.value,
                                                }))
                                            }
                                            id='BRAND'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                            helperText={emptyFields && vehicle.brand === '' ? t('requiredField') : ''}
                                        />
                                    </Box>
                                </div>
                                <div className='col-md-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.subBrand === ''}
                                            fullWidth
                                            required
                                            label={t('sub_brand')}
                                            name='subBrand'
                                            value={vehicle.subBrand}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    subBrand: e.target.value,
                                                }))
                                            }
                                            id='subBrand'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                            helperText={
                                                emptyFields && vehicle.subBrand === '' ? t('requiredField') : ''
                                            }
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className='row pt-0'>
                                <div className='col-md-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.color === ''}
                                            fullWidth
                                            required
                                            label={t('color')}
                                            name='color'
                                            value={vehicle.color}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    color: e.target.value,
                                                }))
                                            }
                                            id='color'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                            helperText={emptyFields && vehicle.color === '' ? t('requiredField') : ''}
                                        />
                                    </Box>
                                </div>
                                <div className='col-md-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.model !== '' && !isValidModel}
                                            type='number'
                                            fullWidth
                                            onKeyDown={blockInvalidNumericChar}
                                            label={t('model')}
                                            name='model'
                                            value={vehicle.model || ''}
                                            // onWheelCapture={(e) => e.preventDefault()}
                                            onFocus={(e) =>
                                                e.target.addEventListener(
                                                    'wheel',
                                                    function (e) {
                                                        e.preventDefault();
                                                    },
                                                    { passive: false },
                                                )
                                            }
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                validateModel(newValue);
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    model: e.target.value,
                                                }));
                                            }}
                                            id='model'
                                            sx={textFieldCommon}
                                            helperText={
                                                emptyFields && vehicle.model !== '' && !isValidModel
                                                    ? t('min_1950_max_2100')
                                                    : ''
                                            }
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className='row pt-0'>
                                <div className='col-md-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.plate === ''}
                                            fullWidth
                                            required
                                            label={t('plates')}
                                            name='plates'
                                            value={vehicle.plate}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    plate: e.target.value,
                                                }))
                                            }
                                            id='plates'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                            helperText={emptyFields && vehicle.plate === '' ? t('requiredField') : ''}
                                        />
                                    </Box>
                                </div>
                                <div className='col-md-6'>
                                    <Box className='box-text-field' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('vin')}
                                            name='vin'
                                            value={vehicle.vin || ''}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    vin: e.target.value,
                                                }))
                                            }
                                            id='vin'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className='row pt-0'>
                                <div className='col-md-6'>
                                    <Box className='box-text-field' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('serial_number')}
                                            name='serialNumber'
                                            value={vehicle.serialNumber || ''}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    serialNumber: e.target.value,
                                                }))
                                            }
                                            id='serialNumber'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                        />
                                    </Box>
                                </div>
                                {isUpdate && vehicle.statusId > 3 && (
                                    <div className='col-md-6'>
                                        <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                            <FormControl fullWidth sx={textFieldCommon}>
                                                <InputLabel id='status'>{t('status')}</InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId='status'
                                                    id='statusId'
                                                    label={t('status')}
                                                    value={vehicle.statusId}
                                                    onChange={(e) =>
                                                        setVehicle((prevData) => ({
                                                            ...prevData,
                                                            statusId: e.target.value,
                                                        }))
                                                    }
                                                >
                                                    {getVehicleStatus?.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item.id}>{t(status[item.id])}</MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                )}
                            </div>
                            <div className='row pt-0'>
                                <div className='col-md-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle} display={'none'}>
                                        <FormControl fullWidth sx={textFieldCommon}>
                                            <InputLabel id='tags' hidden>
                                                {t('tags')}
                                            </InputLabel>
                                            <Select size='small' hidden labelId='tags' id='tagsId' label={t('tags')}>
                                                {getVehicleTags?.map((item, index) => {
                                                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <UploadImageModal
                titlen={t('add_image')}
                show={showModal}
                setImage={setImageFile}
                preview={previewImage}
                setPreview={setPreviewImage}
                onHide={() => setShowModal(false)}
            />
        </>
    );
};

export default CreateVehicle;
