import React from 'react';
import {
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import { DatePicker, DateRangePicker, LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import userregular from '../../../../../assets/images/user-regular.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ClearButton from '../../../../../components/ClearButton';
import { status } from '../../../../../enums/statusEnum';

const Step3EmployeeContract = ({
    employeeData,
    onChange,
    setEmployeeData,
    employeeRoles,
    contractStatusList,
    employeeWorkStations,
    employeeDepartments,
    emptyFields,
    isValidStartDate,
    setIsValidStartDate,
    isValidEndDate,
    setIsValidEndDate,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    const handleStartDateChange = (selectedDate) => {
        setEmployeeData((prevData) => ({
            ...prevData,
            startDate: selectedDate,
        }));
    };

    const handleEndDateChange = (selectedDate) => {
        setEmployeeData((prevData) => ({
            ...prevData,
            endDate: selectedDate,
        }));
    };

    //This section indicates what to do when we click clean button
    const handleClear = () => {
        setEmployeeData({
            departmentId: '',
            employeeId: '',
            startDate: null,
            endDate: null,
            roleId: '',
            zoneId: '',
            contractStatusId: '',
        });
    };

    const smallBoxStyle = {
        width: '100%',
        maxWidth: '100%',
        fontSize: '20px',
        height: '50px',
    };

    const textField = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& 	.MuiFormLabel-filled': {
            marginTop: '-5px',
        },
    };
    return (
        <div>
            <div className='create-enrollment-data'>
                {(employeeData === null || employeeData === '') && (
                    <ClearButton
                        className='mt-0 pt-0'
                        handleClear={handleClear}
                        flagTooltip={true}
                        textTooltip={t('clean_all_fields').toUpperCase()}
                    />
                )}
                <div>
                    <div className='form-field'>
                        <Box className='requiredData' sx={smallBoxStyle}>
                            <FormControl
                                fullWidth
                                required
                                sx={textField}
                                error={emptyFields && employeeData.roleId === ''}
                            >
                                <InputLabel size='small' id='demo-simple-select-label'>
                                    {t('privilege')}
                                </InputLabel>
                                <Select
                                    size='small'
                                    labelId='demo-simple-select-label'
                                    name='roleId'
                                    id='slt_s3ec_privilege'
                                    label={t('privilege')}
                                    value={employeeData.roleId}
                                    onChange={onChange}
                                >
                                    {employeeRoles?.map((item) => (
                                        <MenuItem key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {emptyFields && employeeData.roleId === '' && (
                                    <FormHelperText className='select_helper_text'>
                                        {t('selectOptionText')}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box className='requiredData' sx={smallBoxStyle}>
                            <TextField
                                size='small'
                                fullWidth
                                error={emptyFields && employeeData.employeeId === ''}
                                required
                                name='employeeId'
                                label={t('employee_id')}
                                id='slt_s3ec_employeeId'
                                value={employeeData.employeeId}
                                inputProps={{ maxLength: 128 }}
                                onChange={onChange}
                                sx={textField}
                                helperText={emptyFields && employeeData.employeeId === '' ? t('requiredField') : ''}
                            />
                        </Box>
                    </div>

                    <div className='form-field'>
                        <Box
                            // className='requiredData'
                            sx={smallBoxStyle}
                        >
                            <FormControl
                                fullWidth
                                // required
                                sx={textField}
                            >
                                <InputLabel size='small' id='demo-simple-select-label'>
                                    {t('work_station')}
                                </InputLabel>
                                <Select
                                    size='small'
                                    labelId='demo-simple-select-label'
                                    name='zoneId'
                                    id='slt_s3ec_workStation'
                                    label={t('work_station')}
                                    value={employeeData.zoneId}
                                    onChange={onChange}
                                >
                                    {employeeWorkStations?.map((item) => (
                                        <MenuItem key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/* {emptyFields && employeeData.zoneId === '' && (
                                    <FormHelperText className='select_helper_text'>{t('selectOptionText')}</FormHelperText>
                                )

                                } */}
                            </FormControl>
                        </Box>
                        <Box sx={smallBoxStyle}>
                            <FormControl fullWidth sx={textField}>
                                <InputLabel size='small' id='demo-simple-select-label'>
                                    {t('department')}
                                </InputLabel>
                                <Select
                                    size='small'
                                    labelId='demo-simple-select-label'
                                    name='departmentId'
                                    id='slt_s3ec_department'
                                    label={t('department')}
                                    value={employeeData.departmentId}
                                    onChange={onChange}
                                >
                                    {employeeDepartments?.map((item) => (
                                        <MenuItem key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className='form-field'>
                        <Box className='requiredData' sx={smallBoxStyle}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    allowSameDateSelection
                                    name='startDate'
                                    label={t('start_date')}
                                    id='dtp_s3ec_startDate'
                                    inputFormat='dd/MM/yyyy'
                                    onError={(error) => setIsValidStartDate(error === null)}
                                    value={employeeData.startDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            name='startDate'
                                            size='small'
                                            sx={textField}
                                            fullWidth
                                            required
                                            {...params}
                                            helperText={
                                                emptyFields && employeeData.startDate === null
                                                    ? t('please_select_date')
                                                    : !isValidStartDate
                                                      ? t('date_invalid')
                                                      : ''
                                            }
                                            error={
                                                (emptyFields && employeeData.startDate === null) || !isValidStartDate
                                            }
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box className={!isValidEndDate ? 'requiredData' : ''} sx={smallBoxStyle}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    allowSameDateSelection
                                    name='endDate'
                                    disabled={employeeData.startDate === null}
                                    label={t('end_date')}
                                    inputFormat='dd/MM/yyyy'
                                    id='dtp_s3ec_endDate'
                                    onError={(error) => setIsValidEndDate(error === null)}
                                    minDate={employeeData.startDate}
                                    value={employeeData.endDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            name='endDate'
                                            size='small'
                                            sx={textField}
                                            fullWidth
                                            error={!isValidEndDate}
                                            helperText={
                                                !isValidEndDate
                                                    ? t('date_invalid')
                                                    : t(
                                                          'please_leave_this_field_empty_if_the_contract_duration_is_indefinite',
                                                      )
                                            }
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step3EmployeeContract;
