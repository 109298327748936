import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../../assets/images/ic-cancel.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { GetDeviceLogPageable } from '../../../../reduxToolkit/Devices/DevicesApi';
import TablePagination from '@mui/material/TablePagination';
import { logMessage } from '../../../../enums/logMessage';

const DeviceLogModal = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { getDeviceLogPageable } = useSelector((state) => state.DevicesSlice);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const uuid = props?.data?.id;
    const flag = props.flag;

    // This section calls the API to obtain the respective logs
    useEffect(() => {
        if (flag) {
            const data = {
                id: uuid,
                body: {
                    page: page,
                    size: rowsPerPage,
                },
            };
            dispatch(GetDeviceLogPageable(data));
        } else {
            setPage(0);
            setRowsPerPage(20);
        }
    }, [dispatch, flag, page, rowsPerPage, uuid]);

    const getMessageDescription = (item) => {
        let actionDescription = '';

        const parts = item.message.split(',');
        const messageCode = parts[0].trim();
        const firstDetail = parts[1]?.trim() || '';
        const secondDetail = parts[2]?.trim() || '';

        switch (messageCode) {
            case '40':
                actionDescription = `${t('new_IP')} ${firstDetail} ${t('or_MAC')} ${secondDetail} ${t(
                    'has_changed_verify',
                )}`;
                break;
            case '50':
                actionDescription = `${t('user_with_id:')} ${firstDetail} ${t('try_access')}`;
                break;
            case '51':
                actionDescription = `${t('user_with_id:')} ${firstDetail} ${t('access_waiting')}`;
                break;
            case '60':
                actionDescription = `${t('id:')} ${firstDetail} ${t('access_method_invalid')} ${secondDetail}`;
                break;
            case '101':
                actionDescription = `${t('FAR_ERROR_CONVERTING_SELFIE')} ${firstDetail}`;
                break;
            case '102':
                actionDescription = `${t('FAR_ERROR_CONVERTING_SELFIE_TO_IMAGE')} ${firstDetail}`;
                break;
            case '103':
                actionDescription = `${t('FAR_ERROR_CONVERTING_SELFIE_TO_BMP')} ${firstDetail}`;
                break;
            case '1000':
                actionDescription = `${t('error_syncing_device')}`;
                break;
            case '1001':
                actionDescription = `${t('device_synced_successfully')}`;
                break;
            default:
                if (logMessage[messageCode]) {
                    actionDescription = t(logMessage[messageCode]);
                } else {
                    actionDescription = 'Message not found';
                }
                break;
        }

        return actionDescription;
    };

    return (
        <Modal className='log-modal' {...props} size='xl' aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('device_log')}</Modal.Title>
                <img
                    onClick={() => {
                        props.onHide();
                        props.onLog();
                    }}
                    className='modalClose'
                    src={cancel}
                    alt=''
                />
            </Modal.Header>
            <Modal.Body className='log-modal-body'>
                {getDeviceLogPageable?.content?.length > 0 &&
                    getDeviceLogPageable?.content?.map((item) => (
                        <li key={item.id}>
                            <span className='log-item-text'>
                                {item.action}, {getMessageDescription(item)}
                            </span>
                            <span className='log-item-date' style={{ float: 'right' }}>
                                {new Date(item.createdAt).toLocaleString('es-AR')}
                            </span>
                        </li>
                    ))}
            </Modal.Body>
            <Modal.Footer>
                {getDeviceLogPageable?.content?.length > 0 && (
                    <div className='d-flex justify-content-center'>
                        <TablePagination
                            component='div'
                            rowsPerPageOptions={[20, 40, 60]}
                            count={getDeviceLogPageable?.totalElements}
                            page={page}
                            onPageChange={handleChangePage}
                            labelRowsPerPage={t('logs_per_page')}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default DeviceLogModal;
