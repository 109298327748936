export const ContractorEmployee = {
    contractorEmployeeStatusId: '',
    contractorId: null,
    deviceId: null,
    dob: null,
    email: '',
    firebaseId: null,
    genderId: '',
    id: null,
    lastName: '',
    name: '',
    password: null,
    phoneNumber: '',
    secondLastName: '',
    selfie: null,
    statusId: '',
    userId: null,
    documentId: '',

    toCreateUpdate: function () {
        return {
            contractorEmployeeStatusId: this.contractorEmployeeStatusId !== '' ? this.contractorEmployeeStatusId : null,
            contractorId: sessionStorage.getItem('contractorId'),
            dob: this.dob === null ? null : typeof this.dob === 'number' ? this.dob : this.dob.getTime(),
            email: this.email,
            genderId: this.genderId !== '' ? this.genderId : null,
            id: this.id,
            lastName: this.lastName,
            name: this.name,
            phoneNumber: this.phoneNumber,
            secondLastName: this.secondLastName !== '' ? this.secondLastName : null,
            statusId: this.statusId !== '' ? this.statusId : null,
            selfie: this.selfie,
            userId: this.userId,
            documentId: this.documentId,
        };
    },
};
