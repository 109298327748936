import React, { useRef, useState, useEffect } from 'react';
import { Group, Text, Transformer, Rect } from 'react-konva';
import { Html } from 'react-konva-utils';

const TextComponent = ({
    text,
    index,
    selectedShapeId,
    handleDragMove,
    handleShapeClick,
    currentStage,
    setStageProperties,
    setShapeHeight,
    setShapeWidth,
}) => {
    const shapeRef = useRef(null);
    const trRef = useRef(null);
    const inputRef = useRef(null);
    const [isEditing, setEditing] = useState(false);
    const [editedText, setEditedText] = useState(text.content);
    const [lastText, setLastText] = useState(text.content);
    const MAX_WIDTH = 305;
    const MAX_HEIGHT = 462;

    useEffect(() => {
        if (selectedShapeId === text.id) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedShapeId, text.id]);

    const handleDoubleClick = () => {
        if (text.allowEdit) {
            setEditing(true);
            setLastText(shapeRef.current);
        }
    };

    const handleTextChange = (e) => {
        setEditedText(e.target.value);
    };

    const handleTextBlur = () => {
        setEditing(false);
        updateText();
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleTextBlur(); // Enter key
        } else if (e.keyCode === 27) {
            setEditedText(lastText);
            setEditing(false); // Escape key
        }
    };

    const updateText = () => {
        setStageProperties((prevProperties) => {
            const currentProperties = prevProperties[currentStage];
            const updatedTexts = [...currentProperties.combinedObject];
            updatedTexts[index] = {
                ...updatedTexts[index],
                content: editedText,
            };

            return {
                ...prevProperties,
                [currentStage]: {
                    ...currentProperties,
                    combinedObject: updatedTexts,
                },
            };
        });
    };

    const handleTextFocus = () => {
        const input = inputRef.current;
        input.setSelectionRange(0, input.value.length);
    };

    return (
        <Group key={text.id}>
            {!isEditing && (
                <Text
                    x={Math.round(text.x)}
                    y={Math.round(text.y)}
                    text={editedText}
                    fontSize={text.fontSize}
                    fontFamily={text.fontFamily}
                    fontStyle={text.fontStyle}
                    textDecoration={text.textDecoration}
                    fill={text.fill}
                    align={text.align}
                    width={Math.min(text.width, MAX_WIDTH)}
                    height={Math.min(text.height, MAX_HEIGHT)}
                    wrap='word'
                    id={text.id}
                    ref={shapeRef}
                    draggable={!isEditing}
                    editable={isEditing}
                    onDragMove={(e) => handleDragMove(e, index)}
                    onClick={() => handleShapeClick(text.id, 'texts')}
                    onDblClick={handleDoubleClick}
                    onTap={() => handleShapeClick(text.id, 'texts')}
                    onTransformEnd={(e) => {
                        const node = shapeRef.current;

                        const newWidth = Math.min(Math.round(node.width() * node.scaleX()), MAX_WIDTH);
                        const newHeight = Math.min(Math.round(node.height() * node.scaleY()), MAX_HEIGHT);

                        node.width(newWidth);
                        node.height(newHeight);
                        node.scaleX(1);
                        node.scaleY(1);

                        const newX = Math.round(node.x());
                        const newY = Math.round(node.y());

                        setShapeHeight(newHeight);
                        setShapeWidth(newWidth);

                        setStageProperties((prevProperties) => {
                            const currentProperties = prevProperties[currentStage];
                            const updatedTexts = [...currentProperties.combinedObject];

                            updatedTexts[index] = {
                                ...updatedTexts[index],
                                width: newWidth,
                                height: newHeight,
                                x: newX,
                                y: newY,
                                rotation: node.rotation(),
                            };

                            return {
                                ...prevProperties,
                                [currentStage]: {
                                    ...currentProperties,
                                    combinedObject: updatedTexts,
                                },
                            };
                        });

                        setEditing(false);
                    }}
                />
            )}

            {text.id === selectedShapeId && <Transformer ref={trRef} centeredScaling={true} rotateEnabled={true} />}
            {isEditing && shapeRef.current && (
                <Rect
                    width={shapeRef.current.width() ?? 16}
                    height={shapeRef.current.height() ?? 16}
                    fill='transparent'
                    onMouseDown={(e) => e.evt.preventDefault()}
                />
            )}

            {isEditing && (
                <Html
                    divProps={{
                        style: {
                            position: 'absolute',
                            top: 10,
                            left: 10,
                        },
                    }}
                >
                    <input
                        ref={inputRef}
                        type='text'
                        value={editedText}
                        onChange={handleTextChange}
                        onBlur={handleTextBlur}
                        onFocus={handleTextFocus}
                        onKeyDown={handleKeyDown}
                        style={{
                            position: 'absolute',
                            top: text.y,
                            left: text.x,
                            fontSize: text.fontSize,
                            color: text.fill,
                            width: shapeRef.current ? shapeRef.current.width() : 'auto',
                            height: shapeRef.current ? shapeRef.current.height() : 'auto',
                            border: 'none',
                            padding: 0,
                            margin: 0,
                            outline: 'none',
                            backgroundColor: 'transparent',
                        }}
                    />
                </Html>
            )}
            {isEditing && (
                <Transformer
                    ref={trRef}
                    centeredScaling={true}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width > MAX_WIDTH || newBox.height > MAX_HEIGHT) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Group>
    );
};

export default TextComponent;
