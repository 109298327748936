import React, { useState, useEffect } from 'react';
import { ReactComponent as Green_event } from '../../../assets/images/green-event.svg';
import { ReactComponent as Green_employee } from '../../../assets/images/green-employee.svg';
import { ReactComponent as Green_company } from '../../../assets/images/green-company.svg';
import { ReactComponent as Green_vehicle } from '../../../assets/images/green-vehicle.svg';
import { ReactComponent as Green_contractor } from '../../../assets/images/green-contractor.svg';
import { ReactComponent as Green_provider } from '../../../assets/images/green-provider.svg';
import { ReactComponent as WebPack } from '../../../assets/images/webPack.svg';
import { ReactComponent as Payroll_green } from '../../../assets/images/payroll_green.svg';
import { ReactComponent as Green_department } from '../../../assets/green_department.svg';
import { useNavigate } from 'react-router-dom';

// icons
import { ReactComponent as FirstAccessIcon } from '../../../assets/menuIcon/first_access_white.svg';
import { ReactComponent as WorkShiftIcon } from '../../../assets/menuIcon/work_shift.svg';
import { ReactComponent as AccessIcon } from '../../../assets/menuIcon/access.svg';
import { ReactComponent as PrevilageIcon } from '../../../assets/menuIcon/privilage_settings.svg';
import { ReactComponent as OnboardingIcon } from '../../../assets/menuIcon/onboarding.svg';
import { ReactComponent as UserDocumentIcon } from '../../../assets/menuIcon/user_document_manager.svg';
import { ReactComponent as VehicleDocumentIcon } from '../../../assets/menuIcon/vehicle_document_manager.svg';
import { ReactComponent as EmailSettingIcon } from '../../../assets/menuIcon/email_settings.svg';
import { ReactComponent as CardsIcon } from '../../../assets/menuIcon/cards.svg';
import { ReactComponent as AnnouncementsIcon } from '../../../assets/menuIcon/announcements.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/menuIcon/profile.svg';
import { ReactComponent as DeviceImage } from '../../../assets/menuIcon/device_white.svg';
import { ReactComponent as ControllerImage } from '../../../assets/menuIcon/ic-controller-green.svg';
import { ReactComponent as ControllerImageWhite } from '../../../assets/menuIcon/ic-controller-white.svg';
import { ReactComponent as ChartImage } from '../../../assets/menuIcon/chart-green.svg';
import { ReactComponent as ChartImageWhite } from '../../../assets/menuIcon/chart-white.svg';
import { ReactComponent as SuitcaseIcon } from '../../../assets/menuIcon/ic_suitcase.svg';
import { ReactComponent as HomeofficeIcon } from '../../../assets/menuIcon/ic_homeoffice.svg';
import { ReactComponent as BrushIcon } from '../../../assets/menuIcon/brush-solid.svg';
import { ReactComponent as LogsIcon } from '../../../assets/menuIcon/ic_file_lines_regular.svg';
import { ReactComponent as GuardIcon } from '../../../assets/menuIcon/ic_guard_icon.svg';
import SidebarDropDownOption from '../../../components/SidebarDropDownOption';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { permissionObj } from '../../../Helpers/permission';
import { ClearGetListZoneMap } from '../../../reduxToolkit/EmployeeZones/EmployeeZoneSlice';
import { sidebarEmployeeOptionsEnum } from '../../../enums/sidebarEmployeeOptionsEnum';
import { Tooltip } from '@mui/material';

const EmployeeTabs = ({ isMenuOpen }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const navigate = useNavigate();
    const MenuIcon = (props) => (
        <svg xmlns='http://www.w3.org/2000/svg' fill={props.fill} className={props.class}></svg>
    );

    //Get the current url to choose the respectively module in the sideBar
    const currentUrl = window.location.pathname;
    const parts = currentUrl.split('/');
    const modulePart = parts[3] ? parts[3] + (parts[4] ? '/' + parts[4] : '') : '';

    const [changeStyle, setChangeStyle] = useState(sidebarEmployeeOptionsEnum[modulePart]);
    const [isHovering, setIsHovering] = useState('1');
    const { user, permission } = useSelector((state) => state.authenticatioauthennSlice);
    const [isMenuOpenController, setIsMenuOpenController] = useState(false);
    useEffect(() => {
        setChangeStyle(sidebarEmployeeOptionsEnum[modulePart]);
    }, [navigate]);

    const limitCharacter = 20;

    const menuItems = [
        { name: 'controller', route: '/dashboard/employee/controller', label: 'Controllers' },
        { name: 'readers', route: '/dashboard/employee/readers', label: 'Readers' },
        { name: 'sios', route: '/dashboard/employee/sios', label: 'SIOs' },
        { name: 'inputs', route: '/dashboard/employee/inputs', label: 'Inputs' },
        { name: 'outputs', route: '/dashboard/employee/outputs', label: 'Outputs' },
    ];

    const handleMenuOpen = () => {
        setIsMenuOpenController(!isMenuOpenController);
    };

    const handleSubMenuClick = () => {
        setIsMenuOpenController(false);
    };

    return (
        <ul className={`${isMenuOpen ? 'largeMenu' : 'shortMenu'}`}>
            {user?.data?.status?.id !== 3 && (
                <>
                    {permission?.includes(permissionObj?.WEB_COMPANY_READ) && (
                        <Tooltip
                            title={!isMenuOpen ? t('company_profile')?.toUpperCase() : ''}
                            placement='right'
                            disableInteractive
                        >
                            <Link to='/dashboard/employee/company/update-data'>
                                <li
                                    onClick={() => {
                                        setChangeStyle('company');
                                        sessionStorage.setItem('sidebarOption', 'company');
                                    }}
                                    className={changeStyle === 'company' ? 'activeLi' : isHovering === 1 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(1)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <Green_company className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('company_profile')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_ZONE_READ) && (
                        <Tooltip
                            title={!isMenuOpen ? t('first_access')?.toUpperCase() : ''}
                            placement='right'
                            disableInteractive
                        >
                            <Link to='/dashboard/employee/zones' onClick={() => dispatch(ClearGetListZoneMap())}>
                                <li
                                    onClick={() => {
                                        setChangeStyle('zones');
                                        sessionStorage.setItem('sidebarOption', 'zones');
                                    }}
                                    className={changeStyle === 'zones' ? 'activeLi' : isHovering === 2 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(2)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <FirstAccessIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('first_access')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_DEVICE_READ) && (
                        <Tooltip title={!isMenuOpen ? t('devices')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/devices'>
                                <li
                                    onClick={() => setChangeStyle('devices')}
                                    className={changeStyle === 'devices' ? 'activeLi' : isHovering === 22 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(22)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <DeviceImage className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('devices')?.slice(0, limitCharacter)}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_HID_READ) && (
                        <>
                            <Tooltip title={!isMenuOpen ? t('controller')?.toUpperCase() : ''} placement='right'>
                                <div onClick={handleMenuOpen}>
                                    <li
                                        onClick={() => setChangeStyle('controller')}
                                        className={
                                            ['controller', 'sios', 'readers', 'inputs'].includes(changeStyle)
                                                ? 'activeLi'
                                                : isHovering === 23
                                                  ? ''
                                                  : ''
                                        }
                                        onMouseEnter={() => setIsHovering(23)}
                                        onMouseLeave={() => setIsHovering(0)}
                                    >
                                        <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                            <div>
                                                {isHovering === 23 ? (
                                                    <ControllerImage className='svgHover' />
                                                ) : (
                                                    <ControllerImageWhite />
                                                )}
                                            </div>
                                            <span
                                                className={` ${
                                                    isMenuOpen ? 'profile-span' : 'profile-span hiddenText'
                                                }`}
                                            >
                                                {t('controller')?.slice(0, limitCharacter)}
                                            </span>
                                        </div>
                                    </li>
                                </div>
                            </Tooltip>

                            {isMenuOpenController && (
                                <ul className='subMenu'>
                                    {menuItems.map((item, index) => (
                                        <Tooltip key={index} title={t(item.label)} placement='right'>
                                            <Link to={item.route} onClick={handleSubMenuClick}>
                                                <li className='subMenuItem'>
                                                    <span>{t(item.label)}</span>
                                                </li>
                                            </Link>
                                        </Tooltip>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}

                    {permission?.includes(permissionObj.WEB_ATTENDANCE_READ) && (
                        <Tooltip title={!isMenuOpen ? t('graphs')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/graphs'>
                                <li
                                    onClick={() => setChangeStyle('graphs')}
                                    className={changeStyle === 'graphs' ? 'activeLi' : ''}
                                    onMouseEnter={() => setIsHovering(30)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            {isHovering === 30 || changeStyle === 'graphs' ? (
                                                <ChartImage className='svgHover' />
                                            ) : (
                                                <ChartImageWhite />
                                            )}
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('graphs')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}

                    {permission?.includes(permissionObj.WEB_ATTENDANCE_READ) && (
                        <Tooltip title={!isMenuOpen ? t('attendance')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/attendance'>
                                <li
                                    onClick={() => setChangeStyle('payroll')}
                                    className={changeStyle === 'payroll' ? 'activeLi' : isHovering === 8 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(8)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <Payroll_green className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('attendance')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_DEPARTMENT_READ) && (
                        <Tooltip title={!isMenuOpen ? t('departments')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/departments'>
                                <li
                                    onClick={() => setChangeStyle('departments')}
                                    className={changeStyle === 'departments' ? 'activeLi' : isHovering === 11 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(11)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <Green_department className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('departments')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_EMPLOYEE_READ) && (
                        <Tooltip title={!isMenuOpen ? t('employees')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/all-employees'>
                                <li
                                    onClick={() => {
                                        setChangeStyle('employees');
                                        sessionStorage.setItem('sidebarOption', 'employees');
                                    }}
                                    className={changeStyle === 'employees' ? 'activeLi' : isHovering === 4 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(4)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <Green_employee className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('employees')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_VEHICLE_READ) && (
                        <Tooltip title={!isMenuOpen ? t('vehicle_fleet')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/vehicle-fleet'>
                                <li
                                    onClick={() => {
                                        setChangeStyle('vehicles');
                                        sessionStorage.setItem('sidebarOption', 'vehicles');
                                    }}
                                    className={changeStyle === 'vehicles' ? 'activeLi' : isHovering === 5 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(5)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <Green_vehicle className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('vehicle_fleet')?.slice(0, limitCharacter)}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_EVENT_READ) && (
                        <Tooltip title={!isMenuOpen ? t('events')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/events'>
                                <li
                                    onClick={() => {
                                        setChangeStyle('events');
                                        sessionStorage.setItem('sidebarOption', 'events');
                                    }}
                                    className={changeStyle === 'events' ? 'activeLi' : isHovering === 3 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(3)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <Green_event className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('events')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_WORK_SHIFT_READ) && (
                        <Tooltip title={!isMenuOpen ? t('work_shift_panel')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/company/workshift-panel'>
                                <li
                                    onClick={() => setChangeStyle('workshift')}
                                    className={changeStyle === 'workshift' ? 'activeLi' : isHovering === 13 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(13)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <WorkShiftIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('work_shift_panel')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {(permission?.includes(permissionObj?.WEB_ACCESS_USER_READ) ||
                        permission?.includes(permissionObj?.WEB_ACCESS_VEHICLE_READ)) && (
                        <Tooltip title={!isMenuOpen ? t('access')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/access'>
                                <li
                                    onClick={() => setChangeStyle('access')}
                                    className={changeStyle === 'access' ? 'activeLi' : isHovering === 14 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(14)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <AccessIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('access')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_PRIVILEGE_READ) && (
                        <Tooltip title={!isMenuOpen ? t('privilage_settings')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/company/roles-panel'>
                                <li
                                    onClick={() => setChangeStyle('previlageSetting')}
                                    className={
                                        changeStyle === 'previlageSetting' ? 'activeLi' : isHovering === 15 ? '' : ''
                                    }
                                    onMouseEnter={() => setIsHovering(15)}
                                    onMouseLeave={() => setIsHovering(0)}
                                    style={{ paddingRight: isMenuOpen && '1px' }}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <PrevilageIcon className='svgHover' />
                                        </div>

                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('privilage_settings')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_ONBOARDING_READ) && (
                        <Tooltip title={!isMenuOpen ? t('on_boarding')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/company/onboarding'>
                                <li
                                    onClick={() => setChangeStyle('onBoarding')}
                                    className={changeStyle === 'onBoarding' ? 'activeLi' : isHovering === 16 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(16)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <OnboardingIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('on_boarding')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {(permission?.includes(permissionObj.WEB_CONTRACTOR_READ) ||
                        permission?.includes(permissionObj.WEB_CONTRACTOR_CONTRACT_READ)) && (
                        <Tooltip title={!isMenuOpen ? t('contractors')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/contractors'>
                                <li
                                    onClick={() => {
                                        setChangeStyle('contractor');
                                        sessionStorage.setItem('sidebarOption', 'contractor');
                                    }}
                                    className={changeStyle === 'contractor' ? 'activeLi' : isHovering === 6 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(6)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <Green_contractor className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('contractors')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {(permission?.includes(permissionObj.WEB_SUPPLIER_READ) ||
                        permission?.includes(permissionObj.WEB_SUPPLIER_ORDER_READ)) && (
                        <Tooltip title={!isMenuOpen ? t('supplier')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/suppliers'>
                                <li
                                    onClick={() => {
                                        setChangeStyle('provider');
                                        sessionStorage.setItem('sidebarOption', 'provider');
                                    }}
                                    className={changeStyle === 'provider' ? 'activeLi' : isHovering === 7 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(7)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <Green_provider className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('supplier')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {(permission?.includes(permissionObj.WEB_SUPPLIER_DOCUMENT_READ) ||
                        permission?.includes(permissionObj.WEB_EMPLOYEE_DOCUMENT_READ) ||
                        permission?.includes(permissionObj.WEB_CONTRACTOR_DOCUMENT_READ)) && (
                        <Tooltip title={t('user_document_manager')?.toUpperCase()} placement='right'>
                            <Link to='/dashboard/employee/company/user-doc-panel'>
                                <li
                                    onClick={() => setChangeStyle('userDocument')}
                                    className={
                                        changeStyle === 'userDocument' ? 'activeLi' : isHovering === 17 ? '' : ''
                                    }
                                    onMouseEnter={() => setIsHovering(17)}
                                    onMouseLeave={() => setIsHovering(0)}
                                    style={{ paddingRight: isMenuOpen && '1px' }}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <UserDocumentIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('user_document_manager')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {(permission?.includes(permissionObj.WEB_SUPPLIER_VEHICLE_DOCUMENT_READ) ||
                        permission?.includes(permissionObj.WEB_CONTRACTOR_VEHICLE_DOCUMENT_READ)) && (
                        <Tooltip title={t('vehicle_document_manager')?.toUpperCase()} placement='right'>
                            <Link to='/dashboard/employee/company/vehicle-doc-panel'>
                                <li
                                    onClick={() => setChangeStyle('vehicleDocument')}
                                    className={
                                        changeStyle === 'vehicleDocument' ? 'activeLi' : isHovering === 18 ? '' : ''
                                    }
                                    onMouseEnter={() => setIsHovering(18)}
                                    onMouseLeave={() => setIsHovering(0)}
                                    style={{ paddingRight: isMenuOpen && '1px' }}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <VehicleDocumentIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('vehicle_document_manager')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj?.WEB_EMAIL_SETTINGS_READ) && (
                        <Tooltip title={t('email_service_settings')?.toUpperCase()} placement='right'>
                            <Link to='/dashboard/employee/attendance/email-setting'>
                                <li
                                    onClick={() => setChangeStyle('emailSetting')}
                                    className={
                                        changeStyle === 'emailSetting' ? 'activeLi' : isHovering === 19 ? '' : ''
                                    }
                                    onMouseEnter={() => setIsHovering(19)}
                                    onMouseLeave={() => setIsHovering(0)}
                                    style={{ paddingRight: isMenuOpen && '1px' }}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <EmailSettingIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('email_service_settings')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_BACK_UP_READ) && (
                        <Tooltip title={!isMenuOpen ? t('back_up')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/backup'>
                                <li
                                    onClick={() => setChangeStyle('backup')}
                                    className={changeStyle === 'backup' ? 'activeLi' : isHovering === 10 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(10)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <WebPack className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('back_up')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {/* disabled for second stage */}
                    {permission?.includes(permissionObj.WEB_HOME_OFFICE_READ) && (
                        <Tooltip title={!isMenuOpen ? t('home_office')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/work-office'>
                                <li
                                    onClick={() => setChangeStyle('homeoffice')}
                                    className={changeStyle === 'homeoffice' ? 'activeLi' : isHovering === 24 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(24)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <HomeofficeIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('home_office')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_CARD_DESIGNER_READ) && (
                        <Tooltip title={!isMenuOpen ? t('card_designer')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/card-designer'>
                                <li
                                    onClick={() => setChangeStyle('card_designer')}
                                    className={
                                        changeStyle === 'card_designer' ? 'activeLi' : isHovering === 25 ? '' : ''
                                    }
                                    onMouseEnter={() => setIsHovering(25)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <BrushIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('card_designer')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_LOG_PLATFORM_READ) && (
                        <Tooltip title={!isMenuOpen ? t('logs')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/logs'>
                                <li
                                    onClick={() => setChangeStyle('logs')}
                                    className={changeStyle === 'logs' ? 'activeLi' : isHovering === 26 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(26)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <LogsIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('logs')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_GUARD_READ) && (
                        <Tooltip title={!isMenuOpen ? t('guard')?.toUpperCase() : ''} placement='right'>
                            <Link to='/dashboard/employee/guard'>
                                <li
                                    onClick={() => setChangeStyle('guard')}
                                    className={changeStyle === 'guard' ? 'activeLi' : isHovering === 27 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(27)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <GuardIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('guard')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_ANNOUNCEMENT_READ) && (
                        <Tooltip
                            title={!isMenuOpen ? t('announcements').toUpperCase() : ''}
                            placement='right'
                            disableInteractive
                        >
                            <Link to='/dashboard/employee/announcement-panel'>
                                <li
                                    onClick={() => setChangeStyle('announcements')}
                                    className={
                                        changeStyle === 'announcements' ? 'activeLi' : isHovering === 28 ? '' : ''
                                    }
                                    onMouseEnter={() => setIsHovering(28)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <AnnouncementsIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('announcements')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                    {permission?.includes(permissionObj.WEB_CARD_READ) && (
                        <Tooltip
                            title={!isMenuOpen ? t('card_management').toUpperCase() : ''}
                            placement='right'
                            disableInteractive
                        >
                            <Link to='/dashboard/employee/cards'>
                                <li
                                    onClick={() => setChangeStyle('cards')}
                                    className={changeStyle === 'cards' ? 'activeLi' : isHovering === 29 ? '' : ''}
                                    onMouseEnter={() => setIsHovering(29)}
                                    onMouseLeave={() => setIsHovering(0)}
                                >
                                    <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                        <div>
                                            <CardsIcon className='svgHover' />
                                        </div>
                                        <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                            {t('card_management')}
                                        </span>
                                    </div>
                                </li>
                            </Link>
                        </Tooltip>
                    )}
                </>
            )}
            {permission?.includes(permissionObj.WEB_PROFILE_READ) && (
                <Tooltip title={!isMenuOpen ? t('profile')?.toUpperCase() : ''} placement='right'>
                    <Link to='/dashboard/employee/profile'>
                        <li
                            onClick={() => setChangeStyle('profile')}
                            className={changeStyle === 'profile' ? 'activeLi' : isHovering === 21 ? '' : ''}
                            onMouseEnter={() => setIsHovering(21)}
                            onMouseLeave={() => setIsHovering(0)}
                        >
                            <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                <div>
                                    <ProfileIcon className='svgHover' />
                                </div>
                                <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                    {t('profile')?.slice(0, limitCharacter)}
                                </span>
                            </div>
                        </li>
                    </Link>
                </Tooltip>
            )}

            <Link to='#'>
                <li className={`${isMenuOpen ? 'profile_li' : 'profile_li_col'}`}>
                    <SidebarDropDownOption
                        changestyle={changeStyle}
                        hovereffect={isHovering}
                        isMenuOpen={isMenuOpen}
                        menutop={false}
                    />
                </li>
            </Link>
        </ul>
    );
};

export default EmployeeTabs;
