import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, Box, Typography, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './DocumentValidation.scss';
import { useTranslation } from 'react-i18next';
import background from '../../../assets/images/mountain.png';
import logo from '../../../assets/logo/logo 3.png';
import {
    GetAllDocumentFormats,
    GetUserRestrictionVisitor,
    GetValidationId,
    GetValidateIdentifyById,
} from '../../../reduxToolkit/Visitor/VisitorApi';
import { useNavigate } from 'react-router-dom';
import { setSelectedDocumentType } from '../../../reduxToolkit/Visitor/VisitorSlice';

const DocumentSelector = ({ id }) => {
    const { t } = useTranslation();
    const [documentType, setDocumentType] = useState('');
    const [isDocumentSelected, setIsDocumentSelected] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const documentTypes = useSelector((state) => state.VisitorSlice.getAllDocumentFormats);
    const selectedDocumentType = useSelector((state) => state.VisitorSlice.selectedDocumentType);
    const userRestrictionData = useSelector((state) => state.VisitorSlice.getUserRestrictionVisitor);
    const userValidatedId = useSelector((state) => state.VisitorSlice.getValidateIdentifyById);
    const idAssociatedToTheProcess = useSelector((state) => state.VisitorSlice.getValidationId);
    useEffect(() => {
        const fetchTypes = async () => {
            try {
                dispatch(GetAllDocumentFormats('MX'));
                dispatch(GetUserRestrictionVisitor());
                dispatch(GetValidationId());
            } catch (error) {
                console.error('Error fetching document types:', error);
            }
        };

        fetchTypes();
    }, [dispatch]);

    const handleChange = (event) => {
        const selectedType = event.target.value;
        setDocumentType(selectedType);
        dispatch(setSelectedDocumentType(selectedType));
        setIsDocumentSelected(true);
    };

    const documentTypeText = (type) => {
        switch (type) {
            case 'MX_IDCard_2019':
                return 'INE';
            case 'XX_Passport_YYYY':
                return 'Pasaporte';
            default:
                return type;
        }
    };

    return (
        <Box className='background-document-select'>
            <Box className='background-document-select__modal'>
                <Grid container direction='column' alignItems='center'>
                    <img
                        src={logo}
                        alt='Identity Validated'
                        className='responsive-image background-document-select__image'
                    />

                    <Grid item>
                        <Typography
                            className='document-validation background-document-select__validation'
                            variant='h6'
                            gutterBottom
                        >
                            {t('identity_validation_title')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            className='document-process background-document-select__process'
                            variant='body1'
                            sx={{ textAlign: 'center' }}
                            component='body1'
                        >
                            {t('identity_validation_process')}
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        className='instruction-container background-document-select__instructions'
                        direction='column'
                        alignItems='left'
                    >
                        <Grid item>
                            <Box>
                                <Grid item>
                                    <Typography
                                        className='document-instruction background-document-select__instruction'
                                        variant='body1'
                                        component='body1'
                                        gutterBottom
                                    >
                                        {t('select_document')}
                                    </Typography>
                                </Grid>
                                <div
                                    className='form-control-container background-document-select__form-control'
                                    style={{ marginTop: '15px', marginBottom: '10px' }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>Documento*</InputLabel>
                                        <Select
                                            labelId='demo-simple-select-label'
                                            id='slt_dv_documentType'
                                            value={documentType}
                                            label='Documento'
                                            size='small'
                                            onChange={handleChange}
                                        >
                                            {documentTypes.map((type, index) => (
                                                <MenuItem key={index} value={type}>
                                                    {documentTypeText(type)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography
                                className='document-instruction background-document-select__instruction'
                                variant='body1'
                                component='body1'
                                gutterBottom
                            >
                                {t('capture_front_side')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className='document-instruction background-document-select__instruction'
                                variant='body1'
                                component='body1'
                                gutterBottom
                            >
                                {t('capture_back_side')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className='document-instruction background-document-select__instruction'
                                variant='body1'
                                component='body1'
                            >
                                {t('capture_selfie')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className='document-instruction background-document-select__instruction'
                                variant='body1'
                                component='body1'
                                gutterBottom
                            >
                                {t('validate_data')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className='button-container background-document-select__button-container'
                        style={{ marginTop: '10px' }}
                    >
                        <Link to={`/document-validation?documentType=${documentType}`}>
                            <Button
                                fullWidth
                                className='document-button-continue background-document-select__button'
                                variant='contained'
                                color='primary'
                                disabled={!isDocumentSelected}
                                /* onClick={handleContinue} */
                            >
                                {t('accept_and_continue')}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default DocumentSelector;
