import { Box, Checkbox, Divider, FormControlLabel, Grid, Stack, TablePagination } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import noData from '../../../../assets/images/warning.svg';

import { permissionObj } from '../../../../Helpers/permission';
import {
    GetAllOnBoardingProcess,
    GetOnBoarding,
} from '../../../../reduxToolkit/EmployeeOnBoarding/EmployeeOnBoardingApi';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../../../Modals/DeleteModal';
import { DeleteItemsApi } from '../../../../reduxToolkit/Commons/CommonsApi';
import NotInformation from '../../../../components/NotInformation';
/*
Author : Arman Ali
Module: Zone
github: https://github.com/Arman-Arzoo
*/

// On Boarding  module main funtion

const OnBoarding = () => {
    // hook
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //use State hook for local state management

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(12);
    const [topHeaderHeight, setTopHeaderHeight] = useState('');
    const headerSectionHeight = useRef(null);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectBordingForDelete, setSelectBordingForDelete] = useState([]);
    const [showOnBoaring, setShowOnBoarding] = useState(false);

    //use Selector hook to get state for redux store
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { getOnBoarding } = useSelector((state) => state.employeeOnBoardingSlice);
    const { deleteItemsApi } = useSelector((state) => state.CommonsSlice);

    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    // custom Funtion
    const deleteOnBoarding = (deleteItem) => {
        const tableName = 'onboarding';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body }));
        setSelectBordingForDelete([]);
        setIsAllChecked(false);
    };

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            // logic to filter the root and none
            const selectAllIds = getOnBoarding?.content
                ?.filter((item) => {
                    return item?.id != null;
                })
                .map((item) => item?.id);

            setSelectBordingForDelete(selectAllIds);
        } else {
            setSelectBordingForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e, data) => {
        if (e.target.checked) {
            setSelectBordingForDelete([...selectBordingForDelete, data.id]); // Almacena solo el id o el objeto completo según necesites
        } else {
            setSelectBordingForDelete(selectBordingForDelete.filter((id) => id !== data.id)); // Asegúrate de filtrar por el mismo criterio
        }
    };

    // a funtion to control zone page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // a funtion to control row per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    // useEffect
    useEffect(() => {
        const pagination = {
            order: true,
            page: 0,
            size: rowsPerPage,
            sortBy: 'id',
        };
        dispatch(GetOnBoarding(pagination));
    }, [page, rowsPerPage, deleteItemsApi]);

    useEffect(() => {
        if (headerSectionHeight.current) {
            const height = headerSectionHeight.current.clientHeight;
            setTopHeaderHeight(height);
        }
    }, []);

    //return main content
    return (
        <>
            <Box>
                {/* header */}
                <div className='top_section_wrapper' ref={headerSectionHeight}>
                    <div className='custom_head top_section_wrapper'>
                        <div className='left'>
                            <p>{t('on_boarding')}</p>
                        </div>
                        {permission?.includes(permissionObj?.WEB_ONBOARDING_DELETE) && (
                            <button
                                className='delete-btn-1'
                                disabled={selectBordingForDelete?.length === 0}
                                onClick={() => {
                                    setShowOnBoarding(true);
                                }}
                            >
                                <i class='fa fa-trash-o ' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>
                    <Box>
                        <FormControlLabel
                            className='grid-checkall'
                            sx={{ marginLeft: '0rem' }}
                            control={
                                <Checkbox
                                    label='Label'
                                    checked={selectBordingForDelete?.length > 0}
                                    onChange={handelDeleteAll}
                                    indeterminate={
                                        selectBordingForDelete?.length > 0 &&
                                        getOnBoarding?.content?.filter((item) => item.id != null).length !==
                                            selectBordingForDelete?.length
                                    }
                                    size='small'
                                />
                            }
                            label={t('de_/_select_all')}
                        />
                    </Box>
                </div>

                {/* content */}
                <Box
                    className='scroll_bar_fit_hight_wrapper'
                    sx={{ height: `calc(100vh - ${topHeaderHeight + 70}px )` }}
                >
                    <Box className='on_boarding_container' paddingTop={'0.6rem'}>
                        {getOnBoarding?.content?.length > 0 ? (
                            <Grid container spacing={2} className='grid-container-cards--onBoarding'>
                                {getOnBoarding?.content?.map((data) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <Box className='on_boarding_container_item'>
                                                <section className='header-cards-onboarding'>
                                                    {data?.id != null ? (
                                                        <>
                                                            {permission?.includes(
                                                                permissionObj?.WEB_ONBOARDING_UPDATE,
                                                            ) && (
                                                                <section className='section-updateAndCheckbox--OnBoarding'>
                                                                    <button
                                                                        className='btn'
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `/dashboard/employee/company/onboarding-UE/${data?.id}`,
                                                                            )
                                                                        }
                                                                    >
                                                                        <i
                                                                            className='fa fa-pencil'
                                                                            aria-hidden='true'
                                                                        />
                                                                    </button>
                                                                    <Checkbox
                                                                        className='grid-checkall checkbox checkbox-option-OnBoarding'
                                                                        checked={selectBordingForDelete?.includes(
                                                                            data?.id,
                                                                        )}
                                                                        onChange={(e) => handleCheckboxChange(e, data)}
                                                                    />
                                                                </section>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {permission?.includes(
                                                                permissionObj?.WEB_ONBOARDING_CREATE,
                                                            ) && (
                                                                <button
                                                                    className='btn'
                                                                    onClick={() => {
                                                                        sessionStorage.setItem(
                                                                            'onBoardingRoleId',
                                                                            data?.roleId,
                                                                        );
                                                                        sessionStorage.setItem(
                                                                            'onBoardingRoleName',
                                                                            data?.roleName,
                                                                        );
                                                                        navigate(
                                                                            `/dashboard/employee/company/onboarding-UE/${data?.id}`,
                                                                        );
                                                                    }}
                                                                >
                                                                    <i className='fa fa-plus' aria-hidden='true' />
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    <Box className='on_boarding_container_header'>
                                                        <h4>{t('privilege')}</h4>
                                                        <p>{data?.roleName || '-'} </p>
                                                    </Box>
                                                </section>
                                                <Divider sx={{ marginTop: '0.4rem', marginBottom: '0.4rem' }} />
                                                <Box className='on_boarding_container_content'>
                                                    {/* containe */}
                                                    {data?.id != null ? (
                                                        <Box sx={{ paddingLeft: '1rem' }}>
                                                            <Box className='on_boarding_container_content_item'>
                                                                <h4>{t('manager')}</h4>
                                                                <Box className='list'>
                                                                    <Box className='icon'></Box>
                                                                    <p>{data?.managerName}</p>
                                                                </Box>
                                                            </Box>
                                                            <Box className='on_boarding_container_content_item'>
                                                                <h4>{t('work_station')}</h4>
                                                                <Box className='list'>
                                                                    <Box className='icon'></Box>
                                                                    <p>{data?.zoneName}</p>
                                                                </Box>
                                                            </Box>
                                                            <Box className='on_boarding_container_content_item'>
                                                                <h4>{t('things_to_do')}</h4>
                                                                <Box className='list'>
                                                                    <Box className='icon'></Box>
                                                                    <p>{data?.processNo}</p>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <NotInformation text={t('no_information')} card={false} />
                                                    )}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : (
                            <NotInformation text={t('no_information')} card={true} />
                        )}
                    </Box>
                </Box>

                <div className='d-flex justify-content-center'>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[12, 16, 24, 32]}
                        count={getOnBoarding?.totalElements}
                        page={page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage='On Boardings per page'
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Box>
            <DeleteModal
                show={showOnBoaring}
                onHide={() => setShowOnBoarding(false)}
                onClickFn={() => deleteOnBoarding(selectBordingForDelete)}
                data={selectBordingForDelete}
                title_modal={'on_boarding'}
                element_modal={'boarding'}
                isReset={setSelectBordingForDelete}
                isAllReset={setIsAllChecked}
            />
        </>
    );
};

export default OnBoarding;
