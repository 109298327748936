import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllCompanyVehicles } from '../../../../../Apis/companyVehicle';
import { TablePagination } from '@mui/material';
import { Card } from 'react-bootstrap';
import car from '../../../../../assets/defaultImages/defaultCar.svg';
import angelright_icon from '../../../../../assets/images/angelright.svg';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { status } from '../../../../../enums/statusEnum';
import { GoPrimitiveDot } from 'react-icons/go';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { GetProviderVehicleDetail } from '../../../../../reduxToolkit/Providers/providersApi';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { colors } from '../../../../../enums/colors';
import moment from 'moment';
import { permissionType } from '../../../../../enums/permissionTypeEnum';

const Step9DrivingPermits = ({ searchVehicle, handleCheckboxChange, selectVehicleForDelete, vehicleData }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const { getAllVehicle } = useSelector((state) => state.VehicleSlice);
    const { searchByFilters } = useSelector((state) => state.SearchSlice);

    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));

    return (
        <>
            <div className='row mt-0 ml-0'>
                {vehicleData?.map((item) => (
                    <div className='panel-grid col-12 col-md-6 col-lg-4 col-xl-3 px-0 pr-1 mb-3' key={item?.id}>
                        <div className='card-base-grid mb-3 pb-2 mr-1 h-100'>
                            <div className='top-heading-card pt-3'>
                                <Checkbox
                                    className='grid-checkall checkbox'
                                    checked={selectVehicleForDelete?.includes(item?.id)}
                                    id={item?.id}
                                    onChange={handleCheckboxChange}
                                    size='small'
                                />
                                <div className={'status ' + status[item?.statusId]}>
                                    <p className='text-end'>{t(status[item?.statusId]).toUpperCase()}</p>
                                    <GoPrimitiveDot className='ml-1' />
                                </div>
                            </div>
                            <div className='card-body-grid px-2 pb-2 mt-3'>
                                <div className='img-body'>
                                    <img
                                        style={{ objectFit: 'cover' }}
                                        src={item?.image ? `data:image/png;base64,${item?.image}` : car}
                                    />
                                </div>

                                <div className='card-content-grid'>
                                    <p>{t('brand')}</p>
                                    <span>{item?.brand || '-'}</span>
                                </div>
                                <div className='card-content-grid'>
                                    <p>{t('sub_brand')}</p>
                                    <span>{item?.subBrand || '-'}</span>
                                </div>
                                <div className='card-content-grid'>
                                    <p>{t('model')}</p>
                                    <span>{item?.model || '-'}</span>
                                </div>
                                <div className='card-content-grid'>
                                    <p>{t('color')}</p>
                                    <span>{item?.color || '-'}</span>
                                </div>
                                <div className='card-content-grid'>
                                    <p>{t('plates')}</p>
                                    <span>{item?.plate || '-'}</span>
                                </div>
                                {contractorId === null && supplierId === null && (
                                    <div className='card-content-grid'>
                                        <p>{t('tag')}</p>
                                        <span>{item?.tag || '-'}</span>
                                    </div>
                                )}
                                <div className='card-content-grid'>
                                    <p>{t('permission_type')}</p>
                                    <span>{t(permissionType[item?.vehiclePermissionTypeId]) || '-'}</span>
                                </div>
                                <div className='card-content-grid'>
                                    <p>{t('from')}</p>
                                    <span>
                                        {item?.from === null || item?.from === undefined
                                            ? '-'
                                            : lCode === 'en'
                                              ? moment(item?.from).format('MM/DD/YYYY')
                                              : moment(item?.from).format('DD/MM/YYYY')}
                                    </span>
                                </div>
                                <div className='card-content-grid'>
                                    <p>{t('to')}</p>
                                    <span>
                                        {item?.to === null || item?.to === undefined
                                            ? '-'
                                            : lCode === 'en'
                                              ? moment(item?.to).format('MM/DD/YYYY')
                                              : moment(item?.to).format('DD/MM/YYYY')}
                                    </span>
                                </div>
                            </div>
                            <span className='viewcard-container-link mt-2 d-flex mr-2'>
                                {contractorId !== null && item.statusId === 3 ? (
                                    <Link
                                        to={`/dashboard/contractor/upload-vehicle-documents/${item?.id}`}
                                        onClick={() => {
                                            dispatch(GetProviderVehicleDetail(item?.id));

                                            sessionStorage.setItem('vehicleidfordetail', item?.id);
                                        }}
                                    >
                                        {t('complete_documents')}
                                        <span>
                                            <KeyboardArrowRightIcon
                                                style={{
                                                    transform: lCode === 'ar' ? 'scaleX(-1)' : '',
                                                }}
                                            />
                                        </span>
                                    </Link>
                                ) : contractorId !== null && item.statusId !== 3 ? (
                                    <Link
                                        to={`/dashboard/contractor/vehicle-contract-detail/${item?.id}`}
                                        onClick={() => {
                                            dispatch(GetProviderVehicleDetail(item?.id));

                                            sessionStorage.setItem('vehicleidfordetail', item?.id);
                                        }}
                                    >
                                        {t('details')}
                                        <span>
                                            <KeyboardArrowRightIcon
                                                style={{
                                                    transform: lCode === 'ar' ? 'scaleX(-1)' : '',
                                                }}
                                            />
                                        </span>
                                    </Link>
                                ) : supplierId !== null && item.statusId === 3 ? (
                                    <Link
                                        to='/dashboard/supplier/vehicle-documents'
                                        onClick={() => {
                                            dispatch(GetProviderVehicleDetail(item?.id));

                                            sessionStorage.setItem('vehicleidfordetail', item?.id);
                                        }}
                                    >
                                        {t('complete_documents')}
                                        <span>
                                            <KeyboardArrowRightIcon
                                                style={{
                                                    transform: lCode === 'ar' ? 'scaleX(-1)' : '',
                                                }}
                                            />
                                        </span>
                                    </Link>
                                ) : supplierId !== null && item.statusId !== 3 ? (
                                    <Link
                                        to='/dashboard/supplier/vehicles-details'
                                        onClick={() => {
                                            dispatch(GetProviderVehicleDetail(item?.id));

                                            sessionStorage.setItem('vehicleidfordetail', item?.id);
                                        }}
                                    >
                                        {t('details')}
                                        <span>
                                            <KeyboardArrowRightIcon
                                                style={{
                                                    transform: lCode === 'ar' ? 'scaleX(-1)' : '',
                                                }}
                                            />
                                        </span>
                                    </Link>
                                ) : (
                                    <Link to={`/dashboard/employee/vehicle-fleet/vehicle-detail/${item?.vehicleId}`}>
                                        {t('details')}
                                        <span>
                                            <KeyboardArrowRightIcon
                                                style={{
                                                    transform: lCode === 'ar' ? 'scaleX(-1)' : '',
                                                }}
                                            />
                                        </span>
                                    </Link>
                                )}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Step9DrivingPermits;
