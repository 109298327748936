import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { t } from 'i18next';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ic_birthday from '../../../assets/images/ic-birthday.svg';
import MiniCard from '../../../components/MiniCard';

import ReturnArrow from '../../../components/buttons/ReturnArrow';
import { status } from '../../../enums/statusEnum';
import {
    createNormalEvent,
    createUserInvitation,
    getReservations,
} from '../../../reduxToolkit/EmployeeEvents/EmployeeEventsApi';
import {
    clearAllAreaToReserve,
    clearAllSelectedEmployees,
    saveNormalEventDetail,
    updateSelectedEmployees,
} from '../../../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import GenericModal from '../../Modals/GenericModal';
import Visitors from './CreateOnuEvents/Visitors';
import NotInformation from '../../../components/NotInformation';

const CreateNormalEvent = () => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const detailData = useSelector((state) => state?.EmployeeEventsSlice?.normalEventDetails);
    const userData = useSelector((state) => state?.authenticatioauthennSlice?.user.data);
    const guestData = useSelector((state) => state?.EmployeeEventsSlice?.emailPhoneSearchList);
    const selectedEmployees = useSelector((state) => state?.EmployeeEventsSlice?.selectedEmployees);
    const allEmployees = useSelector((state) => state?.EmployeeEventsSlice?.allEmployees);
    const companyRestrictionsData = useSelector((state) => state?.EmployeeEventsSlice?.companyRestrictionsData);
    const allAreaToReserve = useSelector((state) => state?.EmployeeEventsSlice?.allAreaToReserve);
    const [toggleState, setToggleState] = useState(0);
    const [hostChecked, setHostChecked] = useState(false);
    const [eventDetail, setEventDetail] = useState({
        name: detailData.name,
        date: detailData.date,
        time: detailData.time,
        duration: detailData.duration,
    });
    const [selectedHostEmp, setSelectedHostEmp] = useState(null);
    const [hostObject, setHostObject] = useState({
        name: selectedHostEmp?.name,
        email: selectedHostEmp?.email,
        phoneNo: selectedHostEmp?.phoneNo,
    });
    const [emptyEventName, setEmptyEventName] = useState(false);
    const [eventDate, setEventDate] = useState('');
    const [allFreeReservation, setAllFreeReservation] = useState([]);
    const [hourPairs, setHourPairs] = useState([]);
    const [areaSelected, setAreaSelected] = useState(null);
    const [areaBooking, setAreaBooking] = useState(null);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [scheduleEvent, setScheduleEvent] = useState({});

    useEffect(() => {
        dispatch(clearAllAreaToReserve());
        dispatch(clearAllSelectedEmployees());
    }, []);

    const toggleTab = (index) => {};

    const handleCloseModal = () => {
        setShowScheduleModal(false);
    };

    const handleConfirmSchedule = () => {
        if (selectedCheckboxes.length > 0) {
            const hourStart = hourPairs[selectedCheckboxes[0]][0].hour;
            const hourEnd = hourPairs[selectedCheckboxes[selectedCheckboxes.length - 1]][1].hour;

            setAreaBooking(areaSelected);
            setScheduleEvent({
                from: hourStart,
                to: hourEnd,
            });
            handleCloseModal();
        } else {
            toast('Debes seleccionar por lo menos una hora');
        }
    };

    const handleDeleteAreaSelected = () => {
        setAreaBooking(null);
    };

    const canChangePage = () => {
        const { name } = eventDetail;

        if (name === '') {
            return false;
        }

        if (hostChecked && Object.keys(selectedHostEmp).length === 0) {
            return false;
        }

        return true;
    };

    const handleSubmit = (moveState) => {
        if (moveState === 0) {
            if (canChangePage()) {
                moveState + 1 < 3 && setToggleState((prevState) => prevState + 1);
            } else {
                if (hostChecked) {
                    toast('Select employee').toUpperCase();
                }

                setEmptyEventName(true);
            }
        } else if (moveState === 1) {
            if (areaBooking) {
                moveState + 1 < 3 && setToggleState((prevState) => prevState + 1);
            } else {
                toast.error(t('must_select_a_common_area').toUpperCase());
            }
        } else if (moveState === 2) {
            if (areaBooking !== null) {
                let startTime = scheduleEvent.from;
                let separateTime = scheduleEvent.from.split(':');
                const startDate = eventDate.setHours(separateTime[0], separateTime[1], 0, 0);
                const interval = selectedCheckboxes.length * 30;
                const reservationDate = convertDateReservation();
                const body = {
                    name: eventDetail?.name,
                    hostId: selectedHostEmp?.userId ? selectedHostEmp?.userId : userData?.id,
                    startDate: startDate,
                    reservationZoneId: areaBooking.zoneId,
                    visitPurpose: 'No comments.',
                    duration: interval,
                    reservationDate: reservationDate,
                };

                dispatch(createNormalEvent(body)).then(
                    ({
                        payload: {
                            data: { data },
                        },
                    }) => {
                        let employeeOtherGuests = [];
                        guestData.map((item) => {
                            employeeOtherGuests.push({
                                id: item.id,
                            });
                        });
                        selectedEmployees.map((item) => {
                            employeeOtherGuests.push({
                                id: item.id,
                            });
                        });
                        const invitBody = {
                            eventId: data?.id,
                            guestIds: employeeOtherGuests,
                        };

                        dispatch(createUserInvitation(invitBody)).then(
                            ({
                                payload: {
                                    data: { data },
                                },
                            }) => {
                                if (data !== null) {
                                    dispatch(
                                        saveNormalEventDetail({
                                            name: '',
                                            date: '',
                                            time: '',
                                            duration: '',
                                        }),
                                    );
                                    dispatch(updateSelectedEmployees([]));
                                    navigate('/dashboard/employee/events');
                                }
                            },
                        );
                    },
                );
            } else {
                toast.error('You must atleast one person to invite');
            }
        }
    };

    const handleCheck = () => {
        setSelectedHostEmp({});
        setHostChecked(!hostChecked);
        setHostObject({
            name: null,
            email: null,
            phoneNo: null,
            employee: null,
        });
    };

    useEffect(() => {
        if (companyRestrictionsData?.isOnuEvent === true) {
            navigate('/unauthorized');
        }
    });

    const handleSelectDate = (value) => {
        const userId = userData?.id;

        if (value !== null) {
            const startDate = value.setHours(0, 0, 0, 0);
            const endDate = value.setHours(23, 59, 59, 999);

            setEventDate(value);
            dispatch(getReservations({ userId: userId, fromDate: startDate, toDate: endDate }));
        }
    };

    const handleSelectArea = (area) => {
        setSelectedCheckboxes([]);

        if (eventDate !== null) {
            setAreaSelected(area);
            setAllFreeReservation(area.availableHours);
        }
    };

    useEffect(() => {
        if (allFreeReservation.length > 0) {
            const hoursGenerated = generateBusyHours(areaSelected);
            const hoursToSchedule = hoursGenerated.map((hour, index) => {
                return {
                    id: index,
                    hour: hour.slice(0, -2).slice(0, -1),
                    isDisabled: !allFreeReservation.includes(hour),
                };
            });

            setShowScheduleModal(true);
            let pairs = groupTimesInPairs(hoursToSchedule);
            setHourPairs(pairs);
        }
    }, [allFreeReservation]);

    const groupTimesInPairs = (hoursToSchedule) => {
        const hourPair = [];

        for (let i = 0; i < hoursToSchedule.length - 1; i++) {
            const pair = [hoursToSchedule[i], hoursToSchedule[i + 1]];
            hourPair.push(pair);
        }

        return hourPair;
    };

    const generateBusyHours = (area) => {
        const start = new Date(
            `${eventDate.getFullYear()}-${eventDate.getMonth() + 1}-${eventDate.getDate()} ${area.from}`,
        );
        const endTimeString = area.to.split(':');
        const end = new Date(
            `${eventDate.getFullYear()}-${eventDate.getMonth() + 1}-${eventDate.getDate()} ${endTimeString[0]}:${
                endTimeString[1]
            }`,
        );
        const interval = 30 * 60 * 1000;
        const missingHours = [];

        for (let time = start.getTime(); time <= end.getTime(); time += interval) {
            const hour = new Date(time);
            const formattedHour = `${String(hour.getHours()).padStart(2, '0')}:${String(hour.getMinutes()).padStart(
                2,
                '0',
            )}:${String(hour.getSeconds()).padStart(2, '0')}`;
            missingHours.push(formattedHour);
        }

        return missingHours;
    };

    const handleCheckboxChange = (id) => {
        let updatedSelection;

        if (selectedCheckboxes.includes(id)) {
            updatedSelection = selectedCheckboxes.filter((checkboxId) => checkboxId !== id);
        } else {
            const firstSelected = selectedCheckboxes[0];
            const lastSelected = selectedCheckboxes[selectedCheckboxes.length - 1];

            if (
                selectedCheckboxes.length === 0 ||
                Math.abs(firstSelected - id) === 1 ||
                Math.abs(lastSelected - id) === 1
            ) {
                updatedSelection = [...selectedCheckboxes, id];
            } else {
                updatedSelection = [id];
            }
        }

        const sort = updatedSelection.sort((a, b) => a - b);
        setSelectedCheckboxes(sort);

        const isConsecutive = consecutive(sort);
        if (!isConsecutive) {
            setSelectedCheckboxes([]);
        }
    };

    const consecutive = (sort) => {
        for (let index = 1; index < sort.length; index++) {
            if (sort[index] !== sort[index - 1] + 1) {
                return false;
            }
        }

        return true;
    };

    const convertDate = () => {
        if (eventDate !== '') {
            const date = new Date(eventDate);
            const month = date.getMonth() + 1; // Los monthes son indexados desde 0
            const day = date.getDate();
            const year = date.getFullYear();

            return `${month}/${day}/${year}`;
        }
    };

    const convertDateReservation = () => {
        if (eventDate !== '') {
            const date = new Date(eventDate);
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();

            return `${year}-${month}-${day}`;
        }
    };

    return (
        <div className='providersPanel CreateEventPanel pb-3'>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={'/dashboard/employee/events'} />
                    <h2>{t('create_event')}</h2>
                </div>
            </div>
            <div className='row steps-row mb-2 mt-3' id='pills-tab' role='tablist'>
                <div className='col-4 tab' role='presentation'>
                    <a
                        className={`steps btn ${toggleState === 0 ? 'active-border' : 'disable-border'}`}
                        onClick={() => toggleTab(0)}
                        style={{ width: '100%' }}
                        id='pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-home'
                        type='button'
                        role='tab'
                        aria-controls='pills-home'
                        aria-selected='true'
                    >
                        <span>{t('detail')}</span>
                    </a>
                </div>
                <div className='col-4 tab tab-right' role='presentation'>
                    <a
                        className={`steps btn ${toggleState === 1 ? 'active-border' : 'disable-border'}`}
                        onClick={() => toggleTab(1)}
                        id='pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-home'
                        type='button'
                        style={{ width: '100%' }}
                        role='tab'
                        aria-controls='pills-home'
                        aria-selected='true'
                    >
                        <span>{t('reservation')}</span>
                    </a>
                </div>
                <div className='col-4 tab tab-right' role='presentation'>
                    <a
                        className={`steps btn ${toggleState === 2 ? 'active-border' : 'disable-border'}`}
                        onClick={() => toggleTab(2)}
                        id='pills-home-tab'
                        data-bs-toggle='pill'
                        style={{ width: '100%' }}
                        data-bs-target='#pills-home'
                        type='button'
                        role='tab'
                        aria-controls='pills-home'
                        aria-selected='true'
                    >
                        <span>{t('invitations')}</span>
                    </a>
                </div>
            </div>
            {toggleState === 0 && (
                <div className='content animated-div'>
                    <Grid
                        container
                        spacing={2}
                        className='HostSection'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '51.5%',
                            marginTop: '2rem',
                        }}
                    >
                        <Grid item xs={12} sx={{ position: 'relative' }}>
                            <p className='subtitle'>{t('event_name')}</p>

                            <TextField
                                className='requiredData'
                                error={emptyEventName && eventDetail.name === ''}
                                helperText={emptyEventName && eventDetail.name === '' ? t('requiredField') : ''}
                                size='small'
                                fullWidth
                                label={t('name')}
                                id='NAME'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <img src={ic_birthday} alt='ic_birthday' />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    textAlign: lCode === 'ar' ? 'right' : 'left',

                                    '& 	.MuiOutlinedInput-notchedOutline': {
                                        textAlign: lCode === 'ar' ? 'right' : 'left',
                                    },
                                    '& 	.MuiInputLabel-root': {
                                        fontSize: 12,
                                        left: lCode === 'ar' ? 'inherit' : '0',
                                        right: lCode === 'ar' ? '1.75rem' : '0',
                                        transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                    },
                                }}
                                value={eventDetail.name}
                                onChange={(e) => setEventDetail({ ...eventDetail, name: e.target.value })}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: '12px',
                                position: 'relative',
                            }}
                        >
                            <p className='subtitle'>{t('choose_the_host')}</p>
                            <input
                                type='checkbox'
                                checked={hostChecked}
                                onChange={handleCheck}
                                className='inputWithMargin'
                            />
                            <p className='subtitle'>{t('other_is_the_host')}</p>
                        </Grid>
                    </Grid>

                    <div className=' '>
                        <div
                            className='HostSection'
                            style={{
                                width: '50%',
                                marginTop: '2rem',
                            }}
                        >
                            <div className='name'>
                                <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Grid item xs={12}>
                                        <Box sx={{ mt: '6px' }}>
                                            <FormControl fullWidth>
                                                <InputLabel id='demo-simple-select-label'>{t('employee')}</InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId='demo-simple-select-label'
                                                    id='demo-simple-select'
                                                    defaultValue='employee'
                                                    value={hostObject.employee}
                                                    label={t('employee')}
                                                    onChange={(e) => setSelectedHostEmp(e.target.value)}
                                                    disabled={!hostChecked}
                                                    sx={
                                                        {
                                                            // fontSize: "14px",
                                                        }
                                                    }
                                                >
                                                    {allEmployees?.map((item) => (
                                                        <MenuItem
                                                            value={item}
                                                            sx={{
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            {item.name} {item.firstLastName} {item.secondLastName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='name'>
                                <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Grid item xs={12} sx={{ position: 'relative' }}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('name')}
                                            disabled
                                            sx={{
                                                textAlign: lCode === 'ar' ? 'right' : 'left',

                                                '& 	.MuiOutlinedInput-notchedOutline': {
                                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                                },
                                                '& 	.MuiInputLabel-root': {
                                                    fontSize: 12,
                                                    left: lCode === 'ar' ? 'inherit' : '0',
                                                    right: lCode === 'ar' ? '1.75rem' : '0',
                                                    transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                                },
                                            }}
                                            value={selectedHostEmp?.name ? selectedHostEmp?.name : ''}
                                            onChange={(e) => setHostObject({ ...hostObject, name: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ position: 'relative' }}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('email')}
                                            disabled
                                            sx={{
                                                textAlign: lCode === 'ar' ? 'right' : 'left',

                                                '& 	.MuiOutlinedInput-notchedOutline': {
                                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                                },
                                                '& 	.MuiInputLabel-root': {
                                                    fontSize: 12,
                                                    left: lCode === 'ar' ? 'inherit' : '0',
                                                    right: lCode === 'ar' ? '1.75rem' : '0',
                                                    transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                                },
                                            }}
                                            value={selectedHostEmp?.email ? selectedHostEmp?.email : ''}
                                            onChange={(e) => setHostObject({ ...hostObject, email: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ position: 'relative' }}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('phone_number')}
                                            disabled
                                            sx={{
                                                textAlign: lCode === 'ar' ? 'right' : 'left',

                                                '& 	.MuiOutlinedInput-notchedOutline': {
                                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                                },
                                                '& 	.MuiInputLabel-root': {
                                                    fontSize: 12,
                                                    left: lCode === 'ar' ? 'inherit' : '0',
                                                    right: lCode === 'ar' ? '1.75rem' : '0',
                                                    transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                                },
                                            }}
                                            value={selectedHostEmp?.phoneNumber ? selectedHostEmp?.phoneNumber : ''}
                                            onChange={(e) =>
                                                setHostObject({
                                                    ...hostObject,
                                                    phoneNo: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {toggleState === 1 && (
                <div className='content2 animated-div'>
                    <div className='row'>
                        <p className='headline'>{t('choose_date_to_retrieve')}</p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label={t('event_date')}
                                            inputFormat='MM/dd/yyyy'
                                            value={eventDate}
                                            disablePast
                                            onChange={(e) => handleSelectDate(e)}
                                            renderInput={(params) => (
                                                <TextField size='small' {...params} error={false} />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='row mt-3'>
                        <p className='headline2'>{t('choose_a_common_area_to_reservate')}</p>

                        <Grid container spacing={2}>
                            {allAreaToReserve.length > 0 ? (
                                allAreaToReserve.map((item, index) => (
                                    <Grid key={item?.id} item xs={12} md={6} sx={{ position: 'relative' }}>
                                        <MiniCard
                                            title={item?.zoneName}
                                            status={t(status[item?.statusId]).toUpperCase()}
                                            description={`From ${item?.from} To ${item?.to}`}
                                            availability={item?.isFreeForReservation}
                                            zone={item?.zoneId}
                                            onSelectedCard={() => handleSelectArea(item)}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <NotInformation text={t('no_information')} card={true} />
                            )}
                        </Grid>
                    </div>
                    <div className='row mt-3'>
                        <p className='headline2'>{t('booking')}</p>

                        {areaBooking && (
                            <Grid container spacing={2}>
                                <Grid key={areaBooking?.id} item xs={12} md={6} sx={{ position: 'relative' }}>
                                    <MiniCard
                                        title={areaBooking?.zoneName}
                                        status={t(status[areaBooking?.statusId]).toUpperCase()}
                                        description={`From ${scheduleEvent?.from}:00 To ${scheduleEvent?.to}:00`}
                                        availability={areaBooking?.isFreeForReservation}
                                        zone={areaBooking?.zoneId}
                                        showButtonDelete={true}
                                        onDelete={handleDeleteAreaSelected}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </div>
            )}
            {toggleState === 2 && (
                <div style={{ width: '75%', margin: 'auto' }} className='animated-div'>
                    <Visitors />
                </div>
            )}
            <div className='EventBtns'>
                {toggleState !== 0 ? (
                    <button
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '284px' }}
                        onClick={() => toggleState - 1 > -1 && setToggleState((prevState) => prevState - 1)}
                    >
                        {t('previous')}
                    </button>
                ) : (
                    ''
                )}
                <button
                    style={{ width: '284px' }}
                    className='custom_primary_btn_dark'
                    onClick={() => handleSubmit(toggleState)}
                >
                    {toggleState === 2 ? t('create')?.toUpperCase() : t('next')?.toUpperCase()}
                </button>
            </div>
            <GenericModal
                title={t('choose_schedule')}
                description={t('available_times', { date: convertDate(), interpolation: { escapeValue: false } })}
                descriptionBold={true}
                show={showScheduleModal}
                onClose={handleCloseModal}
                onSave={handleConfirmSchedule}
                confirmButtonText={t('confirm').toUpperCase()}
                cancelButtonText={t('cancel').toUpperCase()}
            >
                <Grid className='grid-checkbox w-100' container spacing={2} sx={{ margin: '0 !important' }}>
                    {hourPairs.map((pair, index) => (
                        <Grid
                            key={pair?.id}
                            item
                            xs={12}
                            md={4}
                            sx={{ paddingTop: '0 !important', paddingLeft: '0 !important' }}
                        >
                            <div style={{ margin: '0' }}>
                                <FormControlLabel
                                    className='hola checkbox-common'
                                    label={`${pair[0].hour} - ${pair[1].hour}`}
                                    control={
                                        <Checkbox
                                            key={pair[0]?.id}
                                            className='grid-checkall checkbox'
                                            id={pair[0]?.id}
                                            size='small'
                                            disabled={
                                                !allFreeReservation.includes(pair[0]?.hour) && pair[0]?.isDisabled
                                            }
                                            checked={selectedCheckboxes.includes(pair[0]?.id)}
                                            onChange={() => handleCheckboxChange(pair[0]?.id)}
                                        />
                                    }
                                />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </GenericModal>
        </div>
    );
};

export default CreateNormalEvent;
