/*
Author : Arman Ali
Module: Department
github: https://github.com/Arman-Arzoo
*/

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstanceV2 } from '../../Apis/AxiosV2';
import { apiInstance } from '../../Apis/Axios';
import { UploadImage } from '../ShareSlice/shareApi';

// List all the Employee
export const GetAllEmployees = createAsyncThunk(
    'employeeSection/getAllEmployees',
    async (params, { dispatch, getState }) => {
        const result = await apiInstanceV2
            .post('employee-service/get-all-pageable/employee-data', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// create employee /create company-module employee-controller
export const CreateEmployeeUser = createAsyncThunk(
    'employeeSection/createEmployeeUser',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .post('employee-service/v1/create', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Get Employee by Id /get-by-user-id company-module employee-controller
export const GetEmployeeDetailsByUserId = createAsyncThunk(
    'employeeSection/getEmployeeByUserId',
    async (userId, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`employee-service/v1/get-by-user-id/${userId}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// update employee /update company-module employee-controller

export const UpdateEmployeeUser = createAsyncThunk(
    'employeeSection/updateEmployeeUser',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .put('employee-service/v1/update', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// corporate-user-pre-prod-v2/document-service/employee/approve-document (done)
export const ApproveCompanyEmployeeDocument = createAsyncThunk(
    'employeeSection/approveCompanyEmployeeDocument',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .post('document-service/employee/approve-document', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Documents for employee
export const GetAllEmployeeDocuments = createAsyncThunk('employeeSection/getAllEmployeeDocuments', async (id) => {
    const result = await apiInstanceV2
        .get(`document-service/employee/get-all/by-user-id/${id}`)
        .then(function (response) {
            if (response.status == 201 || response.status == 200) {
                console.log('All DOC?????', response);
            }
            return response;
        })
        .catch(function (error) {
            //   toast.error("Company Restriction Faild");
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

// set document employee value
export const SetEmployeeDocValue = createAsyncThunk(
    'employeeSection/setEmployeeDocValue',
    async (params, { dispatch, getState }) => {
        const result = await apiInstanceV2
            .put('document-service/employee/set-comment', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// create document employee value
export const CreateEmployeeDocValue = createAsyncThunk(
    'employeeSection/createEmployeeDocValue',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .post('document-service/employee/create', params?.data)
            .then(function (response) {
                if (params?.file) {
                    const formData = new FormData();
                    formData.append('id', response?.data?.data?.id);
                    formData.append('option', 'employee_document');
                    formData.append('file', params?.file);
                    dispatch(UploadImage(formData));
                }
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
