import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { GetHidRecordDetails } from './HidApi';
import i18n, { t } from 'i18next';

const HidSlice = createSlice({
    name: 'hid',
    initialState: {
        getHidRecordDetails: {},
        getAllStatusForDevice: {},
        createHidRecord: {},
        createHidSioRecord: {},
        createHidInput: {},
        createFormat: {},
        createHidReader: {},
        getSio: {},
        getAllSiosByHidId: {},
        advanceSearchByFilters: {},
        updateHidRecord: {},
        deleteRecordsByTableAndIds: {},
        selectedSio: {},
        connectWithHid: false,
        sendInformationSetting: false,
        resetHid: false,
    },
    reducers: {
        setSelectedSio: (state, action) => {
            state.selectedSio = action.payload;
        },
    },
    extraReducers: {
        'hid/getHidRecordDetails/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getHidRecordDetails = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/connectWithHid/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.connectWithHid = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/sendInformationSetting/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.sendInformationSetting = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/resetHid/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.resetHid = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/getAllStatusForDevice/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllStatusForDevice = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/createHidRecord/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createHidRecord = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/createHidSioRecord/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createHidSioRecord = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/createHidInput/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createHidInput = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/createFormat/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createFormat = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/createHidReader/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createHidReader = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/getSio/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getSio = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/getAllSiosByHidId/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllSiosByHidId = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/getAllZones/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllZones = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/advanceSearchByFilters/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.advanceSearchByFilters = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/updateHidRecord/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateHidRecord = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'hid/deleteRecordsByTableAndIds/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.deleteRecordsByTableAndIds = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        clearPreview: (state) => {
            state.getPreview = [];
        },
    },
});

export const { setSelectedSio } = HidSlice.actions;

export default HidSlice.reducer;
