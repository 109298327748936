import React, { useEffect, useState, useRef } from 'react';
import {
    TextField,
    Button,
    Box,
    Tabs,
    Tab,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import userregular from '../../../../../assets/images/user-regular.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ClearButton from '../../../../../components/ClearButton';
import CameraButton from '../../../../../components/CameraButton';
import siluetPerson from '../../../../../assets/defaultImages/silueta_persona.png';
import icCamera from '../../../../../assets/defaultImages/ic-camera.svg';
import SwipeableViews from 'react-swipeable-views';
import cloud from '../../../../../assets/images/cloud.svg';
import { toast } from 'react-toastify';
import SelectCamera from '../Modal/SelectCamera';
import { SnackbarProvider } from 'notistack';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Webcam from 'react-webcam';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const Step5TakeSelfie = ({
    loadingProgress,
    setLoadingProgress,
    extraData,
    onChange,
    setExtraData,
    getAvailableCameras,
    captureStream,
    videoStreamSrc,
    setVideoStreamSrc,
    captureImage,
    selfieSrc,
    setSelfieSrc,
    saveSelfieToFile,
    availableCameras,
    setAvailableCameras,
    selectedCamera,
    setSelectedCamera,
    takeCamera,
    setTakeCamera,
    selectedImage,
    setSelectedImage,
    isSocketOpen,
    setIsSocketOpen,
    websocketRef,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const webcamRef = useRef(null);
    const [activeTab, setActiveTab] = useState(0);
    const [selectCameraDialogShow, setSelectCameraDialogShow] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [hasAgreedCameraUse, setHasAgreedCameraUse] = useState(Cookies.get('agreedCamera') === 'true');
    const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);
    const [cameras, setCameras] = useState([]);

    const handleAgree = () => {
        Cookies.set('agreedCamera', 'true');
        setHasAgreedCameraUse(true);
    };

    const handleCameraPermission = async () => {
        setSelectCameraDialogShow(true);
        setSelfieSrc('');

        try {
            await navigator.mediaDevices.getUserMedia({ video: true });

            onPermissionsAccept();
        } catch (error) {
            onPermissionsReject();
        }
    };

    function onPermissionsAccept() {
        setCameraPermissionGranted(true);
    }

    function onPermissionsReject() {
        setCameraPermissionGranted(false);
    }

    const handleClose = () => {
        setOpen(false);
        setTakeCamera(false);
    };

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleClearSelfie = () => {
        URL.revokeObjectURL(selectedImage);
        setSelectedImage(null);
        handleClearFileInput();
    };

    useEffect(() => {
        if (selfieSrc !== '') {
            handleClearSelfie();
        }
    }, [selfieSrc]);

    useEffect(() => {
        if (selectedImage !== null) {
            handleClearPhoto();
            if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
                websocketRef.current.close();
                setIsSocketOpen(false);
                setTakeCamera(false);
            }
        }
    }, [selectedImage]);

    const handleClearPhoto = () => {
        setSelfieSrc('');
        setSelectedCamera('');
        setVideoStreamSrc('');
    };

    const onFileChange = (e) => {
        const selectedFiles = e.target.files;
        const imageRegex = /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/i;

        if (selectedFiles && selectedFiles.length > 0) {
            if (!imageRegex.test(selectedFiles[0].name)) {
                toast.error(t('error_file_format').toUpperCase());
            } else if (selectedFiles[0].size >= 500000) {
                toast.error(t('error_file_size').toUpperCase());
            } else {
                setSelectedImage(selectedFiles[0]);
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            fileInputRef.current.files = files;
            onFileChange({ target: fileInputRef.current });
        }
    };

    const removeSelectedImage = () => {
        setSelectedImage();
        URL.revokeObjectURL();
    };

    const fileInputRef = useRef(null);

    const handleClearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleStream = () => {
        if (selfieSrc !== '') {
            if (availableCameras.length === 0) {
                getAvailableCameras();
                setSelectCameraDialogShow(true);
            } else {
                setSelectCameraDialogShow(true);
            }
        } else if (videoStreamSrc === '') {
            getAvailableCameras();
            setSelectCameraDialogShow(true);
        } else {
            setSelectCameraDialogShow(true);
        }
    };

    const captureSelfie = () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setSelfieSrc(imageSrc);
            setSelectedCamera('');
        }
    };
    const cancelCaptureSelfie = () => {
        setSelectedCamera('');
    };

    return (
        <>
            <Box className={`wrapper-selfie-tab w-100 mt-5 ${selectCameraDialogShow ? 'gray-background' : ''}`}>
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    variant='fullWidth'
                    aria-label='scrollable prevent tabs example'
                >
                    <Tab label={t('image')} />
                    <Tab label={t('camera')} />
                </Tabs>
            </Box>
            <SwipeableViews index={activeTab} onChangeIndex={handleTabChange}>
                <div
                    className='enrollment-wrapper justify-content-right mt-1 mb-4'
                    style={{ display: activeTab === 0 ? 'block' : 'none' }}
                >
                    <div className='step5-take-selfie mt-3 mb-4'>
                        <div className='title-selfie-tab'>
                            <span>{t('upload_from_file')}</span>
                            <ClearButton
                                className='mt-0 pt-0'
                                handleClear={handleClearSelfie}
                                flagTooltip={true}
                                textTooltip={t('clean_selfie')}
                            />
                        </div>

                        <label
                            htmlFor='file-input'
                            className='dottedborderbox'
                            style={{ maxWidth: '600px' }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            {' '}
                            <img
                                src={cloud}
                                alt='submitupload'
                                className='submitupload'
                                style={{ marginBottom: '10px' }}
                            />
                            <input type='file' id='file-input' accept='image/jpeg, image/png' onChange={onFileChange} />
                            <div className='dragAndDrop'>
                                <p>{t('choose_or_drag_your_image_here')}</p>
                                <span>{t('max_file_size_500kb')}</span>
                            </div>
                        </label>

                        {selectedImage && (
                            <div>
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    style={{
                                        maxHeight: '200px',
                                        maxWidth: '300px',
                                        objectFit: 'cover',
                                    }}
                                    alt='Thumb'
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className='enrollment-wrapper justify-content-right mt-1 mb-4'
                    style={{ display: activeTab === 1 ? 'block' : 'none' }}
                >
                    <div className='step5-take-selfie mt-3 mb-4'>
                        <div className='title-selfie-tab'>
                            {selectedCamera === '' && <span>{t('add_via_camera')}</span>}
                            <div className='selfie-buttons-container'>
                                <ClearButton
                                    className='mt-0 pt-0'
                                    hidden={selfieSrc === ''}
                                    handleClear={handleClearPhoto}
                                    flagTooltip={true}
                                    textTooltip={t('clean_selfie')}
                                />
                                {selectedCamera === '' && (
                                    <CameraButton
                                        className='mt-0 pt-0'
                                        handleStream={handleCameraPermission}
                                        flagTooltip={true}
                                        isSocketOpen={isSocketOpen}
                                        textTooltip={t('start_stop_streaming').toUpperCase()}
                                    />
                                )}
                                {selfieSrc === '' && selectedCamera !== '' && (
                                    <HighlightOffIcon
                                        className='mt-0 pt-0'
                                        onClick={cancelCaptureSelfie}
                                        style={{ fontSize: '33px', color: '#146f62', marginTop: '-2px' }}
                                    />
                                )}
                                <Dialog
                                    maxWidth={'xs'}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby='alert-dialog-title'
                                    aria-describedby='alert-dialog-description'
                                >
                                    <DialogTitle id='alert-dialog-title'>{t('use_cameras_permission')}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id='alert-dialog-description'>
                                            {t('let_ientry_web_app_access_your_devices_cameras')}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <div className='d-flex w-100 justify-content-center'>
                                            <button
                                                className='custom_btn_cancel_gray_hover'
                                                style={{ width: '220px' }}
                                                onClick={() => {
                                                    handleClose();
                                                }}
                                                id='btn_s5ts_cancel'
                                            >
                                                {t('cancel')}
                                            </button>
                                            <button
                                                className='custom_primary_btn_dark'
                                                style={{ width: '220px' }}
                                                onClick={() => {
                                                    handleStream();
                                                    handleClose();
                                                    handleAgree();
                                                }}
                                                id='btn_s5ts_confirm'
                                            >
                                                {t('confirm').toUpperCase()}
                                            </button>
                                        </div>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>{' '}
                        <div
                            className='image-box mb-4'
                            style={{
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundImage:
                                    selfieSrc !== ''
                                        ? `url(${selfieSrc})`
                                        : selectedCamera !== ''
                                          ? `url(${videoStreamSrc})`
                                          : 'none',
                            }}
                        >
                            {selectedCamera === '' && selfieSrc === '' && (
                                <span className='text-indications-camera-selfie mr-3 ml-3'>
                                    {cameraPermissionGranted === false
                                        ? t('camera_access_required')
                                        : t('instructions_to_photography')}
                                </span>
                            )}

                            {selectedCamera !== '' && selfieSrc === '' && (
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat='image/jpeg'
                                    className='selfie-webcam-preview'
                                    videoConstraints={{
                                        deviceId: availableCameras[selectedCamera]?.deviceId,
                                    }}
                                />
                            )}
                        </div>
                        {selfieSrc === '' && selectedCamera !== '' && (
                            <>
                                <div className='d-flex w-100 justify-content-center d-below'>
                                    <RadioButtonCheckedOutlinedIcon
                                        onClick={captureSelfie}
                                        style={{ fontSize: '58px', color: '#146f62', marginTop: '-2px' }}
                                    />{' '}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </SwipeableViews>

            <SelectCamera
                open={selectCameraDialogShow}
                onClose={() => {
                    setSelectCameraDialogShow(false);
                }}
                selectedCamera={selectedCamera}
                takeCamera={takeCamera}
                setLoadingProgress={setLoadingProgress}
                setSelectedCamera={setSelectedCamera}
                setTakeCamera={setTakeCamera}
                availableCameras={availableCameras}
                setAvailableCameras={setAvailableCameras}
                captureStream={captureStream}
                setSelfieSrc={setSelfieSrc}
                cameras={cameras}
                setCameras={setCameras}
            />
        </>
    );
};

export default Step5TakeSelfie;
