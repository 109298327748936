import { InputAdornment, TextField, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleSearch } from '../../reduxToolkit/Search/SearchApi';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { TABLES } from '../../Apis/Tables';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
// EMPLOYEES
export const SearchSimpleEmployeesDocs = ({
    setBodyForExportsEmployeesDocs,
    setDocumentEmployee,
    page,
    rowsPerPage,
    toggleState,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchDocumentEmployee, setSearchDocumentEmployee] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const filterDocumentEmployeeByElement = async () => {
        if (searchDocumentEmployee !== '') {
            const body = {
                key: searchDocumentEmployee,
                model: TABLES.DOCUMENTS_EMPLOYEE,
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
            };
            setBodyForExportsEmployeesDocs(body);

            try {
                const response = await dispatch(SimpleSearch({ body }));
                const {
                    data: { data, success },
                } = response.payload;
                if (success === true) {
                    setDocumentEmployee(data.content);
                }
            } catch (error) {
                console.log('Error in Document Panel - Employee: ', error);
            }
        } else {
            setDocumentEmployee(null);
        }
    };

    useEffect(() => {
        const handleDebounce = () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                filterDocumentEmployeeByElement();
            }, 300);
            setDebounceTimeout(timeout);
        };

        handleDebounce();

        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [searchDocumentEmployee]);

    useEffect(() => {
        setSearchDocumentEmployee('');
    }, [toggleState]);

    const handleReset = () => {
        setSearchDocumentEmployee('');
        setDocumentEmployee(null);
    };

    return (
        <form className='centerElements' onSubmit={(e) => e.preventDefault()}>
            <section className='div-searchElement searchElementByWord-devices--section centerElements'>
                <TextField
                    label={t('TYPE_SOMETHING_TO_SEARCH')}
                    className='searchSimple--TextField'
                    value={searchDocumentEmployee}
                    onChange={(e) => setSearchDocumentEmployee(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <>
                                <InputAdornment position='end'>
                                    <SearchIcon onClick={filterDocumentEmployeeByElement} />
                                </InputAdornment>
                                {searchDocumentEmployee && (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={handleReset}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                />
            </section>
        </form>
    );
};
// SUPPLIERS
export const SearchSimpleSupplierDocs = ({
    setBodyForExportsSupplierDocs,
    toggleState,
    setDocumentSupplier,
    page,
    rowsPerPage,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchDocumentSupplier, setSearchDocumentSupplier] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const filterDocumentSupplierByElement = async () => {
        if (searchDocumentSupplier !== '') {
            const body = {
                key: searchDocumentSupplier,
                model: TABLES.DOCUMENTS_SUPPLIER,
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
            };
            setBodyForExportsSupplierDocs(body);

            try {
                const response = await dispatch(SimpleSearch({ body }));
                const {
                    data: { data, success },
                } = response.payload;
                if (success === true) {
                    setDocumentSupplier(data.content);
                    toast.success(t('search_completed_successfully'));
                }
            } catch (error) {
                toast.error(t('error_when_searching'));
            }
        } else {
            setDocumentSupplier(null);
        }
    };

    useEffect(() => {
        const handleDebounce = () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                filterDocumentSupplierByElement();
            }, 300);
            setDebounceTimeout(timeout);
        };

        handleDebounce();

        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [searchDocumentSupplier]);

    useEffect(() => {
        setSearchDocumentSupplier('');
    }, [toggleState]);

    const handleReset = () => {
        setSearchDocumentSupplier('');
        setDocumentSupplier(null);
    };

    return (
        <form className='centerElements' onSubmit={(e) => e.preventDefault()}>
            <section className='searchElementByWord-devices--section centerElements'>
                <TextField
                    label={t('TYPE_SOMETHING_TO_SEARCH')}
                    className='searchSimple--TextField'
                    value={searchDocumentSupplier}
                    onChange={(e) => setSearchDocumentSupplier(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <>
                                <InputAdornment position='end'>
                                    <SearchIcon onClick={filterDocumentSupplierByElement} />
                                </InputAdornment>
                                {searchDocumentSupplier && (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={handleReset}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                />
            </section>
        </form>
    );
};
// CONTRACTORS
export const SearchContractorsDocs = ({
    setBodyForExportsContractorDocs,
    setDocumentContractor,
    page,
    rowsPerPage,
    toggleState,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchDocumentContractor, setSearchDocumentContractor] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const filterDocumentContractorByElement = async () => {
        if (searchDocumentContractor !== '') {
            const body = {
                key: searchDocumentContractor,
                model: TABLES.DOCUMENTS_CONTRACTOR,
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
            };
            setBodyForExportsContractorDocs(body);

            try {
                const response = await dispatch(SimpleSearch({ body }));
                const {
                    data: { data, success },
                } = response.payload;
                if (success === true) {
                    setDocumentContractor(data.content);
                }
            } catch (error) {
                toast.error(t('error_when_searching').toUpperCase());
            }
        } else {
            setDocumentContractor(null);
        }
    };

    useEffect(() => {
        const handleDebounce = () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                filterDocumentContractorByElement();
            }, 300);
            setDebounceTimeout(timeout);
        };

        handleDebounce();

        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [searchDocumentContractor]);

    useEffect(() => {
        setSearchDocumentContractor('');
    }, [toggleState]);

    const handleReset = () => {
        setSearchDocumentContractor('');
        setDocumentContractor(null);
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <section className='searchElementByWord-devices--section'>
                <TextField
                    label={t('TYPE_SOMETHING_TO_SEARCH')}
                    className='searchSimple--TextField'
                    value={searchDocumentContractor}
                    onChange={(e) => setSearchDocumentContractor(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <>
                                <InputAdornment position='end'>
                                    <SearchIcon onClick={filterDocumentContractorByElement} />
                                </InputAdornment>
                                {searchDocumentContractor && (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={handleReset}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                />
            </section>
        </form>
    );
};
