import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ic_left_arrow from '../../assets/images/ic_left_arrow.svg';

const ReturnArrow = ({ link, onBack, appbar }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lCode = Cookies.get('i18next') || 'en';
    return (
        <Link
            className='top-left-arrow-container'
            to={!link ? '#' : link}
            onClick={() => {
                if (!link) {
                    navigate(-1);
                } else if (link === '#') {
                    onBack();
                }
            }}
        >
            <button
                className='btn-left-arrow'
                style={{
                    width: '32px',
                    marginRight: '15px',
                    paddingLeft: '0px',
                    paddingRight: '10px',
                    backgroundColor: 'transparent',
                }}
            >
                <img className='left-arrow' src={ic_left_arrow} alt='ic_left_arrow' />
            </button>
        </Link>
    );
};

export default ReturnArrow;
