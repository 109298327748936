import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AllEmployeeCards from './subComponents/AllEmployeeCards';
import SortFilter from './Modal/SortFilter';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { permissionObj } from '../../../../Helpers/permission';
import { useDispatch, useSelector } from 'react-redux';
import { handleSelfieImage } from '../../../../reduxToolkit/CompanyEmployees/CompanyEmployeesSlice';
import eyeIcon from '../../../../assets/eye-solid.svg';
import BoyAvatar from '../../../../assets/images/boyAvatarT.svg';
import womanAvatar from '../../../../assets/images/womanAvatarT.png';

import TablePagination from '@mui/material/TablePagination';
import DisplayView from '../../../../components/DisplayView';
import SearchFor from '../../../Modals/SearchFor';
import { MODELS } from '../../../../Apis/Models';
import { TABLES } from '../../../../Apis/Tables';
import { SearchByFilters, SearchByFiltersCustom } from '../../../../reduxToolkit/Search/SearchApi';
import { Checkbox, TextField, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import SettingButton from '../../../../components/SettingButton';
import HeaderSettingButton from '../../../../components/HeaderSettingButton';
import { clearSearchFilters } from '../../../../reduxToolkit/Search/SearchSlice';
import SearchFilterBtn from '../../../../components/buttons/SearchFilterBtn';
import DeleteModal from '../../../Modals/DeleteModal';
import { status } from '../../../../enums/statusEnum';
import moment from 'moment';
import { statusColor } from '../../../../enums/statusColorEnum';
import { DeleteItemsApi } from '../../../../reduxToolkit/Commons/CommonsApi';
import ExportDataBtn from '../../../../components/buttons/ExportDataBtn';
import ImportDataBtn from '../../../../components/buttons/ImportDataBtn';
/* import ImportFileModal from '../../../Modals/ImportFileModal'; */
import NotInformation from '../../../../components/NotInformation';
// import { TABLES } from '../../../../Apis/Tables';
import { SimpleSearch } from '../../../../reduxToolkit/Search/SearchApi';
import SearchIcon from '@mui/icons-material/Search';
import SearchSimple from '../../../../components/SearchSimples/SearchSimple';
import SearchSimpleWithId from '../../../../components/SearchSimples/SearchSimpleWithId';
import ImportFileModal from '../../../Modals/ImportFileModal';
import CreationErrorsModalEmployee from '../../../Modals/CreationErrorsEmployee';
import CreationErrorsEmployee from '../../../Modals/CreationErrorsEmployee';
import OptionModal from '../../../Modals/OptionModal';
import { toast } from 'react-toastify';
const AllEmployees = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // useState
    const [modalShow, setModalShow] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [view, setView] = useState('list');
    const [searchEmployee, setSearchEmployee] = useState('');
    const [selectEmployeeForDelete, setSlectEmployeeForDelete] = useState([]);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteEmployeeShow, setDeleteEmployeeShow] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [employeeId, setEmployeeId] = useState(sessionStorage.getItem('employeeId'));
    const [importFileShow, setImportFileShow] = useState(false);
    const [optionFileShow, setOptionFileShow] = useState(false);
    const [creationErrorsShow, setCreationErrorsShow] = useState(false);
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [simpleSearch, setSimpleSearch] = useState(false);
    const [cleanAdvance, setCleanAdvance] = useState(false);
    // const [searchElement, setSearchElement] = useState('');
    const [bodyForExport, setBodyForExport] = useState();
    const [spreadsheetData, setSpreadsheetData] = useState(null);
    const [search, setSearch] = useState(false);

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    const { searchByFilters, searchByFiltersCustom } = useSelector((state) => state.SearchSlice);
    const [selectedDataDependingOfSearch, setSelectedDataDependingOfSearch] = useState(null);

    useEffect(() => {
        let selectedData;
        if (contractorId === null && supplierId === null) {
            selectedData = searchByFilters;
        } else {
            selectedData = searchByFiltersCustom;
        }
        if (!searchResults || searchResults === null || searchResults === '' || searchResults === undefined) {
            setSelectedDataDependingOfSearch(selectedData);
        } else {
            setSelectedDataDependingOfSearch(searchResults);
        }
    }, [searchByFilters, searchByFiltersCustom, contractorId, supplierId, searchResults]);

    let moduleId;
    let option;
    const [criteria, setCriteria] = useState();

    if (contractorId !== null) {
        moduleId = `${MODELS.ContractorEmployee}`;
        option = `${TABLES.CONTRACTOR_EMPLOYEE}`;
    } else if (supplierId !== null) {
        moduleId = `${MODELS.SupplierEmployee}`;
        option = `${TABLES.SUPPLIER_EMPLOYEE}`;
    } else {
        // Props to the filter window
        moduleId = `${MODELS.Employee}`;
        option = `${TABLES.EMPLOYEES}`;
    }
    const spanRef = useRef(null);
    const tdRef = useRef(null);

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            // const selectAllIds = selectedData?.content?.map((item) => {
            const selectAllIds = selectedDataDependingOfSearch?.content?.map((item) => {
                return item?.id;
            });
            setSlectEmployeeForDelete(selectAllIds);
        } else {
            setSlectEmployeeForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSlectEmployeeForDelete([...selectEmployeeForDelete, e.target.id]);
        } else {
            setSlectEmployeeForDelete(selectEmployeeForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    const handlFilters = (order, sort) => {
        setOrderBy(order);
        setSortBy(sort);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        dispatch(handleSelfieImage(null));
    }, []);

    // useEffect to check automatically all the items when page, rowsPerPage, or search change
    useEffect(() => {
        if (isAllChecked) {
            // const selectAllIds = selectedData?.content?.map((item) => item?.id);
            const selectAllIds = selectedDataDependingOfSearch?.content?.map((item) => item?.id);
            setSlectEmployeeForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [searchByFilters, searchByFiltersCustom]);

    //This fragment makes uncheked all the checkboxes when toggleState change
    const resetAllCheckboxes = () => {
        const checkboxes = document.querySelectorAll('.checkbox');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    };

    const elementRef = useRef(null);
    useEffect(() => {
        dispatch(clearSearchFilters());
        resetAllCheckboxes();
        setSlectEmployeeForDelete([]);
        setIsAllChecked(false);
        setPage(0);
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        if (view !== 'grid') {
            setRowsPerPage(20);
        } else {
            setRowsPerPage(8);
        }
    }, [view]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [view, page, rowsPerPage, finalArray, deletedFlag]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        sessionStorage.removeItem('contractId');
        sessionStorage.removeItem('supplier_order_id');
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    useEffect(() => {
        if (loaded) {
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                    from: item.from,
                    to: item.to,
                };
            });

            if (contractorId === null && supplierId === null) {
                const body = {
                    pagination: {
                        page: page,
                        size: rowsPerPage,
                    },
                    searchCriteriaList: criteriaList,
                };

                dispatch(SearchByFilters({ option, body })).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        {
                            setSearchResults(null);
                        }
                    },
                );
                setCriteria({ searchCriteriaList: criteriaList });
            } else {
                const body = {
                    userToFindId: contractorId !== null ? contractorId : supplierId,
                    pagination: {
                        page: page,
                        size: rowsPerPage,
                    },
                    searchCriteriaList: criteriaList,
                };
                dispatch(SearchByFiltersCustom({ option, body })).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        {
                        }
                    },
                );
                setCriteria({ searchCriteriaList: criteriaList });
            }
            setLoaded(false);
            setDeletedFlag(false);
        }
    }, [loaded, page, rowsPerPage, finalArray, filterDialogShow]);
    const deleteSelectedEmployees = (deleteItem) => {
        const tableName =
            contractorId !== null ? 'contractor_employee' : supplierId !== null ? 'supplier_employee' : 'employee';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSlectEmployeeForDelete([]);
                        setIsAllChecked(false);
                    } else {
                    }
                },
            );
        }
    };
    useEffect(() => {
        if (searchElement && filterDialogShow) {
            toast.info(t('filters_reset_message'));
            setLoaded(true);
            setSearchElement('');
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                    from: item.from,
                    to: item.to,
                };
            });
            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: criteriaList,
            };

            dispatch(SearchByFilters({ option, body })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    {
                        setSearchResults(null);
                        setLoaded(false);
                    }
                },
            );
        }
    }, [searchElement, filterDialogShow]);

    useEffect(() => {
        if (finalArray.length > 0 && cleanAdvance) {
            toast.info(t('filters_reset_message'));
            setSearchElement('');
            setCleanAdvance(true);
        }
    }, [finalArray]);
    return (
        <>
            {creationErrorsShow ? (
                <CreationErrorsEmployee
                    spreadsheetData={spreadsheetData}
                    setSpreadsheetData={setSpreadsheetData}
                    show={creationErrorsShow}
                    onHide={() => setCreationErrorsShow(false)}
                    creationErrorsShow={creationErrorsShow}
                    setCreationErrorsShow={setCreationErrorsShow}
                />
            ) : (
                <div>
                    <div className='head'>
                        <div className='headLeft'>
                            <h2>{t('employees')}</h2>
                            <DisplayView view={view} setView={setView} />
                        </div>
                        <div className='container-top-right-btns'>
                            {contractorId === null && supplierId === null && (
                                <div className='d-flex flex-column gap-1'>
                                    <SettingButton
                                        onAction={() => navigate('/dashboard/employee/user-restriction')}
                                        title={t('modify_settings')?.toUpperCase()}
                                    />
                                    <HeaderSettingButton
                                        onAction={() => navigate('/dashboard/employee/company/headers')}
                                        title={t('modify_headers')?.toUpperCase()}
                                    />
                                </div>
                            )}
                            <ImportDataBtn onClickFn={() => setImportFileShow(true)} />
                            {!searchResults || !bodyForExport ? (
                                <ExportDataBtn body={criteria} option={option} />
                            ) : (
                                <ExportDataBtn searchElement={searchElement} body={bodyForExport} option={option} />
                            )}
                            {(contractorId !== null ||
                                supplierId !== null ||
                                permission?.includes(permissionObj?.WEB_EMPLOYEE_CREATE)) && (
                                <button
                                    id='btn_ae_create'
                                    className='add-btn-1'
                                    onClick={() => {
                                        if (contractorId !== null) {
                                            navigate('/dashboard/contractor/add-new-employe');
                                        } else if (supplierId !== null) {
                                            navigate('/dashboard/supplier/create-employee');
                                        } else {
                                            navigate('/dashboard/employee/all-employees/add-employee');
                                        }
                                    }}
                                >
                                    <i className='fa fa-plus' aria-hidden='true'></i>
                                    {t('create')}
                                </button>
                            )}
                            {permission?.includes(permissionObj?.WEB_EMPLOYEE_DELETE) && (
                                <button
                                    className='delete-btn-1'
                                    disabled={selectEmployeeForDelete?.length === 0}
                                    onClick={() => {
                                        setDeleteEmployeeShow(true);
                                    }}
                                >
                                    <i className='fa fa-trash-o' aria-hidden='true'></i>
                                    {t('delete')}
                                </button>
                            )}
                            <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                        </div>
                    </div>
                    {modalShow && <SortFilter setModalShow={setModalShow} handlFilters={handlFilters} />}
                    {contractorId === null && supplierId === null && (
                        <SearchSimple
                            setBodyForExport={setBodyForExport}
                            setSearchResults={setSearchResults}
                            searchElement={searchElement}
                            setSimpleSearch={setSimpleSearch}
                            setSearchElement={setSearchElement}
                            optionTable={option}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            search={search}
                            setSearch={setSearch}
                            setCleanAdvance={setCleanAdvance}
                        />
                    )}
                    {(contractorId !== null || supplierId !== null) && (
                        <SearchSimpleWithId
                            setBodyForExport={setBodyForExport}
                            setSearchResults={setSearchResults}
                            optionTable={option}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            id={contractorId !== null ? contractorId : supplierId !== null ? supplierId : null}
                        />
                    )}
                    {view === 'grid' && selectedDataDependingOfSearch?.content?.length > 0 && (
                        <div className='d-flex ml-1 pl-0'>
                            <FormControlLabel
                                className='grid-checkall'
                                control={
                                    <Checkbox
                                        label='Label'
                                        checked={isAllChecked}
                                        onChange={handelDeleteAll}
                                        size='small'
                                    />
                                }
                                label={t('de_/_select_all')}
                            />
                        </div>
                    )}
                    {view === 'grid' && (
                        <AllEmployeeCards
                            searchEmployee={searchEmployee}
                            employeeData={selectedDataDependingOfSearch}
                            setSlectEmployeeForDelete={setSlectEmployeeForDelete}
                            selectEmployeeForDelete={selectEmployeeForDelete}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    )}
                    {view === 'list' && (
                        <div
                            className='panelTables w-100 animated-div px-1'
                            style={{ overflowX: 'scroll', overflowY: 'scroll', whiteSpace: 'nowrap' }}
                            ref={elementRef}
                        >
                            {selectedDataDependingOfSearch?.content?.length > 0 ? (
                                <table className='w-100'>
                                    <thead className='no-border-thead'>
                                        <th className='first_head'>
                                            <Tooltip title={t('de_/_select_all').toUpperCase()} placement='top'>
                                                <Checkbox
                                                    className='grid-checkall checkbox'
                                                    checked={isAllChecked}
                                                    onChange={handelDeleteAll}
                                                    size='small'
                                                />
                                            </Tooltip>
                                        </th>
                                        <th className='first_head pl-0'>{t('name')}</th>
                                        <th>{t('last_name')}</th>
                                        <th>{t('second_last_name')}</th>
                                        <th>{t('email')}</th>
                                        <th>{t('phone_number')}</th>
                                        {contractorId === null && supplierId === null && (
                                            <>
                                                <th>{t('documentId')}</th>
                                                <th>{t('work_station')}</th>
                                                <th>{t('privilege')}</th>
                                                <th>{t('department')}</th>
                                                <th>{t('employee_id')}</th>
                                            </>
                                        )}
                                        {(contractorId !== null || supplierId !== null) && <th>{t('dob')}</th>}
                                        {(contractorId !== null || supplierId !== null) && <th>{t('status')}</th>}
                                        {(contractorId !== null || supplierId !== null) && (
                                            <>
                                                <th>{t('documentId')}</th>
                                                <th>{t('contract_status')}</th>
                                            </>
                                        )}
                                        {(contractorId !== null || supplierId !== null) && <th>{t('options')}</th>}
                                        {contractorId === null && supplierId === null && <th>{t('update')}</th>}
                                    </thead>

                                    <tbody>
                                        {/* {selectedData?.content */}
                                        {selectedDataDependingOfSearch?.content
                                            .filter((user) => {
                                                if (searchEmployee === '') {
                                                    return user;
                                                } else if (
                                                    user?.name?.toLowerCase().includes(searchEmployee?.toLowerCase())
                                                ) {
                                                    return user;
                                                }
                                            })
                                            .map((item, index) => (
                                                <tr key={item?.id}>
                                                    <td className='first'>
                                                        <Checkbox
                                                            className='grid-checkall checkbox'
                                                            checked={selectEmployeeForDelete?.includes(item?.id)}
                                                            id={item?.id}
                                                            onChange={handleCheckboxChange}
                                                            size='small'
                                                        />
                                                    </td>
                                                    <td
                                                        ref={tdRef}
                                                        className='first pl-0 align-middle'
                                                        style={{ maxWidth: 180 }}
                                                    >
                                                        <>
                                                            <span
                                                                className='align-middle'
                                                                title={item?.name}
                                                                ref={spanRef}
                                                                style={{
                                                                    textTransform: 'none',
                                                                    maxWidth: '100%',
                                                                    display: 'inline-block',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        (item?.genderId === 1 && BoyAvatar) ||
                                                                        (item?.genderId === 2 && womanAvatar) ||
                                                                        (item?.genderId === null && BoyAvatar)
                                                                    }
                                                                    alt=''
                                                                    className='name_avatar mr-2 align-middle'
                                                                />
                                                                {item?.name}
                                                            </span>
                                                        </>
                                                    </td>
                                                    <td>{item?.lastName || '-'}</td>
                                                    <td>{item?.secondLastName || '-'}</td>
                                                    <td>{item?.email || '-'}</td>
                                                    <td>{item?.phoneNumber || '-'}</td>{' '}
                                                    {contractorId === null && supplierId === null && (
                                                        <>
                                                            {' '}
                                                            <td>{item?.documentId || '-'}</td>{' '}
                                                            <td>{item?.workStation || '-'}</td>
                                                        </>
                                                    )}
                                                    {contractorId === null && supplierId === null && (
                                                        <td>{item?.role || '-'}</td>
                                                    )}
                                                    {contractorId === null && supplierId === null && (
                                                        <td>{item?.department || '-'}</td>
                                                    )}
                                                    {contractorId === null && supplierId === null && (
                                                        <td>{item?.employeeId || '-'}</td>
                                                    )}
                                                    {(contractorId !== null || supplierId !== null) && (
                                                        <td>
                                                            {item.dob
                                                                ? lCode === 'en'
                                                                    ? moment(item.dob).format('MM/DD/YYYY')
                                                                    : moment(item.dob).format('DD/MM/YYYY')
                                                                : '-'}
                                                        </td>
                                                    )}{' '}
                                                    {contractorId !== null && (
                                                        <>
                                                            {' '}
                                                            <td>{item?.documentId || '-'}</td>
                                                        </>
                                                    )}{' '}
                                                    {(contractorId !== null || supplierId !== null) && (
                                                        <td
                                                            style={{
                                                                color: statusColor[item?.statusId],
                                                                fontWeight: 'bold',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            {t(status[item?.statusId]).toUpperCase() || '-'}
                                                        </td>
                                                    )}{' '}
                                                    {contractorId !== null && (
                                                        <>
                                                            <td
                                                                style={{
                                                                    color: statusColor[
                                                                        item?.contractorEmployeeStatusId
                                                                    ],
                                                                    fontWeight: 'bold',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                {t(
                                                                    status[item?.contractorEmployeeStatusId],
                                                                ).toUpperCase() || '-'}
                                                            </td>
                                                        </>
                                                    )}{' '}
                                                    {supplierId !== null && (
                                                        <>
                                                            {' '}
                                                            <td>{item?.documentId || '-'}</td>
                                                        </>
                                                    )}{' '}
                                                    {contractorId !== null && (
                                                        <td className='tableIcon'>
                                                            <Tooltip
                                                                title={
                                                                    item?.statusId == 3
                                                                        ? t('complete_documents').toUpperCase()
                                                                        : t('employee_details').toUpperCase()
                                                                }
                                                                placement='top'
                                                                disableInteractive
                                                            >
                                                                <button
                                                                    className='btn-option'
                                                                    onClick={() => {
                                                                        sessionStorage.setItem(
                                                                            'elementForDelete',
                                                                            item.id,
                                                                        );
                                                                        item?.statusId == 3
                                                                            ? navigate(
                                                                                  `/dashboard/contractor/employee-upload-documets/${item?.userId}`,
                                                                              )
                                                                            : navigate(
                                                                                  `/dashboard/contractor/employee-contract-detail/${item?.userId}`,
                                                                              );
                                                                    }}
                                                                >
                                                                    <img src={eyeIcon} alt='eye' />
                                                                </button>
                                                            </Tooltip>
                                                        </td>
                                                    )}
                                                    {supplierId !== null && (
                                                        <>
                                                            <td
                                                                style={{
                                                                    color: statusColor[item?.supplierEmployeeStatusId],
                                                                    fontWeight: 'bold',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                {t(
                                                                    status[item?.supplierEmployeeStatusId],
                                                                ).toUpperCase() || '-'}
                                                            </td>
                                                        </>
                                                    )}{' '}
                                                    {supplierId !== null && (
                                                        <td className='tableIcon'>
                                                            <Tooltip
                                                                title={
                                                                    item?.statusId == 3
                                                                        ? t('complete_documents').toUpperCase()
                                                                        : t('employee_details').toUpperCase()
                                                                }
                                                                placement='top'
                                                                disableInteractive
                                                            >
                                                                <button
                                                                    className='btn-option'
                                                                    onClick={() => {
                                                                        sessionStorage.setItem(
                                                                            'elementForDelete',
                                                                            item.id,
                                                                        );
                                                                        if (item?.statusId === 3) {
                                                                            sessionStorage.setItem(
                                                                                'provideridfordetail',
                                                                                item?.userId,
                                                                            );
                                                                            navigate(
                                                                                `/dashboard/supplier/complete-document/${item?.userId}`,
                                                                            );
                                                                        } else {
                                                                            sessionStorage.setItem(
                                                                                'provideridfordetail',
                                                                                item?.userId,
                                                                            );
                                                                            navigate(
                                                                                `/dashboard/supplier/supplier-order-detail/${item?.userId}`,
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={eyeIcon} alt='eye' />
                                                                </button>
                                                            </Tooltip>
                                                        </td>
                                                    )}
                                                    {permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE) && (
                                                        <td className='tableIcon'>
                                                            <button
                                                                className='btn-option'
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/dashboard/employee/all-employees/update-employee/${item?.userId}`,
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className='fa fa-pencil'
                                                                    aria-hidden='true'
                                                                    style={{ color: '#146F62' }}
                                                                ></i>
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            ) : (
                                <NotInformation text={t('no_information')} card={true} />
                            )}
                        </div>
                    )}

                    {selectedDataDependingOfSearch?.content?.length > 0 && (
                        <div className='d-flex justify-content-center'>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={view === 'grid' ? [8, 16, 24, 32] : [20, 40, 60]}
                                count={selectedDataDependingOfSearch?.totalElements}
                                page={page}
                                onPageChange={handleChangePage}
                                labelRowsPerPage={t('empolyee_per_page')}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    )}

                    <SearchFor
                        open={filterDialogShow}
                        onClose={() => {
                            setFilterDialogShow(false);
                        }}
                        onFiltered={(originalArray) => {
                            setFilterDialogShow(false);
                            setFinalArray(originalArray);
                        }}
                        moduleId={moduleId}
                        option={option}
                        finalArray={finalArray}
                        cleanAdvance={cleanAdvance}
                    />
                    <DeleteModal
                        show={deleteEmployeeShow}
                        onHide={() => setDeleteEmployeeShow(false)}
                        onClickFn={() => deleteSelectedEmployees(selectEmployeeForDelete)}
                        data={selectEmployeeForDelete}
                        title_modal={t('delete_employees')}
                        description={'are_you_sure_you_want_to_delete'}
                        element_modal={t('employees')}
                        expor
                    />
                    <ImportFileModal
                        show={importFileShow}
                        onHide={() => setImportFileShow(false)}
                        onCreationErrors={() => setCreationErrorsShow(true)}
                        rors={() => setCreationErrorsShow(true)}
                        onCreated={() => setLoaded(true)}
                        title_modal={t('upload_form')}
                        element_modal={t('employees')}
                        model={
                            contractorId !== null
                                ? 'contractorEmployee'
                                : supplierId !== null
                                  ? 'supplierEmployee'
                                  : 'employee'
                        }
                    />
                </div>
            )}
        </>
    );
};

export default AllEmployees;
