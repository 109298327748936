export const base64ToBlob = (base64, mime) => {
    const byteChars = atob(base64.split(',')[1]);
    const byteNums = new Array(byteChars.length);

    for (let i = 0; i < byteChars.length; i++) {
        byteNums[i] = byteChars.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNums);

    return new Blob([byteArray], { type: mime });
};
