/*
Author : Arman Ali
Module: Department
github: https://github.com/Arman-Arzoo
*/
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const departmentSectionSlice = createSlice({
    name: 'departmentSection',
    initialState: {
        getAllDepartments: {},
        createDepartment: {},
        updateDepartment: {},
        deleteDepartment: {},
        getAllNonDepartmentUser: [],
        addDepartmentById: {},
        removeAlluserFromDepartment: {},
        listOfUsersDepartment: {},
        removeUserFromDepartment: {},
        getWorkOffice: [],
        checkInWorkOffice: {},
        checkOutWorkOffice: {},
    },
    reducers: {},
    extraReducers: {
        'departmentSection/getAllDepartments/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getAllDepartments = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
        'departmentSection/createDepartment/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createDepartment = data;
                toast?.success(data.message);
            } else if (status >= 400 && status < 500) {
                toast?.error(data.message);
                // toast("Fail to fetch data")
            }
        },

        'departmentSection/updateDepartment/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateDepartment = data;
                toast?.success(data.message);
            } else if (status >= 400 && status < 500) {
                toast.error(data.message);

                // toast("Fail to fetch data")
            }
        },
        'departmentSection/deleteDepartment/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                toast?.success(data.message);
                state.deleteDepartment = data;
            } else if (status >= 400 && status < 500) {
                toast?.error(data.message);
                // toast("Fail to fetch data")
            }
        },
        'departmentSection/getAllNonDepartmentUser/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getAllNonDepartmentUser = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
        'departmentSection/addDepartmentById/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.addDepartmentById = data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
        'departmentSection/removeAlluserFromDepartment/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.removeAlluserFromDepartment = data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
        'departmentSection/listOfUsersDepartment/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.listOfUsersDepartment = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
        'departmentSection/removeUserFromDepartment/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.removeUserFromDepartment = data?.data;

                toast.success(t('delete_successfully').toUpperCase());
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },

        // work-office
        'departmentSection/getWorkOffice/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getWorkOffice = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
        'departmentSection/checkInWorkOffice/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.checkInWorkOffice = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
        'departmentSection/checkOutWorkOffice/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.checkOutWorkOffice = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
    },
});

export const {} = departmentSectionSlice.actions;

export default departmentSectionSlice.reducer;
