import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { permissionObj } from '../../Helpers/permission';
import { useDispatch, useSelector } from 'react-redux';

import DeleteModal from '../Modals/DeleteModal';
import { TABLES } from '../../Apis/Tables';
import { MODELS } from '../../Apis/Models';
import SearchFor from '../Modals/SearchFor';
import TablePagination from '@mui/material/TablePagination';
import { SearchByFilters } from '../../reduxToolkit/Search/SearchApi';
import { Checkbox } from '@mui/material';
import { DeleteItemsApi } from '../../reduxToolkit/Commons/CommonsApi';
import { clearSearchFilters } from '../../reduxToolkit/Search/SearchSlice';
import { setSelectedCardsR } from '../../reduxToolkit/Cards/CardsSlice';
import SearchFilterBtn from '../../components/buttons/SearchFilterBtn';
import { ReactComponent as LinkIcon } from '../../assets/images/link-solid.svg';
import { ReactComponent as UnlinkIcon } from '../../assets/images/link-slash-solid.svg';
import QRCode from 'qrcode.react';
import { colors } from '../../enums/colors';
import moment from 'moment';
import ExportDataBtn from '../../components/buttons/ExportDataBtn';
import CreateCardBtn from '../../components/buttons/CreateCardBtn';
import CreationErrorsModal from '../Modals/CreationErrorsModal';
import AssignCardModal from '../Dashboard/Company/Employees/Modal/AssignCardModal';
import { RemoveCardToUser } from '../../reduxToolkit/Cards/CardsApi';
import NotInformation from '../../components/NotInformation';
import AddTemplate from '../../components/buttons/AddTemplate';
import eyeIcon from '../../../src/assets/eye-solid.svg';
import CardPreview from './CardPreview';

const DevicesPanel = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [update, setUpdate] = useState(false);
    const [qrData, setQrData] = useState(null);
    const [qrName, setQrName] = useState(null);
    const [creationErrorsShow, setCreationErrorsShow] = useState(false);
    const [assignCardShow, setAssignCardShow] = useState(false);
    const [cardToAssign, setCardToAssign] = useState();
    const [currentId, setCurrentId] = useState('');
    const [unassignModalShow, setUnassignModalShow] = useState(false);
    const [cardToUnassign, setCardToUnassign] = useState([]);
    const [deleteCardShow, setDeleteCardShow] = useState(false);
    const [selectCardForDelete, setSelectCardForDelete] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [previewShow, setPreviewShow] = useState(false);
    const [previewContent, setPreviewContent] = useState(null);

    // useSelector
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { searchByFilters } = useSelector((state) => state.SearchSlice);

    const [cardsNumber, setCardsNumber] = useState();
    // Props to the filter window
    const moduleId = `${MODELS.Card}`;
    const option = `${TABLES.CARD}`;
    const [criteria, setCriteria] = useState();

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage, finalArray, deletedFlag]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, [dispatch]);

    // useEffect to check automatically all the items when page, rowsPerPage, or search change
    useEffect(() => {
        if (isAllChecked) {
            const selectAllIds = searchByFilters?.content?.map((item) => item?.id);
            setSelectCardForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
            setSelectedCards((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [isAllChecked, searchByFilters]);

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = searchByFilters?.content?.map((item) => {
                return item?.id;
            });
            setSelectCardForDelete(selectAllIds);
            setSelectedCards(selectAllIds);
        } else {
            setSelectCardForDelete([]);
            setSelectedCards([]);
        }
    };

    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectCardForDelete([...selectCardForDelete, e.target.id]);
            setSelectedCards([...selectedCards, e.target.id]);
            dispatch(setSelectedCardsR([...selectedCards, e.target.id]));
        } else {
            setSelectCardForDelete(selectCardForDelete.filter((removeid) => removeid !== e.target.id));
            setSelectedCards(selectedCards.filter((removeid) => removeid !== e.target.id));
        }
    };

    // This section help us to determine the height of the main table here
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        const currentUrl = window.location.pathname;
        const parts = currentUrl.split('/');
        const modulePart = parts[3] ? parts[3] : '' + parts[4] ? '/' + parts[4] : '';
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //Create body for the respectively search
    useEffect(() => {
        const criteriaList = finalArray.map((item) => {
            return {
                dataOption: item.dataOption,
                fieldType: item.fieldType,
                filterKey: item.filterKey,
                operation: item.operation,
                sort: item.sort,
                table: item.table,
                values: item.values,
            };
        });

        const body = {
            pagination: {
                page: page,
                size: rowsPerPage,
            },
            searchCriteriaList: criteriaList,
        };

        dispatch(SearchByFilters({ option, body })).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                {
                }
            },
        );
        setCriteria({ searchCriteriaList: criteriaList });
        setLoaded(false);
        setDeletedFlag(false);
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray, deletedFlag, dispatch]);

    const unassignCard = (deleteItem) => {
        const body = {
            cardIds: [deleteItem[0].cardId],
            userId: deleteItem[0].userId,
        };

        dispatch(RemoveCardToUser(body)).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                if (success === true) {
                    setLoaded(true);
                    setUnassignModalShow(false);
                }
            },
        );
    };

    const deleteSelectedCard = (deleteItem) => {
        const body = deleteItem;
        const tableName = TABLES.CARD;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectCardForDelete([]);
                        setIsAllChecked(false);
                    } else {
                    }
                },
            );
        }
    };

    const handlePreview = (idCard) => {
        setPreviewShow(true);
        setCurrentId(idCard);
    };

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('cards')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    <ExportDataBtn body={criteria} option={option} />
                    {permission?.includes(permissionObj?.WEB_CARD_CREATE) && (
                        <CreateCardBtn
                            body={criteria}
                            option={option}
                            loaded={loaded}
                            setLoaded={setLoaded}
                            creationErrorsShow={creationErrorsShow}
                            setCreationErrorsShow={setCreationErrorsShow}
                        />
                    )}
                    <AddTemplate
                        loaded={loaded}
                        setLoaded={setLoaded}
                        creationErrorsShow={creationErrorsShow}
                        setCreationErrorsShow={setCreationErrorsShow}
                    />
                    {permission?.includes(permissionObj?.WEB_CARD_DELETE) && (
                        <button
                            className='delete-btn-1'
                            disabled={selectCardForDelete?.length === 0}
                            id='btn_cp_ddelete'
                            onClick={() => {
                                setDeleteCardShow(true);
                            }}
                        >
                            <i className='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}
                    <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                </div>
            </div>
            {
                <div className='panelTables animated-div px-1' ref={elementRef}>
                    {searchByFilters?.content?.length > 0 ? (
                        <table className='w-100'>
                            <thead className='no-border-thead'>
                                {permission?.includes(permissionObj?.WEB_CARD_DELETE) && (
                                    <th className='first_head' style={{ width: '50px' }}>
                                        <Tooltip
                                            title={t('de_/_select_all').toUpperCase()}
                                            placement='top'
                                            disableInteractive
                                        >
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={isAllChecked}
                                                onChange={handelDeleteAll}
                                                size='small'
                                            />
                                        </Tooltip>
                                    </th>
                                )}
                                <th className='first_head'>{t('number')}</th>
                                <th>{t('user')}</th>
                                <th>{t('facility_code')}</th>
                                <th>{t('format')}</th>
                                <th>{t('created_at')}</th>
                                <th>{t('assign')}</th>
                                <th className='last'>{t('preview')}</th>
                            </thead>
                            {searchByFilters?.content?.length > 0 &&
                                searchByFilters?.content?.map((item) => {
                                    return (
                                        <tr key={item?.id}>
                                            {permission?.includes(permissionObj?.WEB_CARD_DELETE) && (
                                                <td
                                                    className='first'
                                                    style={{ verticalAlign: 'middle', width: '50px' }}
                                                >
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={selectCardForDelete?.includes(item?.id)}
                                                        id={item?.id}
                                                        onChange={handleCheckboxChange}
                                                        size='small'
                                                    />
                                                </td>
                                            )}
                                            <td className='first'>{item?.number}</td>
                                            <td>{item?.userFullName ? item.userFullName : '-'}</td>
                                            <td>{item?.facilityCode}</td>
                                            <td>{item?.cardTypeName}</td>
                                            <td>
                                                {lCode === 'en' ? (
                                                    <span>
                                                        {moment(item?.createdAt).format('MM/DD/YYYY')}
                                                        <br />
                                                        {moment(item?.createdAt).format('HH:mm:ss')}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {moment(item?.createdAt).format('DD/MM/YYYY')}
                                                        <br />
                                                        {moment(item?.createdAt).format('HH:mm:ss')}
                                                    </span>
                                                )}
                                            </td>

                                            <td className='tableIcon'>
                                                <Tooltip
                                                    title={
                                                        item?.userId !== null
                                                            ? t('unassign').toUpperCase()
                                                            : t('assign').toUpperCase()
                                                    }
                                                    placement='top'
                                                    arrow
                                                    disableInteractive={true}
                                                >
                                                    <button
                                                        className='btn-option'
                                                        onClick={() => {
                                                            if (item.userId !== null) {
                                                                setCardToUnassign([
                                                                    { cardId: item?.id, userId: item?.userId },
                                                                ]);
                                                                setUnassignModalShow(true);
                                                            } else {
                                                                setAssignCardShow(true);
                                                                setCardToAssign({
                                                                    id: item?.id,
                                                                    number: item?.number,
                                                                    facilityCode: item?.facilityCode,
                                                                    cardTypeName: item?.cardTypeName,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {item.userId !== null ? (
                                                            <UnlinkIcon fill={colors.grey} />
                                                        ) : (
                                                            <LinkIcon fill={colors.grey} />
                                                        )}
                                                    </button>
                                                </Tooltip>
                                            </td>

                                            <td>
                                                {item.raw ? (
                                                    <Tooltip title={t('card_preview').toUpperCase()} placement='top'>
                                                        <img
                                                            onClick={() => handlePreview(item.id)}
                                                            src={eyeIcon}
                                                            alt={t('preview')}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                        </tr>
                                    );
                                    // }
                                })}
                        </table>
                    ) : (
                        <NotInformation text={t('no_information')} card={true} />
                    )}
                </div>
            }

            <DeleteModal
                show={unassignModalShow}
                onHide={() => setUnassignModalShow(false)}
                // onClickFn={() => deleteSelectedDevices(selectDeviceForDelete)}
                onClickFn={() => unassignCard(cardToUnassign)}
                data={cardToUnassign}
                title_modal={t('unassign_card')}
                description={'are_you_sure_you_want_to_unassign'}
                element_modal={t('card')}
            />
            <DeleteModal
                show={deleteCardShow}
                onHide={() => setDeleteCardShow(false)}
                onClickFn={() => deleteSelectedCard(selectCardForDelete)}
                data={selectCardForDelete}
                title_modal={t('delete_cards')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('cards')}
            />
            <SearchFor
                open={filterDialogShow}
                onClose={() => {
                    setFilterDialogShow(false);
                }}
                onFiltered={(originalArray) => {
                    setFilterDialogShow(false);
                    setFinalArray(originalArray);
                }}
                moduleId={moduleId}
                option={option}
                finalArray={finalArray}
            />
            <CreationErrorsModal show={creationErrorsShow} onHide={() => setCreationErrorsShow(false)} cards={true} />
            <AssignCardModal
                open={assignCardShow}
                cardToAssign={cardToAssign}
                loaded={loaded}
                setLoaded={setLoaded}
                creationErrorsShow={creationErrorsShow}
                setCreationErrorsShow={setCreationErrorsShow}
                onHide={() => setAssignCardShow(false)}
            />
            {previewShow && (
                <CardPreview
                    open={previewShow}
                    cardToAssign={cardToAssign}
                    loaded={loaded}
                    setLoaded={setLoaded}
                    creationErrorsShow={creationErrorsShow}
                    setCreationErrorsShow={setCreationErrorsShow}
                    previewContent={previewContent}
                    currentId={currentId}
                    onHide={() => setPreviewShow(false)}
                />
            )}

            {searchByFilters?.content?.length > 0 && (
                <div className='d-flex justify-content-center'>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[20, 40, 60]}
                        count={searchByFilters?.totalElements}
                        page={page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage={t('cards_per_page')}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}
        </>
    );
};

export default DevicesPanel;
