import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import deleteIcon from '../assets/images/ic-delete-red.svg';
import { updateAllEmployees, updateSelectedEmployees } from '../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import {
    CreateUserZoneList,
    DeleteZoneUser,
    ZoneDetailAuthorizedEmployeeNoPagination,
} from '../reduxToolkit/EmployeeZones/EmployeeZonesApi';
// import { updateAllEmployees, updateSelectedEmployees } from '../../../../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Box, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutoCompleteSearch from '../components/AutoCompleteSearch';
import {
    allVehicleByContractorId,
    byUserId,
    currentContract,
    listofcontractorEmployees,
    listofEmployeWorkOnContract,
} from '../reduxToolkit/Contractor/ContractorSlice';
import {
    CreatelistofContractorEmployeeContract,
    DeleteEmployeeContractByContractAndUserID,
    GetAllVehicleByContractorId,
    GetEmployeOnContractById,
    GetlistofEmployeWorkOnContract,
    VehicleWorkOnContract,
} from '../reduxToolkit/Contractor/ContractorApi';
import {
    AddVehicleWithContractId,
    DeleteVehicleWorkOnContract,
    GetVehicleOnContractById,
} from '../reduxToolkit/Contractor/ContractorApi';
import {
    DeleteEmployeeByContractAndUserList,
    DeleteVehicleByContractAndVehicleList,
} from '../reduxToolkit/Contractor/ContractorApi';
import cancel from '../assets/images/ic-cancel.svg';

const AddRemoveItemsModal = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    // const allEmployeesData = useSelector(state => state?.EmployeeEventsSlice?.allEmployees);
    const { zoneDetailAuthorizedEmployeeNoPagination } = useSelector((state) => state.EmployeeZonesSlice);
    const [query, setQuery] = useState('');
    const [addUserquery, setAddUserQuery] = useState('');
    const [employeesWithRole, setEmployeesWithRole] = useState([]);
    const [totalItems, setTotalItems] = useState([]);

    const filterItems = (allItems, addedItems) => {
        const addedIds = addedItems.map((item) => item.id);
        return allItems.filter((item) => !addedIds.includes(item.id));
    };

    const handleDelete = (dId) => {
        let data;

        switch (props.containerType) {
            case 'zone':
                data = {
                    userIds: [dId], //props?.deleteData,
                    zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                        JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
                    ].id,
                };
                dispatch(DeleteZoneUser(data));
                break;
            case 'contract':
                if (props.mainTitle === 'employees') {
                    data = {
                        userIds: [dId], //props?.deleteData,
                        contractId: props.containerId,
                    };
                    // dispatch(DeleteEmployeeContractByContractAndUserID(data))
                    dispatch(DeleteEmployeeByContractAndUserList(data)).then(
                        ({
                            payload: {
                                data: { data, success },
                            },
                        }) => {
                            if (success === true) {
                                // toast.success(t('has_been_added_successfully'));
                                props.onUpdated();
                            }
                        },
                    );
                } else if (props.mainTitle === 'vehicles') {
                    data = {
                        vehicleIds: [dId], //props?.deleteData,
                        contractId: props.containerId,
                    };
                    // dispatch(DeleteEmployeeContractByContractAndUserID(data))
                    dispatch(DeleteVehicleByContractAndVehicleList(data)).then(
                        ({
                            payload: {
                                data: { data, success },
                            },
                        }) => {
                            if (success === true) {
                                // toast.success(t('has_been_added_successfully'));
                                props.onUpdated();
                            }
                        },
                    );
                }
                break;
            case 'case3':
                data = {
                    userIds: [dId], //props?.deleteData,
                    zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                        JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
                    ].id,
                };
                break;
        }

        setQuery('');
    };

    useEffect(() => {
        if (props.show === false) {
            setAddUserQuery('');
        }
    }, [props.show]);

    const handleRemoveSelected = (element) => {
        setTotalItems(totalItems.filter((item) => item?.id !== element.id));
    };

    //  add user to workshift
    const handleAddUser = () => {
        const all_items = totalItems?.map((item) => item?.id);
        if (all_items) {
            let data;

            switch (props.containerType) {
                case 'zone':
                    data = {
                        list: all_items,
                        zoneId: sessionStorage?.getItem('zonesStackId'),
                    };
                    break;
                case 'contract':
                    if (props.mainTitle === 'employees') {
                        data = {
                            userIds: all_items,
                            contractId: props.containerId,
                        };
                        dispatch(CreatelistofContractorEmployeeContract(data)).then(
                            ({
                                payload: {
                                    data: { data, success },
                                },
                            }) => {
                                if (success === true) {
                                    toast.success(t('has_been_added_successfully'));
                                    props.onUpdated();
                                }
                            },
                        );
                    } else if (props.mainTitle === 'vehicles') {
                        data = {
                            vehicleIds: all_items,
                            contractId: props.containerId,
                        };
                        dispatch(AddVehicleWithContractId(data)).then(
                            ({
                                payload: {
                                    data: { data, success },
                                },
                            }) => {
                                if (success === true) {
                                    toast.success(t('has_been_added_successfully'));
                                    props.onUpdated();
                                }
                            },
                        );
                    }
                    break;
                case 'case3':
                    data = {
                        list: all_items,
                        orderId: sessionStorage?.getItem('orderId'),
                    };
                    break;
            }

            props.onHide();
            setTotalItems([]);
            setAddUserQuery('');
        } else {
            props.onHide();
        }
    };
    // select for add user
    const handleselected = (element) => {
        const checkItem = props.itemDataTransform(props.addedItemsData)?.find((item) => {
            return item?.id === element?.id;
        });

        if (checkItem) {
            toast.warn(`${checkItem?.label} ${t('is_already_added')}`);
        } else {
            const selectedElement = totalItems?.find((item) => item?.id === element?.id);

            if (selectedElement) {
                toast.warn(`${selectedElement?.label} ${t('is_already_selected')}`);
                setAddUserQuery('');
            } else {
                setAddUserQuery('');
                setTotalItems([...totalItems, element]);
            }
        }
    };

    return (
        <Modal
            className='manage-role-panel-modal'
            {...props}
            // size="sm"
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter' style={{ fontSize: '20px', letterSpacing: '7px' }}>
                    {t(props.mainTitle)}
                </Modal.Title>
                <i className='fa fa-times cross fa-2x' aria-hidden='true' onClick={() => props.onHide()}></i>
                <div className='modal-close-custom top-0' style={{ marginTop: '-8px' }}>
                    <i
                        className='closeZoneModal fa fa-times cross fa-2x'
                        aria-hidden='true'
                        onClick={() => props.onHide()}
                    ></i>
                </div>
            </Modal.Header>
            <Modal.Body className='manage_role_modal_body'>
                <div className='row shiftmanagement_modal'>
                    <div className='text_field'>
                        <div className='mt-3 title' style={{ color: '#146F62' }}>
                            {t(props.addTitle)}
                        </div>
                        <Box
                            className='mt-2 mb-2'
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                fontSize: '20px',

                                background: '#FCFCFC 0% 0% no-repeat padding-box',
                            }}
                        >
                            <AutoCompleteSearch
                                data={props.itemDataTransform(filterItems(props.allItemsData, props.addedItemsData))}
                                handleFn={handleselected}
                                value={addUserquery}
                                setValue={setAddUserQuery}
                            />
                        </Box>

                        <div className='main_content'>
                            <div className='mt-2 mb-2  pt-2 user_text'>
                                {totalItems.map((item) => (
                                    <div className='d-flex justify-content-between pr-2'>
                                        <div className='badge_container'>
                                            <div className='c_badge'></div>
                                            <p
                                                style={{
                                                    textTransform: 'none',
                                                }}
                                            >
                                                {item?.label}
                                            </p>
                                        </div>

                                        <img
                                            className='delete-icon-style'
                                            src={deleteIcon}
                                            style={{
                                                color: 'red',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveSelected(item)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <p className='title pt-3'> {t(props.removeTitle)}</p>
                        <Box
                            className='mt-2 mb-2'
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                fontSize: '20px',
                                height: '40px',
                                background: '#FCFCFC 0% 0% no-repeat padding-box',
                            }}
                        >
                            <TextField
                                size='small'
                                fullWidth
                                disabled={props.addedItemsData?.length === 0}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                label={t('search')}
                                id='Search'
                                className=''
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <div className='main_content'>
                            <div className='body'>
                                {props
                                    .itemDataTransform(props.addedItemsData)
                                    ?.filter((element) => {
                                        if (query === '') {
                                            return element;
                                        } else if (element.label.toLowerCase().includes(query.toLowerCase())) {
                                            return element;
                                        }
                                    })
                                    .map((item) => {
                                        // {AllByWorkShiftId?.map((item) => {

                                        return (
                                            <div className='d-flex justify-content-between pr-2'>
                                                <div className='badge_container'>
                                                    <div className='c_badge'></div>
                                                    <p
                                                        style={{
                                                            textTransform: 'none',
                                                        }}
                                                    >
                                                        {item?.label}
                                                    </p>
                                                </div>

                                                <img
                                                    className='delete-icon-style'
                                                    src={deleteIcon}
                                                    style={{
                                                        color: 'red',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleDelete(item.id)}
                                                    // onClick={() => {

                                                    //   setuserRemoveModal(true);
                                                    //   setDelId(item?.userId);
                                                    // }}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className='btn-div'>
                        <button
                            className='btn_cancel_background_gray_hover'
                            style={{ width: '100%' }}
                            onClick={props.onHide}
                        >
                            {t('cancel')}
                        </button>
                        <button className='custom_primary_btn_dark' style={{ width: '100%' }} onClick={handleAddUser}>
                            {t('apply_changes')?.toUpperCase()}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddRemoveItemsModal;
