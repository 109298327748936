import React, { useEffect, useState, useRef, Fragment } from 'react';
import {
    Dialog,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { TABLES } from '../../../../../Apis/Tables';

import useStyle from '../../../../../hooks/useStyle';

import { Circle, Image, Layer, Rect, Stage, Text } from 'react-konva';
import { Link } from 'react-router-dom';
import NotInformation from '../../../../../components/NotInformation';
import { SearchByFilters, SearchTemplates } from '../../../../../reduxToolkit/Search/SearchApi';
import { AssignTemplateToCards } from '../../../../../reduxToolkit/Cards/CardsApi';
import { assign } from 'lodash';

const AssignCardModal = ({ open, assignCardShow, cardToAssign, loaded, onClose, setLoaded }) => {
    const { searchTemplates } = useSelector((state) => state.SearchSlice);

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch();
    const { smallBoxStyle, textFieldCommon } = useStyle();
    const { selectedCardsR } = useSelector((state) => state.CardsSlice);

    const { getAllCardFormats } = useSelector((state) => state.CardsSlice);

    // use state hook  for local state managment
    const [checked, setChecked] = useState([]);
    const [template, setTemplate] = useState('');
    const [emptyFields, setEmptyFields] = useState(false);
    const [assign, setAssign] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [searchResults, setSearchResults] = useState('');

    // Pagination

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(11);
    const [orderby, setOrderby] = useState('id');
    const [toggleState, setToggleState] = useState(1);
    const [searchResult, setSearchResult] = useState(null);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage]);
    let contractPagination = {
        order: true,
        page: page,
        size: rowsPerPage,
        sortBy: orderby,
    };
    const handleTemplateChange = (event) => {
        const selectedId = event.target.value;
        setTemplate(selectedId);
        const selected = searchTemplates.content.find((item) => item.id === selectedId);
        setSelectedTemplate(selected);
    };

    const resetForm = () => {
        setTemplate('');
    };
    const stageFront = useRef();
    const stageBack = useRef();

    const cardBackgroundColor = 'white';
    let resultsToShow;
    if (
        !searchResults ||
        searchResults?.content?.length === 0 ||
        searchResults === null ||
        searchResults === undefined
    ) {
        if (!searchResults) {
            resultsToShow = searchTemplates;
        } else {
            resultsToShow = null;
        }
    } else if (searchResults?.content?.length > 0) {
        resultsToShow = searchResults;
    }
    useEffect(() => {
        if (!open) return;

        let option = '';
        if (toggleState === 1) {
            option = TABLES.TEMPLATE;
        } else if (toggleState === 2) {
            option = TABLES.RESOURCES;
        }

        const body = {
            pagination: {
                page: page,
                size: rowsPerPage,
            },
            searchCriteriaList: [],
        };

        dispatch(SearchTemplates({ option, body }))
            .then((response) => {
                setSearchResult(response.data);
                setLoaded(true);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [open, toggleState, dispatch]);

    //asignar
    const handleAssign = () => {
        if (selectedTemplate && selectedTemplate.id) {
            const body = {
                cardIds: selectedCardsR,
                templateId: selectedTemplate.id,
            };
            // Llamada para asignar el template a las tarjetas
            dispatch(AssignTemplateToCards(body))
                .then(
                    ({
                        payload: {
                            data: { success },
                        },
                    }) => {
                        if (success) {
                            if (typeof setLoaded === 'function') {
                                setLoaded(true);
                            }
                            onClose();
                        }
                    },
                )
                .catch((error) => {
                    console.error('Error', error);
                    setLoaded(true);
                    if (typeof setLoaded === 'function') {
                        setLoaded(true);
                    }
                    onClose();
                });
        }
    };
    const handleCancel = () => {
        onClose();
    };

    const cardWidth = 127;
    const cardHeight = 212.5;

    return (
        <Dialog className='add-workshift-enrollment-dialog' open={open} onClose={onClose} maxWidth='xs'>
            <DialogTitle className='dialog-title'>
                <p className='search-title'>{t('assign_template')}</p>
                <img onClick={() => onClose()} className='dialog-close-custom' src={cancel} alt='' />
                <p className='add-card-modal-text'>{t('now_choose_the_template')}</p>
            </DialogTitle>

            <DialogContent className='dialog-content mt-0 pt-0'>
                <div className='pt-3 row pr-0'>
                    <h2 className='total-cards'>{t('total_cards')}</h2>
                    <p>{selectedCardsR ? selectedCardsR.length : 0}</p>
                    <Box className='add_provider_text_field required-data-text-form mt-4'>
                        <FormControl fullWidth required>
                            <InputLabel id='template'>{t('template')}</InputLabel>
                            <Select
                                size='small'
                                labelId='template'
                                id='slt_atm_template'
                                label={t('template')}
                                value={template}
                                onChange={handleTemplateChange}
                            >
                                {searchTemplates?.content?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText className='required-data-text-form'>{t('selectOptionText')}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box className='cars_side'>
                        {/* <div className='d-flex flex-wrap px-0 mx-0 mt-3'> */}
                        {selectedTemplate ? (
                            <>
                                <div className='col-6 preview'>
                                    <div>
                                        <p className='preview-tilte font-weight-bolder text-center'>
                                            {t('front_side')}
                                        </p>
                                    </div>
                                    <div className='card-container'>
                                        <Stage
                                            width={cardWidth + 25}
                                            height={cardHeight + 25}
                                            scaleX={0.5}
                                            scaleY={0.5}
                                        >
                                            <Layer>
                                                <Rect
                                                    id='background'
                                                    width={cardWidth}
                                                    height={cardHeight}
                                                    fill={cardBackgroundColor}
                                                />
                                                {JSON.parse(selectedTemplate.raw)[1].combinedObject.map(
                                                    (shape, index) => {
                                                        if (
                                                            shape.property === 'squares' ||
                                                            shape.property === 'lines'
                                                        ) {
                                                            return (
                                                                <Rect
                                                                    key={index}
                                                                    x={shape.x}
                                                                    y={shape.y}
                                                                    rotation={shape.rotation}
                                                                    width={shape.width}
                                                                    height={shape.height}
                                                                    cornerRadius={shape.cornerRadius}
                                                                    stroke={shape.stroke}
                                                                    strokeWidth={shape.strokeWidth}
                                                                    id={shape.id}
                                                                    fill={shape.fill}
                                                                />
                                                            );
                                                        } else if (shape.property === 'ellipsis') {
                                                            return (
                                                                <Circle
                                                                    key={index}
                                                                    x={shape.x - 20}
                                                                    y={shape.y - 10}
                                                                    rotation={shape.rotation}
                                                                    radius={shape.radius}
                                                                    fill={shape.fill}
                                                                    stroke={shape.stroke}
                                                                    strokeWidth={shape.strokeWidth}
                                                                    id={shape.id}
                                                                />
                                                            );
                                                        } else if (shape.property === 'texts') {
                                                            return (
                                                                <Text
                                                                    key={index}
                                                                    x={shape.x - 20}
                                                                    y={shape.y - 10}
                                                                    text={shape.content}
                                                                    fontSize={shape.fontSize}
                                                                    fontFamily={shape.fontFamily}
                                                                    fontStyle={shape.fontStyle}
                                                                    textDecoration={shape.textDecoration}
                                                                    fill={shape.fill}
                                                                    align={shape.align}
                                                                    width={shape.width}
                                                                    height={shape.height}
                                                                    wrap={true}
                                                                    id={shape.id}
                                                                />
                                                            );
                                                        } else if (shape.property === 'resources') {
                                                            const imageObject = new window.Image();
                                                            imageObject.src = shape.base64;
                                                            return (
                                                                <Image
                                                                    key={index}
                                                                    image={imageObject}
                                                                    x={shape.x - 20}
                                                                    y={shape.y - 15}
                                                                    rotation={shape.rotation}
                                                                    width={shape.width}
                                                                    height={shape.height}
                                                                    id={shape.id}
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    },
                                                )}
                                            </Layer>
                                        </Stage>
                                    </div>
                                </div>
                                <div className='col-6 preview'>
                                    <p className='preview-tilte font-weight-bolder text-center'>{t('back_side')}</p>
                                    <div className='card-container'>
                                        <Stage
                                            width={cardWidth + 25}
                                            height={cardHeight + 25}
                                            scaleX={0.5}
                                            cornerRadius={10}
                                            scaleY={0.5}
                                        >
                                            <Layer>
                                                <Rect
                                                    id='background'
                                                    width={cardWidth}
                                                    height={cardHeight}
                                                    fill={cardBackgroundColor}
                                                />
                                                {JSON.parse(selectedTemplate.raw)[2].combinedObject.map(
                                                    (shape, index) => {
                                                        if (
                                                            shape.property === 'squares' ||
                                                            shape.property === 'lines'
                                                        ) {
                                                            return (
                                                                <Rect
                                                                    key={index}
                                                                    x={shape.x}
                                                                    y={shape.y}
                                                                    rotation={shape.rotation}
                                                                    width={shape.width}
                                                                    height={shape.height}
                                                                    cornerRadius={shape.cornerRadius}
                                                                    stroke={shape.stroke}
                                                                    strokeWidth={shape.strokeWidth}
                                                                    id={shape.id}
                                                                    fill={shape.fill}
                                                                />
                                                            );
                                                        } else if (shape.property === 'ellipsis') {
                                                            return (
                                                                <Circle
                                                                    key={index}
                                                                    x={shape.x - 20}
                                                                    y={shape.y - 10}
                                                                    rotation={shape.rotation}
                                                                    radius={shape.radius}
                                                                    fill={shape.fill}
                                                                    stroke={shape.stroke}
                                                                    strokeWidth={shape.strokeWidth}
                                                                    id={shape.id}
                                                                />
                                                            );
                                                        } else if (shape.property === 'texts') {
                                                            return (
                                                                <Text
                                                                    key={index}
                                                                    x={shape.x - 20}
                                                                    y={shape.y - 10}
                                                                    text={shape.content}
                                                                    fontSize={shape.fontSize}
                                                                    fontFamily={shape.fontFamily}
                                                                    fontStyle={shape.fontStyle}
                                                                    textDecoration={shape.textDecoration}
                                                                    fill={shape.fill}
                                                                    align={shape.align}
                                                                    width={shape.width}
                                                                    height={shape.height}
                                                                    wrap={true}
                                                                    id={shape.id}
                                                                />
                                                            );
                                                        } else if (shape.property === 'resources') {
                                                            const imageObject = new window.Image();
                                                            imageObject.src = shape.base64;
                                                            return (
                                                                <Image
                                                                    key={index}
                                                                    image={imageObject}
                                                                    x={shape.x - 20}
                                                                    y={shape.y - 15}
                                                                    rotation={shape.rotation}
                                                                    width={shape.width}
                                                                    height={shape.height}
                                                                    id={shape.id}
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    },
                                                )}
                                            </Layer>
                                        </Stage>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <NotInformation text={t('no_information')} card={true} />
                        )}
                    </Box>
                </div>

                <div>
                    <div className='create-enrollment-footer mt-3 row pr-2'>
                        <button
                            onClick={handleCancel}
                            className='btn_cancel_background_gray_hover col-6'
                            style={{ color: '#BC0000' }}
                            id='btn_atm_cancel'
                        >
                            {t('cancel')}
                        </button>
                        <button id='btn_atm_assing' className='custom_primary_btn_dark col-6' onClick={handleAssign}>
                            {t('assign').toUpperCase()}
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AssignCardModal;
const inputBox = {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    height: '50px',
};
