import { Box, TextField } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import chevron_right_solid from '../../../../../assets/images/chevron-right-solid.svg';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import icLaptop from '../../../../../assets/images/ic-laptop.svg';
import mobileIcon from '../../../../../assets/icon/ic-mobile.svg';

function searchVar(stringVar) {
    if (stringVar.toUpperCase().includes('READ')) {
        return 'PRIVILEGE_READ';
    } else if (stringVar.toUpperCase().includes('DELETE')) {
        return 'PRIVILEGE_DELETE';
    } else if (stringVar.toUpperCase().includes('UPDATE')) {
        return 'PRIVILEGE_UPDATE';
    } else if (stringVar.toUpperCase().includes('CREATE')) {
        return 'PRIVILEGE_CREATE';
    } else if (stringVar.toUpperCase().includes('CANCEL')) {
        return 'PRIVILEGE_CANCEL';
    } else if (stringVar.toUpperCase().includes('EXPORT')) {
        return 'PRIVILEGE_EXPORT';
    }

    return 'no: ' + stringVar;
}

const DraggableBox = ({ column, type, index, children, className, selected, check }) => {
    const [{ isDragging }, drag] = useDrag({
        type,
        item: { column, index, selected, check },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <div ref={drag} className={className}>
            {children}
        </div>
    );
};

const AvailableRole = ({ data, mobileAvailableList, websiteAvailableList, onHandler }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleRoles = (role) => {
        onHandler(role);
    };
    return (
        <div>
            <Accordion>
                <Accordion.Item eventKey='0' className='outerAccordionItem'>
                    <Accordion.Header>
                        {t('app')} <sub>{`(${mobileAvailableList?.length})`}</sub> <img src={mobileIcon} alt='' />
                    </Accordion.Header>
                    <Accordion.Body className='mainAccordionBody'>
                        <Accordion id='1'>
                            {/* @company */}
                            {mobileAvailableList.filter((item) => item.moduleId === 1).length > 0 ? (
                                <Accordion.Item eventKey={'1'} key='1' className='innerAccordionItem'>
                                    <Accordion.Header>{t('company')?.toUpperCase()}</Accordion.Header>
                                    {mobileAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 1 && role?.isMobileApp) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 2,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={2}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @profile */}
                            {mobileAvailableList.filter((item) => item.moduleId === 5).length > 0 ? (
                                <Accordion.Item eventKey={'5'} key='5' className='innerAccordionItem'>
                                    <Accordion.Header>{t('profile')?.toUpperCase()}</Accordion.Header>
                                    {mobileAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 5 && role?.isMobileApp) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 2,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={2}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @announcement */}
                            {mobileAvailableList.filter((item) => item.moduleId === 4).length > 0 ? (
                                <Accordion.Item eventKey={'4'} key='4' className='innerAccordionItem'>
                                    <Accordion.Header>{t('announcements')?.toUpperCase()}</Accordion.Header>
                                    {mobileAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 4 && role?.isMobileApp) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 2,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={2}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @Event */}
                            {mobileAvailableList.filter((item) => item.moduleId === 3).length > 0 ? (
                                <Accordion.Item eventKey={'3'} key='3' className='innerAccordionItem'>
                                    <Accordion.Header>{t('event')?.toUpperCase()}</Accordion.Header>
                                    {mobileAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 3 && role?.isMobileApp) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 2,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={2}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @invitation*/}
                            {mobileAvailableList.filter((item) => item.moduleId === 2).length > 0 ? (
                                <Accordion.Item eventKey={'2'} key='2' className='innerAccordionItem'>
                                    <Accordion.Header>{t('invitation')?.toUpperCase()}</Accordion.Header>
                                    {mobileAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 2 && role?.isMobileApp) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 2,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={2}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @device */}
                            {mobileAvailableList.filter((item) => item.moduleId === 17).length > 0 ? (
                                <Accordion.Item eventKey={'17'} key='17' className='innerAccordionItem'>
                                    <Accordion.Header>{t('devices')?.toUpperCase()}</Accordion.Header>
                                    {mobileAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 17 && role?.isMobileApp) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 2,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={2}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* work_offce */}
                            {mobileAvailableList.filter((item) => item.moduleId === 20).length > 0 ? (
                                <Accordion.Item eventKey={'20'} key='20' className='innerAccordionItem'>
                                    <Accordion.Header>{t('work_office')?.toUpperCase()}</Accordion.Header>
                                    {mobileAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 20 && role?.isMobileApp) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 2,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={2}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {mobileAvailableList.length === 0 ? <p>{t('PRIVILEGE_ALL')}</p> : <span></span>}
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' className='outerAccordionItem'>
                    <Accordion.Header>
                        {t('web_app')} <sub>{`(${websiteAvailableList?.length})`}</sub> <img src={icLaptop} alt='' />
                    </Accordion.Header>
                    <Accordion.Body className='mainAccordionBody'>
                        <Accordion id='1'>
                            {/* @company */}
                            {websiteAvailableList.filter((item) => item.moduleId === 1).length > 0 ? (
                                <Accordion.Item eventKey={'1'} key='1' className='innerAccordionItem'>
                                    <Accordion.Header>{t('company')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 1 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @profile */}
                            {websiteAvailableList.filter((item) => item.moduleId === 5).length > 0 ? (
                                <Accordion.Item eventKey={'5'} key='5' className='innerAccordionItem'>
                                    <Accordion.Header>{t('profile')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 5 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @employee */}
                            {websiteAvailableList.filter((item) => item.moduleId === 9).length > 0 ? (
                                <Accordion.Item eventKey={'9'} key='9' className='innerAccordionItem'>
                                    <Accordion.Header>{t('employee')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 9 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @employee */}
                            {websiteAvailableList.filter((item) => item.moduleId === 46).length > 0 ? (
                                <Accordion.Item eventKey={'46'} key='46' className='innerAccordionItem'>
                                    <Accordion.Header>{t('controller')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 46 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id}
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @document employee */}
                            {websiteAvailableList.filter((item) => item.moduleId === 10).length > 0 ? (
                                <Accordion.Item eventKey={'10'} key='10' className='innerAccordionItem'>
                                    <Accordion.Header>{t('document_employee')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 10 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @document supplier*/}
                            {websiteAvailableList.filter((item) => item.moduleId === 11).length > 0 ? (
                                <Accordion.Item eventKey={'11'} key='11' className='innerAccordionItem'>
                                    <Accordion.Header>{t('document_supplier')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 11 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @document contractor */}
                            {websiteAvailableList.filter((item) => item.moduleId === 29).length > 0 ? (
                                <Accordion.Item eventKey={'29'} key='29' className='innerAccordionItem'>
                                    <Accordion.Header>{t('document_contractor')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 29 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @document supplier vehicle */}
                            {websiteAvailableList.filter((item) => item.moduleId === 12).length > 0 ? (
                                <Accordion.Item eventKey={'12'} key='12' className='innerAccordionItem'>
                                    <Accordion.Header>{t('document_supplier_vehicle')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 12 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @document contractor */}
                            {websiteAvailableList.filter((item) => item.moduleId === 30).length > 0 ? (
                                <Accordion.Item eventKey={'30'} key='30' className='innerAccordionItem'>
                                    <Accordion.Header>
                                        {t('document_contractor_vehicle')?.toUpperCase()}
                                    </Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 30 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @Workshif */}
                            {websiteAvailableList.filter((item) => item.moduleId === 13).length > 0 ? (
                                <Accordion.Item eventKey={'13'} key='13' className='innerAccordionItem'>
                                    <Accordion.Header>{t('workshift')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 13 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @previllage */}
                            {websiteAvailableList.filter((item) => item.moduleId === 14).length > 0 ? (
                                <Accordion.Item eventKey={'14'} key='14' className='innerAccordionItem'>
                                    <Accordion.Header>{t('privillage')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 14 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @vehicle */}
                            {websiteAvailableList.filter((item) => item.moduleId === 15).length > 0 ? (
                                <Accordion.Item eventKey={'15'} key='15' className='innerAccordionItem'>
                                    <Accordion.Header>{t('vehicle')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 15 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @document contractor */}
                            {websiteAvailableList.filter((item) => item.moduleId === 16).length > 0 ? (
                                <Accordion.Item eventKey={'16'} key='16' className='innerAccordionItem'>
                                    <Accordion.Header>{t('zones')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 16 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @device */}
                            {websiteAvailableList.filter((item) => item.moduleId === 17).length > 0 ? (
                                <Accordion.Item eventKey={'17'} key='17' className='innerAccordionItem'>
                                    <Accordion.Header>{t('device')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 17 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @reports */}
                            {websiteAvailableList.filter((item) => item.moduleId === 18).length > 0 ? (
                                <Accordion.Item eventKey={'18'} key='18' className='innerAccordionItem'>
                                    <Accordion.Header>{t('reports')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 18 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @backup */}
                            {websiteAvailableList.filter((item) => item.moduleId === 19).length > 0 ? (
                                <Accordion.Item eventKey={'19'} key='19' className='innerAccordionItem'>
                                    <Accordion.Header>{t('backup')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 19 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @payroll */}
                            {/* <Accordion.Item
                                    eventKey={"20"}
                                    key="20"
                                    className="innerAccordionItem"
                                >
                                    <Accordion.Header>{t("payroll")?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map(role => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 20 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() => handleRoles({
                                                        selected: role,
                                                        check: 1

                                                    })}
                                                >
                                                    <span>{t(role?.name)}</span>
                                                    <img
                                                        src={chevron_right_solid}
                                                        alt="chevron_right_solid"
                                                    />
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })
                                    }
                                </Accordion.Item> */}
                            {/* @onBoarding */}
                            {websiteAvailableList.filter((item) => item.moduleId === 21).length > 0 ? (
                                <Accordion.Item eventKey={'21'} key='21' className='innerAccordionItem'>
                                    <Accordion.Header>{t('onboarding')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 21 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @work_office */}
                            {websiteAvailableList.filter((item) => item.moduleId === 20).length > 0 ? (
                                <Accordion.Item eventKey={'20'} key='20' className='innerAccordionItem'>
                                    <Accordion.Header>{t('work_office')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 20 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @hospitality */}
                            {websiteAvailableList.filter((item) => item.moduleId === 22).length > 0 ? (
                                <Accordion.Item eventKey={'22'} key='22' className='innerAccordionItem'>
                                    <Accordion.Header>{t('hospitality')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 22 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <span>{t(searchVar(role?.name))}</span>
                                                    <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @email setting */}
                            {websiteAvailableList.filter((item) => item.moduleId === 23).length > 0 ? (
                                <Accordion.Item eventKey={'23'} key='23' className='innerAccordionItem'>
                                    <Accordion.Header>{t('email_settings')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 23 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @announcement */}
                            {websiteAvailableList.filter((item) => item.moduleId === 4).length > 0 ? (
                                <Accordion.Item eventKey={'4'} key='4' className='innerAccordionItem'>
                                    <Accordion.Header>{t('announcements')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 4 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @web access log */}
                            {websiteAvailableList.filter((item) => item.moduleId === 28).length > 0 ? (
                                <Accordion.Item eventKey={'28'} key='28' className='innerAccordionItem'>
                                    <Accordion.Header>{t('web_access_log')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 28 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @log */}
                            {websiteAvailableList.filter((item) => item.moduleId === 8 || item.moduleId === 44).length >
                            0 ? (
                                <Accordion.Item eventKey={'8'} key='8' className='innerAccordionItem'>
                                    <Accordion.Header>{t('log_action')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (
                                            (role?.moduleId === 8 || role?.moduleId === 44) &&
                                            role?.isMobileApp == false
                                        ) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {websiteAvailableList.filter((item) => item.moduleId === 50 || item.moduleId === 43)
                                .length > 0 ? (
                                <Accordion.Item eventKey={'50'} key='50' className='innerAccordionItem'>
                                    <Accordion.Header>{t('log_platform')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (
                                            (role?.moduleId === 50 || role?.moduleId === 43) &&
                                            role?.isMobileApp == false
                                        ) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @access history */}
                            {/* <Accordion.Item
                                    eventKey={"24"}
                                    key="24"
                                    className="innerAccordionItem"
                                >
                                    <Accordion.Header>{t("access_history")?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map(role => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 24 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() => handleRoles({
                                                        selected: role,
                                                        check: 1

                                                    })}
                                                >
                                                    <span>{t(role?.name)}</span>
                                                    <img
                                                        src={chevron_right_solid}
                                                        alt="chevron_right_solid"
                                                    />
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })
                                    }
                                </Accordion.Item> */}
                            {/* USER_ACCESS_LOG */}
                            {websiteAvailableList.filter((item) => item.moduleId === 27).length > 0 ? (
                                <Accordion.Item eventKey={'27'} key='27' className='innerAccordionItem'>
                                    <Accordion.Header>{t('user_access_log')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 27 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @supplier */}
                            {websiteAvailableList.filter((item) => item.moduleId === 7).length > 0 ? (
                                <Accordion.Item eventKey={'7'} key='7' className='innerAccordionItem'>
                                    <Accordion.Header>{t('supplier')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 7 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @contractor */}
                            {websiteAvailableList.filter((item) => item.moduleId === 6).length > 0 ? (
                                <Accordion.Item eventKey={'6'} key='6' className='innerAccordionItem'>
                                    <Accordion.Header>{t('contractor')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 6 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(role?.name)}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @event */}
                            {websiteAvailableList.filter((item) => item.moduleId === 3).length > 0 ? (
                                <Accordion.Item eventKey={'3'} key='3' className='innerAccordionItem'>
                                    <Accordion.Header>{t('event')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 3 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @attendence */}
                            {websiteAvailableList.filter((item) => item.moduleId === 35).length > 0 ? (
                                <Accordion.Item eventKey={'35'} key='35' className='innerAccordionItem'>
                                    <Accordion.Header>{t('attendance')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 35 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @backup */}
                            {websiteAvailableList.filter((item) => item.moduleId === 31).length > 0 ? (
                                <Accordion.Item eventKey={'31'} key='31' className='innerAccordionItem'>
                                    <Accordion.Header>{t('department')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 31 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @CARD DESIGNER TEMPLATE */}
                            {websiteAvailableList.filter((item) => item.moduleId === 37).length > 0 ? (
                                <Accordion.Item eventKey={'37'} key='37' className='innerAccordionItem'>
                                    <Accordion.Header>{t('PRIVILEGE_CARD_DESIGNER')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 37 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @CARD MANAGEMENT */}
                            {websiteAvailableList.filter((item) => item.moduleId === 36).length > 0 ? (
                                <Accordion.Item eventKey={'36'} key='36' className='innerAccordionItem'>
                                    <Accordion.Header>{t('card_management')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 36 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @RESOURCES */}
                            {websiteAvailableList.filter((item) => item.moduleId === 38).length > 0 ? (
                                <Accordion.Item eventKey={'38'} key='38' className='innerAccordionItem'>
                                    <Accordion.Header>{t('PRIVILEGE_RESOURCES')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 38 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @HOLIDAY */}
                            {websiteAvailableList.filter((item) => item.moduleId === 34).length > 0 ? (
                                <Accordion.Item eventKey={'34'} key='34' className='innerAccordionItem'>
                                    <Accordion.Header>{t('PRIVILEGE_HOLIDAY')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 34 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {/* @GUARD */}
                            {websiteAvailableList.filter((item) => item.moduleId === 45).length > 0 ? (
                                <Accordion.Item eventKey={'45'} key='45' className='innerAccordionItem'>
                                    <Accordion.Header>{t('PRIVILEGE_GUARD')?.toUpperCase()}</Accordion.Header>
                                    {websiteAvailableList?.map((role) => {
                                        // Check if role.isSelected is false
                                        if (role?.moduleId === 45 && role?.isMobileApp == false) {
                                            return (
                                                <Accordion.Body
                                                    key={role.id} // Add a unique key prop for each item in the map() function
                                                    id={role.id}
                                                    onClick={() =>
                                                        handleRoles({
                                                            selected: role,
                                                            check: 1,
                                                        })
                                                    }
                                                >
                                                    <DraggableBox
                                                        column='Available'
                                                        type='box'
                                                        index={role.id}
                                                        className='infoDrop'
                                                        selected={role}
                                                        check={1}
                                                    >
                                                        <span>{t(searchVar(role?.name))}</span>
                                                        <img src={chevron_right_solid} alt='chevron_right_solid' />
                                                    </DraggableBox>
                                                </Accordion.Body>
                                            );
                                        } else {
                                            return null; // Return null if role.isSelected is true
                                        }
                                    })}
                                </Accordion.Item>
                            ) : (
                                <span></span>
                            )}
                            {websiteAvailableList.length === 0 ? <p>{t('PRIVILEGE_ALL')}</p> : <span></span>}
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default AvailableRole;
