import { useEffect, useState, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateEmailPhoneSearchList,
    updateOunEmployeeData,
} from '../../../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import { deleteEvents, validateEvent, cancelEvent } from '../../../reduxToolkit/EmployeeEvents/EmployeeEventsApi';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { permissionObj } from '../../../Helpers/permission';
import DeleteModal from '../../Modals/DeleteModal';
import GenericModal from '../../Modals/GenericModal';
import SettingButton from '../../../components/SettingButton';
import SearchFilterBtn from '../../../components/buttons/SearchFilterBtn';
import SearchFor from '../../Modals/SearchFor';
import { MODELS } from '../../../Apis/Models';
import { TABLES } from '../../../Apis/Tables';
import { SearchByFilters } from '../../../reduxToolkit/Search/SearchApi';
import { clearSearchFilters } from '../../../reduxToolkit/Search/SearchSlice';
import cryptoJs from 'crypto-js';
import securekey from '../../../../src/config';
import DropDownMenuProfile from '../../../components/DropDownMenuProfile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Tooltip from '@mui/material/Tooltip';
import NoEvent from './NoEvent';
import { status } from '../../../enums/statusEnum';
import TablePagination from '@mui/material/TablePagination';
import { Box } from '@mui/material';
import NotInformation from '../../../components/NotInformation';
import ExportDataBtn from '../../../components/buttons/ExportDataBtn';
import { t } from 'i18next';
import { toast } from 'react-toastify';

const Events = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyRestrictionsData = useSelector((state) => state?.EmployeeEventsSlice?.companyRestrictionsData);
    const { searchByFilters, searchByFiltersCustom } = useSelector((state) => state.SearchSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const userInfo = useSelector((state) => state.CommonsSlice.getUserFullInformation);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectEventForDelete, setSelectEventForDelete] = useState([]);
    const [selectedEventNames, setSelectedEventNames] = useState([]);
    const [deleteEventShow, setDeleteEventShow] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [criteria, setCriteria] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [showValidateModal, setShowValidateModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [eventSelected, setEventSelected] = useState(null);
    const token = sessionStorage.getItem('bearerToken');
    const bytes = cryptoJs.AES.decrypt(token, securekey);
    const bearerToken = bytes.toString(cryptoJs.enc.Utf8);
    let option = `${TABLES.EVENT}`;

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleViewDetails = (event) => {
        navigate(
            companyRestrictionsData?.isOnuEvent
                ? `/dashboard/employee/events/incomming-envent-detail/${event?.id}`
                : `/dashboard/employee/events/validation-event-detail/${event?.id}`,
        );
    };

    const handleDownload = (event) => {
        // dispatch(downloadOnuFile(event?.id))
        fetch(`${URL}file-service/download-report-onu/${event?.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                Authorization: `Bearer ${bearerToken}`,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'report.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };

    const handleCreateEvent = () => {
        dispatch(updateEmailPhoneSearchList([]));
        dispatch(updateOunEmployeeData([]));
        companyRestrictionsData?.isOnuEvent
            ? navigate('/dashboard/employee/events/onu-events')
            : navigate('/dashboard/employee/events/normal-events');
    };

    // this function control select all id or unSelect all
    const handleDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);

        if (e.target.checked) {
            const selectAllIds = searchByFilters?.content?.map((item) => {
                return item?.id;
            });
            setSelectEventForDelete(selectAllIds);
        } else {
            setSelectEventForDelete([]);
        }
    };

    // this function handle only specific id base on selection
    const handleCheckboxChange = (e, hostname) => {
        const id = e.target.id;

        if (e.target.checked) {
            setSelectEventForDelete([...selectEventForDelete, id]);
            setSelectedEventNames([...selectedEventNames, hostname]);
        } else {
            setSelectEventForDelete(selectEventForDelete.filter((eventId) => eventId !== id));
            setSelectedEventNames(selectedEventNames.filter((name) => name !== hostname));
        }
    };

    const handleDelete = () => {
        const body = selectEventForDelete;

        const isDifferentUser = selectedEventNames.some((name) => name !== userInfo.name);

        if (isDifferentUser) {
            toast.error(t('event_deletion_restriction'));
            return;
        }

        if (body.length > 0) {
            dispatch(deleteEvents(body)).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setDeletedFlag(true);
                        setSelectEventForDelete([]);
                        setIsAllChecked(false);
                        toast.success(t('Deleted Successfully'));
                    }
                },
            );
        }
    };

    const handleShowModal = (event) => {
        setEventSelected(event);
        setShowValidateModal(true);
    };

    const handleChangeStatus = (option) => {
        dispatch(
            validateEvent({
                id: eventSelected.id,
                option: option,
            }),
        ).then(() => {
            setShowValidateModal(false);
            setLoaded(true);
        });
    };

    const handleHideModal = () => {
        setShowValidateModal(false);
    };

    const handleShowCancelModal = (event) => {
        setEventSelected(event);
        setShowCancelModal(true);
    };

    const handleCloseCancelModal = () => {
        setShowCancelModal(false);
    };

    const handleCancelEvent = () => {
        dispatch(cancelEvent(eventSelected.id)).then(() => {
            setShowCancelModal(false);
            setLoaded(true);
        });
    };

    //This fragment calculates the top position of the elementRef
    const elementRef = useRef(null);

    //This fragment makes unchecked all the checkboxes when toggleState change
    const resetAllCheckboxes = () => {
        const checkboxes = document.querySelectorAll('.checkbox');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    };

    useEffect(() => {
        resetAllCheckboxes();
        setSelectEventForDelete([]);
        setIsAllChecked(false);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage, finalArray, deletedFlag]);

    //Create body to get the respectively search
    useEffect(() => {
        if (loaded) {
            const option = TABLES.EVENT;
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                    from: item.from,
                    to: item.to,
                };
            });

            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: criteriaList,
            };

            dispatch(SearchByFilters({ option, body }));
            setCriteria({ searchCriteriaList: criteriaList });
            setLoaded(false);
            setDeletedFlag(false);
        }
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray]);

    const validatedFullName = (event) => {
        const fullNameParts = [event.validatedByName, event.validatedByLastName, event.validatedBySecondLastName];
        const fullName = fullNameParts.filter(Boolean).join(' ');

        return fullName || '-';
    };

    const hostFullName = (event) => {
        const fullNameParts = [event.hostName, event.hostLastName, event.hostSecondLastName];
        const fullName = fullNameParts.filter(Boolean).join(' ');

        return fullName || '-';
    };

    const formattedDate = (timestamp) => {
        const date = new Date(timestamp);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    };

    const getColor = (status) => {
        const statusColor = {
            31: '#BC0000',
            32: '#F2A100 ',
            33: '#0C4523',
            34: '#006594',
        };

        return statusColor[status];
    };

    const convertDate = () => {
        if (eventSelected !== null) {
            const date = new Date(eventSelected.startDate);
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            const translatedMonth = t(`months.${month}`);

            return `${day}, ${translatedMonth} ${year}`;
        }
    };

    const convertHour = () => {
        if (eventSelected !== null) {
            const date = new Date(eventSelected.startDate);

            const hour = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const ampm = hour >= 12 ? 'PM' : 'AM';

            return `${hour}:${minutes} ${ampm}`;
        }
    };

    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        const currentUrl = window.location.pathname; // Get the current URL
        const parts = currentUrl.split('/');
        const modulePart = parts[3] ? parts[3] : '' + parts[4] ? '/' + parts[4] : '';
    }, []);
    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('events')}</h2>
                </div>

                <div className='container-top-right-btns'>
                    {permission?.includes(permissionObj?.WEB_EVENT_UPDATE) && (
                        <SettingButton
                            onAction={() => navigate('/dashboard/employee/event-restriction')}
                            title={t('restrictions').toUpperCase()}
                        />
                    )}
                    <ExportDataBtn body={criteria} option={option} />
                    {permission?.includes(permissionObj?.WEB_EVENT_CREATE) && (
                        <button className='add-btn-1' onClick={handleCreateEvent}>
                            <i class='fa fa-plus' aria-hidden='true'></i>
                            {t('add')}
                        </button>
                    )}

                    {permission?.includes(permissionObj?.WEB_EVENT_DELETE) && (
                        <button
                            className='delete-btn-1'
                            disabled={selectEventForDelete?.length === 0}
                            onClick={() => {
                                setDeleteEventShow(true);
                            }}
                        >
                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}

                    <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                </div>
            </div>
            <div className='panelTables animated-div px-1' ref={elementRef}>
                {searchByFilters?.content?.length !== 0 ? (
                    <>
                        <table className='w-100'>
                            <thead className='no-border-thead'>
                                <th className='first_head'>
                                    <Tooltip
                                        title={t('de_/_select_all').toUpperCase()}
                                        placement='top'
                                        disableInteractive
                                    >
                                        <input
                                            type='checkbox'
                                            className='checkbox'
                                            checked={isAllChecked}
                                            onChange={handleDeleteAll}
                                        />
                                    </Tooltip>
                                </th>
                                <th className='first_head'>{t('name')}</th>
                                <th>{t('host')}</th>
                                <th>{t('start_date')}</th>
                                <th>{t('end_date')}</th>
                                <th>{t('status')}</th>
                                <th>{t('reservation')}</th>
                                <th>{t('validated_by')}</th>
                                <th className='last'>{t('option')}</th>
                            </thead>
                            {searchByFilters?.content?.map((item) => (
                                <tr key={item.id}>
                                    <td className='first'>
                                        <input
                                            type='checkbox'
                                            className='checkbox'
                                            checked={selectEventForDelete.includes(item.id)}
                                            id={item.id}
                                            onChange={(e) => handleCheckboxChange(e, item.hostName)}
                                        />
                                    </td>
                                    <td className='first'>{item?.name ? item?.name : '-'}</td>
                                    <td>{hostFullName(item)}</td>
                                    <td>
                                        {formattedDate(item.startDate)} <br /> {item.reservationFromTime}
                                    </td>
                                    <td>
                                        {formattedDate(item.endDate)} <br /> {item.reservationToTime}
                                    </td>
                                    <td className='status-event' style={{ color: getColor(item?.statusId) }}>
                                        {t(status[item?.statusId])}
                                    </td>
                                    <td>{item.reservationZoneName}</td>
                                    <td>{validatedFullName(item)}</td>
                                    <td className='tableIcon'>
                                        <DropDownMenuProfile
                                            menuList={[
                                                companyRestrictionsData?.isOnuEvent && {
                                                    name: t('update_contractor'),
                                                    icon: <TextSnippetIcon fontSize='small' />,
                                                    onClick: () => handleDownload(item),
                                                },
                                                (item.statusId === 32 || item.statusId === 33) && {
                                                    name: t('allow_deny_event'),
                                                    icon: <TextSnippetIcon fontSize='small' />,
                                                    onClick: () => handleShowModal(item),
                                                },
                                                {
                                                    name: t('show_details'),
                                                    icon: <ListAltIcon fontSize='small' />,
                                                    onClick: () => handleViewDetails(item),
                                                },
                                                (item.statusId === 32 || item.statusId === 33) && {
                                                    name: t('cancel_event'),
                                                    icon: <DeleteOutlineIcon fontSize='small' />,
                                                    onClick: () => handleShowCancelModal(item),
                                                },
                                            ].filter(Boolean)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </>
                ) : (
                    <NotInformation text={t('no_information')} card={true} />
                )}
            </div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    width: '100%',

                    bottom: '0',
                    backgroundColor: 'white',
                }}
            >
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[20, 40, 60]}
                    count={searchByFilters?.totalElements}
                    page={page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage={t('events_per_page')}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            <Outlet />
            <DeleteModal
                show={deleteEventShow}
                onHide={() => setDeleteEventShow(false)}
                data={selectEventForDelete}
                title_modal={t('delete_events')}
                element_modal={t('events')}
                onClickFn={() => handleDelete(selectEventForDelete)}
            />
            <SearchFor
                open={filterDialogShow}
                onClose={() => {
                    setFilterDialogShow(false);
                }}
                onFiltered={(originalArray) => {
                    setFilterDialogShow(false);
                    setFinalArray(originalArray);
                }}
                moduleId={MODELS.Event}
                option={TABLES.EVENT}
                finalArray={finalArray}
            />
            <GenericModal
                title={t('allow_event')}
                description={
                    <div>
                        {t('allow_event_description_first', {
                            interpolation: { escapeValue: false },
                        }).toUpperCase()}{' '}
                        <span style={{ fontWeight: 600 }}>{eventSelected?.name}</span>{' '}
                        {t('allow_event_description_second', {
                            date: convertDate(),
                            hour: convertHour(),
                            interpolation: { escapeValue: false },
                        }).toUpperCase()}
                    </div>
                }
                show={showValidateModal}
                onClose={() => handleChangeStatus(34)}
                onSave={() => handleChangeStatus(33)}
                onHide={() => handleHideModal()}
                confirmButtonText={t('allow')}
                cancelButtonText={t('not_allow')}
            />
            <GenericModal
                title={t('cancel_event')}
                description={t('cancel_event_description')}
                show={showCancelModal}
                onClose={handleCloseCancelModal}
                onSave={handleCancelEvent}
                confirmButtonText={t('apply')}
                cancelButtonText={t('cancel')}
            />
        </>
    );
};

export default Events;
