import React, { useEffect, useRef, useState } from 'react';
import './DocumentPage.scss';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    ValidateObverseDocument,
    ValidateReverseDocument,
    GetUserRestrictionVisitor,
    GetTokenChallenge,
} from '../../../../reduxToolkit/Visitor/VisitorApi';
import SelfiePage from '../Selfie/SelfiePage';
import apiInstance from '../../../../Apis/Axios';
import { setIsSuccessValidation, setUserDataDocument } from '../../../../reduxToolkit/Visitor/VisitorSlice';
import ValidatedFail from '../../FailValidation/ValidatedFail';
import SuccessValidationForm from '../../SuccessValidation/SuccessValidationForm';
import FullPageLoader from '../../../../utils/FullPageLoader';
import logo from '../../../../assets/logo/logo 3.png';
import axios from 'axios';
import { URL } from '../../../../Apis/Constants';
import { toast } from 'react-toastify';
import { errorCode } from '../../../../enums/errorCodeEnum';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const DocumentPage = () => {
    const [message, setMessage] = useState('');
    const [userData, setUserData] = useState({});
    const [unmounted, setUnmounted] = useState(false);
    const [obverseImage, setObverseImage] = useState(null);
    const [reverseImage, setReverseImage] = useState(null);
    const [showSelfiePage, setShowSelfiePage] = useState(false);
    const [showValidateFail, setShowValidateFail] = useState(false);
    const [obverseValidationCompleted, setObverseValidationCompleted] = useState(false);
    const [reverseValidationCompleted, setReverseValidationCompleted] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userId } = useParams();
    const { t } = useTranslation();
    const selectedDocumentType = useSelector((state) => state.VisitorSlice.selectedDocumentType);
    const idProcess = useSelector((state) => state.VisitorSlice.getValidationId);
    const userRestrictionData = useSelector((state) => state.VisitorSlice.getUserRestrictionVisitor);
    const userDataSuccess = useSelector((state) => state.VisitorSlice.userDataDocument);
    const userDataIsSuccess = useSelector((state) => state.VisitorSlice.isSuccessValidation);

    const targetRef = useRef(null);
    let documentPageSDK = null;

    useEffect(() => {
        start();

        return () => {
            if (!unmounted) {
                destroySDK();
            }
        };
    }, []);

    const start = () => {
        loadScript('./js/VDDocument.js', () => {
            loadScript('./js/opencv/cv.js', onRuntimeInitialized, onCVLoadError);
        });
    };

    const loadScript = (src, callback = null, onError = null) => {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.setAttribute('type', 'application/javascript');
        if (callback != null) {
            script.addEventListener('load', callback);
        }
        if (onError != null) {
            script.addEventListener('error', onError);
        }
        document.head.appendChild(script);
    };

    const onRuntimeInitialized = () => {
        sdkStart();
    };

    const sdkStart = () => {
        documentPageSDK = window.makeVDDocumentWidget();
        let sdkParams = {
            targetSelector: '#target',
            sdkBackgroundColorInactive: '#ffffff66',
            outerGlowCenteringAidDefault: '#00000010',
            outerGlowCenteringAidDetecting: '#178a7b',
            customLogo: logo,
        };

        if (selectedDocumentType === 'MX_IDCard_2019') {
            sdkParams.documents = ['MX_IDCard_2019'];
        } else {
            sdkParams.passport = ['XX_Passport_YYYY'];
        }

        documentPageSDK(sdkParams);
    };

    const destroySDK = () => {
        //n
    };

    const onVDDocumentMounted = () => {};
    // console.log(unmounted);
    const onVDDocumentMountFailure = (data) => {
        setMessage(data.detail);
    };

    const onVDDocumentUnmounted = async () => {
        setUnmounted(true);
        //setShowSelfiePage(true);
    };

    const onVDDocumentObverseDetection = (data) => {
        setObverseImage(data.detail);
    };

    const onVDDocumentReverseDetection = (data) => {
        setReverseImage(data.detail);
    };

    const onCVLoadError = (error) => {
        setMessage('Failed to load/initialize cv.js');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedDocumentType === 'MX_IDCard_2019') {
                    await sendObverseValidationRequest();
                    await sendReverseValidationRequest();
                } else if (selectedDocumentType === 'XX_Passport_YYYY') {
                    await sendObverseValidationRequest();
                }

                await documentValidationStatus(idProcess);
                if (userDataIsSuccess === true) {
                    console.log(userDataIsSuccess, 'Se extraen los datos', userDataSuccess);
                } else {
                    setShowValidateFail(true);
                }
            } catch (error) {
                console.error('Error en la validación:', error);
            }
        };

        if (unmounted === true) {
            fetchData();
        }
    }, [unmounted]);

    useEffect(() => {
        const target = targetRef.current;

        target.addEventListener('VDDocument_mounted', onVDDocumentMounted);
        target.addEventListener('VDDocument_mountFailure', onVDDocumentMountFailure);
        target.addEventListener('VDDocument_unmounted', onVDDocumentUnmounted);
        target.addEventListener('VDDocument_obverseDetection', onVDDocumentObverseDetection);
        target.addEventListener('VDDocument_reverseDetection', onVDDocumentReverseDetection);

        return () => {
            target.removeEventListener('VDDocument_mounted', onVDDocumentMounted);
            target.removeEventListener('VDDocument_mountFailure', onVDDocumentMountFailure);
            target.removeEventListener('VDDocument_unmounted', onVDDocumentUnmounted);
            target.removeEventListener('VDDocument_obverseDetection', onVDDocumentObverseDetection);
            target.removeEventListener('VDDocument_reverseDetection', onVDDocumentReverseDetection);
        };
    }, []);

    const sendObverseValidationRequest = async () => {
        try {
            if (obverseImage) {
                await dispatch(
                    ValidateObverseDocument({
                        analysisType: 'obverse',
                        documentImage: obverseImage,
                        documentType: selectedDocumentType,
                        validationId: idProcess,
                    }),
                );
                setObverseValidationCompleted(true);
            } else {
                console.error('Failed to get obverse image.');
            }
        } catch (error) {
            navigate(`/validation-fail`);
        }
    };

    const sendReverseValidationRequest = async () => {
        try {
            if (reverseImage) {
                await dispatch(
                    ValidateReverseDocument({
                        analysisType: 'reverse',
                        documentImage: reverseImage,
                        validationId: idProcess,
                    }),
                );
                setReverseValidationCompleted(true);
            } else {
                console.error('Failed to get reverse image.');
            }
        } catch (error) {
            navigate(`/validation-fail`);
        }
    };
    const documentValidationStatus = async (validationId) => {
        try {
            const response = await axios.post(`${URL}validas-service/v1/process`, null, {
                params: {
                    validationId: validationId,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                dispatch(setUserDataDocument(response.data.data));

                dispatch(setIsSuccessValidation(response.data.data.success));
                setShowSelfiePage(true);
            }
        } catch (error) {
            navigate(`/validation-fail`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userRestrictionData.livenessVisitor === true) {
                    await getNewTokenChallenge();
                }
            } catch (error) {
                console.error('Error en la validación:', error);
            }
        };

        if (unmounted === true) {
            fetchData();
        }
    }, [unmounted]);
    const getNewTokenChallenge = async () => {
        try {
            if (idProcess) {
                await dispatch(GetTokenChallenge(idProcess));
            } else {
                console.error('Failed to get obverse image.');
            }
        } catch (error) {
            console.error('Error sending obverse validation request:', error);
        }
    };

    return (
        <>
            {!unmounted ? (
                <div id='document_page'>
                    <div ref={targetRef} id='target' style={{ width: '700px', height: '500px' }}></div>
                </div>
            ) : showSelfiePage === true ? (
                userDataIsSuccess === true ? (
                    userRestrictionData.biometricVisitor === true ? (
                        <SelfiePage />
                    ) : (
                        <SuccessValidationForm />
                    )
                ) : (
                    <ValidatedFail />
                )
            ) : (
                <FullPageLoader />
            )}
        </>
    );
};

export default DocumentPage;
