import { t } from 'i18next';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ShiftTimePicker from './../../ShiftTimePicker';
import { CreateAttendanceDepartment } from '../../../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';

const AddNewWorkshift = ({ show, onHide, setCreatedFlag }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [startShiftAt, setStartShiftAt] = useState('');
    const [endShiftAt, setEndShiftAt] = useState('');
    const [range, setRange] = useState('');
    const [departmentsId, setDepartmentsId] = useState('');

    const handleCreate = () => {
        if (!departmentsId || !startShiftAt || !endShiftAt || !range) {
            toast.error(t('please_fill_all_fields').toUpperCase());
            return;
        }
        const body = {
            departmentId: departmentsId,
            endShiftAt,
            range,
            startShiftAt,
        };

        dispatch(CreateAttendanceDepartment(body)).then(
            ({
                payload: {
                    data: { success, data, code },
                },
            }) => {
                if (success === true) {
                    setCreatedFlag(true);
                    onHide();
                } else {
                    if (code === 832) {
                        toast.error(t('attendance_department_already_exists').toUpperCase());
                        onHide();
                    } else {
                        toast.error(t('Error_creating_controller').toUpperCase());
                        onHide();
                    }
                }
            },
        );
    };

    return (
        <Modal
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{ background: 'rgba(0,0,0,0.5)' }}
            show={show}
            onHide={onHide}
        >
            <button className='modal-close-btn' id='btn_anw_close' onClick={onHide}>
                X
            </button>
            <span className='main-modal-heading'>{t('add_new_schedule')}</span>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <ShiftTimePicker
                    startShiftAt={startShiftAt}
                    setStartShiftAt={setStartShiftAt}
                    endShiftAt={endShiftAt}
                    setEndShiftAt={setEndShiftAt}
                    range={range}
                    setRange={setRange}
                    selectedDepartment={departmentsId}
                    setSelectedDepartment={setDepartmentsId}
                />
            </div>
            <div className='unlink-modal-body'>
                <div className='btn-div'>
                    <button
                        className='custom_btn_cancel_gray_hover'
                        id='btn_anw_cancel'
                        style={{ width: '100%' }}
                        onClick={onHide}
                    >
                        {t('cancel').toLocaleUpperCase()}
                    </button>
                    <button
                        className='custom_primary_btn_dark'
                        id='btn_anw_create'
                        style={{ width: '100%' }}
                        onClick={handleCreate}
                    >
                        {t('create').toLocaleUpperCase()}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddNewWorkshift;
