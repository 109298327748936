import { Grid, TextField, InputAdornment, Box, IconButton } from '@mui/material';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetEmailSetting, UpdateEmailSetting } from '../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { permissionObj } from '../../../Helpers/permission';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useStyle from '../../../hooks/useStyle';
import { EmailSettings } from '../../../models/EmailSettings';
import { blockInvalidNumericChar } from '../../../utils/blockInvalidNumericChar';

/*
Author : Arman Ali
Module: Zone
github: https://github.com/Arman-Arzoo
*/

// Email Setting module main funtion
const EmailSetting = () => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch('');
    const { smallBoxStyle, textFieldCommon } = useStyle();

    //use Selector hook to get state for redux store
    const { getEmailSetting } = useSelector((state) => state.employeePayrollSlice);
    const { updateEmailSetting } = useSelector((state) => state.employeePayrollSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    //use State hook for local state management
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [emptyFields, setEmptyFields] = useState(false);
    const [settings, setSettings] = useState(EmailSettings);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => navigate(-1);

    const handleEmailChange = (e) => {
        validateEmail(e); // Run the function validateEmail
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateEmail = (e) => {
        let isValid;
        const { value } = e?.target;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value === null || value === undefined) {
            isValid = emailRegex.test(settings.email);
        } else {
            isValid = emailRegex.test(value);
        }
        setIsValidEmail(isValid);
    };

    // A funtion to manage payload and dispatch update email setting
    const handelEmailSettingUpdate = () => {
        setEmptyFields(true);
        if (settings.email === '' || settings.password === '' || settings.imapHost === '' || settings.port === '') {
            toast.warn(t('please_fill_all_fields'));
        } else {
            dispatch(UpdateEmailSetting(settings.toUpdateEmailSettings())).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        dispatch(GetEmailSetting());
                        setEmptyFields(false);
                    }
                },
            );
        }
    };

    // useEffect for dispatch get email setting data
    useEffect(() => {
        dispatch(GetEmailSetting());
    }, []);

    // useEffect for set email data for update
    useEffect(() => {
        setSettings((prevData) => ({
            ...prevData,
            ...getEmailSetting,
            password: '',
        }));
    }, [getEmailSetting]);

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    {location.state === 'yes' && (
                        <i
                            className='fa fa-arrow-left'
                            aria-hidden='true'
                            onClick={() => goBack()}
                            style={{
                                transform: lCode === 'ar' ? 'scaleX(-1)' : '',
                            }}
                        ></i>
                    )}

                    <h2>{t('email_settings')}</h2>
                </div>
            </div>
            <div className='email_setting_container'>
                <Grid display='flex' gap='0.8rem' alignItems='baseline'>
                    <h4>{t('sender')}</h4>
                    <div className='span'></div>
                </Grid>

                <div className='row'>
                    <div className='col-lg-6 pt-3'>
                        <Box className='box-text-field required-data-text-form' sx={smallBoxStyle}>
                            <TextField
                                size='small'
                                error={(emptyFields && settings.email.trim() === '') || (emptyFields && !isValidEmail)}
                                fullWidth
                                required
                                id='txt_es_email'
                                name='email'
                                inputProps={{ maxLength: 128 }}
                                label={t('email')}
                                value={settings.email}
                                onChange={(e) => {
                                    setSettings((prevData) => ({
                                        ...prevData,
                                        email: e.target.value,
                                    }));
                                    handleEmailChange(e);
                                }}
                                sx={textFieldCommon}
                                helperText={
                                    emptyFields && settings.email.trim() === ''
                                        ? t('requiredField')
                                        : emptyFields && !isValidEmail
                                          ? t('email_format_invalid')
                                          : ''
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <MailOutlineIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </div>

                    <div className='col-lg-6 pt-3'>
                        <Box className='box-text-field required-data-text-form' sx={smallBoxStyle}>
                            <TextField
                                size='small'
                                error={emptyFields && settings.password.trim() === ''}
                                fullWidth
                                required
                                id='txt_es_password'
                                name='password'
                                type={showPassword ? 'text' : 'password'}
                                inputProps={{ maxLength: 128 }}
                                label={t('password')}
                                value={settings.password}
                                onChange={(e) => {
                                    setSettings((prevData) => ({
                                        ...prevData,
                                        password: e.target.value,
                                    }));
                                }}
                                sx={textFieldCommon}
                                helperText={emptyFields && settings.password.trim() === '' ? t('requiredField') : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge='end'
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 pt-3'>
                        <Box sx={smallBoxStyle} className='box-text-field required-data-text-form'>
                            <TextField
                                size='small'
                                error={emptyFields && settings.imapHost.trim() === ''}
                                fullWidth
                                required
                                name='imapHost'
                                inputProps={{ maxLength: 128 }}
                                label={t('imap_host')}
                                id='txt_es_imapHost'
                                value={settings.imapHost}
                                helperText={emptyFields && settings.imapHost.trim() === '' ? t('requiredField') : ''}
                                onChange={(e) => {
                                    setSettings((prevData) => ({
                                        ...prevData,
                                        imapHost: e.target.value,
                                    }));
                                }}
                                sx={textFieldCommon}
                            />
                        </Box>
                    </div>
                    <div className='col-lg-6 pt-3'>
                        <Box className='box-text-field required-data-text-form' sx={smallBoxStyle}>
                            <TextField
                                size='small'
                                error={emptyFields && settings.port === ''}
                                type='number'
                                required
                                fullWidth
                                id='txt_es_port'
                                inputProps={{
                                    max: 100,
                                }}
                                onKeyDown={blockInvalidNumericChar}
                                label={t('port')}
                                name='port'
                                value={settings.port}
                                onFocus={(e) =>
                                    e.target.addEventListener(
                                        'wheel',
                                        function (e) {
                                            e.preventDefault();
                                        },
                                        { passive: false },
                                    )
                                }
                                onChange={(e) => {
                                    let newValue = e.target.value !== '' ? parseInt(e.target.value, 10) : null;
                                    if (newValue < 10000000 || newValue === null) {
                                        setSettings((prevData) => ({
                                            ...prevData,
                                            port: e.target.value,
                                        }));
                                    }
                                }}
                                sx={textFieldCommon}
                                helperText={emptyFields && settings.port === '' ? t('requiredField') : ''}
                            />
                        </Box>
                    </div>
                </div>
                {permission?.includes(permissionObj?.WEB_EMAIL_SETTINGS_UPDATE) && (
                    <Grid className='email_footer'>
                        <button
                            id='btn_es_saveSettings'
                            onClick={() => {
                                handelEmailSettingUpdate();
                            }}
                        >
                            {t('save_settings')?.toUpperCase()}
                        </button>
                    </Grid>
                )}
            </div>
        </>
    );
};

export default EmailSetting;
