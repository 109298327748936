import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import useStyle from '../hooks/useStyle';

export default function AutoCompleteSearch({
    data,
    value,
    setValue,
    handleFn,
    label,
    defaultState,
    required,
    disablePortal,
    error,
}) {
    const { t } = useTranslation();
    const { smallBoxStyle, textFieldCommon } = useStyle();

    // Ensure that 'data' is an array before using it in 'options'
    const options = Array.isArray(data) ? data : [];
    return (
        <Autocomplete
            required
            size='small'
            disablePortal={disablePortal}
            id={data?.id}
            options={options}
            value={value}
            onChange={(event, newValue) => {
                if (newValue) {
                    setValue(newValue);
                    handleFn(newValue);
                } else {
                    setValue('');
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label ? label : t('search')}
                    required={required}
                    defaultValue={defaultState}
                    sx={textFieldCommon}
                    error={error}
                    helperText={error ? t('requiredField') : ''}
                />
            )}
        />
    );
}
