import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { GetExcelHeadersByOption } from '../../reduxToolkit/Files/FilesApi';
import { useDispatch } from 'react-redux';
import pdf_image from '../../assets/images/pdf.svg';
import ic_excel from '../../assets/images/ic-excel.svg';
import OptionModal from '../../pages/Modals/OptionModal';

const ExportDataBtn = ({ body, option, searchElement, exportAttendance, search }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [excel, setExcel] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadOption = (isExcel) => {
        setExcel(isExcel);
        dispatch(
            GetExcelHeadersByOption({
                option: option.toString(),
                isExcel: isExcel,
            }),
        );

        setShowModal(true);
    };

    return (
        <div>
            <button className='export-btn-1' onClick={handleClick}>
                {t('export_data')
                    .split(' ')
                    .map((word, index) => (
                        <React.Fragment key={index}>
                            {word}
                            <br />
                        </React.Fragment>
                    ))}
            </button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleDownloadOption(true)}>
                    <span className='export-data-menu'>
                        <img className='mr-2' src={ic_excel} style={{ width: '16px', height: '16px' }} alt='ic_excel' />
                        Excel
                    </span>
                </MenuItem>

                <MenuItem onClick={() => handleDownloadOption(false)}>
                    <span className='export-data-menu'>
                        <img
                            className='mr-2'
                            src={pdf_image}
                            style={{ width: '16px', height: '16px' }}
                            alt='pdf_image'
                        />
                        PDF
                    </span>
                </MenuItem>
            </Menu>
            <OptionModal
                body={body}
                show={showModal}
                onClose={() => setShowModal(false)}
                excel={excel}
                option={option}
                searchElement={searchElement}
                exportAttendance={exportAttendance}
                search={search}
            />
        </div>
    );
};

export default ExportDataBtn;
