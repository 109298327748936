import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';

// create resource /resource/create card-module card-controller
export const CreateResourceApi = createAsyncThunk('cards/createResourceApi', async (params, { dispatch, getState }) => {
    const result = await apiInstance
        .post('card-service/resource/create', params)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

// create single card /v1/create card-module card-controller
export const CreateSingleCard = createAsyncThunk('cards/createSingleCard', async (params, { dispatch, getState }) => {
    const result = await apiInstance
        .post('card-service/v1/create', params)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

// create multiple cards /v1/create-all card-module card-controller
export const CreateMultipleCards = createAsyncThunk(
    'cards/createMultipleCards',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .post('card-service/v1/create-all', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// analize cards /v1/analize-cards card-module card-controller
export const AnalizeCards = createAsyncThunk('cards/analizeCards', async (params, { dispatch, getState }) => {
    const result = await apiInstance
        .post('card-service/v1/analyze-cards', params)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

// get all card formats /type/get-all card-module card-controller
export const GetAllCardFormats = createAsyncThunk('cards/getAllCardFormats', async (params, { dispatch, getState }) => {
    const result = await apiInstance
        .get('card-service/format/get-all')
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

// get all card formats /type/get-all card-module card-controller
export const GetFreeCards = createAsyncThunk('cards/getFreeCards', async (params, { dispatch, getState }) => {
    const result = await apiInstance
        .get('card-service/v1/get-free-cards')
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

// get preview by id /type/get-all card-module card-controller
export const GetPreview = createAsyncThunk('cards/getPreview', async (id, { dispatch, getState }) => {
    const result = await apiInstance
        .get(`card-service/v1/preview-by-id/${id}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

// Assign Card To User /v1/user/assign-card card-module card-controller
export const AssignCardToUser = createAsyncThunk('cards/assignCardToUser', async (params, { dispatch, getState }) => {
    const result = await apiInstance
        .put('card-service/v1/user/assign-card', params)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

// Remove Card To User /v1/user/remove-card card-module card-controller
export const RemoveCardToUser = createAsyncThunk('cards/removeCardToUser', async (params, { dispatch, getState }) => {
    const result = await apiInstance
        .put('card-service/v1/user/remove-card', params)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});

export const CreateTemplateCard = createAsyncThunk(
    'cards/createTemplateCard',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .post('card-service/template/create', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const UpdateTemplateCard = createAsyncThunk(
    'cards/updateTemplateCard',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .put('card-service/template/update', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
export const AssignTemplateToCards = createAsyncThunk(
    'cards/assignTemplateToCards',
    async (body, { dispatch, getState }) => {
        const result = await apiInstance
            .put('card-service/template/assign-to-cards', body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
