import React, { useEffect, useState } from 'react';
import { MapContainer, useMapEvents, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import Geocode from 'react-geocode';
import { HashLoader } from 'react-spinners';
import { override } from '../Helpers/spinnercss';
import { useDispatch } from 'react-redux';
import { mapCoordinates } from '../reduxToolkit/UpdateCompany/UpdateCompanySlice';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
/*
Author : Arman Ali
Module: Zone
github: https://github.com/Arman-Arzoo
*/

function AddMarkerToClick({ latlng, setLatitude, setLongitude }) {
    const [markers, setMarkers] = useState(latlng);
    const dispatch = useDispatch();

    useMapEvents({
        click(e) {
            setMarkers([e.latlng.lat, e.latlng.lng]);
            setLatitude(e.latlng.lat);
            setLongitude(e.latlng.lng);
            Geocode.setApiKey('AIzaSyDRCcydEnjt7P-riOx4X-Dm4F1HDntPEDg');
            Geocode.fromLatLng(e.latlng.lat, e.latlng.lng).then(
                (response) => {
                    const body = {
                        lat: e.latlng.lat,
                        lng: e.latlng.lng,
                    };
                    dispatch(mapCoordinates(body));
                },
                (error) => {
                    console.error(error);
                },
            );
        },
    });

    return (
        <>
            {
                <Marker key={markers[0]} position={markers}>
                    <Popup>Marker is at {markers}</Popup>
                </Marker>
            }
        </>
    );
}

function SearchMap({ searchLocation, setCenter, setZoom }) {
    const map = useMap();
    useEffect(() => {
        Geocode.setApiKey('AIzaSyDRCcydEnjt7P-riOx4X-Dm4F1HDntPEDg'); // Replace with your API key
        Geocode.fromAddress(searchLocation)
            .then((response) => {
                const { lat, lng } = response?.results[0]?.geometry?.location;
                setCenter([lat, lng]);
                setZoom(3);
                map.flyTo([lat, lng], 12, {
                    duration: 2,
                });
            })
            .catch((error) => {
                console.error('Error while fetching coordinates:', error);
            });
    }, [searchLocation]);

    return null;
}

const ZoneLeaflet = ({ latlng, setLatitude, setLongitude, searchLocation }) => {
    // const [center, setCenter] = useState([sessionStorage.getItem("latitude") !== null ? sessionStorage.getItem("latitude") : 19.4285465, sessionStorage.getItem("longitude") !== null ? sessionStorage.getItem("longitude") : -99.1718796])
    const [center, setCenter] = useState(latlng);

    useEffect(() => {
        setCenter(latlng);
    }, [latlng]);

    const [zoom, setZoom] = useState(6);
    return (
        <>
            {
                // latlng[0] !== undefined ?
                <MapContainer center={center} zoom={zoom} style={{ height: '14rem', width: '100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    <AddMarkerToClick latlng={latlng} setLatitude={setLatitude} setLongitude={setLongitude} />
                    <SearchMap setCenter={setCenter} searchLocation={searchLocation} setZoom={setZoom} />
                    <FullscreenControl position='topright' />
                </MapContainer>
                //  :
                // <div className="overlay">
                //     <HashLoader loading="true" css={override} size={50} color="#fff" />
                // </div>
            }
        </>
    );
};

export default ZoneLeaflet;
