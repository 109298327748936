import React from 'react';
import { Card, CardContent, CardActions, Typography, Box } from '@mui/material';

const MiniCard = ({ title, status, availability, description, zone, onSelectedCard, showButtonDelete, onDelete }) => {
    const handleClickCard = () => {
        onSelectedCard();
    };

    const handleDeleteCard = () => {
        onDelete();
    };

    return (
        <Card
            variant='outlined'
            className={`mini-card ${showButtonDelete ? 'default' : 'pointer'}`}
            onClick={() => {
                if (!showButtonDelete) {
                    handleClickCard();
                }
            }}
        >
            <CardContent className='d-flex p-0'>
                <Box className='py-2 px-3 w-100'>
                    <Typography variant='h6' className='title' component='div'>
                        {title}
                    </Typography>
                    <Typography className='availability' sx={{ mb: 1.5 }}>
                        {availability ? 'Available' : 'No available'}
                    </Typography>
                    <Typography className='description' variant='body2'>
                        {description}
                    </Typography>
                    <Typography className='status' sx={{ mb: 1.5 }} color='text.secondary'>
                        {status}
                    </Typography>
                </Box>
                {showButtonDelete && (
                    <Box>
                        <button
                            className='delete-btn-1 h-100 rounded-0'
                            onClick={() => {
                                handleDeleteCard();
                            }}
                        >
                            <i className='fa fa-trash-o fs-4' aria-hidden='true'></i>
                        </button>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default MiniCard;
