export const sidebarEmployeeOptionsEnum = {
    company: 'company',
    'company/update-data': 'company',
    zones: 'zones',
    'zones/singlezonedetails': 'zones',
    'zones/showdevices': 'zones',
    'zones/authmodal': 'zones',
    'zones/updatezone': 'zones',
    'zones/create-device': 'zones',
    'zones/update-device': 'zones',
    events: 'events',
    'events/normal-events': 'events',
    'events/onu-events': 'events',
    'events/incomming-envent-detail': 'events',
    'events/validation-event-detail': 'events',
    'event-restriction': 'events',
    'all-employees': 'employees',
    'all-employees/employee-Detail': 'employees',
    'all-employees/add-employee': 'employees',
    'all-employees/update-employee': 'employees',
    'all-employees/uploademployeefile': 'employees',
    'all-employees/employee-docs': 'employees',
    'all-employees/employee-docs-complete': 'employees',
    'user-restriction': 'employees',
    'company/headers': 'employees',
    'vehicle-fleet': 'vehicles',
    'vehicle-fleet/create-vehicle': 'vehicles',
    'vehicle-fleet/vehicle-detail': 'vehicles',
    'vehicle-fleet/update-vehicle': 'vehicles',
    access: 'access',
    contractors: 'contractor',
    'contractors/create-contract': 'contractor',
    'contractors/add-contractor': 'contractor',
    'contractors/update-contractor': 'contractor',
    'contractors/employee-contractor-details': 'contractor',
    'contractors/contractor-approve-document': 'contractor',
    'contractors/upload-contractor': 'contractor',
    'contractors/contractor-details': 'contractor',
    'contractors/contractor-detail': 'contractor',
    'contractors/vehicle-detail': 'contractor',
    'contractors/vehicleDetail': 'contractor',
    'contractors/update-contract': 'contractor',
    'contractors/employeeDetail': 'contractor',
    'contractor-restriction': 'contractor',
    suppliers: 'provider',
    'suppliers/create-order': 'provider',
    'suppliers/upload-contractor': 'provider',
    'suppliers/order-details': 'provider',
    'suppliers/vehicle-detail': 'provider',
    'suppliers/add-suppliers': 'provider',
    'suppliers/update-suppliers': 'provider',
    'suppliers/employee-suppliers-details': 'provider',
    'suppliers/approve-documents': 'provider',
    'suppliers/upload-supplier': 'provider',
    'suppliers/suppliers_detail_page': 'provider',
    attendance: 'payroll',
    'attendance/manage-attendence': 'payroll',
    'attendance/email-setting': 'emailSetting',
    backup: 'backup',
    //   {/* @hospitality */}
    //           <Route path="hospitality" element={<Hospitality />} />
    //           <Route path="hospitality" element={<HospitalityOutlet />}>
    //             <Route path="add-room" element={<AddUpdateHospitality />} />
    //             <Route path="room-detail" element={<RoomDetail />} />

    //           </Route>
    departments: 'departments',
    'company/workshift-panel': 'workshift',
    'company/roles-panel': 'previlageSetting',
    'company/add-update-role': 'previlageSetting',
    'company/add-new-role': 'previlageSetting',
    'company/onboarding': 'onBoarding',
    'company/user-doc-panel': 'userDocument',
    'company/vehicle-doc-panel': 'vehicleDocument',
    devices: 'devices',
    'devices/create': 'devices',
    'devices/update': 'devices',
    'announcement-panel': 'announcements',
    'company/create-announcement': 'announcements',
    'devices/configuration': 'devices',
    cards: 'cards',
    'card-designer': 'card_designer',
    'card-designer/create-template': 'card_designer',
    graphs: 'graphs',
    logs: 'logs',
    controller: 'controller',
    'controller/create-controller': 'controller',
    sios: 'sios',
    readers: 'readers',
    holiday: 'holiday',

    'work-office': 'homeoffice',

    guard: 'guard',

    profile: 'profile',
};
