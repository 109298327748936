import React, { useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import CustomTextWithLine from '../../../../components/CustomTextWithLine';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import useStyle from '../../../../hooks/useStyle';
import TablePagination from '@mui/material/TablePagination';
import { toast } from 'react-toastify';
import { fileSize } from '../../../../constant/variable';
import {
    GetListOfVehicleImages,
    GetVehicleById,
    GetVehicleStatus,
    GetVehicleTags,
    UpdateVehicleEmployee,
    UpdateVehicleV1,
} from '../../../../reduxToolkit/Vehicle/VehicleApi';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import RemoveDriver from './modal/RemoveDriver';
import { permissionObj } from '../../../../Helpers/permission';
import ReturnArrow from '../../../../components/buttons/ReturnArrow';
import { Vehicle } from '../../../../models/Vehicle';
import { blockInvalidNumericChar } from '../../../../utils/blockInvalidNumericChar';
import cloud from '../../../../assets/images/cloud.svg';
import NotInformation from '../../../../components/NotInformation';

const UpdateVehicle = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const lCode = Cookies.get('i18next') || 'en';
    const { textField, smallBoxStyle, textFieldCommon } = useStyle();

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    const [image, setImage] = useState();
    const [preview, setPreview] = useState();
    const [showImgDelete, setShowImgDelete] = useState(false);
    const [vehicleImageId, setVehicleImageId] = useState('');
    const [vehicle, setVehicle] = useState(Vehicle);
    const [emptyFields, setEmptyFields] = useState(false);
    const [isValidModel, setIsValidModel] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);

    // pagination
    const [pageImage, setPageImage] = useState(0);
    const [rowsPerPageImage, setRowsPerPageImage] = useState(6);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();

    // useSelctor state from store slice vehicleSlice
    const { getVehicleStatus, getVehicleTags, getVehicleById, getListOfVehicleImages, deleteVehicleImage } =
        useSelector((state) => state.VehicleSlice);

    // functions

    const validateModel = (newValue) => {
        let value;
        if (newValue !== undefined) {
            value = newValue;
        } else {
            value = vehicle.model;
        }
        if (value <= 2100 && value >= 1950) {
            setIsValidModel(true);
        } else {
            setIsValidModel(false);
        }
    };

    // a funtion to control zone page
    const handleChangePageImage = (event, newPage) => {
        setPageImage(newPage);
    };
    // a funtion to control row per page
    const handleChangeRowsPerPageImage = (event) => {
        setRowsPerPageImage(parseInt(event.target.value));
        setPageImage(0);
    };
    // funtion to handle image upload
    const handleImageUpload = (event) => {
        event.map((file) => {
            const fileImage = file['type'].split('/')[0] === 'image';
            if (fileImage) {
                if (file?.size <= fileSize) {
                    setImage(file);
                    setPreview(URL.createObjectURL(file));
                } else {
                    toast.warn('File Should not more then 500kb');
                }
            } else {
                toast.warn('Please Upload Image File');
            }
        });
    };
    // handle update vehicle
    const handelUpdateVehicle = () => {
        setEmptyFields(true);
        validateModel(parseInt(vehicle.model));
        if (
            vehicle.brand === '' ||
            vehicle.subBrand === '' ||
            vehicle.color === '' ||
            vehicle.plate === '' ||
            (vehicle.model !== null && vehicle.model !== '' && !isValidModel)
            // || !tags
        ) {
            toast.warn(t('please_fill_all_fields').toUpperCase());
        } else {
            dispatch(UpdateVehicleV1({ vehicleData: vehicle.toCreateUpdate(), imageFile: selectedImage })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        dispatch(GetVehicleById(data.id));
                        toast.success(t('has_been_updated_successfully').toUpperCase());
                        navigate(`/dashboard/employee/vehicle-fleet/vehicle-detail/${params?.id}`);
                    }
                },
            );
        }
    };

    useEffect(() => {
        dispatch(GetVehicleStatus());
        dispatch(GetVehicleTags());
        dispatch(GetVehicleById(params?.id));
    }, []);
    // fill the data by id
    useEffect(() => {
        setVehicle((prevData) => ({
            ...prevData,
            ...getVehicleById,
            statusId: getVehicleById?.status?.id,
        }));
        validateModel(getVehicleById?.model);
    }, [getVehicleById?.id]);

    const fileInputRef = useRef(null);

    useEffect(() => {
        const data = {
            pagination: {
                order: sortBy === 'asc' ? true : false,
                page: pageImage,
                size: rowsPerPageImage,
                sortBy: orderBy ? orderBy : 'id',
            },
            vehicleId: params?.id,
        };
        dispatch(GetListOfVehicleImages(data));
    }, [sortBy, pageImage, rowsPerPageImage, orderBy, deleteVehicleImage]);

    const onFileChange = (e) => {
        const selectedFiles = e.target.files;
        const imageRegex = /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/i; // Expresión regular para imágenes (jpg, jpeg, png, gif)

        if (selectedFiles && selectedFiles.length > 0) {
            if (!imageRegex.test(selectedFiles[0].name)) {
                toast.error(t('error_file_format').toUpperCase());
            } else if (selectedFiles[0].size >= 500000) {
                toast.error(t('error_file_size').toUpperCase());
            } else {
                setSelectedImage(selectedFiles[0]);
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            fileInputRef.current.files = files;
            onFileChange({ target: fileInputRef.current });
        }
    };

    return (
        <>
            <div className='vehicle_upadate_container'>
                {/* head with back link */}
                <div className='head'>
                    <div className='headLeft'>
                        <ReturnArrow link={'/dashboard/employee/vehicle-fleet'} />
                        <h2>{t('update_vehicle')}</h2>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <div className='vehicle_update_items'>
                        {/* @data */}
                        <CustomTextWithLine title={t('data')} />
                        <div className='update_data p-4'>
                            <div className='row pt-2'>
                                <div className='col-sm-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.brand === ''}
                                            fullWidth
                                            required
                                            label={t('brand')}
                                            name='brand'
                                            value={vehicle.brand}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    brand: e.target.value,
                                                }))
                                            }
                                            id='BRAND'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                            helperText={emptyFields && vehicle.brand === '' ? t('requiredField') : ''}
                                        />
                                    </Box>
                                </div>
                                <div className='col-sm-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.subBrand === ''}
                                            fullWidth
                                            required
                                            label={t('sub_brand')}
                                            name='subBrand'
                                            value={vehicle.subBrand}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    subBrand: e.target.value,
                                                }))
                                            }
                                            id='subBrand'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                            helperText={
                                                emptyFields && vehicle.subBrand === '' ? t('requiredField') : ''
                                            }
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className='row pt-2'>
                                <div className='col-sm-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.color === ''}
                                            fullWidth
                                            required
                                            label={t('color')}
                                            name='color'
                                            value={vehicle.color}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    color: e.target.value,
                                                }))
                                            }
                                            id='color'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                            helperText={emptyFields && vehicle.color === '' ? t('requiredField') : ''}
                                        />
                                    </Box>
                                </div>
                                <div className='col-sm-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.model !== '' && !isValidModel}
                                            type='number'
                                            fullWidth
                                            onKeyDown={blockInvalidNumericChar}
                                            label={t('model')}
                                            name='model'
                                            value={vehicle.model}
                                            onFocus={(e) =>
                                                e.target.addEventListener(
                                                    'wheel',
                                                    function (e) {
                                                        e.preventDefault();
                                                    },
                                                    { passive: false },
                                                )
                                            }
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                validateModel(parseInt(newValue));
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    model: parseInt(newValue),
                                                }));
                                            }}
                                            id='model'
                                            sx={textFieldCommon}
                                            helperText={
                                                emptyFields && vehicle.model !== '' && !isValidModel
                                                    ? t('min_1950_max_2100')
                                                    : ''
                                            }
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className='row pt-2'>
                                <div className='col-sm-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            error={emptyFields && vehicle.plate === ''}
                                            fullWidth
                                            required
                                            label={t('plates')}
                                            name='plates'
                                            value={vehicle.plate}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    plate: e.target.value,
                                                }))
                                            }
                                            id='plates'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                            helperText={emptyFields && vehicle.plate === '' ? t('requiredField') : ''}
                                        />
                                    </Box>
                                </div>
                                <div className='col-sm-6'>
                                    <Box className='box-text-field' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('vin')}
                                            name='vin'
                                            value={vehicle.vin}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    vin: e.target.value,
                                                }))
                                            }
                                            id='vin'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className='row pt-2'>
                                <div className='col-sm-6'>
                                    <Box className='box-text-field' sx={smallBoxStyle}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            label={t('serial_number')}
                                            name='serialNumber'
                                            value={vehicle.serialNumber}
                                            onChange={(e) =>
                                                setVehicle((prevData) => ({
                                                    ...prevData,
                                                    serialNumber: e.target.value,
                                                }))
                                            }
                                            id='serialNumber'
                                            sx={textFieldCommon}
                                            inputProps={{ maxLength: 128 }}
                                        />
                                    </Box>
                                </div>
                                <div className='col-sm-6'>
                                    <Box className='box-text-field required-data' sx={smallBoxStyle}>
                                        <FormControl fullWidth required sx={textFieldCommon}>
                                            <InputLabel id='status'>{t('status')}</InputLabel>
                                            <Select
                                                size='small'
                                                labelId='status'
                                                id='statusId'
                                                label={t('status')}
                                                value={vehicle.statusId}
                                                onChange={(e) =>
                                                    setVehicle((prevData) => ({
                                                        ...prevData,
                                                        statusId: e.target.value,
                                                    }))
                                                }
                                            >
                                                {getVehicleStatus?.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id}>
                                                            {item.name === 'ACTIVE'
                                                                ? t('active').toLocaleUpperCase()
                                                                : t('inactive').toLocaleUpperCase()}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>{' '}
                            </div>
                            <div className='row pt-2'>
                                <div className='col-sm-6'>
                                    <Box sx={smallBoxStyle} display={'none'}>
                                        <FormControl fullWidth sx={textField}>
                                            <InputLabel id='tags' hidden>
                                                {t('tags')}
                                            </InputLabel>
                                            <Select
                                                size='small'
                                                hidden
                                                labelId='tags'
                                                id='tagsId'
                                                label={t('tags')}
                                                // value={tags}
                                                // onChange={(e) => setTags(e.target.value)}
                                            >
                                                {getVehicleTags?.map((item, index) => {
                                                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        {/* @images */}
                        <CustomTextWithLine title={t('images')} />
                        <div className='vehicle_images_container'>
                            <div className='vehicle_images_item'>
                                {getListOfVehicleImages?.content?.length > 0 ? (
                                    getListOfVehicleImages?.content?.map((item) => {
                                        return (
                                            <div className='vehicle_image'>
                                                <button
                                                    className='cross_icon'
                                                    onClick={() => {
                                                        setShowImgDelete(true);
                                                        setVehicleImageId(item?.id);
                                                    }}
                                                >
                                                    <i class='fa fa-times' aria-hidden='true'></i>
                                                </button>
                                                <img
                                                    src={item?.image ? `data:image/png;base64,${item?.image}` : ''}
                                                    alt=''
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <>
                                        <NotInformation text={t('no_information')} card={true} />
                                    </>
                                )}
                            </div>

                            <div className='d-flex justify-content-center pt-3'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[4, 8]}
                                    count={getListOfVehicleImages?.totalElements}
                                    page={pageImage}
                                    onPageChange={handleChangePageImage}
                                    labelRowsPerPage={t('images_per_page')}
                                    rowsPerPage={rowsPerPageImage}
                                    onRowsPerPageChange={handleChangeRowsPerPageImage}
                                />
                            </div>
                        </div>
                        {/* @upload image */}
                        <CustomTextWithLine title={t('upload_image')} />
                        <div className='upload_images'>
                            <div className='row'>
                                <div className='col-md-7 d-flex justify-content-center align-items-center'>
                                    <label
                                        htmlFor='file-input'
                                        className='dottedborderbox'
                                        style={{ maxWidth: '481px' }}
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                    >
                                        <img src={cloud} alt='submitupload' className='submitupload' />
                                        <input
                                            type='file'
                                            ref={fileInputRef}
                                            id='file-input'
                                            accept='image/*'
                                            onChange={onFileChange}
                                        />
                                        <div
                                            className='dragAndDrop'
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                        >
                                            <p>{t('choose_or_drag_your_image_here')}</p>
                                            <span>{t('max_file_size_500kb')}</span>
                                        </div>
                                    </label>
                                </div>
                                <div className='col-md-5 d-flex justify-content-center align-items-center'>
                                    {selectedImage && (
                                        <div>
                                            <img
                                                src={URL.createObjectURL(selectedImage)}
                                                style={{ maxHeight: '200px', maxWidth: '300px', objectFit: 'cover' }}
                                                alt='Thumb'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {permission?.includes(permissionObj?.WEB_VEHICLE_UPDATE) && (
                                <div className='footer'>
                                    <button className='custom_btn_cancel_gray_hover' style={{ width: '372px' }}>
                                        {t('cancel')}
                                    </button>
                                    <button
                                        className='custom_primary_btn_dark'
                                        style={{ width: '372px' }}
                                        onClick={() => {
                                            handelUpdateVehicle();
                                        }}
                                    >
                                        {t('upload').toUpperCase()}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <RemoveDriver
                modal_name='delete_image'
                show={showImgDelete}
                onHide={() => setShowImgDelete(false)}
                title_modal={t('delete_image')}
                data={{ id: vehicleImageId }}
            />
        </>
    );
};

export default UpdateVehicle;
