import React, { useEffect, useState } from 'react';
import { ToastContainer, Slide, toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MainRoutes from './Routes/MainRoutes';
import jwt from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import { logOut } from './reduxToolkit/authentication/authenticationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserByIdAtEntry, RoleCheck } from './reduxToolkit/authentication/AuthenticatonApi';
import { GetProvidersByUserId } from './reduxToolkit/Providers/providersApi';
import { GetContractorsByUserId } from './reduxToolkit/Contractor/ContractorApi';
import cryptoJs from 'crypto-js';
import securekey from './config';
import FullPageLoader from './utils/FullPageLoader';
import Zones from './pages/Dashboard/Zones/Zones';
import { SnackbarProvider } from 'notistack';
import FingerPrintLoader from './utils/FingerPrintLoader';
import SecondPageLoader from './utils/SecondPageLoader';
import NavigationHandler from './components/NavigationHandler';
import AlertMessage from './pages/Alert/AlertMessage';

const App = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const Etoken = sessionStorage.getItem('bearerToken') || '';
    const bytes = cryptoJs.AES.decrypt(Etoken, securekey);
    const token = bytes.toString(cryptoJs.enc.Utf8);

    // const user = JSON.parse(sessionStorage.getItem("userdata"))?.data?.data;
    const userdata = sessionStorage.getItem('userdata');
    const bytess = cryptoJs.AES.decrypt(userdata || '', securekey);
    const userstring = bytess.toString(cryptoJs.enc.Utf8);
    const user = userstring ? JSON.parse(userstring)?.data?.data : '';
    const { getProvidersByUserId } = useSelector((state) => state.providersSlice);
    const { getContractorsByUserId } = useSelector((state) => state.ContractorSlice);
    //Min Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showMessage, setShowMessage] = useState(false);
    const minWindowSize = 900;
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setShowMessage(windowWidth < minWindowSize);
    }, [windowWidth, minWindowSize]);
    // min width
    useEffect(() => {
        if (document.getElementById('overlay')) {
            document.getElementById('overlay').style.display = 'none';
        }
        if (document.getElementById('second-overlay')) {
            document.getElementById('second-overlay').style.display = 'none';
        }
    }, [navigate]);

    useEffect(() => {
        if (token) {
            const tokeninfo = jwt(token);
            const expireDate = tokeninfo?.exp * 1000;
            const currentDate = new Date();

            if (expireDate < currentDate.getTime()) {
                toast.error('Token is Expired');
                dispatch(logOut());
                navigate('/');
            }
        }
    });
    // this useEffect to redirect auth user to page
    useEffect(() => {
        if (token && location.pathname == '/' && user) {
            navigate('/login-option');
        }
    }, []);

    // it will check the token and call api to fetch
    // the premmission at entry point

    useEffect(() => {
        if (token) {
            const tokeninfo = jwt(token);
            const data = {
                roleId: tokeninfo?.role_id,
            };
            dispatch(RoleCheck(data));
            if (user?.userType?.name === 'EMPLOYEE') {
                dispatch(GetUserByIdAtEntry());
            }
            // if (user?.userType?.name === "CONTRACTOR_IN_CHARGE") {
            //

            //   dispatch(GetContractorsByUserId(user?.id)).then(({ payload: { data: data } }) => {
            //     sessionStorage.setItem("contractorId", getContractorsByUserId?.id);

            //   });
            // }
            //   if (user?.userType?.name == "SUPPLIER_IN_CHARGE") {
            //     const data = {
            //         userId: user?.data?.id
            //     }
            //     dispatch(GetProvidersByUserId(data))
            //     sessionStorage.setItem("providerId", getProvidersByUserId?.id)
            // }
        }
    }, []);

    // Function to handle the message received from another window
    const handleStorageChange = (event) => {
        // Check if the received message is of the expected type
        if (event.data && event.data.type === 'sessionStorageChange') {
            // Update the variable with the new value
            const newValue = event.data.newValue !== null ? event.data.newValue : false;
            setCapturing(newValue);
        }
        if (event.data && event.data.type === 'sessionStorageChangeLoader') {
            // Update the variable with the new value
            const newValueLoading = event.data.newValueLoading !== null ? event.data.newValueLoading : false;
            setLoading(newValueLoading);
        }
    };

    useEffect(() => {
        // Add the 'message' event to the component to listen for messages from other windows
        window.addEventListener('message', handleStorageChange);

        // Important: clear event when component is unmounted
        return () => {
            window.removeEventListener('message', handleStorageChange);
        };
    }, []);

    // Variable where we will store the value of the sessionStorage
    const [capturing, setCapturing] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            if (document.getElementById('overlay')) {
                document.getElementById('overlay').style.display = 'none';
            }
        } else {
            if (document.getElementById('overlay')) {
                document.getElementById('overlay').style.display = 'none';
            }
        }
    }, [loading]);

    return (
        <>
            {/*     <div>
                {showMessage && <AlertMessage />}
                {!showMessage && (
                    <div> */}
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <FullPageLoader />
                {capturing && <FingerPrintLoader />}
                <NavigationHandler />
                <DndProvider backend={HTML5Backend}>
                    <MainRoutes />

                    <ToastContainer
                        position='top-right'
                        autoClose={4000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        transition={Slide}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </DndProvider>
            </SnackbarProvider>
            {/*        </div>
                )}
            </div> */}
        </>
    );
};

export default App;
