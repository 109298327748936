import { css } from '@emotion/react';

export const override = css`
    position: fixed;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    z-index: 6;
`;
