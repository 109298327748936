import React, { useEffect, useState, Fragment, useRef } from 'react';
import {
    Dialog,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import cloud from '../../../assets/images/cloud.svg';
import { toast } from 'react-toastify';
import { CreateResourceApi } from '../../../reduxToolkit/Cards/CardsApi';
import { UploadImage } from '../../../reduxToolkit/ShareSlice/shareApi';
import useStyle from '../../../hooks/useStyle';

const AddResource = ({ open, onClose, onFiltered, moduleId, option, finalArray, onUpdated, resourceNames }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch();
    const { textFieldCommon } = useStyle();
    const [resourceName, setResourceName] = useState('');
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [emptyFields, setEmptyFields] = useState(false);

    useEffect(() => {
        setResourceName('');
        handleClearPhoto();
        setEmptyFields(false);
    }, [open]);

    const handleClearPhoto = () => {
        setSelectedImage(null);
        // URL.revokeObjectURL()
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            fileInputRef.current.files = files;
            onFileChange({ target: fileInputRef.current });
        }
    };

    const onFileChange = (e) => {
        const selectedFiles = e.target.files;
        const imageRegex = /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/i; // Expresión regular para imágenes (jpg, jpeg, png, gif)

        if (selectedFiles && selectedFiles.length > 0) {
            if (!imageRegex.test(selectedFiles[0].name)) {
                toast.error(t('error_file_format').toUpperCase());
            } else if (selectedFiles[0].size >= 5000000) {
                toast.error(t('error_file_size').toUpperCase());
            } else {
                setSelectedImage(selectedFiles[0]);
            }
        }
    };

    const handelUploadImageAction = () => {
        setEmptyFields(true);
        if (resourceName !== '' && selectedImage !== null) {
            if (resourceNames.includes(resourceName)) {
                toast.warn(t('name_already_exists').toUpperCase());
            } else {
                const body = {
                    name: resourceName,
                };
                dispatch(CreateResourceApi(body)).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            let formData = new FormData();
                            formData.append('id', data?.id);
                            formData.append('option', 'resource');
                            formData.append('file', selectedImage);
                            dispatch(UploadImage(formData)).then(
                                ({
                                    payload: {
                                        data: { data, success },
                                    },
                                }) => {
                                    if (success === true) {
                                        toast.success(t('has_been_created_successfully').toUpperCase());
                                        onUpdated();
                                        onClose();
                                    } else {
                                        toast.error(t('error_creating').toUpperCase());
                                    }
                                },
                            );
                        }
                    },
                );
            }
        } else if (selectedImage === null) {
            toast.warn(t('please_select_an_image').toUpperCase());
        }
    };

    return (
        <Dialog className='add-resource-dialog' open={open} onClose={onClose} maxWidth='xs'>
            <DialogTitle>
                <div className='d-flex justify-content-center'>
                    <p className='search-title'>{t('add').toUpperCase()}</p>
                </div>
                <img onClick={() => onClose()} className='modal-close' src={cancel} alt='' />
            </DialogTitle>
            <DialogContent>
                <Box
                    className='form-field required-data mt-2'
                    // sx={smallBoxStyle}
                >
                    <TextField
                        size='small'
                        fullWidth
                        error={emptyFields && resourceName === ''}
                        required
                        name='field1'
                        label={t('name')}
                        id='txt_ar_name'
                        value={resourceName}
                        onChange={(e) => setResourceName(e.target.value)}
                        inputProps={{ maxLength: 128 }}
                        helperText={emptyFields && resourceName === '' ? t('requiredField') : ''}
                        sx={textFieldCommon}
                    />
                </Box>

                <label
                    htmlFor='file-input'
                    className='dottedborderbox'
                    style={{ maxWidth: '481px' }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <img src={cloud} alt='submitupload' className='submitupload' />
                    <input
                        type='file'
                        ref={fileInputRef}
                        id='img_ar_inputFile'
                        accept='image/*'
                        onChange={onFileChange}
                    />
                    <div className='dragAndDrop'>
                        <p>{t('choose_or_drag_your_image_here')}</p>
                        <span>{t('max_file_size_5mb')}</span>
                    </div>
                </label>

                {selectedImage && (
                    <div className='d-flex justify-content-center'>
                        <img
                            src={URL.createObjectURL(selectedImage)}
                            style={{ maxHeight: '210px', maxWidth: '360px', objectFit: 'cover' }}
                            alt='Thumb'
                        />
                        {/* <button onClick={removeSelectedImage}>
                                    Remove This Image
                                </button> */}
                    </div>
                )}

                <div className='d-flex w-100 mt-3'>
                    <button
                        onClick={() => {
                            onClose();
                            // setFileData("")
                        }}
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '200px' }}
                        id='btn_ar_cancel'
                    >
                        {t('cancel')}
                    </button>
                    <button
                        onClick={() => {
                            if (resourceName.trim() !== '') {
                                handelUploadImageAction();
                            } else {
                                toast.warn(t('please_enter_a_name').toUpperCase());
                            }
                        }}
                        className='custom_primary_btn_dark'
                        style={{ width: '200px' }}
                        id='btn_ar_create'
                    >
                        {t('create').toUpperCase()}
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AddResource;
