import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const AnnouncementSlice = createSlice({
    name: 'announcement',
    initialState: {
        getListAnnouncements: [],
        notifyTypes: [],
        employeesData: [],
    },
    reducers: {},
    extraReducers: {
        'announcements/getListAnnouncements/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getListAnnouncements = data;
            } else if (status >= 400 && status < 500) {
                toast('Something went wrong');
            }
        },
        'announcements/announcementTypes/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.notifyTypes = data;
            } else if (status >= 400 && status < 500) {
                toast('Something went wrong');
            }
        },
        'announcements/createAnnouncement/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // state.announcementsTypes = data
                toast.success('Announcement Created Successfully..!');
            } else if (status >= 400 && status < 500) {
                toast('Something went wrong');
            }
        },
        'announcements/sendToAllScope/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success('sent to all..!');
            } else if (status >= 400 && status < 500) {
                toast('Something went wrong');
            }
        },
        'announcements/sendByTopicScope/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success('sent to topic scope');
            } else if (status >= 400 && status < 500) {
                toast('Something went wrong');
            }
        },
        'announcements/sendToSomeEmployees/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success('sent to some employees');
            } else if (status >= 400 && status < 500) {
                toast.error('Something went wrong');
            }
        },
        'announcements/getEmployeesPageable/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.employeesData = data;
            } else if (status >= 400 && status < 500) {
                toast.error('Something went wrong');
            }
        },
        'announcements/uploadImg/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success('Media uploaded successfully..!');
            } else if (status >= 400 && status < 500) {
                toast.error('Something went wrong');
            }
        },
    },
});

export const {} = AnnouncementSlice.actions;

export default AnnouncementSlice.reducer;
