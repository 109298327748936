export const SupplierEmployee = {
    supplierEmployeeStatusId: null,
    deviceId: null,
    dob: null,
    email: '',
    firebaseId: null,
    genderId: '',
    id: null,
    lastName: '',
    name: '',
    password: null,
    phoneNumber: '',
    secondLastName: '',
    selfie: null,
    statusId: '',
    supplierId: null,
    userId: null,
    documentId: '',

    toCreateUpdate: function () {
        return {
            supplierEmployeeStatusId: this.supplierEmployeeStatusId !== '' ? this.supplierEmployeeStatusId : null,
            dob: this.dob === null ? null : typeof this.dob === 'number' ? this.dob : this.dob.getTime(),
            email: this.email,
            genderId: this.genderId !== '' ? this.genderId : null,
            id: this.id,
            lastName: this.lastName,
            name: this.name,
            phoneNumber: this.phoneNumber,
            secondLastName: this.secondLastName !== '' ? this.secondLastName : null,
            statusId: this.statusId !== '' ? this.statusId : null,
            supplierId: this.supplierId !== null ? null : sessionStorage.getItem('providerId'),
            userId: this.userId,
            documentId: this.documentId,
        };
    },
    resetToDefaults: function () {
        this.supplierEmployeeStatusId = null;
        this.deviceId = null;
        this.dob = null;
        this.email = '';
        this.firebaseId = null;
        this.genderId = '';
        this.id = null;
        this.lastName = '';
        this.name = '';
        this.password = null;
        this.phoneNumber = '';
        this.secondLastName = '';
        this.selfie = null;
        this.statusId = '';
        this.supplierId = null;
        this.userId = null;
        this.documentId = '';
    },
};
