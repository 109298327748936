export const EmailSettings = {
    id: null,
    email: '',
    password: '',
    imapHost: '',
    port: '',
    time: null,
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
    cronExpression: null,
    daysToSendEmail: null,
    range: null,
    startShiftAt: null,
    endShiftAt: null,
    userTimeZone: null,

    toUpdateEmailSettings: function () {
        return {
            id: this.id,
            email: this.email !== '' ? this.email : null,
            imapHost: this.imapHost !== '' ? this.imapHost : null,
            password: this.password !== '' ? this.password : null,
            port: this.port !== '' ? this.port : null,
            timeZone: this.userTimeZone,
            time: this.time,
            monday: this.monday,
            tuesday: this.tuesday,
            wednesday: this.wednesday,
            thursday: this.thursday,
            friday: this.friday,
            saturday: this.saturday,
            sunday: this.sunday,
            cronExpression: this.cronExpression,
            daysToSendEmail: this.daysToSendEmail,
            range: this.range,
            startShiftAt: this.startShiftAt,
            endShiftAt: this.endShiftAt,
        };
    },
};
