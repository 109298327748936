import React, { useEffect, useState } from 'react';
// import angelright_icon from "../../../assets/images/angelright.svg";
import SidebarDropDownOption from '../../../components/SidebarDropDownOption';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserDocuments } from '../../../reduxToolkit/Providers/providersApi';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import BootstrapTooltip from '../../../utils/BootstrapTooltip';
import { useLocation } from 'react-router-dom';

import green_company from '../../../assets/images/green-company.svg';
import ic_build from '../../../assets/images/ic-build.svg';

import { ReactComponent as Ic_supplier } from '../../../assets/images/ic-supplier.svg';
import green_provider from '../../../assets/images/green-provider.svg';
import { ReactComponent as Green_employee } from '../../../assets/images/green-employee.svg';
import ic_people_group_solid from '../../../assets/images/ic-people-group-solid.svg';
import { ReactComponent as Green_vehicle } from '../../../assets/images/green-vehicle.svg';
import ic_truck from '../../../assets/images/ic-truck.svg';
import { ReactComponent as AnnouncementsIcon } from '../../../assets/menuIcon/announcements.svg';
import announcementsWhite from '../../../assets/menuIcon/white_announcements.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/menuIcon/profile.svg';
import profileWhite from '../../../assets/menuIcon/white_profile.svg';
import { sidebarSupplierOptionsEnum } from '../../../enums/sidebarSupplierOptionsEnum';
import { Tooltip } from '@mui/material';

const ProviderTabs = ({ isMenuOpen }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState(window.location.pathname);

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        // setChangeStyle(sidebarSupplierOptionsEnum[modulePart])
    }, [location]);

    useEffect(() => {
        setChangeStyle(sidebarSupplierOptionsEnum[modulePart]);
    }, [currentUrl]);

    //Get the current url to choose the respectively module in the sideBar
    // const currentUrl = window.location.pathname;
    const parts = currentUrl.split('/');
    const modulePart = parts[3] ? parts[3] : '';

    const [changeStyle, setChangeStyle] = useState(sidebarSupplierOptionsEnum[modulePart]);

    // useState hook
    const [isOpen, setIsOpen] = useState(false);
    // const [changeStyle, setChangeStyle] = useState("orders");
    const [isHovering, setIsHovering] = useState('1');

    const dispatch = useDispatch();
    // useSelector hook for store state
    // sessionStorage.setItem("providerId", getProvidersByUserId?.id)
    useEffect(() => {
        dispatch(GetUserDocuments(user?.data?.id));
    }, []);

    return (
        <ul className={`${isMenuOpen ? 'largeMenu' : 'shortMenu'} `}>
            {user?.data?.status?.id != 3 && (
                <>
                    <Tooltip
                        title={!isMenuOpen ? t('employees').toUpperCase() : ''}
                        placement='right'
                        disableInteractive
                    >
                        <Link to='/dashboard/supplier/employees'>
                            <li
                                onClick={() => setChangeStyle('employees')}
                                className={changeStyle === 'employees' ? 'activeLi' : isHovering === 2 ? 'hoverLi' : ''}
                                onMouseEnter={() => setIsHovering(2)}
                                onMouseLeave={() => setIsHovering(0)}
                            >
                                <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                    <div>
                                        <Green_employee className='svgHover' />
                                    </div>
                                    <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                        {t('employees')}
                                    </span>
                                </div>
                            </li>
                        </Link>
                    </Tooltip>
                    <Tooltip
                        title={!isMenuOpen ? t('vehicle_fleet').toUpperCase() : ''}
                        placement='right'
                        disableInteractive
                    >
                        <Link to='/dashboard/supplier/vehicles'>
                            <li
                                onClick={() => setChangeStyle('vehicles')}
                                className={changeStyle === 'vehicles' ? 'activeLi' : isHovering === 3 ? '' : ''}
                                onMouseEnter={() => setIsHovering(3)}
                                onMouseLeave={() => setIsHovering(0)}
                            >
                                <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                    <div>
                                        <Green_vehicle className='svgHover' />
                                    </div>
                                    <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                        {t('vehicle_fleet')}
                                    </span>
                                </div>
                            </li>
                        </Link>
                    </Tooltip>
                    <Tooltip
                        title={!isMenuOpen ? t('purchase_orders').toUpperCase() : ''}
                        placement='right'
                        disableInteractive
                    >
                        <Link to='/dashboard/supplier/orders'>
                            <li
                                onClick={() => setChangeStyle('orders')}
                                className={changeStyle === 'orders' ? 'activeLi' : isHovering === 1 ? 'hoverLi' : ''}
                                onMouseEnter={() => setIsHovering(1)}
                                onMouseLeave={() => setIsHovering(0)}
                            >
                                <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                    <div>
                                        <Ic_supplier className='svgHover' />
                                    </div>
                                    <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                        {t('company_profile')}
                                    </span>
                                </div>
                                {/* <img src={angelright_icon} alt="" style={{
                                transform: lCode === "ar" ? "scaleX(-1)" : "",
                                margin: "0 10px"
                            }} /> */}
                            </li>
                        </Link>
                    </Tooltip>

                    <Tooltip
                        title={!isMenuOpen ? t('announcements').toUpperCase() : ''}
                        placement='right'
                        disableInteractive
                    >
                        <Link to='/dashboard/supplier/announcements'>
                            <li
                                onClick={() => setChangeStyle('announcements')}
                                className={
                                    changeStyle === 'announcements' ? 'activeLi' : isHovering === 4 ? 'hoverLi' : ''
                                }
                                onMouseEnter={() => setIsHovering(4)}
                                onMouseLeave={() => setIsHovering(0)}
                            >
                                <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                    <div>
                                        <AnnouncementsIcon className='svgHover' />
                                    </div>
                                    <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                        {t('announcements')}
                                    </span>
                                </div>
                            </li>
                        </Link>
                    </Tooltip>
                </>
            )}
            <Tooltip title={!isMenuOpen ? t('profile').toUpperCase() : ''} placement='right' disableInteractive>
                <Link to='/dashboard/supplier/profile'>
                    <li
                        onClick={() => setChangeStyle('profile')}
                        className={changeStyle === 'profile' ? 'activeLi' : isHovering === 5 ? 'hoverLi' : ''}
                        onMouseEnter={() => setIsHovering(5)}
                        onMouseLeave={() => setIsHovering(0)}
                    >
                        <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                            <div>
                                <ProfileIcon className='svgHover' />
                            </div>
                            <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                {t('profile')}
                            </span>
                        </div>
                    </li>
                </Link>
            </Tooltip>
            <Link to='#'>
                <li
                    // onClick={() => { setIsOpen(!isOpen) }}
                    className={`${isMenuOpen ? 'profile_li' : 'profile_li_col'}`}
                >
                    <SidebarDropDownOption menutop={false} isMenuOpen={isMenuOpen} />
                </li>
            </Link>
        </ul>
    );
};

export default ProviderTabs;
