import { t } from 'i18next';

const deviceTypeId = (id) => {
    switch (id) {
        case 1:
            return t('MANTRA MFSTAB II');
        case 2:
            return t('PDA CARIBE PL50L');
        case 3:
            return t('TELPO F6');
        case 4:
            return t('TELPO TPS 980');
        case 5:
            return t('TELPO TPS 450');
        case 6:
            return t('TELPO K5');
        case 7:
            return t('TELPO F10');
        case 8:
            return t('TELPO F6 T');
        case 9:
            return t('TELPO K10');
        case 10:
            return t('VERIDAS 1FA');
        case 11:
            return t('VERIDAS 2FA');
        case 12:
            return t('Camera_lpr');
        case 13:
            return t('antena_rfid');
        default:
            return 'none';
    }
};

export default deviceTypeId;
