import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Avatar,
} from '@mui/material';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import { useTranslation } from 'react-i18next';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import NotInformation from '../../../../../components/NotInformation';

const SelectCamera = ({
    open,
    onClose,
    setSelectedCamera,
    setTakeCamera,
    setSelfieSrc,
    setAvailableCameras,
    selectedCamera,
    setLoadingProgress,
    cameras,
    setCameras,
}) => {
    const { t } = useTranslation();

    const fetchCameras = async () => {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = mediaDevices.filter((device) => device.kind === 'videoinput');
        setCameras(videoDevices);
        setAvailableCameras(videoDevices);
    };

    useEffect(() => {
        fetchCameras();

        const handleDeviceChange = () => {
            fetchCameras();
        };

        navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
        };
    }, [setAvailableCameras, setCameras]);

    const handleListCameraClick = (index) => {
        setSelectedCamera(index);
        setTakeCamera(true);
        onClose();
    };

    return (
        <Dialog className='select-camera-dialog' open={open}>
            <DialogTitle className='mt-2'>
                <p className='search-title'>{t('select_a_camera').toUpperCase()}</p>
                {!cameras.length && <NotInformation text={t('no_information')} card={false} />}
                <img
                    onClick={() => {
                        onClose();
                        setTakeCamera(false);
                    }}
                    className='modal-close'
                    src={cancel}
                    alt=''
                />
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                {cameras.length > 0 &&
                    cameras.map((camera, index) => (
                        <ListItem disableGutters key={camera.deviceId}>
                            <ListItemButton onClick={() => handleListCameraClick(index)}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: '#f5f5f5', color: '#333' }}>
                                        <CameraIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={camera.label || `Camera ${index + 1}`} />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </Dialog>
    );
};

export default SelectCamera;
