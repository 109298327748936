import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import cancel from '../../../../src/assets/images/ic-cancel.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DeleteItemChart } from '../../../reduxToolkit/Graphs/GraphsApi';

const DeleteChartModal = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const navigate = useNavigate();

    const handleDelete = () => {
        const table = 'chart_dashboard_position';
        const body = [props.chartId];
        dispatch(DeleteItemChart({ table, body })).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                if (success === true) {
                    setLoading(true);
                    props.setChanged(true);
                    props.onHide();
                }
            },
        );
    };
    return (
        <Modal
            className='department_and_user_delete-modal'
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title className='title_name'>{t('remove_chart')}</Modal.Title>
                <img onClick={() => props.onHide()} className='modalClose' src={cancel} alt='' />
            </Modal.Header>
            <Modal.Body className='department_modal_body'>
                <p className='paragraph_deps'>{t('are_you_sure_you_want_to_delete')}</p>

                <div className='d-flex'>
                    <button
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '180px', marginLeft: '35px' }}
                        onClick={() => props.onHide()}
                        id='btn_ddm_cancel'
                    >
                        {t('cancel')}
                    </button>
                    <button
                        className='custom_primary_btn_dark'
                        id='btn_ddm_confirm'
                        style={{ width: '180px' }}
                        onClick={handleDelete}
                    >
                        {t('confirm')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteChartModal;
