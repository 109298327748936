/*
Author : Arman Ali
Module: Department
github: https://github.com/Arman-Arzoo
*/
import { createSlice } from '@reduxjs/toolkit';

const employeeSlice = createSlice({
    name: 'employeeSection',
    initialState: {
        getAllEmployees: {},
        getEmployeeDetailsByUserId: {},
        updateEmployeeUser: null,
        createEmployeeUser: null,
        approveCompanyEmployeeDocument: null,
        getAllEmployeeDocuments: [],
        createEmployeeDocValue: {},
        setEmployeeDocValue: {},
    },
    reducers: {},
    extraReducers: {
        'employeeSection/getAllEmployees/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getAllEmployees = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch data")
            }
        },
        'employeeSection/createEmployeeUser/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 201 && status < 300) {
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeSection/getEmployeeDetailsByUserId/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getEmployeeDetailsByUserId = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeSection/updateEmployeeUser/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 201 && status < 300) {
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeSection/approveCompanyEmployeeDocument/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.approveCompanyEmployeeDocument = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to Create Order")
            }
        },
        'employeeSection/getAllEmployeeDocuments/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllEmployeeDocuments = data.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeSection/setEmployeeDocValue/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                state.setEmployeeDocValue = data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeSection/createEmployeeDocValue/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                state.createEmployeeDocValue = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast.error(data?.message)
            }
        },
    },
});

export const {} = employeeSlice.actions;

export default employeeSlice.reducer;
