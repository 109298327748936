import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import deleteIcon from '../../../../assets/images/ic-delete-red.svg';
import deleteIcon from '../../assets/images/ic-delete-red.svg';
import { updateAllEmployees, updateSelectedEmployees } from '../../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import {
    CreateUserZoneList,
    DeleteZoneUser,
    ZoneDetailAuthorizedEmployeeNoPagination,
} from '../../reduxToolkit/EmployeeZones/EmployeeZonesApi';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Box, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutoCompleteSearch from '../../components/AutoCompleteSearch';

const AuthorizedEmployeesModal = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const allEmployeesData = useSelector((state) => state?.EmployeeEventsSlice?.allEmployees);
    const { zoneDetailAuthorizedEmployeeNoPagination } = useSelector((state) => state.EmployeeZonesSlice);
    const [query, setQuery] = useState('');
    const [addUserquery, setAddUserQuery] = useState('');
    const [employeesWithRole, setEmployeesWithRole] = useState([]);
    const [totalEmployees, setTotalEmployees] = useState([]);

    useEffect(() => {
        if (props.show === false) {
            setAddUserQuery('');
        }
    }, [props.show]);

    const handleRemoveSelected = (user) => {
        setTotalEmployees(totalEmployees.filter((item) => item?.id !== user.id));
        dispatch(updateAllEmployees([...allEmployeesData, user]));
    };

    //  add user to workshif
    const handleAction = () => {
        if (!props.personalizedAction) {
            const all_user = totalEmployees?.map((item) => item?.id);
            if (all_user) {
                const data = {
                    list: all_user,
                    // zoneId: sessionStorage.getItem("singlezoneId")
                    zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                        JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
                    ].id,
                };

                dispatch(CreateUserZoneList(data));
                setTotalEmployees([]);
            } else {
                toast.warn('Please Select User');
            }
        } else if (props.personalizedAction) {
            props.onConfirm(totalEmployees);
            setTotalEmployees([]);
        }

        setAddUserQuery('');
        props.onHide();
    };

    const handleRemoveEmployees = () => {
        if (!props.personalizedRemoveAction) {
            return userDataTransform(zoneDetailAuthorizedEmployeeNoPagination);
        } else if (props.personalizedRemoveAction && props.removeEmployeesList.length > 0) {
            return props.removeEmployeesList;
        }
    };

    const handleDelete = (dId) => {
        if (!props.personalizedDeleteAction) {
            const data = {
                userIds: [dId], //props?.deleteData,
                // zoneId: sessionStorage.getItem("singlezoneId")
                zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                    JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
                ].id,
            };
            dispatch(DeleteZoneUser(data));
            // props.onHide();
            setQuery('');
        } else if (props.personalizedDeleteAction) {
            props.onRemoveElement(dId);
        }
    };

    // select for add user
    const handleselected = (user) => {
        const checkUser = userDataTransform(props.removeEmployeesList)?.find((item) => {
            return item?.id === user?.id;
        });
        if (checkUser) {
            toast.warn(`${checkUser?.label} ${t('is_already_added')}`);
        } else {
            const selectedUser = totalEmployees?.find((item) => item?.id === user?.id);

            if (selectedUser) {
                toast.warn(`${selectedUser?.label} ${t('is_already_selected')}`);
                setAddUserQuery('');
            } else {
                setAddUserQuery('');
                setTotalEmployees([...totalEmployees, user]);
            }
        }
    };

    // handel the logic for object transformation for all user
    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    item.label ||
                    (item?.name || '') +
                        ' ' +
                        (item?.firstLastName || '') +
                        (item?.secondLastName ? ' ' + item.secondLastName : ''),
                id: item?.userId || item.id,
                phoneNumber: item?.phoneNumber,
            };
        });
        return newData;
    };

    return (
        <Modal
            className='manage-role-panel-modal'
            {...props}
            // size="sm"
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header style={{ marginTop: '6px' }}>
                <Modal.Title
                    id='contained-modal-title-vcenter'
                    style={{ fontSize: '20px', letterSpacing: '7px', fontWeight: 'bold' }}
                >
                    {props.title}
                </Modal.Title>

                <i className='fa fa-times cross' aria-hidden='true' onClick={() => props.onHide()}></i>
            </Modal.Header>

            <Modal.Body className='manage_role_modal_body'>
                <div className='row shiftmanagement_modal'>
                    <div className='text_field w-100'>
                        <div className='mt-3 title' style={{ color: '#146F62' }}>
                            {t('add_user')}
                        </div>

                        <Box
                            className='mt-2 mb-2'
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                fontSize: '20px',

                                background: '#FCFCFC 0% 0% no-repeat padding-box',
                            }}
                        >
                            {/* <TextField size="small"
                  fullWidth

                  label="Search"
                  id="Search"
                  value={addUserquery}
                  onChange={(e) => setAddUserQuery(e.target.value)}
                  className=""
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                            <AutoCompleteSearch
                                data={userDataTransform(allEmployeesData)}
                                handleFn={handleselected}
                                value={addUserquery}
                                setValue={setAddUserQuery}

                                //  onClick={handleselected(user)}
                            />
                        </Box>
                        <div className='main_content'>
                            <div className='mt-2 mb-2  pt-2 user_text'>
                                {totalEmployees.map((item) => (
                                    <div className='d-flex justify-content-between pr-2'>
                                        <div className='badge_container'>
                                            <div className='c_badge'></div>
                                            <p>{item?.label}</p>
                                        </div>

                                        <img
                                            className='delete-icon-style'
                                            src={deleteIcon}
                                            style={{ color: 'red', cursor: 'pointer' }}
                                            onClick={() => handleRemoveSelected(item)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p className='title pt-3'> {t('remove_user')}</p>
                        <Box
                            className='mt-2 mb-2'
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                fontSize: '20px',
                                height: '40px',
                                background: '#FCFCFC 0% 0% no-repeat padding-box',
                            }}
                        >
                            <TextField
                                size='small'
                                fullWidth
                                disabled={zoneDetailAuthorizedEmployeeNoPagination?.length === 0}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                label={t('search')}
                                id='Search'
                                className=''
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <div className='main_content'>
                            <div className='body'>
                                {handleRemoveEmployees()
                                    ?.filter((user) => {
                                        if (query === '') {
                                            return user;
                                        } else if (user.label.toLowerCase().includes(query.toLowerCase())) {
                                            return user;
                                        }
                                    })
                                    .map((item) => {
                                        return (
                                            <div className='d-flex justify-content-between pr-2'>
                                                <div className='badge_container'>
                                                    <div className='c_badge'></div>
                                                    <p>{item?.label}</p>
                                                </div>

                                                <img
                                                    className='delete-icon-style'
                                                    src={deleteIcon}
                                                    style={{ color: 'red', cursor: 'pointer' }}
                                                    onClick={() => handleDelete(item.id)}
                                                    // onClick={() => {

                                                    //   setuserRemoveModal(true);
                                                    //   setDelId(item?.userId);
                                                    // }}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className='btn-div w-100'>
                        <button
                            className='btn_cancel_background_gray_hover'
                            style={{ width: '100%' }}
                            onClick={props.onHide}
                        >
                            {t('cancel')}
                        </button>
                        <button className='custom_primary_btn_dark' style={{ width: '100%' }} onClick={handleAction}>
                            {t('apply_changes')?.toUpperCase()}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AuthorizedEmployeesModal;
