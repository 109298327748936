import { InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleSearch } from '../../reduxToolkit/Search/SearchApi';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { TABLES } from '../../Apis/Tables';
import { toast } from 'react-toastify';

export const SearchSimpleSuppliersVehicleDocuments = ({
    setBodyForExportsSupplierDocs,
    setSuppliersVehicleDocument,
    pages,
    rowsPerPages,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchSuppliersDocElement, setsearchSuppliersDocElement] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    useEffect(() => {
        const handleDebounce = () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                filterSuppliersVehicleDocByElement();
            }, 300);
            setDebounceTimeout(timeout);
        };

        handleDebounce();

        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [searchSuppliersDocElement]);

    const filterSuppliersVehicleDocByElement = async () => {
        if (searchSuppliersDocElement) {
            let body = {
                key: searchSuppliersDocElement,
                model: TABLES.DOCUMENTS_VEHICLE_SUPPLIER,
                pagination: {
                    page: pages,
                    size: rowsPerPages,
                },
            };
            setBodyForExportsSupplierDocs(body);
            try {
                const response = await dispatch(SimpleSearch({ body }));
                const {
                    data: { data, success },
                } = response.payload;
                if (success) {
                    setSuppliersVehicleDocument(data.content);
                    toast.success(t('search_completed_successfully'));
                }
            } catch (error) {
                toast.error(t('error_when_searching'));
            }
        } else {
            setSuppliersVehicleDocument(null);
        }
    };

    return (
        <form className='centerElements' onSubmit={(e) => e.preventDefault()}>
            <section className='div-searchElement searchElementByWord-devices--section centerElements'>
                <TextField
                    label={t('TYPE_SOMETHING_TO_SEARCH')}
                    className='searchSimple--TextField'
                    onChange={(e) => setsearchSuppliersDocElement(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <SearchIcon onClick={() => filterSuppliersVehicleDocByElement()} />
                            </InputAdornment>
                        ),
                    }}
                />
            </section>
        </form>
    );
};

// BUSCADOR SIMPLE DE CONTRACTORS
export const SearchSimpleVehicleDocuments = ({
    setBodyForExportsContractorDocs,
    setContractorsVehicleDocument,
    pages,
    rowsPerPages,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchContractorsDocElement, setSearchContractorsDocElement] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    useEffect(() => {
        const handleDebounce = () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                filterContractorsVehicleDocByElement();
            }, 300);
            setDebounceTimeout(timeout);
        };

        handleDebounce();

        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [searchContractorsDocElement]);

    const filterContractorsVehicleDocByElement = async () => {
        if (searchContractorsDocElement) {
            let body = {
                key: searchContractorsDocElement,
                model: TABLES.DOCUMENTS_VEHICLE_CONTRACTORS,
                pagination: {
                    page: pages,
                    size: rowsPerPages,
                },
            };
            setBodyForExportsContractorDocs(body);
            try {
                const response = await dispatch(SimpleSearch({ body }));
                const {
                    data: { data, success },
                } = response.payload;
                if (success) {
                    setContractorsVehicleDocument(data.content);
                    toast.success(t('search_completed_successfully'));
                }
            } catch (error) {
                toast.error(t('error_when_searching'));
            }
        } else {
            setContractorsVehicleDocument(null);
        }
    };

    return (
        <form className='centerElements' onSubmit={(e) => e.preventDefault()}>
            <section className='div-searchElement searchElementByWord-devices--section centerElements'>
                <TextField
                    label={t('TYPE_SOMETHING_TO_SEARCH')}
                    className='searchSimple--TextField'
                    onChange={(e) => setSearchContractorsDocElement(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <SearchIcon onClick={() => filterContractorsVehicleDocByElement()} />
                            </InputAdornment>
                        ),
                    }}
                />
            </section>
        </form>
    );
};

export default SearchSimpleVehicleDocuments;
