// import React, { useState } from "react";
// import { Modal } from "react-bootstrap";
// import { useDispatch } from "react-redux";
// import cancel from '../../../../assets/images/ic-cancel.svg'
// import Cookies from "js-cookie";
// import { useTranslation } from 'react-i18next'
// import { Box, TextField } from "@mui/material";
// import { CreateDepartment } from "../../../../reduxToolkit/Department/DepartmentApi";
// import { toast } from "react-toastify";

// const AddNewDepartmentModal = (props) => {

//     const dispatch = useDispatch();
//     const [loading, setLoading] = useState(false);
//     const { t } = useTranslation();
//     const lCode = Cookies.get("i18next") || "en";
//     const [department, setDepartment] = useState("")
//     // add department  handler function
//     const handleAddDepartment = () => {

//         const data = {
//             name: department
//         }
//         if (department) {
//             dispatch(CreateDepartment(data))
//             props.onHide();
//             setDepartment("")
//         } else {
//             toast.warn("Name is required")
//         }

//     }
//     return (
//         <Modal
//             className="department_add_modal"
//             {...props}
//             size="md"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//         >
//             <Modal.Header>
//                 <Modal.Title id="contained-modal-title-vcenter">
//                     {t("add_department")}

//                 </Modal.Title>
//                 <img onClick={() => {
//                     props.onHide();
//                     setDepartment("")
//                 }} className="modalClose" src={cancel} alt="" />
//             </Modal.Header>
//             <Modal.Body className="department_modal_body">

//                 <label htmlFor="department" className="paragraph_title">
//                     {t("enter_the_name_of_department")}
//                 </label>
//                 <Box
//                     sx={{
//                         width: "100%",
//                         maxWidth: "100%",
//                         fontSize: "20px",
//                         height: "50px",
//                         marginBottom: '1rem'
//                     }}
//                 >
//                     <TextField size="small"
//                         fullWidth
//                         label={t("department")}
//                         id="department"
//                         value={department}
//                         onChange={(e) => setDepartment(e.target.value)}
//                      // sx={textField}
//                     />
//                 </Box>

//                 <div className="d-flex">
//                     <button className="custom_btn_cancel_gray_hover"
//                         style={{ width: '180px' }}
//                         onClick={() => {
//                             props.onHide()
//                             setDepartment("")
//                         }}>{t("cancel")}</button>
//                     <button className="custom_primary_btn_dark"
//                         style={{ width: '180px' }}
//                         onClick={handleAddDepartment}
//                     >
//                         {t("create")}
//                     </button>
//                 </div>
//             </Modal.Body>

//         </Modal>
//     )
// }

// export default AddNewDepartmentModal

/*
Author: Mazhar Iqbal
Module: Work Shift Panel      
*/

//Add Work Shift
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ReusableTextField from '../../../../components/ReusableTextField ';
import { CreateDepartment, UpdateDepartment } from '../../../../reduxToolkit/Department/DepartmentApi';

const AddNewDepartmentModal = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const [workshiftName, setWorkShiftName] = useState('');
    const [nameError, setNameError] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false);

    // use hook importer
    const dispatch = useDispatch();

    //
    const handleNameChange = (value) => {
        // Perform validation logic
        if (value === '') {
            setNameError(t('requiredField'));
        } else {
            setNameError('');
        }

        setWorkShiftName(value);
    };

    // create work shift
    const handleSubmit = () => {
        if (props?.isUpdate) {
            const data = {
                id: props?.data?.id,
                name: workshiftName,
                // desciption:""
            };
            if (workshiftName) {
                //dispatch update api here
                dispatch(UpdateDepartment(data));
                props.onHide();
                setWorkShiftName('');
            } else {
                setSubmitClicked(true);
            }
        } else {
            if (workshiftName) {
                const data = {
                    name: workshiftName,
                };
                dispatch(CreateDepartment(data));
                props.onHide();
                setWorkShiftName('');
            } else {
                setSubmitClicked(true);
            }
        }
    };

    return (
        <Modal
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{ background: 'rgba(0,0,0,0.5)' }}
        >
            <button
                onClick={() => {
                    props.onHide();
                    setSubmitClicked(false);
                }}
                className='modal-close-btn'
            >
                X
            </button>
            <span className='main-modal-heading'>{props?.isUpdate ? t('change_name') : t('add_new_department')}</span>
            <div className='unlink-modal-body'>
                {props.isUpdate ? (
                    <span
                        className='modal-desc-text'
                        style={{ color: '#707070', fontSize: '12px', fontWeight: 'normal', textAlign: 'left' }}
                    >
                        {t('enter_the_new_name_for_the_department')}:{' '}
                        <span style={{ fontWeight: 'bold' }}>{props?.data?.name}</span>
                    </span>
                ) : (
                    <span
                        className='modal-desc-text'
                        style={{ color: '#707070', fontSize: '12px', fontWeight: 400, textAlign: 'left' }}
                    >
                        {t('enter_the_name_of_department')}
                    </span>
                )}
                <div className='mt-2' style={{ position: 'relative' }}>
                    <ReusableTextField
                        label={t('name')}
                        onChange={handleNameChange}
                        value={workshiftName}
                        helperText={nameError}
                        isRequired={true}
                        submitClicked={submitClicked}
                        validate={(value) => value === ''}
                    />
                </div>
                <div className='btn-div'>
                    <button
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '100%' }}
                        onClick={() => {
                            props.onHide();
                            setSubmitClicked(false);
                        }}
                    >
                        {t('cancel')}
                    </button>
                    <button
                        className='custom_primary_btn_dark'
                        style={{ width: '100%' }}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        {props?.isUpdate ? t('update').toUpperCase() : t('create')?.toUpperCase()}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddNewDepartmentModal;
