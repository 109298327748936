import { InputAdornment, TextField, IconButton } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleSearch } from '../../reduxToolkit/Search/SearchApi';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { toast } from 'react-toastify';
const SearchSimple = ({
    setSearchResults,
    setSearchElement,
    searchElement,
    setCleanSearch,
    optionTable,
    page,
    rowsPerPage,
    setBodyForExport,
    attendanceStartDate,
    attendanceEndDate,
    attendanceSearchType,
    simpleSearch,
    setSimpleSearch,
    searchClean,
    setSearchClean,
    setSearch,
    search,
    cleanAdvance,
    setCleanAdvance,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const timeoutRef = useRef(null);
    const handleClick = () => {
        if (search) {
            setCleanAdvance(true);
            toast.info(t('filters_reset_message'));
            setSearch(false);
        }
        setCleanAdvance(true);
        /*  toast.info(t('filters_reset_message')); */
    };
    const filterSectionByElement = async (search) => {
        if (search.trim()) {
            let body;
            if (attendanceStartDate || attendanceEndDate || attendanceSearchType) {
                body = {
                    key: search,
                    model: optionTable,
                    pagination: {
                        page: 0,
                        size: rowsPerPage,
                    },
                    attendanceStartDate,
                    attendanceEndDate,
                    attendanceSearchType,
                };
            } else {
                body = {
                    key: search,
                    model: optionTable,
                    pagination: {
                        page: 0,
                        size: rowsPerPage,
                    },
                };
            }

            setBodyForExport(body);
            await dispatch(SimpleSearch({ body })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success) {
                        setSearch(false);
                        setSimpleSearch(true);
                        setSearchResults(data);
                        toast.success(t('search_completed_successfully'));
                    } else {
                        setSearchResults(null);
                        toast.error(t('error_when_searching'));
                    }
                },
            );
        } else {
            handleReset();
        }
    };
    const handleChange = (e) => {
        const newValue = e.target.value;
        setSearchElement(newValue);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            filterSectionByElement(newValue);
        }, 400);
    };
    useEffect(() => {
        if (searchClean) {
            handleReset();
        }
    }, [searchClean]);

    const handleReset = () => {
        setSearchElement('');
        setSearchResults(null);
        setBodyForExport(false);
        setSimpleSearch(false);
    };

    return (
        <form className='centerElements' onSubmit={(e) => e.preventDefault()}>
            <section className='searchElementByWord-devices--section'>
                <TextField
                    label={t('TYPE_SOMETHING_TO_SEARCH')}
                    className='searchSimple--TextField'
                    value={searchElement}
                    onChange={handleChange}
                    onClick={handleClick}
                    InputProps={{
                        endAdornment: (
                            <>
                                <InputAdornment position='end'>
                                    <SearchIcon onClick={() => filterSectionByElement(searchElement)} />
                                </InputAdornment>
                                {searchElement && (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={handleReset}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                />
            </section>
        </form>
    );
};

export default SearchSimple;
