export const sidebarContractorOptionsEnum = {
    // "company": "company",
    'search-employe': 'employees',
    'add-new-employe': 'employees',
    'employee-contract-detail': 'employees',
    'create-employee': 'employees',
    'update-employee': 'employees',
    'employee-upload-documets': 'employees',
    // "vehicle-fleet": "vehicles",
    'search-vehicle': 'vehicles',
    addvehical: 'vehicles',
    'vehicle-contract-detail': 'vehicles',
    'upload-vehicle-documents': 'vehicles',
    'vehicle-detail': 'vehicles',
    'update-vehicle': 'vehicles',
    contracts: 'contractors',
    'user-contract-detail': 'contractors',
    announcementtab: 'announcements',
    profile: 'profile',
};
