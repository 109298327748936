/*
Author : Arman Ali
Module: Department
github: https://github.com/Arman-Arzoo
*/
import { createSlice } from '@reduxjs/toolkit';

const vehicleSlice = createSlice({
    name: 'vehicleSection',
    initialState: {
        getAllVehicle: {},
        createVehicleEmployee: {},
        getVehicleStatus: [],
        getVehicleTags: [],
        createVehicleImage: {},
        getVehicleById: {},
        updateVehicleEmployee: {},
        updateVehicleV1: {},
        deleteVehicleImage: {},
        getAllDriverRelationship: [],
        premmissionType: [],
        permissionByUser: [],
        addDriver: {},
        removeDriverEmployee: {},
        updateDriver: {},
        getListOfVehicleImages: {},
    },
    reducers: {},
    extraReducers: {
        'vehicleSection/getAllVehicle/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllVehicle = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/createVehicleEmployee/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createVehicleEmployee = data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/getVehicleStatus/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getVehicleStatus = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/getVehicleTags/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getVehicleTags = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'vehicleSection/createVehicleImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createVehicleImage = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/getVehicleById/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getVehicleById = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/updateVehicleEmployee/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateVehicleEmployee = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/updateVehicleV1/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateVehicleEmployee = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/getAllDriverRelationship/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllDriverRelationship = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/premmissionType/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.premmissionType = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/permissionByUser/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.permissionByUser = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/addDriver/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.addDriver = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/removeDriverEmployee/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.removeDriverEmployee = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/updateDriver/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateDriver = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/getListOfVehicleImages/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getListOfVehicleImages = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'vehicleSection/deleteVehicleImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.deleteVehicleImage = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
    },
});

export default vehicleSlice.reducer;
