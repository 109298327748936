export const errorCode = {
    1000: 'ip_already_registered_please_use_another',
    1001: 'mac_already_registered_please_use_another',
    1002: 'serial_number_already_registered_please_use_another',
    1005: 'ip_and_mac_already_registered_please_use_another_values',
    1010: 'employee_id_already_registered_please_use_another',
    1020: 'email_already_registered_please_use_another',
    1021: 'phone_number_already_registered_please_use_another',
    2006: 'DOCUMENT_ID_CAN_NOT_BE_NULL_OR_EMPTY',
    2007: 'DOCUMENT_ID_NOT_CORRECT',
    2008: 'ACCOUNT_HAS_BEEN_LOCKED',
    2009: 'POSSIBLE_IDENTITY_THEFT_DETECTED',
    2010: 'UNLOCKED_ACCOUNT_DENIED',
    2011: 'ERROR_DURING_DOCUMENT_ID_MATCH_VALIDATION',
    2090: 'VALIDAS_ERROR_DURING_VALIDATION_PROCESSING',
    2091: 'VERIDAS_SERVICE_IS_NOT_AVAILABLE_ERROR',
    2092: 'VALIDAS_DOCUMENT_TYPE_MISMATCH_DURING_PROCESS',
};
