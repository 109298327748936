export const Driver = {
    from: null,
    to: null,
    id: null,
    userId: '',
    vehicleId: null,
    vehiclePermissionTypeId: '',
    toCreateUpdate: function () {
        return {
            from: this.from === null ? null : typeof this.from === 'number' ? this.from : this.from.getTime(),
            to: this.to === null ? null : typeof this.to === 'number' ? this.to : this.to.getTime(),
            id: this.id,
            userId: this.userId,
            vehicleId: this.vehicleId,
            vehiclePermissionTypeId: this.vehiclePermissionTypeId,
        };
    },
};
