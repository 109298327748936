import React, { useEffect, useState, useRef } from 'react';
import NotInformation from '../../../components/NotInformation';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import tagIcon from '../../../assets/icon/tag-solid.svg';
import faceIcon from '../../../assets/icon/ic-face.svg';
import cardIcon from '../../../assets/icon/ic-card.svg';
import fingerIcon from '../../../assets/icon/ic-fingerprint.svg';
import qrIcon from '../../../assets/icon/ic-qr-code.svg';
import pinIcon from '../../../assets/icon/pin-icon.png';
import bluetoothIcon from '../../../assets/icon/bluetooth-icon.svg';
import laptopIcon from '../../../assets/laptop-solid.svg';
import mobileIcon from '../../../assets/mobile-screen-solid.svg';
import { accessMethod } from '../../../enums/accessMethodEnum';
import { Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SearchByFilters, SimpleSearch } from './../../../reduxToolkit/Search/SearchApi';
import { styled } from '@mui/material/styles';
import { GetColorAttendance } from '../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import { useDispatch, useSelector } from 'react-redux';
import platesIcon from '../../../assets/icon/license-plate.png';
const Tableweekly = ({ data, color, simpleSearch, complete, setComplete }) => {
    const { t } = useTranslation();

    const calculateTimeDifferenceInMinutes = (startTime, time) => {
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const [hours, minutes] = time.split(':').map(Number);
        return (hours - startHours) * 60 + (minutes - startMinutes);
    };
    const [colorOnTime, setColorOnTime] = useState();
    const [colorEffectiveTimeUnder, setColorEffectiveTimeUnder] = useState();
    const [colorEffectiveTimeOver, setColorEffectiveTimeOver] = useState();
    const dispatch = useDispatch();
    const startShiftAt = '09:00';
    const range = 60;
    const dates = (data?.content || []).flatMap((person) => person.attendances?.map((att) => att.day) || []);

    const uniqueDates = [...new Set(dates)];
    const [loaded, setLoaded] = useState(false);
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 20;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        const currentUrl = window.location.pathname;
        const parts = currentUrl.split('/');
        const modulePart = parts[3] ? parts[3] : '' + parts[4] ? '/' + parts[4] : '';
    }, []);

    useEffect(() => {
        dispatch(GetColorAttendance()).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                {
                    setColorEffectiveTimeOver(data.onTime);
                    setColorEffectiveTimeUnder(data.effectiveTimeOver);
                    setColorOnTime(data.effectiveTimeUnder);
                }
            },
        );
    }, [dispatch]);
    const statusColorSchedule = {
        0: color.absence || '',
        1: color.onTime || '',
        2: color.effectiveTimeUnder || '',
        3: color.effectiveTimeOver || '',
    };

    return (
        <div ref={elementRef} className='panelTables animated-div px-1' style={{ width: '100%', paddingTop: '0rem' }}>
            {data?.content?.length > 0 && complete ? (
                <div style={{ overflowY: 'auto', maxHeight: '400px', minHeight: '400px' }}>
                    <table className='w-100'>
                        <thead className='no-border-thead sticky-column'>
                            <tr className='no-border-thead'>
                                <th rowSpan='2' className='first_head sticky-column'>
                                    {t('name')}
                                </th>
                                {uniqueDates.map((date) => {
                                    return (
                                        <React.Fragment key={date}>
                                            <th colSpan='2'>
                                                <div className='date-container no-border-th'> {date} </div>
                                            </th>
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                            <tr className='no-border-thead'>
                                {uniqueDates.map((date) => (
                                    <React.Fragment key={date}>
                                        <th className='no-border-th'>{t('in')}</th>
                                        es <th className='no-border-th'>{t('out')}</th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody style={{ marginTop: '20px' }}>
                            <tr style={{ height: '25px' }}>
                                <td colSpan={uniqueDates.length * 2 + 1} />
                            </tr>
                            {data.content.map((person) => (
                                <tr key={person.name}>
                                    <td className='first align-middle sticky-column'>{person.name?.toUpperCase()}</td>
                                    {uniqueDates.map((date) => {
                                        const attendance = person.attendances.find((att) => att.day === date);
                                        const entryDateColor =
                                            statusColorSchedule[attendance.entryDateStatus] || '#707070';
                                        const exitDateColor =
                                            statusColorSchedule[attendance.exitDateStatus] || '#707070';
                                        const absence = statusColorSchedule[0];

                                        return (
                                            <React.Fragment key={date}>
                                                <td style={{ borderRadius: '0px' }}>
                                                    {attendance.entryAccessMethodId != null ? (
                                                        <div className='container-in'>
                                                            <div style={{ width: '40px' }}>
                                                                {attendance.entryAccessMethodId === null ? (
                                                                    <Tooltip
                                                                        title={t('no_record').toUpperCase()}
                                                                        placement='right'
                                                                        disableInteractive
                                                                    >
                                                                        <CloseIcon />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <div>
                                                                        <Tooltip
                                                                            title={t(
                                                                                accessMethod[
                                                                                    attendance.entryAccessMethodId
                                                                                ],
                                                                            ).toUpperCase()}
                                                                            placement='right'
                                                                            disableInteractive
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    attendance.entryAccessMethodId === 1
                                                                                        ? tagIcon
                                                                                        : attendance.entryAccessMethodId ===
                                                                                            2
                                                                                          ? fingerIcon
                                                                                          : attendance.entryAccessMethodId ===
                                                                                              3
                                                                                            ? cardIcon
                                                                                            : attendance.entryAccessMethodId ===
                                                                                                4
                                                                                              ? qrIcon
                                                                                              : attendance.entryAccessMethodId ===
                                                                                                  5
                                                                                                ? faceIcon
                                                                                                : attendance.entryAccessMethodId ===
                                                                                                    6
                                                                                                  ? pinIcon
                                                                                                  : attendance.entryAccessMethodId ===
                                                                                                      7
                                                                                                    ? fingerIcon
                                                                                                    : attendance.entryAccessMethodId ===
                                                                                                        8
                                                                                                      ? bluetoothIcon
                                                                                                      : attendance.entryAccessMethodId ===
                                                                                                          9
                                                                                                        ? laptopIcon
                                                                                                        : attendance.exitAccessMethodId ===
                                                                                                            11
                                                                                                          ? CloseIcon
                                                                                                          : attendance.exitAccessMethodId ===
                                                                                                              12
                                                                                                            ? CloseIcon
                                                                                                            : attendance.exitAccessMethodId ===
                                                                                                                13
                                                                                                              ? platesIcon
                                                                                                              : attendance.exitAccessMethodId ===
                                                                                                                  14
                                                                                                                ? fingerIcon
                                                                                                                : attendance.exitAccessMethodId ===
                                                                                                                    15
                                                                                                                  ? fingerIcon
                                                                                                                  : attendance.exitAccessMethodId ===
                                                                                                                      16
                                                                                                                    ? CloseIcon
                                                                                                                    : attendance.entryAccessMethodId ===
                                                                                                                          10 &&
                                                                                                                      mobileIcon
                                                                                }
                                                                                alt='AccessMethod'
                                                                                style={{
                                                                                    width:
                                                                                        [6, 8, 9, 10].includes(
                                                                                            attendance.entryAccessMethodId,
                                                                                        ) && '30px',
                                                                                    height:
                                                                                        [6, 8, 9, 10].includes(
                                                                                            attendance.entryAccessMethodId,
                                                                                        ) && '30px',
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className=''>
                                                                {' '}
                                                                <div>
                                                                    {' '}
                                                                    {attendance.entryAccessMethodId === 9
                                                                        ? t('web_app').toUpperCase()
                                                                        : attendance.entryAccessMethodId === 10
                                                                          ? t('mobile_app').toUpperCase()
                                                                          : attendance.exitDate ===
                                                                                  attendance.entryDate ||
                                                                              attendance.entryDeviceName === null
                                                                            ? t('no_record').toUpperCase()
                                                                            : attendance.entryDeviceName}
                                                                </div>
                                                                <div
                                                                    className='date_col'
                                                                    style={{
                                                                        backgroundColor: entryDateColor,
                                                                        width: '125px',
                                                                        height: '25px',
                                                                    }}
                                                                >
                                                                    {attendance?.entryDate === null ? (
                                                                        t('no_record').toUpperCase().replace(' ', '\n')
                                                                    ) : (
                                                                        <>
                                                                            {moment(attendance?.entryDate).format(
                                                                                'HH:mm',
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className='no-record'
                                                            style={{ backgroundColor: statusColorSchedule[0] }}
                                                        >
                                                            {t('no_record').toUpperCase()}
                                                        </div>
                                                    )}
                                                </td>
                                                <td style={{ borderRadius: '0px' }}>
                                                    {attendance.exitAccessMethodId != null ? (
                                                        <div className='container-in container-line'>
                                                            <div className='icon-line'>
                                                                {attendance.exitAccessMethodId === null ? (
                                                                    <Tooltip
                                                                        title={t('no_record').toUpperCase()}
                                                                        placement='right'
                                                                        disableInteractive
                                                                    >
                                                                        <CloseIcon />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Tooltip
                                                                        title={t(
                                                                            accessMethod[attendance.exitAccessMethodId],
                                                                        ).toUpperCase()}
                                                                        placement='right'
                                                                        disableInteractive
                                                                    >
                                                                        <img
                                                                            src={
                                                                                attendance.exitAccessMethodId === 1
                                                                                    ? tagIcon
                                                                                    : attendance.exitAccessMethodId ===
                                                                                        2
                                                                                      ? fingerIcon
                                                                                      : attendance.exitAccessMethodId ===
                                                                                          3
                                                                                        ? cardIcon
                                                                                        : attendance.exitAccessMethodId ===
                                                                                            4
                                                                                          ? qrIcon
                                                                                          : attendance.exitAccessMethodId ===
                                                                                              5
                                                                                            ? faceIcon
                                                                                            : attendance.exitAccessMethodId ===
                                                                                                6
                                                                                              ? pinIcon
                                                                                              : attendance.exitAccessMethodId ===
                                                                                                  7
                                                                                                ? fingerIcon
                                                                                                : attendance.exitAccessMethodId ===
                                                                                                    8
                                                                                                  ? bluetoothIcon
                                                                                                  : attendance.exitAccessMethodId ===
                                                                                                      9
                                                                                                    ? laptopIcon
                                                                                                    : attendance.exitAccessMethodId ===
                                                                                                        11
                                                                                                      ? CloseIcon
                                                                                                      : attendance.exitAccessMethodId ===
                                                                                                          12
                                                                                                        ? CloseIcon
                                                                                                        : attendance.exitAccessMethodId ===
                                                                                                            13
                                                                                                          ? platesIcon
                                                                                                          : attendance.exitAccessMethodId ===
                                                                                                              14
                                                                                                            ? fingerIcon
                                                                                                            : attendance.exitAccessMethodId ===
                                                                                                                15
                                                                                                              ? fingerIcon
                                                                                                              : attendance.exitAccessMethodId ===
                                                                                                                    10 &&
                                                                                                                mobileIcon
                                                                            }
                                                                            alt='AccessMethod'
                                                                            style={{
                                                                                width:
                                                                                    [6, 8, 9, 10].includes(
                                                                                        attendance.exitAccessMethodId,
                                                                                    ) && '30px',
                                                                                height:
                                                                                    [6, 8, 9, 10].includes(
                                                                                        attendance.exitAccessMethodId,
                                                                                    ) && '30px',
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                            <div className='container-in-date'>
                                                                <div>
                                                                    {' '}
                                                                    {attendance.exitAccessMethodId === 9
                                                                        ? t('web_app').toUpperCase()
                                                                        : attendance.exitAccessMethodId === 10
                                                                          ? t('mobile_app').toUpperCase()
                                                                          : attendance.exitDate ===
                                                                                  attendance.entryDate ||
                                                                              attendance.exitDeviceName === null
                                                                            ? t('no_record').toUpperCase()
                                                                            : attendance.exitDeviceName}
                                                                </div>

                                                                <div
                                                                    className='date_col'
                                                                    style={{
                                                                        backgroundColor: exitDateColor,
                                                                        width: '125px',
                                                                        height: '25px',
                                                                    }}
                                                                >
                                                                    {attendance?.exitDate === null ? (
                                                                        t('no_record').toUpperCase().replace(' ', '\n')
                                                                    ) : (
                                                                        <>
                                                                            {moment(attendance?.exitDate).format(
                                                                                'HH:mm',
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='container-line'>
                                                            {' '}
                                                            <div
                                                                className='no-record'
                                                                style={{ backgroundColor: statusColorSchedule[0] }}
                                                            >
                                                                {t('no_record').toUpperCase()}
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <NotInformation text={t('no_information')} card={true} />
            )}
        </div>
    );
};

export default Tableweekly;
