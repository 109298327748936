import React from 'react';
import ic_clean from '../assets/images/ic-clean.svg';
import camera_plugin from '../assets/images/camera-plugin.svg';
import Tooltip from '@mui/material/Tooltip';

const CameraButton = ({ handleStream, flagTooltip, textTooltip, isSocketOpen }) => {
    return (
        <div className={isSocketOpen ? 'camera-plugin-icon-pushed' : 'camera-plugin-icon'}>
            {flagTooltip ? (
                <Tooltip title={textTooltip?.toUpperCase()} placement='right' arrow>
                    <button className='btn-option' onClick={handleStream}>
                        <img src={camera_plugin} alt='ic_clean' />
                    </button>
                </Tooltip>
            ) : (
                <button className='btn-option' onClick={handleStream}>
                    <img src={camera_plugin} alt='ic_clean' />
                </button>
            )}
        </div>
    );
};

export default CameraButton;
