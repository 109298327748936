import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { setDate } from 'date-fns';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import person5 from '../../assets/images/user-png-140.png';

import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GetGenderListProvider } from '../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import {
    CheckProviderImage,
    GetUserCompanyRestrictionData,
    GetUserExtraData,
    SaveProviderImage,
    UnlinkUserDevice,
    UpdateProviderData,
    UpdateProviderExtraData,
    UpdateProviderUserData,
    UploadProviderImage,
} from '../../reduxToolkit/Providers/providersApi';
import { GetHeaders } from '../../reduxToolkit/headers/HeadersApi';
import cryptoJs from 'crypto-js';
import securekey from '../../config';
import { permissionObj } from '../../Helpers/permission';
import { UserFullInformation } from '../../models/UserFullInformation';
import { GetUserFullInformation } from '../../reduxToolkit/Commons/CommonsApi';
import useStyle from '../../hooks/useStyle';
import Step1Details from '../Dashboard/Company/Employees/EnrollmentSteps/Step1Details';
import Step2OtherDetails from '../Dashboard/Company/Employees/EnrollmentSteps/Step2OtherDetails';
import CustomTextWithLine from '../../components/CustomTextWithLine';
import { userInfoStatus } from '../../reduxToolkit/CompanyEmployees/CompanyEmployeesApi';
import { GetUserRestriction } from '../../reduxToolkit/restrictions/RestrictionApi';
import { UpdateUserProfile } from '../../reduxToolkit/Commons/CommonsApi';
import { UpdateUserExtraData } from '../../reduxToolkit/Contractor/ContractorApi';
import UploadImageModal from '../../components/UploadImageModal';
import cancel from '../../assets/images/ic-cancel.svg';

const ProfileProvider = () => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    const { smallBoxStyle, textFieldCommon } = useStyle();

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    // token
    const token = sessionStorage.getItem('bearerToken');
    const bytes = cryptoJs.AES.decrypt(token, securekey);
    const bearerToken = bytes.toString(cryptoJs.enc.Utf8);
    // use hook
    let dispatch = useDispatch();
    const { headersList } = useSelector((state) => state.headersSlice);

    //use state
    const [modalShow, setModalShow] = React.useState(false);
    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();
    const [states, setStates] = useState();
    const [country, setCountry] = useState();
    const [postCode, setPostCode] = useState();
    const [homeNumber, setHomeNumber] = useState();
    const [arabicName, setArabicName] = useState();
    const [bloodType, setBloodType] = useState();
    const [town, setTown] = useState();
    const [contractorName, setContractorName] = useState();
    const [file, setFile] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [userFull, setUserFull] = useState(UserFullInformation);
    const [showModal, setShowModal] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [isValidDob, setIsValidDob] = useState(true);
    const [emptyFields, setEmptyFields] = useState(false);
    const [extra, setExtra] = useState(false);
    const [imageFile, setImageFile] = useState('');
    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));

    // use Selector from store

    const { getGnderListProvider } = useSelector((state) => state.EmployeeProviderSlice);
    const { getSingleUserProvider } = useSelector((state) => state?.providersSlice);
    const { getUserExtraData } = useSelector((state) => state?.providersSlice);
    const { getUserCompanyRestrictionData } = useSelector((state) => state?.providersSlice);
    const { checkProviderImage } = useSelector((state) => state?.providersSlice);
    const { getProviderImage } = useSelector((state) => state?.providersSlice);
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const userType = user?.data?.userType?.name;

    // a function for Password toggle handler
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    // useEffect for api and state manage
    useEffect(() => {
        dispatch(GetHeaders());
        dispatch(GetUserRestriction()).then(
            ({
                payload: {
                    data: { data },
                },
            }) => {
                let extraDataProperty =
                    userType === 'EMPLOYEE'
                        ? data?.extraDataEmployee
                        : userType === 'CONTRACTOR_IN_CHARGE'
                          ? data?.extraDataContractor
                          : userType === 'SUPPLIER_IN_CHARGE'
                            ? data?.extraDataSupplier
                            : null;
                setExtra(extraDataProperty);
            },
        );
        dispatch(userInfoStatus());
        dispatch(GetUserFullInformation(sessionStorage.getItem('id'))).then(
            ({
                payload: {
                    data: { data },
                },
            }) => {
                setUserFull((prevData) => ({
                    ...prevData,
                    ...data,
                    password: prevData.password,
                }));
            },
        );
    }, []);
    // useEffect for set value in field for update
    useEffect(() => {}, [getSingleUserProvider?.id, getUserExtraData?.id, user?.data?.id]);

    const handleUnlinkDevice = (props) => {
        dispatch(UnlinkUserDevice(sessionStorage.getItem('id'))).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success === true) {
                    dispatch(GetUserFullInformation(sessionStorage.getItem('id'))).then(
                        ({
                            payload: {
                                data: { data },
                            },
                        }) => {
                            setUserFull((prevData) => ({
                                ...prevData,
                                ...data,
                                password: prevData.password,
                            }));
                        },
                    );
                }
            },
        );
        props.onHide();
    };

    const handleFormChangeUserData = (e) => {
        const { name, value } = e.target;
        setUserFull((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const textFieldStyle = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
        },
    };

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                style={{ background: 'rgba(0,0,0,0.6)' }}
                className='department_and_user_delete-modal'
            >
                <Modal.Header className='header_top'>
                    <Modal.Title id='contained-modal-title-vcenter '>{t('unlink_device')}</Modal.Title>
                    <div className='modal-close-custom'>
                        <img
                            onClick={() => {
                                props.onHide();
                            }}
                            src={cancel}
                            alt=''
                        />
                    </div>
                </Modal.Header>

                <Modal.Body className='department_modal_body'>
                    <p className='paragraph_deps'>{t('unlink_confirmation_msg')}</p>

                    <div className='d-flex'>
                        <button
                            className='custom_btn_cancel_gray_hover'
                            style={{ width: '180px' }}
                            onClick={() => {
                                props.onHide();
                            }}
                        >
                            {t('cancel')}
                        </button>
                        <button
                            className='custom_primary_btn_dark'
                            style={{ width: '180px' }}
                            onClick={() => {
                                handleUnlinkDevice(props);
                            }}
                        >
                            {t('confirm').toUpperCase()}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    //uploading file state
    const [allUser, setAllUser] = useState(false);

    const [previewSize, setPreviewSize] = useState();
    const [companyImg, setCompanyImg] = useState();
    const [pdfFile, setPdfFile] = useState();

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const onImageChange = async (e) => {
        const originalFile = e.target.files[0];

        const checkExtension = originalFile['type'].split('/')[0] === 'image';

        //if input is image
        if (checkExtension) {
            setPdfFile('');
            setFile(originalFile);

            let formatedValue = formatBytes(originalFile?.size);

            setPreviewSize(formatedValue);

            const [file] = e.target.files;
            setCompanyImg(URL.createObjectURL(file));
        }

        //if input is file
        if (!checkExtension) {
            setCompanyImg('');
            const originalFile = e.target.files[0];
            setPdfFile(originalFile);

            let formatedValue = formatBytes(originalFile?.size);
            setPreviewSize(formatedValue);
        }
    };

    function AllUser(props) {
        return (
            <div className='primary-modal'>
                <Modal
                    {...props}
                    size='md'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                    style={{ background: 'rgba(0,0,0,.4)' }}
                >
                    <button
                        onClick={() => {
                            setCompanyImg();
                            props.onHide();
                        }}
                        className='modal-close-btn'
                    >
                        X
                    </button>
                    <Modal.Header>
                        <Modal.Title class='mt-2 text-center add_workshiftmodal_title d-flex justify-content-center flex-grow-1'>
                            <h4 className='text-center'>
                                <b>UPLOAD FILE</b>
                            </h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row ' style={{ width: '100%' }}>
                            <div style={{ width: '100%', margin: '15px' }}>
                                <div className='updata_img_m'>
                                    <label htmlFor='file-input' className='dottedborderbox'>
                                        <img src={'cloudsvg'} alt='submitupload' className='submitupload' />
                                        <input
                                            type='file'
                                            id='file-input'
                                            accept='image/png,image/jpg,image/jpeg'
                                            onChange={onImageChange}
                                        />
                                        <p>
                                            drag {'&'} drop <br /> your image <br /> size 20 mb max
                                        </p>
                                    </label>
                                </div>
                                <div className='col' style={{ width: '100%' }}>
                                    {companyImg ? (
                                        <img
                                            src={companyImg}
                                            className='previewImg'
                                            alt='imgs'
                                            style={{ width: '100%', height: '200px' }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <div>
                        <div className='btn-div'>
                            <button
                                className='button-sec btn-cancel'
                                style={{ color: 'red' }}
                                onClick={() => {
                                    setCompanyImg();
                                    props.onHide();
                                }}
                            >
                                CANCEL
                            </button>
                            <button
                                className='button-sec btn-confirm'
                                onClick={() => {
                                    // if(previewSize < 500000000){
                                    props.onHide();
                                    // }
                                    // else{
                                    //   toast.info("File size must be lest than 500KB")
                                    // }
                                }}
                            >
                                <b>APPLY CHANGES</b>
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    const handleSubmit = () => {
        setEmptyFields(true);
        if (
            userFull.name.trim() !== '' &&
            userFull.lastName.trim() !== '' &&
            userFull.phoneNumber.trim() !== '' &&
            userFull.phoneNumber.trim() !== '+' &&
            isValidDob &&
            isValidPhoneNumber
        ) {
            dispatch(
                UpdateUserProfile({
                    data: userFull.toUpdateNormal(),
                    file: imageFile,
                    updateImage: userFull.selfie !== null,
                }),
            ).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    setUserFull((prevData) => ({
                        ...prevData,
                        ...data,
                        password: prevData.password,
                    }));
                },
            );
            if (extra) {
                dispatch(UpdateUserExtraData({ id: sessionStorage.getItem('id'), body: userFull.toUpdateExtra() }));
            }
        } else {
            toast.warn(t('please_fill_all_fields').toUpperCase());
        }
    };

    return (
        <div style={{ height: extra !== true ? 'calc(100vh - 80px)' : '' }}>
            <div className='profile-sec'>
                <div className='image_upload_container mb-3'>
                    <div className='image_upload'>
                        {true ? (
                            <img
                                src={
                                    previewImage
                                        ? previewImage
                                        : userFull?.selfie
                                          ? `data:image/png;base64,${userFull?.selfie}`
                                          : person5
                                }
                                alt='dd'
                                style={{ objectFit: 'cover' }}
                            />
                        ) : (
                            <img
                                src={previewImage ? previewImage : person5}
                                alt='vehicle'
                                style={{ objectFit: 'cover' }}
                            />
                        )}
                        <div className='upload_icon_btn' onClick={() => setShowModal(true)}>
                            <i className='fa fa-long-arrow-right height' aria-hidden='true'></i>
                            <i className='fa fa-long-arrow-left' aria-hidden='true'></i>
                        </div>
                    </div>
                </div>

                <CustomTextWithLine title={t('user_data')} />

                <Step1Details
                    userData={userFull}
                    onChange={handleFormChangeUserData}
                    emptyFields={emptyFields}
                    isValidEmail={isValidEmail}
                    setIsValidEmail={setIsValidEmail}
                    isValidPhoneNumber={isValidPhoneNumber}
                    setIsValidPhoneNumber={setIsValidPhoneNumber}
                    isValidDob={isValidDob}
                    setIsValidDob={setIsValidDob}
                    setUserData={setUserFull}
                    profile={true}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                />

                {extra === true && (
                    <>
                        <div className='mt-5'>
                            <CustomTextWithLine title={t('extra_data')} />
                        </div>

                        <Step2OtherDetails
                            extraData={userFull}
                            onChange={handleFormChangeUserData}
                            setExtraData={setUserFull}
                            headersList={headersList}
                            profile={true}
                        />
                    </>
                )}

                <div className='name'>
                    {getUserCompanyRestrictionData?.extraDataExternal == true && (
                        <Grid container spacing={2}>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header1}
                                    id='NAME'
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header2}
                                    id='NAME'
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header3}
                                    id='toen'
                                    value={town}
                                    onChange={(e) => setTown(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header4}
                                    id='NAME'
                                    value={states}
                                    onChange={(e) => setStates(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>{' '}
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header5}
                                    id='NAME'
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header6}
                                    id='NAME'
                                    value={postCode}
                                    onChange={(e) => setPostCode(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header7}
                                    id='NAME'
                                    value={homeNumber}
                                    onChange={(e) => setHomeNumber(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header8}
                                    id='NAME'
                                    value={arabicName}
                                    onChange={(e) => setArabicName(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header9}
                                    id='NAME'
                                    value={contractorName}
                                    onChange={(e) => setContractorName(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={headersList?.header10}
                                    id='NAME'
                                    value={bloodType}
                                    onChange={(e) => setBloodType(e.target.value)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            background: '#ffffff',
                                            padding: '0px 8px 0px 8px',
                                        },
                                    }} // font size of input label
                                    inputProps={{
                                        sx: {
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '10px',
                                            letterSpacing: '0px',
                                            color: '#707070',
                                            '&::placeholder': {
                                                color: '#707070',
                                                fontSize: '8px',
                                            },
                                        },
                                    }}
                                    sx={lCode === 'ar' ? textFieldStyle : ''}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {(permission?.includes(permissionObj.WEB_PROFILE_UPDATE) ||
                        contractorId !== null ||
                        supplierId !== null) && (
                        <div className='btn-container mt-3'>
                            <button className='submit-primary-btn' onClick={handleSubmit}>
                                <span>{t('update_data').toUpperCase()}</span>
                            </button>
                        </div>
                    )}
                </div>
                <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
                <AllUser show={allUser} onHide={() => setAllUser(false)} />

                <UploadImageModal
                    titlen={t('add_image')}
                    show={showModal}
                    setImage={setImageFile}
                    preview={previewImage}
                    setPreview={setPreviewImage}
                    onHide={() => setShowModal(false)}
                />
            </div>
        </div>
    );
};

export default ProfileProvider;
