export const logMessage = {
    10: 'INTERNET_CONNECTED',
    11: 'INTERNET_DISCONNECTED',
    30: 'BLUETOOTH_CONNECTED',
    31: 'BLUETOOTH_DISCONNECTED',
    41: 'SETTINGS_ERROR_SILENT_MODE_IS_ACTIVE',
    45: 'SERVER_DISCONNECTED',
    199: 'FAR_SUCCESS',
    '-1': 'FAR_NO_ACTIVATION_FILE',
    5: 'FAR_BAD_STATE',
    90118: 'FAR_DEVICE_MISMATCH',
    100: 'FAR_IR_CAMERA_NOT_SUPPORT',
    104: 'FAR_RGB_CAMERA_ERROR',
    105: 'FAR_IR_CAMERA_ERROR',
    106: 'FAR_BAD_STATE_F10',
    107: 'FAR_UNKNOWN',
    200: 'PTR_SUCCESS',
    201: 'PTR_NO_DEVICE_CONNECTED',
    202: 'PTR_CONNECTION_FAILED',
    203: 'PTR_INITIALIZE_FAILED',
    204: 'PTR_LID_IS_OPEN',
    205: 'PTR_NO_PAPER',
    206: 'PTR_PAPER_IS_ABOUT_TO_END',
    207: 'PTR_PRINT_TEXT',
    208: 'PTR_PRINT_QR_CODE',
    209: 'PTR_PRINT_AND_FEED',
    210: 'PTR_PARTIAL_CUT',
    211: 'PTR_PARTIAL_CUT_FEED',
    212: 'PTR_UNKNOWN',
    399: 'FIR_SUCCESS',
    '-1140': 'FIR_TIME_OUT',
    '-1307': 'FIR_NO_DEVICE_CONNECTED',
    300: 'FIR_LIBRARY_NOT_INITIALIZED',
    301: 'FIR_ERROR_TURNING_ON_THE_READER',
    302: 'FIR_USB_CONFIGURATION',
    303: 'FIR_NO_CONNECTED_DEVICE_FOUND',
    304: 'FIR_USB_CONFIGURATION_TPS_450',
    305: 'FIR_NO_CONNECTED_DEVICE_FOUND_TPS_450',
    306: 'FIR_THIS_DEVICE_IS_NOT_A_SUPREMA_DEVICE',
    307: 'FIR_THIS_DEVICE_IS_NOT_SUPPORT_AUTO_CAPTURE',
    308: 'FIR_FACE_MASK_ALGORITHM_NOT_INITIALIZED',
    310: 'FIR_ERROR_CAPTURE',
    311: 'FIR_ERROR_WRITE_TEMPLATE',
    312: 'FIR_ERROR_GET_EMPTY_ID',
    313: 'FIR_ERROR_DELETE_TEMPLATE',
    314: 'FIR_ERROR_DELETE_ALL_TEMPLATES',
    315: 'FIR_ERROR_EXTRACT_ANSI_TEMPLATE',
    316: 'FIR_INCOMPLETE_FINGERPRINT_READING',
    317: 'FIR_ERROR_CAPTURE_ABORTING',
    329: 'FIR_ERROR_UNKNOWN',
    330: 'FIR_ERROR_TURNING_OFF_THE_READER',
    400: 'RFID_DEVICE_DOES_NOT_SUPPORT_NFC',
    401: 'RFID_NFC_DISABLED',
    410: 'RFID_UNWRITTEN_CARD',
    411: 'RFID_COULD_NOT_READ_CARD_DATA',
    412: 'RFID_CARD_WITH_INCOMPLETE_DATA',
    413: 'RFID_CARD_WITH_INCORRECT_DATA',
    414: 'RFID_INCOMPLETE_READING',
    415: 'RFID_WRONG_CARD',
    416: 'RFID_UNKNOWN_ERROR',
    419: 'RFID_SUCCESS',
    450: 'LF_READER_DID_NOT_START',
    459: 'LF_SUCCESS',
    500: 'QR_CODE_READER_DID_NOT_START',
    599: 'QR_SUCCESS',
    600: 'AB_INCORRECT_DATA',
    601: 'AB_NO_CONFIGURATION_INFORMATION',
    602: 'AB_INCORRECT_CONFIGURATION',
    603: 'AB_LOST_CONNECTION',
    604: 'AB_UNKNOWM_ERROR',
    609: 'AB_SUCCESS',
    701: 'TTL_LOST_CONNECTION',
    702: 'TTL_UNKNOWN_ERROR',
    703: 'TTL_ERROR_REMOVE_SMART_LOCK_FROM_SERVER',
    707: 'TTL_UNLINKING',
    708: 'TTL_DATA_DOWNLOAD_SUCCESS',
    709: 'TTL_SUCCESS',
    3000: 'UNKNOWN_ERROR',
};
