import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ getColor, initialColor }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState({ hex: initialColor });

    useEffect(() => {
        setColor({ hex: initialColor });
    }, [initialColor]);

    const handleClick = () => {
        setDisplayColorPicker(true);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {
        setColor(color);
        getColor(color.hex);
    };

    const swatch = {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    };

    const colorStyle = {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${color.hex}`,
    };

    const popover = {
        position: 'absolute',
        zIndex: '2',
    };

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };

    return (
        <div className='w-100'>
            <div className='w-100' style={swatch} onClick={handleClick}>
                <div className='w-100' style={colorStyle} />
            </div>
            {displayColorPicker ? (
                <div style={popover}>
                    <div style={cover} onClick={handleClose} />
                    <SketchPicker color={color.hex} onChange={handleChange} disableAlpha presetColors={[]} />
                </div>
            ) : null}
        </div>
    );
};

export default ColorPicker;
