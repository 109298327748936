/*
Author : Arman Ali
Module: create Vehicle
github: https://github.com/Arman-Arzoo
*/
import React from 'react';

const DividerWithText = ({ title, spacing }) => {
    //title : title name with line

    return (
        <div className={`divider-with-text ${spacing}`}>
            <span className='mr-2'>{title}</span>
            <div className='divider-common'></div>
        </div>
    );
};

export default DividerWithText;
