import { UpdateAttendanceDepartment } from '../../../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ShiftTimePicker from '../../ShiftTimePicker';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const UpdateSchedule = ({ show, onHide, setUpdatedFlag, dataUpdate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [startShiftAt, setStartShiftAt] = useState(dataUpdate.startShiftAt);
    const [endShiftAt, setEndShiftAt] = useState(dataUpdate.endShiftAt);
    const [range, setRange] = useState(dataUpdate.range);
    const [selectedDepartment, setSelectedDepartment] = useState(dataUpdate.departmentId);
    const [id, setId] = useState(dataUpdate.id);

    const handleUpdate = () => {
        const body = {
            departmentId: selectedDepartment,
            id: id,
            endShiftAt: endShiftAt,
            range: range,
            startShiftAt: startShiftAt,
        };

        dispatch(UpdateAttendanceDepartment(body)).then(
            ({
                payload: {
                    data: { success, data, code },
                },
            }) => {
                if (success === true) {
                    toast.success(t('has_been_updated_successfully').toUpperCase());
                    setUpdatedFlag(true);
                    onHide();
                } else {
                    if (code === 832) {
                        toast.error(t('attendance_department_already_exists').toUpperCase());
                        onHide();
                    } else {
                        toast.error(t('Error_creating_controller').toUpperCase());
                        onHide();
                    }
                }
            },
        );
    };

    return (
        <Modal
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{ background: 'rgba(0,0,0,0.5)' }}
            show={show}
            onHide={onHide}
        >
            <button className='modal-close-btn' onClick={onHide}>
                X
            </button>
            <span className='main-modal-heading'>{t('update_schedule')}</span>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <ShiftTimePicker
                    startShiftAt={startShiftAt}
                    setStartShiftAt={setStartShiftAt}
                    endShiftAt={endShiftAt}
                    setEndShiftAt={setEndShiftAt}
                    range={range}
                    setRange={setRange}
                    selectedDepartment={selectedDepartment}
                    setSelectedDepartment={setSelectedDepartment}
                    displayNone={selectedDepartment === null}
                />
            </div>
            <div className='unlink-modal-body'>
                <div className='btn-div'>
                    <button
                        className='custom_btn_cancel_gray_hover'
                        id='btn_us_cancel'
                        style={{ width: '100%' }}
                        onClick={onHide}
                    >
                        {t('cancel').toLocaleUpperCase()}
                    </button>
                    <button
                        className='custom_primary_btn_dark'
                        id='btn_us_confirm'
                        style={{ width: '100%' }}
                        onClick={handleUpdate}
                    >
                        {t('confirm').toLocaleUpperCase()}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateSchedule;
