import React from 'react';

export const TemplateUno = () => {
    return (
        <div className='template1'>
            <div
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '2px',
                    width: '90px',
                    height: '35px',
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-b'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '2px',
                    width: '50px',
                    height: '35px',
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '2px',
                    width: '145px',
                    height: '35px',
                    backgroundColor: '#666',
                }}
            ></div>
        </div>
    );
};
export const TemplateDos = () => {
    return (
        <div className='template2'>
            <div
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '70px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-b'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '70px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '70px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '70px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
        </div>
    );
};

export const TemplateTres = () => {
    return (
        <div className='template3'>
            <div
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '79px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-b'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '59px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '45px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '45px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '45px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
        </div>
    );
};

export const TemplateCuatro = () => {
    return (
        <div className='template3'>
            <div
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '37px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-b'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '38px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '65px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '40px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '33px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '33px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
            <div
                className='section-c'
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '5px',
                    margin: '1px',
                    width: '33px',
                    height: '35px', // Cambiado a 35px
                    backgroundColor: '#666',
                }}
            ></div>
        </div>
    );
};
