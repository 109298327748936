import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import deleteIcon from '../../../../assets/images/ic-delete-red.svg';
import warningIcon from '../../../../assets/images/warning.svg';
import TablePagination from '@mui/material/TablePagination';
import { Accordion } from 'react-bootstrap';
import DeleteRoleModal from './components/DeleteRoleModal';
import ManageRoleModal from './components/ManageRoleModal';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import {
    getAllEmployees,
    getAllroleEmployeesPageable,
    getEmployeeRoles,
    getEmployesWithRole,
    removeRoleToUserByIds,
    roleAvailableTasks,
} from '../../../../reduxToolkit/EmployeeRoles/EmployeeRolesApi';
import { permissionObj } from '../../../../Helpers/permission';
import { EmployeesInCreate, HandleEmployeesWithRole } from '../../../../reduxToolkit/EmployeeRoles/EmployeeRolesSlice';
import { Box, Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import { DeleteItemsApi } from '../../../../reduxToolkit/Commons/CommonsApi';
import DeleteModal from '../../../Modals/DeleteModal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import NotInformation from '../../../../components/NotInformation';
import { TABLES } from '../../../../Apis/Tables';
import { SimpleSearch } from '../../../../reduxToolkit/Search/SearchApi';
import { SearchRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import SearchSimple from '../../../../components/SearchSimples/SearchSimple';
import ExportDataBtn from '../../../../components/buttons/ExportDataBtn';

const RolesPanel = () => {
    // use hook
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // use selector
    const { deleteItemsApi } = useSelector((state) => state.CommonsSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    const {
        roleList,
        pageableEmployeesList,
        addUsersToRoleSuccess,
        removeRoleToUser,
        updateRoleSuccess,
        creteRoleSucce,
    } = useSelector((state) => state?.EmployeeRolesSlice);
    // useState
    const [show, setShow] = useState(false);
    const [manageShow, setManageShow] = useState(false);
    const [roleId, setroleId] = useState();
    const [deleteId, setDeleteId] = useState();
    const [deleteItemName, setdeleteItemName] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [empPage, setEmpPage] = useState(0);
    const [empRowsPerPage, setEmpRowsPerPage] = useState(10);
    const [selectedRole, setSelectedRole] = useState();
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectRoleForDelete, setSelectRoleForDelete] = useState([]);
    const [roleShow, setRoleShow] = useState(false);
    const [isAllCheckedUser, setIsAllCheckedUser] = useState(false);
    const [selectUserForDelete, setSelectUserForDelete] = useState([]);
    const [userShow, setUserShow] = useState(false);
    const [topHeaderHeight, setTopHeaderHeight] = useState('');
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState();
    const option = TABLES.ROLE;
    const headerSectionHeight = useRef(null);

    // funtions
    // this function control select all id or unSelect all
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectRoleForDelete([...selectRoleForDelete, e.target.id]);
        } else {
            setSelectRoleForDelete(selectRoleForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };
    // this function control select all id or unSelect all
    const handelDeleteAllUser = (e) => {
        setIsAllCheckedUser(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = pageableEmployeesList?.content?.map((item) => {
                return item?.userId;
            });
            setSelectUserForDelete(selectAllIds);
        } else {
            setSelectUserForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChangeUser = (e) => {
        if (e.target.checked) {
            setSelectUserForDelete([...selectUserForDelete, e.target.id]);
        } else {
            setSelectUserForDelete(selectUserForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };
    // api function
    const deleteRoles = (deleteItem) => {
        const tableName = 'role';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body }));
        setSelectRoleForDelete([]);
        setIsAllChecked(false);
    };
    // api function
    const deleteUsers = (deleteItem) => {
        const data = {
            userIds: deleteItem,
            roleId: roleId,
        };
        dispatch(removeRoleToUserByIds(data));
        setSelectUserForDelete([]);
        setIsAllCheckedUser(false);
    };
    // lifecycle
    useEffect(() => {
        const body = {
            order: true,
            page: page,
            size: rowsPerPage,
            sortBy: 'id',
        };
        dispatch(getEmployeeRoles(body));
    }, [page, rowsPerPage, updateRoleSuccess, creteRoleSucce, deleteItemsApi]);

    useEffect(() => {
        if (selectedRole) {
            const employeeBody = {
                id: selectedRole?.id,
                pagination: {
                    order: true,
                    page: empPage,
                    size: empRowsPerPage,
                    sortBy: 'id',
                },
            };
            dispatch(getAllroleEmployeesPageable(employeeBody));
            setSearchElement('');
        }
    }, [empPage, empRowsPerPage, selectedRole, , addUsersToRoleSuccess, removeRoleToUser]);
    const handleManageUsers = (selectedItem) => {
        dispatch(getEmployesWithRole(selectedItem?.id));
        dispatch(getAllEmployees());
        setroleId(selectedItem?.id);
        setManageShow(true);
    };
    useEffect(() => {
        dispatch(roleAvailableTasks());
    }, []);
    useEffect(() => {
        if (headerSectionHeight.current) {
            const height = headerSectionHeight.current.clientHeight;
            setTopHeaderHeight(height);
        }
    }, []);
    // ¿QUÉ VA A MOSTRAR O ELIMINAR EL USUARIO?
    let resultsToShow;
    if (
        !searchResults ||
        searchResults?.content?.length === 0 ||
        searchResults === null ||
        searchResults === undefined
    ) {
        if (!searchResults) {
            resultsToShow = roleList;
        } else {
            resultsToShow = null;
        }
    } else if (searchResults?.content?.length > 0) {
        resultsToShow = searchResults;
    }
    // HANDLE DELETE ALL
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            // logic to filter the root and none
            // const selectAllIds = roleList?.content
            const selectAllIds = resultsToShow?.content
                ?.filter((item) => {
                    return item?.name !== 'ROOT' && item?.name !== 'NONE';
                })
                .map((item) => item?.id);

            setSelectRoleForDelete(selectAllIds);
        } else {
            setSelectRoleForDelete([]);
        }
    };
    return (
        <>
            <div className='top_section_wrapper' ref={headerSectionHeight}>
                <div className='head'>
                    <div className='headLeft'>
                        <h2>{t('privilage_settings')}</h2>
                    </div>
                    <div className='d-flex gap-1'>
                        {searchResults && bodyForExport && <ExportDataBtn body={bodyForExport} option={false} />}
                        {permission?.includes(permissionObj?.WEB_PRIVILEGE_CREATE) && (
                            <button
                                className='add-btn-1'
                                disabled={selectRoleForDelete?.length > 0}
                                style={{ opacity: selectRoleForDelete?.length === 0 ? '1' : '0.5' }}
                                onClick={() => {
                                    dispatch(EmployeesInCreate([]));
                                    dispatch(HandleEmployeesWithRole([]));
                                    navigate('/dashboard/employee/company/add-new-role');
                                }}
                            >
                                <i className='fa fa-plus' aria-hidden='true'></i>
                                <span>{t('add')}</span>
                            </button>
                        )}
                        {permission?.includes(permissionObj?.WEB_PRIVILEGE_DELETE) && (
                            <button
                                className='delete-btn-1'
                                disabled={selectRoleForDelete?.length === 0}
                                onClick={() => {
                                    setRoleShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>
                </div>
                <SearchSimple
                    setBodyForExport={setBodyForExport}
                    setSearchResults={setSearchResults}
                    searchElement={searchElement}
                    setSearchElement={setSearchElement}
                    optionTable={option}
                    page={page}
                    rowsPerPage={rowsPerPage}
                />
                <div className='panelTables animated-div px-1'>
                    <table className='w-100'>
                        <thead className='no-border-thead'>
                            <section className='table-header'>
                                <section className='checkbox-and-name--WorkShift'>
                                    <th className='first_head'>
                                        <Tooltip
                                            title={t('de_/_select_all').toUpperCase()}
                                            placement='top'
                                            disableInteractive
                                        >
                                            <Checkbox
                                                label='Label'
                                                checked={selectRoleForDelete?.length > 0}
                                                onChange={handelDeleteAll}
                                                indeterminate={
                                                    selectRoleForDelete?.length > 0 &&
                                                    roleList?.content?.length !== selectRoleForDelete?.length
                                                }
                                                size='small'
                                            />
                                        </Tooltip>
                                    </th>
                                    <th>{t('name')}</th>
                                </section>
                                <section>
                                    <th>{t('update')}</th>
                                    <th className='last'>{t('see_more')}</th>
                                </section>
                            </section>
                        </thead>
                    </table>
                </div>
            </div>
            <Box className='scroll_bar_fit_hight_wrapper' sx={{ height: `calc(100vh - ${topHeaderHeight + 70}px )` }}>
                <div className='rolesPanel'>
                    <Accordion>
                        {resultsToShow?.content?.length > 0 ? (
                            resultsToShow?.content?.map((item, index) => (
                                <Accordion.Item eventKey={item?.id} key={item?.id}>
                                    <Accordion.Header
                                        onClick={() => {
                                            setSelectedRole(item);
                                            setroleId(item?.id);
                                            setSelectUserForDelete([]);
                                            setIsAllCheckedUser(false);
                                        }}
                                    >
                                        <div className='rolesHeader'>
                                            <div className='leftText'>
                                                {item?.name !== 'ROOT' && item?.name !== 'NONE' ? (
                                                    <Checkbox
                                                        onClick={(event) => event.stopPropagation()}
                                                        className='grid-checkall checkbox'
                                                        checked={selectRoleForDelete?.includes(item?.id)}
                                                        id={item?.id}
                                                        onChange={handleCheckboxChange}
                                                        size='small'
                                                    />
                                                ) : (
                                                    <p></p>
                                                )}
                                                <p>{item.name}</p>
                                            </div>

                                            {permission?.includes(permissionObj?.WEB_PRIVILEGE_UPDATE) &&
                                            item?.name !== 'ROOT' &&
                                            item?.name !== 'NONE' ? (
                                                <div className='edit'>
                                                    <EditOutlinedIcon
                                                        onClick={() => {
                                                            dispatch(getEmployesWithRole(item?.id));
                                                            dispatch(getAllEmployees());

                                                            navigate(
                                                                `/dashboard/employee/company/add-update-role/${item?.id}`,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='roleBody'>
                                            <div className='upper'>
                                                <p className='heading'>{t('assigned_employees')?.toUpperCase()}</p>
                                                {permission?.includes(permissionObj?.WEB_PRIVILEGE_MANAGE_USERS) && (
                                                    <p className='manage' onClick={() => handleManageUsers(item)}>
                                                        {t('manage_users')}
                                                    </p>
                                                )}
                                            </div>
                                            {pageableEmployeesList?.totalElements !== 0 ? (
                                                // {pageableEmployeesList?.totalElements !== 0 ? (
                                                <>
                                                    <div className='d-flex gap-1'>
                                                        <FormControlLabel
                                                            className='grid-checkall'
                                                            control={
                                                                <Checkbox
                                                                    label='Label'
                                                                    checked={selectUserForDelete?.length > 0}
                                                                    indeterminate={
                                                                        selectUserForDelete?.length > 0 &&
                                                                        pageableEmployeesList?.content?.length !==
                                                                            selectUserForDelete?.length
                                                                    }
                                                                    onChange={handelDeleteAllUser}
                                                                    size='small'
                                                                />
                                                            }
                                                            label={t('de_/_select_all')}
                                                        />
                                                    </div>
                                                    <div className='nameList row'>
                                                        {pageableEmployeesList?.content?.map((item) => (
                                                            <div className='col-3 my-1' key={item.id}>
                                                                <Checkbox
                                                                    className='grid-checkall checkbox'
                                                                    checked={selectUserForDelete?.includes(
                                                                        item?.userId,
                                                                    )}
                                                                    id={item?.userId}
                                                                    onChange={handleCheckboxChangeUser}
                                                                    size='small'
                                                                />

                                                                <span>
                                                                    {item && item.name
                                                                        ? `${item.name} ${item.firstLastName} ${
                                                                              item.secondLastName !== null
                                                                                  ? item.secondLastName
                                                                                  : ''
                                                                          }`
                                                                        : '-'}
                                                                </span>
                                                            </div>
                                                        ))}
                                                        <div className='d-flex justify-content-center '>
                                                            <TablePagination
                                                                component='div'
                                                                rowsPerPageOptions={[10, 15, 20, 30]}
                                                                count={pageableEmployeesList?.totalElements}
                                                                page={empPage}
                                                                onPageChange={(_, newPage) => setEmpPage(newPage)}
                                                                labelRowsPerPage={t('users_per_page')}
                                                                rowsPerPage={empRowsPerPage}
                                                                onRowsPerPageChange={(event) => {
                                                                    setEmpRowsPerPage(parseInt(event.target.value));
                                                                    setEmpPage(0);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    {selectUserForDelete?.length > 0 && (
                                                        <div
                                                            className='remove_selected_role'
                                                            onClick={() => setUserShow(true)}
                                                        >
                                                            <p>{t('remove_selected')?.toUpperCase()}</p>
                                                            <i className='fa fa-trash-o' aria-hidden='true'></i>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <NotInformation text={t('no_information')} card={false} />
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        ) : (
                            <NotInformation text={t('no_information')} card={false} />
                        )}
                    </Accordion>
                </div>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    left: '45%',
                    bottom: '0',
                }}
            >
                {resultsToShow?.content?.length > 0 && (
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[10, 15, 20, 30]}
                        // count={roleList?.totalElements}
                        count={resultsToShow?.totalElements}
                        page={page}
                        onPageChange={(_, newPage) => {
                            setPage(newPage);
                        }}
                        labelRowsPerPage={t('privileges_per_page')}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value));
                            setPage(0);
                        }}
                    />
                )}
            </Box>
            <DeleteRoleModal
                show={show}
                onHide={() => setShow(false)}
                deleteid={deleteId}
                deleteitemname={deleteItemName}
            />
            <ManageRoleModal show={manageShow} onHide={() => setManageShow(false)} roleid={roleId} />

            <DeleteModal
                show={roleShow}
                onHide={() => setRoleShow(false)}
                onClickFn={() => deleteRoles(selectRoleForDelete)}
                data={selectRoleForDelete}
                title_modal={'Privilege'}
                element_modal={'Privilege'}
                isReset={setSelectRoleForDelete}
                isAllReset={setIsAllChecked}
            />
            <DeleteModal
                show={userShow}
                onHide={() => setUserShow(false)}
                onClickFn={() => deleteUsers(selectUserForDelete)}
                data={selectUserForDelete}
                title_modal={'Privilege'}
                element_modal={'assigned_employee'}
                isReset={setSelectUserForDelete}
                isAllReset={setIsAllCheckedUser}
            />
        </>
    );
};
export default RolesPanel;
