import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DocumentValidation from './DocumentValidation/DocumentValidation';
import ValidatedIdentity from './FailValidation/ValidatedIdentity';
import { setUserId, setUserValidatedId } from '../../reduxToolkit/Visitor/VisitorSlice';
import { URL } from '../../Apis/Constants';
import FullPageLoader from '../../utils/FullPageLoader';

const VeridasOnboarding = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const [userValidatedId, setUserValidatedIdLocal] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (userId && userId !== 'undefined') {
            setLoading(true);
            fetch(`${URL}user-service/identity-validation/${userId}`, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setUserValidatedIdLocal(data.data);
                    dispatch(setUserId(userId));
                    dispatch(setUserValidatedId(data.data));
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('There was a problem with the fetch operation:', error);
                    setLoading(false);
                    navigate(`/user-id-not-exist`);
                });
        } else {
            console.error('userId is undefined or invalid');
            setLoading(false);
        }
    }, [dispatch, userId, navigate]);

    if (loading) {
        return <FullPageLoader />;
    }

    return <div>{userValidatedId ? <ValidatedIdentity /> : <DocumentValidation />}</div>;
};

export default VeridasOnboarding;
