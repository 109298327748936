import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import apiInstance from '../../../Apis/Axios';
import emptyList from '../../../assets/images/warning.svg';
import { toast } from 'react-toastify';
import {
    ContractorDownloadDocuments,
    GetAllVehicleByContractorId,
    GetVehicleWithDocumentById,
} from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Grid } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import profileDemo from '../../../assets/images/userDemoImg.png';
import {
    allVehicleByContractorId,
    vehicleWithDocumentById,
} from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsSlice';
import ic_edit from '../../../assets/icon/ic-edit-pencil.svg';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import DocumentTable from '../../Modals/DocumentTable';
import { permissionObj } from '../../../Helpers/permission';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import car from '../../../assets/defaultImages/defaultCar.svg';
import { GoPrimitiveDot } from 'react-icons/go';
import { status } from '../../../enums/statusEnum';
import {
    CreateSupplierDocValue,
    DownloadProviderImage,
    GetProviderEmployeeDetail,
    GetSingleProvider,
    SetSupplierDocValue,
} from '../../../reduxToolkit/Providers/providersApi';
import {
    DownloadEmployeeProviderOrderFiles,
    GetAllCompanybyProviderId,
    GetAllCompanyVehiclebyId,
    GetAllProviderDocuments,
    GetEmployeeProviderById,
    GetEmployeeSupplierByItId,
} from '../../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { GetEmployeeContractorByItId } from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import {
    CreateToExternalVehicle,
    GetProviderVehicleDetail,
    GetProviderVehicleStatus,
    GetSingleProviderVehicle,
    SetToExternalVehicle,
} from '../../../reduxToolkit/Providers/providersApi';
import DeleteModal from '../../Modals/DeleteModal';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import { gender } from '../../../enums/genderEnum';
import NotInformation from '../../../components/NotInformation';
import { fieldOptions } from './../../../Apis/StaticFieldOptions';
import { statusColor } from './../../../enums/statusColorEnum';

let docID;
const ElementDetailsType = ({ isVehicle, isContractEmployee, isContractVehicle }) => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    // let path = state?.approveDoc ? true : false;
    const [approveDocument, setapproveDocument] = useState();
    const getAllVehicleByContractorId = useSelector(allVehicleByContractorId);
    const getVehicleWithDocumentById = useSelector(vehicleWithDocumentById);
    const [fileIdPresent, setfileIdPresent] = useState(true);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { getProviderEmployeeDetail } = useSelector((state) => state?.providersSlice);
    const { getAllProviderDocuments } = useSelector((state) => state.EmployeeProviderSlice);
    const { getEmployeeProviderById } = useSelector((state) => state.EmployeeProviderSlice);
    const { getEmployeeProviderByItId } = useSelector((state) => state.EmployeeProviderSlice);
    const { getAllCompanybyProviderId } = useSelector((state) => state.EmployeeProviderSlice);
    const { getAllCompanyVehiclebyId } = useSelector((state) => state.EmployeeProviderSlice);
    const { getEmployeeContractorByItId } = useSelector((state) => state.EmployeeContractorsSlice);
    const { saveProviderImage, imageChangeCheck } = useSelector((state) => state.providersSlice);
    const {
        getProviderVehicleDetail,
        createToExternalVehicle,
        setToExternalVehicle,
        updateProviderVehicleData,
        saveProviderVehicleImage,
    } = useSelector((state) => state?.providersSlice);
    const { uploadImage, changeCount } = useSelector((state) => state.sharedSlice);

    const lCode = Cookies.get('i18next') || 'en';
    const { t } = useTranslation();

    const [elementDetails, setElementDetails] = useState();
    const [elementEmployees, setElementEmployees] = useState();
    const [elementDocuments, setElementDocuments] = useState();
    const [elementVehicles, setElementVehicles] = useState();

    const [searchEmp, setSearchEmp] = useState('');
    const [searchVeh, setSearchVeh] = useState('');

    ////////////////
    //pagination
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [pageVeh, setPageVeh] = useState(0);
    const [rowsPerPageVeh, setRowsPerPageVeh] = useState(4);

    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [contractId, setContractId] = useState(sessionStorage.getItem('contractId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [employeeOption, setEmployeeOption] = useState(sessionStorage.getItem('sidebarOption'));
    const [orderId, setOrderId] = useState(sessionStorage.getItem('supplier_order_id'));
    const [selectElementForDelete, setSlectElementForDelete] = useState([]);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteElementShow, setDeleteElementShow] = useState(false);

    const handleChangePageVeh = (event, newPage) => {
        setPageVeh(newPage);
    };
    const handleChangeRowsPerPageVeh = (event) => {
        setRowsPerPageVeh(parseInt(event.target.value));
        setPageVeh(0);
    };

    //end pagination

    const [pageEmp, setPageEmp] = useState(0);
    const [rowsPerPageEmp, setRowsPerPageEmp] = useState(4);

    const handleChangePageEmp = (event, newPage) => {
        setPageEmp(newPage);
    };
    const handleChangeRowsPerPageEmp = (event) => {
        setRowsPerPageEmp(parseInt(event.target.value));
        setPageEmp(0);
    };

    //end Emp pagination

    const getContractorDetail = async () => {
        if (contractorId === null && isContractEmployee !== true) {
            await apiInstance
                .get(`contractor-service/get-by-id/${params?.id}`)
                .then(function (response) {
                    if (response.status == 200) {
                        setElementDetails(response?.data?.data);
                        docID = response?.data?.data?.user?.id;
                        getContractorDocument();
                    }
                })
                .catch(function (error) {
                    toast(error?.response?.data?.message).toUpperCase();
                    document.getElementById('overlay').style.display = 'none';
                });
        } else {
            await apiInstance
                .get(`contractor-employee-service/company/get-by-user-id/${params?.id}`)
                .then(function (response) {
                    if (response.status == 200) {
                        setElementDetails(response?.data?.data);
                        docID = response?.data?.data?.user?.id;
                        setElementDocuments(response?.data?.data?.documents);
                    }
                })
                .catch(function (error) {
                    toast(error?.response?.data?.message).toUpperCase();
                    document.getElementById('overlay').style.display = 'none';
                });
        }
    };

    const getCompanyByContractor = async () => {
        const pagination = {
            order: sortBy === 'asc' ? true : false,
            page: pageEmp,
            size: rowsPerPageEmp,
            sortBy: orderBy ? orderBy : 'id',
        };
        await apiInstance
            .post(`contractor-employee-service/get-all-pageable/company/by-contractor-id/${params?.id}`, pagination)
            .then(function (response) {
                if (response.status == 200) {
                    setElementEmployees(response?.data?.data);
                }
            })
            .catch(function (error) {
                toast(error?.response?.data?.message).toUpperCase();
                document.getElementById('overlay').style.display = 'none';
            });
    };

    const getContractorDocument = async () => {
        await apiInstance
            .get(`document-service/contractor/get-all/by-user-id/${docID}`)
            .then(function (response) {
                setElementDocuments(response?.data?.data);
            })
            .catch(function (error) {
                toast(error?.response?.data?.message).toUpperCase();
                document.getElementById('overlay').style.display = 'none';
            });
    };
    const pagination = {
        page: pageVeh,
        size: rowsPerPageVeh,
    };

    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.user?.name || '') +
                    ' ' +
                    (item?.user?.lastName || '') +
                    (item?.user?.secondLastName ? ' ' + item?.user?.secondLastName : ''),
                id: item?.user?.id,
            };
        });
        return newData;
    };

    useEffect(() => {
        if (isContractVehicle) {
            dispatch(GetVehicleWithDocumentById(params?.id)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        setElementDetails(data);
                        setSlectElementForDelete([data.vehicle.id]);
                    }
                },
            );
        } else if (isContractEmployee) {
            getContractorDetail();
        } else if (employeeOption !== null) {
            if (employeeOption === 'provider') {
                const data = {
                    id: params?.id,
                };
                dispatch(GetEmployeeSupplierByItId(params?.id)).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            setElementDetails({ user: data });
                            dispatch(GetAllProviderDocuments(data?.userId)).then(
                                ({
                                    payload: {
                                        data: { data, success },
                                    },
                                }) => {
                                    if (success === true) {
                                        setElementDocuments(data);
                                    }
                                },
                            );
                        }
                    },
                );
            } else if (employeeOption === 'contractor') {
                dispatch(GetEmployeeContractorByItId(params?.id)).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            setElementDetails({ user: data });
                            docID = data?.userId;
                            getContractorDocument();
                        }
                    },
                );
            }
        } else {
            if (isVehicle === true) {
                if (contractorId !== null) {
                    dispatch(GetVehicleWithDocumentById(params?.id)).then(
                        ({
                            payload: {
                                data: { data, success },
                            },
                        }) => {
                            if (success === true) {
                                setElementDetails(data);
                                setSlectElementForDelete([data.vehicle.id]);
                            }
                        },
                    );
                } else {
                    dispatch(GetProviderVehicleDetail(params?.id)).then(
                        ({
                            payload: {
                                data: { data, success },
                            },
                        }) => {
                            if (success === true) {
                                setElementDetails(data);
                                setSlectElementForDelete([data.vehicle.id]);
                            }
                        },
                    );
                }
            } else {
                if (contractorId !== null) {
                    getContractorDetail();
                    setSlectElementForDelete([sessionStorage.getItem('elementForDelete')]);
                } else if (supplierId !== null) {
                    setSlectElementForDelete([sessionStorage.getItem('elementForDelete')]);
                    dispatch(GetProviderEmployeeDetail(params?.id)).then(
                        ({
                            payload: {
                                data: { data, success },
                            },
                        }) => {
                            if (success === true) {
                                setElementDetails(data);
                                setElementDocuments(data?.documents);
                            }
                        },
                    );
                }
            }
        }
    }, [saveProviderImage, imageChangeCheck, uploadImage, changeCount]);

    useEffect(() => {
        /*author mazhar iqbal
            get contarctor employee
          */
        if (employeeOption === 'contractor') {
            getCompanyByContractor();
        } else if (employeeOption === 'provider') {
            const body = {
                providerId: params?.id,

                pagination: {
                    page: pageEmp,
                    size: rowsPerPageEmp,
                },
            };
            dispatch(GetAllCompanybyProviderId(body)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        setElementEmployees(data);
                    }
                },
            );
        }
    }, [pageEmp, rowsPerPageEmp]);

    useEffect(() => {
        /*author mazhar iqbal
          get contarctor vehicle
        */
        if (employeeOption !== null) {
            if (employeeOption === 'contractor') {
                dispatch(GetAllVehicleByContractorId({ contractId: params?.id, pagination })).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            setElementVehicles(data);
                        }
                    },
                );
            } else if (employeeOption === 'provider') {
                const body = {
                    providerId: params?.id,
                    pagination: pagination,
                };
                dispatch(GetAllCompanyVehiclebyId(body)).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            setElementVehicles(data);
                        }
                    },
                );
            }
        }
    }, [pageVeh, rowsPerPageVeh]);

    const deleteSelectedElement = (deleteItem) => {
        const tableName = isVehicle
            ? contractorId !== null
                ? 'contractor_vehicle'
                : supplierId !== null && 'supplier_vehicle'
            : contractorId !== null
              ? 'contractor_employee'
              : supplierId !== null
                ? 'supplier_employee'
                : 'employee';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        navigate(-1);
                    }
                },
            );
        }
    };

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={null} />
                    <h2>
                        {isContractVehicle
                            ? t('vehicle_details')
                            : isContractEmployee
                              ? t('employee_details')
                              : employeeOption !== null
                                ? employeeOption === 'contractor'
                                    ? t('contractor_details')
                                    : t('supplier_details')
                                : isVehicle
                                  ? t('vehicle_details')
                                  : t('employee_details')}
                    </h2>
                </div>
                {(employeeOption === null || permission?.includes(permissionObj?.WEB_CONTRACTOR_UPDATE)) &&
                    isContractEmployee !== true &&
                    isContractVehicle !== true && (
                        <div className='container-top-right-btns'>
                            <button
                                className='add-btn-1'
                                onClick={() =>
                                    navigate(
                                        employeeOption !== null
                                            ? employeeOption === 'contractor'
                                                ? `/dashboard/employee/contractors/update-contractor/${params?.id}`
                                                : `/dashboard/employee/suppliers/update-suppliers/${params?.id}`
                                            : isVehicle === true
                                              ? contractorId !== null
                                                  ? `/dashboard/contractor/update-vehicle/${params?.id}`
                                                  : `/dashboard/supplier/update-vehicles/${params?.id}`
                                              : contractorId !== null
                                                ? `/dashboard/contractor/update-employee/${params?.id}`
                                                : `/dashboard/supplier/update-employee/${params?.id}`,
                                    )
                                }
                            >
                                <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                                {t('update')}
                            </button>
                            <button
                                className='delete-btn-1'
                                onClick={() => {
                                    setDeleteElementShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        </div>
                    )}
            </div>
            <div
                className='row employee_provider_detail'
                style={{ marginTop: '135px', height: contractorId !== null && '70vh' }}
            >
                <div className='col-md-4 __userData h-100'>
                    {isVehicle || isContractVehicle ? (
                        <>
                            <div className={'status ' + status[elementDetails?.vehicle?.status?.id]}>
                                <p className='text-end'>
                                    {t(status[elementDetails?.vehicle?.status?.id]).toUpperCase()}
                                </p>
                                <GoPrimitiveDot className='ml-1' />
                            </div>
                            {elementDetails?.vehicle?.image ? (
                                <img
                                    src={`data:image/png;base64,${elementDetails?.vehicle?.image}`}
                                    alt='Vehicle Image'
                                    className='vehicle-contract-detail__img'
                                />
                            ) : (
                                <img src={car} alt='Vehicle Image' className='vehicle-contract-detail__img' />
                            )}
                        </>
                    ) : (
                        <>
                            {elementDetails?.user?.selfie ? (
                                <img
                                    src={`data:image/png;base64,${elementDetails?.user?.selfie}`}
                                    className='__userImage'
                                    alt='image'
                                    style={{ objectFit: 'cover' }}
                                />
                            ) : (
                                <img src={profileDemo} className='__userImage' style={{ objectFit: 'cover' }} />
                            )}
                            <div className='status'>
                                <span
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                                >
                                    {' '}
                                    <div
                                        className='status_icon'
                                        style={{
                                            background: statusColor[elementDetails?.user?.statusId],
                                            marginRight: '10px',
                                            marginTop: '2px',
                                        }}
                                    ></div>
                                    <span>{t(status[elementDetails?.user?.statusId]) || '-'}</span>{' '}
                                </span>
                            </div>
                        </>
                    )}

                    {isVehicle || isContractVehicle ? (
                        <div className='__body-Vehicle'>
                            <div className='column'>
                                <p className='ishead'>{t('brand')}</p>
                                <span>{elementDetails?.vehicle?.brand}</span>

                                <p className='ishead'>{t('sub_brand')}</p>
                                <span>{elementDetails?.vehicle?.subBrand}</span>

                                <p className='ishead'>{t('color')}</p>
                                <span>{elementDetails?.vehicle?.color}</span>

                                <p className='ishead'>{t('model')}</p>
                                <span>{elementDetails?.vehicle?.model}</span>
                            </div>
                            <div className='column'>
                                <p className='ishead'>{t('plates')}</p>
                                <span>{elementDetails?.vehicle?.plate}</span>

                                <p className='ishead'>{t('vin')}</p>
                                <span>{elementDetails?.vehicle?.vin || '-'}</span>

                                <p className='ishead'>{t('serial_number')}</p>
                                <span>{elementDetails?.vehicle?.serialNumber || '-'}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='__body'>
                            {/*   <div className='status'>
                            <p
                                style={{
                                    color: statusColor[getOrderDetails.status?.id],
                                }}
                            >
                                {t(status[getOrderDetails.status?.id])}
                            </p>
                            <div
                                className='status_icon'
                                style={{
                                    background: statusColor[getOrderDetails.status?.id],
                                }}
                            ></div>   </div> */}
                            <p className='ishead'>{t('name')}</p>
                            <span>
                                {userDataTransform([elementDetails])[0].label
                                    ? userDataTransform([elementDetails])[0].label
                                    : '-'}
                            </span>
                            <p className='ishead'>{t('Acronym')}</p>
                            <span>{elementDetails?.user?.acronym ? elementDetails.user.acronym : '-'}</span>{' '}
                            <p className='ishead'>{t('company_name')}</p>
                            <span>{elementDetails?.user?.supplierCompanyName || '-'}</span>
                            <p className='ishead'>{t('manager')}</p>{' '}
                            <span>
                                <span> {elementDetails?.user?.name || '-'}</span>
                            </span>
                            <p className='ishead'>{t('email')}</p>
                            <span> {elementDetails?.user?.email ? elementDetails?.user?.email : '-'}</span>
                            <p className='ishead'>{t('phone_number')}</p>
                            <span>{elementDetails?.user?.phoneNumber ? elementDetails?.user?.phoneNumber : '-'}</span>
                            <p className='ishead'>{t('password')}</p>
                            <span>**************</span>
                            <p className='ishead'>{t('gender')}</p>
                            <span>
                                {elementDetails?.user?.genderId ? t(gender[elementDetails?.user?.genderId]) : '-'}
                            </span>
                            <p className='ishead'>{t('documentId')}</p>
                            <span>{elementDetails?.user?.documentId ? elementDetails?.user?.documentId : '-'}</span>
                        </div>
                    )}
                </div>
                <div
                    className={
                        contractorId !== null
                            ? 'col-md-7 employee_files_details h-100'
                            : 'col-md-7 employee_files_details'
                    }
                    style={{ height: '492px' }}
                >
                    {isVehicle || isContractVehicle ? (
                        elementDetails?.documents && elementDetails?.documents.length !== 0 ? (
                            <DocumentTable
                                dataTable={elementDetails?.documents}
                                approve={false}
                                optionDownload='document_external'
                                isVehicleDocuments={false}
                            />
                        ) : (
                            <div className='mt-3'>
                                <NotInformation text={t('no_information')} card={false} />
                            </div>
                        )
                    ) : elementDocuments && elementDocuments.length !== 0 ? (
                        <DocumentTable
                            dataTable={elementDocuments}
                            approve={true}
                            optionDownload='document_external'
                            isVehicleDocuments={false}
                        />
                    ) : (
                        <div className='mt-3'>
                            <NotInformation text={t('no_information')} card={false} />
                        </div>
                    )}
                </div>
                {employeeOption !== null && isContractEmployee !== true && isContractVehicle !== true && (
                    <div className='row'>
                        <div className='col-12 px-4 mt-5'>
                            <span className='emp-heading'>{t('employees')}</span>
                            {elementEmployees?.content?.length > 0 ? (
                                <>
                                    <Accordion>
                                        {elementEmployees?.content
                                            ?.filter((user) => {
                                                if (searchEmp === '') {
                                                    return user;
                                                } else if (
                                                    user?.user?.name?.toLowerCase().includes(searchEmp?.toLowerCase())
                                                ) {
                                                    return user;
                                                }
                                            })
                                            .map((item, index) => (
                                                <Accordion.Item eventKey={index} key={index} className='mt-2'>
                                                    <Accordion.Header className='accordionHeader'>
                                                        <Grid container className='accordion-theme-dataAcomodate'>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className='d-flex align-items-center'
                                                                sx={{ width: '30vw' }}
                                                            >
                                                                {item?.user?.selfie ? (
                                                                    <Avatar
                                                                        alt='User img'
                                                                        src={`data:image/png;base64,${item?.user?.selfie}`}
                                                                        className='me-2'
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        alt='User Name'
                                                                        src={profileDemo}
                                                                        className='me-2'
                                                                    />
                                                                )}
                                                                {item?.user?.name}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                className='d-flex statusBox align-items-center'
                                                            >
                                                                <div className='status-text-blue'>
                                                                    {item?.user?.statusId == 1 ? (
                                                                        <span
                                                                            className='viewcard-container__status employe-status-documents'
                                                                            style={{
                                                                                color: '#808080',
                                                                                paddingRight: '10px',
                                                                            }}
                                                                        >
                                                                            {t('pre_registered')}
                                                                            <FiberManualRecordIcon
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                    {item?.user?.statusId == 2 ? (
                                                                        <span
                                                                            className='viewcard-container__status employe-status-documents'
                                                                            style={{
                                                                                color: 'yellow',
                                                                                paddingRight: '10px',
                                                                            }}
                                                                        >
                                                                            {t('to_change_password')}
                                                                            <FiberManualRecordIcon
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                    {item?.user?.statusId == 3 ? (
                                                                        <span
                                                                            className='viewcard-container__status employe-status-documents'
                                                                            style={{
                                                                                color: 'blue',
                                                                                paddingRight: '10px',
                                                                            }}
                                                                        >
                                                                            {t('to_approve_documentS')}
                                                                            <FiberManualRecordIcon
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                    {item?.user?.statusId == 4 ? (
                                                                        <span
                                                                            className='viewcard-container__status employe-status-documents'
                                                                            style={{
                                                                                color: 'green',
                                                                                paddingRight: '10px',
                                                                            }}
                                                                        >
                                                                            {t('active')}
                                                                            <FiberManualRecordIcon
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                    {item?.user?.statusId == 5 ? (
                                                                        <span
                                                                            className='viewcard-container__status employe-status-documents'
                                                                            style={{
                                                                                color: 'orange',
                                                                                paddingRight: '10px',
                                                                            }}
                                                                        >
                                                                            {t('on_vacations')}
                                                                            <FiberManualRecordIcon
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                    {item?.user?.statusId == 6 ? (
                                                                        <span
                                                                            className='viewcard-container__status employe-status-documents'
                                                                            style={{
                                                                                color: 'red',
                                                                                paddingRight: '10px',
                                                                            }}
                                                                        >
                                                                            {t('in_active')}
                                                                            <FiberManualRecordIcon
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div
                                                            className='employee_files_details w-100'
                                                            style={{ boxShadow: 'none' }}
                                                        >
                                                            {item?.documents && item?.documents?.length != 0 ? (
                                                                <>
                                                                    <DocumentTable
                                                                        dataTable={item?.documents}
                                                                        docValue='valueType'
                                                                        // approve={item?.user?.status?.id == 3 ? true : false}
                                                                        approve={true}
                                                                        optionDownload='document_external'
                                                                    />
                                                                </>
                                                            ) : (
                                                                <img
                                                                    src={emptyList}
                                                                    className='d-flex'
                                                                    style={{
                                                                        width: '100px',
                                                                        height: '100px',
                                                                        margin: 'auto',
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                    </Accordion>
                                </>
                            ) : (
                                <div className='mt-3'>
                                    <NotInformation text={t('no_information')} card={true} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {elementEmployees?.content?.length > 0 && (
                    <div className='d-flex justify-content-center'>
                        <TablePagination
                            component='div'
                            rowsPerPageOptions={[4, 8, 12]}
                            count={elementEmployees?.totalElements}
                            page={pageEmp}
                            onPageChange={handleChangePageEmp}
                            labelRowsPerPage='Employees per page'
                            rowsPerPage={rowsPerPageEmp}
                            onRowsPerPageChange={handleChangeRowsPerPageEmp}
                        />
                    </div>
                )}
                {employeeOption !== null && isContractEmployee !== true && isContractVehicle !== true && (
                    <div className='row'>
                        <div className='col-12 px-4 mt-5'>
                            <span className='emp-heading'>{t('vehicles')}</span>
                            {elementVehicles?.content?.length > 0 ? (
                                <>
                                    <Accordion>
                                        {elementVehicles?.content
                                            ?.filter((user) => {
                                                if (searchVeh === '') {
                                                    return user;
                                                } else if (
                                                    user?.vehicle?.plate
                                                        ?.toLowerCase()
                                                        .includes(searchVeh?.toLowerCase())
                                                ) {
                                                    return user;
                                                }
                                            })
                                            .map((item, index) => {
                                                return (
                                                    <Accordion.Item eventKey={index} key={index} className='mt-2'>
                                                        <Accordion.Header className='accordionHeader'>
                                                            <Grid container className='accordion-theme-dataAcomodate'>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className='d-flex align-items-center'
                                                                    sx={{ width: '30vw' }}
                                                                >
                                                                    {item?.vehicle?.image ? (
                                                                        <Avatar
                                                                            alt='User img'
                                                                            src={`data:image/png;base64,${item?.vehicle?.image}`}
                                                                            className='me-2'
                                                                        />
                                                                    ) : (
                                                                        <Avatar
                                                                            alt='User Name'
                                                                            src={car}
                                                                            className='me-2'
                                                                        />
                                                                    )}
                                                                    {item?.vehicle?.brand}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                    className='d-flex statusBox align-items-center'
                                                                >
                                                                    <div className='status-text-blue'>
                                                                        {item?.vehicle?.status?.id == 1 ? (
                                                                            <span
                                                                                className='viewcard-container__status employe-status-documents'
                                                                                style={{
                                                                                    color: '#808080',
                                                                                    paddingRight: '10px',
                                                                                }}
                                                                            >
                                                                                {item?.vehicle?.status?.name.replaceAll(
                                                                                    '_',
                                                                                    ' ',
                                                                                )}
                                                                                <FiberManualRecordIcon
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                        {item?.vehicle?.status?.id == 2 ? (
                                                                            <span
                                                                                className='viewcard-container__status employe-status-documents'
                                                                                style={{
                                                                                    color: 'yellow',
                                                                                    paddingRight: '10px',
                                                                                }}
                                                                            >
                                                                                {item?.vehicle?.status?.name.replaceAll(
                                                                                    '_',
                                                                                    ' ',
                                                                                )}
                                                                                <FiberManualRecordIcon
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                        {item?.vehicle?.status?.id == 3 ? (
                                                                            <span
                                                                                className='viewcard-container__status employe-status-documents'
                                                                                style={{
                                                                                    color: 'blue',
                                                                                    paddingRight: '10px',
                                                                                }}
                                                                            >
                                                                                {item?.vehicle?.status?.name.replaceAll(
                                                                                    '_',
                                                                                    ' ',
                                                                                )}
                                                                                <FiberManualRecordIcon
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                        {item?.vehicle?.status?.id == 4 ? (
                                                                            <span
                                                                                className='viewcard-container__status employe-status-documents'
                                                                                style={{
                                                                                    color: 'green',
                                                                                    paddingRight: '10px',
                                                                                }}
                                                                            >
                                                                                {item?.vehicle?.status?.name.replaceAll(
                                                                                    '_',
                                                                                    ' ',
                                                                                )}
                                                                                <FiberManualRecordIcon
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                        {item?.vehicle?.status?.id == 5 ? (
                                                                            <span
                                                                                className='viewcard-container__status employe-status-documents'
                                                                                style={{
                                                                                    color: 'orange',
                                                                                    paddingRight: '10px',
                                                                                }}
                                                                            >
                                                                                {item?.vehicle?.status?.name.replaceAll(
                                                                                    '_',
                                                                                    ' ',
                                                                                )}
                                                                                <FiberManualRecordIcon
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                        {item?.vehicle?.status?.id == 6 ? (
                                                                            <span
                                                                                className='viewcard-container__status employe-status-documents'
                                                                                style={{
                                                                                    color: 'red',
                                                                                    paddingRight: '10px',
                                                                                }}
                                                                            >
                                                                                {item?.vehicle?.status?.name.replaceAll(
                                                                                    '_',
                                                                                    ' ',
                                                                                )}
                                                                                <FiberManualRecordIcon
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div
                                                                className='employee_files_details w-100'
                                                                style={{ boxShadow: 'none' }}
                                                            >
                                                                {item?.documents && item?.documents?.length != 0 ? (
                                                                    <>
                                                                        {/* <p style={{ backgroundColor: '#000' }}>aaa</p> */}
                                                                        <DocumentTable
                                                                            dataTable={item?.documents}
                                                                            docValue='valueType'
                                                                            approve={
                                                                                item?.vehicle?.status?.id == 3
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            optionDownload='document_external'
                                                                            isVehicleDocuments={true}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <img
                                                                        src={emptyList}
                                                                        className='d-flex'
                                                                        style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            margin: 'auto',
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                );
                                            })}
                                    </Accordion>
                                </>
                            ) : (
                                <div className='mt-3'>
                                    <NotInformation text={t('no_information')} card={true} />
                                </div>
                            )}
                        </div>
                        {elementVehicles?.content?.length > 0 && (
                            <div className='d-flex justify-content-center'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[4, 8, 12]}
                                    count={elementVehicles?.totalElements}
                                    page={pageVeh}
                                    onPageChange={handleChangePageVeh}
                                    labelRowsPerPage='Vehicles per page'
                                    rowsPerPage={rowsPerPageVeh}
                                    onRowsPerPageChange={handleChangeRowsPerPageVeh}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <DeleteModal
                show={deleteElementShow}
                onHide={() => setDeleteElementShow(false)}
                onClickFn={() => deleteSelectedElement(selectElementForDelete)}
                title_modal={isVehicle ? t('delete_vehicle') : t('delete_employee')}
            />
        </>
    );
};

export default ElementDetailsType;
