import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import useStyle from '../../hooks/useStyle';
import { Circle, Image, Layer, Rect, Stage, Text } from 'react-konva';
import { FormControl } from 'react-bootstrap';
import { GetPreview } from '../../reduxToolkit/Cards/CardsApi';
const CardPreview = ({ open, onClose, previewContent, onHide, currentId }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch();
    useEffect(() => {
        if (open) {
            dispatch(GetPreview(currentId)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success) {
                        setPreviewRaw(JSON.parse(data.raw));
                    }
                },
            );
        } else {
            setPreviewRaw(null);
        }
    }, [dispatch, open, currentId]);
    const { smallBoxStyle, textFieldCommon } = useStyle();
    const { getAllCardFormats } = useSelector((state) => state.CardsSlice);

    // Pagination
    const [page, setPage] = useState(0);
    const [previewRaw, setPreviewRaw] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderby, setOrderby] = useState('id');

    let contractPagination = {
        order: true,
        page: page,
        size: rowsPerPage,
        sortBy: orderby,
    };

    const resetForm = () => {};
    if (!previewRaw) {
        return null;
    }
    //function to handle assing
    const handleAssing = () => {};
    const cardWidth = 300;
    const cardHeight = 460;

    const cardBackgroundColor = 'white';

    return (
        <Dialog className='add-workshift-enrollment-dialog' open={open} maxWidth='xs'>
            <DialogTitle className='dialog-title'>
                <p className='search-title'>{t('preview')}</p>
                <img onClick={() => onHide()} className='dialog-close-custom' src={cancel} alt='' />
            </DialogTitle>

            <DialogContent className='dialog-content mt-0 pt-0'>
                <Box className='preview-card'>
                    <div>
                        <div>
                            <p className='front-side'>{t('front_side')}</p>
                        </div>
                        <div className='view'>
                            <Stage
                                style={{ marginTop: '5px' }}
                                width={cardWidth}
                                height={cardHeight}
                                scaleX={0.85}
                                scaleY={0.85}
                            >
                                <Layer>
                                    <Rect
                                        id='background'
                                        width={cardWidth}
                                        height={cardHeight}
                                        fill={cardBackgroundColor}
                                    />
                                    {previewRaw &&
                                        previewRaw[1].combinedObject.map((shape, index) => {
                                            if (shape.property === 'squares' || shape.property === 'lines') {
                                                return (
                                                    <Rect
                                                        key={index}
                                                        x={shape.x}
                                                        y={shape.y}
                                                        rotation={shape.rotation}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        cornerRadius={shape.cornerRadius}
                                                        stroke={shape.stroke}
                                                        strokeWidth={shape.strokeWidth}
                                                        id={shape.id}
                                                        fill={shape.fill}
                                                    />
                                                );
                                            } else if (shape.property === 'ellipsis') {
                                                return (
                                                    <Circle
                                                        key={index}
                                                        x={shape.x - 10}
                                                        y={shape.y - 5}
                                                        rotation={shape.rotation}
                                                        radius={shape.radius}
                                                        fill={shape.fill}
                                                        stroke={shape.stroke}
                                                        strokeWidth={shape.strokeWidth}
                                                        id={shape.id}
                                                    />
                                                );
                                            } else if (shape.property === 'texts') {
                                                return (
                                                    <Text
                                                        key={index}
                                                        x={shape.x - 10}
                                                        y={shape.y - 5}
                                                        text={shape.content}
                                                        fontSize={shape.fontSize}
                                                        fontFamily={shape.fontFamily}
                                                        fontStyle={shape.fontStyle}
                                                        textDecoration={shape.textDecoration}
                                                        fill={shape.fill}
                                                        align={shape.align}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        wrap={true}
                                                        id={shape.id}
                                                    />
                                                );
                                            } else if (shape.property === 'resources') {
                                                const imageObject = new window.Image();
                                                imageObject.src = shape.base64;
                                                return (
                                                    <Image
                                                        key={index}
                                                        image={imageObject}
                                                        x={shape.x}
                                                        y={shape.y}
                                                        rotation={shape.rotation}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        id={shape.id}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                </Layer>
                            </Stage>
                        </div>
                    </div>
                    <div className='preview-front'>
                        <div>
                            <p className='back-side'>{t('back_side')}</p>
                        </div>
                        <div className='view'>
                            <Stage
                                width={cardWidth}
                                style={{ marginTop: '5px' }}
                                height={cardHeight}
                                scaleX={0.85}
                                scaleY={0.85}
                            >
                                <Layer>
                                    <Rect
                                        id='background'
                                        width={cardWidth}
                                        height={cardHeight}
                                        fill={cardBackgroundColor}
                                    />
                                    {previewRaw &&
                                        previewRaw[2].combinedObject.map((shape, index) => {
                                            if (shape.property === 'squares' || shape.property === 'lines') {
                                                return (
                                                    <Rect
                                                        key={index}
                                                        x={shape.x}
                                                        y={shape.y}
                                                        rotation={shape.rotation}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        cornerRadius={shape.cornerRadius}
                                                        stroke={shape.stroke}
                                                        strokeWidth={shape.strokeWidth}
                                                        id={shape.id}
                                                        fill={shape.fill}
                                                    />
                                                );
                                            } else if (shape.property === 'ellipsis') {
                                                return (
                                                    <Circle
                                                        key={index}
                                                        x={shape.x - 10}
                                                        y={shape.y - 5}
                                                        rotation={shape.rotation}
                                                        radius={shape.radius}
                                                        fill={shape.fill}
                                                        stroke={shape.stroke}
                                                        strokeWidth={shape.strokeWidth}
                                                        id={shape.id}
                                                    />
                                                );
                                            } else if (shape.property === 'texts') {
                                                return (
                                                    <Text
                                                        key={index}
                                                        x={shape.x - 10}
                                                        y={shape.y - 5}
                                                        text={shape.content}
                                                        fontSize={shape.fontSize}
                                                        fontFamily={shape.fontFamily}
                                                        fontStyle={shape.fontStyle}
                                                        textDecoration={shape.textDecoration}
                                                        fill={shape.fill}
                                                        align={shape.align}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        wrap={true}
                                                        id={shape.id}
                                                    />
                                                );
                                            } else if (shape.property === 'resources') {
                                                const imageObject = new window.Image();
                                                imageObject.src = shape.base64;
                                                return (
                                                    <Image
                                                        key={index}
                                                        image={imageObject}
                                                        x={shape.x}
                                                        y={shape.y}
                                                        rotation={shape.rotation}
                                                        width={shape.width}
                                                        height={shape.height}
                                                        id={shape.id}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                </Layer>
                            </Stage>
                        </div>
                    </div>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CardPreview;
const inputBox = {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    height: '50px',
};
