export const Vehicle = {
    brand: '',
    color: '',
    createdAt: null,
    from: null,
    id: null,
    model: '',
    plate: '',
    serialNumber: '',
    statusId: '',
    subBrand: '',
    to: null,
    updatedAt: null,
    userId: null,
    vehiclePermissionTypeId: null,
    vehiclePermissionTypeName: null,
    vin: '',

    toCreateUpdate: function () {
        return {
            brand: this.brand,
            color: this.color,
            id: this.id,
            model: this.model !== '' ? this.model : null,
            plate: this.plate,
            serialNumber: this.serialNumber !== '' ? this.serialNumber : null,
            statusId: this.statusId !== '' ? this.statusId : null,
            subBrand: this.subBrand,
            vin: this.vin !== '' ? this.vin : null,
        };
    },
};
