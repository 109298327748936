import React, { useState } from 'react';
import plus from '../assets/icon/plus.png';

const AddButtonPlus = ({ text, onClick }) => {
    const [hover, setHover] = useState(false);

    const addCardFormatContainer = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    };

    const addCardFormat = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '1280px',
        height: '94px',
        backgroundColor: hover ? '#d3d3d3' : '#fcfcfc',
        border: '1px dashed #e1e1e1b3',
        borderRadius: '4px',
        opacity: '1',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0px',
        color: '#707070',
        cursor: 'pointer',
    };

    const addCardFormatText = {
        marginLeft: '20px',
        paddingLeft: '20px',
    };

    const addCardFormatButton = {
        background: 'none',
        border: 'none',
        font: 'inherit',
        fontSize: '0px',
        color: 'inherit',
        cursor: 'pointer',
        marginRight: '20px',
    };

    const plusIcon = {
        width: '40px',
        height: '40px',
    };

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <div style={addCardFormatContainer}>
            <div
                style={addCardFormat}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
            >
                <span style={{ ...addCardFormatText, marginLeft: '20px' }}>{text}</span>
                <button style={addCardFormatButton}>
                    <img src={plus} alt='' style={plusIcon} />
                </button>
            </div>
        </div>
    );
};

export default AddButtonPlus;
