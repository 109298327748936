import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';
import { toast } from 'react-toastify';

// Creation of Contracts API's

export const ApproveOrDeniedVehicleContratorDocs = createAsyncThunk(
    'approveAndDeniedVehicleDocs/ContractorVehicleDoc',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .post('document-service/contractor-vehicle/approve-document', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const ApproveOrDeniedVehicleSupplierDocs = createAsyncThunk(
    'approveAndDeniedVehicleDocs/SupplierVehicleDoc',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .post('document-service/supplier-vehicle/approve-document', params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
