import React from 'react';
import moment from 'moment';
import {
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Card,
    CardActions,
    CardContent,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import userregular from '../../../../../assets/images/user-regular.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ClearButton from '../../../../../components/ClearButton';
import siluetPerson from '../../../../../assets/defaultImages/silueta_persona.png';
import icCamera from '../../../../../assets/defaultImages/ic-camera.svg';
import icFingerPrint from '../../../../../assets/defaultImages/ic-fingerprint.svg';
import ellipsis67 from '../../../../../assets/defaultImages/Ellipse67.svg';
import { gender } from '../../../../../enums/genderEnum';
import { status } from '../../../../../enums/statusEnum';
import dayId from '../../../../../hooks/dayId';

import groupCheckCircle from '../../../../../assets/images/group-check-circle.svg';
import DividerWithText from '../../../../../components/DividerWithText';
import NotInformation from '../../../../../components/NotInformation';

const Step8Summary = ({
    userData,
    onChange,
    extraData,
    headersList,
    employeeData,
    employeeRoles,
    contractStatusList,
    employeeWorkStations,
    employeeDepartments,
    workShiftsList,
    setWorkShiftsList,
    customizedList,
    setCustomizedList,
    extraDataEnabled,
    selectedImage,
    selfieSrc,
    fingerprintSrc,
    fingerprintName,
    cardDataInfo,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    return (
        <div>
            <div className='enrollment-summary-wrapper'>
                <div className='container-fluid'>
                    <div className='create_device_data'>
                        <DividerWithText title={t('details')} />

                        <div className='data-items'>
                            {/* <VehicleStatus statusName={getVehicleById?.status?.name?.replaceAll("_", " ") || "-"} top={"5px"} right={"25px"} data={getVehicleById} /> */}
                            <div className='row'>
                                <div className='col-12 col-sm-6'>
                                    <div className='vehical-data'>
                                        <h4>{t('name')}</h4>
                                        <p>{userData?.name || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='vehical-data'>
                                        <h4>{t('last_name')}</h4>
                                        <p>{userData?.lastName || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='vehical-data'>
                                        <h4>{t('second_last_name')}</h4>
                                        <p>{userData?.secondLastName || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='vehical-data'>
                                        <h4>{t('email')}</h4>
                                        <p>{userData?.email || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='vehical-data'>
                                        <h4>{t('gender')}</h4>
                                        <p className='p-mayus'>{t(gender[userData?.genderId]) || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='vehical-data'>
                                        <h4>{t('employee_phone_number')}</h4>
                                        <p>{userData?.phoneNumber ? '+' + userData?.phoneNumber : '-'}</p>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-sm-6">
                                    <div className="vehical-data">
                                        <h4>{t("status")}</h4>
                                        <p className='p-mayus'>{t(status[userData?.statusId]) || "-"}</p>
                                    </div>
                                </div> */}
                                <div className='col-12 col-sm-6'>
                                    <div className='vehical-data'>
                                        <h4>{t('dob')}</h4>
                                        <p>
                                            {userData?.dob !== null
                                                ? new Date(userData?.dob).toLocaleDateString(lCode)
                                                : '-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {extraDataEnabled && (
                            <>
                                <DividerWithText title={t('other_details')} />

                                <div className='data-items'>
                                    {/* <VehicleStatus statusName={getVehicleById?.status?.name?.replaceAll("_", " ") || "-"} top={"5px"} right={"25px"} data={getVehicleById} /> */}
                                    <div className='row'>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header1 || t('header_1')}</h4>
                                                <p>{extraData?.field1 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header2 || t('header_2')}</h4>
                                                <p>{extraData?.field2 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header3 || t('header_3')}</h4>
                                                <p>{extraData?.field3 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header4 || t('header_4')}</h4>
                                                <p>{extraData?.field4 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header5 || t('header_5')}</h4>
                                                <p>{extraData?.field5 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header6 || t('header_6')}</h4>
                                                <p>{extraData?.field6 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header7 || t('header_7')}</h4>
                                                <p>{extraData?.field7 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header8 || t('header_8')}</h4>
                                                <p>{extraData?.field8 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header9 || t('header_9')}</h4>
                                                <p>{extraData?.field9 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header10 || t('header_10')}</h4>
                                                <p>{extraData?.field10 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header11 || t('header_11')}</h4>
                                                <p>{extraData?.field11 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header12 || t('header_12')}</h4>
                                                <p>{extraData?.field12 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header13 || t('header_13')}</h4>
                                                <p>{extraData?.field13 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header14 || t('header_14')}</h4>
                                                <p>{extraData?.field14 || '-'}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <div className='vehical-data'>
                                                <h4>{headersList?.header15 || t('header_15')}</h4>
                                                <p>{extraData?.field15 || '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <DividerWithText title={t('employee_contract')} />

                        <div className='data-items'>
                            {/* <VehicleStatus statusName={getVehicleById?.status?.name?.replaceAll("_", " ") || "-"} top={"5px"} right={"25px"} data={getVehicleById} /> */}
                            <div className='row'>
                                <div className='col-12 col-sm-6 col-lg-4'>
                                    <div className='vehical-data'>
                                        <h4>{t('privilege')}</h4>
                                        <p>
                                            {employeeRoles.find((item) => item.id === employeeData?.roleId)?.name ||
                                                '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-4'>
                                    <div className='vehical-data'>
                                        <h4>{t('employee_id')}</h4>
                                        <p>{employeeData?.employeeId || '-'}</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-4'>
                                    <div className='vehical-data'>
                                        <h4>{t('start_date')}</h4>
                                        <p>
                                            {employeeData?.startDate !== null
                                                ? new Date(employeeData?.startDate).toLocaleDateString(lCode)
                                                : '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-4'>
                                    <div className='vehical-data'>
                                        <h4>{t('work_station')}</h4>
                                        <p>
                                            {employeeWorkStations.find((item) => item.id === employeeData?.zoneId)
                                                ?.name || '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-4'>
                                    <div className='vehical-data'>
                                        <h4>{t('department')}</h4>
                                        <p>
                                            {employeeDepartments.find((item) => item.id === employeeData?.departmentId)
                                                ?.name || '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-4'>
                                    <div className='vehical-data'>
                                        <h4>{t('end_date')}</h4>
                                        <p>
                                            {employeeData?.endDate !== null
                                                ? new Date(employeeData?.endDate).toLocaleDateString(lCode)
                                                : '-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DividerWithText title={t('access_rights')} />

                        <Grid container spacing={2.2}>
                            <Grid item xs={6} alignItems={'center'} sm={6} className='width50'>
                                <span className='title-card'>{t('work_shifts')}</span>
                                <div className='d-flex ml-1 pl-0 '></div>
                                <Card variant='outlined'>
                                    <CardContent className='card-content-criteria pt-0'>
                                        <List dense>
                                            {workShiftsList.map((element, index) => (
                                                <ListItem
                                                    className='list-item-criteria pt-1 pb-1'
                                                    key={index}
                                                    // onClick={() => handleAssign(element)}
                                                >
                                                    <ListItemText className='ml-3' primary={`${element?.name}`} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6} alignItems={'center'} sm={6} className='width50'>
                                <span className='title-card'>{t('customized')}</span>
                                <div className='d-flex ml-1 pl-0'></div>
                                <Card variant='outlined'>
                                    <CardContent className='card-content-criteria pt-0'>
                                        <List dense>
                                            {customizedList.map(
                                                (element, index) =>
                                                    element.added && (
                                                        <ListItem className='list-item-criteria pt-2 pb-2' key={index}>
                                                            <ListItemText
                                                                className='ml-3'
                                                                // primary={`${dayId(parseInt(element?.day.id))}, ${element?.from} - ${element?.to}, ${element?.zone.id.split(',')[1]}`}
                                                                primary={`${dayId(parseInt(element?.dayId))}, ${
                                                                    element?.from
                                                                } - ${element?.to}, ${element?.zoneName}`}
                                                            />
                                                        </ListItem>
                                                    ),
                                            )}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <div className='mt-4'>
                            <DividerWithText title={t('take_photography')} />
                        </div>

                        {selectedImage !== null || selfieSrc !== '' ? (
                            <>
                                {selectedImage !== null && (
                                    <div className='image-updated mt-4 mb-4'>
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            style={{
                                                maxHeight: '140px',
                                                maxWidth: '140px',
                                                // borderRadius: '50%',
                                                objectFit: 'cover',
                                            }}
                                            alt='Thumb'
                                        />
                                    </div>
                                )}
                                {selfieSrc !== '' && (
                                    <div
                                        className='image-box mt-4 mb-4'
                                        style={{
                                            maxHeight: '140px',
                                            maxWidth: '140px',
                                            objectFit: 'cover',
                                            // borderRadius: '50%',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundImage: `url(${selfieSrc})`,
                                        }}
                                    ></div>
                                )}
                            </>
                        ) : (
                            <div className='mt-4'>
                                <NotInformation text={t('no_information')} card={true} />
                            </div>
                        )}
                        <div className='mt-4'>
                            <DividerWithText title={t('capture_fingerprint')} />
                        </div>

                        {fingerprintSrc !== '' ? (
                            <div className='mt-5 text-center d-flex flex-column justify-content-center'>
                                <img
                                    src={groupCheckCircle}
                                    style={{
                                        maxHeight: '200px',
                                        maxWidth: '200px',
                                        objectFit: 'cover',
                                        margin: '0 auto',
                                    }}
                                    alt='Thumb'
                                />
                                <span className='mt-4'>{t('name')}</span>
                                <p className='mt-1' style={{ textTransform: 'none' }}>
                                    {fingerprintName}
                                </p>
                            </div>
                        ) : (
                            <div className='mt-4'>
                                <NotInformation text={t('no_information')} card={true} />
                            </div>
                        )}
                        <div className='mt-4'>
                            <DividerWithText title={t('card')} />
                        </div>

                        <div className='mt-4 mb-4'>
                            <div className='panelTables'>
                                {cardDataInfo?.length > 0 ? (
                                    <table className='w-100'>
                                        <thead className='no-border-thead'>
                                            <tr
                                                style={{
                                                    border: 'none',
                                                    boxShadow: 'none !important',
                                                }}
                                            >
                                                {' '}
                                                {/* Debes envolver los encabezados en un elemento <tr> */}
                                                <th className='first_head'>{t('number')}</th>
                                                <th>{t('facility_code')}</th>
                                                <th>{t('format')}</th>
                                                <th>{t('created_at')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {' '}
                                            {/* Agrega un tbody para el cuerpo de la tabla */}
                                            {cardDataInfo.map((item) => (
                                                <tr key={item?.id}>
                                                    <td className='first'>{item?.number}</td>
                                                    <td>{item?.facilityCode}</td>
                                                    <td>{item?.cardTypeName}</td>
                                                    <td>
                                                        {lCode === 'en' ? (
                                                            <span>
                                                                {moment(item?.createdAt).format('MM/DD/YYYY')}
                                                                <br />
                                                                {moment(item?.createdAt).format('HH:mm:ss')}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {moment(item?.createdAt).format('DD/MM/YYYY')}
                                                                <br />
                                                                {moment(item?.createdAt).format('HH:mm:ss')}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <NotInformation text={t('no_information')} card={true} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step8Summary;
