import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorCard = ({ numero, row, column, error, value }) => {
    const { t } = useTranslation();

    return (
        <div className='error-card'>
            <div className='error-item-n'>
                <p className='error-details-n'>
                    {t('N°')}: {numero}
                </p>
            </div>
            <div className='error-item'>
                <p className='error-info'>{t('ROW')}:</p> <p className='error-details'>{row}</p>
            </div>
            <div className='error-item'>
                <p className='error-info'>{t('COLUMN')}:</p> <p className='error-details'>{column}</p>
            </div>
            <div className='error-item'>
                <p className='error-info'>{t('value')}:</p> <p className='error-details'>{value}</p>
            </div>
            <div className='error-item'>
                <p className='error-info'>{t('DESCRIPTION')}:</p> <p className='error-details'>{error}</p>
            </div>
        </div>
    );
};

export default ErrorCard;
