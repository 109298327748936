import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import employee from '../../../assets/images/employee-4.png';
import employee_4 from '../../../assets/defaultImages/userDef.svg';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../Apis/Axios';
import emptyList from '../../../assets/images/warning.svg';
import { Box, Checkbox, FormControlLabel, Grid, Stack } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import CarDemoImg from '../../../assets/images/carDemoImg.png';
import car from '../../../assets/defaultImages/defaultCar.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ic_edit from '../../../assets/icon/ic-edit-pencil.svg';
import Step4AccessRights from '../Company/Employees/EnrollmentSteps/Step4AccessRights';
import DeleteModal from '../../Modals/DeleteModal';
import { permissionObj } from '../../../Helpers/permission';
import { useSelector, useDispatch } from 'react-redux';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import {
    GetContractById,
    GetVehicleOnContractById,
    GetEmployeOnContractById,
    GetAllVehicleByContractor,
    VehicleWorkOnContract,
    GetAllVehicleByContractorId,
    GetlistofcontractorEmployees,
    GetlistofEmployeWorkOnContract,
    GetByUserId,
} from '../../../reduxToolkit/Contractor/ContractorApi';
import {
    allVehicleByContractorId,
    currentContract,
    vehicleOnContractById,
    employeOnContractById,
    allVehicleByContractor,
    byUserId,
    allvehicleWorkOnContract,
    listofcontractorEmployees,
    listofEmployeWorkOnContract,
} from '../../../reduxToolkit/Contractor/ContractorSlice';
import {
    DeleteEmployeeByContractAndUserList,
    DeleteVehicleByContractAndVehicleList,
} from '../../../reduxToolkit/Contractor/ContractorApi';

import AddVehicleToContractorModal from '../../Contractor/Vehicle/AddVehicleToContractorModal';
import AddEmployeToContractModal from '../../Contractor/Vehicle/AddEmployeToContractModal';
import ic_add from '../../../assets/icon/ic-add.svg';
import AddRemoveItemsModal from '../../../components/AddRemoveItemsModal';
import { gender } from '../../../enums/genderEnum';
import { GetContractByIdV1 } from '../../../reduxToolkit/Contractor/ContractorApi';
import { getContractByIdV1 } from '../../../reduxToolkit/Contractor/ContractorSlice';
import { status } from '../../../enums/statusEnum';
import { statusColor } from '../../../enums/statusColorEnum';
import NotInformation from '../../../components/NotInformation';

let docID;
const ContractorDetails = () => {
    const navigate = useLocation();
    const dispatch = useDispatch();
    let { state } = navigate;
    // let contractId = state?.state?.id;
    let contractId = sessionStorage.getItem('contractId');
    let contractorId = sessionStorage.getItem('contractorId');
    const [contractDetail, setContractDetail] = useState();
    const [companyContractor, setCompanyContractor] = useState();
    const [contractVehicle, setcontractVehicle] = useState();
    const [contractWork, setContractWork] = useState();

    const [workShiftsList, setWorkShiftsList] = useState([]);
    const [customizedList, setCustomizedList] = useState([]);

    const [isAllCheckedEmployee, setIsAllCheckedEmployee] = useState(false);
    const [selectEmployeeForDelete, setSelectEmployeeForDelete] = useState([]);

    const [isAllCheckedVehicle, setIsAllCheckedVehicle] = useState(false);
    const [selectVehicleForDelete, setSelectVehicleForDelete] = useState([]);

    const [deleteShowEmployee, setDeleteShowEmployee] = useState(false);
    const [deleteShowVehicle, setDeleteShowVehicle] = useState(false);

    const [updated, setUpdated] = useState(0);

    const [deleteShowContract, setDeleteShowContract] = useState(false);
    const lCode = Cookies.get('i18next') || 'en';
    const { t } = useTranslation();

    const [addUserModal, setAddUserModal] = useState(false);
    const [addVehicleModal, setAddVehicleModal] = useState(false);

    // this function control select all id or unSelect all
    const handelDeleteAllEmployee = (e) => {
        setIsAllCheckedEmployee(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = companyContractor?.content?.map((item) => {
                return item?.id;
            });
            setSelectEmployeeForDelete(selectAllIds);
        } else {
            setSelectEmployeeForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChangeEmployee = (e) => {
        if (e.target.checked) {
            setSelectEmployeeForDelete([...selectEmployeeForDelete, e.target.id]);
        } else {
            setSelectEmployeeForDelete(selectEmployeeForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // this function control select all id or unSelect all
    const handelDeleteAllVehicle = (e) => {
        setIsAllCheckedVehicle(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = contractVehicle?.content?.map((item) => {
                return item?.id;
            });
            setSelectVehicleForDelete(selectAllIds);
        } else {
            setSelectVehicleForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChangeVehicle = (e) => {
        if (e.target.checked) {
            setSelectVehicleForDelete([...selectVehicleForDelete, e.target.id]);
        } else {
            setSelectVehicleForDelete(selectVehicleForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    //Employee
    const [pagePaginationEmp, setPagePaginationEmp] = useState(0);
    const [rowsPerPageProviderEmp, setRowsPerProviderEmp] = useState(4);

    const handleChangePageProviderEmp = (event, newPage) => {
        setPagePaginationEmp(newPage);
    };

    const handleChangeRowsPerPageProviderEmp = (event) => {
        setRowsPerProviderEmp(parseInt(event.target.value));
        setPagePaginationEmp(0);
    };
    //end Employee

    //vehicel
    const [pagePaginationVeh, setPagePaginationVeh] = useState(0);
    const [rowsPerPageProviderVeh, setRowsPerProviderVeh] = useState(4);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    // const { getlistofcontractorEmployees } = useSelector(state => state.contractorSlice)
    const getlistofcontractorEmployees = useSelector(listofcontractorEmployees);
    const getlistofEmployeWorkOnContract = useSelector(listofEmployeWorkOnContract);
    const getAllVehicleByContractorId = useSelector(allVehicleByContractorId);
    const vehicleWorkOnContract = useSelector(allvehicleWorkOnContract);
    const handleChangePageProviderVeh = (event, newPage) => {
        setPagePaginationVeh(newPage);
    };

    const handleChangeRowsPerPageProviderVeh = (event) => {
        setRowsPerProviderVeh(parseInt(event.target.value));
        setPagePaginationVeh(0);
    };
    //end vehicle

    const [pagePagination, setPagePagination] = useState(0);
    const [rowsPerPageProvider, setRowsPerProvider] = useState(10);

    const handleChangePageProvider = (event, newPage) => {
        setPagePagination(newPage);
    };

    const handleChangeRowsPerPageProvider = (event) => {
        setRowsPerProvider(parseInt(event.target.value));
        setPagePagination(0);
    };

    const deleleteEmployee = () => {
        const body = {
            userIds: selectEmployeeForDelete, //props?.deleteData,
            contractId: contractId,
        };
        dispatch(DeleteEmployeeByContractAndUserList(body)).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success === true) {
                    setUpdated(updated + 1);
                }
            },
        );
    };
    const deleleteVehicle = () => {
        const body = {
            vehicleIds: selectVehicleForDelete, //props?.deleteData,
            contractId: contractId,
        };
        dispatch(DeleteVehicleByContractAndVehicleList(body)).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success === true) {
                    setUpdated(updated + 1);
                }
            },
        );
    };
    const deleleteContract = () => {};

    const getContractDetail = async () => {
        await apiInstance
            .get(`contract-service/v1/get-by-id/${contractId}`)
            .then(function (response) {
                if (response.status == 200) {
                    docID = response?.data?.data?.id;
                    setContractDetail(response?.data?.data);
                    if (response?.data?.data?.customs?.length > 0) {
                        const responseCustoms = response?.data?.data?.customs;
                        const customsList = responseCustoms.map((custom) => ({ ...custom, added: true }));
                        setCustomizedList(customsList);
                    }
                    if (response?.data?.data?.workShifts?.length > 0) {
                        const responseShifts = response?.data?.data?.workShifts;
                        const shiftsList = responseShifts.map((shift) => ({ ...shift, added: true }));
                        setWorkShiftsList(shiftsList);
                    }
                    getCompanyByContractor();
                    getVehicleByContractId();
                }
            })
            .catch(function (error) {
                toast(error?.response?.data?.message).toUpperCase();
                document.getElementById('overlay').style.display = 'none';
            });
    };

    const getCompanyByContractor = async () => {
        await apiInstance
            .post(`contractor-employee-service/contract/get-all-pageable-employees/by-contract-id/${contractId}`, {
                order: true,
                page: pagePaginationEmp,
                size: rowsPerPageProviderEmp,
                sortBy: 'id',
            })
            .then(function (response) {
                if (response.status == 200) {
                    setCompanyContractor(response?.data?.data);
                }
            })
            .catch(function (error) {
                toast(error?.response?.data?.message).toUpperCase();
                document.getElementById('overlay').style.display = 'none';
            });
    };

    const getVehicleByContractId = async () => {
        await apiInstance
            .post(`contractor-vehicle-service/contract/get-all-pageable/by-contract-id/${contractId}`, {
                order: true,
                page: pagePaginationVeh,
                size: rowsPerPageProviderVeh,
                sortBy: 'id',
            })
            .then(function (response) {
                if (response.status == 200) {
                    setcontractVehicle(response?.data?.data);
                }
            })
            .catch(function (error) {
                toast(error?.response?.data?.message).toUpperCase();
                document.getElementById('overlay').style.display = 'none';
            });
    };

    const getContractWorkByContractId = async () => {
        await apiInstance
            .post(`work-shift-service/contract-work/get-all-pageable/by-contract-id/${contractId}`, {
                order: true,
                page: pagePagination,
                size: rowsPerPageProvider,
                sortBy: 'id',
            })
            .then(function (response) {
                if (response.status == 200) {
                    setContractWork(response?.data?.data);
                }
            })
            .catch(function (error) {
                toast(error?.response?.data?.message).toUpperCase();
                document.getElementById('overlay').style.display = 'none';
            });
    };

    useEffect(() => {
        getCompanyByContractor();

        getContractDetail();

        if (contractorId !== null) {
            dispatch(GetlistofcontractorEmployees(contractorId));
            dispatch(GetlistofEmployeWorkOnContract(contractId));
            dispatch(GetAllVehicleByContractorId(contractorId));
            dispatch(GetVehicleOnContractById(contractId));
            dispatch(VehicleWorkOnContract(contractId));
        }
    }, [updated]);

    useEffect(() => {
        /*author mazhar iqbal
      get work shift of the contract
    */
        getContractWorkByContractId();
    }, [rowsPerPageProvider, pagePagination]);
    useEffect(() => {
        /*author mazhar iqbal
       get all employee work on contract
     */
        getCompanyByContractor();
    }, [pagePaginationEmp, rowsPerPageProviderEmp]);

    useEffect(() => {
        /*author mazhar iqbal
      get all vehicle work on contract
    */
        getVehicleByContractId();
    }, [pagePaginationVeh, rowsPerPageProviderVeh]);

    const createdDate = new Date(contractDetail?.startDate);
    const endDate = new Date(contractDetail?.endDate);
    const time = new Date(contractDetail?.endDate);

    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.name || '') +
                    ' ' +
                    (item?.firstLastName || '') +
                    (item?.lastName || '') +
                    (item?.secondLastName ? ' ' + item.secondLastName : ''),
                id: item?.id,
            };
        });
        return newData;
    };

    const fullNameTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.contractorName || '') +
                    ' ' +
                    (item?.contractorLastName || '') +
                    (item?.contractorSecondLastName ? ' ' + item.contractorSecondLastName : ''),
                id: item?.id,
            };
        });
        return newData;
    };

    const vehicleDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.brand || '') +
                    ' | ' +
                    (item?.subBrand || '') +
                    ', ' +
                    t('plates') +
                    ': ' +
                    (item?.plate || ''),
                id: item?.id,
            };
        });
        return newData;
    };
    return (
        <>
            <div className='order-details'>
                <div className='head'>
                    <div className='headLeft'>
                        <ReturnArrow
                            link={
                                contractorId === null
                                    ? '/dashboard/employee/contractors'
                                    : '/dashboard/contractor/contracts'
                            }
                        />
                        <h2>{t('contract_details')}</h2>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                        {permission?.includes(permissionObj?.WEB_CONTRACTOR_CONTRACT_UPDATE) && (
                            <Link to='/dashboard/employee/contractors/update-contract' state={{ state: contractId }}>
                                <button className='add-btn-1'>
                                    <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                                    {t('update')}
                                </button>
                            </Link>
                        )}

                        {permission?.includes(permissionObj?.WEB_CONTRACTOR_CONTRACT_DELETE) && (
                            <button
                                className='delete-btn-1'
                                onClick={() => {
                                    setDeleteShowContract(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>
                </div>
                <div>
                    <div className='row content-row'>
                        <div className='col-12 details'>
                            <div className='status'>
                                <span
                                    className='viewcard-container__status'
                                    style={{ color: statusColor[contractDetail?.statusId] }}
                                >
                                    {t(status[contractDetail?.statusId])}
                                    <FiberManualRecordIcon sx={{ fontSize: 40 }} />
                                </span>
                            </div>
                            <div className='content'>
                                <div className='order'>
                                    <strong>
                                        {t('folio')?.toUpperCase()}
                                        <span> # {contractDetail?.folio || '-'}</span>
                                    </strong>{' '}
                                    <div className='actual-details'>{t('contractors')?.toUpperCase()}</div>
                                    <div className='faded-headings'>{fullNameTransform([contractDetail])[0].label}</div>
                                    <div className='actual-details'>{t('email')?.toUpperCase()}</div>
                                    <div className='faded-headings'>{contractDetail?.contractorEmail}</div>
                                    <div className='actual-details'>{t('celular')?.toUpperCase()}</div>
                                    <div className='faded-headings'>{contractDetail?.contractorPhoneNumber}</div>
                                </div>
                                <div className='delivery-details'>
                                    <div className='actual-details'>{t('start_contract')?.toUpperCase()}</div>
                                    <div className='faded-headings'>
                                        {lCode === 'en'
                                            ? createdDate.toLocaleDateString('en-US')?.toUpperCase()
                                            : createdDate.toLocaleDateString('es-ES')?.toUpperCase()}
                                    </div>
                                    <div className='actual-details'>{t('corporate')?.toUpperCase()}</div>
                                    <div className='faded-headings'>{contractDetail?.contractorCompanyName}</div>
                                    <div className='actual-details'>{t('vehicles')?.toUpperCase()}</div>
                                    <div className='faded-headings'>{contractVehicle?.content?.length}</div>
                                </div>
                                <div className='time-details' style={{ textAlign: 'center' }}>
                                    <div className='actual-details'>{t('end_contract')?.toUpperCase()}</div>
                                    <div className='faded-headings'>
                                        {lCode === 'en'
                                            ? endDate.toLocaleDateString('en-US')?.toUpperCase()
                                            : endDate.toLocaleDateString('es-ES')?.toUpperCase()}
                                    </div>
                                    <div className='actual-details'>{t('employees')?.toUpperCase()}</div>
                                    <div className='faded-headings'>{companyContractor?.content?.length}</div>
                                </div>
                                <div
                                    className='time-details'
                                    style={{
                                        borderLeft: '2px solid #65ABA0',
                                        height: '100%',
                                        textAlign: 'right',
                                    }}
                                >
                                    <div className='actual-details'>{t('description')?.toUpperCase()}</div>
                                    <div className='actual-details'>{contractDetail?.description}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content-row contractor-cards'>
                        <div className='row cards-section'>
                            <div className='cards-first-row'>
                                <div className='d-flex'>
                                    <div className='cards-heading'>{t('employees').toUpperCase()}</div>
                                    {contractorId !== null && (
                                        <span
                                            className='ml-3 device_button'
                                            onClick={() => {
                                                setAddUserModal(true);
                                            }}
                                        >
                                            {t('add_remove_employee')?.toUpperCase()}
                                            <img className='ml-2' src={ic_add} alt='' />
                                        </span>
                                    )}
                                </div>
                                <div>
                                    {companyContractor && companyContractor?.content.length != 0 ? (
                                        <>
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <FormControlLabel
                                                    className='grid-checkall'
                                                    control={
                                                        <Checkbox
                                                            label='Label'
                                                            checked={isAllCheckedEmployee}
                                                            onChange={handelDeleteAllEmployee}
                                                            size='small'
                                                        />
                                                    }
                                                    label={t('de_/_select_all')}
                                                />
                                                <button
                                                    className='delete-btn-1'
                                                    disabled={selectEmployeeForDelete?.length === 0}
                                                    onClick={() => {
                                                        setDeleteShowEmployee(true);
                                                    }}
                                                >
                                                    <i class='fa fa-trash-o' aria-hidden='true'></i>
                                                    {t('remove')}
                                                </button>
                                            </Stack>
                                            <Grid container spacing={2}>
                                                {companyContractor?.content.map((item) => {
                                                    return (
                                                        <Grid item xs={12} sm={3} md={3} lg={3}>
                                                            <div className='card'>
                                                                <div className='card-body'>
                                                                    <Checkbox
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '-6px',
                                                                            left: '0px',
                                                                        }}
                                                                        className='grid-checkall checkbox'
                                                                        checked={selectEmployeeForDelete?.includes(
                                                                            item?.id,
                                                                        )}
                                                                        id={item?.id}
                                                                        onChange={handleCheckboxChangeEmployee}
                                                                        size='small'
                                                                    />
                                                                    <div className='d-flex align-items-center justify-content-center'>
                                                                        {item?.selfie ? (
                                                                            <img
                                                                                src={`data:image/png;base64,${item?.selfie}`}
                                                                                alt='image'
                                                                                className='card-img-top'
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                className='card-img-top'
                                                                                src={employee_4}
                                                                                alt='employee'
                                                                                style={{
                                                                                    height: '150px',
                                                                                    objectFit: 'fill',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>

                                                                    <div className='card-content'>
                                                                        <span className='status-content'>
                                                                            {t('active').toUpperCase()}
                                                                        </span>
                                                                        <div className='card-data-row'>
                                                                            <div className='headings'>
                                                                                {t('name').toUpperCase()}
                                                                            </div>
                                                                            <div
                                                                                className='details'
                                                                                style={{ textTransform: 'capitalize' }}
                                                                            >
                                                                                {userDataTransform([item])[0].label ||
                                                                                    '-'}
                                                                            </div>
                                                                        </div>
                                                                        <div className='card-data-row'>
                                                                            <div className='headings'>
                                                                                {t('gender').toUpperCase()}
                                                                            </div>
                                                                            <div className='details'>
                                                                                {t(
                                                                                    gender[item?.genderId],
                                                                                )?.toUpperCase() || '-'}
                                                                            </div>
                                                                        </div>
                                                                        <div className='card-data-row'>
                                                                            <div className='headings'>
                                                                                {t('email').toUpperCase()}
                                                                            </div>
                                                                            <div className='details'>
                                                                                {item?.email || '-'}
                                                                            </div>
                                                                        </div>
                                                                        <div className='card-data-row'>
                                                                            <div className='headings'>
                                                                                {t(
                                                                                    'employee_phone_number',
                                                                                ).toUpperCase()}
                                                                            </div>
                                                                            <div className='details'>
                                                                                {item?.phoneNumber || '-'}
                                                                            </div>
                                                                        </div>

                                                                        <span className='viewcard-container__link mt-2 d-flex'>
                                                                            <Link
                                                                                to={
                                                                                    contractorId === null
                                                                                        ? `/dashboard/employee/contractors/employeeDetail/${item?.id}`
                                                                                        : `/dashboard/contractor/employee-contract-detail/${item?.id}`
                                                                                }
                                                                                state={{ state: item }}
                                                                            >
                                                                                {t('employee_details').toUpperCase()}{' '}
                                                                                <KeyboardArrowRightIcon
                                                                                    style={{
                                                                                        transform:
                                                                                            lCode === 'ar'
                                                                                                ? 'scaleX(-1)'
                                                                                                : '',
                                                                                    }}
                                                                                />
                                                                            </Link>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </>
                                    ) : (
                                        <NotInformation text={t('no_information')} card={true} />
                                    )}
                                </div>
                                {companyContractor && companyContractor?.content.length != 0 && (
                                    <div className='d-flex justify-content-center'>
                                        <TablePagination
                                            component='div'
                                            rowsPerPageOptions={[4, 8, 16, 24]}
                                            count={companyContractor?.totalElements}
                                            page={pagePaginationEmp}
                                            onPageChange={handleChangePageProviderEmp}
                                            labelRowsPerPage={t('employees_per_page')}
                                            rowsPerPage={rowsPerPageProviderEmp}
                                            onRowsPerPageChange={handleChangeRowsPerPageProviderEmp}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='cards-second-row'>
                                <div className='d-flex'>
                                    <div className='cards-heading'>{t('vehicles').toUpperCase()}</div>
                                    {contractorId !== null && (
                                        <span className='ml-3 device_button' onClick={() => setAddVehicleModal(true)}>
                                            {t('add_remove_vehicle')?.toUpperCase()}
                                            <img className='ml-2' src={ic_add} alt='' />
                                        </span>
                                    )}
                                </div>
                                <div className=''>
                                    {contractVehicle && contractVehicle?.content?.length != 0 ? (
                                        <>
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <FormControlLabel
                                                    className='grid-checkall'
                                                    control={
                                                        <Checkbox
                                                            label='Label'
                                                            checked={isAllCheckedVehicle}
                                                            onChange={handelDeleteAllVehicle}
                                                            size='small'
                                                        />
                                                    }
                                                    label={t('de_/_select_all')}
                                                />
                                                <button
                                                    className='delete-btn-1'
                                                    disabled={selectVehicleForDelete?.length === 0}
                                                    onClick={() => {
                                                        setDeleteShowVehicle(true);
                                                    }}
                                                >
                                                    <i class='fa fa-trash-o' aria-hidden='true'></i>
                                                    {t('remove')}
                                                </button>
                                            </Stack>
                                            <Grid container spacing={2}></Grid>
                                            {
                                                <Grid container spacing={2} paddingTop='1rem'>
                                                    {contractVehicle.content?.map((item) => {
                                                        return (
                                                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                                                <div className='card'>
                                                                    <div className='card-body'>
                                                                        <Checkbox
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '-6px',
                                                                                left: '0px',
                                                                            }}
                                                                            className='grid-checkall checkbox'
                                                                            checked={selectVehicleForDelete?.includes(
                                                                                item?.id,
                                                                            )}
                                                                            id={item?.id}
                                                                            onChange={handleCheckboxChangeVehicle}
                                                                            size='small'
                                                                        />
                                                                        <div className='d-flex align-items-center justify-content-center'>
                                                                            {item?.image ? (
                                                                                <img
                                                                                    src={`data:image/png;base64,${item?.image}`}
                                                                                    alt='image'
                                                                                    className='card-img-top'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    className='card-img-top'
                                                                                    src={car}
                                                                                    alt='employee'
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className='card-content'>
                                                                            <span className='status-content'>
                                                                                {t('active').toUpperCase()}
                                                                            </span>
                                                                            <div className='card-data-row'>
                                                                                <div className='headings'>
                                                                                    {t('brand').toUpperCase()}
                                                                                </div>
                                                                                <div className='details'>
                                                                                    {item?.brand ? item?.brand : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div className='card-data-row'>
                                                                                <div className='headings'>
                                                                                    {t('sub_brand').toUpperCase()}
                                                                                </div>
                                                                                <div className='details'>
                                                                                    {item?.subBrand
                                                                                        ? item?.subBrand
                                                                                        : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div className='card-data-row'>
                                                                                <div className='headings'>
                                                                                    {t('model').toUpperCase()}
                                                                                </div>
                                                                                <div className='details'>
                                                                                    {item?.model ? item?.model : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div className='card-data-row'>
                                                                                <div className='headings'>
                                                                                    {t('color'.toUpperCase())}
                                                                                </div>
                                                                                <div className='details'>
                                                                                    {item?.color ? item?.color : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div className='card-data-row'>
                                                                                <div className='headings'>
                                                                                    {t('plates').toUpperCase()}
                                                                                </div>
                                                                                <div className='details'>
                                                                                    {item?.plate ? item?.plate : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <span className='viewcard-container__link mt-2 d-flex'>
                                                                                <Link
                                                                                    to={
                                                                                        contractorId !== null
                                                                                            ? `/dashboard/contractor/vehicle-contract-detail/${item?.id}`
                                                                                            : `/dashboard/employee/contractors/vehicleDetail/${item?.id}`
                                                                                    }
                                                                                    state={{ vehicle: item }}
                                                                                >
                                                                                    {t('vehicle_details').toUpperCase()}{' '}
                                                                                    <KeyboardArrowRightIcon
                                                                                        style={{
                                                                                            transform:
                                                                                                lCode === 'ar'
                                                                                                    ? 'scaleX(-1)'
                                                                                                    : '',
                                                                                        }}
                                                                                    />
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            }
                                        </>
                                    ) : (
                                        <NotInformation text={t('no_information').toUpperCase()} card={true} />
                                    )}
                                </div>
                                {contractVehicle && contractVehicle?.content?.length != 0 && (
                                    <div className='d-flex justify-content-center'>
                                        <TablePagination
                                            component='div'
                                            rowsPerPageOptions={[4, 8, 16, 24]}
                                            count={contractVehicle?.totalElements}
                                            page={pagePaginationVeh}
                                            onPageChange={handleChangePageProviderVeh}
                                            labelRowsPerPage={t('vehicles_per_page')}
                                            rowsPerPage={rowsPerPageProviderVeh}
                                            onRowsPerPageChange={handleChangeRowsPerPageProviderVeh}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal
                show={deleteShowEmployee}
                onHide={() => setDeleteShowEmployee(false)}
                data={selectEmployeeForDelete}
                onClickFn={() => deleleteEmployee(selectEmployeeForDelete)}
                title_modal={t('remove_employees')}
                element_modal={t('employees')}
                isReset={setSelectEmployeeForDelete}
                isAllReset={setIsAllCheckedEmployee}
            />

            <DeleteModal
                show={deleteShowVehicle}
                onHide={() => setDeleteShowVehicle(false)}
                data={selectVehicleForDelete}
                onClickFn={() => deleleteVehicle(selectVehicleForDelete)}
                title_modal={t('remove_vehicles')}
                element_modal={t('vehicles')}
                isReset={setSelectVehicleForDelete}
                isAllReset={setIsAllCheckedVehicle}
            />

            <DeleteModal
                show={deleteShowContract}
                onHide={() => setDeleteShowContract(false)}
                // data={selectVehicleForDelete}

                onClickFn={() => deleleteContract()}
                title_modal={t('contractor')}
                element_modal={t('contract')}
                // isReset={setSelectVehicleForDelete}
                // isAllReset={setIsAllCheckedVehicle}
            />

            <AddRemoveItemsModal
                mainTitle={'employees'}
                addTitle={'add_user'}
                removeTitle={'remove_user'}
                allItemsData={getlistofcontractorEmployees}
                addedItemsData={getlistofEmployeWorkOnContract}
                itemDataTransform={userDataTransform}
                containerType={'contract'}
                containerId={sessionStorage.getItem('contractId')}
                onUpdated={() => setUpdated(updated + 1)}
                show={addUserModal}
                onHide={() => setAddUserModal(false)}
                events={true}
                // deleteData={selectEmployeeForDelete}
            />

            <AddRemoveItemsModal
                mainTitle={'vehicles'}
                addTitle={'add_vehicle'}
                removeTitle={'remove_vehicle'}
                allItemsData={getAllVehicleByContractorId}
                addedItemsData={vehicleWorkOnContract}
                itemDataTransform={vehicleDataTransform}
                containerType={'contract'}
                containerId={sessionStorage.getItem('contractId')}
                onUpdated={() => setUpdated(updated + 1)}
                show={addVehicleModal}
                onHide={() => setAddVehicleModal(false)}
                // deleteData={selectEmployeeForDelete}
            />
        </>
    );
};

export default ContractorDetails;
