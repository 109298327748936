import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '@mui/material/TablePagination';
import { clearSearchFilters } from '../../../../reduxToolkit/Search/SearchSlice';
import SearchSimple from '../../../../components/SearchSimples/SearchSimple';
import CreateOutput from './CreateOutput';
import { SearchByFilters } from '../../../../reduxToolkit/Search/SearchApi';
import { MODELS } from '../../../../Apis/Models';
import { TABLES } from '../../../../Apis/Tables';
import { DeleteItemsApi } from '../../../../reduxToolkit/Commons/CommonsApi';
import NotInformation from '../../../../components/NotInformation';
import { permissionObj } from '../../../../Helpers/permission';

const Outputs = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const lCode = Cookies.get('i18next') || 'en';
    const title_modal = `output`;
    const element_modal = `output`;
    const [workShiftModalShow, setWorkShiftModalShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchVehicle, setSearchVehicle] = useState('');
    const [view, setView] = useState('list');
    const [selectVehicleForDelete, setSlectVehicleForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteVehicleShow, setDeleteVehicleShow] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [importFileShow, setImportFileShow] = useState(false);
    const [creationErrorsShow, setCreationErrorsShow] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [selectOutputForDelete, setSelectOutputForDelete] = useState([]);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { uploadImage } = useSelector((state) => state.sharedSlice);
    const { searchByFilters, searchByFiltersCustom } = useSelector((state) => state.SearchSlice);

    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState('');
    const [disable, setDisable] = useState(false);

    // Props to the filter window
    const moduleId = `${MODELS.hid}`;
    const option = 'hid_output';
    const [criteria, setCriteria] = useState();

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(false);
    }, [page, rowsPerPage, finalArray, deletedFlag]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(false);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    // useEffect to check automatically all the items when page, rowsPerPage, or search change
    useEffect(() => {
        if (isAllChecked) {
            const selectAllIds = searchByFilters?.content?.map((item) => item?.id);
            setSelectOutputForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [searchByFilters]);

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = searchByFilters?.content?.map((item) => {
                return item?.id;
            });
            setSelectOutputForDelete(selectAllIds);
        } else {
            setSelectOutputForDelete([]);
        }
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectOutputForDelete([...selectOutputForDelete, e.target.id]);
        } else {
            setSelectOutputForDelete(selectOutputForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };
    // This section help us to determine the height of the main table
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        const currentUrl = window.location.pathname; // Get the current URL
        const parts = currentUrl.split('/');
        const modulePart = parts[3] ? parts[3] : '' + parts[4] ? '/' + parts[4] : '';
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    useEffect(() => {
        if (loaded) {
            /* const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                };
            }); */

            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
            };
            dispatch(SearchByFilters({ option, body }))
                .then(({ payload }) => {
                    if (payload?.data && payload?.success) {
                        const { data, success } = payload.data;
                    } else {
                        console.error('Error fetching data: Response structure not as expected');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setLoaded(false);
                    setDeletedFlag(false);
                });
        }
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray, deletedFlag]);

    const deleteSelectedController = (deleteItem) => {
        const body = deleteItem;
        const tableName = TABLES.HID;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(false);
                        setSelectOutputForDelete([]);
                        setIsAllChecked(false);
                    } else {
                    }
                },
            );
        }
    };

    const deleteOutputs = (deleteItem) => {
        const tableName = 'hid';
        const body = deleteItem;
    };
    return (
        <>
            {!disable ? (
                <>
                    <div className='head'>
                        <div className='headLeft'>
                            <h2>{t('Outputs')}</h2>
                        </div>
                        <div className='container-top-right-btns'>
                            {permission?.includes(permissionObj?.WEB_HID_CREATE) && (
                                <button
                                    className='add-btn-1'
                                    id='btn_o_add'
                                    onClick={() => {
                                        setWorkShiftModalShow(true);
                                    }}
                                >
                                    <i className='fa fa-plus' aria-hidden='true'></i>
                                    {t('add')}
                                </button>
                            )}
                            {permission?.includes(permissionObj?.WEB_HID_DELETE) && (
                                <button
                                    className='delete-btn-1'
                                    onClick={() => {
                                        setDeleteShow(true);
                                    }}
                                    id='btn_o_delete'
                                >
                                    <i className='fa fa-trash-o' aria-hidden='true'></i>
                                    {t('delete')}
                                </button>
                            )}
                        </div>
                    </div>
                    <SearchSimple
                        setSearchResults={setSearchResults}
                        page={page}
                        optionTable={option}
                        searchElement={searchElement}
                        setSearchElement={setSearchElement}
                        rowsPerPage={rowsPerPage}
                        setBodyForExport={setBodyForExport}
                    />

                    <div className='panelTables px-1 animated-div' ref={elementRef}>
                        {searchByFilters?.content?.length > 0 ? (
                            <table className='w-100'>
                                <thead className='no-border-thead'>
                                    <tr>
                                        <section className='table-header'>
                                            <section className='checkbox-and-name--WorkShift'>
                                                {permission?.includes(permissionObj?.WEB_HID_DELETE) && (
                                                    <th className='first_head'>
                                                        <Tooltip
                                                            title={t('de_/_select_all').toUpperCase()}
                                                            placement='top'
                                                        >
                                                            <Checkbox className='grid-checkall checkbox' size='small' />
                                                        </Tooltip>
                                                    </th>
                                                )}
                                                <th className='first_head'>{t('name')}</th>
                                            </section>
                                            <section>
                                                <th>{t('controller')}</th>
                                                <th>{t('sio_type')}</th>
                                                <th>{t('number')}</th>
                                                <th>{t('Default_pulse')}</th>
                                            </section>
                                        </section>
                                    </tr>
                                </thead>
                                {searchByFilters?.content?.length > 0 &&
                                    searchByFilters?.content?.map((item) => (
                                        <tr key={item?.id}>
                                            {permission?.includes(permissionObj?.WEB_HID_DELETE) && (
                                                <td
                                                    className='first'
                                                    style={{ verticalAlign: 'middle', width: '50px' }}
                                                >
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={selectOutputForDelete?.includes(item?.name)}
                                                        id={item?.id}
                                                        onChange={handleCheckboxChange}
                                                        size='small'
                                                    />
                                                </td>
                                            )}
                                            <td className='first'>{item?.ip}</td>
                                            <td>{item?.n_SIO}</td>
                                            <td>{item?.n_Input}</td>
                                            <td>{item?.n_output}</td>
                                            <td>{item?.n_readers}</td>
                                            <td>{item?.n_template}</td>
                                            <td>{item?.status}</td>
                                            <td>{item?.details}</td>
                                        </tr>
                                    ))}
                            </table>
                        ) : (
                            <NotInformation text={t('no_information')} card={true} />
                        )}
                    </div>

                    <CreateOutput
                        title={''}
                        check='false'
                        /*   isUpdate={isUpdate}
                           data={updateData} */
                        show={workShiftModalShow}
                        onHide={() => setWorkShiftModalShow(false)}
                    />
                    {searchByFilters?.content?.length > 0 && (
                        <div className='d-flex justify-content-center'>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={[20, 40, 60]}
                                count={searchByFilters?.totalElements}
                                page={page}
                                onPageChange={handleChangePage}
                                labelRowsPerPage={t('controller_per_page')}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className='headLeft'>
                        <h2>{t('Outputs')}</h2>
                    </div>
                    <NotInformation text={t('no_information')} card={true} />
                </>
            )}
        </>
    );
};

export default Outputs;
