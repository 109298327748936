import { createSlice } from '@reduxjs/toolkit';
import { GetAllEmployeeContracts, ContractorDownloadDocuments } from './EmployeeContractorsApi';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { errorCode } from '../../enums/errorCodeEnum';

const employeeContractorSlice = createSlice({
    name: 'employeeContractor',
    initialState: {
        createContract: [],
        createContractV1: [],
        updateContractV1: {},
        getZonetree: [],
        getAllContractors: [],
        getAllWorkSchdule: [],
        allEmployeeContractors: [],
        allEmployeeContracts: [],
        getWorkTimeAccess: [],
        contractorDownloadDocument: null,
        WorkShiftByContractID: [],
        contractAllStatus: [],
        approveContractorDocument: null,
        customSchdulTime: [],
        getVehicleWithDocumentById: [],
        getAllVehicleByContractorId: [],
        getEmployeeContractorById: {},

        getAllToContractor: [],
        createEmployeeContractor: {},
        updateEmployeeContractor: {},
        getEmployeeContractorByItId: {},
        getAllContractorDocuments: {},
        detailsEmployeeContractorEmployee: {},
    },
    reducers: {
        customScduleTime: (state, { payload }) => {
            state.customSchdulTime = payload;
        },

        ClearGetEmployeeContractorByItId: (state, action) => {
            state.getEmployeeContractorByItId = {};
        },
    },
    extraReducers: {
        'employeeContractor/CreateContract/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createContract = data.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeContractor/createContractV1/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createContractV1 = data.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeContractor/updateContractV1/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateContractV1 = data.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeContractor/getEmployeeContractorById/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getEmployeeContractorById = data.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeContractor/GetStatus/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.contractAllStatus = data.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeContractor/GetZoneTree/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getZonetree = data.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'employeeContractor/GetAllEmployeeContractors/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.allEmployeeContractors = data.data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
            }
        },
        'employeeContractor/GetAllContractors/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllContractors = data.data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
            }
        },
        'employeeContractor/GetAllWorkSchdule/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllWorkSchdule = data.data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
            }
        },
        'employeeContractor/GetWorkTimeAccess/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getWorkTimeAccess = data.data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
            }
        },
        'employeeContractor/GetWorkShiftByContractID/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.WorkShiftByContractID = data.data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
            }
        },
        'employeeContractor/GetVehicleWithDocumentById/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getVehicleWithDocumentById = data.data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
            }
        },
        'employeeContractor/GetAllVehicleByContractorId/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllVehicleByContractorId = data.data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
            }
        },
        'employeeContractor/GetAllToContractor/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllToContractor = data.data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
            }
        },
        [GetAllEmployeeContracts.fulfilled]: (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.allEmployeeContracts = data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
                toast.error('Fail to Fetch Contracts');
            }
        },
        [ContractorDownloadDocuments.fulfilled]: (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast('File Downloading ...');
                state.contractorDownloadDocument = data;
            } else if (status >= 400 && status < 500) {
                // state.errorCreateCutomer= data.errors
                toast('Fail to fetch Document');
                document.getElementById('overlay').style.display = 'none';
            }
        },
        'employeeContractor/createEmployeeContractor/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createEmployeeContractor = data;
                toast.success(t('has_been_created_successfully').toUpperCase());
            } else if (status >= 400 && status < 600) {
                if (!(data?.code && errorCode.includes(data?.code))) {
                    toast.error(t('error_creating').toUpperCase());
                }
            }
        },
        'employeeContractor/updateEmployeeContractor/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateEmployeeContractor = data;
                toast.success(t('has_been_updated_successfully').toUpperCase());
            } else if (status >= 400 && status < 600) {
                if (!(data?.code && errorCode.includes(data?.code))) {
                    toast.error(t('error_updating').toUpperCase());
                }
            }
        },
        'employeeContractor/getEmployeeContractorByItId/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getEmployeeContractorByItId = data?.data;
            } else if (status >= 400 && status < 500) {
                toast.error(data.message);
            }
        },
        'employeeContractor/getAllContractorDocuments/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllContractorDocuments = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast.error(data.message)
            }
        },
        'employeeContractor/detailsEmployeeContractorEmployee/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.detailsEmployeeContractorEmployee = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast.error(data.message)
            }
        },
        'employeeContractor/approveContractorDocument/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.approveContractorDocument = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to Create Order")
            }
        },
    },
});

export const { customScduleTime } = employeeContractorSlice.actions;
export const getcustomSchdulTime = (state) => state.EmployeeContractorsSlice.customSchdulTime;

export const getcreateContract = (state) => state.EmployeeContractorsSlice.createContract;
export const getcreateContractV1 = (state) => state.EmployeeContractorsSlice.createContractV1;
export const getcontractAllStatus = (state) => state.EmployeeContractorsSlice.contractAllStatus;
export const getZonetree = (state) => state.EmployeeContractorsSlice.getZonetree;
export const getAllEmployeeContractors = (state) => state.EmployeeContractorsSlice.allEmployeeContractors;
export const getAllContractors = (state) => state.EmployeeContractorsSlice.getAllContractors;
export const getAllWorkSchdule = (state) => state.EmployeeContractorsSlice.getAllWorkSchdule;
export const getWorkTimeAccess = (state) => state.EmployeeContractorsSlice.getWorkTimeAccess;
export const getAllEmployeeContracts = (state) => state.EmployeeContractorsSlice.allEmployeeContracts;
export const contractorDownloadDocument = (state) => state.EmployeeContractorsSlice.contractorDownloadDocument;
export const getWorkShiftByContractID = (state) => state.EmployeeContractorsSlice.WorkShiftByContractID;
export const vehicleWithDocumentById = (state) => state.EmployeeContractorsSlice.getVehicleWithDocumentById;
export const allVehicleByContractorId = (state) => state.EmployeeContractorsSlice.getAllVehicleByContractorId;
export const allToContractor = (state) => state.EmployeeContractorsSlice.getAllToContractor;

export const { ClearGetEmployeeContractorByItId } = employeeContractorSlice.actions;

export default employeeContractorSlice.reducer;
