import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';
import apiInstanceV2 from '../../Apis/AxiosV2';

// SIMPLE SEARCH
// Simple PDF
export const exportPDFSimple = createAsyncThunk('files/exportPDFSimple', async ({ body }, { dispatch, getState }) => {
    let result = await apiInstanceV2
        .post(`file-service/simple-pdf`, body, {
            responseType: 'blob',
        })
        .then(function (response) {
            var currentdate = new Date();
            var datetime =
                currentdate.getFullYear() +
                '-' +
                (currentdate.getMonth() + 1) +
                '-' +
                currentdate.getDate() +
                '_' +
                currentdate.getHours() +
                '-' +
                currentdate.getMinutes() +
                '-' +
                currentdate.getSeconds();

            const url = window?.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${datetime}.pdf`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);

            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});
// Export PDF Simple Custom
export const ExportPDFSimpleCustom = createAsyncThunk(
    'files/exportPDFSimpleCustom',
    async ({ body }, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post('file-service/simple-pdf/custom', body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `PDFSimpleCustom-${datetime}.pdf`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Simple Excel exportPDFSimple
export const ExportExcelSimple = createAsyncThunk(
    'files/exportDataExcelSimple',
    async ({ body }, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post('file-service/simple-excel', body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `simpleSearchExcel-${datetime}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
// Export Excel Simple Custom
export const ExportExcelSimpleCustom = createAsyncThunk(
    'files/exportExcelSimpleCustom',
    async ({ body }, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post('file-service/simple-excel/custom', body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `simpleSearchExcelCustom-${datetime}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
//get excel and pdf options
//GET
// ​/ientry-corporate-service-v2​/file-service​/headers​/{option}​/{isExcel}

export const GetExcelHeadersByOption = createAsyncThunk(
    'files/getExcelHeadersByOption',
    async ({ option, isExcel }, { dispatch, getState }) => {
        try {
            const response = await apiInstanceV2.get(`file-service/headers/${option}/${isExcel}`);
            const { data, status } = response;
            return { data, status };
        } catch (error) {
            const { response } = error;
            return { data: response.data, status: response.status };
        }
    },
);

// EXPORT DATA ADVANCED
// Export data in PDF /file-service/{option}/pdf file-module file-controller
export const ExportDataPDF = createAsyncThunk(
    'files/exportDataPDF',
    async ({ option, body }, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/${option}/pdf`, body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${option}${datetime}.pdf`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Export data in PDF custom /file-service/{option}/pdf/custom file-module file-controller
export const ExportDataPDFCustom = createAsyncThunk(
    'files/exportDataPDFCustom',
    async ({ option, body }, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/${option}/pdf/custom`, body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${option}${datetime}.pdf`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Export data in Excel /file-service/{option}/excel file-module file-controller
export const ExportDataExcel = createAsyncThunk(
    'files/exportDataExcel',
    async ({ option, body }, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/${option}/excel`, body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${option}${datetime}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Export data in Excel Custom /file-service/{option}/excel/custom file-module file-controller
export const ExportDataExcelCustom = createAsyncThunk(
    'files/exportDataExcelCustom',
    async ({ option, body }, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/${option}/excel/custom`, body, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date();
                var datetime =
                    currentdate.getFullYear() +
                    '-' +
                    (currentdate.getMonth() + 1) +
                    '-' +
                    currentdate.getDate() +
                    '_' +
                    currentdate.getHours() +
                    '-' +
                    currentdate.getMinutes() +
                    '-' +
                    currentdate.getSeconds();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${option}${datetime}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Download Excel Form to Import Employees /file-service/download/employee file-module file-controller
export const DownloadEmployeeForm = createAsyncThunk(
    'files/downloadEmployeeForm',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`file-service/download/employee`, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date().getTime();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `employee_form_${currentdate}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Upload Employee Form File /file-service/upload/employees file-module file-controller
export const UploadEmployeeFormFile = createAsyncThunk(
    'files/uploadFormFile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/upload/employees`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Create Employee List /employee-service/create-list company-module employee-controller
export const CreateListEmployee = createAsyncThunk(
    'files/createListEmployee',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`employee-service/create-list`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Download Excel Form to Import Contractor Employees /file-service/download/contractor-employee file-module file-controller
export const DownloadContractorEmployeeForm = createAsyncThunk(
    'files/downloadContractorEmployeeForm',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`file-service/download/contractor-employee`, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date().getTime();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `contractor_employee_form_${currentdate}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Upload Contractor Employee Form File /file-service/upload/contractor-employees file-module file-controller
export const UploadContractorEmployeeFormFile = createAsyncThunk(
    'files/uploadFormFile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/upload/contractor-employees`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Create Contractor Employee List /contractor-employee-service/v1/create-list contractor-module contractor-employee-controller
export const CreateListContractorEmployee = createAsyncThunk(
    'files/createListContractorEmployee',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`contractor-employee-service/v1/create-list`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Download Excel Form to Import Supplier Employees /file-service/download/supplier-employee file-module file-controller
export const DownloadSupplierEmployeeForm = createAsyncThunk(
    'files/downloadSupplierEmployeeForm',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`file-service/download/supplier-employee`, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date().getTime();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `supplier_employee_form_${currentdate}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Upload Supplier Employee Form File /file-service/upload/supplier-employee file-module file-controller
export const UploadSupplierEmployeeFormFile = createAsyncThunk(
    'files/uploadFormFile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/upload/supplier-employees`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Create Supplier Employee List /supplier-employee-service/v1/create-list supplier-module supplier-employee-controller
export const CreateListSupplierEmployee = createAsyncThunk(
    'files/createListSupplierEmployee',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`supplier-employee-service/v1/create-list`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Download Excel Form to Import Suppliers /file-service/download/supplier file-module file-controller
export const DownloadSupplierForm = createAsyncThunk(
    'files/downloadSupplierForm',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`file-service/download/supplier`, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date().getTime();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `supplier_form_${currentdate}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Upload Supplier Form File /file-service/upload/suppliers file-module file-controller
export const UploadSupplierFormFile = createAsyncThunk(
    'files/uploadFormFile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/upload/suppliers`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Create Supplier List /supplier-service/create-list supplier-module supplier-controller
export const CreateListSupplier = createAsyncThunk(
    'files/createListSupplier',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`supplier-service/create-list`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Download Excel Form to Import Contractors /file-service/download/contractor file-module file-controller
export const DownloadContractorForm = createAsyncThunk(
    'files/downloadContractorForm',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`file-service/download/contractor`, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date().getTime();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `contractor_form_${currentdate}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Upload Contractor Form File /file-service/upload/contractors file-module file-controller
export const UploadContractorFormFile = createAsyncThunk(
    'files/uploadFormFile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/upload/contractors`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Create Contractor List /contractor-service/create-list contractor-module contractor-controller
export const CreateListContractor = createAsyncThunk(
    'files/createListContractor',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`contractor-service/create-list`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Download Excel Form to Import Contractor Vehicles /file-service/download/contractor-vehicle file-module file-controller
export const DownloadContractorVehicleForm = createAsyncThunk(
    'files/downloadContractorVehicleForm',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`file-service/download/contractor-vehicle`, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date().getTime();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `contractor_vehicle_form_${currentdate}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Upload Contractor Vehicle Form File /file-service/upload/contractor-vehicles file-module file-controller
export const UploadContractorVehicleFormFile = createAsyncThunk(
    'files/uploadFormFile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/upload/contractor-vehicles`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Create Contractor Vehicle List /vehicle-service/v1/vehicles/contractor vehicle-module vehicle-controller
export const CreateListContractorVehicle = createAsyncThunk(
    'files/createListContractorVehicle',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`/vehicle-service/v1/vehicles/contractor`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Download Excel Form to Import Supplier Vehicles /file-service/download/supplier-vehicle file-module file-controller
export const DownloadSupplierVehicleForm = createAsyncThunk(
    'files/downloadSupplierVehicleForm',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`file-service/download/supplier-vehicle`, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date().getTime();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `supplier_vehicle_form_${currentdate}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Upload Supplier Vehicle Form File /file-service/upload/supplier-vehicles file-module file-controller
export const UploadSupplierVehicleFormFile = createAsyncThunk(
    'files/uploadFormFile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/upload/supplier-vehicles`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Create Contractor Vehicle List /vehicle-service/v1/vehicles/supplier vehicle-module vehicle-controller
export const CreateListSupplierVehicle = createAsyncThunk(
    'files/createListSupplierVehicle',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`/vehicle-service/v1/vehicles/supplier`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Download Excel Form to Import Employee Vehicles /file-service/download/employee-vehicle file-module file-controller
export const DownloadEmployeeVehicleForm = createAsyncThunk(
    'files/downloadEmployeeVehicleForm',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`file-service/download/employee-vehicle`, {
                responseType: 'blob',
            })
            .then(function (response) {
                var currentdate = new Date().getTime();

                const url = window?.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `employee_vehicle_form_${currentdate}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

// Upload Employee Vehicle Form File /file-service/upload/employee-vehicles file-module file-controller
export const UploadEmployeeVehicleFormFile = createAsyncThunk(
    'files/uploadFormFile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`file-service/upload/employee-vehicles`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Create Employee Vehicle List /vehicle-service/v1/vehicles/employee vehicle-module vehicle-controller
export const CreateListEmployeeVehicle = createAsyncThunk(
    'files/createListEmployeeVehicle',
    async (params, { dispatch, getState }) => {
        let result = await apiInstanceV2
            .post(`/vehicle-service/v1/vehicles/employee`, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
