import React, { useEffect, useState } from 'react';
import { Grid, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import TimePicker from '@mui/lab/TimePicker';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { GetAllDepartments } from '../../../reduxToolkit/DocumentPanel/DocumentPanelApi';
import { useDispatch } from 'react-redux';
import { GetDefaultAttendanceDepartment } from '../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';

const ShiftTimePicker = ({
    startShiftAt,
    setStartShiftAt,
    endShiftAt,
    setEndShiftAt,
    range,
    setRange,
    selectedDepartment,
    setSelectedDepartment,
    readOnly,
    departmentsId,
    displayNone,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [allDepartments, setAllDepartments] = useState([]);

    const parseTime = (timeString) => {
        if (!timeString) return null;
        const [hours, minutes, seconds] = timeString.split(':');
        const date = new Date();
        date.setHours(hours, minutes, seconds);
        return date;
    };

    const formatTime = (date) => {
        if (!date) return '';
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        dispatch(GetDefaultAttendanceDepartment());
        dispatch(GetAllDepartments()).then(({ payload }) => {
            setAllDepartments(payload.data.data || []);
        });
    }, [dispatch]);

    return (
        <div className='col-xl-12'>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '550px' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            renderInput={(props) => <TextField size='small' {...props} />}
                            ampm={false}
                            openTo='hours'
                            className='w-100'
                            id='dtp_stp_startShiftAt'
                            views={['hours', 'minutes', 'seconds']}
                            inputFormat='HH:mm:ss'
                            mask='__:__:__'
                            label={`${t('start_shift_at')} *`}
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={parseTime(startShiftAt)}
                            onChange={(date) => setStartShiftAt(formatTime(date))}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '550px' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            renderInput={(props) => <TextField size='small' {...props} />}
                            ampm={false}
                            readOnly={readOnly}
                            disabled={readOnly}
                            openTo='hours'
                            views={['hours', 'minutes', 'seconds']}
                            inputFormat='HH:mm:ss'
                            mask='__:__:__'
                            id='dtp_stp_endShiftAt'
                            className='w-100'
                            label={`${t('end_shift_at')} *`}
                            value={parseTime(endShiftAt)}
                            onChange={(date) => setEndShiftAt(formatTime(date))}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '550px' }}>
                    <TextField
                        size='small'
                        fullWidth
                        type='number'
                        id='txt_stp_range'
                        label={`${t('range')?.toUpperCase()} *`}
                        value={range}
                        className='w-100'
                        readOnly={readOnly}
                        disabled={readOnly}
                        onChange={(e) => setRange(e.target.value)}
                        InputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                                        {t('min')?.toUpperCase()}
                                    </span>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {!displayNone && (
                    <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '550px' }}>
                        <FormControl size='small' fullWidth>
                            <InputLabel>{t('departments')?.toUpperCase()}</InputLabel>
                            <Select
                                id='slt_stp_departments'
                                value={selectedDepartment}
                                readOnly={readOnly}
                                disabled={readOnly}
                                className='w-100'
                                onChange={(e) => setSelectedDepartment(e.target.value)}
                                label={`${t('departments')?.toUpperCase()} *`}
                            >
                                {allDepartments.map((department) => (
                                    <MenuItem key={department.id} value={department.id}>
                                        {department.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ShiftTimePicker;
