import { colors } from './colors';

export const statusColor = {
    1: null,
    2: colors.yellow,
    3: colors.blue,
    4: colors.green,
    5: colors.orange,
    6: colors.red,
    11: colors.green,
    12: colors.red,
    13: 'zone_in_construction_company',
    14: 'contract_active_employee',
    15: 'contract_inactive_employee',
    21: colors.grey,
    22: colors.green,
    23: colors.red,
    24: colors.green,
    25: colors.red,
    26: colors.green,
    27: colors.red,
    28: colors.grey,
    29: colors.blue,
    30: colors.yellow,
    36: colors.red,
    50: colors.blue,
    31: colors.red,
    32: colors.yellow,
    33: colors.green,
    34: colors.blue,
    15: 'contract_inactive_employee',
    contract_inactive_employee: colors.grey,
    21: colors.grey,
};
