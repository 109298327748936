/* {{base_url}}/chart-service/v1/user/by-user-id/e3456e59-ffc4-4a54-a319-e389cd7af380 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';
import apiInstanceV2 from '../../Apis/AxiosV2';

export const GetGraphByUserId = createAsyncThunk('graph/getGraphByUserId', async (params, { dispatch, getState }) => {
    const result = await apiInstanceV2
        .get(`chart-service/v1/dashboard/get-all/by-user-id/${params}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});
export const GetTemplates = createAsyncThunk('graph/getTemplates', async (params, { dispatch, getState }) => {
    const result = await apiInstanceV2
        .get('chart-service/v1/template/get-all')

        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;

    return { data, status };
});
export const GetAllChartsAvailable = createAsyncThunk(
    'graph/getAllChartsAvailable',
    async (params, { dispatch, getState }) => {
        const result = await apiInstanceV2
            .get('chart-service/v1/get-all')

            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
export const GetChartsAvailableByDashboardId = createAsyncThunk(
    'graph/getChartsAvailableByDashboardId ',
    async (dashboardId, { dispatch, getState }) => {
        const result = await apiInstanceV2
            .get(`chart-service/v1/chart-dashboard/${dashboardId}/non-charts`)

            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const AddCharts = createAsyncThunk('graph/addCharts ', async ({ body }, { dispatch, getState }) => {
    let result = await apiInstanceV2
        .post(`chart-service/v1/chart-dashboard/add-charts`, body)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});
export const UpdateDashboardName = createAsyncThunk(
    'graph/updateDashboardName',
    async ({ body }, { dispatch, getState }) => {
        const result = await apiInstanceV2
            .put('chart-service/v1/dashboard/update', body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

export const DeleteItemChart = createAsyncThunk(
    'graph/deleteItemChart',
    async ({ table, body }, { dispatch, getState }) => {
        let result = await apiInstance
            .post(`assets-service/delete-by-ids/by-table/${table}`, body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
export const CreateDashboard = createAsyncThunk(
    'graph/createDashboard',
    async ({ table, body }, { dispatch, getState }) => {
        let result = await apiInstance
            .post('chart-service/v1/dashboard/create', body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
