import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import defaultCar from '../../assets/defaultImages/defaultCar.svg';
import def from '../../assets/defaultImages/userDef.svg';
import {
    CheckProviderImage,
    GetOrderDetails,
    GetProviderEmployeeDetail,
    GetProviderVehicleDetail,
} from '../../reduxToolkit/Providers/providersApi';
import ReturnArrow from '../../components/buttons/ReturnArrow';
import { statusColor } from '../../enums/statusColorEnum';
import { status } from '../../enums/statusEnum';
import moment from 'moment';
import NotInformation from '../../components/NotInformation';
import ic_edit from '../../assets/icon/ic-edit-pencil.svg';
import DeleteModal from '../Modals/DeleteModal';
import { DeleteItemsApi } from '../../reduxToolkit/Commons/CommonsApi';

const OrderDetail = () => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const userType = user?.data?.userType?.name;
    const [deleteOrderShow, setDeleteOrderShow] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState([]);
    const { getOrderDetails } = useSelector((state) => state.providersSlice);

    const [loaded, setLoaded] = useState(false);
    const date = new Date(getOrderDetails?.eta);
    const id = getOrderDetails?.id;
    // check email length and cut off
    const EmailSlice = getOrderDetails?.supplier?.user?.email.split('@');

    useEffect(() => {
        dispatch(GetOrderDetails(sessionStorage.getItem('supplier_order_id')));
    }, []);

    const deleteSelectedOrders = (deleteItemId) => {
        const tableName = 'purchase';
        const body = [deleteItemId];

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        navigate(`/dashboard/employee/suppliers`);
                    } else {
                    }
                },
            );
        }
    };

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    {' '}
                    {userType === 'EMPLOYEE' ? (
                        <ReturnArrow link={'/dashboard/employee/suppliers'} />
                    ) : userType === 'SUPPLIER_IN_CHARGE' ? (
                        <ReturnArrow link={'/dashboard/supplier/orders'} />
                    ) : null}
                    <h2>{t('order_details')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    {userType === 'SUPPLIER_IN_CHARGE' && (
                        <button className='add-btn-1' onClick={() => navigate(`/dashboard/supplier/update-order`)}>
                            <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                            {t('update')}
                        </button>
                    )}
                    {userType === 'EMPLOYEE' && (
                        <button
                            className='delete-btn-1'
                            onClick={() => {
                                setDeleteOrderShow(true);
                                setDeleteItemId(id);
                            }}
                        >
                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}
                </div>
            </div>
            <div className='order_detail_container'>
                <div className='order_info_conatiner'>
                    <div className='order_info_conatiner_header'>
                        <div>
                            <h5>
                                {t('folio')} #<span>{getOrderDetails.folio}</span>
                            </h5>
                        </div>
                        <div className='status'>
                            <p
                                style={{
                                    color: statusColor[getOrderDetails.status?.id],
                                }}
                            >
                                {t(status[getOrderDetails.status?.id])}
                            </p>
                            <div
                                className='status_icon'
                                style={{
                                    background: statusColor[getOrderDetails.status?.id],
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className='inner_container'>
                        <div className='order_info_items_left'>
                            <h5>{t('courier_information')}</h5>
                            <div className='order_info_item_left'>
                                <p>{t('supplier')}</p>
                                <h4>
                                    {getOrderDetails?.supplier?.supplierCompanyName +
                                        '|' +
                                        getOrderDetails?.supplier?.acronym}
                                </h4>
                            </div>
                            <div className='order_info_item_left'>
                                <p>{t('email')}</p>
                                <h4 style={{ fontSize: '12px', wordBreak: 'break-word' }}>
                                    {getOrderDetails?.supplier?.user?.email}
                                </h4>
                            </div>
                            <div className='order_info_item_left'>
                                <p>{t('celular')}</p>
                                <h4>{getOrderDetails?.supplier?.user?.phoneNumber}</h4>
                            </div>
                        </div>
                        <div className='order_info_items_middel'>
                            <h5>{t('delivery_information')}</h5>
                            <div className='order_info_item_middel'>
                                <p>{t('eta')}</p>
                                <h4>
                                    {' '}
                                    {getOrderDetails?.eta
                                        ? lCode === 'en'
                                            ? moment(getOrderDetails?.eta).format('MM/DD/YYYY')
                                            : moment(getOrderDetails?.eta).format('DD/MM/YYYY')
                                        : '-'}
                                </h4>
                            </div>
                            <div className='order_info_item_middel'>
                                <p>{t('item')} </p>
                                <h4>{getOrderDetails?.item}</h4>
                            </div>
                        </div>
                        <div className='order_info_items_right'>
                            <h5>{t('received_information')}</h5>
                            <div className='order_info_item-right'>
                                <p>{t('received_by')}</p>
                                <h4>
                                    {getOrderDetails?.userReceived?.name ? getOrderDetails?.userReceived?.name : '-'}
                                </h4>
                            </div>
                            <div className='order_info_item-right'>
                                <p>{t('delivery_date')}</p>
                                <h4>
                                    {getOrderDetails?.deliveryDate
                                        ? lCode === 'en'
                                            ? moment(getOrderDetails?.deliveryDate).format('MM/DD/YYYY')
                                            : moment(getOrderDetails?.deliveryDate).format('DD/MM/YYYY')
                                        : '-'}
                                </h4>
                            </div>
                        </div>
                        <div className='order_info_items_right_last'>
                            <div className='order_info_item-right'>
                                <p>{t('description')}</p>
                                <h4>{getOrderDetails?.description ? getOrderDetails?.description : '-'}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='order_info_section'>
                    <div className='order_info_employee'>
                        <h3>{t('employee')}</h3>
                        {getOrderDetails?.supplierEmployee != null ? (
                            <div className='card_container mt-4'>
                                <div className='card_header'>
                                    <div className='left_active'>
                                        <p
                                            style={{
                                                color: statusColor[getOrderDetails?.supplierEmployee?.user?.status?.id],
                                            }}
                                        >
                                            {t(status[getOrderDetails?.supplierEmployee?.user?.status?.id])}
                                        </p>
                                        <div
                                            className='status_active'
                                            style={{
                                                background:
                                                    statusColor[getOrderDetails?.supplierEmployee?.user?.status?.id],
                                            }}
                                        ></div>
                                    </div>
                                </div>

                                <div className='card_body'>
                                    <div className='img_round'>
                                        <img
                                            src={
                                                getOrderDetails?.supplierEmployee?.user?.selfie != null
                                                    ? `data:image/png;base64,${getOrderDetails?.supplierEmployee?.user?.selfie}`
                                                    : def
                                            }
                                            alt=''
                                        />
                                    </div>
                                    <div className='card_body_items'>
                                        <div className='card_body_item'>
                                            <h5>{t('name')}</h5>
                                            <p>{getOrderDetails?.supplierEmployee?.user?.name}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('last_name')}</h5>
                                            <p>{getOrderDetails?.supplierEmployee?.user?.lastName}</p>
                                        </div>

                                        <div className='card_body_item'>
                                            <h5>{t('gender')}</h5>
                                            <p>{getOrderDetails?.supplierEmployee?.user?.gender?.name}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('email')}</h5>
                                            <p>{getOrderDetails?.supplierEmployee?.user?.email}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('number')}</h5>
                                            <p>{getOrderDetails?.supplierEmployee?.user?.phoneNumber}</p>
                                        </div>
                                        <div className='card_footer'>
                                            {getOrderDetails?.supplierEmployee?.user?.status?.id === 3 ? (
                                                <>
                                                    <Link
                                                        to='/dashboard/supplier/complete-document'
                                                        onClick={() => {
                                                            const userId = getOrderDetails?.supplierEmployee?.user?.id;

                                                            dispatch(GetProviderEmployeeDetail(userId));
                                                            dispatch(CheckProviderImage(userId));
                                                            sessionStorage.setItem('provideridfordetail', userId);
                                                        }}
                                                    >
                                                        {t('complete_documents')}
                                                    </Link>
                                                    <i className='fa fa-angle-right' aria-hidden='true'></i>
                                                </>
                                            ) : (
                                                <>
                                                    {userType === 'EMPLOYEE' && (
                                                        <>
                                                            <Link
                                                                to={`/dashboard/employee/suppliers/supplier-order-detail/${getOrderDetails?.supplierEmployee?.user?.id}`}
                                                                onClick={() => {
                                                                    const userId =
                                                                        getOrderDetails?.supplierEmployee?.user?.id;

                                                                    sessionStorage.setItem(
                                                                        'provideridfordetail',
                                                                        userId,
                                                                    );
                                                                }}
                                                            >
                                                                {t('employee_details').toUpperCase()}
                                                            </Link>
                                                            <i className='fa fa-angle-right' aria-hidden='true'></i>
                                                        </>
                                                    )}
                                                    {userType === 'SUPPLIER_IN_CHARGE' && (
                                                        <>
                                                            <Link
                                                                to={`/dashboard/supplier/supplier-order-detail/${getOrderDetails?.supplierEmployee?.user?.id}`}
                                                                onClick={() => {
                                                                    const userId =
                                                                        getOrderDetails?.supplierEmployee?.user?.id;

                                                                    sessionStorage.setItem(
                                                                        'provideridfordetail',
                                                                        userId,
                                                                    );
                                                                }}
                                                            >
                                                                {t('employee_details').toUpperCase()}
                                                            </Link>
                                                            <i className='fa fa-angle-right' aria-hidden='true'></i>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <NotInformation text={t('no_information')} card={false} />
                        )}
                    </div>

                    <div className='order_info_vehicle'>
                        <h3>{t('vehicle')}</h3>
                        {getOrderDetails?.supplierVehicle != null ? (
                            <div className='card_container mt-4'>
                                <div className='card_header'>
                                    <div className='left_active'>
                                        <p
                                            style={{
                                                color: statusColor[
                                                    getOrderDetails?.supplierVehicle?.vehicle?.status?.id
                                                ],
                                            }}
                                        >
                                            {t(status[getOrderDetails?.supplierVehicle?.vehicle?.status?.id])}
                                        </p>
                                        <div
                                            className='status_active'
                                            style={{
                                                background:
                                                    statusColor[getOrderDetails?.supplierVehicle?.vehicle?.status?.id],
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <div className='card_body'>
                                    <div className='img_round'>
                                        <img
                                            src={
                                                getOrderDetails?.supplierVehicle?.vehicle?.image != null
                                                    ? `data:image/png;base64,${getOrderDetails?.supplierVehicle?.vehicle?.image}`
                                                    : defaultCar
                                            }
                                            alt=''
                                        />
                                    </div>

                                    <div className='card_body_items'>
                                        <div className='card_body_item'>
                                            <h5>{t('brand')}</h5>
                                            <p>{getOrderDetails?.supplierVehicle?.vehicle?.brand}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('sub_brand')}</h5>
                                            <p>{getOrderDetails?.supplierVehicle?.vehicle?.subBrand}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('model')}</h5>
                                            <p>{getOrderDetails?.supplierVehicle?.vehicle?.model}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('color')}</h5>
                                            <p>{getOrderDetails?.supplierVehicle?.vehicle?.color}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('plates')}</h5>
                                            <p>{getOrderDetails?.supplierVehicle?.vehicle?.plate}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('s/n')}</h5>
                                            <p>{getOrderDetails?.supplierVehicle?.vehicle?.serialNumber}</p>
                                        </div>
                                        <div className='card_body_item'>
                                            <h5>{t('vin')}</h5>
                                            <p>{getOrderDetails?.supplierVehicle?.vehicle?.vin}</p>
                                        </div>
                                        <div className='card_footer'>
                                            {getOrderDetails?.supplierVehicle?.vehicle?.status?.id === 3 ? (
                                                <>
                                                    <Link
                                                        to='/dashboard/supplier/vehicle-documents'
                                                        onClick={() => {
                                                            const vehicleId =
                                                                getOrderDetails?.supplierVehicle?.vehicle?.id;
                                                            dispatch(GetProviderVehicleDetail(vehicleId));
                                                            sessionStorage.setItem('vehicleidfordetail', vehicleId);
                                                        }}
                                                    >
                                                        {t('complete_documents')}
                                                    </Link>
                                                    <i className='fa fa-angle-right' aria-hidden='true'></i>
                                                </>
                                            ) : (
                                                <>
                                                    {userType === 'EMPLOYEE' && (
                                                        <>
                                                            <Link
                                                                to={`/dashboard/employee/suppliers/vehicles-details/${getOrderDetails?.supplierVehicle?.vehicle?.id}`}
                                                                onClick={() => {
                                                                    const vehicleId =
                                                                        getOrderDetails?.supplierVehicle?.vehicle?.id;
                                                                    dispatch(GetProviderVehicleDetail(vehicleId));
                                                                    sessionStorage.setItem(
                                                                        'vehicleidfordetail',
                                                                        vehicleId,
                                                                    );
                                                                }}
                                                            >
                                                                {t('vehicle_details').toUpperCase()}
                                                            </Link>
                                                            <i className='fa fa-angle-right' aria-hidden='true'></i>
                                                        </>
                                                    )}
                                                    {userType === 'SUPPLIER_IN_CHARGE' && (
                                                        <>
                                                            <Link
                                                                to={`/dashboard/supplier/vehicles-details/${getOrderDetails?.supplierVehicle?.vehicle?.id}`}
                                                                onClick={() => {
                                                                    const vehicleId =
                                                                        getOrderDetails?.supplierVehicle?.vehicle?.id;
                                                                    dispatch(GetProviderVehicleDetail(vehicleId));
                                                                    sessionStorage.setItem(
                                                                        'vehicleidfordetail',
                                                                        vehicleId,
                                                                    );
                                                                }}
                                                            >
                                                                {t('vehicle_detailsaqui').toUpperCase()}
                                                            </Link>
                                                            <i className='fa fa-angle-right' aria-hidden='true'></i>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <NotInformation text={t('no_information')} card={false} />
                        )}
                    </div>
                </div>
            </div>
            <DeleteModal
                title_modal={t('delete_order')}
                element_modal={t('delete_order')}
                show={deleteOrderShow}
                onHide={() => setDeleteOrderShow(false)}
                onClickFn={() => deleteSelectedOrders(deleteItemId)}
            />
        </>
    );
};

export default OrderDetail;
