import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationHandler = () => {
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;

        return () => {
            sessionStorage.setItem('previousPath', currentPath);
        };
    }, [location.pathname]);

    return null;
};

export default NavigationHandler;
