import React, { useEffect, useState, useRef, useMemo } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { permissionObj } from '../../../Helpers/permission';
import { useDispatch, useSelector } from 'react-redux';

import { GetColorAttendance } from '../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import { GetEmailSetting } from '../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';
import tagIcon from '../../../assets/icon/tag-solid.svg';
import faceIcon from '../../../assets/icon/ic-face.svg';
import cardIcon from '../../../assets/icon/ic-card.svg';
import fingerIcon from '../../../assets/icon/ic-fingerprint.svg';
import qrIcon from '../../../assets/icon/ic-qr-code.svg';
import pinIcon from '../../../assets/icon/pin-icon.png';
import bluetoothIcon from '../../../assets/icon/bluetooth-icon.svg';
import laptopIcon from '../../../assets/laptop-solid.svg';
import mobileIcon from '../../../assets/mobile-screen-solid.svg';
import useStyle from '../../../hooks/useStyle';
import { Box, InputAdornment, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { accessMethod } from '../../../enums/accessMethodEnum';
import moment from 'moment';
import SearchFilterBtn from '../../../components/buttons/SearchFilterBtn';
import SearchFor from '../../Modals/SearchFor';
import { TABLES } from '../../../Apis/Tables';
import { MODELS } from '../../../Apis/Models';
import { SearchByFilters } from '../../../reduxToolkit/Search/SearchApi';
import { clearSearchFilters } from '../../../reduxToolkit/Search/SearchSlice';
import ExportDataBtn from '../../../components/buttons/ExportDataBtn';
import NotInformation from '../../../components/NotInformation';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MonthEnum } from '../../../enums/monthEnum';
import Tableweekly from './Tableweekly';
import platesIcon from '../../../assets/icon/license-plate.png';
import calendar from '../../../assets/ic-calendar.svg';
import SearchSimple from '../../../components/SearchSimples/SearchSimple';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { fileDetail } from '../../../reduxToolkit/CompanyBackup/backUpSlice';
const Payroll = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { textField } = useStyle();
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { searchByFilters } = useSelector((state) => state.SearchSlice);

    const lCode = Cookies.get('i18next') || 'en';

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [startShiftAt, setStartShiftAt] = useState('');
    const [endShiftAt, setEndShiftAt] = useState('');
    const [range, setRange] = useState(0);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    const [newFilter, setNewFilter] = useState(true);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [selectedOption, setSelectedOption] = useState(1);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDateStart, setSelectedDateStart] = useState(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const [bodyForExport, setBodyForExport] = useState('');
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [simpleSearch, setSimpleSearch] = useState(false);
    const [search, setSearch] = useState(false);
    const [attendanceBody, setAttendanceBody] = useState(false);
    const [searchClean, setSearchClean] = useState(false);
    const [exportAttendance, setExportAttendance] = useState(true);
    const [modal, setModal] = useState(false);
    const [cleanAdvance, setCleanAdvance] = useState(false);
    const [complete, setComplete] = useState(false);
    const [isAttendance, setIsAttendance] = useState(true);
    const [resultsToShow, setResultsToShow] = useState(null);
    const [sendAttendance, setSendAttendance] = useState(false);
    const [cleanRules, setCleanRules] = useState();
    /*     const [rulesExist, setRulesExist] = useState(false); */
    const [color, setColor] = React.useState({
        absence: '',
        onTime: '',
        effectiveTimeUnder: '',
        effectiveTimeOver: '',
    });

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setSearch(false);
        setSearchClean(true);
        setComplete(false);
        if (finalArray.length > 0 && selectedOption !== 1) {
            setCleanAdvance(true);
            const criteriaList = finalArray.map((item) => ({
                dataOption: item.dataOption,
                fieldType: item.fieldType,
                filterKey: item.filterKey,
                operation: item.operation,
                sort: item.sort,
                table: item.table,
                values: item.values,
                from: item.from,
                to: item.to,
            }));
            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: criteriaList,

                attendanceStartDate: attendanceStartDate,
                attendanceEndDate: attendanceEndDate,
                attendanceSearchType: selectedOption,
            };

            dispatch(SearchByFilters({ option, body })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success) {
                        setComplete(true);
                    }
                },
            );
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleDateEndChange = (date) => {
        setSelectedDateEnd(date);
    };
    const handleDateStartChange = (date) => {
        setSelectedDateStart(date);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //get current day
    const [currentDayTimestamp, setCurrentDayTimestamp] = useState(0);
    useEffect(() => {
        const date = new Date();
        const timestamp = date.getTime();
        setCurrentDayTimestamp(timestamp);
    }, []);
    useEffect(() => {
        if (filterDialogShow) {
            setFilterDialogShow(true);
        }
        if (searchElement && filterDialogShow) {
            toast.info(t('filters_reset_message'));
            setSearchElement('');
            setCleanAdvance(true);
            setSelectedOption(1);
            setSearch(false);
            setResultsToShow(null);
            setSearchClean(true);
            setFilterDialogShow(true);
        }
    }, [searchElement, filterDialogShow]);
    useEffect(() => {
        if (finalArray.length > 0 && cleanAdvance) {
            toast.info(t('filters_reset_message'));
            setSearchElement('');
            setSearch(false);
            setFilterDialogShow(true);
        }
    }, [finalArray]);

    //Get current week
    const getCurrentWeekOfMonth = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const dayOfMonth = today.getDate();
        const dayOfWeek = startOfMonth.getDay();
        const weekOfMonth = Math.ceil((dayOfMonth + dayOfWeek - 1) / 7);
        return weekOfMonth;
    };
    const currentWeekOfMonth = useMemo(() => getCurrentWeekOfMonth(), []);
    const getCurrentWeekOfYearWithWeekdays = () => {
        const today = new Date();
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const daysDifference = Math.floor((today - startOfYear) / (1000 * 60 * 60 * 24));
        const weekOfYear = Math.ceil((daysDifference + startOfYear.getDay() + 1) / 7);

        const startOfWeek = new Date(today);
        const dayOfWeek = startOfWeek.getDay();
        const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
        startOfWeek.setDate(startOfWeek.getDate() + mondayOffset);

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);

        return {
            weekOfYear,
            startOfWeek: startOfWeek.toLocaleDateString(),
            endOfWeek: endOfWeek.toLocaleDateString(),
        };
    };

    const currentWeekOfYearWithWeekdays = getCurrentWeekOfYearWithWeekdays();

    //get current month
    const getCurrentMonthDates = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const monthName = MonthEnum[month + 1];

        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        return {
            monthName,
            firstDay: firstDay.toLocaleDateString(),
            lastDay: lastDay.toLocaleDateString(),
        };
    };

    const currentMonthDates = useMemo(() => getCurrentMonthDates(), []);

    const getCurrentFortnightAndDate = () => {
        const today = new Date();
        const dayOfMonth = today.getDate();
        const monthIndex = today.getMonth() + 1;
        const biweekly = dayOfMonth <= 15 ? '1°' : '2°';
        const monthName = MonthEnum[monthIndex];
        const translatedMonthName = t(monthName);

        // Determine the start and end of the current fortnight
        const startOfFortnight =
            dayOfMonth <= 15
                ? new Date(today.getFullYear(), today.getMonth(), 1)
                : new Date(today.getFullYear(), today.getMonth(), 16);

        const endOfFortnight =
            dayOfMonth <= 15
                ? new Date(today.getFullYear(), today.getMonth(), 15)
                : new Date(today.getFullYear(), today.getMonth() + 1, 0);

        return {
            biweekly,
            startOfFortnight,
            endOfFortnight,
            translatedMonthName,
        };
    };

    // Uso de la función
    const currentFortnightData = useMemo(() => getCurrentFortnightAndDate(), []);

    // Props to the filter window
    const moduleId = `${MODELS.Attendance}`;
    const option = `${TABLES.ATTENDANCE}`;
    const [criteria, setCriteria] = useState();

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage, finalArray, deletedFlag, complete]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    // This section help us to determine the height of the main table
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 20;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        const currentUrl = window.location.pathname;
        const parts = currentUrl.split('/');
        const modulePart = parts[3] ? parts[3] : '' + parts[4] ? '/' + parts[4] : '';
    }, []);
    const { startOfFortnight, endOfFortnight, biweekly } = currentFortnightData;
    const startInMilliseconds = startOfFortnight.getTime();
    const endInMilliseconds = endOfFortnight.getTime();
    const startDate = new Date(startInMilliseconds);
    const endDate = new Date(endInMilliseconds);

    const attendanceStartDate = selectedOption === 4 ? startInMilliseconds : currentDayTimestamp;
    const attendanceEndDate = selectedOption === 4 ? endInMilliseconds : currentDayTimestamp;

    useEffect(() => {
        const criteriaList = finalArray.map((item) => ({
            dataOption: item.dataOption,
            fieldType: item.fieldType,
            filterKey: item.filterKey,
            operation: item.operation,
            sort: item.sort,
            table: item.table,
            values: item.values,
            from: item.from,
            to: item.to,
        }));
        const bodyExport = {
            attendanceEndDate: search ? null : attendanceEndDate,
            attendanceSearchType: search ? null : selectedOption,
            attendanceStartDate: search ? null : attendanceStartDate,
            pagination: {
                page: page,
                size: rowsPerPage,
            },
        };

        if (searchElement === '') {
            bodyExport.searchCriteriaList = criteriaList;
        }

        setExportAttendance(bodyExport);
        const body = {
            pagination: {
                page: page,
                size: rowsPerPage,
            },
            searchCriteriaList: search ? criteriaList : [],
            ...(search
                ? {}
                : {
                      attendanceStartDate: attendanceStartDate,
                      attendanceEndDate: attendanceEndDate,
                      attendanceSearchType: selectedOption,
                  }),
        };

        dispatch(SearchByFilters({ option, body })).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success) {
                    setComplete(true);
                }

                if (search && success) {
                    setSelectedOption(1);
                }
            },
        );

        setCriteria({ searchCriteriaList: criteriaList });
        setLoaded(false);
        setDeletedFlag(false);
    }, [page, rowsPerPage, orderBy, sortBy, deletedFlag, loaded, search]);

    useEffect(() => {
        if (selectedOption) {
            setLoaded(true);
        }
    }, [selectedOption]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await dispatch(GetColorAttendance());
            const {
                payload: {
                    data: { data, success },
                },
            } = result;

            if (success) {
                setColor(data);
            } else {
                console.error('Error fetching color data');
            }
        };

        fetchData();
    }, [dispatch]);
    const statusColorSchedule = {
        0: color.absence || '',
        1: color.onTime || '',
        2: color.effectiveTimeUnder || '',
        3: color.effectiveTimeOver || '',
    };

    useEffect(() => {
        dispatch(GetEmailSetting()).then(
            ({
                payload: {
                    data: { data, success },
                },
            }) => {
                if (success == true) {
                    setStartShiftAt(data.startShiftAt || '');
                    setEndShiftAt(data.endShiftAt || '');
                    setRange(data.range || 0);
                }
            },
        );
    }, []);

    const calculateTimeDifferenceInMinutes = (startShiftTime, entryTime) => {
        const start = new Date(`1970-01-01T${startShiftTime}`);
        const entry = new Date(`1970-01-01T${entryTime}`);

        const differenceInMilliseconds = entry - start;
        const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

        return differenceInMinutes;
    };
    useEffect(() => {
        if (!searchResults || searchResults?.content?.length === 0) {
            setResultsToShow(searchResults?.content?.length === 0 ? null : searchByFilters);
        } else {
            setResultsToShow(searchResults);
        }
    }, [searchResults, searchByFilters]);

    useEffect(() => {
        if ((searchElement && filterDialogShow) || (sendAttendance && search)) {
            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: [],
                attendanceStartDate: attendanceStartDate,
                attendanceEndDate: attendanceEndDate,
                attendanceSearchType: 1,
            };
            dispatch(SearchByFilters({ option, body })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success) {
                        setFilterDialogShow(true);
                        setSendAttendance(false);
                        if (search) {
                            setSearch(false);
                            setSelectedOption(1);
                        }
                    }
                },
            );
        }
    }, []);
    useEffect(() => {
        if (sendAttendance) {
            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: [],
                attendanceStartDate: attendanceStartDate,
                attendanceEndDate: attendanceEndDate,
                attendanceSearchType: 1,
            };
            dispatch(SearchByFilters({ option, body })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success) {
                        setSearch(false);
                        setSelectedOption(1);
                        setFilterDialogShow(true);
                    }
                },
            );
        }
    }, []);

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('attendance')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    <ExportDataBtn
                        body={exportAttendance}
                        searchElement={searchElement}
                        exportAttendance={exportAttendance}
                        option={option}
                        search={search}
                    />
                    {permission?.includes(permissionObj?.WEB_ATTENDANCE_UPDATE) && (
                        <button
                            id='btn_p_manage'
                            className='add-btn-1'
                            onClick={() => navigate('/dashboard/employee/attendance/manage-attendence')}
                        >
                            <i className='fa fa-envelope mx-2' aria-hidden='true'></i>
                            {t('manage')}
                        </button>
                    )}
                    <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                </div>
            </div>
            <div className='space-between'>
                <SearchSimple
                    setBodyForExport={setBodyForExport}
                    setSearchResults={setSearchResults}
                    searchElement={searchElement}
                    setSearchElement={setSearchElement}
                    optionTable={option}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    attendanceStartDate={attendanceStartDate}
                    attendanceEndDate={attendanceEndDate}
                    attendanceSearchType={selectedOption}
                    simpleSearch={simpleSearch}
                    setSimpleSearch={setSimpleSearch}
                    searchClean={searchClean}
                    setSearchClean={setSearchClean}
                    search={search}
                    setSearch={setSearch}
                    setCleanAdvance={setCleanAdvance}
                />
            </div>
            <div
                className='select-container-attendance'
                style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap' }}
            >
                <Box sx={{ flex: 1, marginRight: '20px' }}>
                    <Select
                        size='small'
                        labelId='demo-simple-select-label'
                        id='slt_p_frecuencia'
                        label={t('select_option')}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        style={{ width: '100%' }}
                    >
                        <MenuItem value={1}>{t('daily').toUpperCase()}</MenuItem>
                        <MenuItem value={2}>{t('weekly').toUpperCase()}</MenuItem>
                        <MenuItem value={3}>{t('monthly').toUpperCase()}</MenuItem>
                        <MenuItem value={4}>{t('biweekly').toUpperCase()}</MenuItem>
                    </Select>
                </Box>
                {selectedOption === 4 && (
                    <>
                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                className='helper_color'
                                type='text'
                                id='txt_p_currentMonth'
                                label={t('start_date')?.toUpperCase()}
                                value={startOfFortnight.toLocaleDateString()}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <img src={calendar} alt='calendar' style={{ width: 24, height: 24 }} />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                sx={textField}
                                disabled
                            />
                        </Box>

                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                className='helper_color'
                                type='text'
                                id='txt_p_end_date'
                                label={t('end_date')?.toUpperCase()}
                                value={endOfFortnight.toLocaleDateString()}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <img src={calendar} alt='calendar' style={{ width: 24, height: 24 }} />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                sx={textField}
                                disabled
                            />
                        </Box>

                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                className='helper_color'
                                type='text'
                                id='txt_p_currentFortnight'
                                label={t('current_fortnight')?.toUpperCase()}
                                value={biweekly}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <img src={calendar} alt='calendar' style={{ width: 24, height: 24 }} />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                sx={textField}
                                disabled
                            />
                        </Box>
                    </>
                )}

                {selectedOption === 1 && (
                    <Box sx={{ flex: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={selectedDate}
                                label={t('current')?.toUpperCase()}
                                onChange={handleDateChange}
                                id='dtp_p_currentDate'
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            readOnly: true,
                                        }}
                                        sx={textField}
                                        disabled
                                    />
                                )}
                                disabled={true}
                            />
                        </LocalizationProvider>
                    </Box>
                )}

                {selectedOption === 2 && (
                    <>
                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                className='helper_color'
                                type='text'
                                id='txt_p_start_date'
                                label={t('start_date')?.toUpperCase()}
                                value={currentWeekOfYearWithWeekdays.startOfWeek}
                                InputProps={{ readOnly: true }}
                                sx={textField}
                                disabled
                            />
                        </Box>

                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                className='helper_color'
                                type='text'
                                id='txt_p_end_date'
                                label={t('end_date')?.toUpperCase()}
                                value={currentWeekOfYearWithWeekdays.endOfWeek}
                                InputProps={{ readOnly: true }}
                                sx={textField}
                                disabled
                            />
                        </Box>

                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                className='helper_color'
                                type='number'
                                id='txt_p_week'
                                label={t('current_week_of_the_year')?.toUpperCase()}
                                value={currentWeekOfYearWithWeekdays.weekOfYear}
                                InputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <img src={calendar} alt='calendar' style={{ width: 24, height: 24 }} />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                sx={textField}
                                disabled
                            />
                        </Box>
                    </>
                )}

                {selectedOption === 3 && (
                    <>
                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                id='txt_p_month'
                                className='helper_color'
                                label={t('start_date')?.toUpperCase()}
                                value={currentMonthDates.firstDay}
                                InputProps={{
                                    inputMode: 'text',
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <img src={calendar} alt='calendar' style={{ width: 24, height: 24 }} />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                sx={textField}
                                disabled
                            />
                        </Box>

                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                id='txt_p_month'
                                className='helper_color'
                                label={t('end_date')?.toUpperCase()}
                                value={currentMonthDates.lastDay}
                                InputProps={{
                                    inputMode: 'text',
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <img src={calendar} alt='calendar' style={{ width: 24, height: 24 }} />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                sx={textField}
                                disabled
                            />
                        </Box>

                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <TextField
                                size='small'
                                fullWidth
                                id='txt_p_month'
                                className='helper_color'
                                label={t('current_month')?.toUpperCase()}
                                value={`${currentMonthDates.monthName.toUpperCase()}`}
                                InputProps={{
                                    inputMode: 'text',
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <img src={calendar} alt='calendar' style={{ width: 24, height: 24 }} />
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                sx={textField}
                                disabled
                            />
                        </Box>
                    </>
                )}
            </div>
            {!search && selectedOption === 2 && <Tableweekly complete={complete} data={resultsToShow} color={color} />}
            {!search && selectedOption === 3 && <Tableweekly complete={complete} data={resultsToShow} color={color} />}
            {!search && selectedOption === 4 && <Tableweekly complete={complete} data={resultsToShow} color={color} />}

            {(selectedOption === 1 || search) && (
                <div
                    className='panelTables animated-div px-1'
                    ref={elementRef}
                    style={{ width: '100%', paddingTop: '0rem' }}
                >
                    {resultsToShow?.content?.length > 0 ? (
                        <table className='w-100'>
                            <thead className='no-border-thead sticky-column'>
                                <th className='first_head'>{t('name')}</th>
                                <th>{t('entry_via')}</th>
                                <th>{t('entry_device')}</th>
                                <th>{t('entry_date')}</th>
                                <th>{t('exit_via')}</th>
                                <th>{t('exit_device')}</th>
                                <th className='last'>{t('exit_date')}</th>
                            </thead>
                            <tbody>
                                {search ? (
                                    <>
                                        {' '}
                                        <tr style={{ height: '30px' }}>
                                            <td colSpan={3}></td>
                                        </tr>
                                        {Array.isArray(resultsToShow?.content) && resultsToShow.content.length > 0 ? (
                                            resultsToShow.content.map((item) => (
                                                <tr key={item.id || item.day}>
                                                    <td className='first align-middle'>
                                                        {`${item.userName?.toUpperCase() || ''} ${
                                                            item.userLastName?.toUpperCase() || ''
                                                        } ${item.userSecondLastName?.toUpperCase() || ''}`.trim()}
                                                    </td>

                                                    <td>
                                                        {item.exitDate === item.entryDate || item.entryDate === null ? (
                                                            t('no_record').toUpperCase()
                                                        ) : (
                                                            <Tooltip
                                                                title={t(
                                                                    accessMethod[item.entryAccessMethodId],
                                                                ).toUpperCase()}
                                                                placement='right'
                                                                disableInteractive
                                                            >
                                                                <img
                                                                    src={
                                                                        item.entryAccessMethodId === 1
                                                                            ? tagIcon
                                                                            : item.entryAccessMethodId === 2
                                                                              ? fingerIcon
                                                                              : item.entryAccessMethodId === 3
                                                                                ? cardIcon
                                                                                : item.entryAccessMethodId === 4
                                                                                  ? qrIcon
                                                                                  : item.entryAccessMethodId === 5
                                                                                    ? faceIcon
                                                                                    : item.entryAccessMethodId === 6
                                                                                      ? pinIcon
                                                                                      : item.entryAccessMethodId === 7
                                                                                        ? fingerIcon
                                                                                        : item.entryAccessMethodId === 8
                                                                                          ? bluetoothIcon
                                                                                          : item.entryAccessMethodId ===
                                                                                              9
                                                                                            ? laptopIcon
                                                                                            : item.entryAccessMethodId ===
                                                                                                10
                                                                                              ? mobileIcon
                                                                                              : item.entryAccessMethodId
                                                                                                      ?.id === 11
                                                                                                ? bluetoothIcon
                                                                                                : item
                                                                                                        .entryAccessMethodId
                                                                                                        ?.id === 12
                                                                                                  ? bluetoothIcon
                                                                                                  : item.entryAccessMethodId ===
                                                                                                      13
                                                                                                    ? platesIcon
                                                                                                    : item
                                                                                                            .entryAccessMethodId
                                                                                                            ?.id === 14
                                                                                                      ? fingerIcon
                                                                                                      : item
                                                                                                              .entryAccessMethodId
                                                                                                              ?.id ===
                                                                                                          15
                                                                                                        ? fingerIcon
                                                                                                        : item
                                                                                                                .entryAccessMethodId
                                                                                                                ?.id ===
                                                                                                            16
                                                                                                          ? fingerIcon
                                                                                                          : null
                                                                    }
                                                                    alt='AccessMethod'
                                                                    style={{
                                                                        width: [6, 8, 9, 10].includes(
                                                                            item.entryAccessMethodId,
                                                                        )
                                                                            ? '24px'
                                                                            : undefined,
                                                                        height: [6, 8, 9, 10].includes(
                                                                            item.entryAccessMethodId,
                                                                        )
                                                                            ? '24px'
                                                                            : undefined,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item.entryAccessMethodId === 9
                                                            ? t('web_app').toUpperCase()
                                                            : item.entryAccessMethodId === 10
                                                              ? t('mobile_app').toUpperCase()
                                                              : item.exitDate === item.entryDate ||
                                                                  item.entryDeviceName === null
                                                                ? t('no_record').toUpperCase()
                                                                : item.entryDeviceName}
                                                    </td>
                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                statusColorSchedule[item.entryDateStatus] || '#707070',
                                                        }}
                                                    >
                                                        <div
                                                            className='date_col'
                                                            style={{
                                                                backgroundColor:
                                                                    statusColorSchedule[item.entryDateStatus] ||
                                                                    '#707070',
                                                            }}
                                                        >
                                                            {item.exitDate === item.entryDate ||
                                                            item.entryDate === null ? (
                                                                t('no_record').toUpperCase().replace(' ', '\n')
                                                            ) : (
                                                                <>
                                                                    {lCode === 'en'
                                                                        ? moment(item.entryDate).format('MM/DD/YYYY')
                                                                        : moment(item.entryDate).format('DD/MM/YYYY')}
                                                                    <br />
                                                                    {moment(item.entryDate).format('HH:mm:ss')}
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {item.exitDate === item.entryDate || item.exitDate === null ? (
                                                            t('no_record').toUpperCase()
                                                        ) : (
                                                            <Tooltip
                                                                title={t(
                                                                    accessMethod[item.exitAccessMethodId],
                                                                ).toUpperCase()}
                                                                placement='right'
                                                                disableInteractive
                                                            >
                                                                <img
                                                                    src={
                                                                        item.exitAccessMethodId === 1
                                                                            ? tagIcon
                                                                            : item.exitAccessMethodId === 2
                                                                              ? fingerIcon
                                                                              : item.exitAccessMethodId === 3
                                                                                ? cardIcon
                                                                                : item.exitAccessMethodId === 4
                                                                                  ? qrIcon
                                                                                  : item.exitAccessMethodId === 5
                                                                                    ? faceIcon
                                                                                    : item.exitAccessMethodId === 6
                                                                                      ? pinIcon
                                                                                      : item.exitAccessMethodId === 7
                                                                                        ? fingerIcon
                                                                                        : item.exitAccessMethodId === 8
                                                                                          ? bluetoothIcon
                                                                                          : item.exitAccessMethodId ===
                                                                                              9
                                                                                            ? laptopIcon
                                                                                            : item.exitAccessMethodId ===
                                                                                                10
                                                                                              ? mobileIcon
                                                                                              : item.exitAccessMethodId
                                                                                                      ?.id === 11
                                                                                                ? bluetoothIcon
                                                                                                : item
                                                                                                        .exitAccessMethodId
                                                                                                        ?.id === 12
                                                                                                  ? bluetoothIcon
                                                                                                  : item.exitAccessMethodId ===
                                                                                                      13
                                                                                                    ? platesIcon
                                                                                                    : item
                                                                                                            .exitAccessMethodId
                                                                                                            ?.id === 14
                                                                                                      ? fingerIcon
                                                                                                      : item
                                                                                                              .exitAccessMethodId
                                                                                                              ?.id ===
                                                                                                          15
                                                                                                        ? fingerIcon
                                                                                                        : item
                                                                                                                .exitAccessMethodId
                                                                                                                ?.id ===
                                                                                                            16
                                                                                                          ? fingerIcon
                                                                                                          : null
                                                                    }
                                                                    alt='AccessMethod'
                                                                    style={{
                                                                        width: [6, 8, 9, 10].includes(
                                                                            item.exitAccessMethodId,
                                                                        )
                                                                            ? '24px'
                                                                            : undefined,
                                                                        height: [6, 8, 9, 10].includes(
                                                                            item.exitAccessMethodId,
                                                                        )
                                                                            ? '24px'
                                                                            : undefined,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item.exitAccessMethodId === 9
                                                            ? t('web_app').toUpperCase()
                                                            : item.exitAccessMethodId === 10
                                                              ? t('mobile_app').toUpperCase()
                                                              : item.exitDate === item.entryDate ||
                                                                  item.exitDeviceName === null
                                                                ? t('no_record').toUpperCase()
                                                                : item.exitDeviceName}
                                                    </td>
                                                    <td
                                                        className='last_tr'
                                                        style={{
                                                            backgroundColor:
                                                                statusColorSchedule[item.exitDateStatus] || '#707070',
                                                        }}
                                                    >
                                                        <div
                                                            className='date_col'
                                                            style={{
                                                                backgroundColor:
                                                                    statusColorSchedule[item.exitDateStatus] ||
                                                                    '#707070',
                                                            }}
                                                        >
                                                            {item.exitDate === item.entryDate ||
                                                            item.exitDate === null ? (
                                                                t('no_record').toUpperCase()
                                                            ) : (
                                                                <>
                                                                    {lCode === 'en'
                                                                        ? moment(item.exitDate).format('MM/DD/YYYY')
                                                                        : moment(item.exitDate).format('DD/MM/YYYY')}
                                                                    <br />
                                                                    {moment(item.exitDate).format('HH:mm:ss')}
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan='7' className='text-center'>
                                                    {t('no_records').toUpperCase()}
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        <tr style={{ height: '30px' }}>
                                            <td colSpan={3}></td>
                                        </tr>
                                        {Array.isArray(resultsToShow?.content) && resultsToShow.content.length > 0 ? (
                                            resultsToShow.content.map((item) =>
                                                Array.isArray(item.attendances) && item.attendances.length > 0 ? (
                                                    item.attendances.map((attendance) => (
                                                        <tr key={attendance.id || attendance.day}>
                                                            <td className='first align-middle'>
                                                                {`${attendance.userName?.toUpperCase() || ''} ${
                                                                    attendance.userLastName?.toUpperCase() || ''
                                                                } ${
                                                                    attendance.userSecondLastName?.toUpperCase() || ''
                                                                }`.trim()}
                                                            </td>

                                                            <td>
                                                                {attendance.exitDate === attendance.entryDate ||
                                                                attendance.entryDate === null ? (
                                                                    t('no_record').toUpperCase()
                                                                ) : (
                                                                    <Tooltip
                                                                        title={t(
                                                                            accessMethod[
                                                                                attendance.entryAccessMethodId
                                                                            ],
                                                                        ).toUpperCase()}
                                                                        placement='right'
                                                                        disableInteractive
                                                                    >
                                                                        <img
                                                                            src={
                                                                                attendance.entryAccessMethodId === 1
                                                                                    ? tagIcon
                                                                                    : attendance.entryAccessMethodId ===
                                                                                        2
                                                                                      ? fingerIcon
                                                                                      : attendance.entryAccessMethodId ===
                                                                                          3
                                                                                        ? cardIcon
                                                                                        : attendance.entryAccessMethodId ===
                                                                                            4
                                                                                          ? qrIcon
                                                                                          : attendance.entryAccessMethodId ===
                                                                                              5
                                                                                            ? faceIcon
                                                                                            : attendance.entryAccessMethodId ===
                                                                                                6
                                                                                              ? pinIcon
                                                                                              : attendance.entryAccessMethodId ===
                                                                                                  7
                                                                                                ? fingerIcon
                                                                                                : attendance.entryAccessMethodId ===
                                                                                                    8
                                                                                                  ? bluetoothIcon
                                                                                                  : attendance.entryAccessMethodId ===
                                                                                                      9
                                                                                                    ? laptopIcon
                                                                                                    : attendance.entryAccessMethodId ===
                                                                                                        10
                                                                                                      ? mobileIcon
                                                                                                      : attendance
                                                                                                              ?.entryAccessMethodId
                                                                                                              ?.id ===
                                                                                                          11
                                                                                                        ? bluetoothIcon
                                                                                                        : attendance
                                                                                                                ?.entryAccessMethodId
                                                                                                                ?.id ===
                                                                                                            12
                                                                                                          ? bluetoothIcon
                                                                                                          : attendance?.entryAccessMethodId ===
                                                                                                              13
                                                                                                            ? platesIcon
                                                                                                            : attendance
                                                                                                                    ?.entryAccessMethodId
                                                                                                                    ?.id ===
                                                                                                                14
                                                                                                              ? fingerIcon
                                                                                                              : attendance
                                                                                                                      ?.entryAccessMethodId
                                                                                                                      ?.id ===
                                                                                                                  15
                                                                                                                ? fingerIcon
                                                                                                                : attendance
                                                                                                                        ?.entryAccessMethodId
                                                                                                                        ?.id ===
                                                                                                                    16
                                                                                                                  ? fingerIcon
                                                                                                                  : null
                                                                            }
                                                                            alt='AccessMethod'
                                                                            style={{
                                                                                width: [6, 8, 9, 10].includes(
                                                                                    attendance.entryAccessMethodId,
                                                                                )
                                                                                    ? '24px'
                                                                                    : undefined,
                                                                                height: [6, 8, 9, 10].includes(
                                                                                    attendance.entryAccessMethodId,
                                                                                )
                                                                                    ? '24px'
                                                                                    : undefined,
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {attendance.entryAccessMethodId === 9
                                                                    ? t('web_app').toUpperCase()
                                                                    : attendance.entryAccessMethodId === 10
                                                                      ? t('mobile_app').toUpperCase()
                                                                      : attendance.exitDate === attendance.entryDate ||
                                                                          attendance.entryDeviceName === null
                                                                        ? t('no_record').toUpperCase()
                                                                        : attendance.entryDeviceName}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    backgroundColor:
                                                                        statusColorSchedule[
                                                                            attendance.entryDateStatus
                                                                        ] || '#707070',
                                                                }}
                                                            >
                                                                <div
                                                                    className='date_col'
                                                                    style={{
                                                                        backgroundColor:
                                                                            statusColorSchedule[
                                                                                attendance.entryDateStatus
                                                                            ] || '#707070',
                                                                    }}
                                                                >
                                                                    {attendance.exitDate === attendance.entryDate ||
                                                                    attendance.entryDate === null ? (
                                                                        t('no_record').toUpperCase().replace(' ', '\n')
                                                                    ) : (
                                                                        <>
                                                                            {lCode === 'en'
                                                                                ? moment(attendance.entryDate).format(
                                                                                      'MM/DD/YYYY',
                                                                                  )
                                                                                : moment(attendance.entryDate).format(
                                                                                      'DD/MM/YYYY',
                                                                                  )}
                                                                            <br />
                                                                            {moment(attendance.entryDate).format(
                                                                                'HH:mm:ss',
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {attendance.exitDate === attendance.entryDate ||
                                                                attendance.exitDate === null ? (
                                                                    t('no_record').toUpperCase()
                                                                ) : (
                                                                    <Tooltip
                                                                        title={t(
                                                                            accessMethod[attendance.exitAccessMethodId],
                                                                        ).toUpperCase()}
                                                                        placement='right'
                                                                        disableInteractive
                                                                    >
                                                                        <img
                                                                            src={
                                                                                attendance.exitAccessMethodId === 1
                                                                                    ? tagIcon
                                                                                    : attendance.exitAccessMethodId ===
                                                                                        2
                                                                                      ? fingerIcon
                                                                                      : attendance.exitAccessMethodId ===
                                                                                          3
                                                                                        ? cardIcon
                                                                                        : attendance.exitAccessMethodId ===
                                                                                            4
                                                                                          ? qrIcon
                                                                                          : attendance.exitAccessMethodId ===
                                                                                              5
                                                                                            ? faceIcon
                                                                                            : attendance.exitAccessMethodId ===
                                                                                                6
                                                                                              ? pinIcon
                                                                                              : attendance.exitAccessMethodId ===
                                                                                                  7
                                                                                                ? fingerIcon
                                                                                                : attendance.exitAccessMethodId ===
                                                                                                    8
                                                                                                  ? bluetoothIcon
                                                                                                  : attendance.exitAccessMethodId ===
                                                                                                      9
                                                                                                    ? laptopIcon
                                                                                                    : attendance.exitAccessMethodId ===
                                                                                                        10
                                                                                                      ? mobileIcon
                                                                                                      : attendance
                                                                                                              ?.exitAccessMethodId
                                                                                                              ?.id ===
                                                                                                          11
                                                                                                        ? bluetoothIcon
                                                                                                        : attendance
                                                                                                                ?.exitAccessMethodId
                                                                                                                ?.id ===
                                                                                                            12
                                                                                                          ? bluetoothIcon
                                                                                                          : attendance?.exitAccessMethodId ===
                                                                                                              13
                                                                                                            ? platesIcon
                                                                                                            : attendance
                                                                                                                    ?.exitAccessMethodId
                                                                                                                    ?.id ===
                                                                                                                14
                                                                                                              ? fingerIcon
                                                                                                              : attendance
                                                                                                                      ?.exitAccessMethodId
                                                                                                                      ?.id ===
                                                                                                                  15
                                                                                                                ? fingerIcon
                                                                                                                : attendance
                                                                                                                        ?.exitAccessMethodId
                                                                                                                        ?.id ===
                                                                                                                    16
                                                                                                                  ? fingerIcon
                                                                                                                  : null
                                                                            }
                                                                            alt='AccessMethod'
                                                                            style={{
                                                                                width: [6, 8, 9, 10].includes(
                                                                                    attendance.exitAccessMethodId,
                                                                                )
                                                                                    ? '24px'
                                                                                    : undefined,
                                                                                height: [6, 8, 9, 10].includes(
                                                                                    attendance.exitAccessMethodId,
                                                                                )
                                                                                    ? '24px'
                                                                                    : undefined,
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {attendance.exitAccessMethodId === 9
                                                                    ? t('web_app').toUpperCase()
                                                                    : attendance.exitAccessMethodId === 10
                                                                      ? t('mobile_app').toUpperCase()
                                                                      : attendance.exitDate === attendance.entryDate ||
                                                                          attendance.exitDeviceName === null
                                                                        ? t('no_record').toUpperCase()
                                                                        : attendance.exitDeviceName}
                                                            </td>
                                                            <td
                                                                className='last_tr'
                                                                style={{
                                                                    backgroundColor:
                                                                        statusColorSchedule[
                                                                            attendance.exitDateStatus
                                                                        ] || '#707070',
                                                                }}
                                                            >
                                                                <div
                                                                    className='date_col'
                                                                    style={{
                                                                        backgroundColor:
                                                                            statusColorSchedule[
                                                                                attendance.exitDateStatus
                                                                            ] || '#707070',
                                                                    }}
                                                                >
                                                                    {attendance.exitDate === attendance.entryDate ||
                                                                    attendance.exitDate === null ? (
                                                                        t('no_record').toUpperCase()
                                                                    ) : (
                                                                        <>
                                                                            {lCode === 'en'
                                                                                ? moment(attendance.exitDate).format(
                                                                                      'MM/DD/YYYY',
                                                                                  )
                                                                                : moment(attendance.exitDate).format(
                                                                                      'DD/MM/YYYY',
                                                                                  )}
                                                                            <br />
                                                                            {moment(attendance.exitDate).format(
                                                                                'HH:mm:ss',
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr key={item.id}>
                                                        <td colSpan='7' className='text-center'>
                                                            {t('no_attendances').toUpperCase()}
                                                        </td>
                                                    </tr>
                                                ),
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan='7' className='text-center'>
                                                    {t('no_records').toUpperCase()}
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <NotInformation text={t('no_information')} card={true} />
                    )}
                </div>
            )}
            {searchByFilters?.content?.length > 0 && (
                <div className='d-flex justify-content-center'>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[20, 40, 60]}
                        count={searchByFilters?.totalElements}
                        page={page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage={t('attendance_per_page')}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}
            <SearchFor
                search={search}
                setSearch={setSearch}
                open={filterDialogShow}
                onClose={() => {
                    setFilterDialogShow(false);
                }}
                onFiltered={(originalArray) => {
                    setFilterDialogShow(false);
                    setFinalArray(originalArray);
                }}
                moduleId={moduleId}
                option={option}
                finalArray={finalArray}
                isAttendance={isAttendance}
                setSendAttendance={setSendAttendance}
                setCleanAdvance={setCleanAdvance}
                cleanAdvance={cleanAdvance}
                cleanRules={cleanRules}
            />
        </>
    );
};

export default Payroll;
