export const columnCode = {
    1: 'name',
    2: 'last_name',
    3: 'second_last_name',
    4: 'DEPARTMENT_ID',
    5: 'GENDER_ID',
    6: 'phone_number',
    7: 'email',
    8: 'documentId',
    9: 'DOB',
    10: 'EMPLOYEE_ID',
    11: 'ROLE_ID',
    12: 'ZONE_ID',
    13: 'start_date',
    14: 'end_date',
};
