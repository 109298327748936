import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { errorCode } from '../../enums/errorCodeEnum';
import { GetDocumentValidationStatus, GetUserRestrictionVisitor } from './VisitorApi';

const VisitorSlice = createSlice({
    name: 'documents',
    initialState: {
        getAllDocumentFormats: [],
        getUserRestrictionVisitor: {},
        getDocumentValidationStatus: {},
        getValidateIdentifyById: null,
        getValidationId: '',
        selectedDocumentType: '',
        validateObverseDocument: '',
        validateReverseDocument: '',
        validateSelfie: '',
        validateVideoPhoto: '',
        getTokenChallenge: '',
        updateContextualData: '',
        updateVeridasUser: {},
        validationProcessCancel: '',
        userId: '',
        userValidatedId: null,
        userDataDocument: {},
        isSuccessValidation: '',
        userSelfie: {},
        isSuccessUserSelfie: '',
    },
    reducers: {
        setSelectedDocumentType: (state, action) => {
            state.selectedDocumentType = action.payload;
        },
        clearPreview: (state, action) => {
            state.getPreview = null;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setUserValidatedId: (state, action) => {
            state.userValidatedId = action.payload;
        },
        setUserDataDocument: (state, action) => {
            state.userDataDocument = action.payload;
        },
        setIsSuccessValidation: (state, action) => {
            state.isSuccessValidation = action.payload;
        },
        setUserSelfie: (state, action) => {
            state.userSelfie = action.payload;
        },
        setIsSuccessUserSelfie: (state, action) => {
            state.isSuccessUserSelfie = action.payload;
        },
    },
    extraReducers: {
        'document/getAllAvailableTypes/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllDocumentFormats = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'document/updateVeridasUser/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateVeridasUser = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'document/getDocumentValidationStatus/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getDocumentValidationStatus = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'document/getValidationId/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getValidationId = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'document/getUserRestrictionVisitor/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getUserRestrictionVisitor = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast.success("Fail to ")
            }
        },
        'document/getValidateIdentifyById/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getValidateIdentifyById = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast.success("Fail to ")
            }
        },
        'document/validationProcessCancel/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.validationProcessCancel = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast.success("Fail to ")
            }
        },
        'document/validateObverseDocument/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.validateObverseDocument = data;
            } else if (status >= 400 && status < 500) {
                // toast.success("Fail to ")
            }
        },
        'document/validateReverseDocument/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.validateReverseDocument = data;
            } else if (status >= 400 && status < 500) {
                // toast.success("Fail to ")
            }
        },
        'document/validateSelfie/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.validateSelfie = data;
            } else if (status >= 400 && status < 500) {
                // toast.success("Fail to ")
            }
        },
        'document/getTokenChallenge/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getTokenChallenge = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'document/validateVideoPhoto/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.validateVideoPhoto = data;
            } else if (status >= 400 && status < 500) {
                // toast.success("Fail to ")
            }
        },
        'document/updateContextualData/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateContextualData = data;
            } else if (status >= 400 && status < 500) {
                // toast.success("Fail to ")
            }
        },
        clearPreview: (state) => {
            state.getPreview = [];
        },
    },
});

export const {
    clearPreview,
    setSelectedDocumentType,
    setUserId,
    setLivenessVisitor,
    setBiometricVisitor,
    setUserValidatedId,
    setUserDataDocument,
    setIsSuccessValidation,
    setUserSelfie,
    setIsSuccessUserSelfie,
} = VisitorSlice.actions;

export default VisitorSlice.reducer;
