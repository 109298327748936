import { colors } from './colors';

export const status = {
    // USER, VEHICLES status  1 - 10 //
    1: 'pre_registered', // colors.grey
    2: 'to_change_password', // colors.yellow
    3: 'to_approve_document', // colors.blue
    4: 'active', // colors.green
    5: 'on_vacations', // colors.orange
    6: 'in_active', // colors.red

    // COMPANY Status 11 -  20 //
    11: 'active_company', // colors.blue
    12: 'inactive_company', // colors.blue
    13: 'zone_in_construction_company', // colors.blue
    14: 'start_date', // colors.blue
    15: 'end_date', // colors.blue
    16: 'IN_USE_COMPANY', // colors.blue
    17: 'REMOVED_COMPANY', // colors.blue
    18: 'DOCUMENT_IN_VALIDATION', // colors.blue
    19: 'DOCUMENT_VALIDATED', // colors.blue
    20: 'DOCUMENT_REJECTED', // colors.blue

    // CONTRACTOR 21-25 //
    24: 'contract_finished', // colors.grey
    25: 'contract_active', // colors.yellow

    // SUPPLIER 26-30 //
    26: 'supplier_active', // colors.blue
    27: 'supplier_inactive', // colors.blue

    // EVENTS 31-35 //
    31: 'event_cancel', // colors.red
    32: 'event_in_validation', // colors.blue
    33: 'event_approved', // colors.green
    34: 'event_declined', // colors.orange

    // INVITATIONS 36-40 //
    36: 'INVITATION_PENDING_TO_APPROVE', // colors.blue
    37: 'invitation_unattended', // colors.grey
    38: 'invitation_accepted', // colors.green
    39: 'invitation_rejected', // colors.orange
    40: 'INVITATION_CANCELED', // colors.red

    // HOLIDAYS 41-49 //
    41: 'HOLIDAY_PENDING_TO_VALIDATE', // colors.grey
    42: 'HOLIDAY_DENIED', // colors.red
    43: 'HOLIDAY_APPROVED', // colors.green
    44: 'HOLIDAY_CANCELED', // colors.orange

    // CONTRACTS 50 - 59 //
    50: 'CONTRACT_INCOMING', // colors.grey
    51: 'contract_active', // colors.green
    52: 'CONTRACT_CANCEL', // colors.red
    53: 'contract_finished', // colors.blue

    // ORDERS 60 - 69 //
    60: 'ORDER_INCOMING', // colors.grey
    61: 'ORDER_STARTED', // colors.blue
    62: 'ORDER_COMPLETE', // colors.green
    63: 'ORDER_CANCELED', // colors.red

    // TRAVEL 70 - 79 //
    70: 'TRAVEL_IN_TRANSIT', // colors.grey
    71: 'TRAVEL_ENTRY_REVISION', // colors.blue
    72: 'TRAVEL_DOWNLOAD_ITEM', // colors.blue
    73: 'TRAVEL_EXIT_REVISION', // colors.blue
    74: 'TRAVEL_DELIVERED', // colors.green
    75: 'TRAVEL_NOT_ACCEPTED', // colors.orange
    76: 'TRAVEL_CANCELED', // colors.red
};
