import { t } from 'i18next';
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ReusableTextField from '../../../../components/ReusableTextField ';
import { AdvanceSearchByFilters, CreateHidSioRecord, GetSio } from '../../../../reduxToolkit/HID/HidApi';
import { TABLES } from '../../../../Apis/Tables';
import { MODELS } from '../../../../Apis/Models';
import { useNavigate } from 'react-router-dom';
import { setSelectedSio } from '../../../../reduxToolkit/HID/HidSlice';
const CreateSIOSForController = (props) => {
    const { controllerId, onHide, addNewSio, changed } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const lCode = Cookies.get('i18next') || 'en';
    const [name, setName] = useState('');
    const [hidName, setHidName] = useState('');
    const [hidId, setHidId] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [sioType, setSioType] = useState('');
    const [nameError, setNameError] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const getSio = useSelector((state) => state.HidSlice.getSio);
    const controller = useSelector((state) => state.HidSlice.advanceSearchByFilters);
    const dispatch = useDispatch();
    const option = 'hid';

    useEffect(() => {
        const body = {};

        dispatch(AdvanceSearchByFilters({ option, body }))
            .then(({ payload }) => {
                if (payload?.data && payload?.status === 200 && payload?.data.success) {
                    const { data, success } = payload.data;
                } else {
                    console.error('Error fetching data: Response structure not as expected');
                }
            })
            .catch((error) => {
                setLoaded(false);
                setDeletedFlag(false);
            });
    }, [dispatch, option]);

    const handleChange = (event) => {
        setHidName(event.target.value);
    };

    const getSelectOptions = () => {
        if (deviceType === 'X100') {
            return [
                { value: 'PORT_1', label: t('port_1') },
                { value: 'PORT_2', label: t('port_2') },
            ];
        } else if (deviceType === 'X1100') {
            return [{ value: 'INTERNAL', label: t('internal') }];
        }
        return [];
    };
    const selectOptions = getSelectOptions();
    const handleSubmit = () => {
        const body = {
            hidId: controllerId,
            name: name,
            deviceType: deviceType === 'X1100' ? 196 : deviceType === 'X100' ? 193 : null,
            sioType: sioType === 'PORT_1' ? 1 : sioType === 'PORT_2' ? 2 : sioType === 'INTERNAL' ? 3 : null,
        };
        dispatch(CreateHidSioRecord(body))
            .then((response) => {
                const { payload } = response;
                if (payload && payload.data && payload.data.success === true) {
                    toast.success(t('Created_successfully'));
                    onHide();
                    addNewSio(payload.data.newSio);
                    resetValues();
                } else {
                    console.error(t('Error_creating_SIO'));
                }
            })
            .catch((error) => {
                toast.error(t('Error_creating_SIO'));
            });
    };
    const resetValues = () => {
        setName('');
        setDeviceType('');
        setSioType('');
    };
    return (
        <Modal
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{ background: 'rgba(0,0,0,0.5)' }}
        >
            <div style={{ padding: '20px', width: '100%' }}>
                <button
                    id='btn_csc_back'
                    onClick={() => {
                        props.onHide();
                        setSubmitClicked(false);
                        resetValues();
                    }}
                    className='modal-close-btn'
                >
                    X
                </button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                    <span className='main-modal-heading'>{t('add_SIO')}</span>
                </div>
                <div className='unlink-modal-body'>
                    <span
                        className='modal-desc-text'
                        style={{ color: '#707070', fontSize: '10px', fontWeight: 'normal', textAlign: 'left' }}
                    >
                        <span style={{ fontWeight: 'bold' }}>{props?.data?.name}</span>
                    </span>

                    <div className='mt-2' style={{ position: 'relative' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    required
                                    id='txt_csc_name'
                                    label={t('name')}
                                    inputProps={{ maxLength: 128 }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='device-type-select-label'>{t('device_type')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='device-type-select-label'
                                            id='slt_csc_deviceType'
                                            label={t('device_type')}
                                            value={deviceType}
                                            onChange={(e) => setDeviceType(e.target.value)}
                                        >
                                            <MenuItem value='X1100'>X1100</MenuItem>
                                            <MenuItem value='X100'>X100</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth required>
                                        <InputLabel id='sio-type-select-label'>{t('sio_type')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='sio-type-select-label'
                                            id='slt_csc_sioType'
                                            value={sioType}
                                            onChange={(e) => setSioType(e.target.value)}
                                            label={t('sio_type')}
                                        >
                                            {selectOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='btn-div'>
                        <button
                            id='btn_csc_cancel'
                            className='custom_btn_cancel_gray_hover'
                            style={{ width: '100%' }}
                            onClick={() => {
                                props.onHide();
                                setSubmitClicked(false);
                                resetValues();
                            }}
                        >
                            {t('cancel')}{' '}
                        </button>
                        <button
                            id='btn_csc_add'
                            className='custom_primary_btn_dark'
                            style={{ width: '100%' }}
                            onClick={handleSubmit}
                        >
                            {t('add')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateSIOSForController;
