import React, { useEffect, useState } from 'react';
import Step4AccessRights from '../Company/Employees/EnrollmentSteps/Step4AccessRights';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GetEmployeeContractorByItId } from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import NotInformation from '../../../components/NotInformation';
import { toast } from 'react-toastify';

const AccessRights = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.authenticatioauthennSlice.user.data.id);

    const contractorIdU = useSelector((state) => state.ContractorSlice.getContractorsByUserId.id);

    const [workShiftsList, setWorkShiftsList] = useState([]);
    const [customizedList, setCustomizedList] = useState([]);

    useEffect(() => {
        if (userId) {
            dispatch(GetEmployeeContractorByItId(contractorIdU)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        if (data?.customs?.length > 0) {
                            const responseCustoms = data?.customs;
                            const customsList = responseCustoms.map((custom) => ({
                                ...custom,
                                added: true,
                            }));

                            setCustomizedList(customsList);
                        }

                        if (data?.workShifts?.length > 0) {
                            const responseShifts = data?.workShifts;
                            const shiftsList = responseShifts.map((shift) => ({
                                ...shift,
                                added: true,
                            }));

                            setWorkShiftsList(shiftsList);
                        }
                    }
                },
            );
        }
    }, [contractorIdU, dispatch]);

    return (
        <>
            <div className='head '>
                <div className='headLeft'>
                    <h2>{t('access_rights')}</h2>
                </div>
            </div>
            <div className='access-rights-contractor' style={{ padding: '80px' }}>
                {workShiftsList.length > 0 || customizedList.length > 0 ? (
                    <Step4AccessRights
                        workShiftsList={workShiftsList}
                        setWorkShiftsList={setWorkShiftsList}
                        customizedList={customizedList}
                        setCustomizedList={setCustomizedList}
                    />
                ) : (
                    <NotInformation text={t('no_information')} card={true} />
                )}
            </div>
        </>
    );
};

export default AccessRights;
