import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import personPng from '../../../assets/defaultImages/userDef.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DocumentStatusThree from '../../../components/DocumentStatusThree';
import { DownloadProviderImage } from '../../../reduxToolkit/Providers/providersApi';
import DeleteModal from '../../Modals/DeleteModal';
import genderId from '../../../hooks/genderId';
import UnlinkDeviceChangeModal from '../../Provider/Modal/UnlinkDeviceChangeModal';
import {
    CreateContractorDocValue,
    GetContractorEmployeeDetail,
    SetContractorDocValue,
} from '../../../reduxToolkit/Contractor/ContractorApi';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import ic_edit from '../../../assets/icon/ic-edit-pencil.svg';
import NotInformation from '../../../components/NotInformation';

const EmployeContractDetail = ({ approveDocument }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { id } = useParams();

    // use State
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectDocumentsForDelete, setSelectDocumentsForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteDocShow, setDeleteDocShow] = useState(false);
    const [unlinkShow, setUnlinkShow] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(0);
    const [deleteEmployeeShow, setDeleteEmployeeShow] = useState(false);
    const [selectEmployeeForDelete, setSelectEmployeeForDelete] = useState();

    // use Selector
    const {
        getContractorEmployeeDetail,
        updateContractorUserRelationship,
        createContractorDocValue,
        setContractorDocValue,
        createContractorUserRelationship,
    } = useSelector((state) => state?.ContractorSlice);
    const { saveProviderImage, imageChangeCheck } = useSelector((state) => state.providersSlice);

    useEffect(() => {
        dispatch(GetContractorEmployeeDetail(id));
        setSelectEmployeeForDelete([sessionStorage.getItem('elementForDelete')]);
    }, [
        createContractorDocValue,
        setContractorDocValue,
        saveProviderImage,
        imageChangeCheck,
        updateContractorUserRelationship,
        deletedFlag,
    ]);

    const deleteSelectedDocuments = (deleteItem) => {
        const tableName = 'contractor_document';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        // toast.success(t('device_deleted_success'));
                        setDeletedFlag(deletedFlag + 1);
                        setSelectDocumentsForDelete([]);
                        setIsAllChecked(false);
                    } else {
                        // toast.error(t('something_went_wrong'));
                    }
                },
            );
        }
    };

    const deleteSelectedElement = (deleteItem) => {
        const tableName = 'contractor_employee';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        navigate('/dashboard/contractor/search-employe');
                    }
                },
            );
        }
    };

    return (
        <>
            {/* head with back link */}
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={'/dashboard/contractor/search-employe'} />
                    <h2>{approveDocument ? t('complete_documents') : t('employee_details')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    {!approveDocument && (
                        <button className='unlink-btn-1' onClick={() => setUnlinkShow(true)}>
                            <i class='fa fa-mobile' aria-hidden='true'></i>
                            {t('unlink_device')}
                        </button>
                    )}

                    <Link to={`/dashboard/contractor/update-employee/${getContractorEmployeeDetail?.user?.id}`}>
                        <button className='add-btn-1'>
                            <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                            {t('update')}
                        </button>
                    </Link>
                    {approveDocument && (
                        <button
                            className='delete-btn-1'
                            onClick={() => {
                                setDeleteEmployeeShow(true);
                            }}
                        >
                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}
                </div>
            </div>
            <div className='row employee_provider_detail_flow' style={{ gap: lCode === 'ar' ? '1rem' : '' }}>
                <div className='col-md-3 __userData '>
                    <img
                        src={
                            getContractorEmployeeDetail?.user?.selfie
                                ? `data:image/png;base64,${getContractorEmployeeDetail?.user?.selfie}`
                                : personPng
                        }
                        className='__userImage'
                        style={{ objectFit: 'cover' }}
                    />
                    <div className='__body'>
                        <p>{t('name')}</p>
                        <span>
                            {getContractorEmployeeDetail?.user?.name
                                ? getContractorEmployeeDetail?.user?.name +
                                  ' ' +
                                  (getContractorEmployeeDetail?.user?.secondLastName != null
                                      ? getContractorEmployeeDetail?.user?.secondLastName
                                      : ' ') +
                                  ' ' +
                                  getContractorEmployeeDetail?.user?.lastName
                                : '-'}
                        </span>
                        <p className='ishead'>{t('email')}</p>
                        <span>{getContractorEmployeeDetail?.user?.email}</span>
                        <p className='ishead'>{t('phone_number')}</p>
                        <span>{getContractorEmployeeDetail?.user?.phoneNumber}</span>

                        <p className='ishead'>{t('gender')}</p>
                        <span>
                            {getContractorEmployeeDetail?.user?.genderId
                                ? genderId(getContractorEmployeeDetail?.user?.genderId)
                                : '-----'}
                        </span>
                        <p className='ishead'>{t('documentId')}</p>
                        <span>{getContractorEmployeeDetail?.user?.documnentId}</span>
                    </div>
                </div>
                {getContractorEmployeeDetail?.documents.length > 0 ? (
                    <div className='col-md-8  documents_status_item'>
                        <div className='document_header'>
                            <p className='document_title'>{t('documents')}</p>
                            {approveDocument && (
                                <button
                                    className='delete-btn-1'
                                    disabled={selectDocumentsForDelete?.length === 0}
                                    onClick={() => {
                                        setDeleteDocShow(true);
                                    }}
                                >
                                    <i class='fa fa-trash-o' aria-hidden='true'></i>
                                    {t('delete')}
                                </button>
                            )}
                        </div>
                        <DocumentStatusThree
                            dataTable={getContractorEmployeeDetail?.documents}
                            userType={'contractor'}
                            approve={approveDocument}
                            setDocValue={SetContractorDocValue}
                            createDocValue={CreateContractorDocValue}
                            downloadImg={DownloadProviderImage}
                            optionDownload={'contractor_document'}
                            isAllChecked={isAllChecked}
                            setIsAllChecked={setIsAllChecked}
                            selectDocumentsForDelete={selectDocumentsForDelete}
                            setSelectDocumentsForDelete={setSelectDocumentsForDelete}
                        />
                    </div>
                ) : (
                    <div className='col-md-8  documents_status_item'>
                        <NotInformation text={t('no_information')} card={false} />
                    </div>
                )}
            </div>

            <DeleteModal
                title_modal={t('delete_documents')}
                data={selectDocumentsForDelete}
                show={deleteDocShow}
                onHide={() => setDeleteDocShow(false)}
                element_modal={t('documents')}
                isReset={selectDocumentsForDelete}
                isAllReset={setIsAllChecked}
                onClickFn={() => deleteSelectedDocuments(selectDocumentsForDelete)}
            />

            <DeleteModal
                title_modal={t('delete_employee')}
                // data={selectDocumentsForDelete}
                show={deleteEmployeeShow}
                onHide={() => setDeleteEmployeeShow(false)}
                element_modal={t('documents')}
                // isReset={selectDocumentsForDelete}
                // isAllReset={setIsAllChecked}
                onClickFn={() => deleteSelectedElement(selectEmployeeForDelete)}
            />
            <UnlinkDeviceChangeModal show={unlinkShow} onHide={() => setUnlinkShow(false)} />
        </>
    );
};

export default EmployeContractDetail;
