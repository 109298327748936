const restriction = 'restriction';
const getUserRestriction = 'getUserRestriction';
const getDocumentRestriction = 'getDocumentRestriction';
const getEventRestriction = 'getEventRestriction';
const getSupplierRestriction = 'getSupplierRestriction';
const getContractorRestriction = 'getContractorRestriction';
const updateUserRestriction = 'updateUserRestriction';
const updateDocumentRestriction = 'updateDocumentRestriction';
const updateEventRestriction = 'updateEventRestriction';
const updateSupplierRestriction = 'updateSupplierRestriction';
const updateContractorRestriction = 'updateContractorRestriction';
const getDocumentRestrictionsingle = 'getDocumentRestrictionsingle';

export {
    restriction,
    getUserRestriction,
    getDocumentRestriction,
    getEventRestriction,
    getSupplierRestriction,
    getContractorRestriction,
    updateUserRestriction,
    updateDocumentRestriction,
    updateEventRestriction,
    updateSupplierRestriction,
    updateContractorRestriction,
    getDocumentRestrictionsingle,
};
