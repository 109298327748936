import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import addTemplateIcon from '../../assets/icon/ic-template.svg';
import AddCardModal from '../../pages/Dashboard/Company/Employees/Modal/AddCardModal';
import AddTemplateModal from '../../pages/Dashboard/Company/Employees/Modal/AddTemplateModal';

const AddTemplate = ({ body, option, loaded, setLoaded, creationErrorsShow, setCreationErrorsShow }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [addCustomizedDialogShow, setAddCustomizedDialogShow] = useState(false);
    const [customizedList, setCustomizedList] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleCard = () => {
        setAddCustomizedDialogShow(true);
    };
    return (
        <div>
            <button onClick={handleCard} className='add-btn-2'>
                <img
                    src={addTemplateIcon}
                    alt='Add Template'
                    id='btn_at_addTemplate'
                    style={{ width: '22px', height: '22px', marginRight: '8px' }}
                />
                {t('add_template')}
            </button>

            <AddTemplateModal
                open={addCustomizedDialogShow}
                onClose={() => {
                    setAddCustomizedDialogShow(false);
                }}
                loaded={loaded}
                setLoaded={setLoaded}
                customizedList={customizedList}
                setCustomizedList={setCustomizedList}
                creationErrorsShow={creationErrorsShow}
                setCreationErrorsShow={setCreationErrorsShow}
            />
        </div>
    );
};

export default AddTemplate;
