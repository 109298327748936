import React, { useEffect, useState, useRef } from 'react';
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import FormHelperText from '@mui/material/FormHelperText';

import submitupload from '../../../assets/images/upload.PNG';
import ic_save from '../../../assets/images/ic-save.svg';
import noLogo from '../../../assets/defaultImages/no-logo.png';
import LefletMap from '../../../components/LefletMap';
import { fileSize, imageSize } from '../../../constant/variable';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateComopanyImg } from '../../../Apis/companydata';
import cloud from '../../../assets/images/cloud.svg';
import { toast } from 'react-toastify';
import {
    companyDataToUpdate,
    getCompanyRestrictionObj,
    getSelectRestrictionObj,
    updateCompany,
    updateCompanyRestrictions,
} from '../../../reduxToolkit/UpdateCompany/UpdateCompanyApi';
import { mapCoordinates } from '../../../reduxToolkit/UpdateCompany/UpdateCompanySlice';
// language translator import
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import MuiTextField from '../../../components/MuiTextField';
import DividerWithText from '../../../components/DividerWithText';
import { Company } from '../../../models/Company';
import { permissionObj } from '../../../Helpers/permission';

const UpdateData = () => {
    const lCode = Cookies.get('i18next') || 'en';
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        user: {
            data: { id },
        },
    } = useSelector((state) => state.authenticatioauthennSlice);
    const { SelectRestrictionData } = useSelector((state) => state.UpdateCompanySlice);
    const { companyDataToUpdateObj } = useSelector((state) => state.UpdateCompanySlice);
    const { CompanyRestrictionData } = useSelector((state) => state.UpdateCompanySlice);
    const { latLngObj } = useSelector((state) => state.UpdateCompanySlice);

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    const [showMap, setShowMap] = useState(false);
    const [updateCompanyImg, setUpdateCompanyImg] = useState();
    const [companyImg, setCompanyImg] = useState();
    const [emptyFields, setEmptyFields] = useState(false);

    const [companyData, setCompanyData] = useState(Company);

    useEffect(() => {
        /*
    author rizwan ullah
     get restriction object to display on update data section
     */
        dispatch(getSelectRestrictionObj());

        /*
    author rizwan ullah
    get company data to display 
    */
        dispatch(companyDataToUpdate(id)).then(
            ({
                payload: {
                    data: { data },
                },
            }) => {
                dispatch(
                    mapCoordinates({
                        address: data?.address,
                        lat: data?.latitud,
                        lng: data?.longitud,
                    }),
                );
            },
        );
    }, []);

    useEffect(() => {
        setCompanyData((prevData) => ({
            ...prevData,
            ...companyDataToUpdateObj,
        }));
    }, [companyDataToUpdateObj]);

    useEffect(() => {
        setCompanyData((prevData) => ({
            ...prevData,
            address: latLngObj?.address,
            latitud: latLngObj?.lat,
            longitud: latLngObj?.lng,
        }));
    }, [latLngObj]);

    /* manage image when loaded from local device.*/
    const onImageChange = (e) => {
        if (e.target.files[0].size < imageSize) {
            if (e.target.files[0].type.includes('image')) {
                setUpdateCompanyImg(e.target.files[0]);
                const [file] = e.target.files;
                setCompanyImg(URL.createObjectURL(file));
            } else {
                toast.error(t('error_file_format').toUpperCase());
            }
        } else {
            toast.error(`${t('the_file_size_should_not_exceed').toUpperCase()} ${imageSize / 1024}KB.`);
        }
    };

    const fileInputRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            fileInputRef.current.files = files;
            onImageChange({ target: fileInputRef.current });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompanyData({ ...companyData, [name]: value });
    };

    const handleSubmit = () => {
        setEmptyFields(true);
        if (!companyData.name || !companyData.acronym || !companyData.address) {
            toast.warn(t('please_fill_all_fields').toUpperCase());
        } else {
            /* update company data.*/
            dispatch(updateCompany(companyData.toUpdate())).then(
                ({
                    payload: {
                        data: { data },
                    },
                }) => {
                    dispatch(
                        mapCoordinates({
                            address: data?.address,
                            lat: data?.latitud,
                            lng: data?.longitud,
                        }),
                    );

                    let formData = new FormData();
                    formData.append('id', data?.id);
                    formData.append('option', 'company');
                    formData.append('file', updateCompanyImg);

                    /* restric if image is not uploaded don't call Api*/
                    if (updateCompanyImg !== undefined && updateCompanyImg?.size <= 512000) {
                        /* company image Update */
                        updateComopanyImg(formData)
                            .then((data) => {
                                // console.log(data);
                            })
                            .catch((error) => {
                                toast.error('something went wrong in updating image company section');
                            });
                    }
                },
            );
        }
    };

    return (
        <div className='dragdrop_row'>
            <div className='head'>
                <div className='headLeft'>
                    <Link to='/dashboard/employee/company' hidden={true}>
                        <i
                            className='fa fa-arrow-left'
                            aria-hidden='true'
                            style={{
                                transform: lCode === 'ar' ? 'scaleX(-1)' : '',
                                margin: '0 10px',
                            }}
                        ></i>
                    </Link>
                    <h2>{t('profile')}</h2>
                </div>
                <button onClick={handleSubmit} hidden={true}>
                    <span>{t('update_data')}</span>
                    <img src={ic_save} alt='ic_save' />
                </button>
            </div>
            <div className='d-flex justify-content-center'>
                <div style={{ width: '90%' }}>
                    <DividerWithText title={t('data')} />
                    <div className='mt-0 row'>
                        <div className='col-lg-3 required-data-text-form mt-4'>
                            <MuiTextField
                                label={t('company_name')}
                                name='name'
                                value={companyData.name}
                                handleChange={handleChange}
                                require={true}
                                inputProps={{ maxLength: 128 }}
                                error={emptyFields && companyData.name === ''}
                                helperText={emptyFields && companyData.name === '' ? t('requiredField') : ''}
                            />
                        </div>
                        <div className='col-lg-3 required-data-text-form mt-4'>
                            <MuiTextField
                                label={t('acronym')}
                                name='acronym'
                                value={companyData.acronym}
                                handleChange={handleChange}
                                require={true}
                                inputProps={{ maxLength: 128 }}
                                error={emptyFields && companyData.acronym === ''}
                                helperText={emptyFields && companyData.acronym === '' ? t('requiredField') : ''}
                            />
                        </div>
                        <div className='col-lg-6 position-relative mt-4'>
                            <MuiTextField
                                label={t('address')}
                                name='address'
                                disable={true}
                                value={latLngObj?.address}
                                require={true}
                                // handleChange={handlFChange}
                            />
                            <p className='pull-right mt-3' onClick={() => setShowMap(!showMap)}>
                                {showMap ? t('click_to_close_map') : t('click_to_show_map')}
                            </p>
                            {/* condition if user want to show mapor not */}
                        </div>
                        {/* condition if user want to show mapor not */}
                        {showMap ? <LefletMap latlng={[latLngObj?.lat, latLngObj?.lng]} /> : ''}
                    </div>
                    <div className={showMap ? 'mt-5 row' : 'mt-4 row'}>
                        <DividerWithText title={t('image_logo')} />
                        <div className='col-xl-6 d-flex align-items-center justify-content-center'>
                            <div className='updata_img_m'>
                                <label
                                    htmlFor='file-input'
                                    className='dottedborderbox'
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <img src={submitupload} alt='submitupload' className='submitupload' />
                                    <input
                                        type='file'
                                        id='file-input'
                                        accept='image/*, video/*'
                                        onChange={onImageChange}
                                        ref={fileInputRef}
                                    />
                                    <p>
                                        {t('drag_your_logo_or')} <br />
                                        {t('click_to_upload')}
                                        <br /> {`${t('max_size')} ${imageSize / 1024}KB`}
                                    </p>
                                </label>
                            </div>
                        </div>

                        <div className='col-xl-6 text-center mt-3 mb-5'>
                            {/* {companyImg ? ( */}
                            <img
                                src={
                                    companyImg
                                        ? companyImg
                                        : companyData?.image
                                          ? `data:;base64,${companyData?.image}`
                                          : noLogo
                                }
                                className='uploadedPath'
                                style={{ objectFit: 'contain' }}
                            />
                        </div>
                    </div>
                    <DividerWithText title={t('our_values')} />
                    <div className='mt-4 row'>
                        <div className='col-lg-6 information-data-text-form'>
                            <TextField
                                size='small'
                                fullWidth
                                multiline
                                rows={3}
                                label={t('mission')}
                                name='mision'
                                id='outlined-size-normal'
                                // defaultValue={latLngObj?.mision || ""}
                                value={companyData?.mision}
                                onChange={handleChange}
                                sx={{
                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                    '& 	.MuiOutlinedInput-notchedOutline': {
                                        textAlign: lCode === 'ar' ? 'right' : 'left',
                                    },
                                    '& 	.MuiInputLabel-root': {
                                        fontSize: 12,
                                        left: lCode === 'ar' ? 'inherit' : '0',
                                        right: lCode === 'ar' ? '1.75rem' : '0',
                                        transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                    },
                                }}
                            />
                            <FormHelperText id='outlined-weight-helper-text'>
                                {companyData?.mision ? `${companyData.mision.length}/2048 MAX` : '0/2048 MAX'}
                            </FormHelperText>
                        </div>
                        <div className='col-lg-6 information-data-text-form'>
                            <TextField
                                size='small'
                                fullWidth
                                multiline
                                rows={3}
                                label={t('vision')}
                                name='vision'
                                id='outlined-size-normal'
                                // defaultValue={latLngObj?.vision || ""}
                                value={companyData?.vision}
                                onChange={handleChange}
                                sx={{
                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                    '& 	.MuiOutlinedInput-notchedOutline': {
                                        textAlign: lCode === 'ar' ? 'right' : 'left',
                                    },
                                    '& 	.MuiInputLabel-root': {
                                        fontSize: 12,
                                        left: lCode === 'ar' ? 'inherit' : '0',
                                        right: lCode === 'ar' ? '1.75rem' : '0',
                                        transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                    },
                                }}
                            />
                            <FormHelperText id='outlined-weight-helper-text'>
                                {companyData?.vision ? `${companyData.vision.length}/2048 MAX` : '0/2048 MAX'}
                            </FormHelperText>
                        </div>
                        <div className='col-lg-12 information-data-text-form'>
                            <TextField
                                size='small'
                                fullWidth
                                multiline
                                rows={4}
                                label={t('introduction')}
                                name='introduction'
                                id='outlined-size-normal'
                                // defaultValue={latLngObj?.introduction || ""}
                                value={companyData?.introduction}
                                onChange={handleChange}
                                sx={{
                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                    '& 	.MuiOutlinedInput-notchedOutline': {
                                        textAlign: lCode === 'ar' ? 'right' : 'left',
                                    },
                                    '& 	.MuiInputLabel-root': {
                                        fontSize: 12,
                                        left: lCode === 'ar' ? 'inherit' : '0',
                                        right: lCode === 'ar' ? '1.75rem' : '0',
                                        transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                    },
                                }}
                            />
                            <FormHelperText id='outlined-weight-helper-text'>
                                {companyData?.introduction
                                    ? `${companyData.introduction.length}/2048 MAX`
                                    : '0/2048 MAX'}
                            </FormHelperText>
                        </div>
                    </div>
                    {permission?.includes(permissionObj?.WEB_COMPANY_UPDATE) && (
                        <div className='d-flex justify-content-end mb-3'>
                            <button className='custom_primary_btn_dark' style={{ width: '50%' }} onClick={handleSubmit}>
                                {t('update')?.toUpperCase()}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UpdateData;
