import React from 'react';
import pdfFile from '../../assets/Pdf/Data.pdf';
const DataProcessingAgreement = () => {
    return (
        <div className='privacy-policy'>
            <iframe src={pdfFile} style={{ width: '100%', height: '120vh', border: 'none' }} title='Privacy Policy' />
        </div>
    );
};

export default DataProcessingAgreement;
