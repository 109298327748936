import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const CardDetail = ({ title, children }) => {
    return (
        <div>
            <Typography className='title-section mb-3' sx={{ mb: 1.5 }}>
                {title}
            </Typography>
            <Card variant='outlined'>
                <CardContent className='d-flex p-2'>{children}</CardContent>
            </Card>
        </div>
    );
};

export default CardDetail;
