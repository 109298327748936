import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { errorCode } from '../../enums/errorCodeEnum';

const CardsSlice = createSlice({
    name: 'cards',
    initialState: {
        createResourceApi: null,
        getAllCardFormats: [],
        getFreeCards: [],
        createSingleCard: null,
        createMultipleCards: null,
        assignCardToUser: null,
        assignTemplateToCards: {},
        removeCardToUser: null,
        analizeCards: null,
        createTemplateCard: null,
        updateTemplateCard: null,
        getPreview: null,
        selectedCardsR: null,
    },
    reducers: {
        clearPreview: (state, action) => {
            state.getPreview = null;
        },
        setSelectedCardsR: (state, action) => {
            state.selectedCardsR = action.payload;
        },
    },
    extraReducers: {
        'cards/createResourceApi/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 201 && status < 300) {
            } else if (status >= 400 && status < 500) {
            }
        },
        'cards/createSingleCard/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success(t('has_been_created_successfully').toUpperCase());
            } else if (status >= 400 && status < 600) {
                if (!(data?.code && errorCode.includes(data?.code))) {
                    toast.error(t('error_creating').toUpperCase());
                }
            }
        },
        'cards/createMultipleCards/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success(t('has_been_created_successfully').toUpperCase());
            } else if (status >= 400 && status < 600) {
                if (!(data?.code && errorCode.includes(data?.code))) {
                    toast.error(t('error_creating').toUpperCase());
                }
            }
        },
        'cards/analizeCards/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.analizeCards = data;
            } else if (status >= 400 && status < 600) {
            }
        },
        'cards/getAllCardFormats/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllCardFormats = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'cards/getFreeCards/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getFreeCards = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'cards/getPreview/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // state.getPreview = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'cards/assignCardToUser/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.assignCardToUser = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'cards/assignTemplateToCards/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success(t('has_been_assigned_successfully').toUpperCase());
            } else if (status >= 400 && status < 600) {
                if (!(data?.code && errorCode.includes(data?.code))) {
                    toast.error(t('error_assigning').toUpperCase());
                }
            }
        },
        'cards/removeCardToUser/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success(t('has_been_unassigned_successfully').toUpperCase());
            } else if (status >= 400 && status < 500) {
                toast.error(t('error_unassigning').toUpperCase());
            }
        },
        'cards/createTemplateCard/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createTemplateCard = data;
            } else if (status >= 400 && status < 600) {
            }
        },
        'cards/updateTemplateCard/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                toast.success(t('card_updated').toUpperCase());
            } else if (status >= 400 && status < 500) {
                toast.error(t('error'));
            }
        },
        clearPreview: (state) => {
            state.getPreview = [];
        },
    },
});

export const { clearPreview, setSelectedCardsR } = CardsSlice.actions;

export default CardsSlice.reducer;
