import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DeleteItemsApi } from '../../../../../reduxToolkit/Commons/CommonsApi';

const DeleteScheduleModal = ({ selectedIds, show, onHide, flag, setDeletedFlag }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    const handleDelete = () => {
        const tableName = 'attendance_department';
        const body = selectedIds;
        setLoading(true);
        dispatch(DeleteItemsApi({ tableName, body })).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                setLoading(false);
                if (success) {
                    setDeletedFlag(true);
                    onHide();
                } else {
                    toast.error(t('error_deleting'));
                }
            },
        );
    };

    return (
        <Modal
            className='department_and_user_delete-modal'
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title className='title_name'>{t('remove_schedule')}</Modal.Title>
                <img onClick={onHide} className='modalClose' src={cancel} alt='' />
            </Modal.Header>
            <Modal.Body className='department_modal_body'>
                <p className='paragraph_deps'>{t('are_you_sure_you_want_to_delete')}?</p>
                <div className='d-flex'>
                    <button
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '180px', marginLeft: '35px' }}
                        onClick={onHide}
                    >
                        {t('cancel').toLocaleUpperCase()}
                    </button>
                    <button
                        className='custom_primary_btn_dark'
                        style={{ width: '180px' }}
                        onClick={handleDelete}
                        disabled={loading}
                    >
                        {t('confirm').toLocaleUpperCase()}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteScheduleModal;
