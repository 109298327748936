import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import deleteIcon from '../../../assets/images/ic-delete-red.svg';
import warningIcon from '../../../assets/images/warning.svg';
import ManageDeleteDepartment from './component/ManageDeleteDepartment';
import ManageEmployeeDepartment from './component/ManageEmployeeDepartment';
import AddNewDepartmentModal from './component/AddNewDepartmentModal';
import { useDispatch, useSelector } from 'react-redux';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import {
    GetAllDepartments,
    GetAllNonDepartmentUser,
    ListOfUsersDepartment,
    RemoveUserFromDepartment,
} from '../../../reduxToolkit/Department/DepartmentApi';
import DeleteModal from '../../Modals/DeleteModal';
import { Box, Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';

import { permissionObj } from '../../../Helpers/permission';
import NotInformation from '../../../components/NotInformation';

import { TABLES } from '../../../Apis/Tables';
import { SimpleSearch } from '../../../reduxToolkit/Search/SearchApi';
import SearchIcon from '@mui/icons-material/Search';
import SearchSimple from '../../../components/SearchSimples/SearchSimple';
import ExportDataBtn from '../../../components/buttons/ExportDataBtn';

const Department = () => {
    // use hook
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    // use State hook
    const [show, setShow] = useState(false);
    const [modalName, setModalName] = useState('null');
    const [manageShow, setManageShow] = useState(false);
    const [addShow, setAddShow] = useState(false);
    const [data, setData] = useState('');
    const [manageEmploye, setManageEmployee] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [pageDepartment, setPageDepartment] = useState(0);
    const [rowsPerPageDepartment, setRowsPerPageDepartment] = useState(10);
    const [pageDepartmentUser, setPageDepartmentUser] = useState(0);
    const [rowsPerPageDepartmentUser, setRowsPerPageDepartmentUser] = useState(10);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [updateData, setUpdateData] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [deleteDepartments, setDeletedepartments] = useState(false);
    const [selectDepartmentForDelete, setSelectDepartmentForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectUserForDelete, setSelectUserForDelete] = useState([]);
    const [isAllCheckedUser, setIsAllCheckedUser] = useState(false);
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState();
    const option = TABLES.DEPARTMENT;

    // use Selector from redux store
    const {
        getAllDepartments,
        deleteDepartment,
        createDepartment,
        updateDepartment,
        listOfUsersDepartment,
        addDepartmentById,
        removeUserFromDepartment,
        removeAlluserFromDepartment,
    } = useSelector((state) => state.DepartmentSectionSlice);
    const { deleteItemsApi } = useSelector((state) => state.CommonsSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    // custom Funtion
    // a funtion to control zone page
    const handleChangePageDepartment = (event, newPage) => {
        setPageDepartment(newPage);
    };
    // a funtion to control row per page
    const handleChangeRowsPerPageDepartment = (event) => {
        setRowsPerPageDepartment(parseInt(event.target.value));
        setPageDepartment(0);
    };

    const handleChangePageDepartmentUser = (event, newPage) => {
        setPageDepartmentUser(newPage);
    };
    // a funtion to control row per page
    const handleChangeRowsPerPageDepartmentUser = (event) => {
        setRowsPerPageDepartmentUser(parseInt(event.target.value));
        setPageDepartmentUser(0);
    };

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getAllDepartments?.content?.map((item) => {
                return item?.id;
            });
            setSelectDepartmentForDelete(selectAllIds);
        } else {
            setSelectDepartmentForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectDepartmentForDelete([...selectDepartmentForDelete, e.target.id]);
        } else {
            setSelectDepartmentForDelete(selectDepartmentForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // this function control select all id or unSelect all
    const handelDeleteAllUser = (e) => {
        setIsAllCheckedUser(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = listOfUsersDepartment?.content?.map((item) => {
                return item?.userId;
            });
            setSelectUserForDelete(selectAllIds);
        } else {
            setSelectUserForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChangeUser = (e) => {
        if (e.target.checked) {
            setSelectUserForDelete([...selectUserForDelete, e.target.id]);
        } else {
            setSelectUserForDelete(selectUserForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };
    const userList = (id) => {
        const data = {
            id,
            pagination: {
                order: sortBy === 'asc' ? true : false,
                page: pageDepartmentUser,
                size: rowsPerPageDepartmentUser,
                sortBy: orderBy ? orderBy : 'id',
            },
        };
        dispatch(ListOfUsersDepartment(data));
        dispatch(GetAllNonDepartmentUser(id));
    };
    // delete department
    const deleteDepartmentModal = (deleteItem) => {
        const tableName = 'department';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body }));
    };
    const deleteSelectedManageUser = (ids) => {
        dispatch(RemoveUserFromDepartment(ids));
    };
    // useEffect cycle cycle for departments
    useEffect(() => {
        const body = {
            pagination: {
                order: sortBy === 'asc' ? true : false,
                page: pageDepartment,
                size: rowsPerPageDepartment,
                sortBy: orderBy ? orderBy : 'id',
            },
        };
        dispatch(GetAllDepartments(body));
    }, [
        pageDepartment,
        rowsPerPageDepartment,
        orderBy,
        sortBy,
        deleteDepartment,
        createDepartment,
        updateDepartment,
        deleteItemsApi,
    ]);
    // useEffect cycle for department users
    useEffect(() => {
        if (departmentId) {
            const data = {
                id: departmentId,
                pagination: {
                    order: sortBy === 'asc' ? true : false,
                    page: pageDepartmentUser,
                    size: rowsPerPageDepartmentUser,
                    sortBy: orderBy ? orderBy : 'id',
                },
            };
            dispatch(ListOfUsersDepartment(data));
        }
    }, [
        pageDepartmentUser,
        rowsPerPageDepartmentUser,
        orderBy,
        sortBy,
        addDepartmentById,
        removeUserFromDepartment,
        removeAlluserFromDepartment,
    ]);

    let resultsToShow;
    if (!searchResults || searchResults?.content?.length === 0) {
        resultsToShow = searchResults?.content?.length === 0 ? null : getAllDepartments;
    } else {
        resultsToShow = searchResults;
    }
    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('departments')}</h2>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    {/* Cambiar la lógica por si se implementa el Advanced Search */}
                    {searchResults && bodyForExport && <ExportDataBtn body={bodyForExport} option={false} />}
                    {permission?.includes(permissionObj?.WEB_DEPARTMENT_CREATE) && (
                        <button
                            className='add-btn-1'
                            disabled={selectDepartmentForDelete?.length > 0}
                            onClick={() => {
                                setAddShow(true);
                                setIsUpdate(false);
                            }}
                        >
                            <i className='fa fa-plus' aria-hidden='true'></i>
                            {t('add')}
                        </button>
                    )}

                    {permission?.includes(permissionObj?.WEB_DEPARTMENT_DELETE) && (
                        <button
                            className='delete-btn-1'
                            disabled={selectDepartmentForDelete?.length === 0}
                            onClick={() => {
                                setDeletedepartments(true);
                            }}
                        >
                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}
                </div>
            </div>
            <SearchSimple
                searchElement={searchElement}
                setSearchElement={setSearchElement}
                setBodyForExport={setBodyForExport}
                setSearchResults={setSearchResults}
                optionTable={option}
                page={pageDepartment}
                rowsPerPage={rowsPerPageDepartment}
            />
            <div className='departments'>
                {resultsToShow?.content?.length > 0 && (
                    <div className='panelTables animated-div px-1'>
                        <table>
                            <thead className='no-border-thead'>
                                <section className='table-header'>
                                    <section className='checkbox-and-name--WorkShift'>
                                        <th className='first_head'>
                                            <Tooltip
                                                title={t('de_/_select_all').toUpperCase()}
                                                placement='top'
                                                disableInteractive
                                            >
                                                <Checkbox
                                                    label='Label'
                                                    checked={selectDepartmentForDelete?.length > 0}
                                                    indeterminate={
                                                        selectDepartmentForDelete?.length > 0 &&
                                                        getAllDepartments?.content?.length !==
                                                            selectDepartmentForDelete?.length
                                                    }
                                                    onChange={handelDeleteAll}
                                                    size='small'
                                                />
                                            </Tooltip>
                                        </th>
                                        <th>{t('name')}</th>
                                    </section>
                                    <section>
                                        <th>{t('update')}</th>
                                        <th className='last'>{t('see_more')}</th>
                                    </section>
                                </section>
                            </thead>
                        </table>
                    </div>
                )}
                <Accordion>
                    {/* {getAllDepartments?.content?.map((item) => ( */}
                    {resultsToShow?.content?.length > 0 ? (
                        <>
                            {resultsToShow?.content?.map((item) => (
                                <Accordion.Item eventKey={item?.id} key={item?.id}>
                                    <Accordion.Header
                                        onClick={() => {
                                            setDepartmentId(item?.id);
                                            setDepartmentName(item?.name);
                                            userList(item?.id);
                                            setSelectUserForDelete([]);
                                        }}
                                    >
                                        <div className='rolesHeader'>
                                            <div className='leftText d-flex align-items-center'>
                                                <Checkbox
                                                    onClick={(event) => event.stopPropagation()}
                                                    className='grid-checkall checkbox'
                                                    checked={selectDepartmentForDelete?.includes(item?.id)}
                                                    id={item?.id}
                                                    onChange={handleCheckboxChange}
                                                    size='small'
                                                />
                                                <p>{item?.name}</p>
                                            </div>
                                            {
                                                // permission?.includes(permissionObj?.WEB_ROLE_DELETE) && item?.name !== "ROOT" && item?.name !== "NONE" ?
                                                <div className='rightText'>
                                                    {permission?.includes(permissionObj?.WEB_DEPARTMENT_UPDATE) && (
                                                        <div className='edit'>
                                                            <EditOutlinedIcon
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setAddShow(true);
                                                                    setUpdateData(item);
                                                                    setIsUpdate(true);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                //  : null
                                            }
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <div className='roleBody'>
                                            <div className='upper'>
                                                <p className='left'>{t('users_in_the_department')}</p>
                                                <p
                                                    className='right'
                                                    onClick={() => {
                                                        setManageShow(true);
                                                        const data = {
                                                            id: item?.id,
                                                        };
                                                        setManageEmployee(data);
                                                    }}
                                                >
                                                    {t('add_employees')}
                                                </p>
                                            </div>
                                            {/* <p className='inner_head'>{t('name')}</p> */}
                                            {listOfUsersDepartment?.content?.length > 0 ? (
                                                <>
                                                    <div className='d-flex gap-1 pl-2'>
                                                        <FormControlLabel
                                                            className='grid-checkall'
                                                            control={
                                                                <Checkbox
                                                                    label='Label'
                                                                    checked={selectUserForDelete?.length > 0}
                                                                    indeterminate={
                                                                        selectUserForDelete?.length > 0 &&
                                                                        listOfUsersDepartment?.content?.length !==
                                                                            selectUserForDelete?.length
                                                                    }
                                                                    onChange={handelDeleteAllUser}
                                                                    size='small'
                                                                />
                                                            }
                                                            label={t('de_/_select_all')}
                                                        />
                                                    </div>

                                                    <div className='nameList row'>
                                                        {listOfUsersDepartment?.content?.map((item, index) => (
                                                            <div className='col-3 my-1' key={index}>
                                                                {/* <img
                                                                    src={deleteIcon}
                                                                    alt="deleteimg"
                                                                    onClick={() => {
                                                                        setShow(true)
                                                                        setModalName("user")
                                                                        const data = {
                                                                            id: item?.id,
                                                                            Ename: item?.name,
                                                                            Dname: departmentName
                                                                        }
                                                                        setData(data)
                                                                    }}

                                                                /> */}
                                                                <Checkbox
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    className='grid-checkall checkbox'
                                                                    checked={selectUserForDelete?.includes(
                                                                        item?.userId,
                                                                    )}
                                                                    id={item?.userId}
                                                                    onChange={handleCheckboxChangeUser}
                                                                    size='small'
                                                                />
                                                                <span>
                                                                    {(item?.name || '') +
                                                                        ' ' +
                                                                        (item?.firstLastName || '') +
                                                                        (item?.secondLastName
                                                                            ? ' ' + item.secondLastName
                                                                            : '')}
                                                                </span>
                                                            </div>
                                                        ))}
                                                        <div className='footer_department'>
                                                            <TablePagination
                                                                component='div'
                                                                rowsPerPageOptions={[10, 15, 20, 30]}
                                                                count={listOfUsersDepartment?.totalElements}
                                                                page={pageDepartmentUser}
                                                                onPageChange={handleChangePageDepartmentUser}
                                                                labelRowsPerPage={t('employees_per_page')}
                                                                rowsPerPage={rowsPerPageDepartmentUser}
                                                                onRowsPerPageChange={
                                                                    handleChangeRowsPerPageDepartmentUser
                                                                }
                                                            />
                                                            {selectUserForDelete?.length > 0 && (
                                                                <div
                                                                    className='delete_user'
                                                                    onClick={() => {
                                                                        setShow(true);
                                                                        // setModalName("users")
                                                                        const data = {
                                                                            id: item?.id,
                                                                            Tuser: listOfUsersDepartment?.totalElements,
                                                                            Dname: departmentName,
                                                                        };
                                                                        setData(data);
                                                                    }}
                                                                >
                                                                    <p>{t('remove_selected')}</p>
                                                                    <i className='fa fa-trash-o' aria-hidden='true'></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='pb-5'>
                                                    <NotInformation text={t('no_information')} card={false} />
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </>
                    ) : (
                        <div className='pb-5'>
                            <NotInformation text={t('no_information')} card={false} />
                        </div>
                    )}
                </Accordion>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    left: '45%',
                    bottom: '0',
                }}
            >
                {resultsToShow?.content?.length > 0 && (
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[10, 15, 20, 30]}
                        count={resultsToShow?.totalElements}
                        page={pageDepartment}
                        onPageChange={handleChangePageDepartment}
                        labelRowsPerPage={t('department_per_page')}
                        rowsPerPage={rowsPerPageDepartment}
                        onRowsPerPageChange={handleChangeRowsPerPageDepartment}
                    />
                )}
            </Box>
            <AddNewDepartmentModal
                show={addShow}
                onHide={() => setAddShow(false)}
                title={''}
                check='false'
                isUpdate={isUpdate}
                data={updateData}
            />
            <DeleteModal
                show={deleteDepartments}
                onHide={() => setDeletedepartments(false)}
                data={selectDepartmentForDelete}
                onClickFn={() => deleteDepartmentModal(selectDepartmentForDelete)}
                title_modal={'departments'}
                element_modal={'department'}
                isReset={setSelectDepartmentForDelete}
                isAllReset={setIsAllChecked}
            />
            {/* <ManageDeleteDepartment
                show={show}
                onHide={() => setShow(false)}
                modalis={modalName}
                data={data}
            /> */}
            <DeleteModal
                show={show}
                onHide={() => setShow(false)}
                onClickFn={() => deleteSelectedManageUser(selectUserForDelete)}
                data={selectUserForDelete}
                description={'are_you_sure_you_want_to_remove'}
                title_modal={'departments'}
                element_modal={'employee'}
                isReset={setSelectUserForDelete}
                isAllReset={setIsAllCheckedUser}
            />
            <ManageEmployeeDepartment
                show={manageShow}
                onHide={() => setManageShow(false)}
                manageEmploye={manageEmploye}
            />
        </>
    );
};

export default Department;
