import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import qrIcon from '../../assets/icon/ic-qr-code-white.svg';
import SendQrModal from '../../pages/Modals/SendQrModal';
import { qrCodeByEmail } from '../../reduxToolkit/CompanyEmployees/CompanyEmployeesApi';

const SendQrBtn = ({ userId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);

    const sendQr = () => {
        dispatch(qrCodeByEmail(userId));
    };

    return (
        <div>
            <button className='send-qr-btn-1' onClick={() => setShowModal(true)}>
                <img
                    src={qrIcon}
                    alt='qrIcon'
                    style={{ width: '18px', height: '18px', marginBottom: '2px', marginLeft: '0px' }}
                />
                {t('send_qr_code')}
            </button>

            <SendQrModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onClickFn={() => sendQr()}
                title_modal={t('send_qr_code')}
                description={'to_send_the_qr_code_of_the_user_you_must_confirm_the_action'}
                element_modal={t('employees')}
            />
        </div>
    );
};

export default SendQrBtn;
