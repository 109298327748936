import React, { useEffect, useState, Fragment } from 'react';
import {
    Dialog,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Avatar,
    ListItemAvatar,
    ListItemButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { SnackbarProvider } from 'notistack';
import { useSnackbar } from 'notistack';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import NotInformation from '../../../../../components/NotInformation';
import FullPageLoader from '../../../../../utils/FullPageLoader';

const SelectReader = ({
    open,
    onClose,
    onFiltered,
    moduleId,
    option,
    finalArray,
    availableReaders,
    setSelectedReader,
    setAvailableReaders,
    selectedReader,
    captureFingerprint,
    fingerprintSrc,
    setFingerprintSrc,
    setIsSocketOpen,
    isSocketOpen,
    websocketRef,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const [showSpinner, setShowSpinner] = useState(false);

    const handleListReaderClick = (id) => {
        {
            // if (selectedReader === id) {
            //     enqueueSnackbar(t('instructions_capture_fingerprint').toUpperCase())
            //     setFingerprintSrc('')
            //     onClose();
            // } else {
            setSelectedReader(id);
            captureFingerprint(id);
            // enqueueSnackbar(t('instructions_capture_fingerprint').toUpperCase(), { variant: 'info', persist: true })
            setFingerprintSrc('');
            onClose();
            // }
        }
    };

    return (
        <Dialog className='select-camera-dialog' open={open}>
            <DialogTitle className='mt-2'>
                <p className='search-title'>{t('select_a_reader').toUpperCase()}</p>
                {!availableReaders.length && <NotInformation text={t('no_information')} card={false} />}
                <img
                    onClick={() => {
                        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
                            // Close the WebSocket if it's already open
                            websocketRef.current.close();
                            setIsSocketOpen(false);
                        }
                        onClose();
                    }}
                    className='modal-close'
                    src={cancel}
                    alt=''
                />
            </DialogTitle>

            {showSpinner && <FullPageLoader />}
            <List sx={{ pt: 0 }}>
                {availableReaders.length > 0 &&
                    availableReaders.map((reader, index) => (
                        <ListItem disableGutters>
                            <ListItemButton onClick={() => handleListReaderClick(reader.id)} key={index}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: `$grey`[100], color: `$grey`[600] }}>
                                        <FingerprintIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={reader.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </Dialog>
    );
};

export default SelectReader;
