import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { permissionObj } from '../../../Helpers/permission';
import { useDispatch, useSelector } from 'react-redux';
import ic_list_detail from '../../../assets/images/ic-list-detail_grey.svg';

import { GetAllDevices } from '../../../reduxToolkit/Devices/DevicesApi';
import DeleteModal from '../../Modals/DeleteModal';
import mobile_app from '../../../assets/images/mobile_app_image.png';
import fixed_app from '../../../assets/images/fixed_app_image.png';
import entry_ic from '../../../assets/images/arrow-up-solid-image.png';
import exit_ic from '../../../assets/images/arrow-down-solid-image.png';
import both_ic from '../../../assets/images/ic-both-entries-image.png';
import DeleteDeviceModal from './Modals/DeleteDeviceModal';
import DeviceLogModal from './Modals/DeviceLogModal';
import { TABLES } from '../../../Apis/Tables';
import { MODELS } from '../../../Apis/Models';
import SearchFor from '../../Modals/SearchFor';
import TablePagination from '@mui/material/TablePagination';
import { SearchByFilters, SimpleSearch } from '../../../reduxToolkit/Search/SearchApi';
import deviceTypeId from '../../../hooks/deviceTypeId';
import { toast } from 'react-toastify';
import { Checkbox, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import { clearSearchFilters } from '../../../reduxToolkit/Search/SearchSlice';
import SearchFilterBtn from '../../../components/buttons/SearchFilterBtn';
import qrIcon from '../../../assets/icon/ic-qr-code.svg';
import { ReactComponent as QrImage } from '../../../assets/icon/ic-qr-code.svg';
import { ReactComponent as ListDetail } from '../../../assets/images/ic-list-detail.svg';
import { GetQrCode } from '../../../reduxToolkit/Devices/DevicesApi';
import ReactDOM from 'react-dom';
import QRCode from 'qrcode.react';
import { colors } from '../../../enums/colors';
import NotInformation from '../../../components/NotInformation';
import SettingButton from '../../../components/SettingButton';
import SearchIcon from '@mui/icons-material/Search';
import SearchSimple from '../../../components/SearchSimples/SearchSimple';
import ExportDataBtn from '../../../components/buttons/ExportDataBtn';

const DevicesPanel = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [selectDeviceForDelete, setSelectDeviceForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteDeviceShow, setDeleteDeviceShow] = useState(false);
    const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [deleteSingleDevice, setDeleteSingleDevice] = useState();
    const [logDevice, setLogDevice] = useState();
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [logFlag, setLogFlag] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [qrData, setQrData] = useState(null);
    const [qrName, setQrName] = useState(null);
    const [searchResults, setSearchResults] = useState('');

    const [bodyForExport, setBodyForExport] = useState('');
    const [isElementExists, setIsElementExists] = useState(true);

    // useSelector
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { getAllDevices, deleteDevicesApi, getQrCode } = useSelector((state) => state.DevicesSlice);
    const { searchByFilters } = useSelector((state) => state.SearchSlice);

    // Props to the delete modal window
    const title_modal = `delete_devices`;
    const element_modal = `device_s`;
    const delete_table = `${TABLES.DEVICES}`;

    // Search simple for Devices Panel (only name)
    const [searchElement, setSearchElement] = useState('');
    const [cristeriaListBody, setCristeriaListBody] = useState();

    // Props to the filter window
    const moduleId = `${MODELS.Device}`;
    const option = `${TABLES.DEVICES}`;

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [page, rowsPerPage, finalArray, deletedFlag]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    // useEffect to check automatically all the items when page, rowsPerPage, or search change
    useEffect(() => {
        if (isAllChecked) {
            const selectAllIds = searchByFilters?.content?.map((item) => item?.id);
            setSelectDeviceForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [searchByFilters]);

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = searchByFilters?.content?.map((item) => {
                return item?.id;
            });
            setSelectDeviceForDelete(selectAllIds);
        } else {
            setSelectDeviceForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectDeviceForDelete([...selectDeviceForDelete, e.target.id]);
        } else {
            setSelectDeviceForDelete(selectDeviceForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // This section help us to determine the height of the main table
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        const currentUrl = window.location.pathname; // Get the current URL
        const parts = currentUrl.split('/');
        const modulePart = parts[3] ? parts[3] : '' + parts[4] ? '/' + parts[4] : '';
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //Create body for the respectively search
    useEffect(() => {
        if (loaded) {
            const criteriaList = finalArray.map((item) => {
                return {
                    dataOption: item.dataOption,
                    fieldType: item.fieldType,
                    filterKey: item.filterKey,
                    operation: item.operation,
                    sort: item.sort,
                    table: item.table,
                    values: item.values,
                };
            });

            const body = {
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                searchCriteriaList: criteriaList,
            };
            setCristeriaListBody(body);
            dispatch(SearchByFilters({ option, body })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        setSearchResults('');
                    }
                    {
                    }
                },
            );
            setLoaded(false);
            setDeletedFlag(false);
        }
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray, deletedFlag]);

    const deleteSelectedDevices = (deleteItem) => {
        const tableName = 'device';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setDeletedFlag(true);
                        setSelectDeviceForDelete([]);
                        setIsAllChecked(false);
                    } else {
                    }
                },
            );
        }
    };

    const downloadQRCode = () => {
        // Verificar si el elemento con ID "qr-gen" está presente en el DOM
        const canvas = document.getElementById('qr-gen');

        if (canvas) {
            // Si está presente, generamos la descarga
            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;

            downloadLink.download = `qr_code_${qrName}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } else {
            // Si el elemento no está presente, muestra un mensaje de error o toma otra acción
        }
    };

    useEffect(() => {
        if (qrData) {
            downloadQRCode();
        }
    }, [qrData]);

    // ¿Qué debe de imprimir en pantalla?
    let resultsToShow;

    if (!searchResults || searchResults?.content?.length === 0) {
        // Si searchResults es nulo, indefinido, o su contenido está vacío,
        // asignar searchByFilters, pero si searchResults existe y su contenido está vacío,
        // entonces resultsToShow debe ser null.
        resultsToShow = searchResults?.content?.length === 0 ? null : searchByFilters;
    } else {
        resultsToShow = searchResults;
    }
    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('devices_panel')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    <SettingButton
                        onAction={() => navigate('/dashboard/employee/devices/configuration')}
                        title={t('modify_settings')?.toUpperCase()}
                    />
                    {permission?.includes(permissionObj?.WEB_DEVICE_EXPORT) &&
                        (!searchResults || !bodyForExport ? (
                            <ExportDataBtn body={cristeriaListBody} option={option} />
                        ) : (
                            <ExportDataBtn searchElement={searchElement} body={bodyForExport} option={option} />
                        ))}

                    {permission?.includes(permissionObj?.WEB_DEVICE_CREATE) && (
                        <button
                            className='add-btn-1'
                            id='btn_dp_add'
                            disabled={selectDeviceForDelete.length > 0}
                            onClick={() => navigate('/dashboard/employee/devices/create')}
                        >
                            <i class='fa fa-plus' aria-hidden='true'></i>
                            {t('add')}
                        </button>
                    )}
                    {permission?.includes(permissionObj?.WEB_DEVICE_DELETE) && (
                        <button
                            className='delete-btn-1'
                            disabled={selectDeviceForDelete?.length === 0}
                            onClick={() => {
                                setDeleteDeviceShow(true);
                            }}
                            id='btn_dp_delete'
                        >
                            <i class='fa fa-trash-o' aria-hidden='true'></i>
                            {t('delete')}
                        </button>
                    )}
                    <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                </div>
            </div>

            <SearchSimple
                setBodyForExport={setBodyForExport}
                setSearchResults={setSearchResults}
                searchElement={searchElement}
                setSearchElement={setSearchElement}
                optionTable={option}
                page={page}
                rowsPerPage={rowsPerPage}
            />
            {
                <div className='panelTables animated-div px-1' ref={elementRef}>
                    {resultsToShow?.content?.length > 0 ? (
                        <table className='w-100'>
                            <thead className='no-border-thead'>
                                {permission?.includes(permissionObj?.WEB_DEVICE_DELETE) && (
                                    <th className='first_head'>
                                        <Tooltip
                                            title={t('de_/_select_all').toUpperCase()}
                                            placement='top'
                                            disableInteractive
                                        >
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={isAllChecked}
                                                onChange={handelDeleteAll}
                                                size='small'
                                                id='cb_dp_checkbox'
                                            />
                                        </Tooltip>
                                    </th>
                                )}

                                <th className='first_head'>{t('name')}</th>

                                <th>{t('ip')}</th>
                                <th>{t('mac')}</th>
                                <th>{t('s/n')}</th>
                                <th dangerouslySetInnerHTML={{ __html: t('device_type_table') }} />
                                <th dangerouslySetInnerHTML={{ __html: t('access_type_table') }} />
                                <th>{t('status')}</th>
                                <th style={{ minWidth: 160 }}>{t('zone')}</th>
                                {permission?.includes(permissionObj?.WEB_DEVICE_UPDATE) && (
                                    <th className='last'>{t('update')}</th>
                                )}
                                <th>{t('qr')}</th>
                                <th className='last'>{t('log')}</th>
                            </thead>
                            {resultsToShow?.content?.length > 0 &&
                                resultsToShow?.content?.map((item) => {
                                    return (
                                        <tr key={item?.id}>
                                            {permission?.includes(permissionObj?.WEB_DEVICE_DELETE) && (
                                                <td
                                                    className='first'
                                                    style={{ verticalAlign: 'middle', textAlig: 'center' }}
                                                >
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={selectDeviceForDelete?.includes(item?.id)}
                                                        id={item?.id}
                                                        onChange={handleCheckboxChange}
                                                        size='small'
                                                    />
                                                </td>
                                            )}
                                            <td className='first'>{item?.name}</td>
                                            <td>{item?.ip}</td>
                                            <td>{item?.mac}</td>
                                            <td>{item?.serialNumber}</td>
                                            <td className={'img_device_table'}>
                                                {
                                                    <>
                                                        <Tooltip
                                                            title={deviceTypeId(item?.deviceTypeId)}
                                                            placement='right'
                                                            arrow
                                                            disableInteractive
                                                        >
                                                            <img
                                                                src={item?.deviceTypeId === 2 ? mobile_app : fixed_app}
                                                                alt=''
                                                            />
                                                        </Tooltip>
                                                    </>
                                                }
                                            </td>
                                            <td className={'img_arrow_table'}>
                                                {
                                                    <>
                                                        <Tooltip
                                                            title={
                                                                (item?.deviceAccessTypeId === 3 &&
                                                                    t('entry_exit').toUpperCase()) ||
                                                                (item?.deviceAccessTypeId === 2 &&
                                                                    t('exit').toUpperCase()) ||
                                                                (item?.deviceAccessTypeId === 1 &&
                                                                    t('entry').toUpperCase())
                                                            }
                                                            placement='right'
                                                            arrow
                                                            disableInteractive
                                                        >
                                                            <img
                                                                src={
                                                                    (item?.deviceAccessTypeId === 3 && both_ic) ||
                                                                    (item?.deviceAccessTypeId === 2 && exit_ic) ||
                                                                    (item?.deviceAccessTypeId === 1 && entry_ic)
                                                                }
                                                                alt=''
                                                            />
                                                        </Tooltip>
                                                    </>
                                                }
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: 'bold',
                                                    font: 'normal normal bold 12px/15px Montserrat',
                                                    color: item?.statusId === 11 ? colors.green : colors.red,
                                                }}
                                            >
                                                {item?.statusId === 11
                                                    ? t('active').toUpperCase()
                                                    : t('inactive').toUpperCase()}
                                            </td>
                                            <td>{item?.zoneName ? item.zoneName : '-'} </td>
                                            {permission?.includes(permissionObj?.WEB_DEVICE_UPDATE) && (
                                                <td className=' pad-r'>
                                                    <button
                                                        className='btn-option no-border no-background pad-r'
                                                        onClick={() =>
                                                            navigate(`/dashboard/employee/devices/update/${item?.id}`)
                                                        }
                                                    >
                                                        <i
                                                            className='fa fa-pencil'
                                                            aria-hidden='true'
                                                            style={{ color: colors.dark }}
                                                        ></i>
                                                    </button>
                                                </td>
                                            )}
                                            <td>
                                                <button
                                                    className='btn-option no-border no-background qr'
                                                    onClick={() => {
                                                        dispatch(GetQrCode(item?.id)).then(
                                                            ({
                                                                payload: {
                                                                    data: { data, success },
                                                                },
                                                            }) => {
                                                                if (success === true) {
                                                                    const jsonData = JSON.stringify(data);

                                                                    setQrName(item?.name.replaceAll(' ', '_'));
                                                                    setQrData(jsonData);
                                                                }
                                                            },
                                                        );
                                                    }}
                                                >
                                                    <QrImage
                                                        fill={colors.dark}
                                                        width={'18px'}
                                                        height={'18px'}
                                                        paddingRight={'10px'}
                                                    />
                                                </button>
                                            </td>
                                            <td className='tableIcon'>
                                                <button
                                                    className='btn-option'
                                                    onClick={() => {
                                                        const data = {
                                                            id: item?.id,
                                                            name: item?.name,
                                                        };
                                                        setLogDevice(data);
                                                        setShowLogModal(true);
                                                        setLogFlag(true);
                                                    }}
                                                >
                                                    <ListDetail fill={colors.dark} width={'18px'} height={'16px'} />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                    // }
                                })}
                        </table>
                    ) : (
                        <NotInformation text={t('no_information')} card={true} />
                    )}
                </div>
            }
            <DeleteDeviceModal
                show={showDeleteDeviceModal}
                onHide={() => setShowDeleteDeviceModal(false)}
                data={deleteSingleDevice}
                flag={deletedFlag}
                onDelete={() => setDeletedFlag(true)}
            />

            <div className='d-none'>
                <QRCode id='qr-gen' value={qrData} size={290} level={'H'} includeMargin={true} />
            </div>

            <DeviceLogModal
                show={showLogModal}
                onHide={() => {
                    setShowLogModal(false);
                    setLogFlag(false);
                }}
                data={logDevice}
                flag={logFlag}
                onLog={() => setLogFlag(false)}
            />
            <DeleteModal
                show={deleteDeviceShow}
                onHide={() => setDeleteDeviceShow(false)}
                onClickFn={() => deleteSelectedDevices(selectDeviceForDelete)}
                data={selectDeviceForDelete}
                title_modal={t('delete_devices')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('devices')}
            />
            <SearchFor
                open={filterDialogShow}
                onClose={() => {
                    setFilterDialogShow(false);
                }}
                onFiltered={(originalArray) => {
                    setFilterDialogShow(false);
                    setFinalArray(originalArray);
                }}
                moduleId={moduleId}
                option={option}
                finalArray={finalArray}
            />
            {resultsToShow?.content?.length > 0 && (
                <div className='d-flex justify-content-center'>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[20, 40, 60]}
                        count={resultsToShow?.totalElements}
                        page={page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage={t('devices_per_page')}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}
        </>
    );
};

export default DevicesPanel;
