import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import file from '../../../assets/images/file.png';
import DownloadIcon from '@mui/icons-material/Download';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    DetailsEmployeeProviderEmployee,
    DownloadEmployeeProviderOrderFiles,
} from '../../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
// import ApproveDenyModal from "./ProviderModels/ApproveDenyModal";
// import ProviderDropDown from "./SubComponents/providerDropDown";

import {
    DetailsEmployeeContractorEmployee,
    GetAllContractorDocuments,
    GetEmployeeContractorById,
} from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import DocumentTable from '../../Modals/DocumentTable';
import ic_left_arrow from '../../../assets/images/ic_left_arrow.svg';
import Cookies from 'js-cookie';
import personPng from '../../../assets/defaultImages/userDef.svg';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import NotInformation from '../../../components/NotInformation';

const ProviderOrderDetail = ({ employeeDetailsFlag, approveDocumentFlag }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const goBack = () => navigate(-1);
    const lCode = Cookies.get('i18next') || 'en';

    const [filePresent, setfilePresent] = useState(true);
    const [fileIdPresent, setfileIdPresent] = useState(true);
    const [employeeDetails, setEmployeeDetails] = useState(false);
    const [approveDocument, setapproveDocument] = useState(false);
    const [updated, setUpdated] = useState(true);

    const { getEmployeeContractorById, detailsEmployeeContractorEmployee, getAllContractorDocuments } = useSelector(
        (state) => state.EmployeeContractorsSlice,
    );
    const [showModal, setShowModal] = useState(false);

    const onHide = () => {
        setShowModal(false);
    };

    const onShow = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (updated) {
            setEmployeeDetails(employeeDetailsFlag);
            setapproveDocument(approveDocumentFlag);
            const data = {
                id: params?.id,
            };
            dispatch(GetAllContractorDocuments(sessionStorage.getItem('userId')));
            dispatch(GetEmployeeContractorById(data));
            dispatch(DetailsEmployeeContractorEmployee(params?.id));
            setUpdated(false);
        }
    }, [updated]);

    return (
        <>
            {/* <div className='details-header'>
        <div className='details-header-left'>

          <Link className='top-left-arrow-container' to="#"
          >
            <button className='btn-left-arrow' onClick={() => goBack()}
            >
              <img className="mx-0" src={ic_left_arrow} alt="ic_left_arrow" style={{
                transform: lCode === "ar" ? "scaleX(-1)" : "",
                margin: "0 10px",
              }} />
            </button>
          </Link>
          <h2>

            {employeeDetails && t("employee_supplier_detail")}
            {approveDocument && "APPROVE DOCUMENTS"}
          </h2>
        </div>
      </div> */}
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={'/dashboard/employee/contractors'} />
                    <h2>
                        {employeeDetails && t('employee_supplier_detail')}
                        {approveDocument && t('approve_documents')}
                    </h2>
                </div>
            </div>
            <div className='row employee_provider_detail' style={{ marginTop: '10px' }}>
                <div className='col-md-4 __userData'>
                    <img
                        src={
                            getEmployeeContractorById?.selfie
                                ? `data:image/png;base64,${getEmployeeContractorById?.selfie}`
                                : personPng
                        }
                        className='__userImage'
                    />
                    <div className='__body'>
                        <p>{t('name')}</p>
                        <span>
                            {(employeeDetails && detailsEmployeeContractorEmployee?.user?.name) ||
                                (approveDocument && getEmployeeContractorById?.user?.name)}
                        </span>
                        <p className='ishead'>{t('email')}</p>
                        <span>
                            {' '}
                            {(employeeDetails && detailsEmployeeContractorEmployee?.user?.email) ||
                                (approveDocument && getEmployeeContractorById?.user?.email)}
                        </span>
                        <p className='ishead'>{t('phone_number')}</p>
                        <span>
                            {(employeeDetails && detailsEmployeeContractorEmployee?.user?.phoneNumber) ||
                                (approveDocument && getEmployeeContractorById?.user?.phoneNumber)}
                        </span>
                        <p className='ishead'>{t('password')}</p>
                        <span>**************</span>
                        <p className='ishead'>{t('gender')}</p>
                        <span>
                            {(employeeDetails && detailsEmployeeContractorEmployee?.user?.gender?.name) ||
                                (approveDocument && getEmployeeContractorById?.user?.gender?.name)}
                        </span>
                    </div>
                </div>
                <div className='col-md-7 employee_files_details'>
                    {!approveDocument && detailsEmployeeContractorEmployee?.documents?.length > 0 ? (
                        <>
                            <DocumentTable
                                dataTable={detailsEmployeeContractorEmployee?.documents}
                                approve={false}
                                optionDownload='document_external'
                            />
                        </>
                    ) : (
                        !approveDocument && (
                            <div className='no_document'>
                                <i class='fa fa-file' aria-hidden='true'></i>
                                <p>{t('no_data')}</p>
                            </div>
                        )
                    )}

                    {!employeeDetails && getAllContractorDocuments?.length > 0 ? (
                        <>
                            <DocumentTable
                                dataTable={getAllContractorDocuments}
                                approve={true}
                                optionDownload='document_external'
                                updated={updated}
                                setUpdated={setUpdated}
                            />
                            {/* <ApproveDenyModal show={showModal} onHide={() => setShowModal(false)} documentId={item?.id} /> */}
                        </>
                    ) : (
                        !employeeDetails && <NotInformation text={t('no_information')} card={true} />
                    )}
                </div>
            </div>
        </>
    );
};

export default ProviderOrderDetail;
