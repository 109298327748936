import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Checkbox,
    FormControlLabel,
    FormHelperText,
} from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import {
    CreateEmployeeProviderOrders,
    GetEmployeeProviderLists,
} from '../../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import useStyle from '../../../hooks/useStyle';
import dateInputLanguage from '../../../hooks/dateInputLanguage';
import { CreateSupplierOrder } from '../../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { Order } from '../../../models/Order';

export const CreateOrder = () => {
    const lCode = Cookies.get('i18next') || 'en';

    const dispatch = useDispatch();
    let navigate = useNavigate();

    // import useStyle()
    const { textField, smallBoxStyle, textFieldCommon } = useStyle();

    // import slice
    const { getEmployeeProviderLists } = useSelector((state) => state.EmployeeProviderSlice);
    const [emptyFields, setEmptyFields] = useState(false);
    const [isValidDeliveryDate, setIsValidDeliveryDate] = useState(true);
    const [order, setOrder] = useState(Order);

    // const

    const CreateOrderHandler = () => {
        setEmptyFields(true);
        if (order.supplierId !== '' && order.item !== '' && order.deliveryDate !== null) {
            dispatch(CreateSupplierOrder(order.toCreateUpdate())).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        toast.success(t('created_successfully').toUpperCase());
                        navigate('/dashboard/employee/suppliers');
                    } else {
                        toast.error(t('fail_to_create_order').toUpperCase());
                    }
                },
            );
        } else {
            toast.warn('Please Fill All The Fields');
        }
    };

    const handleIsDeliveryChange = (e) => {
        setOrder((prevData) => ({
            ...prevData,
            isDelivery: !order.isDelivery,
        }));
    };

    const handleDateChange = (selectedDate) => {
        setOrder((prevData) => ({
            ...prevData,
            deliveryDate: selectedDate,
        }));
    };

    useEffect(() => {
        dispatch(GetEmployeeProviderLists());
        sessionStorage.setItem('cId', 'a6bd2887-0f4a-4e5f-b0b5-000d9817ab23');
    }, []);
    const footerStyles = {
        margin: '15px',
    };
    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={'/dashboard/employee/suppliers'} />
                    <h2>{t('create_order')}</h2>
                </div>
            </div>
            <div className='mt-5 row order_data_component'>
                <div className='formCard'>
                    <div className='col-md-11'>
                        <p className='__header'>{t('order_data')}</p>
                        <div className='__body'>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <Box className='inputField required-data'>
                                        <FormControl
                                            fullWidth
                                            required
                                            sx={textFieldCommon}
                                            error={emptyFields && order.supplierId === ''}
                                        >
                                            <InputLabel id='demo-simple-select-label'>
                                                {t('choose_a_supplier')}
                                            </InputLabel>
                                            <Select
                                                size='small'
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                label={t('choose_a_supplier')}
                                                value={order.supplierId}
                                                onChange={(e) =>
                                                    setOrder((prevData) => ({
                                                        ...prevData,
                                                        supplierId: e.target.value,
                                                    }))
                                                }
                                            >
                                                {getEmployeeProviderLists?.map((item, index) => {
                                                    return <MenuItem value={item.id}>{item.acronym}</MenuItem>;
                                                })}
                                            </Select>
                                            {emptyFields && order.supplierId === '' && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className='col-md-6'>
                                    <TextField
                                        size='small'
                                        className='inputField'
                                        fullWidth
                                        sx={textFieldCommon}
                                        label={t('folio')}
                                        value={order.folio}
                                        inputProps={{ maxLength: 128 }}
                                        onChange={(e) =>
                                            setOrder((prevData) => ({
                                                ...prevData,
                                                folio: e.target.value,
                                            }))
                                        }
                                        id='folio'
                                    />
                                </div>
                            </div>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <Box className='inputField required-data'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={dateInputLanguage(lCode)}
                                        >
                                            <Stack spacing={3}>
                                                <DatePicker
                                                    disablePast
                                                    allowSameDateSelection
                                                    label={t('delivery_date')}
                                                    inputFormat={lCode === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                                                    value={order.deliveryDate}
                                                    onChange={handleDateChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name='startDate'
                                                            size='small'
                                                            sx={textFieldCommon}
                                                            fullWidth
                                                            required
                                                            {...params}
                                                            helperText={
                                                                emptyFields && order.deliveryDate === null
                                                                    ? t('please_select_date')
                                                                    : !isValidDeliveryDate
                                                                      ? t('date_invalid')
                                                                      : ''
                                                            }
                                                            error={
                                                                (emptyFields && order.deliveryDate === null) ||
                                                                !isValidDeliveryDate
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Box>
                                </div>
                                <div className='col-md-6'>
                                    <div className='checkbox-common col-md-6'>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={order.isDelivery}
                                                    onChange={handleIsDeliveryChange}
                                                />
                                            }
                                            label={t('is_delivery')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-1 col-md-12'>
                                <TextField
                                    size='small'
                                    error={emptyFields && order.item === ''}
                                    className='inputField required-data'
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 128 }}
                                    sx={textFieldCommon}
                                    label={t('item')}
                                    value={order.item}
                                    onChange={(e) =>
                                        setOrder((prevData) => ({
                                            ...prevData,
                                            item: e.target.value,
                                        }))
                                    }
                                    id='Item'
                                    helperText={emptyFields && order.item === '' ? t('requiredField') : ''}
                                />
                            </div>
                            <div className='col-md-12'>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    sx={textFieldCommon}
                                    id='outlined-multiline-static'
                                    label={t('description')}
                                    inputProps={{ maxLength: 512 }}
                                    multiline
                                    rows={4}
                                    value={order.description}
                                    onChange={(e) =>
                                        setOrder((prevData) => ({
                                            ...prevData,
                                            description: e.target.value,
                                        }))
                                    }
                                    style={{ color: '#707070' }}
                                />
                            </div>

                            <div className='footer' style={{ margin: '15px !important' }}>
                                <button className='cancel'>{t('cancel').toUpperCase()}</button>
                                <button
                                    onClick={() => {
                                        CreateOrderHandler();
                                    }}
                                >
                                    {t('create').toUpperCase()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
