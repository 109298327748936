import axios from 'axios';
import { URL } from './Constants';
import { toast } from 'react-toastify';
import cryptoJs from 'crypto-js';
import securekey from '../config';
import i18n, { t } from 'i18next';
import { GetUserFullInformation } from '../reduxToolkit/Commons/CommonsApi';
import store from '../reduxToolkit/store';

const waitFori18nInit = () =>
    new Promise((resolve) => {
        if (i18n.isInitialized) {
            resolve();
        } else {
            i18n.on('initialized', () => resolve());
        }
    });

// Keep the offline flag state in the same file
let offlineFlag = false;

// Defines the toast handling function
const handleOfflineToast = async () => {
    await waitFori18nInit();
    if (!offlineFlag) {
        offlineFlag = true;
        toast.info(t('no_internet_conection_has_been_established'), {
            onClose: () => {
                offlineFlag = false;
            },
        });
    }
};

export const apiInstanceV2 = axios.create({
    // baseURL: 'http://38.65.139.14:8081/corporate-user-pre-prod-v2/',
    baseURL: URL,
    // timeout: 10000,
    headers: {
        Accept: 'application/json',
        // "Access-Control-Allow-Origin":"*"

        // "application": 'web',
        // "locale": Cookies.get("i18next") || "en"
    },
});

const responseSuccessHandler = (response) => {
    if (response.config.url === 'image-service/upload' || response.config.url === 'user-service/user-image/create') {
        store.dispatch(GetUserFullInformation(sessionStorage.getItem('id')));
    }

    document.getElementById('overlay').style.display = 'none';
    return response;
};

const responseErrorHandler = (error) => {
    switch (error?.response?.data?.code) {
        case 1010:
            toast.error(t('employee_id_already_registered_please_use_another'));
            break;
        case 1020:
            toast.error(t('email_already_registered_please_use_another'));
            break;
        case 1021:
            toast.error(t('phone_number_already_registered_please_use_another'));
            break;
        default:
            break;
    }
    if (document.getElementById('overlay')) {
        document.getElementById('overlay').style.display = 'none';
    }
    if (document.getElementById('second-overlay')) {
        document.getElementById('second-overlay').style.display = 'none';
    }
    if (!navigator.onLine) {
        handleOfflineToast();
    }

    return Promise.reject(error);
};

apiInstanceV2.interceptors.request.use(
    function (config) {
        document.getElementById('overlay').style.display = 'block';
        const token = sessionStorage.getItem('bearerToken');
        const bytes = cryptoJs.AES.decrypt(token, securekey);
        const tokenDecrpt = bytes.toString(cryptoJs.enc.Utf8);
        config.headers.Authorization = `Bearer ${tokenDecrpt}`;

        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

apiInstanceV2.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error),
);

export default apiInstanceV2;
