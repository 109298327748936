/*
Author : Arman Ali
Module: create Vehicle
github: https://github.com/Arman-Arzoo
*/
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, FormHelperText } from '@mui/material';
import useStyle from '../hooks/useStyle';
import { useTranslation } from 'react-i18next';

const SelectWithSearch = ({ options, label, onSelect, update, checkUpdate, emptyFields }) => {
    // option : is the api response
    // label : is the lable of select
    // onSelect : return data to parent

    const { t } = useTranslation();

    const [value, setValue] = useState(null);
    const { textField, textFieldCommon } = useStyle();

    //   a funtion to set value of on change field...
    const handleSelect = (event, newValue) => {
        setValue(newValue);
        onSelect(newValue);
    };

    // // handel the logic for object transformation for all user
    // const userDataTransformUser = (tras) => {

    //   const newData = tras?.map(item => {
    //     return {
    //       label: `${(item?.name || '')} ${(item?.lastName || '')} ${(item?.secondLastName || '')}`.trim(),
    //       id: item?.id || item?.userId
    //     }
    //   })
    //   return newData;
    // }

    useEffect(() => {
        if (checkUpdate === 'update') {
            setValue(`${update?.name || ''} ${update?.lastName || ''} ${update?.secondLastName || ''}`.trim());
            setValue('casa');
        }
    }, []);
    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label}
            onChange={handleSelect}
            sx={textFieldCommon}
            value={value}
            renderInput={(params) => (
                <TextField
                    error={emptyFields && (value === '' || value === null)}
                    {...params}
                    label={label}
                    variant='outlined'
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'disabled',
                    }}
                    helperText={emptyFields && (value === '' || value === null) ? t('selectOptionText') : ''}
                />
            )}
        />
    );
};

export default SelectWithSearch;
