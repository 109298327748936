import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';
import { UploadImage } from '../ShareSlice/shareApi';
import { UploadProviderImage, UploadProviderVehicleImage } from '../Providers/providersApi';
import { SaveProviderImage } from '../Providers/providersApi';
import { getSelfie } from '../CompanyEmployees/CompanyEmployeesApi';

//Delete Items /assets-service/delete-by-ids/by-table/${table} assets-module assets-controller
export const DeleteItemsApi = createAsyncThunk(
    'commons/deleteItemsApi',
    async ({ tableName, body }, { dispatch, getState }) => {
        let result = await apiInstance
            .post(`assets-service/delete-by-ids/by-table/${tableName}`, body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Get Media /assets-service/media/get-all assets-module assets-controller
export const GetAllMedia = createAsyncThunk('commons/getAllMedia', async (params, { dispatch, getState }) => {
    const result = await apiInstance
        .get('assets-service/media/get-all')
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});

//Get User Full Information /user-service/v1/app/full-information/get-by-id/{id} user-module user-controller
export const GetUserFullInformation = createAsyncThunk(
    'commons/getUserFullInformation',
    async (id, { dispatch, getState }) => {
        let result = await apiInstance
            .get(`user-service/v1/app/full-information/get-by-id/${id}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

//Update User Profile /user-service/v1/app/update user-module user-controller
export const UpdateUserProfile = createAsyncThunk(
    'commons/updateUserProfile',
    async (params, { dispatch, getState }) => {
        let result = await apiInstance
            .put(`user-service/v1/app/update`, params?.data)
            .then(function (response) {
                const imgData = {
                    user: {
                        id: response?.data?.data?.id,
                    },
                    accessMethod: {
                        id: '5',
                    },
                    description: 'Face recognition',
                };
                // want to update or create image
                if (params?.updateImage) {
                    if (params?.file != '') {
                        dispatch(getSelfie(response?.data?.data?.id)).then(
                            ({
                                payload: {
                                    data: { data },
                                },
                            }) => {
                                dispatch(SaveProviderImage({ id: data?.id, file: params?.file }));
                            },
                        );
                    }
                } else {
                    if (params?.file != '') {
                        dispatch(UploadProviderImage({ imgData, file: params?.file }));
                    }
                }
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

//WebSocket Guard /websocket/guard
export const WebSocketGuard = createAsyncThunk('commons/webSocketGuard', async (params, { dispatch, getState }) => {
    let result = await apiInstance
        .post(`websocket/guard`, params, {
            headers: {
                Accept: '*/*',
            },
        })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});
