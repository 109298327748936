import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const GraphsSlice = createSlice({
    name: 'graph',
    initialState: {
        getGraphByUserId: {},
        getTemplates: {},
        getAllChartsAvailable: {},
        getChartsAvailableByDashboardId: {},
        addCharts: {},
        updateDashboardName: {},
        deleteItemChart: {},
        createDashboard: {},
    },
    reducers: {},
    extraReducers: {
        'graph/getGraphByUserId/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getGraphByUserId = data;
            } else if (status >= 400 && status < 500) {
                toast('Fail to fetch data');
            }
        },
        'graph/getTemplates/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getTemplates = data;
            } else if (status >= 400 && status < 500) {
                toast('Fail to fetch data');
            }
        },
        'graph/getAllChartsAvailable/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getAllChartsAvailable = data;
            } else if (status >= 400 && status < 500) {
                toast('Fail to fetch data');
            }
        },
        'graph/getChartsAvailableByDashboardId/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getChartsAvailableByDashboardId = data;
            } else if (status >= 400 && status < 500) {
                toast('Fail to fetch data');
            }
        },
        'graph/addCharts/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.addCharts = data;
            } else if (status >= 400 && status < 500) {
                toast('Fail to fetch data');
            }
        },
        'graph/updateDashboardName/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.updateDashboardName = data;
            } else if (status >= 400 && status < 500) {
                toast('Fail to fetch data');
            }
        },
        'graph/deleteItemChart/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.deleteItemChart = data;
            } else if (status >= 400 && status < 500) {
                toast('Fail to fetch data');
            }
        },
        'graph/createDashboard/fulfilled': (state, action) => {
            const {
                data: { data },
                status,
            } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createDashboard = data;
            } else if (status >= 400 && status < 500) {
                toast('Fail to fetch data');
            }
        },
    },
});

export const {} = GraphsSlice.actions;

export default GraphsSlice.reducer;
