import axios from 'axios';
import { URL, VURL } from './Constants';
import { pluginURL } from './Constants';
import { toast } from 'react-toastify';
import cryptoJs from 'crypto-js';
import securekey from '../config';
import i18n, { t } from 'i18next';
import { GetUserFullInformation } from '../reduxToolkit/Commons/CommonsApi';
import store from '../reduxToolkit/store';

const waitFori18nInit = () =>
    new Promise((resolve) => {
        if (i18n.isInitialized) {
            resolve();
        } else {
            i18n.on('initialized', () => resolve());
        }
    });

// Keep the state of the offline flag in the same file
let offlineFlag = false;

// Define la función de manejo de toasts
const handleOfflineToast = async () => {
    await waitFori18nInit();
    if (!offlineFlag) {
        offlineFlag = true;
        toast.info(t('no_internet_conection_has_been_established'), {
            onClose: () => {
                offlineFlag = false;
            },
        });
    }
};

// axios.defaults.withCredentials = true

export const apiInstance = axios.create({
    baseURL: URL,
    headers: {
        Accept: 'application/json',
    },
});
export const apiInstanceID = axios.create({
    baseURL: URL,
    headers: {
        Accept: '*/*',
    },
});

export const apiInstanceValidas = axios.create({
    baseURL: VURL,
    headers: {
        Accept: 'application/json',
    },
});
export const apiInstanceUpdate = axios.create({
    baseURL: URL,
    headers: {
        Accept: 'application/json',
    },
});

export const pluginInstance = axios.create({
    baseURL: pluginURL,
    headers: {
        Accept: 'application/json',
    },
});

const responseSuccessHandler = (response) => {
    if (response.config.url === 'image-service/upload' || response.config.url === 'user-service/user-image/create') {
        store.dispatch(GetUserFullInformation(sessionStorage.getItem('id')));
    }

    document.getElementById('overlay').style.display = 'none';
    return response;
};

const responseErrorHandler = (error) => {
    switch (error?.response?.data?.code) {
        case 1010:
            toast.error(t('employee_id_already_registered_please_use_another'));
            break;
        case 1020:
            toast.error(t('email_already_registered_please_use_another'));
            break;
        case 1021:
            toast.error(t('phone_number_already_registered_please_use_another'));
            break;
        default:
            break;
    }
    if (document.getElementById('overlay')) {
        document.getElementById('overlay').style.display = 'none';
    }
    if (document.getElementById('second-overlay')) {
        document.getElementById('second-overlay').style.display = 'none';
    }
    if (!navigator.onLine) {
        window.postMessage(
            {
                type: 'sessionStorageChangeLoader',
                newValueLoading: false,
            },
            '*',
        );
        handleOfflineToast();
    }
    return Promise.reject(error);
};

apiInstance.interceptors.request.use(
    function (config) {
        document.getElementById('overlay').style.display = 'block';
        const token = sessionStorage.getItem('bearerToken');
        const bytes = cryptoJs.AES.decrypt(token, securekey);
        const tokenDecrpt = bytes.toString(cryptoJs.enc.Utf8);
        config.headers.Authorization = `Bearer ${tokenDecrpt}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

apiInstance.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error),
);
apiInstanceID.interceptors.request.use(
    function (config) {
        document.getElementById('overlay').style.display = 'block';
        const token = sessionStorage.getItem('bearerToken');
        const bytes = cryptoJs.AES.decrypt(token, securekey);
        const tokenDecrpt = bytes.toString(cryptoJs.enc.Utf8);
        config.headers.Authorization = `Bearer ${tokenDecrpt}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default apiInstance;
