import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import deleteIcon from '../../../../../assets/images/ic-delete-red.svg';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import {
    updateAllEmployees,
    updateSelectedEmployees,
} from '../../../../../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import {
    CreateUserZoneList,
    DeleteZoneUser,
    ZoneDetailAuthorizedEmployeeNoPagination,
} from '../../../../../reduxToolkit/EmployeeZones/EmployeeZonesApi';
// import { updateAllEmployees, updateSelectedEmployees } from '../../../../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Box, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutoCompleteSearch from '../../../../../components/AutoCompleteSearch';
import { addUsersToRole, removeRoleToUserByIds } from '../../../../../reduxToolkit/EmployeeRoles/EmployeeRolesApi';
import { getAllroleEmployeesPageable } from '../../../../../Apis/roles';

const ManageRoleModal = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const { employeesWithRoleList, allEmployeesList } = useSelector((state) => state?.EmployeeRolesSlice);
    const [query, setQuery] = useState('');
    const [addUserquery, setAddUserQuery] = useState('');
    const [employeesWithRole, setEmployeesWithRole] = useState([]);
    const [totalEmployees, setTotalEmployees] = useState([]);

    const handleDelete = (dId) => {
        const data = {
            userIds: [dId], //props?.deleteData,
            roleId: props.roleid,
        };
        dispatch(removeRoleToUserByIds(data));
        props.onHide();
        setQuery('');
    };
    const handleRemoveSelected = (user) => {
        setTotalEmployees(totalEmployees.filter((item) => item?.id !== user.id));
    };

    //  add user to role
    const handleAddUser = () => {
        const all_user = totalEmployees?.map((item) => item?.id);
        if (all_user?.length > 0) {
            const data = {
                userIds: all_user,
                roleId: props.roleid,
            };
            dispatch(addUsersToRole(data));
            props.onHide();
            setTotalEmployees([]);
            setAddUserQuery('');
        } else {
            toast.warn('Please atlest select one user');
        }
    };
    // select for add user
    const handleselected = (user) => {
        const checkUser = employeesWithRoleList?.find((item) => {
            return item?.userId === user?.id;
        });

        if (checkUser) {
            toast.warn(`${checkUser?.name} ${t('is_already_added').toUpperCase()}`);
        } else {
            const selectedUser = totalEmployees?.find((item) => item?.id === user?.id);

            if (selectedUser) {
                toast.warn(`${selectedUser?.label} ${t('is_already_selected').toUpperCase()}`);
                setAddUserQuery('');
            } else {
                setAddUserQuery('');
                setTotalEmployees([...totalEmployees, user]);
            }
        }
    };

    // handel the logic for object transformation for all user
    const userDataTransform = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label:
                    (item?.name || '') +
                    ' ' +
                    (item?.firstLastName || '') +
                    (item?.secondLastName ? ' ' + item.secondLastName : ''),
                id: item?.userId,
            };
        });
        return newData;
    };

    useEffect(() => {
        if (props.show === false) {
            setAddUserQuery('');
        }
    }, [props.show]);

    return (
        <Modal className='manage-role-panel-modal' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header className='header_zone'>
                <Modal.Title className='header_role'>
                    <h4
                        style={{
                            font: 'normal normal bold 20px / 24px Montserrat',
                            letterSpacing: '7px',
                            color: '#146f62',
                            opacity: 1,
                            marginTop: '1rem',
                            paddingTop: '1rem',
                        }}
                    >
                        {t('manage_access')}
                    </h4>
                </Modal.Title>

                <img
                    onClick={() => {
                        props.onHide();
                        setTotalEmployees([]);
                    }}
                    src={cancel}
                    alt='cancel'
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '15px',
                        cursor: 'pointer',
                        paddingTop: '1rem',
                    }}
                />
            </Modal.Header>
            <Modal.Body className='manage_role_modal_body'>
                <div className='row shiftmanagement_modal'>
                    <div className='text_field'>
                        <div className='mt-3 title' style={{ color: '#146F62' }}>
                            {t('add_user')}
                        </div>
                        <Box
                            className='mt-2 mb-2'
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                fontSize: '20px',

                                background: '#FCFCFC 0% 0% no-repeat padding-box',
                            }}
                        >
                            <AutoCompleteSearch
                                label={t('search')}
                                data={userDataTransform(allEmployeesList)}
                                handleFn={handleselected}
                                value={addUserquery}
                                setValue={setAddUserQuery}
                            />
                        </Box>
                        <div className='main_content'>
                            <div className=' mb-2 user_text'>
                                {totalEmployees.map((item) => (
                                    <div
                                        className='d-flex justify-content-between pr-2 pt-2'
                                        style={{ marginTop: '4px' }}
                                    >
                                        <div className='badge_container'>
                                            <div className='c_badge'></div>
                                            <p>{item?.label}</p>
                                        </div>

                                        <img
                                            className='delete-icon-style'
                                            src={deleteIcon}
                                            style={{ color: 'red', cursor: 'pointer' }}
                                            onClick={() => handleRemoveSelected(item)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p className='title '> {t('remove_user')}</p>
                        <Box
                            className='mt-2 mb-2'
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                fontSize: '20px',
                                height: '40px',
                                background: '#FCFCFC 0% 0% no-repeat padding-box',
                            }}
                        >
                            <TextField
                                size='small'
                                fullWidth
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                label={t('search')}
                                id='Search'
                                className=''
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        {/* {
                AllByWorkShiftId?.length > 0 && */}
                        <div className='main_content'>
                            <div className='body'>
                                {employeesWithRoleList &&
                                    employeesWithRoleList
                                        ?.filter((user) => {
                                            if (query === '') {
                                                return user;
                                            } else if (user.name.toLowerCase().includes(query.toLowerCase())) {
                                                return user;
                                            }
                                        })
                                        .map((item) => {
                                            return (
                                                <div
                                                    className='d-flex justify-content-between pr-2'
                                                    style={{ marginTop: '4px' }}
                                                >
                                                    <div className='badge_container'>
                                                        <div className='c_badge'></div>
                                                        <p>
                                                            {item?.name} {item?.firstLastName}{' '}
                                                            {item?.secondLastName ? ' ' + item?.secondLastName : ''}
                                                        </p>
                                                    </div>

                                                    <img
                                                        className='delete-icon-style'
                                                        src={deleteIcon}
                                                        style={{ color: 'red', cursor: 'pointer' }}
                                                        onClick={() => handleDelete(item.userId)}
                                                    />
                                                </div>
                                            );
                                        })}
                            </div>
                        </div>
                    </div>
                    <div className='btn-div'>
                        <button
                            className='btn_cancel_background_gray_hover'
                            style={{ width: '100%' }}
                            onClick={() => {
                                props.onHide();
                                setTotalEmployees([]);
                            }}
                        >
                            {t('cancel')}
                        </button>
                        <button className='custom_primary_btn_dark' style={{ width: '100%' }} onClick={handleAddUser}>
                            {t('apply_changes')?.toUpperCase()}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ManageRoleModal;
