import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import { Box, InputAdornment, TextField } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import searchIcon from '../../../../assets/images/ic-search.svg';
import userregular from '../../../../assets/images/user-regular.svg';
import {
    preRegisterUser,
    searchByEmail,
    searchByPhone,
} from '../../../../reduxToolkit/EmployeeEvents/EmployeeEventsApi';
import { SaveEmailPhoneSearchList, setReadOnly } from '../../../../reduxToolkit/EmployeeEvents/EmployeeEventsSlice';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ManageOthersModal = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    const emailPhoneSearchData = useSelector((state) => state?.EmployeeEventsSlice?.emailPhoneSearchData);
    const readOnly = useSelector((state) => state?.EmployeeEventsSlice?.readOnly);
    const [errorEmail, setErrorEmail] = useState('');
    const [errorNumber, setErrorNumber] = useState('');
    const [checked, setChecked] = useState(true);
    const [cellular, setCellular] = useState();
    const [email, setEmail] = useState();
    const [preRegisterObj, setPreRegisterObj] = useState({
        name: '',
        lastName: '',
        secondLastName: '',
        email: '',
        phoneNumber: '',
    });

    useEffect(() => {
        if (props.show === false) {
            setPreRegisterObj({
                name: '',
                lastName: '',
                secondLastName: '',
                email: '',
                phoneNumber: '',
            });
            setEmail('');
            setCellular('');
            dispatch(setReadOnly(true));
        }
        if (readOnly === false) {
            setPreRegisterObj({
                name: '',
                lastName: '',
                secondLastName: '',
                email: '',
                phoneNumber: '',
            });
            setEmail('');
            setCellular('');
        }
        if (readOnly) {
            setErrorEmail('');
            setErrorNumber('');
        }
    }, [props.show, readOnly]);

    const handleSwitch = (checked) => {
        setChecked(checked);
        setCellular('');
        setEmail('');
        setPreRegisterObj({
            name: '',
            lastName: '',
            secondLastName: '',
            email: '',
            phoneNumber: '',
        });
    };

    const handleSearch = () => {
        if (checked) {
            dispatch(searchByPhone(cellular)).then((res) => {
                if (res?.payload?.data?.code === 200) {
                    setPreRegisterObj({
                        name: res?.payload?.data?.data?.name,
                        lastName: res?.payload?.data?.data?.lastName,
                        secondLastName: res?.payload?.data?.data?.secondLastName,
                        email: res?.payload?.data?.data?.email,
                        phoneNumber: res?.payload?.data?.data?.phoneNumber,
                    });
                    setErrorNumber(false);
                } else {
                    if (res?.payload?.data?.code === 831) {
                        setErrorNumber(true);
                    } else {
                        setErrorNumber(true);
                    }
                }
            });
        } else {
            dispatch(searchByEmail(email)).then((res) => {
                // if (res?.payload?.data?.code == 831) {
                //     setErrorEmail(res?.payload?.data?.message);
                // } else {
                //     setErrorEmail('');
                // }
                // setPreRegisterObj({
                //     name: res?.payload?.data?.data?.name,
                //     email: res?.payload?.data?.data?.email,
                //     phoneNumber: res?.payload?.data?.data?.phoneNumber,
                // });

                if (res?.payload?.data?.code === 200) {
                    setPreRegisterObj({
                        name: res?.payload?.data?.data?.name,
                        lastName: res?.payload?.data?.data?.lastName,
                        secondLastName: res?.payload?.data?.data?.secondLastName,
                        email: res?.payload?.data?.data?.email,
                        phoneNumber: res?.payload?.data?.data?.phoneNumber,
                    });
                    setErrorEmail(false);
                } else {
                    if (res?.payload?.data?.code === 831) {
                        setErrorEmail(true);
                    } else {
                        setErrorEmail(true);
                    }
                }
            });
        }
    };

    const handleConfirm = () => {
        if (readOnly === true) {
            if (preRegisterObj?.name) {
                dispatch(SaveEmailPhoneSearchList(emailPhoneSearchData));
                props.onHide();
                setErrorNumber('');
                setErrorEmail('');
            } else {
                toast.warn('Please Find user or Field info');
            }
        } else if (readOnly === false) {
            if (
                preRegisterObj?.name &&
                preRegisterObj?.lastName &&
                preRegisterObj.email &&
                preRegisterObj.phoneNumber
            ) {
                dispatch(
                    preRegisterUser({
                        email: preRegisterObj.email,
                        name: preRegisterObj.name,
                        lastName: preRegisterObj.lastName,
                        secondLastName: preRegisterObj.secondLastName,
                        phoneNumber: preRegisterObj.phoneNumber,
                    }),
                ).then((res) => {
                    if (res?.payload?.data?.data === null) {
                        toast.error(res?.payload?.data?.message);
                    } else {
                        dispatch(SaveEmailPhoneSearchList(res?.payload?.data?.data));
                        setErrorNumber('');
                        setErrorEmail('');
                        props.onHide();
                    }
                });
            } else {
                toast.warn('Please Find user or Field info');
            }
        }
    };

    return (
        <Modal
            className='manage-role-panel-modal'
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('find_person')}</Modal.Title>

                <i className='fa fa-times cross' aria-hidden='true' onClick={() => props.onHide()}></i>
            </Modal.Header>
            <Modal.Body className='manage_others_modal_body'>
                <div className='d-flex align-items-center justify-content-between'>
                    <span
                        style={{
                            fontSize: '15px',
                            color: checked ? '#707070' : '#65ABA0',
                            textDecoration: checked ? 'none' : 'underline',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('find_by_email')}
                    </span>
                    <Switch
                        checked={checked}
                        onChange={handleSwitch}
                        onColor='#65ABA0'
                        onHandleColor='#178A7B'
                        handleDiameter={14}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow='0px 1px 2px rgba(0, 0, 0, 0.6)'
                        activeBoxShadow='0px 0px 1px 2px rgba(0, 0, 0, 0.2)'
                        height={11}
                        width={26}
                        className='react-switch'
                        id='material-switch'
                    />
                    <span
                        style={{
                            fontSize: '15px',
                            color: checked ? '#65ABA0' : '#707070',
                            textDecoration: checked ? 'underline' : 'none',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('find_by_phone_number')}
                    </span>
                </div>
                <div className='findPersonDiv'>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            fontSize: '20px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {checked ? (
                            <TextField
                                size='small'
                                fullWidth
                                label='NUMBER'
                                id='CELULAR'
                                value={cellular}
                                onChange={(e) => setCellular(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <PhoneIphoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    textAlign: lCode === 'ar' ? 'right' : 'left',

                                    '& 	.MuiOutlinedInput-notchedOutline': {
                                        textAlign: lCode === 'ar' ? 'right' : 'left',
                                    },
                                    '& 	.MuiInputLabel-root': {
                                        fontSize: 12,
                                        left: lCode === 'ar' ? 'inherit' : '0',
                                        right: lCode === 'ar' ? '1.75rem' : '0',
                                        transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                    },
                                }}
                            />
                        ) : (
                            <TextField
                                size='small'
                                fullWidth
                                label='EMAIL'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                id='EMAIL'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <MailOutlineIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    textAlign: lCode === 'ar' ? 'right' : 'left',

                                    '& 	.MuiOutlinedInput-notchedOutline': {
                                        textAlign: lCode === 'ar' ? 'right' : 'left',
                                    },
                                    '& 	.MuiInputLabel-root': {
                                        fontSize: 12,
                                        left: lCode === 'ar' ? 'inherit' : '0',
                                        right: lCode === 'ar' ? '1.75rem' : '0',
                                        transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                    },
                                }}
                            />
                        )}
                        <div
                            style={{
                                padding: '5px',
                                borderRadius: '5px',
                                backgroundColor: 'rgb(20, 111, 98)',
                                height: '35px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '3rem',
                                marginLeft: '10px',
                                cursor: 'pointer',
                            }}
                            onClick={handleSearch}
                        >
                            <img
                                src={searchIcon}
                                alt='searchIcon'
                                style={{
                                    width: '20px',
                                    height: '20px',
                                }}
                            />
                        </div>
                    </Box>
                </div>
                {errorEmail ||
                    (errorNumber && (
                        <p className='bottomText text-center mt-2'>
                            <span>{t('user_not_found')} </span>, {t('please_pre_register_the_user')}
                        </p>
                    ))}
                <div className='preRegisterUserFields'>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            height: '40px',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <TextField
                            size='small'
                            fullWidth
                            label='NAME'
                            id='NAME'
                            value={preRegisterObj?.name}
                            sx={{
                                textAlign: lCode === 'ar' ? 'right' : 'left',

                                '& 	.MuiOutlinedInput-notchedOutline': {
                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                },
                                '& 	.MuiInputLabel-root': {
                                    fontSize: 12,
                                    left: lCode === 'ar' ? 'inherit' : '0',
                                    right: lCode === 'ar' ? '1.75rem' : '0',
                                    transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                },
                            }}
                            // defaultValue=" "
                            disabled={readOnly}
                            required
                            onChange={(e) => setPreRegisterObj({ ...preRegisterObj, name: e.target.value })}
                            className=''
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <img src={userregular} className='user_regular_img' alt='acadd_logo' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',

                            height: '40px',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <TextField
                            size='small'
                            fullWidth
                            label='LAST NAME'
                            id='last_name'
                            value={preRegisterObj?.lastName}
                            sx={{
                                textAlign: lCode === 'ar' ? 'right' : 'left',

                                '& 	.MuiOutlinedInput-notchedOutline': {
                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                },
                                '& 	.MuiInputLabel-root': {
                                    fontSize: 12,
                                    left: lCode === 'ar' ? 'inherit' : '0',
                                    right: lCode === 'ar' ? '1.75rem' : '0',
                                    transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                },
                            }}
                            // defaultValue=" "
                            disabled={readOnly}
                            required
                            onChange={(e) => setPreRegisterObj({ ...preRegisterObj, lastName: e.target.value })}
                            className=''
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            height: '40px',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <TextField
                            size='small'
                            fullWidth
                            label={t('second_last_name')}
                            id='second_last_name'
                            value={preRegisterObj?.secondLastName}
                            sx={{
                                textAlign: lCode === 'ar' ? 'right' : 'left',

                                '& 	.MuiOutlinedInput-notchedOutline': {
                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                },
                                '& 	.MuiInputLabel-root': {
                                    fontSize: 12,
                                    left: lCode === 'ar' ? 'inherit' : '0',
                                    right: lCode === 'ar' ? '1.75rem' : '0',
                                    transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                },
                            }}
                            // defaultValue=" "
                            disabled={readOnly}
                            onChange={(e) => setPreRegisterObj({ ...preRegisterObj, secondLastName: e.target.value })}
                            className=''
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            height: '40px',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <TextField
                            size='small'
                            fullWidth
                            label='NUMBER'
                            id='CELULAR'
                            disabled={readOnly}
                            value={preRegisterObj?.phoneNumber}
                            sx={{
                                textAlign: lCode === 'ar' ? 'right' : 'left',

                                '& 	.MuiOutlinedInput-notchedOutline': {
                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                },
                                '& 	.MuiInputLabel-root': {
                                    fontSize: 12,
                                    left: lCode === 'ar' ? 'inherit' : '0',
                                    right: lCode === 'ar' ? '1.75rem' : '0',
                                    transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                },
                            }}
                            // defaultValue=" "
                            onChange={(e) => setPreRegisterObj({ ...preRegisterObj, phoneNumber: e.target.value })}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <PhoneIphoneIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            height: '40px',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <TextField
                            size='small'
                            fullWidth
                            label='EMAIL'
                            value={preRegisterObj?.email}
                            sx={{
                                textAlign: lCode === 'ar' ? 'right' : 'left',

                                '& 	.MuiOutlinedInput-notchedOutline': {
                                    textAlign: lCode === 'ar' ? 'right' : 'left',
                                },
                                '& 	.MuiInputLabel-root': {
                                    fontSize: 12,
                                    left: lCode === 'ar' ? 'inherit' : '0',
                                    right: lCode === 'ar' ? '1.75rem' : '0',
                                    transformOrigin: lCode === 'ar' ? 'right' : 'left',
                                },
                            }}
                            // defaultValue=" "
                            disabled={readOnly}
                            onChange={(e) => setPreRegisterObj({ ...preRegisterObj, email: e.target.value })}
                            id='EMAIL'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <MailOutlineIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </div>
                <div className='buttonArea mt-4 d-flex'>
                    <button
                        style={{ width: '180px' }}
                        className='custom_btn_cancel_gray_hover'
                        onClick={() => props.onHide()}
                    >
                        CANCEL
                    </button>
                    <button style={{ width: '180px' }} className='custom_primary_btn_dark' onClick={handleConfirm}>
                        CONFIRM
                        {/* {
                            loading ? "Deleting...!" : "Delete"
                        } */}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ManageOthersModal;
