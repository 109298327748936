import React from 'react';
import loadericon from '../assets/icon/spinner.gif';
import fingerprint from '../assets/icon/fingerprint.gif';
import fingerAnimated from '../assets/icon/finger-animated-unscreen.gif';

const FingerPrintLoader = () => {
    return (
        <div className='loader_fingerprint' id='overlay'>
            <div className='loader_div'>
                <img src={fingerAnimated} alt='' width='300px' height='300px' />
            </div>
        </div>
    );
};

export default FingerPrintLoader;
