import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleSearch, SimpleSearchWithId } from '../../reduxToolkit/Search/SearchApi';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
const SearchSimpleWithId = ({ setSearchResults, optionTable, page, rowsPerPage, setBodyForExport, id }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // El setSearchResults se usará para almacenar los resultados de la búsqueda
    // y se pasará al componente PADRE
    const [searchElement, setSearchElement] = useState('');

    const filterSectionByElement = async () => {
        if (searchElement) {
            const body = {
                key: searchElement,
                model: optionTable,
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
                userId: id,
            };
            setBodyForExport(body);
            await dispatch(SimpleSearchWithId({ body })).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        setSearchResults(data);
                        toast.success(t('search_completed_successfully'));
                    } else {
                        toast.error(t('error_when_searching'));
                    }
                },
            );
        } else {
            setSearchResults('');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            filterSectionByElement();
        }
    };

    return (
        <>
            <form className='centerElements' onSubmit={(e) => e.preventDefault()}>
                <section className='searchElementByWord-devices--section centerElements'>
                    <TextField
                        label={t('TYPE_SOMETHING_TO_SEARCH')}
                        className='searchSimple--TextField'
                        onChange={(e) => setSearchElement(e.target.value)}
                    />
                    <button
                        className='filter--devices-btn'
                        onKeyDown={handleKeyDown}
                        onClick={() => filterSectionByElement()}
                    >
                        <SearchIcon />
                    </button>
                </section>
            </form>
        </>
    );
};

export default SearchSimpleWithId;
