import React, { useEffect, useRef, useState } from 'react';
import ic_map from '../../../assets/images/ic-map.svg';
import ic_chevron from '../../../assets/ic-right-chevron.svg';
import warningImg from '../../../assets/images/warning.svg';
import { Link } from 'react-router-dom';
import AddZoneModal from './Modal/AddZoneModal';
import { Table } from 'react-bootstrap';
import AddBuildingModel from './Modal/AddBuildingModal';
import RemovePlanModal from './Modal/RemovePlanModal';
import TotalAccessService from './TotalAccessService';
import TablePagination from '@mui/material/TablePagination';
import { useDispatch, useSelector } from 'react-redux';
import ic_edit from '../../../assets/icon/ic-edit-pencil.svg';
import {
    DeleteZoneUser,
    GetListZoneMap,
    ZoneDetailAuthorizedEmployee,
    ZoneDetailAuthorizedEmployeeNoPagination,
    ZoneDetailFatherAndChild,
    ZoneDetailChild,
} from '../../../reduxToolkit/EmployeeZones/EmployeeZonesApi';
import AuthorizedEmployeesModal from '../../Modals/AuthorizedEmployeesModal';
import { getAllEmployees } from '../../../reduxToolkit/EmployeeEvents/EmployeeEventsApi';
import { permissionObj } from '../../../Helpers/permission';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import ic_left_arrow from '../../../assets/images/ic_left_arrow.svg';
import DeleteModal from '../../Modals/DeleteModal';
import { Checkbox, FormControlLabel, Tooltip, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { DeleteItemsApi } from '../../../reduxToolkit/Commons/CommonsApi';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import '../../../../src/scss/colors.scss';
import { status } from '../../../enums/statusEnum';
import { GoPrimitiveDot } from 'react-icons/go';
import ic_add from '../../../assets/icon/ic-add.svg';
import { set } from 'lodash';
import NotInformation from '../../../components/NotInformation';
// import "./colors.scss";
/*
Author : Arman Ali
Module: Zone
github: https://github.com/Arman-Arzoo
*/

const SingleZoneDetails = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    // use hook importer
    const dispatch = useDispatch();

    // use State hook for local state management
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [isFatherZone, setIsFatherZone] = useState({});
    const [pageAuthorizedEmployee, setPageAuthorizedEmployee] = useState(0);
    const [rowsPerPageAuthorizedEmployee, setRowsPerPageAuthorizedEmployee] = useState(12);
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [freeadded, setfreeadded] = useState(false);
    const [devicedeleted, setdevicedeleted] = useState(false);
    const [selectEmployeeForDelete, setSelectEmployeeForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);

    const [selectSubZoneForDelete, setSelectSubZoneForDelete] = useState([]);
    const [isAllCheckedSubZone, setIsAllCheckedSubZone] = useState(false);
    const [deleteZone, setDeleteZone] = useState(false);
    const [deleteShowSubZone, setDeleteShowSubZone] = useState(false);
    const [zoneId, setZoneId] = useState('');
    const [zoneName, setZoneName] = useState('');
    const [stack, setStack] = useState(JSON.parse(sessionStorage.getItem('zonesStack')));
    const [inputValue, setInputValue] = useState({ id: '', name: '' });
    const [prevStack, Setprevstack] = useState(props.prevStack || {});
    const [showNavbar, setShowNavbar] = useState(false);
    const [backResponse, setBackResponse] = useState(false);
    const [open, setOpen] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 65) {
            setShowNavbar(true);
        } else {
            setShowNavbar(false);
        }
    };

    // use Selector hook to get state for redux store
    const {
        createUserZoneList,
        createZonePlane,
        uploadImgZonePlane,
        deleteImgZonePlane,
        zoneDetailFatherAndChild,
        zoneDetailAuthorizedEmployee,
        createCommonAreaZone,
        updateZone,
        updateCommonAreaZone,
        deleteZoneUser,
        setZoneImageCoordinate,
        createChildZone,
        zoneDetailChild,
    } = useSelector((state) => state.EmployeeZonesSlice);

    const { deleteItemsApi } = useSelector((state) => state.CommonsSlice);

    // const { addFreeDevice } = useSelector(state => state.DevicesSlice)

    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);

        if (e.target.checked) {
            const selectAllIds = zoneDetailAuthorizedEmployee?.content?.map((item) => {
                return item?.userId;
            });

            setSelectEmployeeForDelete(selectAllIds);
        } else {
            setSelectEmployeeForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectEmployeeForDelete([...selectEmployeeForDelete, e.target.id]);
        } else {
            setSelectEmployeeForDelete(selectEmployeeForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };
    // handel delete zone employee
    const handleDeleteEmployee = () => {
        const data = {
            userIds: selectEmployeeForDelete,
            // zoneId: sessionStorage.getItem("singlezoneId")
            zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
            ].id,
        };
        dispatch(DeleteZoneUser(data));
        setDeleteZone(false);
    };

    // this function handle only specific id base on selection
    const handleCheckboxChangeSubZone = (e) => {
        if (e.target.checked) {
            setSelectSubZoneForDelete([...selectSubZoneForDelete, e.target.id]);
        } else {
            setSelectSubZoneForDelete(selectSubZoneForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };
    // this function control select all id or unSelect all
    const handelDeleteAllSubZone = (e) => {
        setIsAllCheckedSubZone(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = zoneDetailFatherAndChild?.children?.map((item) => {
                return item?.id;
            });
            setSelectSubZoneForDelete(selectAllIds);
        } else {
            setSelectSubZoneForDelete([]);
        }
    };

    // a funtion to change authorized page
    const handleChangePageAuthorizedEmployee = (event, newPage) => {
        setPageAuthorizedEmployee(newPage);
    };

    // a funtion to change row per page
    const handleChangeRowsPerPageAuthorizedEmployee = (event) => {
        setRowsPerPageAuthorizedEmployee(parseInt(event.target.value));
        setPageAuthorizedEmployee(0);
    };
    // api function
    const deleteSubZone = (deleteItem) => {
        const tableName = 'zone';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body }));
    };

    // a useEffect lifeCycle for dispatch zone detail authorized employee
    useEffect(() => {
        const body = {
            pagination: {
                order: sortBy === 'asc' ? true : false,
                page: pageAuthorizedEmployee,
                size: rowsPerPageAuthorizedEmployee,
                sortBy: orderBy ? orderBy : 'id',
            },
            // zoneId: sessionStorage.getItem("singlezoneId")
            // zoneId: JSON.parse(sessionStorage?.getItem("zonesStack"))[JSON.parse(sessionStorage?.getItem("zonesStack")).length - 1].id
            zoneId: zoneDetailFatherAndChild?.id,
        };
        dispatch(ZoneDetailAuthorizedEmployee(body));
    }, [
        pageAuthorizedEmployee,
        rowsPerPageAuthorizedEmployee,
        zoneDetailFatherAndChild,
        orderBy,
        sortBy,
        deleteZoneUser,
        setDeleteZone,
        createUserZoneList,
        stack,
    ]);

    useEffect(() => {
        dispatch(ZoneDetailAuthorizedEmployeeNoPagination());
    }, [deleteItemsApi, deleteZoneUser]);
    // a useEffect lifeCycle for dispatch zone detail father and child
    useEffect(() => {
        setfreeadded(false);
        setdevicedeleted(false);

        if (JSON.parse(sessionStorage?.getItem('zonesStack')).length > 0) {
            setStack(JSON.parse(sessionStorage.getItem('zonesStack')));
        }
        // dispatch(ZoneDetailFatherAndChild({ zoneId: JSON.parse(sessionStorage?.getItem("zonesStack"))[JSON.parse(sessionStorage?.getItem("zonesStack")).length - 1].id }  )).then(({ payload: { data } }) => {

        dispatch(
            ZoneDetailFatherAndChild({
                zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                    JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
                ].id,
            }),
        ).then(({ payload: { data } }) => {
            setInputValue({ id: data?.data?.id, name: data?.data?.name });
        });

        // dispatch(ZoneDetailFatherAndChild({ zoneId: stack[stack.length - 1].id })).then(({ payload: { data } }) => {
        //       setInputValue({ id: data?.data?.id, name: data?.data?.name })

        // if (stack.length > 0) {
        //   dispatch(ZoneDetailFatherAndChild({ zoneId: stack[stack.length - 1].id })).then(({ payload: { data } }) => {
        //     setInputValue({ id: data?.data?.id, name: data?.data?.name })
        //   })
        // } else {
        //   dispatch(ZoneDetailFatherAndChild({ zoneId: sessionStorage?.getItem("singlezoneId") })).then(({ payload: { data } }) => {
        //     setInputValue({ id: data?.data?.id, name: data?.data?.name })
        //   })
        // }
    }, [
        updateCommonAreaZone,
        updateZone,
        createCommonAreaZone,
        createZonePlane,
        uploadImgZonePlane,
        deleteImgZonePlane,
        setZoneImageCoordinate,
        createChildZone,
        deleteZoneUser,
        freeadded,
        devicedeleted,
        deleteItemsApi,
        zoneDetailChild,
    ]);

    // set lng and lng for current zone
    sessionStorage.setItem('currentZoneLat', zoneDetailFatherAndChild?.latitud);
    sessionStorage.setItem('currentZoneLng', zoneDetailFatherAndChild?.longitud);

    // Función para verificar si existe un objeto con el mismo ID en el stack
    const isDuplicateId = (newId) => {
        return stack.some((obj) => obj.id === newId);
    };

    const pushToStack = () => {
        if (
            inputValue.id !== '' &&
            inputValue.name !== '' &&
            inputValue.id !== null &&
            inputValue.name !== null &&
            inputValue.id !== undefined &&
            inputValue.name !== undefined
        ) {
            if (
                !isDuplicateId(inputValue.id) &&
                inputValue !== '' &&
                inputValue !== undefined &&
                inputValue !== {} &&
                inputValue !== null
            ) {
                setStack([...stack, inputValue]);
                setInputValue({ id: '', name: '' }); // Limpia los valores del input
            }
            // setStack([...stack, inputValue]);
            // setInputValue({ id: '', name: '' }); // Limpia los valores del input
        }
    };

    const popFromStack = () => {
        if (stack.length > 0) {
            const newStack = [...stack];
            newStack.pop();
            setStack(newStack);
        }
    };

    useEffect(() => {
        // const data = {
        //   // zoneId: sessionStorage?.getItem('singlezoneId')
        //   zoneId: JSON.parse(sessionStorage?.getItem("zonesStack"))[JSON.parse(sessionStorage?.getItem("zonesStack")).length - 1].id
        // }
        // dispatch(GetListZoneMap(data))
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            // setStack([])
            // setInputValue({ id: '', name: '' })
        };
        // location?.state?.zoneNameProps !== undefined ?
        // if (props === undefined) {
        //   pushToStack()
        // }
    }, []);

    useEffect(() => {
        if (inputValue.name !== '') {
            pushToStack();
        } else {
        }
    }, [inputValue]);

    useEffect(() => {
        if (backResponse) {
            dispatch(ZoneDetailFatherAndChild({ zoneId: stack[stack.length - 2].id })).then(({ payload: { data } }) => {
                popFromStack();
                setBackResponse(false);
            });
        }
    }, [backResponse]);

    useEffect(() => {
        if (stack.length > 0) {
            sessionStorage.setItem('zonesStack', JSON.stringify(stack));
        }
        // sessionStorage.setItem('zonesStack', JSON.stringify(stack))
        // sessionStorage.setItem('singlezoneId', item?.id)
    }, [stack]);

    // return main page
    return (
        <>
            {/* <div className="thin-scroll-bar"> */}
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow
                        link={stack.length > 1 ? '#' : '/dashboard/employee/zones'}
                        // onBack={() => popFromStack()}
                        onBack={() => setBackResponse(true)}
                    />
                    <h2>{t('details')}</h2>

                    {/* <div>
            {stack.map((item, index) => (
              <span
                className={
                  index === stack.length - 1 ? "ml-3 father-child-zone-selected" : "ml-3 father-child-zone"
                }
                onClick={() => {
                  if (index !== stack.length - 1) {
                    dispatch(ZoneDetailFatherAndChild({ zoneId: item?.id })).then(({ payload: { data } }) => {
                      setInputValue({ id: "", name: "" })
                      setStack(stack.slice(0, index + 1))
                    })
                  }
                }}
              >
                {index > 0 && <img src={ic_chevron} alt="Flecha" className="mr-3" style={{ pointerEvents: 'none' }} />}
                {item.name}
              </span>
            ))}
          </div> */}

                    <div className='d-flex'>
                        {stack.map((item, index) => (
                            <div>
                                {index > 0 && <img src={ic_chevron} alt='Flecha' style={{ pointerEvents: 'none' }} />}
                                <span
                                    className={
                                        index === stack.length - 1
                                            ? 'ml-3 father-child-zone-selected'
                                            : 'mx-3 father-child-zone'
                                    }
                                    onClick={() => {
                                        if (index !== stack.length - 1) {
                                            dispatch(ZoneDetailFatherAndChild({ zoneId: item?.id })).then(
                                                ({ payload: { data } }) => {
                                                    setInputValue({ id: '', name: '' });
                                                    setStack(stack.slice(0, index + 1));
                                                },
                                            );
                                        }
                                    }}
                                >
                                    {item.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gridGap: '10px',
                        alignItems: 'center',
                    }}
                >
                    {/* {
            permission?.includes(permissionObj?.WEB_ZONE_READ_DEVICES) &&
            <div className="pull-right">
              <Link to="/dashboard/employee/zones/showdevices" style={{ opacity: 0.5 }}>
                <button className="add-btn-1" >
                  <img src={ic_map} alt="" />
                  {t("show_devices")}
                </button>
              </Link>
            </div>
          } */}

                    {permission?.includes(permissionObj?.WEB_ZONE_UPDATE) && (
                        <Link
                            to='/dashboard/employee/zones/updatezone'
                            className='pull-right'
                            onClick={() => {
                                sessionStorage.getItem('singlezoneId');
                                // dispatch(ZoneDetailFatherAndChild({ zoneId: sessionStorage.getItem("singlezoneId") }))
                                dispatch(
                                    ZoneDetailFatherAndChild({
                                        zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                                            JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
                                        ].id,
                                    }),
                                );
                            }}
                        >
                            <button className='add-btn-1'>
                                <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                                {t('update')}
                            </button>
                        </Link>
                    )}

                    {permission?.includes(permissionObj?.WEB_ZONE_READ_DEVICES) && (
                        <div className='pull-right'>
                            <Link to='/dashboard/employee/zones/showdevices'>
                                <button className='add-btn-1'>
                                    <img src={ic_map} alt='' />
                                    {t('show_devices')}
                                </button>
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <div className='top-appbar'>
                <AppBar
                    position='fixed'
                    style={{
                        transform: showNavbar ? 'translateY(0)' : 'translateY(-100%)',
                        transition: 'transform 0.3s ease',
                    }}
                >
                    <Toolbar>
                        <div className='head pl-4 ml-3'>
                            <div className='headLeft'>
                                <ReturnArrow
                                    link={stack.length > 1 ? '#' : '/dashboard/employee/zones'}
                                    // onBack={() => popFromStack()}
                                    onBack={() => setBackResponse(true)}
                                    appbar={true}
                                />
                                <h2>{t('details')}</h2>

                                {/* <div>
                  {stack.map((item, index) => (
                    <span
                      className={
                        index === stack.length - 1 ? "ml-3 father-child-zone-selected" : "ml-3 father-child-zone"
                      }
                      onClick={() => {
                        if (index !== stack.length - 1) {
                          dispatch(ZoneDetailFatherAndChild({ zoneId: item?.id })).then(({ payload: { data } }) => {
                            setInputValue({ id: "", name: "" })
                            setStack(stack.slice(0, index - 1))
                          })
                        }
                      }}
                    >
                      {index > 0 && <img src={ic_chevron} alt="Flecha" className="mr-3" />}
                      {item.name}
                    </span>
                  ))}
                </div> */}
                                <div className='d-flex'>
                                    {stack.map((item, index) => (
                                        <div>
                                            {index > 0 && (
                                                <img src={ic_chevron} alt='Flecha' style={{ pointerEvents: 'none' }} />
                                            )}
                                            <span
                                                className={
                                                    index === stack.length - 1
                                                        ? 'ml-3 father-child-zone-selected'
                                                        : 'mx-3 father-child-zone'
                                                }
                                                onClick={() => {
                                                    if (index !== stack.length - 1) {
                                                        dispatch(ZoneDetailFatherAndChild({ zoneId: item?.id })).then(
                                                            ({ payload: { data } }) => {
                                                                setInputValue({ id: '', name: '' });
                                                                setStack(stack.slice(0, index + 1));
                                                            },
                                                        );
                                                    }
                                                }}
                                            >
                                                {item.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>

            {/* Building Details Main Section Start */}

            <div className='zonesinactive_res'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='building_details_text'>
                            <div className=' text-center'>
                                <h1>{t('details')}</h1>
                                <div className='building_details_text_border'>
                                    <p>{t('name')}</p>
                                    <h2>{zoneDetailFatherAndChild?.name}</h2>
                                    <div className='mt-4'>
                                        <p>{t('status')}</p>
                                        <div className={'status ' + status[zoneDetailFatherAndChild?.status?.id]}>
                                            {t(status[zoneDetailFatherAndChild?.status?.id]).toUpperCase()}
                                            <GoPrimitiveDot className='ml-1' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-5'>
                        <div className='text-center buildingdetail_access_txt'>
                            <h1 className='mt-4'>{t('common_area')}</h1>
                        </div>
                        <div
                            className='text-center mt-4 schedule_zoneb'
                            style={{
                                borderLeft: lCode === 'ar' ? '' : '3px solid #146f62',
                                borderRight: lCode === 'ar' ? '3px solid #146f62' : '',
                            }}
                        >
                            {zoneDetailFatherAndChild?.commonArea == null && (
                                <NotInformation text={t('no_information')} card={false} />
                            )}
                            {zoneDetailFatherAndChild?.commonArea != null && (
                                <>
                                    <h2>{t('schedule_use')}</h2>
                                    <h3>{t('from')}</h3>
                                    <p>{zoneDetailFatherAndChild?.commonArea?.fromTime}</p>
                                    <h3>{t('to')}</h3>
                                    <p>{zoneDetailFatherAndChild?.commonArea?.toTime}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='div'>
                {/* table for sub zone list */}
                <div
                    className='buildingdetail_access_d mt-4'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: 'none',
                        gap: '14px',
                        alignItems: 'center',
                        padding: '10px 0',
                    }}
                >
                    <div className='d-flex'>
                        <h1>{t('internal_monitoring')?.toUpperCase()}</h1>
                        {permission?.includes(permissionObj?.WEB_ZONE_CREATE) && (
                            <span
                                className='ml-3 device_button'
                                onClick={() => {
                                    setModalShow(true);
                                    setIsFatherZone({
                                        id: zoneDetailFatherAndChild?.id,
                                        name: zoneDetailFatherAndChild?.name,
                                    });
                                }}
                            >
                                {t('add_sub_zone')?.toUpperCase()}
                                <img className='ml-2' src={ic_add} alt='' />
                            </span>
                        )}
                    </div>
                    {permission?.includes(permissionObj?.WEB_ZONE_DELETE) &&
                        zoneDetailFatherAndChild?.children?.length > 0 && (
                            <button
                                className='delete-btn-1 mr-3'
                                disabled={selectSubZoneForDelete?.length === 0}
                                onClick={() => {
                                    setDeleteShowSubZone(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                </div>

                {/* <div className="zonescollaps_building"> */}
                {/* <div className="mt-4 row room_access_text"> */}
                {/* <div className="">
              {
                zoneDetailFatherAndChild?.children?.length > 0 ?
                  <Table
                  >
                    <thead  >
                      <tr>

                        <th style={{ border: 'none', display: "flex", gap: "0.1rem", alignItems: "center" }}>
                          <Tooltip title={t("de_/_select_all").toUpperCase()} placement="top" disableInteractive>
                            <Checkbox
                              className="grid-checkall checkbox"
                              checked={isAllCheckedSubZone}
                              onChange={handelDeleteAllSubZone}
                              size="small"
                            />
                          </Tooltip>
                          <h5>{t("sub_zone")?.toUpperCase()}</h5>
                        </th>
                        <th style={{ border: 'none', textAlign: "center" }}>
                          <h5>{t("access_devices")}</h5>
                        </th>
                        <th style={{ border: 'none', textAlign: "center" }}>
                          <h5>{t("common_area")}</h5>
                        </th>
                        <th style={{ border: 'none', textAlign: "center" }}>
                          <h5>{t("status")}</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        zoneDetailFatherAndChild?.children?.map((item, index) => {
                          return (
                            <tr>

                              <td className="d-flex align-items-center ">
                                <Checkbox
                                  className="grid-checkall checkbox"
                                  checked={selectSubZoneForDelete?.includes(item?.id)}
                                  id={item?.id}
                                  onChange={handleCheckboxChangeSubZone}
                                  size="small"
                                />
                                <h4 className="d-flex align-items-center gap-2">
                                  {item?.name}
                                  <a
                                    className="zone_child_more_detail"
                                    onClick={() => {
                                      dispatch(ZoneDetailFatherAndChild({ zoneId: item?.id })).then(({ payload: { data } }) => {
                                        setInputValue({ id: data?.data?.id, name: data?.data?.name })
                                      })
                                    }}

                                    href="#">{t("more_details")}</a>
                                </h4>
                              </td>
                              <td className="text-center">
                                <div>
                                  {
                                    item?.devices.length == 0 &&
                                    <i class="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.2rem" }}></i>
                                  }
                                  {
                                    item?.devices.length > 0 &&
                                    <i class="fa fa-check" aria-hidden="true" style={{ color: 'green', fontSize: "1.2rem" }}></i>
                                  }
                                </div>
                              </td>
                              <td className="text-center">
                                <div>
                                  {
                                    item?.commonArea == null &&
                                    <i class="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.2rem" }}></i>
                                  }
                                  {
                                    item?.commonArea != null &&
                                    <i class="fa fa-check" aria-hidden="true" style={{ color: 'green', fontSize: "1.2rem" }}></i>
                                  }
                                </div>
                              </td>
                              <td className="text-center">
                                <h6>
                                  {item?.status?.name.split("_").join(" ")}
                                  <>
                                    <i style={{ marginLeft: "0.4rem", fontSize: '0.6rem' }} class="fa fa-circle" aria-hidden="true"></i>
                                  </>
                                </h6>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                  :
                  <div>
                    <NotInformation text={t('no_information')} card={true} />
                  </div>
              }
            </div> */}
                {zoneDetailFatherAndChild?.children?.length > 0 ? (
                    <div className='col-12 panelTables px-1' style={{ overflow: 'none', height: 'auto' }}>
                        {zoneDetailFatherAndChild?.children?.length > 0 && (
                            <table className='w-100'>
                                <thead className='no-border-thead'>
                                    {permission?.includes(permissionObj?.WEB_ZONE_DELETE_DEVICES) && (
                                        <th className='first_head'>
                                            <Tooltip
                                                title={t('de_/_select_all').toUpperCase()}
                                                placement='top'
                                                disableInteractive
                                            >
                                                <Checkbox
                                                    className='grid-checkall checkbox'
                                                    checked={isAllCheckedSubZone}
                                                    onChange={handelDeleteAllSubZone}
                                                    size='small'
                                                />
                                            </Tooltip>
                                        </th>
                                    )}
                                    <th className='first_head'>{t('sub_zone')}</th>
                                    <th>{t('access_devices')}</th>
                                    <th>{t('common_area')}</th>
                                    <th>{t('status')}</th>
                                </thead>
                                {zoneDetailFatherAndChild?.children?.map((item, index) => (
                                    <tr key={item?.id}>
                                        {permission?.includes(permissionObj?.WEB_ZONE_DELETE_DEVICES) && (
                                            <td className='first'>
                                                <Checkbox
                                                    className='grid-checkall checkbox'
                                                    checked={selectSubZoneForDelete?.includes(item?.id)}
                                                    id={item?.id}
                                                    onChange={handleCheckboxChangeSubZone}
                                                    size='small'
                                                />
                                            </td>
                                        )}
                                        <td className='first'>
                                            {item?.name}
                                            {/* <a
                          className="zone_child_more_detail"
                          onClick={() => {
                            dispatch(ZoneDetailFatherAndChild({ zoneId: item?.id })).then(({ payload: { data } }) => {
                              setInputValue({ id: data?.data?.id, name: data?.data?.name })
                            })
                          }}
                          href="#">
                          {t("more_details")}
                        </a> */}
                                            <span
                                                className='ml-3 device_button'
                                                onClick={() => {
                                                    dispatch(ZoneDetailFatherAndChild({ zoneId: item?.id })).then(
                                                        ({ payload: { data } }) => {
                                                            setInputValue({
                                                                id: data?.data?.id,
                                                                name: data?.data?.name,
                                                            });
                                                        },
                                                    );
                                                }}
                                                href='#'
                                            >
                                                {t('more_details')}
                                            </span>
                                        </td>
                                        <td>
                                            {item?.devices.length === 0 ? (
                                                <i
                                                    className='fa fa-times'
                                                    aria-hidden='true'
                                                    style={{ color: 'red', fontSize: '1.2rem' }}
                                                ></i>
                                            ) : (
                                                <i
                                                    className='fa fa-check'
                                                    aria-hidden='true'
                                                    style={{ color: 'green', fontSize: '1.2rem' }}
                                                ></i>
                                            )}
                                        </td>
                                        <td>
                                            {item?.commonArea === null ? (
                                                <i
                                                    className='fa fa-times'
                                                    aria-hidden='true'
                                                    style={{ color: 'red', fontSize: '1.2rem' }}
                                                ></i>
                                            ) : (
                                                <i
                                                    className='fa fa-check'
                                                    aria-hidden='true'
                                                    style={{ color: 'green', fontSize: '1.2rem' }}
                                                ></i>
                                            )}
                                        </td>
                                        <td>
                                            {/* {item?.status?.name.split("_").join(" ")} */}
                                            <div className={'pl-2 status ' + status[item?.status?.id]}>
                                                {t(status[item?.status?.id]).toUpperCase()}
                                                <GoPrimitiveDot className='ml-1' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        )}
                    </div>
                ) : (
                    <div>
                        <NotInformation text={t('no_information')} card={true} />
                    </div>
                )}

                {/* </div> */}
                {/* </div> */}

                {/* Total Access Sevice Section Start */}

                {permission?.includes(permissionObj?.WEB_ZONE_READ_DEVICES) && (
                    <TotalAccessService
                        item={zoneDetailFatherAndChild}
                        freeadded={freeadded}
                        onfreeadded={() => setfreeadded(true)}
                        devicedeleted={devicedeleted}
                        ondevicedeleted={() => setdevicedeleted(true)}
                    />
                )}

                {/* authorized employee module */}
                {permission?.includes(permissionObj?.WEB_ZONE_MANAGE_USERS) && (
                    <div className='buildingdetail_access_d mt-4'>
                        <Table className='table'>
                            <thead className='no-border-thead'>
                                <tr>
                                    <th className='head_line'>
                                        <h1>{t('authorized_employees')}</h1>

                                        <span
                                            className='device_button'
                                            onClick={() => {
                                                setShow(true);
                                                dispatch(getAllEmployees());
                                            }}
                                        >
                                            {t('manage_employees')}
                                            <img className='ml-2' src={ic_add} alt='' />
                                        </span>

                                        <AuthorizedEmployeesModal
                                            title={t('manage_employees')}
                                            show={show}
                                            onHide={() => setShow(false)}
                                            deleteData={selectEmployeeForDelete}
                                            personalizedAction={false}
                                            personalizedRemoveAction={false}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <div className='d-flex gap-1 p-0 pb-2'>
                                        <FormControlLabel
                                            className='grid-checkall'
                                            control={
                                                <Checkbox
                                                    label='Label'
                                                    checked={isAllChecked}
                                                    onChange={handelDeleteAll}
                                                    size='small'
                                                />
                                            }
                                            label={t('de_/_select_all')}
                                        />
                                    </div>
                                </tr>
                            </thead>
                            {zoneDetailAuthorizedEmployee?.content?.length > 0 ? (
                                <tbody>
                                    {zoneDetailAuthorizedEmployee?.content?.map((employee) => (
                                        <>
                                            <div
                                                className='column'
                                                style={{ float: 'left', width: '25%', color: 'gray' }}
                                            >
                                                <tr style={{ border: 'hidden' }}>
                                                    <td
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'spacebetween',
                                                            border: 'hidden',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            gap: '0.5rem',
                                                        }}
                                                    >
                                                        <Checkbox
                                                            className='grid-checkall checkbox'
                                                            checked={selectEmployeeForDelete?.includes(
                                                                employee?.userId,
                                                            )}
                                                            id={employee?.userId}
                                                            onChange={handleCheckboxChange}
                                                            size='small'
                                                        />
                                                        <span style={{ fontSize: '0.8rem', opacity: '0.5' }}>
                                                            {(employee?.name || '') +
                                                                ' ' +
                                                                (employee?.firstLastName || '') +
                                                                (employee?.secondLastName
                                                                    ? ' ' + employee.secondLastName
                                                                    : '')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </>
                                    ))}
                                </tbody>
                            ) : (
                                <NotInformation text={t('no_information')} card={true} />
                            )}
                        </Table>

                        {selectEmployeeForDelete?.length > 0 && (
                            <div className='remove_selected_zone_employee'>
                                <span className='device_button' onClick={() => setDeleteZone(true)}>
                                    {t('remove_selected').toUpperCase()}
                                    <i className='fa fa-trash-o ml-2' aria-hidden='true'></i>
                                </span>
                            </div>
                        )}
                        {zoneDetailAuthorizedEmployee?.content?.length > 0 && (
                            <div className='d-flex justify-content-center'>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[12, 24, 36]}
                                    count={zoneDetailAuthorizedEmployee?.totalElements}
                                    page={pageAuthorizedEmployee}
                                    onPageChange={handleChangePageAuthorizedEmployee}
                                    labelRowsPerPage={t('authorized_employee_per_page')}
                                    rowsPerPage={rowsPerPageAuthorizedEmployee}
                                    onRowsPerPageChange={handleChangeRowsPerPageAuthorizedEmployee}
                                />
                            </div>
                        )}
                    </div>
                )}
                {/* Total Access Sevice Section End */}
            </div>
            {/* Building Details Main Section End */}

            {/* Add Building Modal Start */}
            <AddZoneModal
                title={t('add_zone')}
                check='true'
                show={modalShow}
                onHide={() => setModalShow(false)}
                isFatherZone={isFatherZone}
            />
            {/* <!-- The Modal --> */}
            <AddBuildingModel />
            {/* Add Building Modal End */}
            {/* Add Child Zones Modal Start */}
            {/* <!-- The Modal --> */}
            {/* <AddChildZoneModal /> */}
            {/* Add Child Zones Modal End */}
            <RemovePlanModal />
            <DeleteModal
                show={deleteZone}
                onHide={() => setDeleteZone(false)}
                onClickFn={() => handleDeleteEmployee()}
                data={selectEmployeeForDelete}
                title_modal={'zone_details'}
                isReset={setSelectEmployeeForDelete}
                // description={"are_you_sure_you_want_to_remove"}
                element_modal={'authorized_employees'}
                isAllReset={setIsAllChecked}
            />
            <DeleteModal
                show={deleteShowSubZone}
                onHide={() => setDeleteShowSubZone(false)}
                onClickFn={() => deleteSubZone(selectSubZoneForDelete)}
                data={selectSubZoneForDelete}
                title_modal={'first_access'}
                element_modal={'sub_zone'}
                isReset={setSelectSubZoneForDelete}
                isAllReset={setIsAllCheckedSubZone}
            />
            {/* </div> */}
        </>
    );
};

export default SingleZoneDetails;
