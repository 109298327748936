import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ContratorLayout from '../Layouts/ContratorLayout';
import DashboardLayout from '../Layouts/DashboardLayout';
import EmployeeLayout from '../Layouts/EmployeeLayout';
import ProviderLayout from '../Layouts/ProviderLayout';

// outlets
import { CompanyOutlet } from '../Outlet/Employee/CompanyOutlet';
import { ContractorEmployeeOutlet } from '../Outlet/Employee/ContractorEmployeeOutlet';
import { DevicesOutlet } from '../Outlet/Employee/DevicesOutlet';
import { CardsOutlet } from '../Outlet/Employee/CardsOutlet';
import { GraphsOutlet } from '../Outlet/Employee/GraphsOutlet';
import { EmployeeOutlet } from '../Outlet/Employee/EmployeeOutlet';
import { EventOutlet } from '../Outlet/Employee/EventOutlet';
import { InputsOutlet } from '../Outlet/Employee/InputsOutlet';
import { ControllerOutlet } from '../Outlet/Employee/ControllerOutlet';
import { OutputsOutlet } from '../Outlet/Employee/OutputsOutlet';
import { ReadersOutlet } from '../Outlet/Employee/ReadersOutlet';
import { SIOsOutlet } from '../Outlet/Employee/SIOsOutlet';
import { PayrollOutlet } from '../Outlet/Employee/PayrollOutlet';
import { ProviderEmployeeOutlet } from '../Outlet/Employee/ProviderEmployeeOutlet';
import { VehicleOutlet } from '../Outlet/Employee/VehicleOutlet';
import { ZoneOutlet } from '../Outlet/Employee/ZoneOutlet';
import { CardDesignerOutlet } from '../Outlet/Employee/CardDesignerOutlet';

// Other
import NotFound from '../components/NotFound';
import RequireAuth from '../components/RequireAuth';
import Unauthorized from '../components/Unauthorized';
import Home from '../pages/Home/Home';

// Employee route import
import { CompanyDetails } from '../pages/Dashboard/Company/CompanyDetails';
import RolesPanel from '../pages/Dashboard/Company/CompanyRoles/RolesPanel';
import CreateNewRole from '../pages/Dashboard/Company/CompanyRoles/components/CreateNewRole';
import CreateNotifications from '../pages/Dashboard/Company/CreateNotifications';
import EmailSetting from '../pages/Dashboard/Company/EmailSetting';
import AllEmployees from '../pages/Dashboard/Company/Employees/AllEmployees';
import CreateEmployee from '../pages/Dashboard/Company/Employees/CreateEmployee';
import EmployeeDetail from '../pages/Dashboard/Company/Employees/EmployeeDetails/EmployeeDetail';
import UpdateEmployee from '../pages/Dashboard/Company/Employees/UpdateEmployee';
import ManageEmployeeDocs from '../pages/Dashboard/Company/Employees/subComponents/ManageEmployeeDocs';
import UploadEmployeeFile from '../pages/Dashboard/Company/Employees/subComponents/UploadEmployeeFile';
import ManageAttendence from '../pages/Dashboard/Company/ManageAttendence';
import NotificationPanel from '../pages/Dashboard/Company/NotificationPanel/NotificationPanel';
import OnBoarding from '../pages/Dashboard/Company/OnBoarding/OnBoarding';
import OnBoardingUE from '../pages/Dashboard/Company/OnBoarding/OnBoardingUE';
import Payroll from '../pages/Dashboard/Company/Payroll';
import UpdateData from '../pages/Dashboard/Company/UpdateData';
import UserDocPanel from '../pages/Dashboard/Company/UserDocPanel';
import UserDocumentsEmployee from '../pages/Dashboard/Company/UserDocumentsEmployee';
import VehicleDocPanel from '../pages/Dashboard/Company/VehicleDocPanel';
import AllVehicles from '../pages/Dashboard/Company/Vehicles/AllVehicles';
import CreateVehicle from '../pages/Dashboard/Company/Vehicles/CreateVehicle';
import EmployeeVehicleDetail from '../pages/Dashboard/Company/Vehicles/EmployeeVehicleDetail';
import UpdateVehicle from '../pages/Dashboard/Company/Vehicles/UpdateVehicle';
import WorkShiftPanel from '../pages/Dashboard/Company/WorkShift/WorkShiftPanel';
import AuthorizedEmployees from '../pages/Dashboard/Zones/Modal/AuthorizedEmployees';
import ShowDevices from '../pages/Dashboard/Zones/ShowDevices';
import SingleZoneDetails from '../pages/Dashboard/Zones/SingleZoneDetails';
import UpdateZone from '../pages/Dashboard/Zones/UpdateZone';
import Zones from '../pages/Dashboard/Zones/Zones';
import CreateUpdateDevices from '../pages/Dashboard/Devices/CreateUpdateDevices';
import DevicesPanel from '../pages/Dashboard/Devices/DevicesPanel';
import DevicesConfiguration from '../pages/Dashboard/Devices/DevicesConfiguration';
import BackUp from '../pages/Dashboard/BackUp/backUp';
import AddUserType from '../pages/Dashboard/Contractors/AddUserType';
import ElementDetailsType from '../pages/Dashboard/Contractors/ElementDetailsType';
import { CreateContractor } from '../pages/Dashboard/Contractors/CreateContract';
import EmployeeContractorDetails from '../pages/Dashboard/Contractors/EmployeeContractorDetails';
import UploadContractorFile from '../pages/Dashboard/Contractors/UploadContractorFile';
import ContractorApproveDocument from '../pages/Dashboard/Contractors/contractorApproveDocument';
import ContractorDetails from '../pages/Dashboard/Contractors/contractorDetails';
import ContractorPanel from '../pages/Dashboard/Contractors/contractorPanel';
import CreateNormalEvent from '../pages/Dashboard/Events/CreateNormalEvent';
import CreateOnuEvent from '../pages/Dashboard/Events/CreateOnuEvents/CreateOnuEvent';
import EventDetailIcomming from '../pages/Dashboard/Events/EventDetailIcomming';
import EventDetailValidation from '../pages/Dashboard/Events/EventDetailValidation';
import Events from '../pages/Dashboard/Events/Events';
import ApproveDocument from '../pages/Dashboard/Providers/ApproveDocument';
import { CreateOrder } from '../pages/Dashboard/Providers/CreateOrder';
import ProviderDetails from '../pages/Dashboard/Providers/ProviderDetails';
import EmployeeProvidersPanel from '../pages/Dashboard/Providers/ProvidersPanel';
import UploadProviderFile from '../pages/Dashboard/Providers/UploadProviderFile';
import VehicleProviderDetails from '../pages/Dashboard/Providers/VehicleProviderDetails';

// provider route import
import { useDispatch, useSelector } from 'react-redux';
import { permissionObj } from '../Helpers/permission';
import { HospitalityOutlet } from '../Outlet/Employee/HospitalityOutlet';
import Forbidden from '../components/Forbidden';
import EmployeContractDetail from '../pages/Contractor/Employee/EmployeContractDetail';

import EditProfile from '../pages/Contractor/Profile/EditProfile';
import AddVehicleDocuments from '../pages/Contractor/Vehicle/AddVehicleDocuments';
import VehicalContractDetail from '../pages/Contractor/Vehicle/VehicalContractDetail';
import AddUpdateHeaders from '../pages/Dashboard/Company/Companyheaders/AddUpdateHeaders';
import AddUpdateHospitality from '../pages/Dashboard/Company/Hospitality/AddUpdateHospitality';
import Hospitality from '../pages/Dashboard/Company/Hospitality/Hospitality';
import RoomDetail from '../pages/Dashboard/Company/Hospitality/RoomDetail';
import Department from '../pages/Dashboard/Department/Department';
import LoginOptions from '../pages/LoginOption/LoginOptions';
import CompleteOrder from '../pages/Provider/CompleteOrder';
import DocumentUpload from '../pages/Provider/DocumentUpload';
import OrderDetail from '../pages/Provider/OrderDetail';
import ProfileProvider from '../pages/Provider/ProfileProvider';
import ProviderOrderDetail from '../pages/Provider/ProviderDetailsApproveDocuments';
import ProvidersPanel from '../pages/Provider/ProvidersPanel';
import UserDocuments from '../pages/Provider/UserDocuments';
import VehicleDetail from '../pages/Provider/VehicleDetail';
import { contractorFile, supplierFile, employeeFile } from '../constant/userType';
import UserRestriction from '../pages/Dashboard/Restrictions/UserRestriction';
import DocumentRestriction from '../pages/Dashboard/Restrictions/DocumentRestriction';
import EventRestriction from '../pages/Dashboard/Restrictions/EventRestriction';
import RestrictionUsers from '../pages/Dashboard/Restrictions/RestrictionUsers';
import Access from '../pages/Dashboard/Access/Access';
import Logs from '../pages/Dashboard/Logs/Logs';
import Guard from '../pages/Dashboard/Guard/Guard';
import DownloadApk from '../pages/appDownload/DownloadApk';
import Holiday from '../pages/Holiday/Holiday';
import CardsPanel from '../pages/Cards/CardsPanel';
import WorkOffice from '../pages/WorkOffice/WorkOffice';
import CardDesignerPanel from '../pages/CardDesigner/CardDesignerPanel';
import { CreateTemplate } from '../pages/CardDesigner/CreateTemplate';
import VeridasOnboarding from '../pages/Veridas/VeridasOnboarding';
import ValidatedFail from '../pages/Veridas/FailValidation/ValidatedFail';
import SelfiePage from '../pages/Veridas/Integracion/Selfie/SelfiePage';
import DocumentPage from '../pages/Veridas/Integracion/Document/DocumentPage';
import ValidatedIdentity from '../pages/Veridas/FailValidation/ValidatedIdentity';
import SuccessValidation from '../pages/Veridas/SuccessValidation/SuccessValidation';
import SuccessValidationForm from '../pages/Veridas/SuccessValidation/SuccessValidationForm';
import BrowserCheck from '../pages/Veridas/browserEvent/BrowserCheck';
import UserNotification from '../pages/Veridas/Notification/UserNotification';
import LeaveConfirmation from '../pages/Veridas/browserEvent/Browserclose';
import Controller from '../pages/Dashboard/Controller/Controllers/Controller';
import Readers from '../pages/Dashboard/Controller/Readers/Readers';
import SIOS from '../pages/Dashboard/Controller/SIOs/SIOS';
import Inputs from '../pages/Dashboard/Controller/Inputs/Inputs';
import Outputs from '../pages/Dashboard/Controller/Outputs/Outputs';
import CreateController from '../pages/Dashboard/Controller/Controllers/CreateController';
import ControllerDetails from '../pages/Dashboard/Controller/Controllers/ControllerDetails';
import CreationErrorsContractor from '../pages/Modals/CreationErrorsContractor';
import CreationErrorsModal from '../pages/Modals/CreationErrorsModal';
import CreationErrorsSupplier from '../pages/Modals/CreationErrorsSupplier';
import CreationErrorsContractorEmployee from '../pages/Modals/CreationErrorsContractorEmployee';
import CreationErrorsContractorVehicle from '../pages/Modals/CreationErrorsContractorVehicle';
import CreationErrorsSuplierEmployee from '../pages/Modals/CreationErrorsSuplierEmployee';
import CreationErrorsSuplierVehicle from './../pages/Modals/CreationErrorsSuplierVehicle';
import CreationErrorsVehicle from '../pages/Modals/CreationErrorsVehicle';
import CreationErrorsEmployee from '../pages/Modals/CreationErrorsEmployee';
import AccessRights from '../pages/Dashboard/Contractors/AccessRights';
import GraphsPanel from '../pages/Graphs/GraphsPanel';
import DataProcessingAgreement from '../pages/Legal/DataProcessingAgreement';
import CompanyPrivacyPolicy from '../pages/Legal/CompanyPrivacyPolicy';
import RightToErasureRequestForm from '../pages/Legal/RightToErasureRequestForm';
import ValidationFailNoExist from '../pages/Veridas/FailValidation/ValidationFailNoExist';

const MainRoutes = () => {
    const token = sessionStorage?.getItem('bearerToken') || '';
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (
            (token &&
                !permission?.includes(permissionObj?.WEB_COMPANY_READ) &&
                location?.pathname == '/dashboard/employee/company') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_EVENT_READ) &&
                location?.pathname == '/dashboard/employee/events') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_ZONE_READ) &&
                location?.pathname == '/dashboard/employee/zones') ||
            /*   (token &&
              /*   !permission?.includes(permissionObj?.WEB_HID_READ) && */
            /*             location?.pathname == '/dashboard/employee/controller') || */
            (token &&
                !permission?.includes(permissionObj?.WEB_DEVICE_READ) &&
                location?.pathname == '/dashboard/employee/devices') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_DEPARTMENT_READ) &&
                location?.pathname == '/dashboard/employee/departments') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_EMPLOYEE_READ) &&
                location?.pathname == '/dashboard/employee/all-employees') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_LOG_PLATFORM_READ) &&
                location?.pathname == '/dashboard/employee/logs') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_VEHICLE_READ) &&
                location?.pathname == '/dashboard/employee/vehicle-fleet') ||
            (token &&
                !(
                    (permission && permission?.includes(permissionObj?.WEB_CONTRACTOR_READ)) ||
                    permission?.includes(permissionObj?.WEB_CONTRACTOR_CONTRACT_READ)
                ) &&
                location?.pathname == '/dashboard/employee/contractors') ||
            (token &&
                !(
                    (permission && permission?.includes(permissionObj?.WEB_SUPPLIER_READ)) ||
                    permission?.includes(permissionObj?.WEB_SUPPLIER_ORDER_READ)
                ) &&
                location?.pathname == '/dashboard/employee/suppliers') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_ATTENDANCE_READ) &&
                location?.pathname == '/dashboard/employee/attendance') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_BACK_UP_READ) &&
                location?.pathname == '/dashboard/employee/backup') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_HOSPITALITY_MENU) &&
                location?.pathname == '/dashboard/employee/hospitality') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_COMPANY_READ) &&
                location?.pathname == '/dashboard/employee/company/update-data') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_WORK_SHIFT_READ) &&
                location?.pathname == '/dashboard/employee/company/workshift-panel') ||
            (token &&
                !(
                    (permission && permission?.includes(permissionObj?.WEB_EMPLOYEE_DOCUMENT_READ)) ||
                    permission?.includes(permissionObj?.WEB_SUPPLIER_DOCUMENT_READ) ||
                    permission?.includes(permissionObj?.WEB_CONTRACTOR_DOCUMENT_READ)
                ) &&
                location?.pathname == '/dashboard/employee/company/user-doc-panel') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_PRIVILEGE_READ) &&
                location?.pathname == '/dashboard/employee/company/roles-panel') ||
            (token &&
                !(
                    (permission && permission?.includes(permissionObj?.WEB_SUPPLIER_VEHICLE_DOCUMENT_READ)) ||
                    permission?.includes(permissionObj?.WEB_CONTRACTOR_VEHICLE_DOCUMENT_READ)
                ) &&
                location?.pathname == '/dashboard/employee/company/vehicle-doc-panel') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_EMAIL_SETTINGS_READ) &&
                location?.pathname == '/dashboard/employee/attendance/email-setting') ||
            // token && !permission?.includes(permissionObj?.WEB_PROFILE_READ) && location?.pathname == "/dashboard/employee/profile" ||
            (token &&
                !permission?.includes(permissionObj?.WEB_ONBOARDING_READ) &&
                location?.pathname == '/dashboard/employee/company/onboarding') ||
            // devices
            (token &&
                !permission?.includes(permissionObj?.WEB_DEVICE_CREATE) &&
                location?.pathname == '/dashboard/employee/devices/create') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_DEVICE_UPDATE) &&
                location?.pathname == '/dashboard/employee/devices/update/:id') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_ZONE_CREATE_DEVICES) &&
                location?.pathname == '/dashboard/employee/zones/create-device') ||
            (token &&
                !permission?.includes(permissionObj?.WEB_ZONE_UPDATE_DEVICES) &&
                location?.pathname == '/dashboard/employee/zones/update-device/:id') ||
            //
            // !permission.includes(permissionObj?.WEB_EMPLOYEE_CREATE) && location?.pathname == "/dashboard/employee/all-employees/add-employee"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/allvehicles/create-vehicle"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/zones/showdevices"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/zones/updatezone"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/zones/create-device"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/events/onu-events"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/events/incomming-envent-detail/:id"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/all-employees/employee-Detail/:id"||
            (token &&
                !permission.includes(permissionObj?.WEB_EMPLOYEE_UPDATE) &&
                location?.pathname == '/dashboard/employee/all-employees/update-employee/:id')
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/all-employees/uploademployeefile"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/allVehicles/vehicle-detail/:id"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/allVehicles/update-vehicle/:id"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/payroll/manage-attendence"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/hospitality/add-room"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/hospitality/room-detail"||

            // contractor
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/contractors/create-contract"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/contractors/contractor-details"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/contractors/update-contract"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/contractors/upload-contractor"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/contractors/add-contractor"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/contractors/update-contractor"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/contractors/contractor-detail"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/contractors/contractor-detail"||

            // provider
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/suppliers/create-order"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/suppliers/order-details"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/suppliers/upload-supplier"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/suppliers/add-suppliers"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/suppliers/approve-documents"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/suppliers/update-suppliers"||
            // !permission.includes(permissionObj?.WEB_ONBOARDING_MENU) && location?.pathname == "/dashboard/employee/suppliers/suppliers_detail_page"||
            // /dashboard/employee/hospitality/update-room
        ) {
            navigate('/forbidden');
        }
    }, [location]);

    return (
        <Routes>
            {/* legal-documents route */}
            <Route path='data-processing-agreement.pdf' element={<DataProcessingAgreement />} />
            <Route path='company-privacy-policy.pdf' element={<CompanyPrivacyPolicy />} />
            <Route path='right-to-erasure-request-form.pdf' element={<RightToErasureRequestForm />} />
            {/* Visitor route */}
            <Route path='verify-visitor/:userId' element={<VeridasOnboarding />} />
            <Route path='document-validation' element={<DocumentPage />} />
            <Route path='validation-fail' element={<ValidatedFail />} />
            <Route path='success-validation' element={<SuccessValidation />} />
            <Route path='user-id-not-exist' element={<ValidationFailNoExist />} />
            <Route path='selfie-validation' element={<SelfiePage />} />
            <Route path='unsupported-browser' element={<BrowserCheck />} />
            {/* dashboard */}
            {/* protected route */}
            <Route element={<RequireAuth allowedRoles={['EMPLOYEE', 'SUPPLIER_IN_CHARGE', 'CONTRACTOR_IN_CHARGE']} />}>
                <Route path='/dashboard' element={<DashboardLayout />}>
                    <Route path='announcement-panel' element={<NotificationPanel />} />

                    {/* employee route start*/}
                    <Route element={<RequireAuth allowedRoles={['EMPLOYEE']} />}>
                        <Route path='employee' element={<EmployeeLayout />}>
                            <Route path='user-Documents' element={<UserDocuments docType='employee' />} />
                            {/* @company */}

                            <Route path='announcement-panel' element={<NotificationPanel />} />
                            <Route path='profile' element={<ProfileProvider />} />
                            <Route index path='company' element={<CompanyDetails />} />

                            <Route path='company' element={<CompanyOutlet />}>
                                <Route path='user-documents' element={<UserDocumentsEmployee />} />
                                <Route path='user-doc-panel' element={<UserDocPanel />} />
                                <Route path='update-data' element={<UpdateData />} />
                                <Route path='workshift-panel' element={<WorkShiftPanel />} />
                                <Route path='roles-panel' element={<RolesPanel />} />
                                <Route path='add-new-role' element={<CreateNewRole />} />
                                <Route path='add-update-role/:id' element={<CreateNewRole />} />
                                {/* <Route path="add-update-role/:id" element={<AddUpdateRole />} /> */}
                                <Route path='vehicle-doc-panel' element={<VehicleDocPanel />} />
                                {/* <Route
                                        path="notification-panel"
                                        element={<NotificationPanel />}
                                        /> */}
                                <Route path='create-announcement' element={<CreateNotifications />} />

                                {/* onboarding */}
                                <Route path='onboarding' element={<OnBoarding />} />
                                <Route path='onboarding-UE/:id' element={<OnBoardingUE />} />

                                {/* manage headers */}
                                <Route path='headers' element={<AddUpdateHeaders />} />
                            </Route>
                            {/* @zone */}
                            <Route index path='zones' element={<Zones />} />
                            <Route path='zones' element={<ZoneOutlet />}>
                                <Route path='singlezonedetails' element={<SingleZoneDetails />} />
                                <Route path='showdevices' element={<ShowDevices />} />
                                <Route path='authmodal' element={<AuthorizedEmployees />} />
                                <Route path='updatezone' element={<UpdateZone />} />
                                <Route path='create-device' element={<CreateUpdateDevices />} />
                                <Route path='update-device/:id' element={<CreateUpdateDevices />} />
                            </Route>

                            {/* @devices */}
                            <Route index path='devices' element={<DevicesPanel />} />
                            <Route path='devices' element={<DevicesOutlet />}>
                                <Route path='configuration' element={<DevicesConfiguration />} />
                                <Route path='create' element={<CreateUpdateDevices />} />
                                <Route path='update/:id' element={<CreateUpdateDevices />} />
                            </Route>

                            {/* @cards */}
                            <Route index path='cards' element={<CardsPanel />} />
                            <Route path='cards' element={<CardsOutlet />}>
                                <Route path='create' element={<CreateUpdateDevices />} />
                            </Route>
                            {/* @graphs */}
                            <Route index path='graphs' element={<GraphsPanel />} />
                            <Route path='graphs' element={<GraphsOutlet />}></Route>

                            {/* @event */}
                            <Route path='events' element={<Events />} />
                            <Route path='events' element={<EventOutlet />}>
                                {/* <Route path="events" element={<Events />} /> */}
                                <Route path='normal-events' element={<CreateNormalEvent />} />
                                <Route path='onu-events' element={<CreateOnuEvent />} />
                                <Route path='incomming-envent-detail/:id' element={<EventDetailIcomming />} />
                                <Route path='validation-event-detail/:id' element={<EventDetailValidation />} />
                            </Route>
                            {/* @controller */}
                            <Route path='controller' element={<Controller />} />
                            <Route path='controller' element={<ControllerOutlet />}>
                                <Route path='create-controller' element={<CreateController />} />
                                <Route path='controller-detail/:id' element={<ControllerDetails />} />
                                {/*   aqui lo de el controller */}
                            </Route>
                            {/* @Readers */}
                            <Route path='readers' element={<Readers />} />
                            <Route path='readers' element={<ReadersOutlet />}>
                                {/*   aqui lo de el controller */}
                            </Route>
                            {/* @SIOs */}
                            <Route path='sios' element={<SIOS />} />
                            <Route path='sios' element={<SIOsOutlet />}>
                                {/*   aqui lo de el controller */}
                            </Route>
                            {/* @Inputs */}
                            <Route path='inputs' element={<Inputs />} />
                            <Route path='inputs' element={<InputsOutlet />}>
                                {/*   aqui lo de el controller */}
                            </Route>
                            {/* @Outputs */}
                            <Route path='outputs' element={<Outputs />} />
                            <Route path='outputs' element={<OutputsOutlet />}>
                                {/*   aqui lo de el controller */}
                            </Route>

                            {/* @employees */}
                            <Route path='all-employees' element={<AllEmployees />} />
                            <Route path='all-employees' element={<EmployeeOutlet />}>
                                <Route path='employee-Detail/:id' element={<EmployeeDetail />} />
                                <Route path='add-employee' element={<CreateEmployee />} />
                                <Route path='errors' element={<CreationErrorsEmployee />} />
                                <Route path='update-employee/:id' element={<UpdateEmployee />} />
                                <Route path='uploademployeefile' element={<UploadEmployeeFile />} />
                                <Route path='employee-docs' element={<ManageEmployeeDocs approveDocument={false} />} />
                                <Route
                                    path='employee-docs-complete'
                                    element={<ManageEmployeeDocs approveDocument={true} />}
                                />
                            </Route>
                            {/* @Vehicle */}
                            <Route path='vehicle-fleet' element={<AllVehicles />} />
                            <Route path='vehicle-fleet' element={<VehicleOutlet />}>
                                <Route path='create-vehicle' element={<CreateVehicle />} />
                                <Route path='errors' element={<CreationErrorsVehicle />} />
                                <Route path='vehicle-detail/:id' element={<EmployeeVehicleDetail />} />
                                <Route path='update-vehicle/:id' element={<UpdateVehicle />} />
                            </Route>
                            {/* @Contractor */}
                            <Route index path='contractors' element={<ContractorPanel />} />
                            <Route path='contractors' element={<ContractorEmployeeOutlet />}>
                                {/* <Route path="contractor-panel" element={<ContractorPanel />} /> */}
                                <Route path='create-contract' element={<CreateContractor />} />
                                <Route
                                    path='add-contractor'
                                    element={<AddUserType userType={contractorFile} isUpdate={false} />}
                                />
                                <Route
                                    path='update-contractor/:id'
                                    element={<AddUserType userType={contractorFile} isUpdate={true} />}
                                />
                                <Route path='employee-contractor-details' element={<EmployeeContractorDetails />} />
                                <Route
                                    path='contractor-approve-document/:id'
                                    // element={<ApproveDocument />}
                                    element={<ContractorApproveDocument />}
                                />
                                <Route path='upload-contractor' element={<UploadContractorFile />} />
                                <Route path='errors' element={<CreationErrorsContractor />} />
                                <Route path='contractor-details' element={<ContractorDetails />} />
                                <Route
                                    path='contractor-detail/:id'
                                    // element={<ContractorDetail />}
                                    element={<ElementDetailsType />}
                                />

                                <Route path='vehicle-detail' element={<VehicleProviderDetails />} />
                                <Route
                                    path='vehicleDetail/:id'
                                    element={<ElementDetailsType isContractVehicle={true} />}
                                />
                                {/* <Route path="update-contract" element={<UpdateContract />} /> */}
                                <Route path='update-contract' element={<CreateContractor isUpdate={true} />} />

                                <Route
                                    path='employeeDetail/:id'
                                    element={<ElementDetailsType isContractEmployee={true} />}
                                />
                            </Route>
                            {/* @supplier */}
                            <Route index path='suppliers' element={<EmployeeProvidersPanel />} />
                            <Route path='suppliers' element={<ProviderEmployeeOutlet />}>
                                <Route path='create-order' element={<CreateOrder />} />
                                <Route path='upload-contractor' element={<UploadContractorFile />} />
                                <Route path='errors' element={<CreationErrorsSupplier />} />
                                <Route path='order-details' element={<OrderDetail />} />
                                <Route path='vehicle-detail' element={<VehicleProviderDetails />} />
                                <Route
                                    path='add-suppliers'
                                    element={<AddUserType userType={supplierFile} isUpdate={false} />}
                                />
                                <Route
                                    path='update-suppliers/:id'
                                    element={<AddUserType userType={supplierFile} isUpdate={true} />}
                                />
                                <Route path='employee-suppliers-details' element={<ProviderDetails />} />
                                <Route path='approve-documents/:id' element={<ApproveDocument />} />
                                <Route path='upload-supplier' element={<UploadProviderFile />} />
                                <Route path='suppliers_detail_page/:id' element={<ElementDetailsType />} />
                                <Route
                                    path='vehicles-details/:id'
                                    element={<VehicleDetail approveDocumentVehicle={false} />}
                                />
                                <Route
                                    path='supplier-order-detail/:id'
                                    element={<ProviderOrderDetail approveDocument={false} />}
                                />
                            </Route>

                            {/* @attendance */}
                            <Route path='attendance' element={<Payroll />} />
                            <Route path='attendance' element={<PayrollOutlet />}>
                                <Route path='manage-attendence' element={<ManageAttendence />} />
                                <Route path='email-setting' element={<EmailSetting />} />
                            </Route>
                            {/* @back up */}
                            <Route path='backup' element={<BackUp />} />
                            {/* @access */}
                            <Route path='access' element={<Access />} />
                            {/* @logs */}
                            <Route path='logs' element={<Logs />} />
                            {/* @logs */}
                            <Route path='guard' element={<Guard />} />
                            {/* holiday */}
                            <Route path='holiday' element={<Holiday />} />
                            {/* work-office */}
                            <Route path='work-office' element={<WorkOffice />} />
                            {/* card-designer */}
                            <Route path='card-designer' element={<CardDesignerPanel />} />
                            <Route path='card-designer' element={<CardDesignerOutlet />}>
                                <Route path='create-template' element={<CreateTemplate />} />
                                <Route path='email-setting' element={<EmailSetting />} />
                            </Route>
                            {/* @hospitality */}
                            <Route path='hospitality' element={<Hospitality />} />
                            <Route path='hospitality' element={<HospitalityOutlet />}>
                                <Route path='add-room' element={<AddUpdateHospitality />} />
                                <Route path='room-detail' element={<RoomDetail />} />
                            </Route>
                            {/* @Departments */}
                            <Route path='departments' element={<Department />} />

                            {/* Restriction  */}
                            <Route path='user-restriction' element={<UserRestriction />} />
                            <Route path='document-restriction' element={<DocumentRestriction />} />
                            <Route path='event-restriction' element={<EventRestriction />} />
                            <Route
                                path='contractor-restriction'
                                element={<RestrictionUsers userType={contractorFile} />}
                            />
                            <Route path='supplier-restriction' element={<RestrictionUsers userType={supplierFile} />} />
                        </Route>
                    </Route>

                    {/* @Provider route start */}
                    <Route element={<RequireAuth allowedRoles={['SUPPLIER_IN_CHARGE']} />}>
                        <Route index path='supplier' element={<ProvidersPanel />} />
                        <Route path='supplier' element={<ProviderLayout />}>
                            <Route path='orders' element={<EmployeeProvidersPanel />} />
                            <Route path='employees' element={<AllEmployees />} />
                            <Route path='vehicles' element={<AllVehicles />} />
                            <Route path='errors-employee' element={<CreationErrorsSuplierEmployee />} />
                            <Route path='errors-vehicle' element={<CreationErrorsSuplierVehicle />} />
                            <Route // esto es pra verd detalles de orden de vehiculo
                                path='vehicles-details/:id'
                                element={<VehicleDetail approveDocumentVehicle={false} />}
                            />
                            <Route path='vehicle-documents' element={<VehicleDetail approveDocumentVehicle={true} />} />{' '}
                            <Route path='complete-order' element={<CompleteOrder isUpdateOrder={false} />} />
                            <Route path='update-order' element={<CompleteOrder isUpdateOrder={true} />} />
                            <Route //esto es para ver los detalles de la orden del empleado
                                path='supplier-order-detail/:id'
                                element={<ProviderOrderDetail approveDocument={false} />}
                            />
                            <Route //la otra ruta alterna
                                path='complete-document/:id'
                                element={<ProviderOrderDetail approveDocument={true} />}
                            />
                            <Route path='order-detail' element={<OrderDetail />} />
                            <Route
                                path='create-employee'
                                element={<AddUserType userType={supplierFile} isEmployee={true} isUpdate={false} />}
                            />
                            <Route path='add-vehicles' element={<CreateVehicle isUpdate={false} />} />
                            <Route path='update-vehicles/:id' element={<CreateVehicle isUpdate={true} />} />
                            <Route path='user-Documents' element={<UserDocuments docType='supplier' />} />
                            <Route
                                path='update-employee/:id'
                                element={<AddUserType userType={supplierFile} isUpdate={true} isEmployee={true} />}
                            />
                            <Route path='upload-doc' element={<DocumentUpload />} />
                            <Route path='announcements' element={<NotificationPanel />} />
                            <Route path='profile' element={<ProfileProvider />} />
                        </Route>
                    </Route>

                    {/* contrator route start */}
                    <Route element={<RequireAuth allowedRoles={['CONTRACTOR_IN_CHARGE']} />}>
                        <Route path='contractor' element={<ContratorLayout />}>
                            <Route path='profile' element={<ProfileProvider />} />
                            <Route path='access-rights' element={<AccessRights />} />
                            <Route path='announcementtab' element={<NotificationPanel />} />
                            <Route path='edit-profile/:id' element={<EditProfile />} />
                            <Route path='addvehical' element={<CreateVehicle isUpdate={false} />} />
                            <Route path='update-vehicle/:id' element={<CreateVehicle isUpdate={true} />} />
                            <Route path='search-vehicle' element={<AllVehicles />} />
                            <Route path='search-employe' element={<AllEmployees />} />
                            <Route path='errors-employee' element={<CreationErrorsContractorEmployee />} />
                            <Route path='errors-vehicle' element={<CreationErrorsContractorVehicle />} />
                            <Route
                                path='add-new-employe'
                                element={<AddUserType isUpdate={false} isEmployee={true} userType={contractorFile} />}
                            />
                            <Route
                                path='update-employee/:id'
                                element={<AddUserType isUpdate={true} isEmployee={true} userType={contractorFile} />}
                            />
                            <Route path='add-vehicle-docs' element={<AddVehicleDocuments />} />
                            <Route
                                path='employee-contract-detail/:id'
                                element={<EmployeContractDetail approveDocument={false} />}
                            />
                            <Route
                                path='vehicle-contract-detail/:id'
                                element={<VehicalContractDetail approveDocumentVehicle={false} />}
                            />
                            <Route
                                path='upload-vehicle-documents/:id'
                                element={<VehicalContractDetail approveDocumentVehicle={true} />}
                            />
                            <Route path='user-contract-detail/:id' element={<ContractorDetails />} />
                            <Route path='contracts' element={<ContractorPanel />} />
                            <Route
                                path='employee-upload-documets/:id'
                                element={<EmployeContractDetail approveDocument={true} />}
                            />
                            <Route path='user-Documents' element={<UserDocuments docType='contractor' />} />
                        </Route>
                    </Route>
                </Route>
                {/* other prtected route */}
                <Route path='/login-option' element={<LoginOptions />} />:
            </Route>
            {/* other public route */}
            <Route path='/' element={<Home />} />
            <Route path='/*' element={<NotFound />} />
            <Route path='/unauthorized' element={<Unauthorized />} />
            <Route path='/forbidden' element={<Forbidden />} />
            <Route path='/download-apk' element={<DownloadApk />} />
        </Routes>
    );
};

export default MainRoutes;
