import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { errorCode } from '../../enums/errorCodeEnum';

const providersSlice = createSlice({
    name: 'providers',
    initialState: {
        getProvidersByUserId: {},
        getProvidersIncoming: {},
        getProvidersRecord: {},
        providerslistOfEmployees: {},
        getProvidersEmployeeSortList: [],
        createProviderUserRelationship: {},
        uploadProviderImage: {},
        getProviderEmployeeDetail: {},
        getAllStatusProvider: [],
        getSingleProvider: {},
        updateProviderData: {},
        checkProviderImage: {},
        getProviderImage: {},
        downloadProviderImage: {},
        downloadCompanyFile: {},
        createToExternal: {},
        setToExternal: {},
        providerVehicleSortList: [],
        providerlistOfVehicles: {},
        createVehicleAndRelationSupplier: {},
        getProviderVehicleDetail: {},
        getSingleProviderVehicle: {},
        checkProviderVehicleImage: {},
        getProviderVehicleImage: {},
        downloadProviderVehicleImage: {},
        getProviderVehicleStatus: [],
        UpdateProviderVehicleData: {},
        downloadCompanyVehicleFile: {},
        createToExternalVehicle: {},
        setToExternalVehicle: {},
        getOrderDetails: {},
        getAllProviderEmployeeListDown: [],
        getAllProviderVehicleListDown: [],
        getSingleUserProvider: {},
        getUserDocuments: {},
        getUserExtraData: {},
        getUserCompanyRestrictionData: {},
        getAllSupplierDocumentsById: [],
        createSupplierDocValue: {},
        setSupplierDocValue: {},
        saveProviderVehicleImage: {},
        saveProviderImage: {},
        imageChangeCheck: '',
        getSupplierInfoById: {},
        getSupplierStatus: [],
    },
    reducers: {
        ClearGetSupplierInfoById: (state, action) => {
            state.getSupplierInfoById = {};
            state.getSingleProviderVehicle = {};
        },
    },
    extraReducers: {
        // order section
        'providers/getProvidersByUserId/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProvidersByUserId = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getProvidersIncoming/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProvidersIncoming = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        // get supplier status
        'providers/getSupplierStatus/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getSupplierStatus = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'providers/getProvidersRecord/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProvidersRecord = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getOrderDetails/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getOrderDetails = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getAllProviderVehicleListDown/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getAllProviderVehicleListDown = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/getAllProviderEmployeeListDown/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getAllProviderEmployeeListDown = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        // employees section

        'providers/providerslistOfEmployees/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.providerslistOfEmployees = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        // get supplier by id
        'contractor/getSupplierInfoById/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getSupplierInfoById = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'providers/getProvidersEmployeeSortList/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProvidersEmployeeSortList = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/createProviderUserRelationship/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                state.createProviderUserRelationship = data?.data;
                toast.success(t('has_been_created_successfully'));
            } else if (status >= 400 && status < 600) {
                if (!(data?.code && errorCode.includes(data?.code))) {
                    toast.error(t('error_creating'));
                }
            }
        },
        'providers/uploadProviderImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.uploadProviderImage = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/getProviderEmployeeDetail/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProviderEmployeeDetail = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/getAllStatusProvider/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getAllStatusProvider = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getSingleProvider/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.getSingleProvider = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'providers/updateProviderData/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                state.updateProviderData = data?.data;
                toast.success(t('has_been_updated_successfully'));
            } else if (status >= 400 && status < 600) {
                if (!(data?.code && errorCode.includes(data?.code))) {
                    toast.error(t('error_updating'));
                }
            }
        },

        'providers/checkProviderImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.checkProviderImage = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getProviderImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProviderImage = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/downloadProviderImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.downloadProviderImage = data;
            } else if (status >= 400 && status < 500) {
                toast.error(t('error_downloading'));
            }
        },
        'providers/downloadCompanyFile/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.downloadCompanyFile = data;
            } else if (status >= 400 && status < 500) {
            }
        },

        // createToExternal

        'providers/createToExternal/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createToExternal = data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'providers/setToExternal/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.setToExternal = data;
            } else if (status >= 400 && status < 500) {
            }
        },

        // vehicle section slice
        'providers/providerlistOfVehicles/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.providerlistOfVehicles = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/providerVehicleSortList/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.providerVehicleSortList = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/createVehicleAndRelationSupplier/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createVehicleAndRelationSupplier = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'providers/checkProviderVehicleImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.checkProviderVehicleImage = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getProviderVehicleImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProviderVehicleImage = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/downloadProviderVehicleImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.downloadProviderVehicleImage = data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getSingleProviderVehicle/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getSingleProviderVehicle = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/getProviderVehicleDetail/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProviderVehicleDetail = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/getProviderVehicleStatus/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getProviderVehicleStatus = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/UpdateProviderVehicleData/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.UpdateProviderVehicleData = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/saveProviderVehicleImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.saveProviderVehicleImage = data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/downloadCompanyVehicleFile/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.downloadCompanyVehicleFile = data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        // createToExternalVehcile

        'providers/createToExternalVehicle/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.createToExternalVehicle = data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'providers/setToExternalVehicle/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                state.setToExternalVehicle = data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'providers/saveProviderImage/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                const change = 0;
                state.saveProviderImage = data;
                state.imageChangeCheck = change + 1;
            } else if (status >= 400 && status < 500) {
            }
        },

        // getUserDocuments  for user document

        'providers/getUserDocuments/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                // toast.success("set value successfully")
                state.getUserDocuments = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getUserExtraData/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                // toast(data.message)
                // toast.success("set value successfully")
                state.getUserExtraData = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },
        'providers/getUserCompanyRestrictionData/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                // toast(data.message)
                // toast.success("set value successfully")
                state.getUserCompanyRestrictionData = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getSingleUserProvider/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                // toast(data.message)
                state.getSingleUserProvider = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast("Fail to fetch Zone Devices Lists")
            }
        },

        'providers/getAllSupplierDocumentsById/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                state.getAllSupplierDocumentsById = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },

        'providers/setSupplierDocValue/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                state.setSupplierDocValue = data;
            } else if (status >= 400 && status < 500) {
            }
        },
        'providers/createSupplierDocValue/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};

            if (status >= 200 && status < 300) {
                state.createSupplierDocValue = data?.data;
            } else if (status >= 400 && status < 500) {
            }
        },
    },
});

export const { ClearGetSupplierInfoById } = providersSlice.actions;

export default providersSlice.reducer;
