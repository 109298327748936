export const errorCodes = {
    2020: 'NO_DATA_FOUND_IN_THE_FILE',
    2006: 'DOCUMENT_ID_CAN_NOT_BE_NULL_OR_EMPTY',
    2021: 'NAME_CAN_NOT_BE_EMPTY_OR_NULL',
    2022: 'LAST_NAME_CAN_NOT_BE_EMPTY_OR_NULL',
    2023: 'EMAIL_CAN_NOT_BE_EMPTY_OR_NULL',
    2024: 'PHONE_NUMBER_CAN_NOT_BE_EMPTY_OR_NULL',
    2025: 'ACRONYM_CAN_NOT_BE_EMPTY_OR_NULL',
    2026: 'CONTRACTOR_COMPANY_NAME_CAN_NOT_BE_EMPTY_OR_NULL',
    2027: 'GENDER_IS_NOT_VALID',
    2028: 'DUPLICATE_EMAIL',
    2029: 'DUPLICATE_PHONE_NUMBER',
    2030: 'DATE_FORMAT_IS_NOT_VALID',
    2031: 'SUPPLIER_COMPANY_NAME_CAN_NOT_BE_EMPTY_OR_NULL',
    2032: 'BRAND_CAN_NOT_BE_EMPTY',
    2033: 'SUB_BRAND_CAN_NOT_BE_EMPTY',
    2034: 'MODEL_CAN_NOT_BE_EMPTY',
    2035: 'COLOR_CAN_NOT_BE_EMPTY',
    2036: 'PLATE_CAN_NOT_BE_EMPTY',
    2037: 'MODEL_IS_NOT_VALID',
    2038: 'DUPLICATE_PLATE',
    2039: 'DUPLICATE_SERIAL_NUMBER',
    2040: 'EMPLOYEE_ID_CAN_NOT_BE_EMPTY',
    2041: 'ROLE_CAN_NOT_BE_EMPTY',
    2042: 'START_DATE_CAN_NOT_BE_EMPTY',
    2043: 'ROLE_IS_NOT_VALID',
    2044: 'DEPARTMENT_IS_NOT_VALID',
    2045: 'ZONE_IS_NOT_VALID',
    2046: 'DUPLICATE_EMPLOYEE_ID',
    2047: 'MODEL_OUT_OF_RANGE',
    802: 'PHONE_NUMBER_IS_NOT_CORRECT',
    1020: 'email_already_registered_please_use_another',
    1021: 'phone_number_already_registered_please_use_another',
};
