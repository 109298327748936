import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import cryptoJs from 'crypto-js';
import securekey from '../../config';

export const authenticationSlice = createSlice({
    name: 'authenticationSlice',
    reducers: {
        logOut: (state) => {
            state.user = {};
            sessionStorage.removeItem('userdata');
            sessionStorage.removeItem('bearerToken');
            sessionStorage.removeItem('companyId');
            sessionStorage.removeItem('deleteId');
            sessionStorage.removeItem('cId');
            sessionStorage.removeItem('pid');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('singlezoneId');
            sessionStorage.removeItem('providerId');
            sessionStorage.removeItem('contractorId');
            sessionStorage.removeItem('provideridfordetail');
            sessionStorage.removeItem('vehicleidfordetail');
            sessionStorage.removeItem('onBoardingRoleName');
            sessionStorage.removeItem('lng');
            sessionStorage.removeItem('lat');
            sessionStorage.removeItem('onBoardingRoleId');
            sessionStorage.removeItem('providerOrderDetail');
            sessionStorage.removeItem('employeeProviderDetail');
            sessionStorage.removeItem('vehicleProviderDetail');
            state.permission = [];
        },
    },
    initialState: {
        user: {},
        permission: [],
        getUserByIdAtEntry: {},
    },
    extraReducers: {
        'authenticationSlice/tokenApi/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.user = data;
            } else if (status >= 400 && status < 500) {
                toast.error(data?.message);
            }
        },
        'authenticationSlice/roleCheck/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                state.permission = data?.data.map((item) => {
                    return item.id;
                });

                // sessionStorage.setItem("premission", state.roleCheck)
            } else if (status >= 400 && status < 500) {
                // toast.error(data?.message)
            }
        },
        'authenticationSlice/loginMiddleware/fulfilled': (state, { payload }) => {
            state.user = payload;
        },

        'authenticationSlice/getUserByIdAtEntry/fulfilled': (state, action) => {
            const { data, status } = action.payload || {};
            if (status >= 200 && status < 300) {
                const employeeEntryData = cryptoJs.AES.encrypt(JSON.stringify(data?.data), securekey);
                sessionStorage.setItem('employeeEntryData', employeeEntryData);
                state.getUserByIdAtEntry = data?.data;
            } else if (status >= 400 && status < 500) {
                // toast.error(data?.message)
            }
        },
    },
});

export const { logOut, getUserByIdAtEntry } = authenticationSlice.actions;
export const userDetail = (state) => state?.authenticationSlice?.user;
export default authenticationSlice.reducer;
