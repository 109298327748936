import es from 'react-phone-input-2/lang/es.json';
import fr from 'react-phone-input-2/lang/fr.json';
import ar from 'react-phone-input-2/lang/ar.json';

const phoneInputLanguage = (lCode) => {
    switch (lCode) {
        case 'sp':
            return es;
        case 'fr':
            return fr;
        case 'ar':
            return ar;
        default:
            return '';
    }
};

export default phoneInputLanguage;
