import { t } from 'i18next';
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ReusableTextField from '../../../../components/ReusableTextField ';

const CreateOutput = (props) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const [workshiftName, setWorkShiftName] = useState('');
    const [nameError, setNameError] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false);

    const textField = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& 	.MuiFormLabel-filled': {
            marginTop: '-5px',
        },
    };

    // use hook importer
    const dispatch = useDispatch();

    //
    const handleNameChange = (value) => {
        // Perform validation logic
        if (value === '') {
            setNameError('name cannot be empty');
        } else {
            setNameError('');
        }

        setWorkShiftName(value);
    };

    // create work shift
    const handleSubmit = () => {
        /* if (props?.isUpdate) {
          const payload = {
              id: props?.data?.id,
              name: workshiftName,
          };
          if (workshiftName) {
              dispatch(UpdateWorkShiftName(payload)).then((res) => {
                  if (res.payload?.data?.code === 200) {
                      props.onHide();
                      setWorkShiftName('');
                      toast.success('Successfuly Updated');
                  }
              });
          } else {
              setSubmitClicked(true);
          }
      } else {
          if (workshiftName) {
              dispatch(CreateCompanyWorkShift(workshiftName)).then(() => {
                  props.onHide();
                  setWorkShiftName('');
              });
          } else {
              setSubmitClicked(true);
          }
      } */
    };
    return (
        <Modal
            {...props}
            size='md' // Cambia el tamaño del modal a 'lg' o 'xl'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{ background: 'rgba(0,0,0,0.5)' }}
        >
            <div style={{ padding: '20px', width: '100%' }}>
                {' '}
                {/* Ajusta el padding y el ancho del modal */}
                <button
                    onClick={() => {
                        props.onHide();
                        setSubmitClicked(false);
                    }}
                    className='modal-close-btn'
                >
                    X
                </button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                    <span className='main-modal-heading'>{t('add_output')}</span>
                </div>
                <div className='unlink-modal-body'>
                    <span
                        className='modal-desc-text'
                        style={{ color: '#707070', fontSize: '10px', fontWeight: 'normal', textAlign: 'left' }}
                    >
                        <span style={{ fontWeight: 'bold' }}>{props?.data?.name}</span>
                    </span>

                    <div className='mt-2' style={{ position: 'relative' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth required>
                                        <InputLabel id='demo-simple-select-label'>{t('controller')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='demo-simple-select'
                                            label={t('controller')}
                                        >
                                            {/* Agrega las opciones aquí */}
                                            <MenuItem></MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth required>
                                        <InputLabel id='demo-simple-select-label'>{t('SIO')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            id='demo-simple-select'
                                            label={t('SIO')}
                                        >
                                            {/* Agrega las opciones aquí */}
                                            <MenuItem></MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <ReusableTextField
                                    label={t('name')}
                                    onChange={handleNameChange}
                                    value={workshiftName}
                                    helperText={nameError}
                                    submitClicked={submitClicked}
                                    validate={(value) => value === ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <ReusableTextField
                                    label={t('default_pulse')}
                                    onChange={handleNameChange}
                                    value={workshiftName}
                                    helperText={nameError}
                                    submitClicked={submitClicked}
                                    validate={(value) => value === ''}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className='btn-div'>
                        <button
                            className='custom_btn_cancel_gray_hover'
                            style={{ width: '100%' }}
                            id='btn_co_cancel'
                            onClick={() => {
                                props.onHide();
                                setSubmitClicked(false);
                            }}
                        >
                            {t('cancel')}
                        </button>
                        <button
                            className='custom_primary_btn_dark'
                            style={{ width: '100%' }}
                            id='btn_co_add'
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {t('add')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateOutput;
