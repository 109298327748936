import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';

// Get Attributes Filter /advanced-search-service/get-all/by-module-id/{moduleId} search-module searching-controller
export const GetAttributesFilter = createAsyncThunk(
    'search/getAttributesFilter',
    async (moduleId, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`advanced-search-service/get-all/by-module-id/${moduleId}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Get Condition Filter /advanced-search-service/get-all/by-module-id/{moduleId} search-module searching-controller
export const GetConditionFilter = createAsyncThunk(
    'search/getConditionFilter',
    async (typeAttribute, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`advanced-search-service/get-operation-keys/by-type/${typeAttribute}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Get Values By Option and Field /advanced-search-service/{option}/{field}/values-to-filter search-module searching-controller
export const GetValuesByOptionAndField = createAsyncThunk(
    'search/getValuesByOptionAndField',
    async ({ option, field }, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`advanced-search-service/${option}/${field}/values-to-filter`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Get Values By Option and Field /advanced-search-service/{option}/{field}/values-to-filter search-module searching-controller
export const GetValuesByOptionFieldAndUserId = createAsyncThunk(
    'search/getValuesByOptionFieldAndUserId',
    async ({ option, field, userId }, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`advanced-search-service/${option}/${field}/values-to-filter/to-user-id/${userId}`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Get Search By Filters /advanced-search-service/{option} search-module searching-controller
export const SearchByFilters = createAsyncThunk(
    'search/searchByFilters',
    async ({ option, body }, { dispatch, getState }) => {
        window.postMessage(
            {
                type: 'sessionStorageChangeLoader',
                newValueLoading: true,
            },
            '*',
        );
        let result = await apiInstance
            .post(`advanced-search-service/${option}`, body)
            .then(function (response) {
                if (body?.searchCriteriaList.length > 0) {
                    toast.success(t('search_completed_successfully').toUpperCase());
                }
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
// Get Search By Filters /advanced-search-service/{option} search-module searching-controller
export const SearchTemplates = createAsyncThunk(
    'search/searchTemplates',
    async ({ option, body }, { dispatch, getState }) => {
        window.postMessage(
            {
                type: 'sessionStorageChangeLoader',
                newValueLoading: true,
            },
            '*',
        );
        let result = await apiInstance
            .post(`advanced-search-service/${option}`, body)
            .then(function (response) {
                if (body?.searchCriteriaList.length > 0) {
                    toast.success(t('search_completed_successfully').toUpperCase());
                }
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Get Search By Filters /advanced-search-service/{option} search-module searching-controller
export const SearchByFiltersCustom = createAsyncThunk(
    'search/searchByFiltersCustom',
    async ({ option, body }, { dispatch, getState }) => {
        window.postMessage(
            {
                type: 'sessionStorageChangeLoader',
                newValueLoading: true,
            },
            '*',
        );
        let result = await apiInstance
            .post(`advanced-search-service/${option}/custom`, body)
            .then(function (response) {
                if (body.searchCriteriaList.length > 0) {
                    toast.success(t('search_completed_successfully').toUpperCase());
                }
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// List all the posible models in the dropdown (done)
export const GetListSearchModels = createAsyncThunk(
    'search/getListSearchModels',
    async (params, { dispatch, getState }) => {
        const result = await apiInstance
            .get('advanced-search-service/models')
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });

        const { data, status } = result;

        return { data, status };
    },
);

// Get Values To FilterCrudLogByField
export const GetValuesToFilterLogByField = createAsyncThunk(
    'search/getValuesToFilterLogByField',
    async ({ model, field }, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`advanced-search-service/crud-log/${model}/${field}/values`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Get Search CrudLog by Model /advanced-search-service/{model}/crud-log search-module searching-controller
export const SearchCrudLogByModel = createAsyncThunk(
    'search/searchCrudLogByModel',
    async ({ model, body }, { dispatch, getState }) => {
        window.postMessage(
            {
                type: 'sessionStorageChangeLoader',
                newValueLoading: true,
            },
            '*',
        );
        let result = await apiInstance
            .post(`advanced-search-service/${model}/crud-log`, body)
            .then(function (response) {
                if (body.searchCriteriaList.length > 0) {
                    toast.success(t('search_completed_successfully').toUpperCase());
                }
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);

// Get Search By Filters /advanced-search-service/simple search-module searching-controller
export const SimpleSearch = createAsyncThunk('search/SimpleSearch', async ({ body }, { dispatch, getState }) => {
    window.postMessage(
        {
            type: 'sessionStorageChangeLoader',
            newValueLoading: true,
        },
        '*',
    );
    let result = await apiInstance
        .post(`advanced-search-service/simple`, body)
        .then(function (response) {
            if (body?.key !== null && body?.model !== null) {
            }
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    const { data, status } = result;
    return { data, status };
});
// Get Search By Filters /advanced-search-service/simple/user search-module searching-controller

export const SimpleSearchWithId = createAsyncThunk(
    'search/SimpleSearchWithId',
    async ({ body }, { dispatch, getState }) => {
        window.postMessage(
            {
                type: 'sessionStorageChangeLoader',
                newValueLoading: true,
            },
            '*',
        );
        let result = await apiInstance
            .post(`advanced-search-service/simple/user`, body)
            .then(function (response) {
                if (body?.key !== null && body?.model !== null) {
                    toast.success(t('search_completed_successfully').toUpperCase());
                }
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;
        return { data, status };
    },
);
