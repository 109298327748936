import { t } from 'i18next';
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ReusableTextField from '../../../../components/ReusableTextField ';
import { CreateHidInput, GetAllSiosByHidId } from '../../../../reduxToolkit/HID/HidApi';
import { AdvanceSearchByFilters, CreateHidSioRecord, GetSio } from '../../../../reduxToolkit/HID/HidApi';
import { useNavigate } from 'react-router-dom';

const CreateInputs = (props) => {
    const controller = useSelector((state) => state.HidSlice.advanceSearchByFilters);
    const sioIds = useSelector((state) => state.HidSlice.getAllSiosByHidId);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lCode = Cookies.get('i18next') || 'en';

    const [workshiftName, setWorkShiftName] = useState('');
    const [nameError, setNameError] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false);
    const [hidSioId, setHidSioId] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [id, setId] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);

    const textField = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& .MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& .MuiInputLabel-root': {
            fontSize: 12,
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& .MuiFormLabel-filled': {
            marginTop: '-5px',
        },
    };

    const handleNameChange = (event) => {
        const value = event.target.value;
        if (value === '') {
            setNameError('name cannot be empty');
        } else {
            setNameError('');
        }
        setWorkShiftName(value);
    };

    const handleSubmit = () => {
        if (!hidSioId) {
            toast.error(t('Please_select_a_valid_controller').toUpperCase());
            return;
        }

        const body = {
            hidSioId: id,
            name: name,
            number: number,
        };

        dispatch(CreateHidInput(body)).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                if (success) {
                    toast.success(t('Created_successfully').toUpperCase());
                    props.onHide();
                    resetValues();
                    navigate('/dashboard/employee/inputs');
                } else {
                    toast.error(t('Error_creating_controller').toUpperCase());
                }
            },
        );
    };
    const resetValues = () => {
        setName('');
        setNumber('');
        setHidSioId('');
        setId('');
    };
    useEffect(() => {
        if (hidSioId) {
            dispatch(GetAllSiosByHidId({ hidId: hidSioId })).catch((error) => {});
        }
    }, [hidSioId, dispatch]);

    useEffect(() => {
        const body = {};

        dispatch(AdvanceSearchByFilters({ option: 'hid', body }))
            .then(({ payload }) => {
                if (payload?.data && payload?.success) {
                    const { data, success } = payload.data;
                } else {
                    console.error(t('Error_fetching_data:_Response_structure_not_as_expected'));
                }
            })
            .catch((error) => {
                console.error(t('Error_fetching_data:', error));
                setLoaded(false);
                setDeletedFlag(false);
            });
    }, [dispatch]);

    const handleControllerChange = (event) => {
        setHidSioId(event.target.value);
    };
    const handleIdChange = (event) => {
        setId(event.target.value);
    };

    return (
        <Modal
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{ background: 'rgba(0,0,0,0.5)' }}
        >
            <div style={{ padding: '20px', width: '100%' }}>
                <button
                    id='btn_ci_back'
                    onClick={() => {
                        props.onHide();
                        setSubmitClicked(false);
                        resetValues();
                    }}
                    className='modal-close-btn'
                >
                    X
                </button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                    <span className='main-modal-heading'>{t('add_input')}</span>
                </div>
                <div className='unlink-modal-body'>
                    <span
                        className='modal-desc-text'
                        style={{ color: '#707070', fontSize: '10px', fontWeight: 'normal', textAlign: 'left' }}
                    >
                        <span style={{ fontWeight: 'bold' }}>{props?.data?.name}</span>
                    </span>

                    <div className='mt-2' style={{ position: 'relative' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>{t('controller')}</InputLabel>
                                        <Select
                                            id='slt_ci_hidSioId'
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            label={t('controller')}
                                            value={hidSioId}
                                            onChange={handleControllerChange}
                                        >
                                            {Array.isArray(controller) &&
                                                controller.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth required>
                                        <InputLabel id='sio-select-label'>{t('SIO')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            label={t('SIO')}
                                            value={id}
                                            id='slt_ci_sio'
                                            onChange={handleIdChange}
                                        >
                                            {Array.isArray(sioIds) &&
                                                sioIds.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12} style={{ width: '100%' }}>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    required
                                    id='txt_ci_name'
                                    label={t('name')}
                                    inputProps={{ maxLength: 128 }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className='inputField required-data'>
                                    <FormControl fullWidth required>
                                        <InputLabel id='n-input-select-label'>{t('n_input')}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId='n-input-select-label'
                                            id='slt_ci_inputNumber'
                                            label={t('n_input')}
                                            value={number}
                                            onChange={(e) => setNumber(e.target.value)}
                                        >
                                            <MenuItem value={0}>INPUT 1 </MenuItem>
                                            <MenuItem value={1}>INPUT 2 </MenuItem>
                                            <MenuItem value={2}>INPUT 3 </MenuItem>
                                            <MenuItem value={3}>INPUT 4 </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='btn-div'>
                        <button
                            id='btn_ci_cancel'
                            className='custom_btn_cancel_gray_hover'
                            style={{ width: '100%' }}
                            onClick={() => {
                                props.onHide();
                                setSubmitClicked(false);
                                resetValues();
                            }}
                        >
                            {t('cancel')}
                        </button>
                        <button
                            id='btn_ci_add'
                            className='custom_primary_btn_dark'
                            style={{ width: '100%' }}
                            onClick={handleSubmit}
                        >
                            {t('add')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateInputs;
