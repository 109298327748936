import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { GetWorkTimeAccess } from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import { GetZoneTree } from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import useStyle from '../../../../../hooks/useStyle';
import { GetAllCardFormats } from '../../../../../reduxToolkit/Cards/CardsApi';
import { AssignCardToUser } from '../../../../../reduxToolkit/Cards/CardsApi';
import AutoCompleteSearch from '../../../../../components/AutoCompleteSearch';
import { GetAllEmployeesPayroll } from '../../../../../reduxToolkit/EmployeePayRoll/EmployeePayRollApi';

const AssignCardModal = ({
    open,
    onClose,
    loaded,
    setLoaded,
    creationErrorsShow,
    setCreationErrorsShow,
    cardToAssign,
    onHide,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use hook importer
    const dispatch = useDispatch();
    const { smallBoxStyle, textFieldCommon } = useStyle();

    const { getAllEmployeesPayroll } = useSelector((state) => state.employeePayrollSlice);

    // use state hook  for local state managment

    const [facilityCode, setFacilityCode] = useState('');
    const [number, setNumber] = useState('');
    const [format, setFormat] = useState('');
    const [addRange, setAddRange] = useState(false);
    const [emptyFields, setEmptyFields] = useState(false);
    const [startingIn, setStartingIn] = useState('');
    const [endingIn, setEndingIn] = useState('');
    const [validStarting, setValidStarting] = useState(true);
    const [validEnding, setValidEnding] = useState(true);
    const [workshift, setWorkshift] = useState('');
    const [employee, setEmployee] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    // Pagination
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderby, setOrderby] = useState('id');
    let contractPagination = {
        order: true,
        page: page,
        size: rowsPerPage,
        sortBy: orderby,
    };
    // End Pagination

    useEffect(() => {
        if (workshift) {
            dispatch(GetWorkTimeAccess({ id: workshift, contractPagination }));
        } else {
            return null;
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        dispatch(GetZoneTree());
        dispatch(GetAllEmployeesPayroll());
    }, []);

    useEffect(() => {
        resetForm();
        dispatch(GetAllCardFormats());
        setEmptyFields(false);
        setValidEnding(true);
        setValidStarting(true);
    }, [open]);

    const resetForm = () => {
        setFacilityCode('');
        setNumber('');
        setStartingIn('');
        setEndingIn('');
        setFormat('');
        setAddRange(false);
    };

    // handel the logic for object transformation for all user

    // handel the logic for object transformation for all user

    const userDataTransformUser = (tras) => {
        const newData = tras?.map((item) => {
            return {
                label: `${item?.name || ''} ${item?.firstLastName || ''} ${item?.secondLastName || ''}`.trim(),
                id: item?.id || item?.userId,
            };
        });
        return newData;
    };

    // funtion to handel select employee
    const handleAddEmployee = (e) => {
        setSelectedEmployee(e?.id);
    };

    //function to handle confirm
    const handleConfirm = () => {
        setEmptyFields(true);
        if (employee) {
            const data = {
                cardIds: [cardToAssign.id],
                userId: selectedEmployee,
            };
            dispatch(AssignCardToUser(data)).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        onHide();
                    }
                },
            );
        }
    };

    return (
        <Dialog className='add-workshift-enrollment-dialog' open={open} onClose={onClose} maxWidth='xs'>
            <DialogTitle className='dialog-title'>
                <p className='search-title'>{t('assign_card')}</p>
                <img onClick={() => onHide()} className='dialog-close-custom' src={cancel} alt='' />
            </DialogTitle>

            <DialogContent className='dialog-content mt-0 pt-0' style={{ overflowX: 'hidden' }}>
                <div className='pt-3 row pr-0'>
                    <p className='add-card-modal-text text-center'>
                        {t('select_the_employee_to_be_linked_to_this_card')}
                    </p>

                    <p className='add-card-modal-text fw-bold mt-2' style={{ textTransform: 'capitalize' }}>
                        {t('number').toLowerCase()}
                    </p>
                    <p className='add-card-modal-text mt-1 ml-3'>{cardToAssign?.number}</p>
                    <p className='add-card-modal-text fw-bold mt-1' style={{ textTransform: 'capitalize' }}>
                        {t('facility_code')}
                    </p>
                    <p className='add-card-modal-text mt-1 ml-3'>{cardToAssign?.facilityCode}</p>
                    <p className='add-card-modal-text fw-bold mt-1' style={{ textTransform: 'capitalize' }}>
                        {t('format')}
                    </p>
                    <p className='add-card-modal-text mt-1 ml-3'>{cardToAssign?.cardTypeName}</p>
                    <Box
                        className='search-card-assign required-data-text-form'
                        marginTop='1rem'
                        marginBottom='1rem'
                        sx={smallBoxStyle}
                    >
                        <AutoCompleteSearch
                            data={userDataTransformUser(getAllEmployeesPayroll)}
                            disablePortal={false}
                            handleFn={(e) => handleAddEmployee(e)}
                            value={employee}
                            setValue={setEmployee}
                            label={t('employee')}
                            required={true}
                            error={emptyFields && !employee}
                        />
                    </Box>
                </div>

                <div>
                    <>
                        <div className='create-enrollment-footer mt-3 row pr-2'>
                            <button
                                className='btn_cancel_background_gray_hover col-6'
                                onClick={() => {
                                    onHide();
                                }}
                                style={{ color: '#BC0000' }}
                                id='btn_acm_cancel'
                            >
                                {t('cancel')}
                            </button>
                            <button
                                className='custom_primary_btn_dark col-6'
                                onClick={() => {
                                    handleConfirm();
                                }}
                                id='btn_acm_assign'
                            >
                                {t('assign').toUpperCase()}
                            </button>
                        </div>
                    </>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AssignCardModal;

const inputBox = {
    width: '100%',
    maxWidth: '100%',
    fontSize: '20px',
    height: '50px',
};
