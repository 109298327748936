import React, { useState, useEffect, useRef } from 'react';
import './SelfiePage.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
    GetDocumentValidationStatus,
    GetTokenChallenge,
    ValidateSelfie,
    ValidateVideoPhoto,
} from '../../../../reduxToolkit/Visitor/VisitorApi';
import { GetUserRestrictionVisitor } from '../../../../reduxToolkit/Visitor/VisitorApi';
import apiInstance from '../../../../Apis/Axios';
import { setIsSuccessUserSelfie, setUserSelfie } from '../../../../reduxToolkit/Visitor/VisitorSlice';
import SuccessValidationForm from '../../SuccessValidation/SuccessValidationForm';
import ValidatedFail from '../../FailValidation/ValidatedFail';
import logo from '../../../../assets/logo/logo 3.png';

const SelfiePage = () => {
    const [prefaceImage, setPrefaceImage] = useState(null);
    const [faceImage, setFaceImage] = useState(null);
    const [vtt, setVtt] = useState(null);
    const [unmounted, setUnmounted] = useState(false);
    const [tokenChallengeGet, setTokenChallengeGet] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    const userSelfieSuccess = useSelector((state) => state.VisitorSlice.userSelfie);
    const userSelfieIsSuccess = useSelector((state) => state.VisitorSlice.isSuccessUserSelfie);
    const validationData = useSelector((state) => state.VisitorSlice.getValidationId);
    const tokenChallenge = useSelector((state) => state.VisitorSlice.getTokenChallenge);
    const userRestrictionData = useSelector((state) => state.VisitorSlice.getUserRestrictionVisitor);

    const targetRef = useRef(null);
    let vdAlive = null;
    useEffect(() => {
        start();
        setTokenChallengeGet(tokenChallenge);
        return () => {
            if (!unmounted) {
                window.destroyVDAliveWidget();
            }
        };
    }, []);

    const start = () => {
        loadScript('./js/VDAlive.js');
    };

    const loadScript = (src) => {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.setAttribute('type', 'application/javascript');
        script.addEventListener('load', sdkStart);

        document.head.appendChild(script);
    };

    const sdkStart = () => {
        var jws = tokenChallenge;
        const gifPath = process.env.PUBLIC_URL + '/js/gifs/';
        let gif = 'VDAlive_Smile.gif';
        if (jws !== '') {
            gif = 'VDAlive_Challenge.gif';
        }
        vdAlive = window.makeVDAliveWidget();
        vdAlive({
            targetSelector: '#target',
            pathModels: process.env.PUBLIC_URL + '/js/models/',
            infoAlertShow: true,
            infoModalShow: true,
            reviewImage: true,
            logEventsToConsole: true,
            ngas_images_path: gifPath,
            aliveChallenge: jws,
            infoUserAliveHeader: '',
            infoUserButtonTextColor: '#f56789',
            infoUserAliveMedia: gif,
            infoUserAliveHeaderColor: '#178a7b',
            infoUserAliveTitleColor: '#178a7b',
            infoUserAliveSubTitleColor: '#178a7b',
            infoUserAliveColorButton: '#178a7b',
            infoUserAliveNextButtonColor: '#178a7b',
            infoUserAlivePrevButtonColor: '#178a7b',
            stepsChallengeColor: '#178a7b',
            buttonBackgroundColorDark: '#178a7b',
            buttonBackgroundColorLight: '#178a7b',
            buttonBackgroundColorDarkRepeat: 'transparent',
            buttonBackgroundColorLightRepeat: 'transparent',
            repeatButtonColor: '#178a7b',
            sdkBackgroundColorInactive: '#FFFFFF',
            borderColorCenteringAidDetecting: '#078B3C',
            outerGlowCenteringAidDefault: '#178a7b',
            detectionMessageBackgroundColor: '#178a7b',
            detectionMessageTextColor: '#178a7b',
            detectionMessageTextColorSelfie: '#178a7b',
            confirmationDialogTextColor: '#178a7b',
            confirmationColorTick: '#178a7b',
            errorDisplayBackgroundColor: '#FFFFFF',
            errorDisplayTextColor: '#178a7b',
            errorActionButtonBackgroundColor: '#FFFFFF',
            errorActionButtonTextColor: '#178a7b',
            errorDisplayIconColor: '##178a7b',
            customLogo: logo,
        });
    };

    const onVDAliveMounted = () => {
        setUnmounted(false);
    };

    const onVDAliveMountFailure = (data) => {
        setMessage(data.detail);
    };

    const onVDAliveUnmounted = () => {
        setUnmounted(true);
        setMessage('');
    };

    const onVDAliveFaceDetection = (data) => {
        setPrefaceImage(data.detail.image);
        setFaceImage(data.detail.image_alive);
        setVtt(data.detail.webVTT);
    };

    const onCVLoadError = (error) => {
        setMessage('Failed to load/initialize cv.js');
    };

    useEffect(() => {
        const target = targetRef.current;

        target.addEventListener('VDAlive_mounted', onVDAliveMounted);
        target.addEventListener('VDAlive_mountFailure', onVDAliveMountFailure);
        target.addEventListener('VDAlive_unmounted', onVDAliveUnmounted);
        target.addEventListener('VDAlive_faceDetection', onVDAliveFaceDetection);

        return () => {
            target.removeEventListener('VDAlive_mounted', onVDAliveMounted);
            target.removeEventListener('VDAlive_mountFailure', onVDAliveMountFailure);
            target.removeEventListener('VDAlive_unmounted', onVDAliveUnmounted);
            target.removeEventListener('VDAlive_faceDetection', onVDAliveFaceDetection);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (vtt && userRestrictionData.livenessVisitor === true) {
                    // Realiza la validación del video y espera la respuesta
                    await sendValidateVideoPhoto();
                    // Después de recibir la respuesta de sendValidateVideoPhoto, llama a selfieValidationStatus
                    await selfieValidationStatus();
                }
            } catch (error) {
                console.error('Error en la validación:', error);
            }
        };

        fetchData();
    }, [tokenChallenge, prefaceImage, faceImage, vtt, validationData, userRestrictionData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userRestrictionData.livenessVisitor === false) {
                    // Realiza la validación de la selfie y espera la respuesta
                    await sendValidateSelfie();
                    // Después de recibir la respuesta de sendValidateSelfie, llama a selfieValidationStatus
                    await selfieValidationStatus();
                }
            } catch (error) {
                console.error('Error en la validación:', error);
            }
        };

        fetchData();
    }, [prefaceImage, faceImage, userRestrictionData]);

    const selfieValidationStatus = async () => {
        await apiInstance
            .post('validas-service/v1/process', null, {
                params: { validationId: validationData },
            })
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(setUserSelfie(response.data.data));
                    dispatch(setIsSuccessUserSelfie(response.data.data.success));
                }
            })
            .catch(function (error) {
                return error.response;
            });
    };

    const sendValidateSelfie = async () => {
        try {
            if (prefaceImage && faceImage) {
                dispatch(
                    ValidateSelfie({
                        image: prefaceImage,
                        imageAlive: faceImage,
                        validationId: validationData,
                    }),
                );
            } else {
                console.error('Failed to get obverse image.');
            }
        } catch (error) {
            console.error('Error sending obverse validation request:', error);
        }
    };

    const sendStatusValidationRequest = async () => {
        try {
            dispatch(GetDocumentValidationStatus({ validationId: validationData }));
        } catch (error) {
            console.error('Error sending validation status request:', error);
        }
    };
    const sendValidateVideoPhoto = async () => {
        try {
            if (prefaceImage && faceImage && tokenChallenge) {
                dispatch(
                    ValidateVideoPhoto({
                        annotations: vtt,
                        selfie: prefaceImage,
                        video: faceImage,
                        challenge: tokenChallenge,
                        validationId: validationData,
                    }),
                );
            } else {
                console.error('Failed to get obverse image.');
            }
        } catch (error) {
            console.error('Error sending obverse validation request:', error);
        }
    };

    return !unmounted ? (
        <div id='document_page'>
            <div ref={targetRef} id='target' style={{ width: '700px', height: '500px' }}></div>
        </div>
    ) : userSelfieIsSuccess === true ? (
        <SuccessValidationForm />
    ) : (
        <ValidatedFail />
    );
};

export default SelfiePage;
