import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { columnCode } from '../../enums/columnCodeEnumsContractor';
import * as XLSX from 'xlsx';
import jspreadsheet from 'jspreadsheet-ce';
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import ReturnArrow from '../../components/buttons/ReturnArrow';
import ErrorCard from '../../components/buttons/ErrorCard';
import { toast } from 'react-toastify';
import { errorCodes } from '../../enums/ErrorCodeExcel';
import {
    CreateListContractor,
    CreateListContractorVehicle,
    CreateListSupplierVehicle,
    UploadContractorFormFile,
    UploadContractorVehicleFormFile,
    UploadSupplierVehicleFormFile,
} from '../../reduxToolkit/Files/FilesApi';

const CreationErrorsSuplierVehicle = ({ creationErrorsShow, setCreationErrorsShow }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { uploadFormFile } = useSelector((state) => state.FilesSlice);
    const { analizeCards } = useSelector((state) => state.CardsSlice);
    const excel = useSelector((state) => state.FilesSlice.excelFile);
    const [spreadsheetData, setSpreadsheetData] = useState(null);
    const [update, setUpdate] = useState(false);
    const [selectedErrorIndex, setSelectedErrorIndex] = useState(null);
    const [errorsArray, setErrorsArray] = useState([]);
    const [contractorData, setContractorData] = useState();

    const jRef = useRef(null);

    useEffect(() => {
        if (excel) {
            readExcelFile(excel);
        }
    }, [excel]);

    useEffect(() => {
        let fetchedErrors = [];
        if (analizeCards) {
            fetchedErrors = analizeCards.data?.filter((item) => item.errors !== null) || [];
        } else if (uploadFormFile) {
            fetchedErrors = uploadFormFile.data?.filter((item) => item.errors !== null) || [];
        }
        setErrorsArray(fetchedErrors);
    }, [analizeCards, uploadFormFile]);

    const readExcelFile = (file) => {
        if (!(file instanceof Blob)) {
            console.error('El archivo proporcionado no es un Blob');
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName], { header: 1, defval: '' });

            const filteredData = worksheet.map((row) => row.slice(0, 7));
            const cleanedData = filteredData.map((row, index) => {
                if (index >= 7 && row[5]) {
                    row[5] = row[5].replace(/\s/g, '');
                }
                return row;
            });

            setSpreadsheetData(cleanedData.length > 0 ? cleanedData : null);
        };
        reader.readAsBinaryString(file);
    };

    const handleUploadFile = () => {
        setUpdate(true);
        if (spreadsheetData) {
            const ws = XLSX.utils.aoa_to_sheet(spreadsheetData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBlob = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const arrayBuffer = new ArrayBuffer(excelBlob.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < excelBlob.length; i++) {
                view[i] = excelBlob.charCodeAt(i) & 0xff;
            }
            const binaryString = String.fromCharCode.apply(null, view);
            let formData = new FormData();
            formData.append(
                'file',
                new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
                'errors.xlsx',
            );
            dispatch(UploadSupplierVehicleFormFile(formData))
                .then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            const errorsArray = data?.filter((item) => item.errors !== null);
                            setContractorData(data);
                        }
                    },
                )
                .catch((error) => {
                    console.error('Error uploading file:', error);
                    toast.error(t('fail_to_upload_file').toUpperCase());
                });
        } else {
            toast?.warn(t('there_is_not_file_to_upload').toUpperCase());
        }
    };

    useEffect(() => {
        if (spreadsheetData && jRef.current && errorsArray) {
            if (jRef.current.jexcel) {
                jRef.current.jexcel.destroy();
            }

            const jSpreadsheetInstance = jspreadsheet(jRef.current, {
                data: spreadsheetData,
                minDimensions: [2, 2],
                tableOverflow: true,
                columns: new Array(7).fill({ type: 'text', width: 100 }),

                onselection: (el, startCell, endCell) => {
                    const startCellName = `${String.fromCharCode(65 + startCell[0])}${startCell[1] + 1}`;
                    const cellErrorIndex = errorsArray.findIndex((errorItem, rowIndex) =>
                        errorItem.errors.some(
                            (error) =>
                                `${String.fromCharCode(65 + error.position - 1)}${rowIndex + 3}` === startCellName,
                        ),
                    );
                    if (cellErrorIndex !== -1) {
                        setSelectedErrorIndex(cellErrorIndex);
                    }
                },
            });

            errorsArray.forEach((errorItem, dataIndex) => {
                errorItem.errors.forEach((error) => {
                    const cellName = `${String.fromCharCode(65 + error.position - 1)}${dataIndex + 9}`;
                    const errorMessage = t(errorCodes[error.errorCode]);
                    jSpreadsheetInstance.setStyle(cellName, 'background-color', '#F7A9A8');
                    jSpreadsheetInstance.setComments(cellName, `Error: ${errorMessage}`);
                });
            });

            jRef.current.jexcel = jSpreadsheetInstance;
        }
    }, [spreadsheetData, errorsArray]);

    const renderErrorCards = () => {
        let errorNumber = 1;
        if (errorsArray.length === 0 || errorsArray === undefined) {
            return <p className='no-error-found'>{t('no_errors_found')}</p>;
        } else {
            return (
                <div className='error-cards-container'>
                    {errorsArray.map((errorItem, rowIndex) => (
                        <div key={rowIndex} className='error-row'>
                            {errorItem.errors.map((error, errorIndex) => (
                                <ErrorCard
                                    key={errorIndex}
                                    numero={errorNumber++}
                                    row={rowIndex + 9}
                                    column={t(columnCode[error.position])}
                                    error={t(errorCodes[error.errorCode])}
                                    value={error.value}
                                    isSelected={selectedErrorIndex === rowIndex && errorIndex === error.position}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            );
        }
    };
    const handleConfirm = () => {
        dispatch(CreateListSupplierVehicle(contractorData)).then(({ payload: { data, success } }) => {
            if (success === true) {
                toast.success(t('has_been_created_successfully').toUpperCase());
                navigate('/dashboard/supplier/vehicles');
            } else {
                toast.error(t('error_creating').toUpperCase());
            }
        });
    };

    const totalErrors =
        errorsArray && errorsArray !== null
            ? errorsArray.reduce((acc, curr) => acc + curr.errors.length, 0)
            : errorsArray && errorsArray.length === 0
              ? 0
              : 0;

    const handleSubmit = () => {
        navigate('/dashboard/supplier/vehicles');
    };

    return (
        <div className='page-container'>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow onClick={handleSubmit} />
                    <h2>{t('import_data')}</h2>
                </div>
            </div>
            <div className='error-number'>
                <h2 className='error-detail-n'>{t('suplier_vehicle_detail')}</h2>
                <h2 className='error-detail-n'>
                    {t('ERRORS')} ({totalErrors}){' '}
                </h2>
            </div>
            <>
                <div className='content'>
                    <div>
                        <div ref={jRef} />
                    </div>

                    <div className='error-container-background'>{renderErrorCards()}</div>
                </div>
            </>
            <div className='footer-error'>
                <button onClick={handleSubmit} className='cancel-error full-width'>
                    {t('cancel').toUpperCase()}
                </button>

                {errorsArray.length === 0 || errorsArray.length === undefined ? (
                    <button className='verify-error full-width' onClick={handleConfirm}>
                        {t('create').toUpperCase()}
                    </button>
                ) : (
                    <button className='verify-error full-width' onClick={handleUploadFile}>
                        {t('verify').toUpperCase()}
                    </button>
                )}
            </div>
        </div>
    );
};

export default CreationErrorsSuplierVehicle;
