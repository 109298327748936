import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import SaveIcon from '@mui/icons-material/Save';
import ContractorAccessCard from './ContractorAccessCard';
import { Modal } from 'react-bootstrap';
import {
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextareaAutosize,
    FormHelperText,
} from '@mui/material';
import DeleteIcon from '../../../assets/images/redTrash.svg';
import emptyList from '../../../assets/images/warning.svg';
import TablePagination from '@mui/material/TablePagination';
import { Box } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from 'react-bootstrap';
import apiInstance from '../../../Apis/Axios';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect } from 'react';
import {
    CreateContract,
    CreateContractWithCustom,
    CreateContractV1,
} from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import { GetAllContractors, GetAllWorkSchdule } from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import {
    getAllContractors,
    getcreateContract,
} from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import Step4AccessRights from '../Company/Employees/EnrollmentSteps/Step4AccessRights';
import ClearButton from '../../../components/ClearButton';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import { Contract } from '../../../models/Contract';
import useStyle from '../../../hooks/useStyle';
import dateInputLanguage from '../../../hooks/dateInputLanguage';
import { UpdateContractV1 } from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import { GetContractByIdV1 } from '../../../reduxToolkit/Contractor/ContractorApi';
import { getContractByIdV1 } from '../../../reduxToolkit/Contractor/ContractorSlice';
export const CreateContractor = ({ isUpdate }) => {
    const lCode = Cookies.get('i18next') || 'en';
    const { t, i18n } = useTranslation();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();
    const getAllContractor = useSelector(getAllContractors);

    const [contract, setContract] = useState(Contract);
    const [emptyFields, setEmptyFields] = useState(false);
    const [isValidStartDate, setIsValidStartDate] = useState(true);
    const [isValidEndDate, setIsValidEndDate] = useState(true);

    const { textField, smallBoxStyle, textFieldCommon } = useStyle();

    const state = location.state;
    const contractId = state && state.state;

    const handleStartDateChange = (selectedDate) => {
        const startDateInMilliseconds = selectedDate.getTime();
        setContract((prevData) => ({
            ...prevData,
            startDate: startDateInMilliseconds,
        }));
    };

    const handleEndDateChange = (selectedDate) => {
        const endDateInMilliseconds = selectedDate.getTime();
        setContract((prevData) => ({
            ...prevData,
            endDate: endDateInMilliseconds,
        }));
    };

    // Pagination
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderby, setOrderby] = useState('id');
    let contractPagination = {
        order: true,
        page: page,
        size: rowsPerPage,
        sortBy: orderby,
    };
    // End Pagination

    const getAllcontractDetail = async () => {
        const data = {
            order: true,
            page: 0,
            size: 10,
            sortBy: 'string',
        };
        const result = await apiInstance
            .post('contractor-service/get-all-pageable', {
                order: true,
                page: 0,
                size: 10,
                sortBy: 'id',
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    };

    const handleChange = (key, value) => {
        setContract((prevData) => ({
            ...prevData,
            [key]: value === '' ? null : value,
        }));
    };
    const handleSubmit = () => {
        setEmptyFields(true);
        if (contract.contractorId && contract.startDate && contract.endDate) {
            /*author mazhar iqbal
        create contract
      */ const data = {
                ...contract,
                startDate: Number(contract.startDate),
                endDate: Number(contract.endDate),
            };
            const dataToSend = {
                contractorEmail: null,
                contractorId: contract.contractorId,
                contractorLastName: null,
                contractorName: null,
                contractorPhoneNumber: null,
                contractorSecondLastName: null,
                description: contract.description,
                endDate: Number(contract.endDate),
                folio: contract.folio,
                id: null,
                orderId: null,
                startDate: Number(contract.startDate),
                statusId: null,
            };
            Object.keys(dataToSend).forEach((key) => {
                if (dataToSend[key] === '') {
                    dataToSend[key] = null;
                }
            });
            if (isUpdate) {
                const data = contract;
                dispatch(UpdateContractV1(data)).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            toast.success(t('has_been_updated_successfully').toUpperCase());
                            navigate(-1);
                        } else {
                            toast.error(t('error_updating').toUpperCase());
                        }
                    },
                );
            } else {
                const dataWithNullFields = { ...dataToSend };
                Object.keys(dataWithNullFields).forEach((key) => {
                    if (dataWithNullFields[key] === '') {
                        dataWithNullFields[key] = null;
                    }
                });

                dispatch(CreateContractV1(dataWithNullFields)).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success === true) {
                            toast.success(t('has_been_created_successfully').toUpperCase());
                            navigate(-1);
                        } else {
                            toast.error(t('error_creating').toUpperCase());
                        }
                    },
                );
            }
        } else {
            toast.warn(t('please_fill_all_fields').toUpperCase());
        }
    };

    const handleClearAll = () => {
        setContract(Contract);
    };

    useEffect(() => {
        /*author mazhar iqbal
      get contractor list
    */
        dispatch(GetAllContractors());
        getAllcontractDetail();
        /*author mazhar iqbal
      get schedule access list
    */
        dispatch(GetAllWorkSchdule());
        /*author mazhar iqbal
     get schedule access list detail
   */
        if (isUpdate) {
            dispatch(GetContractByIdV1(contractId)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        setContract((prevData) => ({
                            ...prevData,
                            ...data,
                        }));
                    }
                },
            );
        }
    }, []);

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow link={null} />
                    <h2>{isUpdate ? t('update') : t('create')}</h2>
                </div>
            </div>
            <Box sx={{ padding: '0rem 4rem' }}>
                <div className='mt-2 order_data_component'>
                    <p className='__header'>{t('contract_data')}</p>
                    <div className='formCard'>
                        <div className='mt-2' style={{ position: 'relative', padding: '20px' }}>
                            {isUpdate !== true && (
                                <Grid
                                    style={{ paddingBottom: '10px', paddingLeft: '400px' }}
                                    className='d-flex justify-content-end mb-3'
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                >
                                    <ClearButton
                                        flagTooltip={true}
                                        handleClear={() => handleClearAll()}
                                        textTooltip={t('clean_all_fields')}
                                    />
                                </Grid>
                            )}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} style={{ width: '50%' }}>
                                    <Box className='inputField required-data'>
                                        <FormControl
                                            required
                                            fullWidth
                                            sx={textFieldCommon}
                                            error={emptyFields && contract.contractorId === null}
                                        >
                                            <InputLabel id='demo-simple-select-label'>
                                                {t('choose_a_contractor')}
                                            </InputLabel>
                                            <Select
                                                size='small'
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                label={t('choose_a_contractor')}
                                                disabled={isUpdate}
                                                value={contract.contractorId}
                                                onChange={(e) =>
                                                    setContract((prevData) => ({
                                                        ...prevData,
                                                        contractorId: e.target.value,
                                                    }))
                                                }
                                            >
                                                {getAllContractor &&
                                                    getAllContractor.map((item, index) => (
                                                        <MenuItem value={item?.id} key={index}>
                                                            <strong className='me-2'>{item?.acronym} | </strong>{' '}
                                                            <span style={{ fontSize: '14px' }}>{item?.user?.name}</span>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {emptyFields && contract.contractorId === null && (
                                                <FormHelperText className='select_helper_text'>
                                                    {t('selectOptionText')}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} style={{ width: '50%' }}>
                                    <Box className='inputField'>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            className='inputField'
                                            id='outlined-multiline-static'
                                            label={t('folio')}
                                            value={contract.folio}
                                            style={{ color: '#707070' }}
                                            onChange={(e) =>
                                                setContract((prevData) => ({
                                                    ...prevData,
                                                    folio: e.target.value,
                                                }))
                                            }
                                            sx={textFieldCommon}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '50%' }}>
                                    <Box className='inputField required-data'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={dateInputLanguage(lCode)}
                                        >
                                            <Stack spacing={3} sx={textFieldCommon}>
                                                <DesktopDatePicker
                                                    disablePast
                                                    allowSameDateSelection
                                                    label={t('start_contract')}
                                                    inputFormat={lCode === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                                                    value={contract.startDate}
                                                    onChange={handleStartDateChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name='startDate'
                                                            size='small'
                                                            sx={textFieldCommon}
                                                            required
                                                            {...params}
                                                            helperText={
                                                                emptyFields && contract.startDate === null
                                                                    ? t('please_select_date')
                                                                    : !isValidStartDate
                                                                      ? t('date_invalid')
                                                                      : ''
                                                            }
                                                            error={
                                                                (emptyFields && contract.startDate === null) ||
                                                                !isValidStartDate
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Box>{' '}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '50%' }}>
                                    <Box className='inputField required-data'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={dateInputLanguage(lCode)}
                                        >
                                            <Stack spacing={3} sx={textFieldCommon}>
                                                <DesktopDatePicker
                                                    disabled={contract.startDate === null}
                                                    minDate={contract.startDate}
                                                    label={t('end_contract')}
                                                    inputFormat={lCode === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                                                    value={contract.endDate}
                                                    onChange={handleEndDateChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name='endDate'
                                                            size='small'
                                                            sx={textFieldCommon}
                                                            required
                                                            {...params}
                                                            helperText={
                                                                emptyFields && contract.endDate === null
                                                                    ? t('please_select_date')
                                                                    : !isValidEndDate
                                                                      ? t('date_invalid')
                                                                      : ''
                                                            }
                                                            error={
                                                                (emptyFields && contract.endDate === null) ||
                                                                !isValidEndDate
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '100%' }}>
                                    <Box className='inputField required-data'>
                                        <TextField
                                            size='small'
                                            className='inputField'
                                            fullWidth
                                            id='outlined-multiline-static'
                                            label={t('description')}
                                            multiline
                                            rows={4}
                                            value={contract.description}
                                            style={{ color: '#707070' }}
                                            onChange={(e) =>
                                                setContract((prevData) => ({
                                                    ...prevData,
                                                    description: e.target.value,
                                                }))
                                            }
                                            sx={textFieldCommon}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-center mt-12 mb-12 pt-4 pb-4' style={{ marginTop: '100px' }}>
                    <button
                        onClick={() => navigate('/dashboard/employee/contractors')}
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '520px', flex: 'unset' }}
                    >
                        {t('cancel')?.toUpperCase()}
                    </button>
                    <button
                        className='custom_primary_btn_dark'
                        onClick={handleSubmit}
                        style={{ width: '520px', flex: 'unset' }}
                    >
                        {isUpdate ? t('update').toUpperCase() : t('create')?.toUpperCase()}
                    </button>
                </div>
            </Box>
        </>
    );
};
