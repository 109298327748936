export const UserFullInformation = {
    id: null,
    genderId: '',
    statusId: '',
    userTypeId: 5,
    name: '',
    lastName: '',
    secondLastName: '',
    email: '',
    password: null,
    phoneNumber: '',
    deviceId: '',
    dob: null,
    createdAt: null,
    updatedAt: null,
    extraDataId: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    field9: '',
    field10: '',
    field11: '',
    field12: '',
    field13: '',
    field14: '',
    field15: '',
    selfie: '',

    toUpdateExtra: function () {
        return {
            id: this.extraDataId,
            userId: this.id,
            field1: this.field1 !== '' ? this.field1 : null,
            field2: this.field2 !== '' ? this.field2 : null,
            field3: this.field3 !== '' ? this.field3 : null,
            field4: this.field4 !== '' ? this.field4 : null,
            field5: this.field5 !== '' ? this.field5 : null,
            field6: this.field6 !== '' ? this.field6 : null,
            field7: this.field7 !== '' ? this.field7 : null,
            field8: this.field8 !== '' ? this.field8 : null,
            field9: this.field9 !== '' ? this.field9 : null,
            field10: this.field10 !== '' ? this.field10 : null,
            field11: this.field11 !== '' ? this.field11 : null,
            field12: this.field12 !== '' ? this.field12 : null,
            field13: this.field13 !== '' ? this.field13 : null,
            field14: this.field14 !== '' ? this.field14 : null,
            field15: this.field15 !== '' ? this.field15 : null,
        };
    },

    toUpdateNormal: function () {
        return {
            id: this.id,
            genderId: this.genderId !== '' ? this.genderId : null,
            statusId: this.statusId !== '' ? this.statusId : null,
            dob: this.dob === null ? null : typeof this.dob === 'number' ? this.dob : this.dob.getTime(),
            email: this.email,
            lastName: this.lastName,
            name: this.name,
            phoneNumber: this.phoneNumber,
            secondLastName: this.secondLastName !== '' ? this.secondLastName : null,
            password: this.password !== '' ? this.password : null,
        };
    },
};
