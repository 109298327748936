import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../../Apis/Axios';

export const GetListAnnouncements = createAsyncThunk(
    'announcements/getListAnnouncements',
    async (body, { dispatch, getState }) => {
        const result = await apiInstance
            .post(`announcement-service/get-all-pageable/incoming-date/${body.date}`, body?.pagination)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const AnnouncementTypes = createAsyncThunk('announcements/announcementTypes', async () => {
    const response = await apiInstance
        .get('announcement-service/announcement-type/get-all')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    const { data, status } = response;
    return { data, status };
});

export const CreateAnnouncement = createAsyncThunk(
    'announcements/createAnnouncement',
    async (body, { dispatch, getState }) => {
        const result = await apiInstance
            .post('announcement-service/create', body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const SendToAllScope = createAsyncThunk(
    'announcements/sendToAllScope',
    async (announcementId, { dispatch, getState }) => {
        const result = await apiInstance
            .get(`announcement-service/user-announcement/send-to-all/by-announcement-id/${announcementId})`)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const SendByTopicScope = createAsyncThunk(
    'announcements/sendByTopicScope',
    async (body, { dispatch, getState }) => {
        const result = await apiInstance
            .post('announcement-service/user-announcement/create/by-topic', body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const SendToSomeEmployees = createAsyncThunk(
    'announcements/sendToSomeEmployees',
    async (body, { dispatch, getState }) => {
        const result = await apiInstance
            .post('announcement-service/user-announcement/create/by-employees-ids', body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const GetEmployeesPageable = createAsyncThunk(
    'announcements/getEmployeesPageable',
    async (body, { dispatch, getState }) => {
        const result = await apiInstance
            .post('employee-service/get-all-pageable/only-user-data', body)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);

export const UploadImgToServer = createAsyncThunk(
    'announcements/uploadImg',
    async (formData, { dispatch, getState }) => {
        const result = await apiInstance
            .put('image-service/upload', formData)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
        const { data, status } = result;

        return { data, status };
    },
);
