export const permissionObj = {
    WEB_COMPANY_MENU: 100, // done
    WEB_COMPANY_READ: 101, // done
    WEB_COMPANY_UPDATE: 102, // done
    WEB_PROFILE_READ: 110,
    WEB_PROFILE_UPDATE: 111,
    WEB_EMPLOYEE_MENU: 120, // done
    WEB_EMPLOYEE_READ: 121, // done
    WEB_EMPLOYEE_CREATE: 122, // done
    WEB_EMPLOYEE_DELETE: 123,
    WEB_EMPLOYEE_UPDATE: 123, // done
    WEB_EMPLOYEE_DOCUMENT_MENU: 130, // done
    WEB_EMPLOYEE_DOCUMENT_READ: 131,
    WEB_EMPLOYEE_DOCUMENT_CREATE: 132,
    WEB_EMPLOYEE_DOCUMENT_UPDATE: 133,
    WEB_EMPLOYEE_DOCUMENT_DELETE: 134, // done
    WEB_SUPPLIER_DOCUMENT_MENU: 140, // done
    WEB_SUPPLIER_DOCUMENT_READ: 141, // done
    WEB_SUPPLIER_DOCUMENT_CREATE: 142, // done
    WEB_SUPPLIER_DOCUMENT_UPDATE: 143, // done
    WEB_SUPPLIER_DOCUMENT_DELETE: 144, // done
    WEB_CONTRACTOR_DOCUMENT_MENU: 145, // done
    WEB_CONTRACTOR_DOCUMENT_READ: 146, // done
    WEB_CONTRACTOR_DOCUMENT_CREATE: 147, // done
    WEB_CONTRACTOR_DOCUMENT_UPDATE: 148, // done
    WEB_CONTRACTOR_DOCUMENT_DELETE: 149, // done
    WEB_SUPPLIER_VEHICLE_DOCUMENT_MENU: 150, // done
    WEB_SUPPLIER_VEHICLE_DOCUMENT_READ: 151, // done
    WEB_SUPPLIER_VEHICLE_DOCUMENT_CREATE: 152, // done
    WEB_SUPPLIER_VEHICLE_DOCUMENT_UPDATE: 153, // no found
    WEB_SUPPLIER_VEHICLE_DOCUMENT_DELETE: 154, // done
    WEB_CONTRACTOR_VEHICLE_DOCUMENT_MENU: 155,
    WEB_CONTRACTOR_VEHICLE_DOCUMENT_READ: 156,
    WEB_CONTRACTOR_VEHICLE_DOCUMENT_CREATE: 157,
    WEB_CONTRACTOR_VEHICLE_DOCUMENT_UPDATE: 158,
    WEB_CONTRACTOR_VEHICLE_DOCUMENT_DELETE: 159,
    WEB_WORK_SHIFT_MENU: 160, // done
    WEB_WORK_SHIFT_READ: 161, // done
    WEB_WORK_SHIFT_CREATE: 162, // done
    WEB_WORK_SHIFT_UPDATE: 163, // not found
    WEB_WORK_SHIFT_DELETE: 164, // not found
    WEB_WORK_SHIFT_MANAGE_USERS: 165, // done
    WEB_PRIVILEGE_MENU: 170, // done
    WEB_PRIVILEGE_READ: 171, // done
    WEB_PRIVILEGE_CREATE: 172, // done
    WEB_PRIVILEGE_UPDATE: 173, // done
    WEB_PRIVILEGE_DELETE: 174, // done
    WEB_PRIVILEGE_MANAGE_USERS: 175,
    WEB_VEHICLE_MENU: 180, // done
    WEB_VEHICLE_READ: 181, // done
    WEB_VEHICLE_CREATE: 182, // done
    WEB_VEHICLE_UPDATE: 183, // done
    WEB_VEHICLE_DELETE: 184, // not found
    WEB_ZONE_MENU: 190, // done
    WEB_ZONE_READ: 191, // ode
    WEB_ZONE_CREATE: 192, // done
    WEB_ZONE_UPDATE: 193, // done
    WEB_ZONE_DELETE: 194, // done
    WEB_ZONE_MANAGE_USERS: 195, // done
    WEB_DEVICE_MENU: 200, // done
    WEB_DEVICE_READ: 201, // done
    WEB_DEVICE_CREATE: 202, // done
    WEB_DEVICE_UPDATE: 203, // done
    WEB_DEVICE_DELETE: 204, // done
    WEB_DEVICE_EXPORT: 205,
    /*  WEB_ZONE_READ_DEVICES: 205, // SHOW (BUTTON/VIEW)  // done */
    WEB_ZONE_CREATE_DEVICES: 206, // ADD NEW PLANE // done
    WEB_ZONE_UPDATE_DEVICES: 207, // UPDATE /ADD/REMOVE LOCATION(PIN) // done
    WEB_ZONE_DELETE_DEVICES: 208, // DELETE PLANE // done
    WEB_ZONE_OPEN_THE_DOOR_DEVICE: 209,

    WEB_REPORTS_MENU: 210,
    WEB_REPORTS_READ: 211,
    WEB_REPORTS_EXPORT: 212,
    WEB_BACK_UP_MENU: 220, // done
    WEB_BACK_UP_READ: 221, // done
    WEB_BACK_UP_CREATE: 222, // done
    WEB_BACK_UP_FULL_RESTORE: 223, // done
    WEB_BACK_UP_PARTIAL_RESTORE: 224,
    WEB_PAYROLL_MENU: 230, // done
    WEB_PAYROLL_CREATE: 231, // not found
    WEB_PAYROLL_UPDATE: 232, // not found
    WEB_PAYROLL_READ: 233, // done
    WEB_ONBOARDING_MENU: 240, // done
    WEB_ONBOARDING_CREATE: 241, // done
    WEB_ONBOARDING_UPDATE: 242, // done
    WEB_ONBOARDING_READ: 243, // done
    WEB_ONBOARDING_DELETE: 244, // done
    WEB_HOSPITALITY_MENU: 250, // done
    WEB_HOSPITALITY_CREATE: 251, // done
    WEB_HOSPITALITY_UPDATE: 252, // done
    WEB_HOSPITALITY_READ: 253, // done
    WEB_HOSPITALITY_DELETE: 254, // done
    WEB_EMAIL_SETTINGS_MENU: 260, // done
    WEB_EMAIL_SETTINGS_CREATE: 261, // not found
    WEB_EMAIL_SETTINGS_UPDATE: 262, // done
    WEB_EMAIL_SETTINGS_READ: 263, // done
    WEB_NOTIFICATION_MENU: 270,

    WEB_LOG_MENU: 280,
    WEB_LOG_READ: 281,
    WEB_LOG_EXPORT: 282,
    WEB_ACCESS_HISTORY_MENU: 290,
    WEB_ACCESS_USER_READ: 291,
    WEB_ACCESS_USER_EXPORT: 292,
    WEB_ACCESS_VEHICLE_READ: 296,
    WEB_ACCESS_VEHICLE_EXPORT: 297,

    WEB_SUPPLIER_MENU: 300, // done
    WEB_SUPPLIER_CREATE: 301,
    WEB_SUPPLIER_UPDATE: 302,
    WEB_SUPPLIER_DELETE: 303,
    WEB_SUPPLIER_READ: 304,
    WEB_SUPPLIER_FORM: 305,
    WEB_SUPPLIER_ORDER_READ: 306,
    WEB_SUPPLIER_ORDER_UPDATE: 307,
    WEB_SUPPLIER_ORDER_DELETE: 308,
    WEB_SUPPLIER_ORDER_CREATE: 309,
    WEB_CONTRACTOR_MENU: 320, // done
    WEB_CONTRACTOR_CREATE: 321, // done
    WEB_CONTRACTOR_UPDATE: 322,
    WEB_CONTRACTOR_DELETE: 323,
    WEB_CONTRACTOR_READ: 324, // done
    WEB_CONTRACTOR_FORM: 325,
    WEB_CONTRACTOR_CONTRACT_READ: 326,
    WEB_CONTRACTOR_CONTRACT_UPDATE: 327,
    WEB_CONTRACTOR_CONTRACT_DELETE: 328,
    WEB_CONTRACTOR_CONTRACT_CREATE: 329,
    WEB_EVENT_MENU: 340, // done
    WEB_EVENT_READ: 341, // done
    WEB_EVENT_CREATE: 342, // done
    WEB_EVENT_UPDATE: 343, // done
    WEB_EVENT_DELETE: 344, // done
    WEB_ATTENDANCE_MENU: 350,
    WEB_ATTENDANCE_READ: 351,
    WEB_ATTENDANCE_CREATE: 352,
    WEB_ATTENDANCE_UPDATE: 353,
    WEB_ATTENDANCE_DELETE: 354,
    WEB_DEPARTMENT_MENU: 360,
    WEB_DEPARTMENT_READ: 361,
    WEB_DEPARTMENT_CREATE: 362,
    WEB_DEPARTMENT_UPDATE: 363,
    WEB_DEPARTMENT_DELETE: 364,
    WEB_HOLIDAY_READ: 371,
    WEB_HOLIDAY_CREATE: 372,
    WEB_HOLIDAY_UPDATE: 373,
    WEB_HOLIDAY_DELETE: 374,
    WEB_HOME_OFFICE_READ: 401,

    WEB_CARD_READ: 381,
    WEB_CARD_CREATE: 382,
    WEB_CARD_UPDATE: 383,
    WEB_CARD_DELETE: 384,
    WEB_CARD_DESIGNER_MENU: 430,
    WEB_CARD_DESIGNER_READ: 431,
    WEB_CARD_DESIGNER_CREATE: 432,
    WEB_CARD_DESIGNER_UPDATE: 433,
    WEB_CARD_DESIGNER_DELETE: 434,
    WEB_ANNOUNCEMENT_READ: 271,
    WEB_ANNOUNCEMENT_CREATE: 272,
    WEB_ANNOUNCEMENT_DELETE: 273,
    WEB_ANNOUNCEMENT_EXPORT: 274,
    WEB_GUARD_READ: 411,
    WEB_LOG_PLATFORM_READ: 421,
    WEB_LOG_PLATFORM_EXPORT: 422,
    WEB_LOG_ACTION_READ: 426,
    WEB_LOG_ACTION_EXPORT: 427,
    WEB_RESOURCES_READ: 451,
    WEB_RESOURCES_CREATE: 452,
    WEB_RESOURCES_UPDATE: 453,
    WEB_RESOURCES_DELETE: 454,
    WEB_HID_MENU: 460,
    WEB_HID_READ: 461,
    WEB_HID_CREATE: 462,
    WEB_HID_UPDATE: 463,
    WEB_HID_DELETE: 464,
};
