import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import NotInformation from '../../components/NotInformation';
import DeleteModal from '../Modals/DeleteModal';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Circle, Image, Layer, Rect, Stage, Text } from 'react-konva';
import { MODELS } from '../../Apis/Models';
import { TABLES } from '../../Apis/Tables';
import { permissionObj } from '../../Helpers/permission';
import SearchFilterBtn from '../../components/buttons/SearchFilterBtn';
import { SearchByFilters } from '../../reduxToolkit/Search/SearchApi';
import { clearSearchFilters } from '../../reduxToolkit/Search/SearchSlice';
import SearchFor from '../Modals/SearchFor';
import ResourcesPanel from './ResourcesPanel';
import { SimpleSearch } from '../../reduxToolkit/Search/SearchApi';
import SearchIcon from '@mui/icons-material/Search';
import ExportDataBtn from '../../components/buttons/ExportDataBtn';
import { DeleteItemsApi } from '../../reduxToolkit/Commons/CommonsApi';

const CardDesignerPanel = () => {
    // hook
    const lCode = Cookies.get('i18next') || 'en';
    const { t } = useTranslation();
    const navigate = useNavigate();

    // useState
    const [orderby, setOrderby] = useState('id');
    const [sort, setSort] = useState();
    const [checked, setChecked] = useState(true);
    const [show, setShow] = useState(false);
    const [view, setView] = useState('grid');
    const [toggleState, setToggleState] = useState(1);
    const [showIncome, setShowIncome] = useState(false);
    const [allFilters, setAllFilters] = useState('id');
    const [orderBy, setOrderBy] = useState();
    const [sortBy, setSortBy] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [selectContractForDelete, setSelectContractForDelete] = useState([]);
    const [deleteCardShow, setDeleteCardShow] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectContractorForDelete, setSelectContractorForDelete] = useState([]);
    const [deleteContractorShow, setDeleteContractorShow] = useState(false);
    const [isAllCheckedContractor, setIsAllCheckedContractor] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchElement, setSearchElement] = useState('');
    const [dropDownProps, setDropDownProps] = useState({
        panel: 'contractor',
        firstItem: 'ALLOW EVENT',
        secondItem: 'VIEW DETAILS',
    });
    const [bodyForExports, setBodyForExports] = useState('');
    const [searchResults, setSearchResults] = useState('');

    // useSelector
    let dispatch = useDispatch();
    const { searchByFilters } = useSelector((state) => state.SearchSlice);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    // Props to the filter window
    const [moduleId, setModuleId] = useState(`${MODELS.Contract}`);
    const [option, setOption] = useState(`${TABLES.CONTRACTS}`);
    // function
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // toggele function
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const handlFilters = (order, sort) => {
        setOrderBy(order);
        setSortBy(sort);
    };

    // functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    let contractPagination = {
        order: sortBy === 'asc' ? true : false,
        page: page,
        size: rowsPerPage,
        sortBy: orderBy ? orderBy : 'id',
    };

    const stageFront = useRef();
    const stageBack = useRef();

    const cardWidth = 204;
    const cardHeight = 325;
    const cardBackgroundColor = 'white';

    // useEffect to avoid first load
    useEffect(() => {
        let option = '';
        if (toggleState === 1) {
            option = TABLES.TEMPLATE;
        } else if (toggleState === 2) {
            option = TABLES.RESOURCES;
        }

        const body = {
            pagination: {
                page: page,
                size: rowsPerPage,
            },
            searchCriteriaList: [],
        };

        dispatch(SearchByFilters({ option, body }));

        setLoaded(true);
    }, [toggleState, rowsPerPage, page]);
    // }, []);
    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    // useEffect to check automatically all the items when page, rowsPerPage, or search change
    useEffect(() => {
        if (isAllChecked) {
            const selectAllIds = searchByFilters?.content?.map((item) => item?.id);
            setSelectContractForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
        if (isAllCheckedContractor) {
            const selectAllIds = searchByFilters?.content?.map((item) => item?.id);
            setSelectContractorForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [searchByFilters]);

    let resultsToShow;
    if (
        !searchResults ||
        searchResults?.content?.length === 0 ||
        searchResults === null ||
        searchResults === undefined
    ) {
        resultsToShow = searchByFilters;
        if (!searchResults) {
            resultsToShow = searchByFilters;
        } else {
            resultsToShow = null;
        }
    } else if (searchResults?.content?.length > 0) {
        resultsToShow = searchResults;
    }
    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            // const selectAllIds = searchByFilters?.content?.map((item) => {
            const selectAllIds = resultsToShow?.content?.map((item) => {
                return item?.id;
            });
            setSelectContractForDelete(selectAllIds);
        } else {
            setSelectContractForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectContractForDelete([...selectContractForDelete, e.target.id]);
        } else {
            setSelectContractForDelete(selectContractForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    //Clean any checkbox when toggle change
    useEffect(() => {
        resetAllCheckboxes();
        setSelectContractForDelete([]);
        setSelectContractorForDelete([]);
        setIsAllChecked(false);
        setIsAllCheckedContractor(false);
    }, [toggleState, view]);

    // This components are used to calculate the distance between the top of the window and the top of the table panel
    const elementRef = useRef(null);
    const elementRefer = useRef(null);
    useEffect(() => {
        if (elementRefer.current && toggleState === 1) {
            const rect = elementRefer.current.getBoundingClientRect();
            const distanceTop = rect.top + 11;
            elementRefer.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        if (elementRef.current && toggleState === 2) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 11;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
    }, [toggleState, view, checked, orderby, rowsPerPage, page, sortBy]);

    //Create body to get the respectively search
    useEffect(() => {}, [loaded, checked, page, rowsPerPage, orderBy, sortBy, finalArray, toggleState]);

    var now = new Date();
    var nowIso = now.toISOString(); //"2020-07-24T06:11:49.911Z"
    var nowInMilliseconds = Date.parse(nowIso);
    var date = new Date('11/21/1987 16:00:00'); // some mock date
    var milliseconds = date.getTime();

    //This fragment makes uncheked all the checkboxes when toggleState change
    const resetAllCheckboxes = () => {
        const checkboxes = document.querySelectorAll('.checkbox');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    };

    const handleStatus = (paramId) => {
        return paramId === 2
            ? '#F2A100'
            : paramId === 3
              ? 'blue'
              : paramId === 4
                ? '#0C4523'
                : paramId === 5
                  ? 'orange'
                  : paramId === 6
                    ? '#BC0000'
                    : 'black';
    };

    const options = {
        filterType: 'checkbox',
    };

    const deleteSelectedCard = (deleteItem) => {
        const tableName = 'template';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setDeleteCardShow(false);
                        setSelectContractForDelete([]);
                        let option = toggleState === 1 ? TABLES.TEMPLATE : TABLES.RESOURCES;
                        const body = {
                            pagination: {
                                page: page,
                                size: rowsPerPage,
                            },
                            searchCriteriaList: [],
                        };

                        dispatch(SearchByFilters({ option, body }));
                    }
                },
            );
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            filterCardDesignByElement();
        }
    };
    const filterCardDesignByElement = async () => {
        if (searchElement) {
            let body = {
                key: searchElement,
                model: TABLES.TEMPLATE,
                pagination: {
                    page: page,
                    size: rowsPerPage,
                },
            };
            setBodyForExports(body);
            await dispatch(SimpleSearch({ body }))
                .then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success) {
                            setSearchResults(data);
                        }
                    },
                )
                .catch((error) => {
                    console.log('Error in WORKSHIFT: ', error);
                });
        } else {
            setSearchResults('');
        }
    };
    return (
        <>
            <div className='providersPanel contractors'>
                <div className='head'>
                    <div className='headLeft '>
                        <h2 className='mr-2'>{t('card_designer')}</h2>
                    </div>
                </div>
                <div>
                    <div className='row steps-row mb-3 mx-0' id='pills-tab' role='tablist'>
                        {permission?.includes(permissionObj?.WEB_CARD_DESIGNER_READ) && (
                            <div
                                role='presentation'
                                className={`col-6 text-center p-0 tap_hover ${
                                    toggleState === 1 ? 'active_tap' : 'deactive_tap'
                                }`}
                            >
                                <a
                                    className={`steps-global btn ${toggleState === 1 ? 'btn-bordered-global' : ''}`}
                                    onClick={() => {
                                        if (toggleState !== 1) {
                                            setLoaded(false);

                                            setPage(0);
                                            setToggleState(1);
                                            setView('grid');
                                            setRowsPerPage(8);
                                            setOption(`${TABLES.TEMPLATE}`);
                                            setModuleId(`${MODELS.Contract}`);
                                            setFinalArray([]);
                                            if (searchByFilters.content.length > 0) {
                                                dispatch(clearSearchFilters());
                                            }

                                            let option = TABLES.TEMPLATE;
                                            const body = {
                                                pagination: {
                                                    page: page,
                                                    size: rowsPerPage,
                                                },
                                                searchCriteriaList: [],
                                            };

                                            dispatch(SearchByFilters({ option, body })).then(
                                                ({
                                                    payload: {
                                                        data: { data, success },
                                                    },
                                                }) => {
                                                    {
                                                        setSearchResults(null);
                                                        setSearchElement('');
                                                    }
                                                },
                                            );
                                            setLoaded(true);
                                        }
                                    }}
                                    id='pills-home-tab'
                                    data-bs-toggle='pill'
                                    data-bs-target='#pills-home'
                                    type='button'
                                    role='tab'
                                    aria-controls='pills-home'
                                    aria-selected='true'
                                >
                                    <span>{t('templates')}</span>
                                </a>
                            </div>
                        )}
                        {permission?.includes(permissionObj?.WEB_RESOURCES_READ) && (
                            <div
                                role='presentation'
                                className={`col-6 text-center p-0 tap_hover ${
                                    toggleState === 2 ? 'active_tap' : 'deactive_tap'
                                }`}
                            >
                                <a
                                    className={`steps-global btn ${toggleState === 2 ? 'btn-bordered-global' : ''}`}
                                    onClick={() => {
                                        if (toggleState !== 2) {
                                            setLoaded(false);

                                            setPage(0);
                                            setToggleState(2);
                                            setRowsPerPage(8);
                                            setOption(`${TABLES.RESOURCES}`);
                                            setModuleId(`${MODELS.Contractor}`);
                                            setFinalArray([]);
                                            if (searchByFilters.content.length > 0) {
                                                dispatch(clearSearchFilters());
                                            }
                                            let option = TABLES.RESOURCES;

                                            const body = {
                                                pagination: {
                                                    page: page,
                                                    size: rowsPerPage,
                                                },
                                                searchCriteriaList: [],
                                            };

                                            dispatch(SearchByFilters({ option, body }));

                                            setLoaded(true);
                                        }
                                    }}
                                    id='pills-home-tab'
                                    data-bs-toggle='pill'
                                    data-bs-target='#pills-home'
                                    type='button'
                                    role='tab'
                                    aria-controls='pills-home'
                                    aria-selected='true'
                                >
                                    <span>{t('resources')}</span>
                                </a>
                            </div>
                        )}
                    </div>
                    <div className='tab-content' id='pills-tabContent'>
                        <div
                            className={`${toggleState === 1 ? 'tab-pane fade show active ' : 'tab-pane fade'}`}
                            id='pills-home'
                            role='tabpanel'
                            aria-labelledby='pills-home-tab'
                        >
                            <div>
                                <div
                                    className='head d-flex justify-content-space-between'
                                    style={{ position: 'relative', margin: '0rem' }}
                                >
                                    <div>
                                        <section className='searchSimple-section--form'>
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <section className='searchElementByWord-devices--section'>
                                                    <TextField
                                                        label={t('TYPE_SOMETHING_TO_SEARCH')}
                                                        className='searchSimple--TextField'
                                                        onChange={(e) => {
                                                            setSearchElement(e.target.value);
                                                            filterCardDesignByElement(); // Trigger search here
                                                        }}
                                                    />

                                                    <button
                                                        className='filter--devices-btn'
                                                        onKeyDown={handleKeyDown}
                                                        onClick={() => filterCardDesignByElement()}
                                                    >
                                                        <SearchIcon />
                                                    </button>
                                                </section>
                                            </form>
                                        </section>
                                    </div>

                                    <div className='container-top-right-btns'>
                                        {/* ¿Tiene o no tendrá permisos? */}
                                        {permission?.includes(permissionObj?.WEB_CARD_DESIGNER_CREATE) && (
                                            <button
                                                className='add-btn-1'
                                                onClick={() =>
                                                    navigate('/dashboard/employee/card-designer/create-template')
                                                }
                                                id='btn_cdp_create'
                                            >
                                                <i className='fa fa-plus' aria-hidden='true'></i>
                                                {t('create')}
                                            </button>
                                        )}
                                        {permission?.includes(permissionObj?.WEB_CARD_DESIGNER_DELETE) && (
                                            <button
                                                className='delete-btn-1'
                                                disabled={selectContractForDelete?.length === 0}
                                                onClick={() => {
                                                    setDeleteCardShow(true);
                                                }}
                                                id='btn_cdp_delete'
                                            >
                                                <i className='fa fa-trash-o' aria-hidden='true'></i>
                                                {t('delete')}
                                            </button>
                                        )}
                                        <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                                    </div>

                                    {showIncome && (
                                        <div
                                            className='col-md-3 filter_parent'
                                            style={{
                                                right: '50px',
                                                top: '0px',
                                                zIndex: '101',
                                            }}
                                        >
                                            <p className='filter_header'>
                                                {t('filters')}
                                                <CloseIcon
                                                    style={{
                                                        marginTop: '10px',
                                                        color: 'red',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => setShowIncome(false)}
                                                />
                                            </p>
                                            <div className='filter_body d-flex justify-content-between py-3'>
                                                <div className='col-md-12'>
                                                    <p>{t('attributes')}</p>
                                                    <Box
                                                        style={{ marginTop: '20px !important' }}
                                                        className='mt-2'
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: '100%',
                                                            fontSize: '20px',
                                                            height: '40px',
                                                        }}
                                                    >
                                                        <FormControl fullWidth>
                                                            <InputLabel>{t('order_by')}</InputLabel>
                                                            <Select
                                                                size='small'
                                                                value={orderby}
                                                                id='slt_cdp_orderBy'
                                                                label={t('order_by')}
                                                                onChange={(e) => setOrderby(e.target.value)}
                                                            >
                                                                {allFilters &&
                                                                    allFilters?.map((item) => {
                                                                        return <MenuItem value={item}>{item}</MenuItem>;
                                                                    })}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                    <Box
                                                        className='mt-2'
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: '100%',
                                                            fontSize: '20px',
                                                            height: '40px',
                                                        }}
                                                    >
                                                        <FormControl fullWidth>
                                                            <InputLabel id='demo-simple-select-label'>
                                                                {t('sort')}
                                                            </InputLabel>
                                                            <Select
                                                                size='small'
                                                                value={sortBy}
                                                                id='slt_cdp_sortBy'
                                                                label={t('sort')}
                                                                onChange={(e) => setSortBy(e.target.value)}
                                                            >
                                                                <MenuItem
                                                                    value={10}
                                                                    sx={{
                                                                        fontSize: '16px',
                                                                    }}
                                                                >
                                                                    ASC
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={20}
                                                                    sx={{
                                                                        fontSize: '16px',
                                                                    }}
                                                                >
                                                                    DES
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={'row mt-0 px-0 mx-0'} ref={elementRefer}>
                                    {view && (
                                        <>
                                            {resultsToShow && resultsToShow?.content?.length > 0 && (
                                                <div className='d-flex mr-0 pl-0'>
                                                    <FormControlLabel
                                                        className='grid-checkall'
                                                        control={
                                                            <Checkbox
                                                                label='Label'
                                                                checked={isAllChecked}
                                                                onChange={handelDeleteAll}
                                                                size='small'
                                                                id='cb_cdp_checkbox'
                                                                disabled={
                                                                    !resultsToShow?.content?.length ||
                                                                    resultsToShow?.content[0]?.whoCreatedId ===
                                                                        undefined
                                                                }
                                                            />
                                                        }
                                                        label={t('de_/_select_all')}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <div className='d-flex flex-wrap px-0 mx-0 mt-3'>
                                        {loaded && toggleState === 1 && resultsToShow?.content?.length > 0 ? (
                                            <>
                                                {resultsToShow?.content[0].whoCreatedId !== undefined ? (
                                                    <>
                                                        {view === 'grid' &&
                                                            toggleState === 1 &&
                                                            // searchByFilters?.content?.map((item) => {
                                                            resultsToShow?.content?.map((item) => {
                                                                const startDate = new Date(item?.createdAt);
                                                                const raw = JSON.parse(item?.raw);
                                                                for (let key in raw) {
                                                                    raw[key].combinedObject.forEach((item) => {
                                                                        if (item.liveContent === 'userSelfie') {
                                                                            item.base64 =
                                                                                '/static/media/userDemoImg.1a05a7d972162c340842.png';
                                                                        }
                                                                    });
                                                                }

                                                                return (
                                                                    <div className='panel-grid col-md-4 col-lg-3 px-0 mx-0 mb-3 contentCard'>
                                                                        <div className='card-base-grid pb-3 h-100'>
                                                                            <div className='top-heading-card'>
                                                                                <Checkbox
                                                                                    className='grid-checkall checkbox'
                                                                                    checked={selectContractForDelete?.includes(
                                                                                        item?.id,
                                                                                    )}
                                                                                    id={item?.id}
                                                                                    onChange={handleCheckboxChange}
                                                                                    size='small'
                                                                                />
                                                                            </div>
                                                                            <div className='card-body-grid'>
                                                                                <div className='p-2'>
                                                                                    <div className='card-content-grid'>
                                                                                        <span className='font-weight-bolder'>
                                                                                            {t('name')}
                                                                                        </span>
                                                                                        <span className=' text-right'>
                                                                                            {item?.name || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='row w-100 no-grid-gap mt-3 contentImgCard'>
                                                                                        <div className='col-6 preview'>
                                                                                            <div>
                                                                                                <p className='preview-tilte font-weight-bolder text-center'>
                                                                                                    {t('front')}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='preview-container'>
                                                                                                <Stage
                                                                                                    ref={stageFront}
                                                                                                    width={cardWidth}
                                                                                                    height={cardHeight}
                                                                                                    scaleX={1}
                                                                                                    scaleY={1}
                                                                                                >
                                                                                                    <Layer>
                                                                                                        {/* Background color */}
                                                                                                        <Rect
                                                                                                            id='background'
                                                                                                            width={
                                                                                                                cardWidth
                                                                                                            }
                                                                                                            height={
                                                                                                                cardHeight
                                                                                                            }
                                                                                                            fill={
                                                                                                                cardBackgroundColor
                                                                                                            }
                                                                                                        />

                                                                                                        {item &&
                                                                                                            raw[1].combinedObject.map(
                                                                                                                (
                                                                                                                    shape,
                                                                                                                    index,
                                                                                                                ) => {
                                                                                                                    if (
                                                                                                                        shape.property ===
                                                                                                                            'squares' ||
                                                                                                                        shape.property ===
                                                                                                                            'lines'
                                                                                                                    ) {
                                                                                                                        return (
                                                                                                                            <Rect
                                                                                                                                x={
                                                                                                                                    shape.x -
                                                                                                                                    40
                                                                                                                                }
                                                                                                                                y={
                                                                                                                                    shape.y
                                                                                                                                }
                                                                                                                                rotation={
                                                                                                                                    shape.rotation
                                                                                                                                }
                                                                                                                                width={
                                                                                                                                    shape.width
                                                                                                                                }
                                                                                                                                height={
                                                                                                                                    shape.height
                                                                                                                                }
                                                                                                                                cornerRadius={
                                                                                                                                    shape.cornerRadius
                                                                                                                                }
                                                                                                                                stroke={
                                                                                                                                    shape.stroke
                                                                                                                                }
                                                                                                                                strokeWidth={
                                                                                                                                    shape.strokeWidth
                                                                                                                                }
                                                                                                                                id={
                                                                                                                                    shape.id
                                                                                                                                }
                                                                                                                                fill={
                                                                                                                                    shape.fill
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    } else if (
                                                                                                                        shape.property ===
                                                                                                                        'ellipsis'
                                                                                                                    ) {
                                                                                                                        return (
                                                                                                                            <Circle
                                                                                                                                x={
                                                                                                                                    shape.x -
                                                                                                                                    40
                                                                                                                                }
                                                                                                                                y={
                                                                                                                                    shape.y -
                                                                                                                                    20
                                                                                                                                }
                                                                                                                                rotation={
                                                                                                                                    shape.rotation
                                                                                                                                }
                                                                                                                                radius={
                                                                                                                                    shape.radius
                                                                                                                                }
                                                                                                                                fill={
                                                                                                                                    shape.fill
                                                                                                                                }
                                                                                                                                stroke={
                                                                                                                                    shape.stroke
                                                                                                                                }
                                                                                                                                strokeWidth={
                                                                                                                                    shape.strokeWidth
                                                                                                                                }
                                                                                                                                id={
                                                                                                                                    shape.id
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    } else if (
                                                                                                                        shape.property ===
                                                                                                                        'texts'
                                                                                                                    ) {
                                                                                                                        return (
                                                                                                                            <Text
                                                                                                                                x={
                                                                                                                                    shape.x -
                                                                                                                                    40
                                                                                                                                }
                                                                                                                                y={
                                                                                                                                    shape.y -
                                                                                                                                    20
                                                                                                                                }
                                                                                                                                text={
                                                                                                                                    shape.content
                                                                                                                                }
                                                                                                                                fontSize={
                                                                                                                                    shape.fontSize
                                                                                                                                }
                                                                                                                                fontFamily={
                                                                                                                                    shape.fontFamily
                                                                                                                                }
                                                                                                                                fontStyle={
                                                                                                                                    shape.fontStyle
                                                                                                                                }
                                                                                                                                textDecoration={
                                                                                                                                    shape.textDecoration
                                                                                                                                }
                                                                                                                                fill={
                                                                                                                                    shape.fill
                                                                                                                                }
                                                                                                                                align={
                                                                                                                                    shape.align
                                                                                                                                }
                                                                                                                                width={
                                                                                                                                    shape.width
                                                                                                                                }
                                                                                                                                height={
                                                                                                                                    shape.height
                                                                                                                                }
                                                                                                                                wrap={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                id={
                                                                                                                                    shape.id
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    } else if (
                                                                                                                        shape.property ===
                                                                                                                        'resources'
                                                                                                                    ) {
                                                                                                                        const imageObject =
                                                                                                                            new window.Image();
                                                                                                                        imageObject.src =
                                                                                                                            shape.base64;
                                                                                                                        return (
                                                                                                                            <Image
                                                                                                                                image={
                                                                                                                                    imageObject
                                                                                                                                }
                                                                                                                                x={
                                                                                                                                    shape.x -
                                                                                                                                    40
                                                                                                                                }
                                                                                                                                y={
                                                                                                                                    shape.y -
                                                                                                                                    30
                                                                                                                                }
                                                                                                                                rotation={
                                                                                                                                    shape.rotation
                                                                                                                                }
                                                                                                                                width={
                                                                                                                                    shape.width
                                                                                                                                }
                                                                                                                                height={
                                                                                                                                    shape.height
                                                                                                                                }
                                                                                                                                id={
                                                                                                                                    shape.id
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    }
                                                                                                                    return null;
                                                                                                                },
                                                                                                            )}
                                                                                                    </Layer>
                                                                                                </Stage>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-6 preview'>
                                                                                            <p className='preview-tilte font-weight-bolder text-center'>
                                                                                                {t('back')}
                                                                                            </p>
                                                                                            <div className='preview-container'>
                                                                                                <Stage
                                                                                                    ref={stageBack}
                                                                                                    width={cardWidth}
                                                                                                    height={cardHeight}
                                                                                                    scaleX={1}
                                                                                                    scaleY={1}
                                                                                                >
                                                                                                    <Layer>
                                                                                                        {/* Background color */}
                                                                                                        <Rect
                                                                                                            id='background'
                                                                                                            width={
                                                                                                                cardWidth
                                                                                                            }
                                                                                                            height={
                                                                                                                cardHeight
                                                                                                            }
                                                                                                            fill={
                                                                                                                cardBackgroundColor
                                                                                                            }
                                                                                                        />

                                                                                                        {item &&
                                                                                                            raw[2].combinedObject.map(
                                                                                                                (
                                                                                                                    shape,
                                                                                                                    index,
                                                                                                                ) => {
                                                                                                                    if (
                                                                                                                        shape.property ===
                                                                                                                            'squares' ||
                                                                                                                        shape.property ===
                                                                                                                            'lines'
                                                                                                                    ) {
                                                                                                                        return (
                                                                                                                            <Rect
                                                                                                                                x={
                                                                                                                                    shape.x -
                                                                                                                                    40
                                                                                                                                }
                                                                                                                                y={
                                                                                                                                    shape.y -
                                                                                                                                    20
                                                                                                                                }
                                                                                                                                rotation={
                                                                                                                                    shape.rotation
                                                                                                                                }
                                                                                                                                width={
                                                                                                                                    shape.width
                                                                                                                                }
                                                                                                                                height={
                                                                                                                                    shape.height
                                                                                                                                }
                                                                                                                                cornerRadius={
                                                                                                                                    shape.cornerRadius
                                                                                                                                }
                                                                                                                                stroke={
                                                                                                                                    shape.stroke
                                                                                                                                }
                                                                                                                                strokeWidth={
                                                                                                                                    shape.strokeWidth
                                                                                                                                }
                                                                                                                                id={
                                                                                                                                    shape.id
                                                                                                                                }
                                                                                                                                //ref={shapeRef}
                                                                                                                                fill={
                                                                                                                                    shape.fill
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    } else if (
                                                                                                                        shape.property ===
                                                                                                                        'ellipsis'
                                                                                                                    ) {
                                                                                                                        return (
                                                                                                                            <Circle
                                                                                                                                x={
                                                                                                                                    shape.x -
                                                                                                                                    40
                                                                                                                                }
                                                                                                                                y={
                                                                                                                                    shape.y -
                                                                                                                                    20
                                                                                                                                }
                                                                                                                                rotation={
                                                                                                                                    shape.rotation
                                                                                                                                }
                                                                                                                                radius={
                                                                                                                                    shape.radius
                                                                                                                                }
                                                                                                                                fill={
                                                                                                                                    shape.fill
                                                                                                                                }
                                                                                                                                stroke={
                                                                                                                                    shape.stroke
                                                                                                                                }
                                                                                                                                strokeWidth={
                                                                                                                                    shape.strokeWidth
                                                                                                                                }
                                                                                                                                id={
                                                                                                                                    shape.id
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    } else if (
                                                                                                                        shape.property ===
                                                                                                                        'texts'
                                                                                                                    ) {
                                                                                                                        return (
                                                                                                                            <Text
                                                                                                                                x={
                                                                                                                                    shape.x -
                                                                                                                                    40
                                                                                                                                }
                                                                                                                                y={
                                                                                                                                    shape.y -
                                                                                                                                    20
                                                                                                                                }
                                                                                                                                text={
                                                                                                                                    shape.content
                                                                                                                                }
                                                                                                                                fontSize={
                                                                                                                                    shape.fontSize
                                                                                                                                }
                                                                                                                                fontFamily={
                                                                                                                                    shape.fontFamily
                                                                                                                                }
                                                                                                                                fontStyle={
                                                                                                                                    shape.fontStyle
                                                                                                                                }
                                                                                                                                textDecoration={
                                                                                                                                    shape.textDecoration
                                                                                                                                }
                                                                                                                                fill={
                                                                                                                                    shape.fill
                                                                                                                                }
                                                                                                                                align={
                                                                                                                                    shape.align
                                                                                                                                }
                                                                                                                                width={
                                                                                                                                    shape.width
                                                                                                                                }
                                                                                                                                height={
                                                                                                                                    shape.height
                                                                                                                                }
                                                                                                                                wrap={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                id={
                                                                                                                                    shape.id
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    } else if (
                                                                                                                        shape.property ===
                                                                                                                        'resources'
                                                                                                                    ) {
                                                                                                                        const imageObject =
                                                                                                                            new window.Image();
                                                                                                                        imageObject.src =
                                                                                                                            shape.base64;
                                                                                                                        return (
                                                                                                                            <Image
                                                                                                                                image={
                                                                                                                                    imageObject
                                                                                                                                }
                                                                                                                                x={
                                                                                                                                    shape.x -
                                                                                                                                    40
                                                                                                                                }
                                                                                                                                y={
                                                                                                                                    shape.y -
                                                                                                                                    30
                                                                                                                                }
                                                                                                                                rotation={
                                                                                                                                    shape.rotation
                                                                                                                                }
                                                                                                                                width={
                                                                                                                                    shape.width
                                                                                                                                }
                                                                                                                                height={
                                                                                                                                    shape.height
                                                                                                                                }
                                                                                                                                id={
                                                                                                                                    shape.id
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    }
                                                                                                                    return null;
                                                                                                                },
                                                                                                            )}
                                                                                                    </Layer>
                                                                                                </Stage>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span className='viewcard-container-link mt-2 d-flex'>
                                                                                <Link
                                                                                    to={'create-template'}
                                                                                    state={{ state: item }}
                                                                                >
                                                                                    {t('details')}{' '}
                                                                                    <KeyboardArrowRightIcon
                                                                                        style={{
                                                                                            transform:
                                                                                                lCode === 'ar'
                                                                                                    ? 'scaleX(-1)'
                                                                                                    : '',
                                                                                        }}
                                                                                    />
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </>
                                                ) : null}
                                            </>
                                        ) : (
                                            <NotInformation text={t('no_information')} card={true} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {resultsToShow && resultsToShow?.content?.length > 0 ? (
                                <div className='pagination-container'>
                                    <div className='d-flex justify-content-center'>
                                        <TablePagination
                                            component='div'
                                            rowsPerPageOptions={view === 'grid' ? [8, 16, 24, 32] : [20, 40, 60]}
                                            count={resultsToShow?.totalElements}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            labelRowsPerPage={t('templates_per_page')}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div
                            className={`${toggleState === 2 ? 'tab-pane fade show active ' : 'tab-pane fade'}`}
                            id='pills-profile'
                            role='tabpanel'
                            aria-labelledby='pills-profile-tab'
                        >
                            <ResourcesPanel toggleState={toggleState} />
                        </div>
                    </div>
                </div>
            </div>
            {/* modal for delete contract */}
            <DeleteModal
                show={deleteCardShow}
                onHide={() => setDeleteCardShow(false)}
                onClickFn={() => deleteSelectedCard(selectContractForDelete)}
                data={selectContractForDelete}
                title_modal={t('CARD_DESIGNER_DELETE_CARD')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={toggleState === 2 ? t('resource') : t('template')}
            />
            <SearchFor
                open={filterDialogShow}
                onClose={() => {
                    setFilterDialogShow(false);
                }}
                onFiltered={(originalArray) => {
                    setFilterDialogShow(false);
                    setFinalArray(originalArray);
                }}
                moduleId={moduleId}
                option={option}
                finalArray={finalArray}
            />
        </>
    );
};
export default CardDesignerPanel;
