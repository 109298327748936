import React, { useEffect, useState } from 'react';
import DocumentStatusThree from '../../components/DocumentStatusThree';
import {
    CreateSupplierDocValue,
    DownloadProviderImage,
    GetAllSupplierDocumentsById,
    SetSupplierDocValue,
} from '../../reduxToolkit/Providers/providersApi';
import { GetUserDocumentsEmployee } from '../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import cryptoJs from 'crypto-js';
import securekey from '../../config';
import {
    CreateContractorDocValue,
    GetAllContractorDocuments,
    SetContractorDocValue,
} from '../../reduxToolkit/Contractor/ContractorApi';
import {
    CreateEmployeeDocValue,
    GetAllEmployeeDocuments,
    SetEmployeeDocValue,
} from '../../reduxToolkit/Employee/EmployeeApi';
import DeleteModal from '../Modals/DeleteModal';
import { Checkbox } from '@mui/material';
import { DeleteItemsApi } from '../../reduxToolkit/Commons/CommonsApi';
const UserDocuments = ({ docType }) => {
    // user
    const userdata = sessionStorage.getItem('userdata');
    const bytess = cryptoJs.AES.decrypt(userdata || '', securekey);
    const userstring = bytess.toString(cryptoJs.enc.Utf8);
    const user = userstring ? JSON.parse(userstring)?.data?.data : '';
    // use Hook
    const dispatch = useDispatch();
    // use state
    const [selectDocumentsForDelete, setSelectDocumentsForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteDocShow, setDeleteDocShow] = useState(false);
    const [updated, setUpdated] = useState(0);
    const [deletedFlag, setDeletedFlag] = useState(0);
    // use Selc
    const { getAllSupplierDocumentsById, setSupplierDocValue, createSupplierDocValue } = useSelector(
        (state) => state.providersSlice,
    );
    const { getAllContractorDocuments, createContractorDocValue, setContractorDocValue } = useSelector(
        (state) => state.ContractorSlice,
    );
    const { getUserDocumentsEmployee, createEmployeeDocValue, setEmployeeDocValue } = useSelector(
        (state) => state.EmployeeProviderSlice,
    );
    const { uploadImg, changeCount } = useSelector((state) => state.sharedSlice);

    useEffect(() => {
        switch (docType) {
            case 'supplier':
                dispatch(GetAllSupplierDocumentsById(user?.id));
                break;
            case 'contractor':
                dispatch(GetAllContractorDocuments(user?.id));
                break;
            case 'employee':
                dispatch(GetUserDocumentsEmployee(user?.id));
                break;
            default:
                return null;
        }
    }, [
        createSupplierDocValue,
        setSupplierDocValue,
        uploadImg,
        changeCount,
        createContractorDocValue,
        setContractorDocValue,
        createEmployeeDocValue,
        setEmployeeDocValue,
        createEmployeeDocValue,
        setEmployeeDocValue,
        updated,
        deletedFlag,
    ]);

    const deleteSelectedDocuments = (deleteItem) => {
        const tableName =
            docType === 'contractor'
                ? 'contractor_document'
                : docType === 'supplier'
                  ? 'supplier_document'
                  : 'employee_document';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setDeletedFlag(deletedFlag + 1);
                        setSelectDocumentsForDelete([]);
                        setIsAllChecked(false);
                    }
                },
            );
        }
    };
    return (
        <>
            <div className='documents_status_three_container'>
                <div className='documents_status_section'>
                    <h2 className='title'>{t('documents_to_upload')}</h2>
                    <p className='tagline'>
                        {(docType === 'supplier' && t('please_attach_document_supplier')) ||
                            (docType === 'contractor' && t('please_attach_document_contractor')) ||
                            (docType === 'employee' && t('please_attach_document_employee'))}
                    </p>
                    <button
                        className='delete-btn-1'
                        disabled={selectDocumentsForDelete?.length > 0 ? false : true}
                        onClick={() => setDeleteDocShow(true)}
                    >
                        <i class='fa fa-trash-o' aria-hidden='true'></i>
                        {t('delete')}
                    </button>
                    <div className='documents_status_item'>
                        <DocumentStatusThree
                            dataTable={
                                (docType === 'supplier' && getAllSupplierDocumentsById) ||
                                (docType === 'contractor' && getAllContractorDocuments) ||
                                (docType === 'employee' && getUserDocumentsEmployee)
                            }
                            approve={true}
                            userType={docType}
                            optionDownload={
                                (docType === 'supplier' && 'supplier_document') ||
                                (docType === 'contractor' && 'contractor_document') ||
                                (docType === 'employee' && 'employee_document')
                            }
                            isAllChecked={isAllChecked}
                            setIsAllChecked={setIsAllChecked}
                            selectDocumentsForDelete={selectDocumentsForDelete}
                            setSelectDocumentsForDelete={setSelectDocumentsForDelete}
                            setDocValue={
                                (docType === 'supplier' && SetSupplierDocValue) ||
                                (docType === 'contractor' && SetContractorDocValue) ||
                                (docType === 'employee' && SetEmployeeDocValue)
                            }
                            createDocValue={
                                (docType === 'supplier' && CreateSupplierDocValue) ||
                                (docType === 'contractor' && CreateContractorDocValue) ||
                                (docType === 'employee' && CreateEmployeeDocValue)
                            }
                            downloadImg={DownloadProviderImage}
                            onUpdate={() => {
                                setUpdated(updated + 1);
                            }}
                            updated={updated}
                        />
                    </div>
                </div>
            </div>

            <DeleteModal
                show={deleteDocShow}
                onHide={() => setDeleteDocShow(false)}
                data={selectDocumentsForDelete}
                title_modal={t('delete_documents')}
                element_modal={t('documents')}
                isReset={setSelectDocumentsForDelete}
                isAllReset={setIsAllChecked}
                onClickFn={() => deleteSelectedDocuments(selectDocumentsForDelete)}
            />
        </>
    );
};

export default UserDocuments;
