import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SidebarDropDownOption from '../../../components/SidebarDropDownOption';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Green_contractor } from '../../../assets/images/green-contractor.svg';
import { ReactComponent as Green_employee } from '../../../assets/images/green-employee.svg';
import { ReactComponent as Green_vehicle } from '../../../assets/images/green-vehicle.svg';
import { ReactComponent as Access_right } from '../../../assets/images/accessRight.svg';
import { ReactComponent as AnnouncementsIcon } from '../../../assets/menuIcon/announcements.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/menuIcon/profile.svg';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { sidebarContractorOptionsEnum } from '../../../enums/sidebarContractorOptionsEnum';
import { GetAllWorkSchdule } from '../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';

const ContractorTabs = ({ isMenuOpen }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHovering, setIsHovering] = useState('1');
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const { user } = useSelector((state) => state.authenticatioauthennSlice);

    const dispatch = useDispatch();
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
    useEffect(() => {
        dispatch(GetAllWorkSchdule());
    }, []);
    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        // setChangeStyle(sidebarContractorOptionsEnum[modulePart])
    }, [location]);

    useEffect(() => {
        setChangeStyle(sidebarContractorOptionsEnum[modulePart]);
    }, [currentUrl]);

    const parts = currentUrl.split('/');
    const modulePart = parts[3] ? parts[3] : '';

    const [changeStyle, setChangeStyle] = useState(sidebarContractorOptionsEnum[modulePart]);

    return (
        <ul className={`${isMenuOpen ? 'largeMenu' : 'shortMenu'} `}>
            {user?.data?.status?.id != 3 && (
                <>
                    <Tooltip
                        title={!isMenuOpen ? t('employees').toUpperCase() : ''}
                        placement='right'
                        disableInteractive
                    >
                        <Link to='/dashboard/contractor/search-employe'>
                            <li
                                onClick={() => setChangeStyle('employees')}
                                className={changeStyle === 'employees' ? 'activeLi' : isHovering === 2 ? '' : ''}
                                onMouseEnter={() => setIsHovering(2)}
                                onMouseLeave={() => setIsHovering(0)}
                            >
                                <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                    <div>
                                        <Green_employee className='svgHover' />
                                    </div>
                                    <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                        {t('employees')}
                                    </span>
                                </div>
                                {/* <img src={angelright_icon} alt="" /> */}
                            </li>
                        </Link>
                    </Tooltip>
                    <Tooltip
                        title={!isMenuOpen ? t('vehicle_fleet').toUpperCase() : ''}
                        placement='right'
                        disableInteractive
                    >
                        <Link to='/dashboard/contractor/search-vehicle'>
                            <li
                                onClick={() => setChangeStyle('vehicles')}
                                className={changeStyle === 'vehicles' ? 'activeLi' : isHovering === 3 ? '' : ''}
                                onMouseEnter={() => setIsHovering(3)}
                                onMouseLeave={() => setIsHovering(0)}
                            >
                                <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                    <div>
                                        <Green_vehicle className='svgHover' />
                                    </div>
                                    <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                        {t('vehicle_fleet')}
                                    </span>
                                </div>
                            </li>
                        </Link>
                    </Tooltip>
                    <Tooltip
                        title={!isMenuOpen ? t('contracts').toUpperCase() : ''}
                        placement='right'
                        disableInteractive
                    >
                        <Link to='/dashboard/contractor/contracts'>
                            <li
                                onClick={() => setChangeStyle('contractors')}
                                className={changeStyle === 'contractors' ? 'activeLi' : isHovering === 1 ? '' : ''}
                                onMouseEnter={() => setIsHovering(1)}
                                onMouseLeave={() => setIsHovering(0)}
                            >
                                <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                    <div>
                                        <Green_contractor className='svgHover' />
                                    </div>
                                    <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                        {t('contractors')}
                                    </span>
                                </div>
                                {/* <img src={angelright_icon} alt="" /> */}
                            </li>
                        </Link>
                    </Tooltip>

                    <Tooltip
                        title={!isMenuOpen ? t('announcements').toUpperCase() : ''}
                        placement='right'
                        disableInteractive
                    >
                        <Link to='/dashboard/contractor/announcementtab'>
                            <li
                                onClick={() => setChangeStyle('announcements')}
                                className={changeStyle === 'announcements' ? 'activeLi' : isHovering === 4 ? '' : ''}
                                onMouseEnter={() => setIsHovering(4)}
                                onMouseLeave={() => setIsHovering(0)}
                            >
                                <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                                    <div>
                                        <AnnouncementsIcon className='svgHover' />
                                    </div>
                                    <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                        {t('announcements')}
                                    </span>
                                </div>
                                {/* <img src={angelright_icon} alt="angelright_icon" /> */}
                            </li>
                        </Link>
                    </Tooltip>
                </>
            )}

            <Tooltip title={!isMenuOpen ? t('profile').toUpperCase() : ''} placement='right' disableInteractive>
                <Link to='/dashboard/contractor/profile'>
                    <li
                        onClick={() => setChangeStyle('profile')}
                        className={changeStyle === 'profile' ? 'activeLi' : isHovering === 5 ? '' : ''}
                        onMouseEnter={() => setIsHovering(5)}
                        onMouseLeave={() => setIsHovering(0)}
                    >
                        <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                            <div>
                                <ProfileIcon className='svgHover' />
                            </div>
                            <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                {t('profile')}
                            </span>
                        </div>
                        {/* <img src={angelright_icon} alt="angelright_icon" /> */}
                    </li>
                </Link>
            </Tooltip>

            <Tooltip title={!isMenuOpen ? t('access-rights').toUpperCase() : ''} placement='right' disableInteractive>
                <Link to='/dashboard/contractor/access-rights'>
                    <li
                        onClick={() => setChangeStyle('access-rights')}
                        className={changeStyle === 'access-rights' ? 'activeLi' : isHovering === 5 ? '' : ''}
                        onMouseEnter={() => setIsHovering(5)}
                        onMouseLeave={() => setIsHovering(0)}
                    >
                        <div className={`infoMenu ${isMenuOpen ? 'infoMOpen' : 'infoMClose'}`}>
                            <div>
                                <Access_right className='svgHover' fill='white' />
                            </div>
                            <span className={` ${isMenuOpen ? 'profile-span' : 'profile-span hiddenText'}`}>
                                {t('ACCESS_RIGHTS')}
                            </span>
                        </div>
                        {/* <img src={angelright_icon} alt="angelright_icon" /> */}
                    </li>
                </Link>
            </Tooltip>

            <Link to='#'>
                <li
                    // onClick={() => { setIsOpen(!isOpen) }}
                    className={`${isMenuOpen ? 'profile_li' : 'profile_li_col'}`}
                >
                    {/* <ProfileDropDown /> */}
                    <SidebarDropDownOption menutop={false} isMenuOpen={isMenuOpen} />
                </li>
            </Link>
        </ul>
    );
};

export default ContractorTabs;
