import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import i18next from 'i18next';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import languageIcon from '../assets/images/ic-language-black.svg';
import ic_check from '../assets/images/ic-check.svg';
import uaeFlag from '../assets/flag/united-arab-emirates.png';
import englishFlag from '../assets/flag/united-states.png';
import spanishFlag from '../assets/flag/spain.png';
import franceFlag from '../assets/flag/france.png';

const languages = [
    {
        id: 2,
        name: 'spanish',
        code: 'sp',
        flag: spanishFlag,
    },
    {
        id: 1,
        name: 'english',
        code: 'en',
        flag: englishFlag,
    },
    {
        id: 3,
        name: 'french',
        code: 'fr',
        flag: franceFlag,
    },
];

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
    const { t } = useTranslation();
    return (
        <div
            ref={ref}
            onClick={(e) => onClick(e)}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '195px',
            }}
        >
            {children}
            <p className='font-user-card ml-0 pl-1'>{t('language')}</p>
            <img
                src={languageIcon}
                style={{
                    width: '1em',
                    height: '1em',
                    fontSize: '1.3rem',
                    marginRight: '8px',
                    padding: '3px',
                }}
                alt='languageIcon'
            />
        </div>
    );
});

const LanguageSelector = ({ isMenuOpen, menutop }) => {
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    const [currentLanguage, setCurrentLanguage] = useState({});
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setCurrentLanguage(languages.find((l) => l.code === currentLanguageCode));
        document.body.dir = currentLanguage?.dir || 'ltr';
        document.body.style.textAlign = currentLanguageCode === 'ar' ? 'right' : 'left';
        document.title = t('app_title');

        window.postMessage(
            {
                type: 'languageChange',
                newValue: currentLanguageCode,
            },
            '*',
        );
    }, [currentLanguage, t]);

    const handleSelect = (code) => {
        i18next.changeLanguage(code);
        setOpen(false);
    };

    return (
        <div className='languageSelector'>
            <Dropdown drop='end' show={open} onToggle={() => setOpen(!open)}>
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu
                    className={
                        (menutop === false &&
                            (currentLanguageCode === 'ar'
                                ? isMenuOpen
                                    ? 'leftArbicL'
                                    : 'leftArbicLClose'
                                : isMenuOpen
                                  ? 'notleftL'
                                  : 'notleftLClose')) ||
                        (menutop === true && (currentLanguageCode === 'ar' ? 'leftArbicLM' : 'notleftLM'))
                    }
                    size='sm'
                    title='Change Languages'
                >
                    {languages?.map((value) => (
                        <div
                            className={`dropdownDivMenu ${currentLanguage.code === value.code ? 'activeDrop' : ''}`}
                            key={value.id}
                            onClick={() => handleSelect(value.code)}
                            style={{
                                padding: '0 10px',
                            }}
                        >
                            <div>
                                <img
                                    src={value.flag}
                                    style={{ width: '38px', height: '38px' }}
                                    className='mx-2'
                                    alt='images'
                                />
                                <span>{t(value.name)}</span>
                            </div>
                            {currentLanguage.code === value.code ? (
                                <img
                                    src={ic_check}
                                    style={{
                                        width: '20px',
                                        height: '10px',
                                    }}
                                    alt='images'
                                />
                            ) : null}
                        </div>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default LanguageSelector;
