import { FormControl, InputLabel, MenuItem, Select, TextField, Grid } from '@mui/material';
import SelectInput from '@mui/material/Select/SelectInput';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import cancel from '../../../../../assets/images/ic-cancel.svg';
import { toast } from 'react-toastify';
import { userTypeDrop } from '../../../../../enums/userTypeEnumDrop';
import { Col, Container, Dropdown, DropdownButton, Form, Modal, Row, Tab, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ApproveExternalDocument } from '../../../../../reduxToolkit/EmployeeProviders/EmployeeProvidersApi';
import { ApproveCompanyEmployeeDocument } from '../../../../../reduxToolkit/Employee/EmployeeApi';
import { ApproveContractorDocument } from '../../../../../reduxToolkit/EmployeeContractors/EmployeeContractorsApi';
import Tooltip from '@mui/material/Tooltip';
import { t } from 'i18next';
import { userType } from '../../../../../enums/userTypeEnum';

import {
    ApproveOrDeniedVehicleContratorDocs,
    ApproveOrDeniedVehicleSupplierDocs,
} from '../../../../../reduxToolkit/ApproveAndDeniedVehiclesDocs/ApproveAndDeniedVehicleDocsApi';

const ApproveDocumentModal = (props) => {
    const [commentText, setCommentText] = useState('');
    const dispatch = useDispatch();
    return (
        <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered className='approveModal'>
            <img onClick={props.onHide} className='dialog-close-custom' src={cancel} alt='' />
            <span className='main-modal-heading'>{t('approve_document')}</span>
            <div className='unlink-modal-body'>
                <span className='modal-desc-text'>
                    {t('are_you_sure_you_want_to_approve_the_document')}
                    <strong>
                        <Tooltip title={props.documentName.toUpperCase()} placement='top'>
                            <p className='ms-1 d-none d-sm-inline ellipsis-one-line'>
                                {props.documentName.length > 30
                                    ? props.documentName?.slice(0, 30) + '...'
                                    : props.documentName}
                            </p>
                        </Tooltip>
                    </strong>
                    {' ' + t('for_the')}
                    <strong>{' ' + t(userTypeDrop[props.userType])}</strong>
                    <strong>{' ' + props.employeeName}</strong>
                    {'?'}
                </span>

                <div className='btn-div'>
                    <button className='button-sec btn-cancel' style={{ color: 'red' }} onClick={props.onHide}>
                        {t('cancel').toUpperCase()}
                    </button>
                    <button
                        className='button-sec btn-confirm'
                        onClick={() => {
                            const data = {
                                comments: '',
                                id: props?.documentId,
                                validated: true,
                            };
                            if (props.isVehicleDocuments === true) {
                                // ¿Es un documento de vehiculo?
                                try {
                                    // Función si es un documento de vehículo de contrator
                                    dispatch(ApproveOrDeniedVehicleContratorDocs(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('approved_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                            }
                                        },
                                    );
                                } catch (error) {
                                    // Función por si el try principal no funciona, que se ejecute
                                    // el de provider
                                    dispatch(ApproveOrDeniedVehicleSupplierDocs(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('approved_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                            }
                                        },
                                    );
                                }
                            } else {
                                // ¿Es un documento de empleado o derivado?
                                if (props.userType === 1) {
                                    dispatch(ApproveCompanyEmployeeDocument(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('approved_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                            }
                                        },
                                    );
                                } else if (props.userType === 3 || props.userType === 4) {
                                    dispatch(ApproveContractorDocument(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('approved_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                            }
                                        },
                                    );
                                } else if (props.userType === 5 || props.userType === 6) {
                                    dispatch(ApproveExternalDocument(data)).then(
                                        ({
                                            payload: {
                                                data: { data, success },
                                            },
                                        }) => {
                                            if (success) {
                                                toast.success(t('approved_successfully').toUpperCase());
                                                props.setUpdated(true);
                                            } else {
                                            }
                                        },
                                    );
                                }
                            }
                            props.onHide();
                        }}
                    >
                        {t('confirm')?.toUpperCase()}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ApproveDocumentModal;
