import { deviceType } from './deviceTypeEnum';
import { gender } from './genderEnum';
import { status } from './statusEnum';
import { userType } from './userTypeEnum';
import { contractorStatus } from './contractorStatusEnum';
import { supplierStatus } from './supplierStatus';
import { accessMethod } from './accessMethodEnum';

export const fieldNameEnum = {
    deviceType,
    gender,
    status,
    userType,
    contractorStatus,
    supplierStatus,
    entryAccessMethod: accessMethod,
    exitAccessMethod: accessMethod,
    accessMethod,
};
