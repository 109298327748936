import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import cancel from '../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';
import { GoPrimitiveDot } from 'react-icons/go';
import downloadIcon from '../../assets/icon/DownloadIcon.svg';
import Dropzone from 'react-dropzone';
import cloudsvg from '../../assets/images/cloud.svg';
import { toast } from 'react-toastify';
import { fileSize } from '../../constant/variable';
import exelIcon from '../../assets/fileIcon/xls.png';
import { DownloadEmployeeForm } from '../../reduxToolkit/Files/FilesApi';
import { useDispatch, useSelector } from 'react-redux';
import { UploadEmployeeFormFile } from '../../reduxToolkit/Files/FilesApi';
import { CreateListEmployee } from '../../reduxToolkit/Files/FilesApi';
import { DownloadContractorEmployeeForm } from '../../reduxToolkit/Files/FilesApi';
import { DownloadSupplierEmployeeForm } from '../../reduxToolkit/Files/FilesApi';
import { UploadContractorEmployeeFormFile } from '../../reduxToolkit/Files/FilesApi';
import { UploadSupplierEmployeeFormFile } from '../../reduxToolkit/Files/FilesApi';
import { CreateListContractorEmployee } from '../../reduxToolkit/Files/FilesApi';
import { CreateListSupplierEmployee } from '../../reduxToolkit/Files/FilesApi';
import { DownloadContractorForm } from '../../reduxToolkit/Files/FilesApi';
import { DownloadSupplierForm } from '../../reduxToolkit/Files/FilesApi';
import { DownloadContractorVehicleForm } from '../../reduxToolkit/Files/FilesApi';
import { DownloadSupplierVehicleForm } from '../../reduxToolkit/Files/FilesApi';
import { DownloadEmployeeVehicleForm } from '../../reduxToolkit/Files/FilesApi';
import { UploadContractorFormFile } from '../../reduxToolkit/Files/FilesApi';
import { UploadSupplierFormFile } from '../../reduxToolkit/Files/FilesApi';
import { UploadContractorVehicleFormFile } from '../../reduxToolkit/Files/FilesApi';
import { UploadSupplierVehicleFormFile } from '../../reduxToolkit/Files/FilesApi';
import { UploadEmployeeVehicleFormFile } from '../../reduxToolkit/Files/FilesApi';
import { CreateListContractor } from '../../reduxToolkit/Files/FilesApi';
import { CreateListSupplier } from '../../reduxToolkit/Files/FilesApi';
import { CreateListContractorVehicle } from '../../reduxToolkit/Files/FilesApi';
import { CreateListSupplierVehicle } from '../../reduxToolkit/Files/FilesApi';
import { CreateListEmployeeVehicle } from '../../reduxToolkit/Files/FilesApi';
import { setExcelFile } from '../../reduxToolkit/Files/FilesSlice';
import { useNavigate } from 'react-router-dom';
const ImportFileModal = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fileData, setFileData] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileDate, setFileDate] = useState('');
    const validFileTypes = ['xlsx'];

    let DownloadForm;
    let UploadForm;
    let CreateList;

    switch (props.model) {
        case 'employee':
            DownloadForm = DownloadEmployeeForm;
            UploadForm = UploadEmployeeFormFile;
            CreateList = CreateListEmployee;
            break;
        case 'contractorEmployee':
            DownloadForm = DownloadContractorEmployeeForm;
            UploadForm = UploadContractorEmployeeFormFile;
            CreateList = CreateListContractorEmployee;
            break;
        case 'supplierEmployee':
            DownloadForm = DownloadSupplierEmployeeForm;
            UploadForm = UploadSupplierEmployeeFormFile;
            CreateList = CreateListSupplierEmployee;
            break;
        case 'contractor':
            DownloadForm = DownloadContractorForm;
            UploadForm = UploadContractorFormFile;
            CreateList = CreateListContractor;
            break;
        case 'supplier':
            DownloadForm = DownloadSupplierForm;
            UploadForm = UploadSupplierFormFile;
            CreateList = CreateListSupplier;
            break;
        case 'contractorVehicle':
            DownloadForm = DownloadContractorVehicleForm;
            UploadForm = UploadContractorVehicleFormFile;
            CreateList = CreateListContractorVehicle;
            break;
        case 'supplierVehicle':
            DownloadForm = DownloadSupplierVehicleForm;
            UploadForm = UploadSupplierVehicleFormFile;
            CreateList = CreateListSupplierVehicle;
            break;
        case 'employeeVehicle':
            DownloadForm = DownloadEmployeeVehicleForm;
            UploadForm = UploadEmployeeVehicleFormFile;
            CreateList = CreateListEmployeeVehicle;
            break;
    }

    const handleUploadFile = (e) => {
        if (fileData) {
            let formData = new FormData();
            formData.append('file', fileData);
            dispatch(UploadForm(formData)).then(
                ({
                    payload: {
                        data: { data, success },
                    },
                }) => {
                    if (success === true) {
                        const errorsArray = data?.filter((item) => item.errors !== null);

                        if (errorsArray.length > 0) {
                            switch (props.model) {
                                case 'contractor':
                                    navigate('/dashboard/employee/contractors/errors');
                                    break;
                                case 'supplier':
                                    navigate('/dashboard/employee/suppliers/errors');

                                    break;
                                case 'contractorEmployee':
                                    navigate('/dashboard/contractor/errors-employee');

                                    break;
                                case 'contractorVehicle':
                                    navigate('/dashboard/contractor/errors-vehicle');

                                    break;
                                case 'supplierEmployee':
                                    navigate('/dashboard/supplier/errors-employee');

                                    break;
                                case 'supplierVehicle':
                                    navigate('/dashboard/supplier/errors-vehicle');

                                    break;
                                case 'employeeVehicle':
                                    navigate('/dashboard/employee/vehicle-fleet/errors');

                                    break;
                                case 'employee':
                                    navigate('/dashboard/employee/all-employees/errors');

                                    break;
                            }
                        } else {
                            dispatch(CreateList(data)).then(
                                ({
                                    payload: {
                                        data: { data, success },
                                    },
                                }) => {
                                    if (success === true) {
                                        props.onCreated();
                                        toast.success(t('has_been_created_successfully').toUpperCase());
                                    } else {
                                        toast.error(t('error_creating').toUpperCase());
                                    }
                                },
                            );
                        }
                    } else {
                        toast.error(t('fail_to_upload_file').toUpperCase());
                    }
                },
            );

            props.onHide();
        } else {
            toast?.warn(t('there_is_not_file_to_upload').toUpperCase());
        }
    };

    const handleImageUpload = (event) => {
        if (event.type !== 'change') {
            event.map((file) => {
                const checkFile = file?.name?.split('.')?.pop();
                const fileImage = validFileTypes.includes(checkFile);
                const date = new Date(file?.lastModified); // current date and time
                const utcString = date.toUTCString();
                setFileDate(utcString);
                if (fileImage) {
                    if (file?.size <= fileSize) {
                        setFileData(file);
                        dispatch(setExcelFile(file));
                        setFileType(file?.name?.split('.')?.pop());
                    } else {
                        setFileData('');
                        toast.error(t('error_file_size').toUpperCase());
                    }
                } else {
                    setFileData('');
                    toast.error(t('error_file_format').toUpperCase());
                }
            });
        } else {
            const files = event.target.files;
            // setUploadFile(e.target.files[0])
            Array.from(files).forEach((file) => {
                const checkFile = file?.name?.split('.')?.pop();
                const fileImage = validFileTypes.includes(checkFile);
                const date = new Date(file?.lastModified); // current date and time
                const utcString = date.toUTCString();
                setFileDate(utcString);
                if (fileImage) {
                    if (file?.size <= fileSize) {
                        setFileData(file);
                        setFileType(file?.name?.split('.')?.pop());
                    } else {
                        setFileData('');
                        toast.error(t('error_file_size').toUpperCase());
                    }
                } else {
                    setFileData('');
                    toast.error(t('error_file_format').toUpperCase());
                }
            });
        }
    };

    useEffect(() => {
        setFileData('');
        setFileDate('');
        setFileType('');
    }, [props.show]);

    return (
        <Modal
            className='import-file-modal px-5'
            {...props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t(props.title_modal)}</Modal.Title>
                <img
                    onClick={() => {
                        props.onHide();
                        props?.isReset([]);
                        props?.isAllReset(false);
                    }}
                    className='modalClose'
                    src={cancel}
                    alt=''
                />
            </Modal.Header>
            <Modal.Body className='department_modal_body mx-4'>
                <p className='paragraph_deps mt-3' style={{ textAlign: 'center' }}>
                    {t('to_upload_multiple')} <span> {t(props.element_modal)}</span>{' '}
                    {t('you_need_to_follow_the_next_steps')}
                </p>

                <div className='mt-4 d-flex'>
                    <GoPrimitiveDot className='dot-modal mr-2' />
                    <p className='paragraph_deps'>{t('download_the_form_in_the_next_link')}</p>
                </div>

                <p
                    className='download_buttom mt-2'
                    onClick={() => {
                        dispatch(DownloadForm());
                    }}
                >
                    {t('click_to_download_file')}
                    <img src={downloadIcon} alt='download' className='d_icon' />
                </p>

                <div className='mt-2 d-flex'>
                    <GoPrimitiveDot className='dot-modal mr-2' />
                    <p className='paragraph_deps'>
                        {t('fill_on_it_the_data_and_make_sure_you_are_following_the_correct_way_to_fill_it')}
                    </p>
                </div>
                <div className='mt-2 d-flex'>
                    <GoPrimitiveDot className='dot-modal mr-2' />
                    <p className='paragraph_deps'>
                        {t('drag_&_drop_the_file_or_click_on_the_shape_to_search_on_the_explorer')}
                    </p>
                </div>
                <div className='mt-2 d-flex'>
                    <GoPrimitiveDot className='dot-modal mr-2' />
                    <p className='paragraph_deps'>{t('click_on_the_create_button_to_confirm_the_action')}</p>
                </div>
                <div className='mt-2 d-flex'>
                    <GoPrimitiveDot className='dot-modal mr-2' />
                    <p className='paragraph_deps'>
                        {t(
                            'in_case_the_server_finds_some_errors_they_will_appear_to_be_corrected_then_follow_the_steps_again',
                        )}
                    </p>
                </div>
                <div className='d-flex justify-content-center'>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            handleImageUpload(acceptedFiles);
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className='drop_zone_area mt-4'>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} onChange={(e) => handleImageUpload(e)} />
                                    <div className='drop_zone_item'>
                                        <img src={cloudsvg} alt='' />
                                        <p>
                                            {t('drag_your_file_or')}
                                            <br />
                                            {t('click_to_upload')}
                                            <br /> <span>{`${t('max_size')} ${fileSize / (1024 * 1024)}MB`}</span>
                                        </p>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                {fileData && (
                    <div className='preview_file '>
                        <div className='preview_file_item'>
                            <img src={fileType === 'xlsx' && exelIcon} alt='pdf' />
                            <div>
                                <p className='file_type'>
                                    {fileData?.name?.length > 18
                                        ? fileData?.name.slice(0, 18) + '.' + fileType
                                        : fileData?.name}
                                </p>

                                <p className='time_line'>{fileDate}</p>
                            </div>
                            <i onClick={() => setFileData('')} className='fa fa-times' aria-hidden='true'></i>
                        </div>
                    </div>
                )}

                <div className='d-flex justify-content-center mt-5'>
                    <button
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '180px' }}
                        onClick={() => {
                            props.onHide();
                            props?.isReset([]);
                            props?.isAllReset(false);
                        }}
                    >
                        {t('cancel')}
                    </button>
                    <button
                        className='custom_primary_btn_dark'
                        style={{ width: '180px' }}
                        onClick={() => handleUploadFile()}
                    >
                        {t('confirm').toUpperCase()}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ImportFileModal;
