import React from 'react';
import warning from '../assets/warningImage.png';

const NotInformation = ({ text, card }) => {
    return (
        <div className={`content_warning ${card ? ' back_card' : ''}`}>
            <div className='info_waring'>
                <img src={warning} alt='' />
                <p className='text-center'> {text}</p>
            </div>
        </div>
    );
};

export default NotInformation;
