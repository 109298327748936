import React from 'react';
import { Modal } from 'react-bootstrap';
import cancel from '../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';

const GenericModal = (props) => {
    const { t } = useTranslation();
    const handleOnSave = () => {
        props.onSave();
    };

    const handleHide = () => {
        props.onSave();
    };

    return (
        <Modal
            className='department_and_user_delete-modal'
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t(props.title)}</Modal.Title>
                <img onClick={props.onHide ? props.onHide : props.onClose} className='modalClose' src={cancel} alt='' />
            </Modal.Header>
            <Modal.Body className='department_modal_body'>
                <p
                    className={`paragraph_deps ${props.descriptionBold ? 'fw-bolder' : ''}`}
                    style={{ textTransform: 'math-auto' }}
                >
                    {props?.description}
                </p>
                {props.children}
                <div className='d-flex mt-4'>
                    <button className='custom_btn_cancel_gray_hover' style={{ width: '180px' }} onClick={props.onClose}>
                        {props.cancelButtonText.toUpperCase()}
                    </button>
                    <button
                        className='custom_primary_btn_dark'
                        style={{ width: '180px' }}
                        onClick={() => {
                            handleOnSave();
                        }}
                    >
                        {props.confirmButtonText.toUpperCase()}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default GenericModal;
