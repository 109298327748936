/*
Author : Arman Ali
Module: Delete Records (resueable component)
github: https://github.com/Arman-Arzoo
website: https://www.toplinegeeks.com
*/
import React from 'react';
import { Modal } from 'react-bootstrap';
import cancel from '../../../assets/images/ic-cancel.svg';
import cancelIcon from '../../../assets/icon/cancelIcon.svg';
import checkIcon from '../../../assets/icon/checkIcon.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import DeleteModal from '../../Modals/DeleteModal';

const HolidayDetailModal = ({ onHide, titleModal, show, data }) => {
    const { t } = useTranslation();
    const [cancelRequest, setCancelRequest] = useState(false);

    return (
        <>
            <Modal
                className='holiday_detail_modal'
                show={show}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header>
                    <Modal.Title id='contained-modal-title-vcenter'>{t(titleModal)}</Modal.Title>
                    <img
                        onClick={() => {
                            onHide();
                        }}
                        className='modalClose'
                        src={cancel}
                        alt=''
                    />
                </Modal.Header>
                <Modal.Body className='modal_body'>
                    <div className='items'>
                        <h4>{t('from')}</h4>
                        <p>01/01/2023</p>
                    </div>
                    <div className='items'>
                        <h4>{t('HASTA')}</h4>
                        <p>01/01/2023</p>
                    </div>
                    <div className='items'>
                        <h4>{t('TOTALES')}</h4>
                        <p>0 DAYS</p>
                    </div>
                    <div className='items'>
                        <h4>{t('STATUS')}</h4>
                        <div className='status_container'>
                            <p className='status_text'>PENDING TO VALIDATE</p>
                            <div className='status_icon' style={{ background: 'green' }}></div>
                        </div>
                    </div>
                    <div className='items'>
                        <h4>{t('DEPARTMENT WILL APPROVE')}</h4>
                        <div className='directional_status'>
                            <p>HUMAN RESORUCES</p>
                            <img src={cancelIcon} alt='cancel' />
                        </div>
                    </div>
                    <div className='items'>
                        <h4>{t('BOSS')}</h4>
                        <div className='directional_status'>
                            <div>
                                <p>Luis Enrique Cornejo Arreola</p>
                                <p>lcornejo@ibl.mx</p>
                                <p>+524422655987</p>
                            </div>
                            <img src={checkIcon} alt='check' />
                        </div>
                    </div>
                    <div className='items'>
                        <h4>{t('REQUESTED AT')}</h4>
                        <p>01/01/2023 00:00:00</p>
                    </div>

                    <div className='footer'>
                        <p
                            onClick={() => {
                                setCancelRequest(true);
                                onHide();
                            }}
                        >
                            {t('cancel_request')}
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
            <DeleteModal
                show={cancelRequest}
                onHide={() => setCancelRequest(false)}
                // data={selectedDeviceForDelete}
                // onClickFn={() => deleteDevice(selectedDeviceForDelete)}
                title_modal={t('cancel_request')}
                // element_modal={t("device")}
                description='Do you want to cancel the holiday? To apply the action, please confirm it.'
                // isReset={setSelectDeviceForDelete}
                // isAllReset={setIsAllCheckedDevice}
            />
        </>
    );
};

export default HolidayDetailModal;
