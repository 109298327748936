import React, { useEffect, useState } from 'react';
import AddWorkShift from './Modals/AddWorkShift';
import RemoveUser from './Modals/RemoveUser';
import WorkShiftPanelCard from './WorkShiftPanelCard';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { permissionObj } from '../../../../Helpers/permission';
import { AllWorkShiftTime } from '../../../../reduxToolkit/CompanyWorkShift/CompanyWorkShiftSlice';
import DeleteModal from '../../../Modals/DeleteModal';
import { DeleteItemsApi } from '../../../../reduxToolkit/Commons/CommonsApi';
import { toast } from 'react-toastify';

import { GetAllWorkShifts } from '../../../../reduxToolkit/CompanyWorkShift/CompanyWorkShiftApi';
import { Checkbox, FormControlLabel, TextField, Tooltip, Typography } from '@mui/material';
import NotInformation from '../../../../components/NotInformation';
import SearchIcon from '@mui/icons-material/Search';
import { SimpleSearch } from '../../../../reduxToolkit/Search/SearchApi';
import { TABLES } from '../../../../Apis/Tables';
import SearchSimple from '../../../../components/SearchSimples/SearchSimple';
import ExportDataBtn from '../../../../components/buttons/ExportDataBtn';

const WorkShiftPanel = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const title_modal = `workshift_panel`;
    const element_modal = `workshift`;

    // useState
    const [workShiftModalShow, setWorkShiftModalShow] = useState(false);
    const [removeUserModal, setRemoveUserModal] = useState();
    const [selectWorkshiftForDelete, setSelectWorkshiftForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [updateData, setUpdateData] = useState();
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState();
    // use selector
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const fetchAllWorkTime = useSelector(AllWorkShiftTime);
    const option = TABLES.WORKSHIFT;

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            // const selectAllIds = fetchAllWorkTime?.content?.map((item) => {
            const selectAllIds = resultsToShow?.content?.map((item) => {
                return item?.id;
            });
            setSelectWorkshiftForDelete(selectAllIds);
        } else {
            setSelectWorkshiftForDelete([]);
        }
    };
    // this function handle only specific id base on selection
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectWorkshiftForDelete([...selectWorkshiftForDelete, e.target.id]);
        } else {
            setSelectWorkshiftForDelete(selectWorkshiftForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    useEffect(() => {
        const pagination = {
            order: true,
            page: 0,
            size: 8,
            sortBy: 'id',
        };
        //get all work shifts
        dispatch(GetAllWorkShifts(pagination));
    }, []);
    // delete workshif
    const deleteWorkShift = (deleteItem) => {
        const tableName = 'work_shift';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body }));
    };

    let resultsToShow;
    if (
        !searchResults ||
        searchResults?.content?.length === 0 ||
        searchResults === null ||
        searchResults === undefined
    ) {
        // Reconfirma que no haya nada en SearchResults para mostrar resultados
        // si en definitiva no hay nada en SearchResults, entonces muestra la vista de que no hay información
        if (!searchResults) {
            resultsToShow = fetchAllWorkTime;
        } else {
            resultsToShow = null;
        }
    } else if (searchResults.content?.length > 0) {
        resultsToShow = searchResults;
    }

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <h2>{t('work_shift_panel')}</h2>
                </div>

                <div className='container-top-right-btns'>
                    <>
                        {searchResults && bodyForExport && <ExportDataBtn body={bodyForExport} option={false} />}
                        {permission?.includes(permissionObj?.WEB_WORK_SHIFT_CREATE) && (
                            <>
                                <button
                                    className='add-btn-1'
                                    disabled={selectWorkshiftForDelete?.length > 0}
                                    onClick={() => {
                                        setWorkShiftModalShow(true);
                                        setIsUpdate(false);
                                    }}
                                >
                                    <i className='fa fa-plus' aria-hidden='true'></i>
                                    {t('add')}
                                </button>
                            </>
                        )}
                        {permission?.includes(permissionObj?.WEB_WORK_SHIFT_DELETE) && (
                            <button
                                className='delete-btn-1'
                                disabled={selectWorkshiftForDelete?.length === 0}
                                onClick={() => {
                                    setDeleteShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </>
                </div>
            </div>
            <SearchSimple
                setBodyForExport={setBodyForExport}
                setSearchResults={setSearchResults}
                searchElement={searchElement}
                setSearchElement={setSearchElement}
                optionTable={option}
                page={0}
                rowsPerPage={8}
            />
            <div className='panelTables animated-div px-1'>
                {resultsToShow?.content?.length > 0 && (
                    <table className='w-100'>
                        {' '}
                        <thead className='no-border-thead'>
                            <section className='table-header'>
                                <section className='checkbox-and-name--WorkShift'>
                                    <th className='first_head'>
                                        <Tooltip
                                            title={t('de_/_select_all').toUpperCase()}
                                            placement='top'
                                            disableInteractive
                                        >
                                            <Checkbox
                                                label='Label'
                                                checked={selectWorkshiftForDelete?.length > 0}
                                                indeterminate={
                                                    selectWorkshiftForDelete?.length > 0 &&
                                                    fetchAllWorkTime?.content?.length !==
                                                        selectWorkshiftForDelete?.length
                                                }
                                                onChange={handelDeleteAll}
                                                size='small'
                                            />
                                        </Tooltip>
                                    </th>
                                    <th>{t('name')}</th>
                                </section>
                                <section>
                                    <th>{t('update')}</th>
                                    <th className='last'>{t('see_more')}</th>
                                </section>
                            </section>
                        </thead>
                    </table>
                )}
            </div>
            {resultsToShow?.content?.length > 0 ? (
                <WorkShiftPanelCard
                    setRemoveUserModal={setRemoveUserModal}
                    selectForDelete={selectWorkshiftForDelete}
                    handleCheckboxChange={handleCheckboxChange}
                    setUpdateModal={setWorkShiftModalShow}
                    setIsUpdate={setIsUpdate}
                    setUpdateData={setUpdateData}
                    searchResults={resultsToShow}
                />
            ) : (
                <>
                    <NotInformation text={t('no_information')} card={true} />
                </>
            )}
            <AddWorkShift
                title={''}
                check='false'
                isUpdate={isUpdate}
                data={updateData}
                show={workShiftModalShow}
                onHide={() => setWorkShiftModalShow(false)}
            />
            <RemoveUser title={t('remove_user')} show={removeUserModal} onHide={() => setRemoveUserModal(false)} />

            <DeleteModal
                show={deleteShow}
                onHide={() => setDeleteShow(false)}
                data={selectWorkshiftForDelete}
                onClickFn={() => deleteWorkShift(selectWorkshiftForDelete)}
                title_modal={title_modal}
                element_modal={element_modal}
                isReset={setSelectWorkshiftForDelete}
                isAllReset={setIsAllChecked}
            />
        </>
    );
};

export default WorkShiftPanel;
