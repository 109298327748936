import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import indentityT from '../../assets/icon/identity theft.svg';
import { ResetUnlockedAccount } from '../../reduxToolkit/CompanyEmployees/CompanyEmployeesApi';

const BtnResetByImpersonation = ({ id }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const sendResetByImpersonation = () => {
        dispatch(ResetUnlockedAccount(id));
    };

    return (
        <div>
            <div>
                <button className='send-qr-btn-1' onClick={() => sendResetByImpersonation()}>
                    <img
                        src={indentityT}
                        alt='qrIcon'
                        style={{ width: '18px', height: '18px', marginBottom: '2px', marginLeft: '0px' }}
                    />
                    {t('reset_by_impersonation')}
                </button>
            </div>
        </div>
    );
};

export default BtnResetByImpersonation;
