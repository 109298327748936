import { endpoints } from './Constants';
import { apiInstance } from '../Apis/Axios';
const {
    GET_DEVICE_CONFIGURATION,
    DOWNLOAD_DEVICE_CONFIGURATION_IDLE,
    DOWNLOAD_DEVICE_CONFIGURATION_MANAGE_CLOCK,
    GET_SELFIE_BY_USER_ID,
} = endpoints;

export const getDeviceConfiguration = () => apiInstance.get(GET_DEVICE_CONFIGURATION);
export const getDownloadImageDeviceIdle = () =>
    apiInstance.get(DOWNLOAD_DEVICE_CONFIGURATION_IDLE, { responseType: 'blob' });
export const getDownloadImageDeviceManageClock = () =>
    apiInstance.get(DOWNLOAD_DEVICE_CONFIGURATION_MANAGE_CLOCK, { responseType: 'blob' });

//User background - selfie
export const getUserSelfie = (userId) => apiInstance.get(GET_SELFIE_BY_USER_ID + `${userId}`, { responseType: 'blob' });
