import React, { useState } from 'react';
import { Modal, Box, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import cancel from '../../../../src/assets/images/ic-cancel.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { DeleteItemChart } from '../../../reduxToolkit/Graphs/GraphsApi';

const DeleteDashboardModal = ({ show, onHide, setChanged, changed }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [dashboardId, setDashboardId] = useState('');
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const graphData = useSelector((state) => state.GraphsSlice.getGraphByUserId);

    const handleDelete = () => {
        const table = 'dashboard';
        const body = [dashboardId];
        dispatch(DeleteItemChart({ table, body })).then(
            ({
                payload: {
                    data: { success, data },
                },
            }) => {
                if (success === true) {
                    setChanged(true);
                    onHide();
                }
            },
        );
    };

    return (
        <Modal
            open={show}
            onClose={onHide}
            className='modal-dialog modal-lg modal-dialog-centered modal-content'
            style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
        >
            <Box
                sx={{
                    width: '500px',
                    bgcolor: 'background.paper',
                    p: 3,
                    borderRadius: '8px',
                    position: 'relative',
                }}
            >
                <img
                    className='close_icon'
                    src={cancel}
                    alt='close'
                    style={{ cursor: 'pointer', position: 'absolute', top: '16px', right: '16px' }}
                    onClick={onHide}
                />
                <Box>
                    <h4 className='header-graph'>{t('remove_dashboard')}</h4>
                </Box>
                <Box mt={2}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id='dashboard-select-label'>{t('select_dashboard')}</InputLabel>
                        <Select
                            size='small'
                            labelId='dashboard-select-label'
                            value={dashboardId}
                            onChange={(e) => setDashboardId(e.target.value)}
                            label={t('select_dashboard')}
                            helperText={t('requiredField')}
                            id='slt_ddm_dashboard'
                        >
                            {graphData.map((dashboard) => (
                                <MenuItem key={dashboard.id} value={dashboard.id}>
                                    <span>{dashboard.name.toUpperCase()}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <h5 variant='body1' className='label-graph-top'>
                        {t('are_you_sure_you_want_to_delete')}?
                    </h5>
                </Box>

                <div className='d-flex mt-3' style={{ marginLeft: '35px' }}>
                    <button
                        style={{ width: '180px', height: '30px' }}
                        onClick={onHide}
                        className='custom_btn_cancel_gray_hover'
                        id='btn_ddm_cancel'
                    >
                        {t('cancel')?.toUpperCase()}
                    </button>
                    <button
                        style={{ width: '180px', height: '30px' }}
                        className='custom_primary_btn_dark'
                        onClick={handleDelete}
                        disabled={!dashboardId}
                        id='btn_ddm_confirm'
                    >
                        {t('confirm')?.toUpperCase()}
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default DeleteDashboardModal;
