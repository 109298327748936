import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import personPng from '../../assets/defaultImages/userDef.svg';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DocumentStatusThree from '../../components/DocumentStatusThree';
import {
    CreateSupplierDocValue,
    DownloadProviderImage,
    GetProviderEmployeeDetail,
    GetSingleProvider,
    SetSupplierDocValue,
} from '../../reduxToolkit/Providers/providersApi';
import UnlinkDeviceChangeModal from './Modal/UnlinkDeviceChangeModal';
import DeleteModal from '../Modals/DeleteModal';
import genderId from '../../hooks/genderId';
import ReturnArrow from '../../components/buttons/ReturnArrow';
import { DeleteItemsApi } from '../../reduxToolkit/Commons/CommonsApi';
import ic_edit from '../../../src/assets/icon/ic-edit-pencil.svg';
import { statusColor } from '../../enums/statusColorEnum';
import { status } from '../../enums/statusEnum';
import { GoPrimitiveDot } from 'react-icons/go';

const ProviderOrderDetail = ({ approveDocument }) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    // use State
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectDocumentsForDelete, setSelectDocumentsForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteDocShow, setDeleteDocShow] = useState(false);
    const [unlinkShow, setUnlinkShow] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(0);
    const [deleteEmployeeShow, setDeleteEmployeeShow] = useState(false);
    const [selectEmployeeForDelete, setSelectEmployeeForDelete] = useState();
    const { user } = useSelector((state) => state.authenticatioauthennSlice);
    const userType = user?.data?.userType?.name;
    // use Selector
    const { getProviderEmployeeDetail } = useSelector((state) => state?.providersSlice);
    const { setSupplierDocValue, createSupplierDocValue } = useSelector((state) => state?.providersSlice);
    const { uploadImage, changeCount } = useSelector((state) => state.sharedSlice);

    const { saveProviderImage, imageChangeCheck } = useSelector((state) => state.providersSlice);

    useEffect(() => {
        dispatch(GetProviderEmployeeDetail(sessionStorage.getItem('provideridfordetail')));
        setSelectEmployeeForDelete([sessionStorage.getItem('elementForDelete')]);
    }, [
        setSupplierDocValue,
        createSupplierDocValue,
        saveProviderImage,
        imageChangeCheck,
        deletedFlag,
        uploadImage,
        changeCount,
    ]);

    const deleteSelectedDocuments = (deleteItem) => {
        const tableName = 'supplier_document';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setDeletedFlag(deletedFlag + 1);
                        setSelectDocumentsForDelete([]);
                        setIsAllChecked(false);
                    }
                },
            );
        }
    };

    const deleteSelectedElement = (deleteItem) => {
        const tableName = 'supplier_employee';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        navigate('/dashboard/supplier/employees');
                    }
                },
            );
        }
    };

    return (
        <>
            {/* head with back link */}
            <div className='head'>
                <div className='headLeft'>
                    {userType === 'EMPLOYEE' && <ReturnArrow link={'/dashboard/employee/suppliers/order-details'} />}
                    {userType === 'SUPPLIER_IN_CHARGE' && <ReturnArrow link={'/dashboard/supplier/employees'} />}
                    <h2>{t('complete_documents')}</h2>
                </div>
                {userType === 'SUPPLIER_IN_CHARGE' && (
                    <div className='container-top-right-btns'>
                        {!approveDocument && (
                            <button className='unlink-btn-1' onClick={() => setUnlinkShow(true)}>
                                <i class='fa fa-mobile' aria-hidden='true'></i>
                                {t('unlink_device')}
                            </button>
                        )}

                        <Link to={`/dashboard/supplier/update-employee/${getProviderEmployeeDetail?.user?.id}`}>
                            <button
                                className='add-btn-1'
                                onClick={() => {
                                    dispatch(GetSingleProvider(getProviderEmployeeDetail?.user?.id));
                                }}
                            >
                                <img src={ic_edit} alt='Edit' style={{ width: '20px', height: '20px' }} />
                                {t('update')}
                            </button>
                        </Link>
                        {approveDocument && (
                            <button
                                className='delete-btn-1'
                                onClick={() => {
                                    setDeleteEmployeeShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div className='row employee_provider_detail_flow' style={{ gap: lCode === 'ar' ? '1rem' : '' }}>
                <div className='col-md-3 __userData '>
                    <img
                        src={
                            getProviderEmployeeDetail?.user?.selfie
                                ? `data:image/png;base64,${getProviderEmployeeDetail?.user?.selfie}`
                                : personPng
                        }
                        className='__userImage'
                        style={{ objectFit: 'cover' }}
                    />
                    <div className='__body'>
                        <div
                            className={'status, status-vehicle'}
                            style={{ color: statusColor[getProviderEmployeeDetail?.user?.statusId] }}
                        >
                            <p style={{ color: statusColor[getProviderEmployeeDetail?.user?.statusId] }}>
                                {t(status[getProviderEmployeeDetail?.user?.statusId]).toUpperCase()}
                            </p>
                            <GoPrimitiveDot className='ml-1' />
                        </div>
                        <p>{t('name')}</p>
                        <span>
                            {getProviderEmployeeDetail?.user?.name
                                ? getProviderEmployeeDetail?.user?.name +
                                  ' ' +
                                  (getProviderEmployeeDetail?.user?.secondLastName != null
                                      ? getProviderEmployeeDetail?.user?.secondLastName
                                      : ' ') +
                                  ' ' +
                                  getProviderEmployeeDetail?.user.lastName
                                : '-'}
                        </span>
                        <p className='ishead'>{t('email')}</p>
                        <span>{getProviderEmployeeDetail?.user?.email}</span>
                        <p className='ishead'>{t('phone_number')}</p>
                        <span>{getProviderEmployeeDetail?.user?.phoneNumber}</span>

                        <p className='ishead'>{t('gender')}</p>
                        <span>
                            {getProviderEmployeeDetail?.user?.genderId
                                ? genderId(getProviderEmployeeDetail?.user?.genderId)
                                : '-----'}
                        </span>
                    </div>
                </div>
                <div className='col-md-8  documents_status_item'>
                    <div className='document_header'>
                        <p className='document_title'>{t('documents')}</p>
                        {approveDocument && (
                            <button
                                className='delete-btn-1'
                                disabled={selectDocumentsForDelete?.length === 0}
                                onClick={() => {
                                    setDeleteDocShow(true);
                                }}
                            >
                                <i class='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                    </div>
                    <DocumentStatusThree
                        dataTable={getProviderEmployeeDetail?.documents}
                        approve={approveDocument}
                        userType={'supplier'}
                        setDocValue={SetSupplierDocValue}
                        createDocValue={CreateSupplierDocValue}
                        downloadImg={DownloadProviderImage}
                        optionDownload={'supplier_document'}
                        isAllChecked={isAllChecked}
                        setIsAllChecked={setIsAllChecked}
                        selectDocumentsForDelete={selectDocumentsForDelete}
                        setSelectDocumentsForDelete={setSelectDocumentsForDelete}
                    />
                </div>
            </div>

            <DeleteModal
                title_modal={t('delete_documents')}
                data={selectDocumentsForDelete}
                show={deleteDocShow}
                onHide={() => setDeleteDocShow(false)}
                element_modal={t('documents')}
                isReset={setSelectDocumentsForDelete}
                isAllReset={setIsAllChecked}
                onClickFn={() => deleteSelectedDocuments(selectDocumentsForDelete)}
            />
            <DeleteModal
                title_modal={t('delete_employee')}
                show={deleteEmployeeShow}
                onHide={() => setDeleteEmployeeShow(false)}
                onClickFn={() => deleteSelectedElement(selectEmployeeForDelete)}
            />

            <UnlinkDeviceChangeModal show={unlinkShow} onHide={() => setUnlinkShow(false)} />
        </>
    );
};

export default ProviderOrderDetail;
