import React, { useEffect, useState, useRef } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterPopus from './FilterPopus';
import { Link, useNavigate } from 'react-router-dom';
import AllVehiclesCards from './subComponents/AllVehiclesCards';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { permissionObj } from '../../../../Helpers/permission';
import ic_left_icon from '../../../../assets/ic-left-arrow.svg';
import listIcon from '../../../../assets/ic-list-detail.svg';
import GridIcon from '../../../../assets/grip-vertical-solid.svg';
import eyeIcon from '../../../../assets/eye-solid.svg';

import TablePagination from '@mui/material/TablePagination';
import DeleteVehicleModal from './modal/DeleteVehicleModal';
import SearchIcon from '@mui/icons-material/Search';
import DisplayView from '../../../../components/DisplayView';
import SearchFor from '../../../Modals/SearchFor';
import { MODELS } from '../../../../Apis/Models';
import { TABLES } from '../../../../Apis/Tables';
import { SearchByFilters, SearchByFiltersCustom } from '../../../../reduxToolkit/Search/SearchApi';
import { GetProviderVehicleDetail } from '../../../../reduxToolkit/Providers/providersApi';
import { status } from '../../../../enums/statusEnum';
import { toast } from 'react-toastify';
import { Checkbox, TextField, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import checkedIcon from '../../../../assets/icon/ic-check-white.svg';
import SearchFilterBtn from '../../../../components/buttons/SearchFilterBtn';
import { clearSearchFilters } from '../../../../reduxToolkit/Search/SearchSlice';
import { statusColor } from '../../../../enums/statusColorEnum';
import DeleteModal from '../../../Modals/DeleteModal';
import { DeleteItemsApi } from '../../../../reduxToolkit/Commons/CommonsApi';
import ExportDataBtn from '../../../../components/buttons/ExportDataBtn';
import ImportDataBtn from '../../../../components/buttons/ImportDataBtn';
import ImportFileModal from '../../../Modals/ImportFileModal';
import CreationErrorsModal from '../../../Modals/CreationErrorsModal';
import NotInformation from '../../../../components/NotInformation';
import { SimpleSearch } from '../../../../reduxToolkit/Search/SearchApi';
import SearchSimple from '../../../../components/SearchSimples/SearchSimple';
import SearchSimpleWithId from '../../../../components/SearchSimples/SearchSimpleWithId';
import CreationErrorsVehicle from '../../../Modals/CreationErrorsVehicle';

const AllVehicles = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const lCode = Cookies.get('i18next') || 'en';

    // State variables
    const [modalShow, setModalShow] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [searchVehicle, setSearchVehicle] = useState('');
    const [view, setView] = useState('list');
    const [selectVehicleForDelete, setSlectVehicleForDelete] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteVehicleShow, setDeleteVehicleShow] = useState(false);
    const [deletedFlag, setDeletedFlag] = useState(false);
    const [filterDialogShow, setFilterDialogShow] = useState(false);
    const [finalArray, setFinalArray] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [supplierId, setSupplierId] = useState(sessionStorage.getItem('providerId'));
    const [importFileShow, setImportFileShow] = useState(false);
    const [creationErrorsShow, setCreationErrorsShow] = useState(false);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const { uploadImage } = useSelector((state) => state.sharedSlice);
    const { searchByFilters, searchByFiltersCustom } = useSelector((state) => state.SearchSlice);
    const [searchElement, setSearchElement] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [bodyForExport, setBodyForExport] = useState('');
    const [spreadsheetData, setSpreadsheetData] = useState(null);
    const [selectedDataDependingOfSearch, setSelectedDataDependingOfSearch] = useState(null);

    // Effect to handle pagination and data fetching
    useEffect(() => {
        let selectedData;

        if (contractorId === null && supplierId === null) {
            selectedData = searchByFilters;
        } else {
            selectedData = searchByFiltersCustom;
        }

        if (contractorId === null && supplierId === null) {
            if (!searchResults || searchResults == null || searchResults === '' || searchResults === undefined) {
                setSelectedDataDependingOfSearch(selectedData);
            } else {
                setSelectedDataDependingOfSearch(searchResults);
            }
        } else {
            if (!searchResults || searchResults === null || searchResults === '' || searchResults === undefined) {
                setSelectedDataDependingOfSearch(selectedData);
            } else {
                setSelectedDataDependingOfSearch(searchResults);
            }
        }
    }, [searchByFilters, searchByFiltersCustom, contractorId, supplierId, searchResults]);

    let moduleId;
    let option;
    const [criteria, setCriteria] = useState();

    if (contractorId !== null) {
        moduleId = `${MODELS.ContractorVehicle}`;
        option = `${TABLES.CONTRACTOR_VEHICLE}`;
    } else if (supplierId !== null) {
        moduleId = `${MODELS.SupplierVehicle}`;
        option = `${TABLES.SUPPLIER_VEHICLE}`;
    } else {
        // Props to the filter window
        moduleId = `${MODELS.vehicle}`;
        option = `${TABLES.VEHICLES}`;
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Function to handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // this function control select all id or unSelect all
    const handelDeleteAll = (e) => {
        setIsAllChecked(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = selectedDataDependingOfSearch?.content?.map((item) => {
                return item?.id;
            });
            setSlectVehicleForDelete(selectAllIds);
        } else {
            setSlectVehicleForDelete([]);
        }
    };

    // Function to handle checkbox change
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSlectVehicleForDelete([...selectVehicleForDelete, e.target.id]);
        } else {
            setSlectVehicleForDelete(selectVehicleForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    const elementRef = useRef(null);

    // Effect to handle component initialization and view updates
    useEffect(() => {
        dispatch(clearSearchFilters());
        resetAllCheckboxes();
        setSlectVehicleForDelete([]);
        setIsAllChecked(false);
        setPage(0);
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const distanceTop = rect.top + 0;
            elementRef.current.style.setProperty('--top-value', `${distanceTop}px`);
        }
        if (view === 'grid') {
            setRowsPerPage(12);
        } else {
            setRowsPerPage(20);
        }
    }, [view]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
    }, [view, page, rowsPerPage, finalArray, deletedFlag]);

    // useEffect to avoid first load
    useEffect(() => {
        setLoaded(true);
        sessionStorage.removeItem('contractId');
        sessionStorage.removeItem('supplier_order_id');
        return () => {
            dispatch(clearSearchFilters());
        };
    }, []);

    // useEffect to check automatically all the items when page, rowsPerPage, or search change
    useEffect(() => {
        if (isAllChecked) {
            const selectAllIds = selectedDataDependingOfSearch?.content?.map((item) => item?.id);
            setSlectVehicleForDelete((prevState) => {
                const uniqueIds = selectAllIds.filter((id) => !prevState.includes(id));
                return [...prevState, ...uniqueIds];
            });
        }
    }, [searchByFilters, searchByFiltersCustom]);

    //This fragment makes uncheked all the checkboxes when toggleState change
    const resetAllCheckboxes = () => {
        const checkboxes = document.querySelectorAll('.checkbox');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    };

    //Create body to get the respectively search
    useEffect(() => {
        if (loaded) {
            const criteriaList = finalArray.map((item) => ({
                dataOption: item.dataOption,
                fieldType: item.fieldType,
                filterKey: item.filterKey,
                operation: item.operation,
                sort: item.sort,
                table: item.table,
                values: item.values,
                from: item.from,
                to: item.to,
            }));

            // Constructing body based on contractor or supplier
            if (contractorId === null && supplierId === null) {
                const body = {
                    pagination: {
                        page: page,
                        size: rowsPerPage,
                    },
                    searchCriteriaList: criteriaList,
                };
                dispatch(SearchByFilters({ option, body })).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success) {
                            setSelectedDataDependingOfSearch(data);
                        }
                    },
                );
                setCriteria({ searchCriteriaList: criteriaList });
            } else {
                const body = {
                    userToFindId: contractorId !== null ? contractorId : supplierId,
                    pagination: {
                        page: page,
                        size: rowsPerPage,
                    },
                    searchCriteriaList: criteriaList,
                };
                dispatch(SearchByFiltersCustom({ option, body })).then(
                    ({
                        payload: {
                            data: { data, success },
                        },
                    }) => {
                        if (success) {
                            // Manejo de datos
                            setSelectedDataDependingOfSearch(data);
                        }
                    },
                );
                setCriteria({ searchCriteriaList: criteriaList });
            }
            setLoaded(false);
            setDeletedFlag(false);
        }
    }, [loaded, page, rowsPerPage, orderBy, sortBy, finalArray]);

    // Function to delete selected vehicles
    const deleteSelectedVehicles = (deleteItem) => {
        const tableName =
            contractorId !== null ? 'contractor_vehicle' : supplierId !== null ? 'supplier_vehicle' : 'vehicle_company';
        const body = deleteItem;

        if (body.length > 0) {
            dispatch(DeleteItemsApi({ tableName, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSlectVehicleForDelete([]);
                        setIsAllChecked(false);
                    }
                },
            );
        }
    };

    return (
        <>
            <div>
                <div className='head'>
                    <div className='headLeft'>
                        <h2>{t('vehicle_fleet')}</h2>
                        <DisplayView view={view} setView={setView} />
                    </div>
                    <div className='container-top-right-btns'>
                        <ImportDataBtn onClickFn={() => setImportFileShow(true)} />
                        {!searchResults || !bodyForExport ? (
                            <ExportDataBtn body={criteria} option={option} />
                        ) : (
                            <ExportDataBtn searchElement={searchElement} body={bodyForExport} option={option} />
                        )}

                        {(contractorId !== null ||
                            supplierId !== null ||
                            permission?.includes(permissionObj?.WEB_VEHICLE_CREATE)) && (
                            <button
                                className='add-btn-1'
                                onClick={() =>
                                    navigate(
                                        contractorId !== null
                                            ? '/dashboard/contractor/addvehical'
                                            : supplierId !== null
                                              ? '/dashboard/supplier/add-vehicles'
                                              : '/dashboard/employee/vehicle-fleet/create-vehicle',
                                    )
                                }
                            >
                                <i className='fa fa-plus' aria-hidden='true'></i>
                                {t('create')}
                            </button>
                        )}
                        {(contractorId !== null ||
                            supplierId !== null ||
                            permission?.includes(permissionObj?.WEB_VEHICLE_DELETE)) && (
                            <button
                                className='delete-btn-1'
                                disabled={selectVehicleForDelete?.length === 0}
                                onClick={() => {
                                    setDeleteVehicleShow(true);
                                }}
                            >
                                <i className='fa fa-trash-o' aria-hidden='true'></i>
                                {t('delete')}
                            </button>
                        )}
                        <SearchFilterBtn fnclick={() => setFilterDialogShow(true)} />
                    </div>
                </div>
                {contractorId === null && supplierId === null && (
                    <SearchSimple
                        setSearchResults={setSearchResults}
                        optionTable={option}
                        searchElement={searchElement}
                        setSearchElement={setSearchElement}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setBodyForExport={setBodyForExport}
                    />
                )}
                {(contractorId !== null || supplierId !== null) && (
                    <SearchSimpleWithId
                        setSearchResults={setSearchResults}
                        setBodyForExport={setBodyForExport}
                        optionTable={option}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        id={contractorId !== null ? contractorId : supplierId}
                    />
                )}
                {modalShow && <FilterPopus setModalShow={setModalShow} />}

                {view === 'grid' && (
                    <div className='d-flex mr-0 pl-0'>
                        <FormControlLabel
                            className='grid-checkall'
                            control={
                                <Checkbox
                                    label='Label'
                                    checked={isAllChecked}
                                    onChange={handelDeleteAll}
                                    size='small'
                                />
                            }
                            label={t('de_/_select_all')}
                        />
                    </div>
                )}
                {view === 'grid' && (
                    <AllVehiclesCards
                        searchVehicle={searchVehicle}
                        vehicleData={selectedDataDependingOfSearch}
                        setSlectVehicleForDelete={setSlectVehicleForDelete}
                        selectVehicleForDelete={selectVehicleForDelete}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                )}

                {view === 'list' && (
                    <div className='panelTables px-1 animated-div' ref={elementRef}>
                        {selectedDataDependingOfSearch?.content?.length > 0 ? (
                            <table className='w-100'>
                                <thead className='no-border-thead'>
                                    <th className='first_head'>
                                        <Tooltip title={t('de_/_select_all').toUpperCase()} placement='top'>
                                            <Checkbox
                                                className='grid-checkall checkbox'
                                                checked={isAllChecked}
                                                onChange={handelDeleteAll}
                                                size='small'
                                            />
                                        </Tooltip>
                                    </th>
                                    <th className='first_head'>{t('brand')}</th>
                                    <th>{t('sub_brand')}</th>
                                    <th>{t('model')}</th>
                                    <th>{t('color')}</th>
                                    <th>{t('plates')}</th>
                                    {contractorId === null && supplierId === null && <th>{t('vin')}</th>}
                                    {contractorId === null && supplierId === null && <th>{t('tag')}</th>}
                                    {(contractorId !== null || supplierId !== null) && <th>{t('S/N')}</th>}
                                    {(contractorId !== null || supplierId !== null) && <th>{t('status')}</th>}
                                    {(contractorId !== null || supplierId !== null) && <th>{t('options')}</th>}
                                    {contractorId === null && supplierId === null && <th>{t('details')}</th>}
                                </thead>

                                {selectedDataDependingOfSearch?.content
                                    ?.filter((user) => {
                                        if (searchVehicle === '') {
                                            return user;
                                        } else if (
                                            user?.vehicle?.brand?.toLowerCase().includes(searchVehicle?.toLowerCase())
                                        ) {
                                            return user;
                                        }
                                    })
                                    ?.map((item, index) => {
                                        return (
                                            <tr key={item?.id}>
                                                <td className='first align-middle'>
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={selectVehicleForDelete?.includes(item?.id)}
                                                        id={item?.id}
                                                        onChange={handleCheckboxChange}
                                                        size='small'
                                                    />
                                                </td>
                                                <td className='first align-middle'>{item?.brand || '-'}</td>
                                                <td>{item?.subBrand || '-'}</td>
                                                <td>{item?.model || '-'}</td>
                                                <td>{item?.color || '-'}</td>
                                                <td> {item?.plate || '-'} </td>
                                                {contractorId === null && supplierId === null && (
                                                    <td>{item?.vin || '-'}</td>
                                                )}
                                                {contractorId === null && supplierId === null && (
                                                    <td>{item?.tag || '-'}</td>
                                                )}
                                                {(contractorId !== null || supplierId !== null) && (
                                                    <td>{item?.serialNumber || '-'}</td>
                                                )}
                                                {(contractorId !== null || supplierId !== null) && (
                                                    <td
                                                        style={{
                                                            color: statusColor[item?.statusId],
                                                            fontWeight: 'bold',
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        {t(status[item?.statusId]).toUpperCase() || '-'}
                                                    </td>
                                                )}
                                                <td className='tableIcon'>
                                                    <Tooltip
                                                        title={
                                                            item?.statusId === 3
                                                                ? t('complete_documents').toUpperCase()
                                                                : t('vehicle_details').toUpperCase()
                                                        }
                                                        placement='top'
                                                        disableInteractive
                                                    >
                                                        <button
                                                            className='btn-option'
                                                            onClick={() => {
                                                                if (contractorId !== null) {
                                                                    item?.statusId === 3
                                                                        ? navigate(
                                                                              `/dashboard/contractor/upload-vehicle-documents/${item?.id}`,
                                                                          )
                                                                        : navigate(
                                                                              `/dashboard/contractor/vehicle-contract-detail/${item?.id}`,
                                                                          );
                                                                    dispatch(GetProviderVehicleDetail(item?.id));

                                                                    sessionStorage.setItem(
                                                                        'vehicleidfordetail',
                                                                        item?.id,
                                                                    );
                                                                } else if (supplierId !== null) {
                                                                    item?.statusId === 3
                                                                        ? navigate(
                                                                              `/dashboard/supplier/vehicle-documents`,
                                                                          )
                                                                        : navigate(
                                                                              `/dashboard/supplier/vehicles-details/${item?.id}`,
                                                                          );
                                                                    dispatch(GetProviderVehicleDetail(item?.id));

                                                                    sessionStorage.setItem(
                                                                        'vehicleidfordetail',
                                                                        item?.id,
                                                                    );
                                                                } else {
                                                                    navigate(
                                                                        `/dashboard/employee/vehicle-fleet/vehicle-detail/${item?.id}`,
                                                                    );
                                                                    sessionStorage.setItem(
                                                                        'vehicleidfordetail',
                                                                        item?.id,
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <img src={eyeIcon} alt='eye' />
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </table>
                        ) : (
                            <NotInformation text={t('no_information')} card={true} />
                        )}
                    </div>
                )}

                {/* Pagination component */}
                {selectedDataDependingOfSearch?.content?.length > 0 && (
                    <div className='d-flex justify-content-center'>
                        <TablePagination
                            component='div'
                            rowsPerPageOptions={view === 'grid' ? [12, 24, 36, 48] : [20, 40, 60]}
                            count={selectedDataDependingOfSearch?.totalElements}
                            page={page}
                            onPageChange={handleChangePage}
                            labelRowsPerPage={t('vehicle_per_page')}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                )}

                <DeleteModal
                    show={deleteVehicleShow}
                    onHide={() => setDeleteVehicleShow(false)}
                    onClickFn={() => deleteSelectedVehicles(selectVehicleForDelete)}
                    data={selectVehicleForDelete}
                    title_modal={t('delete_vehicles')}
                    description={'are_you_sure_you_want_to_delete'}
                    element_modal={t('vehicles')}
                />

                {/* dialog for advance search */}
                <SearchFor
                    open={filterDialogShow}
                    onClose={() => {
                        setFilterDialogShow(false);
                    }}
                    onFiltered={(originalArray) => {
                        setFilterDialogShow(false);
                        setFinalArray(originalArray);
                    }}
                    moduleId={moduleId}
                    option={option}
                    finalArray={finalArray}
                />

                <ImportFileModal
                    show={importFileShow}
                    onHide={() => setImportFileShow(false)}
                    onCreationErrors={() => setCreationErrorsShow(true)}
                    onCreated={() => setLoaded(true)}
                    title_modal={t('upload_form')}
                    element_modal={t('vehicles')}
                    model={
                        contractorId !== null
                            ? 'contractorVehicle'
                            : supplierId !== null
                              ? 'supplierVehicle'
                              : 'employeeVehicle'
                    }
                />
            </div>
        </>
    );
};

export default AllVehicles;
