import React, { useState } from 'react';
import {
    TextField,
    Box,
    FormControl,
    Snackbar,
    SnackbarContent,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
    Checkbox,
    ListItemIcon,
    Typography,
} from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import userregular from '../../../../../assets/images/user-regular.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ClearButton from '../../../../../components/ClearButton';
import CameraButton from '../../../../../components/CameraButton';
import ReaderFingerButton from '../../../../../components/ReaderFingerButton';
import siluetPerson from '../../../../../assets/defaultImages/silueta_persona.png';
import icCamera from '../../../../../assets/defaultImages/ic-camera.svg';
import icFingerPrint from '../../../../../assets/defaultImages/ic-fingerprint.svg';
import icFingerPrintGreen from '../../../../../assets/defaultImages/ic-fingerprint-green.svg';
import ellipsis67 from '../../../../../assets/defaultImages/Ellipse67.svg';
import Alert from '@material-ui/lab/Alert';
import { SnackbarProvider } from 'notistack';
import { useSnackbar } from 'notistack';
import SelectReader from '../Modal/SelectReader';

import { DeleteItemsApi } from '../../../../../reduxToolkit/Commons/CommonsApi';
import DeleteModal from '../../../../Modals/DeleteModal';
import { toast } from 'react-toastify';
import { permissionObj } from '../../../../../Helpers/permission';
import NotInformation from '../../../../../components/NotInformation';

const Step6CaptureFinger = ({
    userData,
    extraData,
    onChange,
    setExtraData,
    selectedReader,
    setSelectedReader,
    availableReaders,
    setAvailableReaders,
    captureFingerprint,
    getAvailableReaders,
    fingerprintSrc,
    fingerprintsList,
    handleUploadFinger,
    fingerprintName,
    setFingerprintName,
    setFingerprintSrc,
    updated,
    setUpdated,
    isSocketOpen,
    setIsSocketOpen,
    websocketRef,
    loadingProgress,
    setLoadingProgress,
    setCapturing,
    capturing,
    emptyFields,
    setEmptyFields,
}) => {
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    const dispatch = useDispatch();

    const [nameFinger, setNameFinger] = useState('');
    // const [capturing, setCapturing] = useState(false);
    const [selectReaderDialogShow, setSelectReaderDialogShow] = useState(false);
    const [selectFingerprintsForDelete, setSelectFingerprintsForDelete] = useState([]);
    const [isAllCheckedFingerprints, setIsAllCheckedFingerprints] = useState(false);
    const [deleteFingerprintsShow, setDeleteFingerprintsShow] = useState(false);
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);

    const { enqueueSnackbar } = useSnackbar();

    const handleCaptureFinger = () => {
        if (isSocketOpen && capturing) {
            getAvailableReaders();
        } else {
            getAvailableReaders();
            setSelectReaderDialogShow(true);
        }
    };

    const handleClearFingerprint = () => {
        setFingerprintName('');
        setSelectedReader('');
        setFingerprintSrc('');
        setAvailableReaders([]);
    };

    // this function handle only specific id base on selection
    const handleCheckboxChangeFingerprint = (e) => {
        if (e.target.checked) {
            setSelectFingerprintsForDelete([...selectFingerprintsForDelete, e.target.id]);
        } else {
            setSelectFingerprintsForDelete(selectFingerprintsForDelete.filter((removeid) => removeid !== e.target.id));
        }
    };

    // this function control select all id or unSelect all
    const handleRemoveAllFingerprints = (e) => {
        setIsAllCheckedFingerprints(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = fingerprintsList.map((item, index) => {
                return item?.id;
            });
            setSelectFingerprintsForDelete(selectAllIds);
        } else {
            setSelectFingerprintsForDelete([]);
        }
    };

    const deleteSelectedFingerprints = (deleteItem) => {
        const tableName = 'user_image';
        const body = deleteItem;
        dispatch(DeleteItemsApi({ tableName, body })).then(() => {
            // dispatch(GetWorkTimeAccess({ id, contractPagination }));
            setSelectFingerprintsForDelete([]);
            setIsAllCheckedFingerprints(false);
            // toast.success("Deleted Successfully")
            setUpdated(true);
        });
    };

    const smallBoxStyle = {
        width: '100%',
        maxWidth: '100%',
        fontSize: '20px',
        height: '400px',
    };

    const textField = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& 	.MuiFormLabel-filled': {
            marginTop: '-5px',
        },
    };
    const getAccessMethodName = (accessMethodId) => {
        switch (accessMethodId) {
            case 2:
                return t('FINGERPRINT');
            case 7:
                return t('FINGERPRINT_PDA');
            case 14:
                return t('FINGERPRINT_ANSI');
            case 15:
                return t('FINGERPRINT_ISO');
        }
    };

    return (
        <>
            <SnackbarProvider />
            <div className='d-flex flex-row justify-content-between'>
                <Grid container>
                    {
                        userData.id !== '' && (
                            <Grid
                                item
                                xs
                                className={
                                    'container-list-fingerprints pr-5 pl-5 ' + (userData.id !== '' ? 'width50' : '')
                                }
                            >
                                {fingerprintsList.length > 0 ? (
                                    <>
                                        {permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE) && (
                                            <>
                                                <div className='container-top-right-btns mb-1'>
                                                    <button
                                                        className='delete-btn-1 mr-0'
                                                        disabled={selectFingerprintsForDelete?.length === 0}
                                                        // onClick={removeWorkshiftsSelected}
                                                        id='btn_s6cf_delete'
                                                        onClick={() => setDeleteFingerprintsShow(true)}
                                                    >
                                                        <i className='fa fa-trash-o' aria-hidden='true'></i>
                                                        {t('delete')}
                                                    </button>
                                                </div>
                                            </>
                                        )}

                                        <table className='w-100'>
                                            <thead className='no-border-thead'>
                                                <th className='first_head'>
                                                    <Checkbox
                                                        className='grid-checkall checkbox'
                                                        checked={
                                                            selectFingerprintsForDelete.length > 0 &&
                                                            fingerprintsList.length ===
                                                                selectFingerprintsForDelete.length
                                                        }
                                                        indeterminate={
                                                            selectFingerprintsForDelete.length > 0 &&
                                                            fingerprintsList.length !==
                                                                selectFingerprintsForDelete.length
                                                        }
                                                        onChange={handleRemoveAllFingerprints}
                                                        size='small'
                                                    />
                                                </th>
                                                <th style={{ padding: '30px' }} className='name-finger first_head pl-0'>
                                                    {t('name').toLocaleUpperCase()}
                                                </th>
                                                <th className='name-finger aling-c'>{t('access_method')}</th>
                                            </thead>
                                            <tbody>
                                                {fingerprintsList.map((element, index) => (
                                                    <tr key={index} className='list-item-criteria'>
                                                        <td className='first'>
                                                            {permission?.includes(
                                                                permissionObj?.WEB_EMPLOYEE_UPDATE,
                                                            ) && (
                                                                <Checkbox
                                                                    className='grid-checkall checkbox'
                                                                    checked={selectFingerprintsForDelete?.includes(
                                                                        element?.id,
                                                                    )}
                                                                    id={element?.id}
                                                                    onChange={handleCheckboxChangeFingerprint}
                                                                    size='small'
                                                                />
                                                            )}
                                                        </td>
                                                        <td className='first pl-0 align-middle first-column-name'>
                                                            {element?.description}
                                                        </td>
                                                        <td className='aling-c'>
                                                            {getAccessMethodName(element?.accessMethodId)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </>
                                ) : (
                                    <NotInformation text={t('no_information')} card={true} />
                                )}
                            </Grid>
                        )
                        // <Divider orientation="vertical" />
                    }
                    <Grid item xs className={'marginCenter ' + (userData.id !== '' ? 'width50' : '')}>
                        {userData.id === '' ||
                        (userData.id !== '' && permission?.includes(permissionObj?.WEB_EMPLOYEE_UPDATE)) ? (
                            <>
                                <div className='step6-capture-finger mb-4'>
                                    <div className='fingers-buttons-container mb-2'>
                                        <ClearButton
                                            className='mt-0 pt-0'
                                            handleClear={handleClearFingerprint}
                                            flagTooltip={true}
                                            hidden={capturing}
                                            textTooltip={t('clean_fingerprint').toUpperCase()}
                                        />
                                        <ReaderFingerButton
                                            className='mt-0 pt-0'
                                            handleCaptureFinger={handleCaptureFinger}
                                            flagTooltip={true}
                                            disabled={capturing}
                                            textTooltip={t('start_enrollment').toUpperCase()}
                                        />
                                    </div>
                                    {selectedReader !== '' && <span className='mt-5'>{t('add_new_fingerprint')}</span>}
                                    <div
                                        className={
                                            selectedReader !== ''
                                                ? fingerprintSrc !== ''
                                                    ? 'image-box-finger-captured mt-5 mb-4'
                                                    : 'image-box-capturing-finger mt-5 mb-4'
                                                : 'image-box-not-capturing mt-4 mb-4'
                                        }
                                    >
                                        {selectedReader !== '' && (
                                            <img
                                                className='selfie-camera'
                                                src={fingerprintSrc === '' ? icFingerPrint : icFingerPrintGreen}
                                                alt='Fingerprint'
                                            />
                                        )}
                                        {selectedReader === '' && (
                                            <span className='text-indications-capture-finger'>
                                                {t('instructions_to_capture_finger')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {selectedReader !== '' && (
                                    <div className='create-enrollment-data'>
                                        <div className='m-40' style={{ margin: '30px' }}>
                                            <Box className='requiredData'>
                                                <TextField
                                                    size='medium'
                                                    fullWidth
                                                    error={emptyFields && fingerprintName === ''}
                                                    required
                                                    name='field1'
                                                    label={t('name')}
                                                    id='txt_s6cf_name'
                                                    value={fingerprintName}
                                                    onChange={(e) => setFingerprintName(e.target.value)}
                                                    inputProps={{ maxLength: 128 }}
                                                    helperText={
                                                        emptyFields && fingerprintName === '' ? t('requiredField') : ''
                                                    }
                                                    sx={{ textField, fontSize: '18px' }}
                                                />
                                            </Box>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div
                                className='mt-5'
                                style={{ position: 'relative', left: '50%', transform: 'translateX(-50%)' }}
                            >
                                <NotInformation text={t('no_information')} card={true} />
                            </div>
                        )}
                        {selectedReader !== '' && userData.id !== '' && (
                            <div className='button-selfie-tab'>
                                <button
                                    className='custom_primary_btn_dark col-12'
                                    id='btn_s6cf_addFingerprint'
                                    // disabled={activeStep === steps.length - 1}
                                    onClick={() => {
                                        if (fingerprintSrc === '') {
                                            enqueueSnackbar(t('fingerprint_not_captured').toUpperCase(), {
                                                variant: 'error',
                                            });
                                        } else if (fingerprintName !== '') {
                                            handleUploadFinger();
                                            setEmptyFields(false);
                                        } else {
                                            setEmptyFields(true);
                                        }
                                    }}
                                >
                                    {/* //     onClick={() => handleUploadFinger()}
                                // > */}
                                    {t('add_fingerprint').toUpperCase()}
                                </button>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>

            <SelectReader
                open={selectReaderDialogShow}
                onClose={() => {
                    setSelectReaderDialogShow(false);
                }}
                websocketRef={websocketRef}
                isSocketOpen={isSocketOpen}
                setIsSocketOpen={setIsSocketOpen}
                selectedReader={selectedReader}
                setSelectedReader={setSelectedReader}
                availableReaders={availableReaders}
                setAvailableReaders={setAvailableReaders}
                captureFingerprint={captureFingerprint}
                fingerprintSrc={fingerprintSrc}
                setFingerprintSrc={setFingerprintSrc}
                setLoadingProgress={setLoadingProgress}
                loadingProgress={loadingProgress}
            />
            <DeleteModal
                show={deleteFingerprintsShow}
                onHide={() => setDeleteFingerprintsShow(false)}
                onClickFn={() => deleteSelectedFingerprints(selectFingerprintsForDelete)}
                data={selectFingerprintsForDelete}
                description={'are_you_sure_you_want_to_delete'}
                title_modal={t('delete_fingerprints')}
                element_modal={t('fingerprints')}
            />
        </>
    );
};

export default Step6CaptureFinger;
