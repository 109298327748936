import React from 'react';
import { Modal } from 'react-bootstrap';
import cancel from '../../assets/images/ic-cancel.svg';
import { useTranslation } from 'react-i18next';

const SendQrModal = (props) => {
    const { t } = useTranslation();

    const handleSend = (e) => {
        props?.onClickFn(e);
        props?.onHide();
    };
    return (
        <Modal
            className='department_and_user_delete-modal'
            {...props}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t(props.title_modal)}</Modal.Title>
                <img
                    onClick={() => {
                        props.onHide();
                        props?.isReset([]);
                        props?.isAllReset(false);
                    }}
                    className='modalClose'
                    src={cancel}
                    alt=''
                />
            </Modal.Header>
            <Modal.Body className='department_modal_body'>
                <p className='paragraph_deps'>{t(`${props?.description}`)}</p>
                <div className='d-flex'>
                    <button
                        className='custom_btn_cancel_gray_hover'
                        style={{ width: '180px' }}
                        onClick={() => {
                            props.onHide();
                        }}
                    >
                        {t('cancel')}
                    </button>
                    <button className='custom_primary_btn_dark' style={{ width: '180px' }} onClick={() => handleSend()}>
                        {t('confirm').toUpperCase()}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SendQrModal;
