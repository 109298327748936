export const TABLES = {
    DEVICES: 'device',
    EMPLOYEES: 'employee',
    CONTRACTORS: 'contractor',
    CONTRACTS: 'contract',
    ORDERS: 'purchase',
    SUPPLIERS: 'supplier',
    VEHICLES: 'vehicle_company',
    ATTENDANCE: 'attendance',
    USER_ACCESS_LOG: 'user_access_log',
    VEHICLE_ACCESS_LOG: 'vehicle_access_log',
    RESOURCES: 'resource',
    CONTRACTOR_EMPLOYEE: 'contractor_employee',
    SUPPLIER_EMPLOYEE: 'supplier_employee',
    CONTRACTOR_VEHICLE: 'contractor_vehicle',
    SUPPLIER_VEHICLE: 'supplier_vehicle',
    LOG_IN_LOG: 'log_in_log',
    LOG_MODULE: 'log_module',
    EVENT: 'event',
    CARD: 'card',
    TEMPLATE: 'template',
    ZONE: 'zone',
    DEPARTMENT: 'department',
    ROLE: 'role',
    WORKSHIFT: 'work_shift',
    // Add more tables here

    // Documents
    DOCUMENTS_EMPLOYEE: 'employee_document_company',
    DOCUMENTS_SUPPLIER: 'supplier_document_company',
    DOCUMENTS_CONTRACTOR: 'contractor_document_company',

    // Documents Vehicle
    DOCUMENTS_VEHICLE_SUPPLIER: 'supplier_vehicle_document_company',
    DOCUMENTS_VEHICLE_CONTRACTORS: 'contractor_vehicle_document_company',
};
