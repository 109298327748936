import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import house_plane from '../../../assets/images/house_plane@2x.png';
import map_solid from '../../../assets/images/map-solid.svg';
import ic_info from '../../../assets/images/ic-info.svg';
import ShowDeviceModal from './Modal/ShowDeviceModal';
import ShowDeviceMapModal from './Modal/showDeviceMapModal';
import ShowDeviceListModal from './Modal/ShowDeviceListModal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    GetListZoneMap,
    GetZoneDevicesLists,
    SetZoneImageCoordinate,
    ZoneDetailFatherAndChild,
} from '../../../reduxToolkit/EmployeeZones/EmployeeZonesApi';
import { useDispatch, useSelector } from 'react-redux';
import ImageMarker, { MarkerComponentProps } from 'react-image-marker';
import RemovePlanModal from './Modal/RemovePlanModal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ic_left_arrow from '../../../assets/images/ic_left_arrow.svg';

import { useDrag, useDrop } from 'react-dnd';
import DeviceListZonePlane from './DeviceListZonePlane';
import { set } from 'lodash';
import { permissionObj } from '../../../Helpers/permission';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Box } from '@mui/material';
import noData from '../../../assets/images/warning.svg';
import LefletMap from '../../../components/LefletMap';
import ZoneMapSelect from '../../../components/ZoneMapSelect';
import { ClearGetListZoneMap } from '../../../reduxToolkit/EmployeeZones/EmployeeZoneSlice';
import ReturnArrow from '../../../components/buttons/ReturnArrow';
import ShowImg from './ShowDevicesImg';

/*
Author : Arman Ali
Module: Zone
github: https://github.com/Arman-Arzoo
*/
// show devices main function
const ShowDevices = () => {
    // translation
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';

    // use hook importer
    const dispatch = useDispatch();

    // use State hook for local state management
    const [ProfileImage, setProfileImage] = useState('');
    const [viewPlane, setViewPlane] = React.useState('');
    const [openNavigation, setOpenNavigation] = React.useState(true);
    const [selectedZone, setSelectedZone] = React.useState('');
    const [isDeviceList, setIsDeviceList] = React.useState(false);
    const [imageMarkers, setImageMarkers] = useState([]);
    const [defaultZone, setDefaultZone] = useState([]);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [showPlaneModal, setShowPlaneModal] = useState(false);
    const [deletePlaneModal, setDeletePlaneModal] = useState(false);

    //use Selector hook to get state for redux store
    const { permission } = useSelector((state) => state.authenticatioauthennSlice);
    const {
        zoneDetailFatherAndChild,
        getListZoneMap,
        createZonePlane,
        deleteImgZonePlane,

        setZoneImageCoordinate,
        getZoneDevicesLists,
        uploadImgZonePlane,
    } = useSelector((state) => state.EmployeeZonesSlice);

    const handleChange = (event) => {
        setViewPlane(event.target.value);
    };

    // get the position on x and y on drop
    const printCoordinate = (e) => {
        const { width, height } = e.target.getBoundingClientRect();
        const { offsetX, offsetY } = e.nativeEvent;

        setX(Math.round((offsetX / width) * 100));
        setY(Math.round((offsetY / height) * 100));
    };

    //drop the device to the zone plane
    const [{ isOver }, drop] = useDrop({
        accept: 'image',
        drop: (item, monitor) => {
            addDeviceToImage(item?.id);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
    // put device on image
    const addDeviceToImage = (id) => {
        const data = {
            deviceId: id,
            axisPositionX: y,
            axisPositionY: x,
            zonePlaneId: selectedZone?.id,
        };
        dispatch(SetZoneImageCoordinate({ data }));
    };
    // get list of zone map
    useEffect(() => {
        const data = {
            // zoneId: sessionStorage?.getItem('singlezoneId')
            zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
            ].id,
        };
        dispatch(GetListZoneMap(data));
    }, [createZonePlane, deleteImgZonePlane, uploadImgZonePlane, viewPlane]);

    // get the selected zone map
    useEffect(() => {
        const checked = getListZoneMap?.filter((item) => item.id === viewPlane);
        setSelectedZone(checked[0]);
    }, [selectedZone, viewPlane, getListZoneMap, deleteImgZonePlane]);

    useEffect(() => {
        const filtered = getZoneDevicesLists?.filter((item) => item.axisPositionX || item?.axisPositionY > 0);
        const checkDeviceImg = filtered?.map((item) => ({
            top: item.axisPositionX,
            left: item.axisPositionY,
            ...item,
        }));
        setImageMarkers(checkDeviceImg);
    }, [selectedZone, getZoneDevicesLists, viewPlane, deleteImgZonePlane]);

    //get all device zone
    useEffect(() => {
        const data = {
            zonePlaneId: selectedZone?.id,
        };
        dispatch(GetZoneDevicesLists(data));
    }, [selectedZone?.id, setZoneImageCoordinate]);
    // get detail zone father and child
    useEffect(() => {
        dispatch(
            ZoneDetailFatherAndChild({
                zoneId: JSON.parse(sessionStorage?.getItem('zonesStack'))[
                    JSON.parse(sessionStorage?.getItem('zonesStack')).length - 1
                ].id,
            }),
        );
    }, [setZoneImageCoordinate, viewPlane, selectedZone]);

    // set map by default plane or map if no zone plane
    useEffect(() => {
        if (getListZoneMap?.length > 0) {
            setViewPlane(getListZoneMap[0].id);
        }
        if (getListZoneMap?.length === 0) {
            setViewPlane('map');
        }
    }, [deleteImgZonePlane]);

    // useEffect(() => {
    //   getListZoneMap?.map((item) => {
    //     setViewPlane(item?.id)
    //  setDefaultZone(item?.id);
    //   })
    // }, [])

    return (
        <>
            <div className='container_fluid show_device'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='show_device_navigation' style={{ height: openNavigation ? '43px' : '' }}>
                            <div className='show_device_navigation_item pb-2'>
                                <Link
                                    to='/dashboard/employee/zones/singlezonedetails'
                                    onClick={() => dispatch(ClearGetListZoneMap())}
                                >
                                    <img
                                        src={ic_left_arrow}
                                        alt='image'
                                        style={{
                                            transform: lCode === 'ar' ? 'scaleX(-1)' : '',
                                            margin: '2px 2px',
                                            width: '20px',
                                        }}
                                    ></img>
                                </Link>
                                <h4 className='ml-2' onClick={() => setOpenNavigation(!openNavigation)}>
                                    {t('zone_information')}
                                </h4>
                            </div>
                            <div>
                                <h6>{t('zone')}</h6>
                                <p>{zoneDetailFatherAndChild?.name}</p>
                            </div>
                            <div>
                                <FormControl sx={{ minWidth: 200, paddingBottom: '0.6rem' }} size='small' fullWidth>
                                    <InputLabel id='demo-select-small'>{t('view')}</InputLabel>
                                    <Select
                                        size='small'
                                        labelId='demo-select-small'
                                        id='demo-select-small'
                                        // value={viewPlane}
                                        // value={!viewPlane ? defaultZone : viewPlane}
                                        value={viewPlane}
                                        label='View'
                                        // defaultValue={defaultZone}
                                        onChange={handleChange}
                                        // onSelect={() => { checkedSelectedZone() }}
                                    >
                                        {getListZoneMap?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id ? item.id : 'map'}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                        <MenuItem value={'map'} selected>
                                            {t('map')}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {viewPlane !== 'map' &&
                                    permission?.includes(permissionObj?.WEB_ZONE_DELETE_DEVICES) && (
                                        <div>
                                            <Link
                                                to='#'
                                                className='remove_link'
                                                onClick={() => setDeletePlaneModal(true)}
                                            >
                                                {t('remove_blueprint')?.toUpperCase()}
                                            </Link>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                {permission?.includes(permissionObj?.WEB_ZONE_CREATE_DEVICES) && (
                    <div
                        className='showdevice_addbtn'
                        // data-toggle="modal"
                        // data-target="#showdeviceModal"
                    >
                        <button
                            className='add-btn-1'
                            style={{ minWidth: '150px' }}
                            onClick={() => setShowPlaneModal(true)}
                        >
                            <img src={map_solid} alt='' />
                            {t('add_blueprint')}
                        </button>
                    </div>
                )}

                {permission?.includes(permissionObj?.WEB_ZONE_UPDATE_DEVICES) && viewPlane != 'map' && (
                    <div className='showdevice_list' data-toggle='modal' data-target='#showdevice_listModal'>
                        <button
                            className='search-btn-1'
                            style={{ minWidth: '150px' }}
                            onClick={() => {
                                setIsDeviceList(true);
                            }}
                        >
                            <img src={ic_info} alt='' />
                            {t('device_info')}
                        </button>
                    </div>
                )}
                {/* image show  */}
                <div className='Image_container_zone'>
                    {viewPlane === 'map' ? (
                        <div>
                            <ZoneMapSelect
                                latlng={[
                                    zoneDetailFatherAndChild?.latitud || sessionStorage.getItem('currentZoneLat'),
                                    zoneDetailFatherAndChild?.longitud || sessionStorage.getItem('currentZoneLng'),
                                ]}
                                className='zone_map'
                            />
                        </div>
                    ) : (
                        <div className='zone_plane_drag_drop_container'>
                            <ShowImg xCord={x} yCord={y} />

                            {isDeviceList && (
                                <div className='zone_plane_device_list'>
                                    <div className='cross_container'>
                                        <i
                                            class='fa fa-times cross_zone_device'
                                            aria-hidden='true'
                                            onClick={() => {
                                                setIsDeviceList(false);
                                            }}
                                        ></i>
                                    </div>
                                    <h4>{t('devices')}</h4>

                                    {getZoneDevicesLists?.length > 0 ? (
                                        getZoneDevicesLists?.map((item, index) => {
                                            return (
                                                <DeviceListZonePlane
                                                    item={item}
                                                    zoneplaneId={selectedZone}
                                                    index={index}
                                                />
                                            );
                                        })
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: '1rem',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                width: '100%',
                                                gap: '1rem',
                                            }}
                                        >
                                            <img src={noData} alt='' />
                                            <p style={{ color: '#BC0000', fontSize: '24px', fontWeight: 'bold' }}>
                                                {t('no_devices')}
                                            </p>
                                        </Box>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <ShowDeviceModal
                    show={showPlaneModal}
                    onHide={() => setShowPlaneModal(false)}
                    setProfileImage={setProfileImage}
                />
                <ShowDeviceMapModal />
                <RemovePlanModal
                    id={selectedZone?.id}
                    show={deletePlaneModal}
                    onHide={() => setDeletePlaneModal(false)}
                />
            </div>
        </>
    );
};

export default ShowDevices;
