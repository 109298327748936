import axios from 'axios';
import { logOut } from '../reduxToolkit/authentication/authenticationSlice';
import { endpoints, URL } from './Constants';
import { clearUserInformation } from '../reduxToolkit/Commons/CommonsSlice';

import cryptoJs from 'crypto-js';
import securekey from '../config';

const config = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};

export const getToken = (params) => {
    return axios.post(URL + endpoints.TOKEN, params, config);
};

export const logoutUser = (navigate, dispatch) => {
    dispatch(clearUserInformation());
    sessionStorage.removeItem('id');
    dispatch(logOut());
    navigate('/');
};

export const checkAutoLogin = (navigate) => {
    const token = sessionStorage.getItem('bearerToken');
    const bytes = cryptoJs.AES.decrypt(token, securekey);
    const tokenDetailsString = bytes.toString(cryptoJs.enc.Utf8);
    if (!tokenDetailsString) {
        logoutUser(navigate);
    }
};
